import React, {useEffect, useState} from 'react';
import {Image, Modal, Text, View} from 'react-native';
import Button from '../components/Button';
import {useNavigation} from '@react-navigation/native';
import MainStyle from "../../MainStyle";
import bg from "../assets/img/Group 2494.svg";

type Props = { visible: boolean, onClose: () => void, email: string };

const EmailNotExistModal = (props: Props) => {
  const {visible, email} = props;
  const navigation: any = useNavigation();
  const [bgColor, setBgColor] = useState(MainStyle.bg_transparent);

  const createNewAccount = () => {
    props.onClose();
    navigation.navigate({name: 'Register', params: {email}});
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setBgColor(MainStyle.bc_popupDropBg);
      }, 400);

    } else {
      setBgColor(MainStyle.bg_transparent);
    }
  }, [visible]);

  return (
    <Modal animationType="slide" transparent visible={visible} onRequestClose={props.onClose}>
      <View style={[MainStyle.flex1, MainStyle.z_i_2, bgColor]}>
        <View style={MainStyle.flex1}/>

        <View style={styles.container}>
          <Image source={bg as any} style={styles.image}/>

          <Text style={styles.text}>You do not have an account with this email address.</Text>

          <Text style={[...styles.text, MainStyle.Barlow700, MainStyle.m_b_30]}>
            Do you want to create a new account?
          </Text>

          <View style={[MainStyle.p_x_18, MainStyle.m_b_60, MainStyle.column]}>
            <Button text="Create a New Account" onPress={createNewAccount} w="100%" mb={20}/>

            <Button text="Re-Enter Email" onPress={props.onClose} w="100%" bg="transparent" color="white"
                    borderWidth={1} borderColor="white"/>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = {
  container: [
    MainStyle.bg_main, MainStyle.full_w, MainStyle.max_w_430, MainStyle.m_x_auto, MainStyle.relative,
    MainStyle.b_c_cardInfoBorder, MainStyle.b_w_x_1
  ],
  text: [
    MainStyle.c_white, MainStyle.Barlow400, MainStyle.t_a_c, MainStyle.f_s_18, MainStyle.w_261,
    MainStyle.m_x_auto, MainStyle.m_b_16
  ],
  image: [MainStyle.h_267, MainStyle.absolute, MainStyle.t__50, MainStyle.z_i__1, MainStyle.l__1, MainStyle.r__1]
};

export default React.memo(EmailNotExistModal);
