import React from "react";
import {ScrollView, StyleProp, Text, View, ViewStyle} from "react-native";
import MainStyle from "../../MainStyle";
import {Tournament, TournamentPlayerDetails} from "../../generated/smg_service_pb";
import {numberWithCommas} from "../utils/number";

type Props = {
  mx?: string | number, maxH?: string | number, prizePoolList?: number[], w?: string | number, b_b_r?: number,
  state?: number, playerDetailsList: TournamentPlayerDetails.AsObject[]
};

const PrizePool = (props: Props) => {
  const {mx, maxH, prizePoolList, w, b_b_r, state, playerDetailsList} = props;
  const dynamicStyles: StyleProp<ViewStyle> = {
    marginHorizontal: mx, maxHeight: maxH, width: w, borderBottomLeftRadius: b_b_r, borderBottomRightRadius: b_b_r
  };

  const comparePlayerDetails = (a: TournamentPlayerDetails.AsObject, b: TournamentPlayerDetails.AsObject) => {
    return b.prize - a.prize;
  };

  const compareNumbers = (a: number, b: number) => {
    return b - a;
  };

  return (
    <View style={[...styles.wrap, dynamicStyles]}>
      <View style={MainStyle.row}>
        <Text style={styles.row_header}>Place</Text>
        <Text style={styles.row_header}>
          {state === Tournament.State.FINISHED ? 'Payout' : 'Payout (proj)'}
        </Text>
      </View>

      <View style={[MainStyle.m_x_auto, MainStyle.w_1, MainStyle.h_5, MainStyle.bg_settings_border]}/>

      <ScrollView>
        {(prizePoolList && prizePoolList.length) ? prizePoolList.sort(compareNumbers).map((prize, i) => (
          <View key={i} style={MainStyle.row}>
            <Text style={styles.place}>{i + 1}</Text>
            <View style={[MainStyle.m_x_auto, MainStyle.w_1, MainStyle.h_24, MainStyle.bg_settings_border]}/>
            <Text style={styles.payout}>${prize ? numberWithCommas(prize / 100, true) : 0}</Text>
          </View>
        )) : (playerDetailsList && playerDetailsList.length) ? playerDetailsList.sort(comparePlayerDetails).map((el, i) => (
          <View key={el.playerId} style={MainStyle.row}>
            <Text style={styles.place}>{i + 1}</Text>
            <View style={[MainStyle.m_x_auto, MainStyle.w_1, MainStyle.h_24, MainStyle.bg_settings_border]}/>
            <Text style={styles.payout}>${el.prize ? numberWithCommas(el.prize / 100, true) : 0}</Text>
          </View>
        )) : null}
      </ScrollView>

      <View style={[MainStyle.m_x_auto, MainStyle.w_1, MainStyle.h_5, MainStyle.bg_settings_border]}/>
    </View>
  );
};

const styles = {
  wrap: [MainStyle.b_r_20, MainStyle.b_w_1, MainStyle.b_c_settings_border, MainStyle.bg_settings, MainStyle.p_y_19],
  row_header: [MainStyle.c_white, MainStyle.flex1, MainStyle.Barlow500, MainStyle.f_s_16, MainStyle.t_a_c],
  place: [
    MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_20, MainStyle.flex1,
    MainStyle.l_h_24
  ],
  payout: [
    MainStyle.c_new_green_text, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_20, MainStyle.flex1,
    MainStyle.l_h_24
  ]
};

export default React.memo(PrizePool);
