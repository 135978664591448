export function numberWithCommas(x, toFixed = false, decimals = 2) {
  let string;
  if (toFixed) {
    string = Number.isNaN(x) ? '0' : Number(x).toFixed(decimals);
  } else {
    string = x.toString();
  }

  return string.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return string;
}
