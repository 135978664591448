import React from 'react';
import {Modal, ScrollView, Text, Pressable, View} from 'react-native';
import CheckMarkCheckbox from '../../../components/CheckMarkCheckbox';
import {colors} from "../../../assets/styles/colors";
import MainStyle from "../../../../MainStyle";
import {Fontisto} from "@expo/vector-icons";
import {MaterialIcons} from '@expo/vector-icons';

type IFProps = {
  isOpen: boolean, onClose: () => void, filters: string[], onChangeFilter: (val: string) => void, sortBy: string,
  sortChange: (val: string) => void
};

const TournamentsFilterModal: React.FC<IFProps> = (props: IFProps) => {
  const {isOpen, filters, sortBy} = props;

  return (
    <Modal animationType="fade" transparent visible={isOpen} statusBarTranslucent={false} hardwareAccelerated={false}>
      <View style={[MainStyle.fix_gradient_and_modal_error_in_landscape, MainStyle.flex1, MainStyle.bg_filter_modal]}>
        <ScrollView contentContainerStyle={[MainStyle.a_i_c, MainStyle.flex1]}>
          <View style={styles.contentWrapper}>
            <View style={[MainStyle.a_i_e, MainStyle.m_b_10]}>
              <Fontisto name="close-a" size={20} color={colors.white} onPress={props.onClose}/>
            </View>

            <Text style={styles.title}>Refine Tournaments List</Text>

            <View style={[MainStyle.row, MainStyle.m_l_52, MainStyle.a_i_c, MainStyle.m_b_8]}>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_21]}>Filter</Text>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_14, MainStyle.m_l_6]}>
                (Select Any / All)
              </Text>
            </View>

            <Pressable style={[...styles.filterItem, MainStyle.m_b_9]} onPress={() => props.onChangeFilter('upcoming')}>
              <CheckMarkCheckbox checked={filters.includes('upcoming')}
                                 onChange={() => props.onChangeFilter('upcoming')}/>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17, MainStyle.m_l_15]}>
                Upcoming
              </Text>
            </Pressable>

            <Pressable style={[...styles.filterItem, MainStyle.m_b_9]} onPress={() => props.onChangeFilter('live')}>
              <CheckMarkCheckbox checked={filters.includes('live')} onChange={() => props.onChangeFilter('live')}/>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17, MainStyle.m_l_15]}>
                Live Now
              </Text>
            </Pressable>

            <Pressable style={[...styles.filterItem, MainStyle.m_b_31]}
                       onPress={() => props.onChangeFilter('My Entries')}>
              <CheckMarkCheckbox checked={filters.includes('My Entries')}
                                 onChange={() => props.onChangeFilter('My Entries')}/>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17, MainStyle.m_l_15]}>
                My Entries
              </Text>
            </Pressable>

            <View style={[MainStyle.row, MainStyle.m_l_52, MainStyle.a_i_c, MainStyle.m_b_8]}>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_21]}>Sort</Text>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_14, MainStyle.m_l_6]}>
                (Select One)
              </Text>
            </View>

            <Pressable style={[...styles.filterItem, MainStyle.m_b_8]}
                       onPress={() => props.sortChange('Start Date/Time - Soonest')}>
              <MaterialIcons name={`radio-button-${sortBy === 'Start Date/Time - Soonest' ? 'on' : 'off'}`} size={19}
                             color="white"/>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17, MainStyle.m_l_15]}>
                Start Date/Time - Soonest
              </Text>
            </Pressable>

            <Pressable style={[...styles.filterItem, MainStyle.m_b_8]}
                       onPress={() => props.sortChange('Start Date/Time - Latest')}>
              <MaterialIcons name={`radio-button-${sortBy === 'Start Date/Time - Latest' ? 'on' : 'off'}`} size={19}
                             color="white"/>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17, MainStyle.m_l_15]}>
                Start Date/Time - Latest
              </Text>
            </Pressable>

            <Pressable style={[...styles.filterItem, MainStyle.m_b_8]}
                       onPress={() => props.sortChange('End Date/Time - Soonest')}>
              <MaterialIcons name={`radio-button-${sortBy === 'End Date/Time - Soonest' ? 'on' : 'off'}`} size={19}
                             color="white"/>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17, MainStyle.m_l_15]}>
                End Date/Time - Soonest
              </Text>
            </Pressable>

            <Pressable style={[...styles.filterItem, MainStyle.m_b_29]}
                       onPress={() => props.sortChange('End Date/Time - Latest')}>
              <MaterialIcons name={`radio-button-${sortBy === 'End Date/Time - Latest' ? 'on' : 'off'}`} size={19}
                             color="white"/>
              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17, MainStyle.m_l_15]}>
                End Date/Time - Latest
              </Text>
            </Pressable>

            <Pressable onPress={props.onClose}>
              <Text style={styles.close_btn}>CLOSE</Text>
            </Pressable>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = {
  contentWrapper: [
    MainStyle.bg_black, MainStyle.w_370, MainStyle.b_r_20, MainStyle.b_c_darkText, MainStyle.b_w_1, MainStyle.p_t_18,
    MainStyle.p_r_18, MainStyle.m_t_114
  ],
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_24, MainStyle.m_b_24, MainStyle.m_l_24],
  filterItem: [MainStyle.m_l_77, MainStyle.h_26, MainStyle.row, MainStyle.a_i_c],
  close_btn: [
    MainStyle.c_new_green_text, MainStyle.Barlow500, MainStyle.f_s_18, MainStyle.m_x_auto, MainStyle.l_h_26,
    MainStyle.m_b_30
  ]
};

export default React.memo(TournamentsFilterModal);
