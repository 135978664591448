import React from "react";
import {StyleProp, Text, TouchableOpacity, View, ViewStyle} from "react-native";
import MainStyle from "../../MainStyle";
import {toCapitalize} from "../utils";
import {colors} from "../assets/styles/colors";
import {MaterialIcons} from "@expo/vector-icons";

type Props = {
  value: string, mt?: string | number, w?: string | number, error?: boolean | string, mb?: string | number,
  onChange: (val: 'left' | 'right') => void, mx?: string | number
};

const Handedness = (props: Props) => {
  const {value, mt, w, error, mx, mb} = props;
  const wrapStyle: StyleProp<ViewStyle> = {};

  if (mt) wrapStyle.marginTop = mt;
  if (w) wrapStyle.width = w;
  if (mx) wrapStyle.marginHorizontal = mx;
  if (mb) wrapStyle.marginBottom = mb;

  return (
    <View style={wrapStyle}>
      <Text style={styles.title_s}>HANDEDNESS</Text>

      <View style={[...styles.container, {borderColor: error ? colors.newDangerText : colors.cardInfoBorder}]}>
        <TouchableOpacity style={styles.item} onPress={() => props.onChange('left')}>
          <MaterialIcons name={`radio-button-${value === 'left' ? 'on' : 'off'}`} size={18} color="white"/>
          <Text style={styles.label_s}>Left</Text>
        </TouchableOpacity>

        <View style={MainStyle.w_83}/>

        <TouchableOpacity style={styles.item} onPress={() => props.onChange('right')}>
          <MaterialIcons name={`radio-button-${value === 'right' ? 'on' : 'off'}`} size={18} color="white"/>
          <Text style={styles.label_s}>Right</Text>
        </TouchableOpacity>
      </View>

      <Text style={[MainStyle.flex1, MainStyle.c_white, MainStyle.t_a_r, MainStyle.f_w_500, MainStyle.f_s_8, MainStyle.l_h_9, MainStyle.c_warn, MainStyle.t_a_c]}>
        {toCapitalize(error as string)}
      </Text>
    </View>
  );
};

const styles = {
  title_s: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.o_05],
  container: [
    MainStyle.row, MainStyle.b_w_1, MainStyle.b_r_200, MainStyle.h_66, MainStyle.bg_settings, MainStyle.j_c_c
  ],
  item: [MainStyle.row, MainStyle.a_i_c],
  label_s: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_18, MainStyle.m_l_15]
};

export default React.memo(Handedness);
