import React, {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import NewHeader from "../components/NewHeader";
import MainStyle from "../../MainStyle";
import {
  FlatList, NativeScrollEvent, NativeSyntheticEvent, Platform, Pressable, ScrollView, Text, View
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import {LANDING_PAGE_URL, SESSION_EXPIRE_TIME} from "../constants";
import {Tournament, TournamentPlayerBet, TournamentPlayerDetails, User} from "../../generated/smg_service_pb";
import {StoreContext} from "../components/StoreContextProvider";
import {useIsFocused, useNavigation, useRoute} from "@react-navigation/native";
import ServiceClient from "../utils/ServiceClient";
import {CancelModalProps} from "../types";
import TournamentHeader from "../components/TournamentHeader";
import PrizePool from "../components/PrizePool";
import BottomNavigation from "../components/BottomNavigation";
import RegisteredPlayersStatus from "../components/RegisteredPlayersStatus";
import JoinTournamentButton from "../components/JoinTournamentButton";
import ContinueAutoPlayModal from "../modals/ContinueAutoPlay";

const ITEM_WEIGHT = 387;

const TournamentDetails = () => {
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user, session, extendedUserData} = Context;
  const isFocused = useIsFocused();
  const [tournament, setTournament] = useState<Tournament.AsObject>();
  const route = useRoute();
  const navigation: any = useNavigation();
  const [activeTab, setActiveTab] = useState(1);
  const flatListRef = useRef<FlatList<number>>(null);
  const [continueAutoPlay, setContinueAutoPlay] = useState(false);

  const leaveTournament = () => {
    if (!tournament) return;
    Context.setLoadingOverlay(true);

    ServiceClient.leaveTournament(tournament.id).then(() => {
      getTournament();

    }).catch(err => {
      console.log('leaveTournament err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const scrollToItem = (index: number) => {
    flatListRef.current?.scrollToIndex({animated: true, index: index});
  };

  const onScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width;
    setActiveTab(Math.ceil((event.nativeEvent.contentOffset.x + slideSize / 2) / 378));
  }, []);

  const getItemLayout = (data: any, index: number) => (
    {length: ITEM_WEIGHT, offset: ITEM_WEIGHT * index, index}
  );

  const renderItem = ({item}: any) => {
    if (!tournament) return <></>;
    else {
      return (
        <View>
          <View style={MainStyle.row}>
            <View style={item === 1 ? [MainStyle.h_4, MainStyle.flex1, MainStyle.bg_filter_chip] : MainStyle.flex1}/>
            <View style={item === 2 ? [MainStyle.h_4, MainStyle.flex1, MainStyle.bg_filter_chip] : MainStyle.flex1}/>
          </View>

          <View style={[MainStyle.h_1, MainStyle.m_b_14, MainStyle.bg_darkText]}/>

          {item === 1 ? (
            <PrizePool
              w={ITEM_WEIGHT} maxH={248} b_b_r={0} state={tournament.state}
              prizePoolList={tournament.prizepoolList} playerDetailsList={tournament.playerdetailsList}
            />
          ) : (
            <RegisteredPlayersStatus w={ITEM_WEIGHT} maxH={248} b_b_r={0} item={tournament?.playerdetailsList}
                                     playerId={tournament.currentplayerdetails?.playerId}/>
          )}
        </View>
      );
    }
  };

  const getTournament = () => {
    const params: any = route.params;
    if (!params || !params.id) return;

    ServiceClient.getTournament(params.id).then(res => {
      Context.setLoadingOverlay(false);
      setTournament(res);
      if (extendedUserData.hasactivetournament && !user?.autoPlayEnabled) setContinueAutoPlay(true);

    }).catch(err => {
      console.log('getTournament err', err);
      Context.setLoadingOverlay(false);

      let conf: CancelModalProps = {
        visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: err.message,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 1}});
        }
      };

      Context.setCancelModalConfig(conf);
    });
  };

  const getUser = () => {
    Context.getUser().catch(err => {
      console.log('getUser err', err);
      setTimeout(() => {
        getUser();
      }, 5000);
    });
  };

  useEffect(() => {
    if (user?.kycStatus === 6) {
      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', leftBtnText: 'Cancel', rightBtnText: 'Contact Support',
        subtitle: 'Your Know Your Customer information and registration information do not match. Your account with Skill Money Games has been locked. Please contact support to resolve this issue.',
        onResume: () => {
          if (Platform.OS === 'web') {
            window.open('https://smgames.atlassian.net/servicedesk/customer/portal/3', '_self');
          }
        },
        onClose: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 0}});
        }
      });
    }
  }, [user?.kycStatus]);

  useEffect(() => {
    if (!user || !isFocused) return;

    getTournament();

    const time = setTimeout(() => {
      getUser();
    }, 1000 * 10);

    return () => {
      clearTimeout(time);
    };
  }, [user, isFocused]);

  useEffect(() => {
    if (!isFocused) return;
    const time = setInterval(() => {
      (async () => {
        const lastActiveTime = await AsyncStorage.getItem('lastActiveTime');

        if (lastActiveTime && moment().diff(moment(lastActiveTime), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            Context.setLoadingOverlay(false);
          });
        }
      })();
    }, 1000 * 60);

    return () => {
      clearInterval(time);
    };
  }, [isFocused]);

  useLayoutEffect(() => {
    Context.setLoadingOverlay(true);

    if (!session || !user) {
      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            Context.setLoadingOverlay(false);
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);

            } catch (e: any) {
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
              });
            }
          }

          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
              Context.setLoadingOverlay(false);
            });

          } else console.log('user', curUser);
        }
      })();
    }
  }, []);

  return (
    <>
      <NewHeader/>

      <ScrollView>
        <Text style={styles.title}>Tournaments</Text>

        <View style={[MainStyle.row, MainStyle.j_c_s_between, MainStyle.m_b_10, MainStyle.a_i_s]}>
          <Text style={[...styles.title, MainStyle.f_s_22]}>/ Tournament Details</Text>
          <Pressable style={[MainStyle.m_r_24, MainStyle.j_c_c]} onPress={() => navigation.navigate('tournaments')}>
            <Text style={styles.back_btn}>{'< Back to List'}</Text>
          </Pressable>
        </View>

        <View style={[MainStyle.h_1, MainStyle.bg_divider, MainStyle.m_x_23, MainStyle.m_b_18]}/>

        {tournament && (
          <>
            <TournamentHeader mb={24} item={tournament} onReload={getUser}/>

            <View style={[{width: ITEM_WEIGHT}, MainStyle.m_x_auto]}>
              <View style={[MainStyle.row, MainStyle.m_b_1]}>
                <Text style={[...styles.tab_label, activeTab === 1 ? MainStyle.c_white : null]}
                      onPress={() => scrollToItem(0)}>
                  {`Prize\nPool`}
                </Text>
                <Text style={[...styles.tab_label, activeTab === 2 ? MainStyle.c_white : null]}
                      onPress={() => scrollToItem(1)}>
                  {`Registered\nPlayers + Status`}
                </Text>
              </View>

              <FlatList
                ref={flatListRef}
                data={[1, 2]}
                renderItem={renderItem}
                keyExtractor={(item) => item.toString()}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                onScroll={onScroll}
                style={MainStyle.m_b_12}
                getItemLayout={getItemLayout}
              />

              <View style={[MainStyle.h_1, MainStyle.bg_divider, MainStyle.m_b_10]}/>
            </View>

            <Pressable style={MainStyle.m_b_13} onPress={() => navigation.navigate('tournaments')}>
              <Text style={[styles.back_btn, MainStyle.l_h_26]}>{'< Back to List'}</Text>
            </Pressable>

            <JoinTournamentButton
              entryfee={tournament.entryfee}
              id={tournament.id}
              onReload={getTournament}
              registered={!!tournament.currentplayerdetails && tournament.currentplayerdetails.state !== TournamentPlayerDetails.State.FINISHED}
              joinDisabled={tournament.state === Tournament.State.CANCELLED || tournament.state === Tournament.State.FINISHED || tournament.currentplayerdetails?.state === TournamentPlayerDetails.State.FINISHED}
              leaveDisabled={tournament?.playerdetailsList.find(el => el.playerId === tournament.currentplayerdetails?.playerId)?.betsList.filter(bet => bet.state === TournamentPlayerBet.State.FINISHED).length === tournament.totalshots}
              totalShots={tournament.totalshots}
              leave={leaveTournament}
              autoPlayAmount={user?.autoPlayAmount}
            />

            <Text style={[MainStyle.c_Gray, MainStyle.t_a_c, MainStyle.Barlow400, MainStyle.f_s_17]}>
              Malfunction voids all pays and plays
            </Text>
          </>
        )}
      </ScrollView>

      <ContinueAutoPlayModal
        isOpen={continueAutoPlay}
        onClose={() => setContinueAutoPlay(false)}
        onReload={getUser}
      />

      <BottomNavigation page="tournaments"/>
    </>
  );
};

const styles = {
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_32, MainStyle.m_l_21],
  back_btn: [MainStyle.c_new_green_text, MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.t_t_u, MainStyle.t_a_c],
  tab_label: [MainStyle.c_state_completed, MainStyle.flex1, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_16]
};

export default React.memo(TournamentDetails);
