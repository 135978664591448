import React, {useContext} from "react";
import {Platform} from "react-native";
import Button from "./Button";
import {Div} from "react-native-magnus";
import {StoreContext} from "./StoreContextProvider";
import {useNavigation} from "@react-navigation/native";
import {colors} from "../assets/styles/colors";

type Props = { isSm: boolean, tabIndex: number, onChange: (val: number) => void };

const AccountNavBar = (props: Props) => {
  const {tabIndex, isSm} = props;
  const Context = useContext(StoreContext);
  const navigation: any = useNavigation();
  const {cancelModalConfig} = Context;

  const Logout = () => {
    Context.setCancelModalConfig({
      visible: true, title: 'Logout', subtitle: 'Are you sure you want to logout?',
      onExit: () => {
        Context.logout().then(() => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          setTimeout(() => {
            if (Platform.OS === 'web') window.location.reload();
          }, 150);
          navigation.reset({index: 0, routes: [{name: 'Splash'}]});
        });
      },
      onResume: () => {
        Context.setCancelModalConfig({...cancelModalConfig, visible: false});
      }
    });
  };

  return (
    <Div rounded={isSm ? 10 : 16} bg={colors.bg_contest} w="100%" maxW={isSm ? 314 : 668} row>
      <Button
        text="PROFILE"
        onPress={() => props.onChange(0)}
        w={isSm ? 67 : 125}
        h={isSm ? 32 : 50}
        radius={isSm ? 10 : 16}
        bg={tabIndex === 0 ? colors.amount : 'transparent'}
        borderColor={tabIndex === 0 ? colors.blueText : 'transparent'}
        color={tabIndex === 0 ? colors.whiteText : colors.lightBlue}
        borderWidth={isSm ? 1 : 2}
        fontSize={isSm ? 10 : 18}
      />

      <Button
        text="DEPOSIT"
        onPress={() => props.onChange(1)}
        w={isSm ? 67 : 125}
        h={isSm ? 32 : 50}
        radius={isSm ? 10 : 16}
        bg={tabIndex === 1 ? colors.amount : 'transparent'}
        borderColor={tabIndex === 1 ? colors.blueText : 'transparent'}
        borderWidth={isSm ? 1 : 2}
        fontSize={isSm ? 10 : 18}
        color={tabIndex === 1 ? colors.whiteText : colors.lightBlue}

      />

      <Button
        text="WITHDRAWAL"
        onPress={() => props.onChange(2)}
        w={isSm ? 80 : 150}
        h={isSm ? 32 : 50}
        radius={isSm ? 10 : 16}
        bg={tabIndex === 2 ? colors.amount : 'transparent'}
        borderColor={tabIndex === 2 ? colors.blueText : 'transparent'}
        borderWidth={isSm ? 1 : 2}
        fontSize={isSm ? 10 : 18}
        color={tabIndex === 2 ? colors.whiteText : colors.lightBlue}

      />

      <Div flex={1}/>

      <Button text="SIGN OUT" onPress={Logout} w={isSm ? 67 : 125} h={isSm ? 32 : 50} bg="transparent"
              fontSize={isSm ? 10 : 18} color="white"/>
    </Div>
  );
};

export default React.memo(AccountNavBar);
