import React, {useEffect, useRef} from "react";
import {Animated, StyleProp, ViewStyle} from "react-native";

type Props = { style?: StyleProp<ViewStyle>, duration: number, showDuration: number, children?: React.ReactNode };

const FadeView = (props: Props) => {
  const {style, duration, children, showDuration} = props;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {toValue: 1, duration, useNativeDriver: true}).start();

    setTimeout(() => {
      Animated.timing(fadeAnim, {toValue: 0, duration, useNativeDriver: true}).start();
    }, duration + showDuration);
  }, []);

  return (
    <Animated.View style={[style, {opacity: fadeAnim}]}>
      {children}
    </Animated.View>
  );
};

export default React.memo(FadeView);
