import React, {useEffect, useRef, useState} from "react";
import {
  View, TextInput, StyleProp, ViewStyle, Platform, Text, Pressable, NativeSyntheticEvent, TextInputFocusEventData,
  TextStyle
} from "react-native";
import MainStyle from "../../MainStyle";
import Ionicons from '@expo/vector-icons/Ionicons';
import {toCapitalize} from "../utils";

// @ts-ignore
import NumericInput from "@wwdrew/react-native-numeric-textinput";
import {outline_0} from "../constants";
import {colors} from "../assets/styles/colors";

type Props = {
  style?: StyleProp<ViewStyle>, placeholder?: string, label?: string, type?: 'numeric' | 'email-address',
  secureTextEntry?: boolean, helperText?: string, value?: string, onChangeText?: ((text: string) => void),
  onBlur?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void), error?: string | boolean | null,
  autoFocus?: boolean, editable?: boolean, dollarMask?: boolean, labelStyle?: StyleProp<TextStyle>,
  inputStyles?: StyleProp<TextStyle>, decimalPlaces?: number, useGrouping?: boolean, onFocusScroll?: boolean,
  mb?: string | number, w?: string | number, textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify'
};

const Input = (props: Props) => {
  const {style, placeholder, label, type, secureTextEntry, helperText, value, error, autoFocus, mb, editable} = props;
  const {labelStyle, inputStyles, dollarMask, decimalPlaces, useGrouping, onFocusScroll, w, textAlign} = props;
  let wrapStyle: StyleProp<ViewStyle> = [MainStyle.relative];
  const [secureText, setSecureText] = useState(secureTextEntry);
  let inputStyle: StyleProp<TextStyle> = [
    outline_0, MainStyle.b_r_200, MainStyle.b_w_1, MainStyle.b_c_cardInfoBorder, MainStyle.bg_settings, MainStyle.h_66,
    MainStyle.p_x_32, MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_24
  ];
  const wrapRef = useRef<any>();
  const secureTextStyle: any = (secureText && Platform.OS === 'web') ? {WebkitTextSecurity: 'disc'} : {};

  if (style) {
    if (Array.isArray(style)) wrapStyle = [...wrapStyle, ...style];
    else wrapStyle.push(style);
  }

  if (mb) wrapStyle.push({marginBottom: mb});
  if (w) wrapStyle.push({width: w});
  if (textAlign) inputStyle.push({textAlign});

  const onTouchStart = () => {
    if (Platform.OS === 'web') {
      const {maxTouchPoints, platform} = window.navigator;

      if (maxTouchPoints && platform === 'Win32' && onFocusScroll) {
        wrapRef.current?.scrollIntoView({behavior: 'instant', block: 'center', inline: 'center'});
      }
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const {maxTouchPoints, platform} = window.navigator;

      if ((dollarMask || secureTextEntry) && maxTouchPoints && platform === 'Win32') {
        wrapRef.current.querySelector('input').setAttribute('type', 'tel');
      }
    }
  }, []);

  return (
    <View style={wrapStyle}>
      <View style={[MainStyle.row, MainStyle.p_x_9, MainStyle.m_b_3, MainStyle.relative]}>
        {label && <Text style={[styles.label_s, labelStyle]}>{label}</Text>}

        {helperText ? <Text style={styles.helper_text_s}>{helperText}</Text> : null}
      </View>

      <View style={MainStyle.relative} ref={ref => wrapRef.current = ref} onTouchStart={onTouchStart}>
        {dollarMask ? (
          <NumericInput
            value={value}
            onUpdate={(val: any) => {
              if (props.onChangeText) props.onChangeText(`${val || ''}`);
            }}
            style={[inputStyle, inputStyles, secureTextStyle, error && MainStyle.b_c_warn]}
            placeholder={placeholder}
            placeholderTextColor={colors.gray}
            decimalPlaces={decimalPlaces || 0}
            secureTextEntry={Platform.OS !== 'web' && secureText}
            onBlur={props.onBlur}
            autoFocus={autoFocus}
            editable={editable}
            useGrouping={!!useGrouping}
          />
        ) : (
          <TextInput
            placeholder={placeholder}
            style={[inputStyle, inputStyles, secureTextStyle, error && MainStyle.b_c_warn]}
            placeholderTextColor={colors.gray}
            keyboardType={type}
            secureTextEntry={secureText}
            value={value}
            onChangeText={props.onChangeText}
            onBlur={props.onBlur}
            autoFocus={autoFocus}
            editable={editable}
          />
        )}

        {typeof secureText === 'boolean' && (
          <View style={[MainStyle.absolute, MainStyle.b_1, MainStyle.t_1, MainStyle.r_1, MainStyle.row, MainStyle.bc_transparent]}>
            <Pressable style={styles.icon_s} onPress={() => props.onChangeText?.('')}>
              <Ionicons name="close" size={16} color={colors.iconColor}/>
            </Pressable>

            <Pressable style={styles.icon_s} onPress={() => setSecureText(!secureText)}>
              <Ionicons name={secureText ? 'eye-off-outline' : 'eye-outline'} size={16} color={colors.iconColor}/>
            </Pressable>
          </View>
        )}
      </View>

      <View style={[MainStyle.absolute, MainStyle.b__14, MainStyle.full_w]}>
        {error && (
          <Text style={[...styles.helper_text_s, MainStyle.c_warn, MainStyle.t_a_c]}>
            {toCapitalize(error as string)}
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = {
  label_s: [
    MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_14, MainStyle.l_h_18,
    MainStyle.flex1, MainStyle.o_05
  ],

  helper_text_s: [
    MainStyle.flex1, MainStyle.c_white, MainStyle.t_a_r, MainStyle.Barlow500, MainStyle.f_s_12
  ],

  icon_s: [MainStyle.h_40, MainStyle.w_40, MainStyle.a_i_c, MainStyle.j_c_c],

  country_picker_btn_s: [
    MainStyle.bc_popupDropBg, MainStyle.b_w_1, MainStyle.b_c_blue, MainStyle.b_r_4, MainStyle.w_50, MainStyle.p_l_10
  ],

  textContainerStyle: [
    MainStyle.bc_popupDropBg, MainStyle.b_r_4, MainStyle.b_w_1, MainStyle.b_c_blue, MainStyle.flex1, MainStyle.p_r_0,
    MainStyle.p_y_0, MainStyle.p_r_0, MainStyle.p_l_14
  ],
  code_text_s: [
    MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.c_lightblue, MainStyle.p_r_6, MainStyle.m_r_0,
    MainStyle.h_40, MainStyle.l_h_40
  ]
};

export default React.memo(Input);