import React from "react";
import {Text, useWindowDimensions, View} from "react-native";
import MainStyle from "../../MainStyle";
import {breakpoint} from "../constants";

const TransactionHistoryHeader = () => {
  const {width} = useWindowDimensions();
  const isLg = width >= breakpoint;

  return (
    <>
      <Text style={isLg ? styles.title_l : styles.title_s}>TRANSACTIONS</Text>

      <View style={[...styles.row, isLg ? MainStyle.m_b_11 : MainStyle.m_b_2]}>
        <Text style={[...styles.text, ...(isLg ? [MainStyle.f_s_24, MainStyle.w_286] : [MainStyle.f_s_12, MainStyle.flex1])]}>
          TIME
        </Text>

        <Text style={[...styles.text, ...(isLg ? [MainStyle.f_s_24, MainStyle.w_224] : [MainStyle.f_s_12, MainStyle.flex1])]}>
          TYPE
        </Text>

        <Text style={[...styles.text, ...(isLg ? [MainStyle.f_s_24, MainStyle.w_160] : [MainStyle.f_s_12, MainStyle.flex1])]}>
          AMOUNT
        </Text>

        <Text style={[...styles.text, ...(isLg ? [MainStyle.f_s_24, MainStyle.w_370] : [MainStyle.f_s_12, MainStyle.flex1])]}>
          STATUS
        </Text>
      </View>
    </>
  );
};

const styles = {
  title_l: [
    MainStyle.f_w_700, MainStyle.f_s_32, MainStyle.l_h_33, MainStyle.t_a_c, MainStyle.c_lightblue, MainStyle.m_t_28,
    MainStyle.m_b_26
  ],
  title_s: [
    MainStyle.f_w_700, MainStyle.f_s_16, MainStyle.l_h_16, MainStyle.t_a_c, MainStyle.c_lightblue, MainStyle.m_t_17,
    MainStyle.m_b_8
  ],
  row: [
    MainStyle.row, MainStyle.full_w, MainStyle.max_w_1100, MainStyle.p_x_30, MainStyle.m_x_auto, MainStyle.j_c_s_around
  ],
  text: [MainStyle.f_w_700, MainStyle.l_h_33, MainStyle.c_white, MainStyle.t_a_c]
};

export default React.memo(TransactionHistoryHeader);
