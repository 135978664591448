import React, {useEffect, useRef} from "react";
import {Animated, Pressable, ViewStyle} from "react-native";

interface Style extends ViewStyle {
  activeBackgroundColor?: string;
  inActiveBackgroundColor?: string;
  activeBorderColor?: string;
  inActiveBorderColor?: string;
}

type Props = { value?: boolean, onPress: () => void, trackStyle?: Style, thumbStyle: Style, isSm?: boolean };

const Switch = (props: Props) => {
  const {value, trackStyle, thumbStyle, isSm} = props;
  const leftAnim = useRef(new Animated.Value(isSm ? 1 : 3)).current;
  const wrapStyle = {
    borderWidth: trackStyle?.borderWidth, width: trackStyle?.width, height: trackStyle?.height,
    borderRadius: trackStyle?.borderRadius,
    backgroundColor: value ? trackStyle?.activeBackgroundColor : trackStyle?.inActiveBackgroundColor,
    borderColor: value ? trackStyle?.activeBorderColor : trackStyle?.inActiveBorderColor
  };
  const thumb = {
    borderWidth: thumbStyle.borderWidth, width: thumbStyle.width, height: thumbStyle.height,
    borderRadius: thumbStyle.borderRadius,
    borderColor: value ? thumbStyle.activeBorderColor : thumbStyle.inActiveBorderColor,
    backgroundColor: value ? thumbStyle.activeBackgroundColor : thumbStyle.inActiveBackgroundColor,
    shadowColor: thumbStyle.shadowColor, shadowOffset: thumbStyle.shadowOffset, shadowOpacity: thumbStyle.shadowOpacity,
    shadowRadius: thumbStyle.shadowRadius, elevation: thumbStyle.elevation
  };

  useEffect(() => {
    if (wrapStyle.width && thumb.width) {
      const toValue = value ? +wrapStyle.width - +thumb.width - (isSm ? 3 : 5) : isSm ? 1 : 3;
      Animated.timing(leftAnim, {toValue, duration: 300, useNativeDriver: true}).start();
    }
  }, [value]);

  return (
    <Pressable style={{...wrapStyle, justifyContent: 'center', position: 'relative'}} onPress={props.onPress}>
      <Animated.View style={{...thumb, position: 'absolute', left: leftAnim}}/>
    </Pressable>
  );
};

export default React.memo(Switch);
