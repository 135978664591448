import React, {useContext, useState} from "react";
import {Text, View, Image, Pressable} from "react-native";
import MainStyle from "../../MainStyle";
import lock_icon from '../assets/img/icons/icon-lock-small.svg';
import {outline_0} from "../constants";
import {colors} from "../assets/styles/colors";
import {Tournament, TournamentPlayerDetails} from "../../generated/smg_service_pb";
import moment from "moment";
import {Ionicons} from '@expo/vector-icons';
import TournamentRegistrationConfirmationModal from "../modals/TournamentRegistrationConfirmationModal";
import ServiceClient from "../utils/ServiceClient";
import {StoreContext} from "./StoreContextProvider";

type Props = { item: Tournament.AsObject, mb?: string | number, onReload: () => void };

const TournamentHeader = (props: Props) => {
  const {item, mb} = props;
  const [isOpen, setIsOpen] = useState(false);
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user} = Context;

  const enableAutoPlay = (fee: string) => {
    Context.setLoadingOverlay(true);
    setIsOpen(false);

    ServiceClient.enableAutoPlay(true, +fee * 100).then(() => {
      Context.setLoadingOverlay(false);
      props.onReload();

    }).catch(err => {
      console.log('enableAutoPlay err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const renderState = () => {
    const State = Tournament.State;
    let val = {color: '', text: ''};

    if (item.state === State.PENDING) val = {color: colors.filter_chip_bg, text: 'CREATED'};
    else if (item.state === State.PUBLISHED) val = {color: colors.disable_chip, text: 'PUBLISHED'};
    else if (item.state === State.PLAYING) val = {color: colors.state_color, text: 'ACTIVE'};
    else if (item.state === State.FINISHED) val = {color: colors.state_completed, text: 'Completed'};
    else if (item.state === State.CANCELLED) val = {color: colors.state_cancelled, text: 'CANCELLED'};

    return <Text style={[...styles.status, {borderColor: val.color}]}>{val.text}</Text>;
  };

  return (
    <View style={[MainStyle.m_x_auto, MainStyle.w_367]}>
      <Text style={styles.name} numberOfLines={1}>{item.name}</Text>
      <Text style={styles.id}>#{item.id}</Text>

      <View style={[MainStyle.row, MainStyle.m_x_12, MainStyle.a_i_c, MainStyle.m_b_12]}>
        <View style={[MainStyle.column, MainStyle.flex1]}>
          <View style={[MainStyle.row, MainStyle.m_b_3, MainStyle.j_c_s_between]}>
            <Text style={styles.label_text}>Start</Text>
            <Text style={styles.date}>{moment.unix(item.entrybegindate).format('MM/DD/YYYY hh:mm A [PST]')}</Text>
          </View>

          <View style={[MainStyle.row, MainStyle.j_c_s_between]}>
            <Text style={styles.label_text}>End</Text>
            <Text style={styles.date}>{moment.unix(item.entryenddate).format('MM/DD/YYYY hh:mm A [PST]')}</Text>
          </View>
        </View>

        {renderState()}
      </View>

      <View style={MainStyle.row}>
        <Text style={styles.row_label}>Players</Text>
        <Text style={styles.row_label}>Registered</Text>
        <Text style={styles.row_label}>Availability</Text>
      </View>

      <View style={[MainStyle.row, MainStyle.m_b_8]}>
        <Text style={styles.row_value}>1 - {item.maxparticipants}</Text>
        <Text style={styles.row_value}>{item.playerdetailsList.length}</Text>
        <View style={[MainStyle.flex1, MainStyle.a_i_c]}>
          <View style={[MainStyle.p_x_5, MainStyle.bg_availability, MainStyle.row, MainStyle.a_i_c]}>
            <Text style={styles.availability}>{item.isprivate ? 'PRIVATE' : 'PUBLIC'}</Text>
            <Image source={lock_icon as any} style={[MainStyle.w_8, MainStyle.h_12, MainStyle.m_l_4]}/>
          </View>
        </View>
      </View>

      <View style={MainStyle.row}>
        <Text style={styles.row_label}>total Shots</Text>
        <Text style={styles.row_label}>Entry Fee</Text>
        <Text style={styles.row_label}>Challenge Fee</Text>
        <Text style={styles.row_label}>Fee %</Text>
      </View>

      <View style={[MainStyle.row, {marginBottom: mb}]}>
        <Text style={styles.row_value}>{item.totalshots}</Text>
        <Text style={styles.row_value}>${item.entryfee / 100}</Text>

        <View style={[MainStyle.flex1, MainStyle.a_i_c]}>
          {!!item.currentplayerdetails && item.currentplayerdetails.state !== TournamentPlayerDetails.State.FINISHED ? (
            <Pressable style={styles.input_wrap} onPress={() => setIsOpen(true)}>
              <Text style={styles.text_s}>
                ${user?.autoPlayAmount ? (user.autoPlayAmount !== -2 ? user.autoPlayAmount / 100 : 0) : 0}
              </Text>

              <Ionicons
                name="pencil"
                size={11}
                color={colors.newGreenText}
                style={[MainStyle.absolute, MainStyle.r__17]}
              />
            </Pressable>
          ) : (
            <Text style={styles.row_value}>$0</Text>
          )}
        </View>

        <Text style={styles.row_value}>{item.rake}</Text>
      </View>

      <TournamentRegistrationConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        joinTournament={enableAutoPlay}
        type="ChallengeFeeConfirmationModal"
        totalShots={item.totalshots}
        entryFee={item.entryfee}
        entryChallengeFee={`${user?.autoPlayAmount ? user.autoPlayAmount / 100 : 0}`}
      />
    </View>
  );
};

const styles = {
  name: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_24, MainStyle.m_b_7],
  id: [
    MainStyle.c_id_text, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_20, MainStyle.l_h_20,
    MainStyle.m_b_11
  ],
  label_text: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_14, MainStyle.l_h_17, MainStyle.t_t_u
  ],
  date: [MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.l_h_17],
  status: [
    MainStyle.w_82, MainStyle.l_h_36, MainStyle.t_a_c, MainStyle.b_w_1, MainStyle.m_l_13, MainStyle.c_white,
    MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.t_t_u
  ],
  row_label: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_14, MainStyle.flex1, MainStyle.t_a_c, MainStyle.t_t_c,
    MainStyle.l_h_18
  ],
  row_value: [
    MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.l_h_18, MainStyle.flex1,
    MainStyle.t_a_c
  ],
  availability: [MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.l_h_18, MainStyle.t_t_c],
  text_s: [MainStyle.Barlow500, MainStyle.f_s_15, MainStyle.c_new_green_text],
  input_s: [MainStyle.absolute, MainStyle.t_0, MainStyle.r_0, MainStyle.b_0, MainStyle.l_18, outline_0],
  input_wrap: [
    MainStyle.row, MainStyle.relative, MainStyle.b_b_w_1, MainStyle.b_c_newGreen, MainStyle.p_x_8, MainStyle.a_i_c
  ]
};

export default React.memo(TournamentHeader);
