import React, {useContext, useEffect, useRef, useState} from "react";
import {Div, Text} from "react-native-magnus";
import {colors} from "../assets/styles/colors";
import Button from "./Button";
import {smgTransactorWorldpayWebsocket} from "../constants";
import {AxiosError} from "axios";
import {StoreContext} from "./StoreContextProvider";
import PosTerminalLoading from "../modals/PosTerminalLoading";
import {getLocation} from "../utils";
import MainStyle from "../../MainStyle";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import {numberWithCommas} from "../utils/number";
import ServiceClient from "../utils/ServiceClient";

type Props = { isSm: boolean, amount: string, goBack: () => void };

const PosTerminal = (props: Props) => {
  const {isSm, amount} = props;
  const socket = useRef<WebSocket>();
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const isFocused = useIsFocused();
  const navigation: any = useNavigation();

  const sale = async () => {
    const curLocation = await getLocation();

    if (!curLocation) {
      setLoadingOverlay(false);
      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: 'Permission to access the location was denied.', rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });

      return;
    }

    const data = {
      amount: +amount * 100,
      location: {latitude: `${curLocation.coords.latitude}`, longitude: `${curLocation.coords.longitude}`}
    };

    ServiceClient.sale(data).then(transactionId => {
      openWebsocketConnection(transactionId);

    }).catch((err: AxiosError<{ success: boolean, message: string }>) => {
      console.log('sale error', err);
      setLoadingOverlay(false);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const openWebsocketConnection = (transactionId: any) => {
    console.log('openWebsocketConnection');
    socket.current = new WebSocket(`${smgTransactorWorldpayWebsocket}/v1/transactions/sale/result/${transactionId}`);

    socket.current.onopen = function () {
      console.log('[open] Connection established');
    };

    socket.current.onmessage = function (event) {
      console.log('[message] Data received from server:', event.data);
      setLoadingOverlay(false);
      socket.current?.close(1000, JSON.stringify({message: 'Work complete'}));
    };

    socket.current.onclose = function (event) {
      setLoadingOverlay(false);

      if (event.wasClean) {
        const reason = JSON.parse(event.reason);
        console.log('reason', reason);

        if (reason && reason.success) {
          Context.setCancelModalConfig({
            visible: true, title: 'CONGRATULATIONS!', titleColor: MainStyle.c_lightblue, leftBtnText: 'ENTER CHALLENGES',
            subtitle: reason.result,
            onExit: () => {
              Context.setCancelModalConfig({...cancelModalConfig, visible: false});
              navigation.navigate('challenges');
            }
          });

        } else if (reason && !reason.success) {
          Context.setCancelModalConfig({
            visible: true, title: 'Error', subtitle: reason.message, rightBtnText: 'OK',
            onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
          });

        } else {
          console.log(`[close] Connection closed cleanly, reason=${event.reason}`);
        }

        console.log(`[close] Connection closed cleanly, reason=${event.reason}`);

      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log('[close] Connection died');
      }
    };
  };

  const getToken = () => {
    setLoadingOverlay(true);

    ServiceClient.getPayGateToken().then(() => {
      sale().then();

    }).catch(err => {
      console.log('getPayGateToken err', err);
      setLoadingOverlay(false);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const handleSubmit = () => {
    getToken();
  };

  useEffect(() => {
    if (!isFocused) return;

    return () => {
      if (socket.current) socket.current.close(1000, JSON.stringify({message: 'Work complete'}));
    };
  }, [isFocused]);

  return (
    <Div flexDir={isSm ? 'column' : 'row'} alignItems="center" flex={1}>
      <Div minW={isSm ? 228 : 569} flex={1} justifyContent="center">
        <Text fontSize={isSm ? 16 : 36} fontWeight="400" color={colors.whiteText} textAlign="center">
          Select the DEPOSIT button
        </Text>

        <Text fontSize={isSm ? 16 : 36} fontWeight="400" color={colors.whiteText} textAlign="center">
          then swipe your card
        </Text>

        <Text fontSize={isSm ? 16 : 36} fontWeight="400" color={colors.whiteText} textAlign="center">
          on the credit card terminal
        </Text>
      </Div>

      <Div w={isSm ? 164 : 'auto'} alignItems="center" mb={isSm ? 58 : 0} mx={isSm ? 'auto' : 0}
           flex={isSm ? undefined : 1}>
        <Text color={colors.lightBlue} mb={isSm ? 36 : 6} fontWeight="600" fontSize={isSm ? 28 : 60}
              lineHeight={isSm ? 27 : 78}>
          ${amount ? numberWithCommas(amount) : 0}
        </Text>

        <Button
          text="DEPOSIT"
          onPress={handleSubmit}
          w={isSm ? 164 : 323}
          h={isSm ? 40 : 80}
          textStyle={[{fontSize: isSm ? 16 : 32}]}
          mb={isSm ? 16 : 25}
        />

        <Button
          text="BACK"
          onPress={props.goBack}
          w={isSm ? 164 : 323}
          h={isSm ? 40 : 80}
          textStyle={[{fontSize: isSm ? 16 : 32}]}
          bg={colors.unselect}
        />
      </Div>

      <PosTerminalLoading visible={loadingOverlay}/>
    </Div>
  );
};

export default React.memo(PosTerminal);
