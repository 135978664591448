import React, {useState} from 'react';
import {Linking, Modal, Platform, ScrollView, StyleSheet, Text, View} from 'react-native';
import Button from '../components/Button';
import MainStyle from '../../MainStyle';
import TopGolfRentonModal from './TopGolfRentonModal';
import {colors} from '../assets/styles/colors';

type Props = { isOpen: boolean, onClose: () => void };

const PlayNowModal = (props: Props) => {
  const {isOpen, onClose} = props;
  const [isOpenTopGolfModal, setIsOpenTopGolfModal] = useState(false);
  const openLink = (url: string) => {
    if (Platform.OS === 'web') window.open(url, '_blank');
    else Linking.openURL(url).then();
  };

  return (
    <>
      <Modal animationType="fade" transparent visible={isOpen} statusBarTranslucent={false} hardwareAccelerated={false}
             onRequestClose={onClose}>
        <View style={[MainStyle.fix_gradient_and_modal_error_in_landscape, MainStyle.flex1, MainStyle.bg_filter_modal]}>
          <ScrollView contentContainerStyle={MainStyle.flex1}>
            <View style={[MainStyle.min_h_full, MainStyle.a_i_c, MainStyle.j_c_c]}>
              <View style={rnStyles.contentWrapper}>
                <Text style={rnStyles.title}>I’m here… Let’s Go!</Text>

                <Button text="PLAY" fontFamily="Barlow-SemiBold" onPress={onClose} mb={40}/>

                <Text style={{...rnStyles.title, color: colors.lightYellow}}>FIND SKILL MONEY</Text>

                <View style={rnStyles.blk}>
                  <Text style={rnStyles.label}>Topgolf Swing Suites</Text>
                  <Button
                    bg={colors.mainBackground} borderWidth={1} borderColor="white" text="TG RENTON MAP" color="white"
                    onPress={() => {
                      setIsOpenTopGolfModal(true);
                      onClose();
                    }}
                  />
                </View>

                <Button
                  bg={colors.mainBackground} borderWidth={1} borderColor="white" text="OTHER LOCATIONS" color="white"
                  onPress={() => openLink('https://www.skillmoney.com/locations')}
                />
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>

      <TopGolfRentonModal
        isOpen={isOpenTopGolfModal}
        onClose={() => setIsOpenTopGolfModal(false)}
      />
    </>
  );
};

const rnStyles = StyleSheet.create({
  contentWrapper: {
    backgroundColor: colors.settings_bg,
    maxWidth: 330,
    width: '100%',
    borderRadius: 20,
    borderColor: colors.settings_border,
    borderWidth: 1,
    paddingTop: 22,
    minHeight: 400,
    paddingBottom: 35,
    paddingHorizontal: 34,
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: 30,
    letterSpacing: -0.408,
    marginBottom: 10,
    ...MainStyle.Barlow500,
  },
  blk: {
    marginBottom: 14,
  },
  label: {
    color: '#FFF',
    textAlign: 'center',
    fontFeatureSettings: "'case' on",
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: -0.408,
    marginBottom: 10,
    ...MainStyle.Barlow600,
  },
  ftr: {
    marginTop: 16,
  },
});

export default React.memo(PlayNowModal);
