import React, {useContext, useState} from 'react';
import {FlatList, Linking, Modal, Platform, StyleSheet, Text, TouchableOpacity, useWindowDimensions} from 'react-native';
import {Div, Image} from 'react-native-magnus';
import {StoreContext} from '../components/StoreContextProvider';
import {breakpoint, SkillMoneyLanding, tabletBreakpoint} from '../constants';
import {useNavigation} from '@react-navigation/native';
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {colors} from "../assets/styles/colors";
import TutorialModal from "./TutorialModal";
import {Ionicons} from '@expo/vector-icons';

const support_url = 'https://smgames.atlassian.net/servicedesk/customer/portal/3';

type Props = { isSplashPage?: boolean };

export const HelpLinkModalTrigger = (props: Props) => {
  const {isSplashPage} = props;
  const Context = useContext(StoreContext);
  const {session} = Context;
  const {width} = useWindowDimensions();
  const isSm = width < tabletBreakpoint;
  const insets = useSafeAreaInsets();

  return (
    <Div position="absolute" right={isSplashPage ? (isSm ? 23 : 43) : (isSm ? 16 : 50)} zIndex={9}
         top={(isSplashPage ? (isSm ? 22 : 44) : 0) + insets.top}>
      <TouchableOpacity onPress={() => Context.setIsHelpModalVisible(true)}>
        {isSplashPage ? (
          <Ionicons name="menu" size={isSm ? 20 : 28} color="white"/>
        ) : (
          <Ionicons name={session ? 'settings-outline' : 'help-circle-outline'} size={isSm ? 20 : 24} color="white"/>
        )}
      </TouchableOpacity>
    </Div>
  );
};

const HelpLinksModal = () => {
  const navigation: any = useNavigation();
  const {width, height} = useWindowDimensions();
  const isSm = width < breakpoint;
  const Context = useContext(StoreContext);
  const {isHelpModalVisible, user} = Context;
  const [showTutorial, setShowTutorial] = useState(false);
  const AUTHENTICATIONS_LINKS = [
    {
      title: 'CONTACT SUPPORT',
      onPress: () => {
        if (Platform.OS === 'web') window.open(support_url, isSm ? '_blank' : '_self');
        else Linking.openURL(support_url).then();
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Location Settings',
      onPress: () => {
        navigation.navigate('LocationSettings');
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'TRANSACTION HISTORY',
      onPress: () => {
        navigation.navigate('TransactionHistory');
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Tutorial',
      onPress: () => {
        Context.setIsHelpModalVisible(false);
        setShowTutorial(true);
      }
    },
    {
      title: 'Notifications',
      onPress: () => {
        navigation.navigate('Notifications');
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Terms and Conditions',
      onPress: () => {
        navigation.navigate('TermsAndConditions');
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Privacy Policy',
      onPress: () => {
        Context.setIsHelpModalVisible(false);
        navigation.navigate('PrivacyPolicy');
      }
    },
    {
      title: 'Responsible Gaming',
      onPress: () => {
        navigation.navigate('ResponsibleGaming');
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Self-Limitation',
      onPress: () => {
        navigation.navigate('SelfExclusion');
        Context.setIsHelpModalVisible(false);
      },
    },
    {
      title: 'PROMOTIONS TERMS AND CONDITIONS',
      onPress: () => {
        Context.setIsHelpModalVisible(false);
        navigation.navigate('Promotions');
      }
    },
    {
      title: 'About the Company',
      onPress: () => {
        if (Platform.OS === 'web') window.open(SkillMoneyLanding, '_self');
        Context.setIsHelpModalVisible(false);
      }
    }
  ];

  const UN_AUTHENTICATIONS_LINKS = [
    {
      title: 'Contact Support',
      onPress: () => {
        if (Platform.OS === 'web') window.open(support_url, isSm ? '_blank' : '_self');
        else Linking.openURL(support_url).then();
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Location Settings',
      onPress: () => {
        navigation.navigate('LocationSettings');
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Tutorial',
      onPress: () => {
        Context.setIsHelpModalVisible(false);
        setShowTutorial(true);
      }
    },
    {
      title: 'Terms and Conditions',
      onPress: () => {
        navigation.navigate('TermsAndConditions');
        Context.setIsHelpModalVisible(false);
      }
    },
    {
      title: 'Privacy Policy',
      onPress: () => {
        Context.setIsHelpModalVisible(false);
        navigation.navigate('PrivacyPolicy');
      }
    },
    {
      title: 'PROMOTIONS TERMS AND CONDITIONS',
      onPress: () => {
        Context.setIsHelpModalVisible(false);
        navigation.navigate('Promotions');
      }
    },
    {
      title: 'About the Company',
      onPress: () => {
        if (Platform.OS === 'web') window.open(SkillMoneyLanding, '_self');
        Context.setIsHelpModalVisible(false);
      }
    }
  ];

  const _renderItem = ({item}: any) => {
    return (
      <TouchableOpacity onPress={item.onPress}>
        <Div row justifyContent="center">
          <Text style={[isSm ? styles.titleSm : styles.title, item.titleColor]}>{item.title}</Text>
        </Div>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <Modal animationType="fade" transparent visible={isHelpModalVisible} statusBarTranslucent={false}
             hardwareAccelerated={false} onRequestClose={() => Context.setIsHelpModalVisible(false)}>
        <Div style={styles.container} h={height} w={width}>
          <Div style={styles.modalWrapper} row minW={isSm ? 240 : 560}>
            <Div position="absolute" top={isSm ? -28 : -67} right={isSm ? -28 : -67}>
              <TouchableOpacity onPress={() => Context.setIsHelpModalVisible(false)}>
                <Image source={require('../assets/img/circle-close-icon.png')} h={isSm ? 28 : 67} w={isSm ? 28 : 67}/>
              </TouchableOpacity>
            </Div>

            <FlatList
              data={!!user ? AUTHENTICATIONS_LINKS : UN_AUTHENTICATIONS_LINKS}
              renderItem={_renderItem}
              keyExtractor={(item) => item.title}
            />
          </Div>
        </Div>
      </Modal>

      <TutorialModal visible={showTutorial} isLg={!isSm} onResume={() => setShowTutorial(false)} logged={!!user}/>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.popupDropBg
  },
  modalWrapper: {
    backgroundColor: colors.bottomTabBg,
    borderWidth: 2,
    borderColor: colors.popupBorder,
    borderStyle: 'solid',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    paddingVertical: 15,
    position: 'relative',
    paddingHorizontal: 5
  },
  title: {
    fontFamily: 'Barlow-Bold',
    fontSize: 32,
    lineHeight: 72,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.whiteText
  },
  titleSm: {
    fontFamily: 'Barlow-Bold',
    fontSize: 16,
    lineHeight: 36,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.whiteText
  },
  closeIcon: {position: 'absolute', right: -67, top: -67},
  closeIconImage: {width: 67, height: 67}
});

export default HelpLinksModal;
