import React from 'react';
import {FlatList, Modal, StyleSheet, Text, TouchableOpacity, useWindowDimensions} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {Div, Image} from 'react-native-magnus';
import {colors} from '../../../../assets/styles/colors';
import {breakpoint} from '../../../../constants';

type IProps = { isOpen: boolean, onClose: () => void };

const PromoHelpLinkModal = (props: IProps) => {
  const {isOpen} = props;
  const navigation: any = useNavigation();
  const {width, height} = useWindowDimensions();
  const isSm = width < breakpoint;
  const MENUS = [
    {
      title: 'Free Play Terms\n & Conditions',
      onPress: () => {
        props.onClose();
        navigation.navigate('Promotions', {scrollTo: 'ELIGIBILITY_SECTION'});
      }
    },
    {
      title: 'Deposit Bonus Terms\n & Conditions',
      onPress: () => {
        props.onClose();
        navigation.navigate('Promotions', {scrollTo: 'PROMOTIONS_AND_PROMOTION_PERIOD'});
      }
    }
  ];

  const _renderItem = ({item}: any) => (
    <TouchableOpacity onPress={item.onPress}>
      <Div row justifyContent="center">
        <Text style={[isSm ? styles.titleSm : styles.title, item.titleColor]}>{item.title}</Text>
      </Div>
    </TouchableOpacity>
  );

  return (
    <Modal animationType="fade" transparent visible={isOpen} statusBarTranslucent={false}
           hardwareAccelerated={false} onRequestClose={props.onClose}>
      <Div style={styles.container} h={height} w={width}>
        <Div style={styles.modalWrapper} row minW={isSm ? 240 : 560} minH={160}>
          <Div position="absolute" top={isSm ? -28 : -67} right={isSm ? -28 : -67}>
            <TouchableOpacity onPress={props.onClose}>
              <Image
                source={require('../../../../assets/img/circle-close-icon.png')}
                h={isSm ? 28 : 67} w={isSm ? 28 : 67}
              />
            </TouchableOpacity>
          </Div>

          <FlatList
            data={MENUS}
            renderItem={_renderItem}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(item) => item.title}
            ItemSeparatorComponent={() => <Div h={23}/>}
          />
        </Div>
      </Div>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.popupDropBg
  },
  modalWrapper: {
    backgroundColor: colors.bottomTabBg,
    borderWidth: 2,
    borderColor: colors.popupBorder,
    borderStyle: 'solid',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    paddingVertical: 36,
    position: 'relative',
    paddingHorizontal: 5
  },
  title: {
    fontFamily: 'Barlow-Bold',
    fontSize: 32,
    lineHeight: 36,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.whiteText
  },
  titleSm: {
    fontFamily: 'Barlow-Bold',
    fontSize: 16,
    lineHeight: 20,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.whiteText
  },
  closeIcon: {position: 'absolute', right: -67, top: -67},
  closeIconImage: {width: 67, height: 67}
});

export default PromoHelpLinkModal;
