import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {Modal, View, Pressable, Platform, Text} from 'react-native';
import MainStyle from "../../MainStyle";
import * as faceapi from 'face-api.js';
import {Camera, CameraType} from 'expo-camera';
import {Ionicons} from '@expo/vector-icons';
import {colors} from "../assets/styles/colors";

type Props = {
  cameraModalVisible: boolean, onRequestClose: (() => void), takePicture: () => void,
  videoRef: MutableRefObject<Camera | null>
};

const CameraModal = (props: Props) => {
  const {cameraModalVisible, videoRef} = props;
  const [face, setFace] = useState<faceapi.FaceDetection | undefined>();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const video = useRef<HTMLVideoElement | null>(null);

  const startVideo = () => {
    if (Platform.OS === 'web') {
      video.current = document.getElementsByTagName('video')[0];

      if (video.current) {
        intervalRef.current = setInterval(async () => {
          const options = new faceapi.TinyFaceDetectorOptions({inputSize: 320, scoreThreshold: 0.7});
          const detection = await faceapi.detectSingleFace(video.current!, options);
          setFace(detection);
        }, 100);
      }
    }
  };

  const stopVideo = () => {
    if (video.current && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (cameraModalVisible) startVideo();
    else stopVideo();
  }, [cameraModalVisible]);

  return (
    <Modal animationType="slide" visible={cameraModalVisible} onRequestClose={props.onRequestClose}>
      <View style={[MainStyle.bc_black, MainStyle.flex1, MainStyle.p_10]}>
        <View style={[MainStyle.column, MainStyle.b_r_40, MainStyle.o_hidden, MainStyle.relative, MainStyle.flex1, MainStyle.m_b_10]}>
          <Ionicons
            name="close-circle" size={40} color="white" style={[MainStyle.m_t_20, MainStyle.m_l_21]}
            onPress={props.onRequestClose}
          />

          <Camera type={CameraType.front} ref={videoRef} style={styles.camera}/>

          <View style={styles.oval_wrap}>
            <View style={[styles.oval, {borderColor: !!face ? 'white' : 'gray'}]}/>
          </View>

          <View style={[MainStyle.relative, MainStyle.a_i_c]}>
            {!!face ? (
              <Ionicons name="checkmark-circle" size={24} color={colors.activeText} style={[MainStyle.absolute, MainStyle.z_i_2, MainStyle.t__28]}/>
            ) : (
              <Ionicons name="close-circle" size={24} color={colors.warningText} style={[MainStyle.absolute, MainStyle.z_i_2, MainStyle.t__28]}/>
            )}

            <Pressable style={[...styles.btn, {backgroundColor: !!face ? 'white' : 'gray'}]} onPress={props.takePicture}
                       disabled={!face}>
              <View style={[MainStyle.b_w_2, MainStyle.flex1, MainStyle.m_5, MainStyle.b_r_40]}/>
            </Pressable>
          </View>
        </View>

        <Text style={[MainStyle.c_white, MainStyle.t_a_c, MainStyle.f_s_18]}>
          Try shifting angles or moving to a space with better lighting.
        </Text>
      </View>
    </Modal>
  );
};

const styles = {
  oval_wrap: [MainStyle.flex1, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.relative],
  oval: [MainStyle.w_250, MainStyle.h_350, MainStyle.b_r_125, MainStyle.b_w_5],
  btn: [MainStyle.w_60, MainStyle.h_60, MainStyle.b_r_40, MainStyle.m_b_10],
  camera: [MainStyle.absolute, MainStyle.t_0, MainStyle.l_0, MainStyle.full_h, MainStyle.full_w, MainStyle.z_i__1]
};

export default React.memo(CameraModal);
