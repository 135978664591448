import React, {useContext, useEffect, useRef, useState} from 'react';
import {View, Image, BackHandler, ScrollView, useWindowDimensions, Platform, ImageBackground, Text} from 'react-native';
import Step1 from './steps/ContactInfo';
import Step2 from './steps/TermsConditions';
import MainStyle from '../../../../MainStyle';
import IdVerification from "./steps/IdVerification";
import kiosk_logo_RGB_1row from "../../../assets/img/kiosk_logo_RGB_1row.png";
import {StoreContext} from "../../../components/StoreContextProvider";
import {LANDING_PAGE_URL, breakpoint} from "../../../constants";
import {HelpLinkModalTrigger} from '../../../modals/HelpLinksModal';
import bg from "../../../assets/img/Loading login page-001d.jpg";
import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from "@react-navigation/native";

type Props = { route: any };

const SignUpScreen = ({route}: Props) => {
  const [step, setStep] = useState(1);
  const backhandlerRef = useRef<any>();
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const {width} = useWindowDimensions();
  const isLg = width >= breakpoint;
  const navigation: any = useNavigation();

  const goToCSR = () => {
    if (Platform.OS === 'web') window.open('https://smgames.atlassian.net/servicedesk/customer/portal/3', isLg ? '_self' : '_blank');
  };

  const modalClose = () => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
  };

  const onCancel = () => {
    Context.setCancelModalConfig({
      visible: true, title: 'CANCEL SIGN UP', subtitle: 'Are you sure you want to exit?',
      secondary: step === 1 ? 'All information entered earlier will be deleted' : 'All information entered earlier will be saved',
      onExit: onExit, onResume: modalClose
    });
    return true;
  };

  const onExit = async () => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
    setStep(1);

    if (backhandlerRef?.current?.remove) backhandlerRef.current.remove();

    Context.logout().then(() => {
      if (Platform.OS === 'web') {
        window.open(LANDING_PAGE_URL, '_self');
      }
    });
  };

  const GetUser = () => {
    Context.getUser().then(() => {
      if (backhandlerRef?.current?.remove) backhandlerRef.current.remove();
      window.open('/challenges?fromRegistrationPage=true', '_self');
    });
  };

  useEffect(() => {
    if (route.params?.step) setStep(+route.params?.step);

    const backHandler = BackHandler.addEventListener('hardwareBackPress', onCancel);
    backhandlerRef.current = backHandler;

    return () => {
      backHandler.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params?.step]);

  return (
    <>
      <ImageBackground source={bg} style={MainStyle.flex1} imageStyle={{backgroundColor: '#132038'}}>
        <HelpLinkModalTrigger/>

        <ScrollView contentContainerStyle={MainStyle.min_h_full} keyboardShouldPersistTaps="handled">
          <View style={[MainStyle.w_390, MainStyle.m_x_auto, MainStyle.full_h]}>
            <View style={[MainStyle.m_t_17, MainStyle.row, MainStyle.j_c_s_between, MainStyle.a_i_c, MainStyle.m_b_32]}>
              <Ionicons name="chevron-back" size={24} color="white" onPress={onCancel}/>
              <Image source={kiosk_logo_RGB_1row} style={[MainStyle.h_32, MainStyle.w_190]}/>
              <View style={MainStyle.w_20}/>
            </View>

            <View style={[MainStyle.row, MainStyle.j_c_s_between, MainStyle.m_b_13]}>
              <Text style={styles.title}>
                {step === 1 ? 'Contact Information' : step === 2 ? 'Terms & Conditions' : 'Photo'}
              </Text>

              <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_24, MainStyle.l_h_20]}>
                {step}/<Text style={MainStyle.c_darkText}>3</Text>
              </Text>
            </View>

            <View style={[MainStyle.h_4, MainStyle.bg_filter_chip2, MainStyle.m_b_60]}>
              <View style={[...styles.progress, {width: `${step * 33.3}%`}]}/>
            </View>

            {step === 1 && (
              <Step1 goNext={() => navigation.navigate({name: 'Register', params: {step: 2}})} onCancel={onCancel}/>
            )}

            {step === 2 && (
              <Step2 goNext={() => navigation.navigate({name: 'Register', params: {step: 3}})} goBack={onCancel}/>
            )}

            {step === 3 && (
              <IdVerification
                goNextStep={GetUser}
                goCsr={goToCSR}
                onCancel={onCancel}
                title="Please take a clear photo of yourself"
                type="photo"
              />
            )}
          </View>
        </ScrollView>
      </ImageBackground>
    </>
  );
};

const styles = {
  title: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_16, MainStyle.l_h_20],
  progress: [MainStyle.bg_filter_chip, MainStyle.b_r_200, MainStyle.full_h]
};

export default SignUpScreen;
