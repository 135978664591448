import React, {useCallback, useContext} from "react";
import SupportGuideHeader from "../components/ SupportGuideHeader";
import {useNavigation} from "@react-navigation/native";
import {StoreContext} from "../components/StoreContextProvider";
import {useWindowDimensions} from "react-native";
import {breakpoint} from "../constants";
import {colors} from "../assets/styles/colors";
import SupportGuideButtonMenu from "../components/SupportGuideButtonMenu";
import TermsAndConditionsContent from "../components/TermsAndConditionsContent";

const TermsAndConditionsScreen = () => {
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {width} = useWindowDimensions();
  const isSm = width < breakpoint;

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  return (
    <>
      <SupportGuideHeader title="Terms and Conditions" onExit={onExit}/>

      <TermsAndConditionsContent bg={colors.bg_contest}/>

      <SupportGuideButtonMenu isSm={isSm} onExit={onExit}/>
    </>
  );
};

export default TermsAndConditionsScreen;
