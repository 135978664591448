import React, {useContext, useEffect, useState} from 'react';
import {Pressable, Text, View} from 'react-native';
import {Email, Name, Phone} from './SubSteps';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import MainStyle from "../../../../../../MainStyle";
import Button from "../../../../../components/Button";
import {StoreContext} from "../../../../../components/StoreContextProvider";
import {phoneUtil, smgEnvironment} from "../../../../../constants";
import {profanity} from '@2toad/profanity';
import ServiceClient from "../../../../../utils/ServiceClient";
import OtpModal from "../../../../../modals/OtpModal";
import {useNavigation, useRoute} from "@react-navigation/native";
import {registerUserPayloadType} from "../../../../../types";
import {MaterialIcons} from "@expo/vector-icons";

const initialValues: registerUserPayloadType = {
  email: '', smsCode: '', phone: '', username: '', handedness: '', handicap: 'N/A',
  dateOfBirth: '', firstname: '', lastname: ''
};

type Props = { goNext: () => void, onCancel: () => void };

export const ContactInfo = (props: Props) => {
  const isTestEnv = smgEnvironment === 'test';
  const [contactInfoStep, setContactInfoStep] = useState(1);
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email().required(), smsCode: Yup.string(), phone: Yup.string(), handicap: Yup.string(),
      username: Yup.string().required('Player Name is a required field'), dateOfBirth: Yup.string().required(),
      handedness: Yup.string().required('Handedness is a required field'), firstname: Yup.string().required(),
      lastname: Yup.string().required()
    }),
    onSubmit: (values) => {
      if (contactInfoStep === 4) {
        if (profanity.exists(values.username)) {
          formik.setFieldError('username', 'The selected user name is not allowed. Please choose another username');

        } else RegisterUser();
      }
    },
    validate(values) {
      if (isTestEnv && !values.handedness.length) {
        return {handedness: 'Handedness is required'};
      }
    }
  });
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const [phoneVerificationDialog, setPhoneVerificationDialog] = useState(false);
  const route = useRoute();
  const navigation: any = useNavigation();
  const [otpType, setOtpType] = useState('phone');

  const phoneVerificationHandler = (code?: string) => {
    setPhoneVerificationDialog(false);

    if (code) {
      formik.setFieldValue('smsCode', code);
      setContactInfoStep(4);
      untouchedForm();
    }
  };

  const RegisterUser = () => {
    Context.setLoadingOverlay(true);

    Context.registerUser(formik.values).then(() => {
      Context.setLoadingOverlay(false);
      props.goNext();

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('registerUser error', err);

      if (err && err.message) {
        Context.setCancelModalConfig({
          visible: true, subtitle: err.message, onExit: onResume, title: 'Error', leftBtnText: 'OK'
        });
      }
    });
  };

  const untouchedForm = () => {
    formik.setTouched({phone: false, username: false, firstname: false, lastname: false});
  };

  const requestSmsCode = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.requestSmsCode({phone: formik.values.phone}).then(() => {
      Context.setLoadingOverlay(false);
      setPhoneVerificationDialog(true);

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('requestSmsCode err', err);

      if (err && err.message) {
        Context.setCancelModalConfig({
          visible: true, subtitle: err.message, onExit: onResume, title: 'Error', leftBtnText: 'OK'
        });
      }
    });
  };

  const next = () => {
    formik.submitForm().then(async () => {
      const {errors, values} = formik;

      if (contactInfoStep === 1 && !errors.email) {
        setContactInfoStep(2);
        untouchedForm();

      } else if (contactInfoStep === 2) {
        setContactInfoStep(3);
        untouchedForm();

      } else if (contactInfoStep === 3 && !errors.phone) {
        let isValid = false;

        try {
          isValid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(values.phone));

        } catch (error) {
          console.log('isValidNumber error', error);
        }

        if (isValid) {
          if (formik.values.smsCode) {
            setContactInfoStep(4);
            untouchedForm();

          } else {
            requestSmsCode();
          }

        } else {
          formik.setFieldError('phone', 'Invalid format');
        }
      }
    });
  };

  const onResume = () => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
  };

  const onCancel = () => {
    if (contactInfoStep === 1) props.onCancel();
    else setContactInfoStep(contactInfoStep - 1);
  };

  useEffect(() => {
    formik.setFieldValue('smsCode', '');
  }, [formik.values.phone]);

  useEffect(() => {
    (async () => {
      const params: any = route.params;

      if (params.email) {
        await formik.setFieldValue('email', params.email);

      } else {
        // set form to dirty
        // we need this for showing an error message
        // if the player tries to go to the next page without changing any field
        await formik.setFieldValue('password', '1');
        await formik.setFieldValue('password', '');
      }
    })();
  }, []);

  return (
    <>
      <Text style={styles.title}>Sign Up</Text>

      <View style={MainStyle.flex1}>
        {contactInfoStep === 1 ? (
          <Email formik={formik}/>
        ) : contactInfoStep === 2 ? (
          <>
            <View style={[MainStyle.w_213, MainStyle.m_x_auto, MainStyle.m_b_42]}>
              <Text style={[...styles.text, MainStyle.m_b_18]}>
                When signing in, we will send you a one-time password.
              </Text>
              <Text style={[...styles.text, MainStyle.Barlow700]}>
                Select which method you would like to receive the password.
              </Text>
            </View>

            <View style={[MainStyle.m_b_70, MainStyle.m_x_auto, MainStyle.w_155]}>
              <Pressable style={[MainStyle.row, MainStyle.a_i_c, MainStyle.m_b_9]} onPress={() => setOtpType('phone')}>
                <MaterialIcons name={`radio-button-${otpType === 'phone' ? 'on' : 'off'}`} size={18} color="white"/>
                <Text style={[MainStyle.m_l_15, MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_18]}>
                  Text Message
                </Text>
              </Pressable>

              {/*<Pressable style={[MainStyle.row, MainStyle.a_i_c]} onPress={() => setOtpType('email')}>*/}
              {/*  <MaterialIcons name={`radio-button-${otpType === 'email' ? 'on' : 'off'}`} size={18} color="white"/>*/}
              {/*  <Text style={[MainStyle.m_l_15, MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_17]}>*/}
              {/*    Email*/}
              {/*  </Text>*/}
              {/*</Pressable>*/}
            </View>

            <Text style={styles.message}>
              {`Message and data rates may apply.\nWhen you opt in to the service, we will send you\na one-time passcode text message per login attempt.`}
            </Text>
          </>
        ) : contactInfoStep === 3 ? (
          <Phone formik={formik}/>
        ) : contactInfoStep === 4 ? (
          <Name formik={formik}/>
        ) : null}
      </View>

      <Text style={[...styles.TC, MainStyle.t_a_c, MainStyle.m_b_46]}>
        <Text style={MainStyle.c_new_green_text} onPress={() => navigation.navigate('TermsAndConditions')}>Terms and
          Conditions</Text> and <Text style={MainStyle.c_new_green_text}
                                      onPress={() => navigation.navigate('PrivacyPolicy')}>Privacy Policy</Text>
      </Text>

      <View style={[MainStyle.h_63, MainStyle.relative, MainStyle.z_i__1, MainStyle.m_b_60]}>
        <View style={[MainStyle.absolute, MainStyle.full_w, MainStyle.row, MainStyle.j_c_s_between]}>
          <Button text="Back" w={190} bg="transparent" borderWidth={1} borderColor="white" onPress={onCancel}
                  color="white"/>
          <Button text="Next" w={190} onPress={next}/>
        </View>
      </View>

      <OtpModal
        visible={phoneVerificationDialog}
        setVisible={phoneVerificationHandler}
        phone={formik.values.phone}
        email={formik.values.email}
        goBackBtn
      />
    </>
  );
};

const styles = {
  TC: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_16],
  text: [MainStyle.t_a_c, MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_18],
  message: [MainStyle.c_white, MainStyle.o_05, MainStyle.t_a_c, MainStyle.f_s_16, MainStyle.Barlow500],
  title: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_32, MainStyle.l_h_26, MainStyle.t_a_c,
    MainStyle.m_b_57
  ]
};

export default React.memo(ContactInfo);
