import React, {useContext, useState} from 'react';
import {Image, Platform, ScrollView, Text, View, Pressable, ImageBackground} from 'react-native';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import MainStyle from '../../../../../MainStyle';
import {StoreContext} from '../../../../components/StoreContextProvider';
import kiosk_logo_RGB_1row from '../../../../assets/img/kiosk_logo_RGB_1row.png';
import {useFormik} from 'formik';
import {LANDING_PAGE_URL} from "../../../../constants";
import {HelpLinkModalTrigger} from '../../../../modals/HelpLinksModal';
import ServiceClient from "../../../../utils/ServiceClient";
import EmailNotExistModal from "../../../../modals/EmailNotExistModal";
import OtpModal from "../../../../modals/OtpModal";
import bg from '../../../../assets/img/Loading login page-001d.jpg';
import {useNavigation} from "@react-navigation/native";

const LoginWithPin = () => {
  const [showEmailNotExistDialog, setShowEmailNotExistDialog] = useState(false);
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const [phoneVerificationDialog, setPhoneVerificationDialog] = useState(false);
  const formik = useFormik({
    initialValues: {email: ''}, validationSchema: Yup.object({email: Yup.string().email().required()}),
    onSubmit: (values) => {
      checkUserEmailExists(values.email);
    }
  });
  const navigation: any = useNavigation();

  const errorModalClose = () => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
  };

  const checkUserEmailExists = (email: string) => {
    Context.setLoadingOverlay(true);

    ServiceClient.checkUserEmailExists(email).then(isExists => {
      if (isExists) {
        requestSmsCode(email);

      } else {
        Context.setLoadingOverlay(false);
        setShowEmailNotExistDialog(true);
      }

    }).catch(err => {
      console.log('checkUserEmailExists err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: errorModalClose
      });
    });
  };

  const requestSmsCode = (email: string) => {
    ServiceClient.requestSmsCode({email}).then(() => {
      Context.setLoadingOverlay(false);
      setPhoneVerificationDialog(true);

    }).catch(err => {
      console.log('requestSmsCode err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: errorModalClose
      });
    });
  };

  const goBack = () => {
    // navigation.reset({index: 0, routes: [{name: 'Splash'}]});
    if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
  };

  const getFieldError = (key: string): boolean | string | undefined => {
    return formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors];
  };

  return (
    <ImageBackground source={bg} style={MainStyle.flex1} imageStyle={{backgroundColor: '#132038'}}>
      <HelpLinkModalTrigger/>

      <ScrollView contentContainerStyle={[MainStyle.full_h, MainStyle.full_w]}>
        <Pressable style={[MainStyle.m_t_17, MainStyle.m_x_auto, MainStyle.m_b_129]} onPress={goBack}>
          <Image source={kiosk_logo_RGB_1row} style={[MainStyle.h_32, MainStyle.w_199]}/>
        </Pressable>

        <View style={[MainStyle.flex1, MainStyle.a_i_c]}>
          <Text style={styles.title}>Sign In</Text>

          <Input
            w={390} placeholder="Enter Email" label="EMAIL ADDRESS" type="email-address"
            value={formik.values.email} onChangeText={formik.handleChange('email')}
            onBlur={formik.handleBlur('email')} error={getFieldError('email')}
          />
        </View>

        <Text style={[...styles.text, MainStyle.t_a_c, MainStyle.m_b_46]}>
          <Text style={MainStyle.c_new_green_text} onPress={() => navigation.navigate('TermsAndConditions')}>Terms and
            Conditions</Text> and <Text style={MainStyle.c_new_green_text}
                                        onPress={() => navigation.navigate('PrivacyPolicy')}>Privacy Policy</Text>
        </Text>

        <Button text="Let's Go!" onPress={() => formik.handleSubmit()} w={389} mb={86} mx="auto"/>
      </ScrollView>

      <EmailNotExistModal
        visible={showEmailNotExistDialog}
        onClose={() => setShowEmailNotExistDialog(false)}
        email={formik.values.email}
      />

      <OtpModal
        visible={phoneVerificationDialog}
        setVisible={() => setPhoneVerificationDialog(false)}
        email={formik.values.email}
      />
    </ImageBackground>
  );
};

const styles = {
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_32, MainStyle.l_h_26, MainStyle.m_b_57],
  text: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_16]
};

export default LoginWithPin;
