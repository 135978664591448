import React, {useContext, useLayoutEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, useWindowDimensions} from 'react-native';
import {Div} from 'react-native-magnus';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Promo, User} from '../../../../generated/smg_service_pb';
import MainStyle from '../../../../MainStyle';
import ProfileHeader from '../../../components/ProfileHeader';
import {StoreContext} from '../../../components/StoreContextProvider';
import {LANDING_PAGE_URL, SESSION_EXPIRE_TIME, tabletBreakpoint} from '../../../constants';
import ServiceClient from '../../../utils/ServiceClient';
import PromoItem from './components/PromoItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import {Platform} from 'react-native';
import {useNavigation} from "@react-navigation/native";
import {colors} from "../../../assets/styles/colors";
import BottomNavigation from '../../../components/BottomNavigation';

const PromosScreen = () => {
  const Context = useContext(StoreContext);
  const {user, cancelModalConfig} = Context;
  const insets = useSafeAreaInsets();
  const {width} = useWindowDimensions();
  const isSm = tabletBreakpoint > width;
  const [promos, setPromos] = useState<Promo.AsObject[]>([]);
  const navigation: any = useNavigation();

  const filterPromos = async (userId: number) => {
    try {
      Context.setLoadingOverlay(true);
      const res = await ServiceClient.getPromos(userId);
      Context.setLoadingOverlay(false);
      setPromos(res);

    } catch (err: any) {
      Context.setLoadingOverlay(false);
      Context.setCancelModalConfig({
        visible: true, subtitle: err.message || 'Request Promos Error', title: 'Error', leftBtnText: 'OK',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 1}});
        }
      });
    }
  };

  useLayoutEffect(() => {
    if (!user) {
      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            Context.setLoadingOverlay(false);
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);

            } catch (e: any) {
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
              });
            }
          }

          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
              Context.setLoadingOverlay(false);
            });

          } else filterPromos(curUser.userId);
        }
      })();

    } else filterPromos(user.userId);
  }, []);

  return (
    <>
      <Div flex={1} pt={insets.top} position='relative'>
        <ScrollView contentContainerStyle={MainStyle.min_h_full}>
          <ProfileHeader mb={25}/>

          <Div bg={colors.blueText} style={styles.edge} h={isSm ? 32 : 50} pr={isSm ? 107 : 117} alignItems="flex-end"
               w={isSm ? '60.28%' : '52.08%'} justifyContent="center">
            <Text style={isSm ? styles.edgeTitleSm : styles.edgeTitle}>Promos</Text>
          </Div>

          <Div maxW={isSm ? 250 : 400} row justifyContent="center" mx="auto" pt={isSm ? 18 : 22} pb={isSm ? 18 : 20}>
            <Text style={[isSm ? styles.subTitleSm : styles.subTitle, MainStyle.Barlow700]}>
              Select a promo to see its terms and conditions
            </Text>
          </Div>

          {promos.length > 0 && (
            <Div bg={colors.amount} w={isSm ? 310 : 460} mx="auto" mb={36} rounded={isSm ? 8 : 16} alignItems="center"
                 p={isSm ? 10 : 15}>
              {promos.map((promo, i) => (
                <React.Fragment key={promo.id}>
                  <PromoItem data={promo}/>
                  {i !== promos.length && <Div h={isSm ? 5 : 10} w='100%'/>}
                </React.Fragment>
              ))}
            </Div>
          )}
        </ScrollView>

        <Div alignItems="center" maxW={1125} mx="auto" w="100%">
          <BottomNavigation page="promos"/>
        </Div>
      </Div>
    </>
  );
};

export default PromosScreen;

const styles = StyleSheet.create({
  edge: {
    backgroundColor: colors.darkBlue,
    borderWidth: 1,
    borderColor: colors.darkBlue,
    borderStyle: 'solid',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 0,
  },
  edgeTitle: {
    fontFamily: 'Barlow-Bold',
    fontStyle: 'italic',
    fontWeight: '800',
    fontSize: 32,
    lineHeight: 44,
    textTransform: 'uppercase',
    color: colors.white,
  },
  edgeTitleSm: {
    fontFamily: 'Barlow-Bold',
    fontStyle: 'italic',
    fontWeight: '800',
    fontSize: 18,
    lineHeight: 25,
    textTransform: 'uppercase',
    color: colors.white,
  },
  subTitle: {
    fontSize: 24,
    lineHeight: 28,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.whiteText
  },
  subTitleSm: {
    fontSize: 16,
    lineHeight: 19,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.whiteText
  }
});
