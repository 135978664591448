import React, {useEffect, useLayoutEffect} from 'react';
import 'react-native-gesture-handler';
import {Text, Platform, SafeAreaView, Linking} from 'react-native';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import {ThemeProvider as MagnusThemeProvider} from 'react-native-magnus';
import {Main} from './src/components/Main';
import {StoreContextProvider} from './src/components/StoreContextProvider';
import MainStyle from "./MainStyle";
import {LinearGradient} from "expo-linear-gradient";
import {useFonts} from 'expo-font';
import {FixForTournamentFlatListAlignment, smgEProtectUrl} from "./src/constants";
import * as faceapi from "face-api.js";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

// @ts-ignore
Text.defaultProps = Text.defaultProps || {};
// @ts-ignore
Text.defaultProps.allowFontScaling = false;

const App = () => {
  let [fontsLoaded] = useFonts({
    'Barlow-Regular': require('./src/assets/fonts/Barlow-Regular.ttf'),
    'Barlow-Medium': require('./src/assets/fonts/Barlow-Medium.ttf'),
    'Barlow-SemiBold': require('./src/assets/fonts/Barlow-SemiBold.ttf'),
    'Barlow-Bold': require('./src/assets/fonts/Barlow-Bold.ttf')
  });
  const gradient = [
    'black', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent'
  ];

  useEffect(() => {
    (async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');

      if (Platform.OS === 'web') {
        const url = await Linking.getInitialURL();

        if (url && url.startsWith('http://') && !url.includes('localhost')) {
          const httpsURL = url.replace('http://', 'https://');
          window.open(httpsURL, '_self');
        }

        // adding eProtect lib
        const tag = document.createElement("script");
        tag.src = `${smgEProtectUrl}/eProtect/eProtect-api3.js`;
        document.getElementsByTagName("head")[0].appendChild(tag);

        // adding ApplePay SDK
        const scriptTag = document.createElement("script");
        scriptTag.src = 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js';
        document.getElementsByTagName("head")[0].appendChild(scriptTag);
      }
    })();
  }, []);

  useLayoutEffect(() => {
    // if (Platform.OS === 'web') document.body.insertAdjacentHTML('beforeend', CustomScrollbarStyle);
    if (Platform.OS === 'web') document.body.insertAdjacentHTML('beforeend', FixForTournamentFlatListAlignment);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <LinearGradient style={[MainStyle.flex1, MainStyle.bg_main, MainStyle.fix_gradient_and_modal_error_in_landscape]}
                      colors={gradient}>
        <SafeAreaView style={MainStyle.flex1}>
          <StoreContextProvider>
            <MagnusThemeProvider>
              <Main/>
            </MagnusThemeProvider>
          </StoreContextProvider>
        </SafeAreaView>
      </LinearGradient>
    </LocalizationProvider>
  );
};

if (Platform.OS === 'web') serviceWorkerRegistration.register();

export default App;
