import React, {useLayoutEffect, useRef, useState} from "react";
import {Text, TextInput, View, Pressable, Platform} from "react-native";
import {defaultCountries, FlagEmoji, parseCountry, usePhoneInput} from 'react-international-phone';
import MainStyle from "../../MainStyle";
import {formatWithMask, useMaskedInputProps} from "react-native-mask-input";
import {outline_0} from "../constants";
import SelectModal from "../modals/SelectModal";

const Countries = defaultCountries.map(el => el);

const us = Countries.splice(Countries.findIndex(el => {
  const [, iso2] = el;
  return iso2 === 'us';
}), 1)[0];

const ca = Countries.splice(Countries.findIndex(el => {
  const [, iso2] = el;
  return iso2 === 'ca';
}), 1)[0];

// Add the United States and Canada to the top.
Countries.unshift(us, ca);

type Props = {
  value: string, onChangeText: (val: string) => void, label: string, w?: string | number, placeholder: string,
  onBlur?: (e: any) => void, error?: string | boolean, mb?: number, withoutMask?: boolean
};

const PhoneInput = (props: Props) => {
  const {value, label, w, placeholder, error, mb, withoutMask} = props;
  const wrapStyle: any = {};
  const [country, setCountry] = useState(parseCountry(Countries[0]));
  const mask = country.format ? ['+', ...country.dialCode.split(''), ' ', ...country.format.split('').map(str => str === '.' ? /\d/ : str)] : ['+', ...country.dialCode.split(''), ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  const numberMask = country.format ? ['+', ...country.dialCode.split(''), ' ', ...country.format.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').split('').map(str => str === '.' ? /\d/ : str)] : ['+', ...country.dialCode.split(''), ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  const phoneMaskedInputProps = useMaskedInputProps({
    value, onChangeText: props.onChangeText, mask: withoutMask ? numberMask : mask
  });
  const [visible, setVisible] = useState(false);
  const ref = useRef<any>();
  const CountryGuess = usePhoneInput({defaultCountry: 'us', value});

  if (w) wrapStyle.width = w;
  if (mb) wrapStyle.marginBottom = mb;

  const onChangeText = (text: string) => {
    const {unmasked: phone} = formatWithMask({text: text, mask});
    props.onChangeText(`+${country.dialCode}${phone}`);
  };

  const onFocus = () => {
    if (Platform.OS === 'web') {
      const {maxTouchPoints, platform} = window.navigator;

      if (maxTouchPoints && platform === 'Win32') {
        ref.current?.scrollIntoView({behavior: 'instant', block: 'center', inline: 'center'});
      }
    }
  };

  const onResume = (val?: string) => {
    setVisible(false);
    if (!val) return;

    const find = Countries.find(el => {
      const [, iso2] = el;
      return iso2 === val;
    });

    if (find) {
      setCountry(parseCountry(find));
      props.onChangeText('');
    }
  };

  useLayoutEffect(() => {
    if (value) {
      const findCountry = Countries.find(el => {
        const [, iso2] = el;
        return iso2 === CountryGuess.country;
      });

      if (findCountry) setCountry(parseCountry(findCountry));
    }
  }, []);

  return (
    <View style={wrapStyle}>
      <Text style={styles.label_s}>{label}</Text>

      <View style={[MainStyle.row, MainStyle.h_66, MainStyle.b_r_200, MainStyle.b_w_1, MainStyle.b_c_cardInfoBorder, MainStyle.bg_settings, MainStyle.relative, MainStyle.a_i_c, error ? MainStyle.b_c_warn : {}]} ref={ref}>
        <Pressable style={styles.icon} onPress={() => setVisible(true)}>
          <FlagEmoji iso2={country.iso2} style={{width: 33, height: 33}}/>
        </Pressable>

        <TextInput
          style={styles.input}
          keyboardType="phone-pad"
          onBlur={props.onBlur}
          value={phoneMaskedInputProps.value}
          onChangeText={onChangeText}
          placeholder={`+${country.dialCode} ${placeholder}`}
          onFocus={onFocus}
        />
      </View>

      <SelectModal visible={visible} onResume={onResume} isLg={false} value={country.iso2} countries={Countries}/>
    </View>
  );
};

const styles = {
  label_s: [
    MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.t_a_c, MainStyle.flex1,
    MainStyle.o_05
  ],
  icon: [
    MainStyle.m_l_32, MainStyle.w_24, MainStyle.h_24, MainStyle.b_r_20, MainStyle.o_hidden, MainStyle.a_i_c,
    MainStyle.j_c_c
  ],
  input: [
    MainStyle.m_l_8, MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_24, outline_0,
    MainStyle.flex1
  ]
};

export default React.memo(PhoneInput);
