import React, {useContext, useLayoutEffect, useState} from 'react';
import {View, Text, Pressable, LayoutChangeEvent} from 'react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";
import MainStyle from "../../../../../../MainStyle";
import Button from "../../../../../components/Button";
import {StoreContext} from "../../../../../components/StoreContextProvider";
import Checkbox from "../../../../../components/Checkbox";
import {useNavigation} from "@react-navigation/native";
import ServiceClient from "../../../../../utils/ServiceClient";
import TermsAndConditionsContent from '../../../../../components/TermsAndConditionsContent';
import {colors} from "../../../../../assets/styles/colors";

type Props = { goNext: () => void, goBack: () => void };

const TermsConditions = (props: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const Context = useContext(StoreContext);
  const {cancelModalConfig, session} = Context;
  const navigation: any = useNavigation();
  const [height, setHeight] = useState(0);
  const [step, setStep] = useState(1);

  const onLayout = (event: LayoutChangeEvent) => {
    setHeight(event.nativeEvent.layout.height);
  };

  const onResume = () => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
  };

  const AcceptTnc = () => {
    if (step === 1 && isChecked) {
      setIsChecked(false);
      setStep(2);

    } else if (step === 2 && isChecked) {
      Context.setLoadingOverlay(true);

      ServiceClient.acceptTnc().then(() => {
        Context.setLoadingOverlay(false);
        props.goNext();

      }).catch(err => {
        console.log('acceptTnc err', err);
        Context.setLoadingOverlay(false);

        if (err && err.message) {
          Context.setCancelModalConfig({
            visible: true, subtitle: err.message, onExit: onResume, title: 'Error', leftBtnText: 'OK'
          });
        }
      });

    } else {
      Context.setCancelModalConfig({
        visible: true, subtitle: 'You need to accept TERMS & CONDITIONS', onExit: onResume, title: 'Error',
        leftBtnText: 'OK'
      });
    }
  };

  useLayoutEffect(() => {
    (async () => {
      if (!session) {
        const val = await AsyncStorage.getItem('session');

        if (val) {
          const session = JSON.parse(val);
          Context.setSession(session);

        } else {
          navigation.reset({index: 0, routes: [{name: 'Splash'}]});
        }
      }
    })();
  }, []);

  return (
    <View style={MainStyle.flex1}>
      <Text style={styles.title}>
        {step === 1 ? 'Terms & Conditions' : 'Health Information and Biometric Consent'}
      </Text>

      <View style={[MainStyle.min_h_164, MainStyle.m_b_37, MainStyle.flex1]} onLayout={onLayout}>
        {!!height && (
          <>
            {step === 1 ? <TermsAndConditionsContent height={height} px={20} size="small"/> : (
              <View style={[MainStyle.max_w_1120, MainStyle.m_x_auto, MainStyle.p_x_20]}>
                <Text
                  style={[MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.l_h_18, MainStyle.m_b_21, MainStyle.t_t_u]}>
                  As part of participating in SkillMoneyGames, we (Super Money Games Inc. dba SkillMoneyGames) and our
                  service providers collect certain health information, namely, facial recognition biometrics. We use
                  this information to provide you with our services, including to verify your identity and for fraud
                  prevention purposes. We will retain your biometric identifier locally for 30 days and on our servers
                  for 7 years. For more information on our data processing practices and any rights you may have, please
                  see our Privacy Policy at <Text style={styles.small_link}
                                                  onPress={() => navigation.navigate('PrivacyPolicy')}>app.skillmoney.com/privacy</Text>.
                  Please note that Topgolf International, Inc. does not control or receive your biometric information.
                </Text>
              </View>
            )}
          </>
        )}
      </View>

      <Pressable style={[MainStyle.row, MainStyle.m_b_40, MainStyle.m_l_20, MainStyle.a_i_start]}
                 onPress={() => setIsChecked(!isChecked)}>
        <Checkbox value={isChecked} size={18} onValueChange={() => setIsChecked(!isChecked)}/>
        <Text style={styles.label_s}>
          {step === 1 ? 'I have read and agree to all Terms and Conditions' : 'Collection and Use. By checking this box and clicking “Next”, I consent to SkillMoneyGames collecting and using my biometric information to participate in SkillMoneyGames services and to the SkillMoneyGames Terms of Service at app.skillmoney.com/termsandconditions.'}
        </Text>
      </Pressable>

      <View style={[MainStyle.h_63, MainStyle.relative, MainStyle.z_i__1, MainStyle.m_b_86]}>
        <View style={[MainStyle.absolute, MainStyle.full_w, MainStyle.row, MainStyle.j_c_s_between]}>
          <Button text="Cancel" w={190} h={63} bg="transparent" borderWidth={1} borderColor="white"
                  onPress={props.goBack} fontSize={20} color="white"/>
          <Button text={step === 1 ? 'Proceed' : 'Next'} w={190} h={63} onPress={AcceptTnc} bg={colors.newGreenText}
                  color={colors.mainBackground} fontSize={20}/>
        </View>
      </View>
    </View>
  );
};

const styles = {
  title: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_32, MainStyle.l_h_26, MainStyle.t_a_c,
    MainStyle.m_b_57
  ],
  text_s: [MainStyle.f_w_400, MainStyle.f_s_12, MainStyle.c_white, MainStyle.max_w_600, MainStyle.m_x_8],
  label_s: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_16, MainStyle.m_l_17],
  btn_group_s: [MainStyle.w_160, MainStyle.h_90, MainStyle.j_c_s_between, MainStyle.m_b_50],
  small_link: [MainStyle.c_lightblue, MainStyle.Barlow700]
};

export default React.memo(TermsConditions);
