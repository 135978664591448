import React, {useContext} from "react";
import {Image, Text, useWindowDimensions, View, TouchableOpacity} from "react-native";
import kiosk_logo_RGB_1row from "../assets/img/kiosk_logo_RGB_1row.png";
import {breakpoint} from "../constants";
import MainStyle from "../../MainStyle";
import button_exit from "../assets/img/icons/button_exit.png";
import button_menu from "../assets/img/icons/button_menu.png";
import {StoreContext} from "./StoreContextProvider";
import {useSafeAreaInsets} from "react-native-safe-area-context";

type Props = {title: string, onExit: () => void};

const SupportGuideHeader = (props: Props) => {
  const {title} = props;
  const {width} = useWindowDimensions();
  const isLg = width >= breakpoint;
  const Context = useContext(StoreContext);
  const insets = useSafeAreaInsets();

  return (
    <>
      <View style={[...(isLg ? styles.logo_wrap_l : styles.logo_wrap_s), {paddingTop: insets.top}]}>
        {!isLg && <View style={styles.nav_btn_s}/>}

        <View style={MainStyle.flex1}>
          <Image source={kiosk_logo_RGB_1row} style={isLg ? styles.logo_l : styles.logo_s}/>
        </View>

        {!isLg && (
          <View>
            <TouchableOpacity onPress={props.onExit}>
              <Image source={button_exit} style={[...styles.nav_btn_s, MainStyle.m_b_4]}/>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => Context.setIsHelpModalVisible(true)}>
              <Image source={button_menu} style={styles.nav_btn_s}/>
            </TouchableOpacity>
          </View>
        )}
      </View>

      <Text style={isLg ? styles.subtitle_l : styles.subtitle_s}>Support Guide and FAQ</Text>

      <View style={isLg ? styles.footer_l : styles.footer_s}>
        {/*<Image source={button_previous} style={isLg ? styles.nav_btn_l : styles.nav_btn_s}/>*/}

        <Text style={isLg ? styles.title_l : styles.title_s}>{title}</Text>

        {/*<Image source={button_next} style={isLg ? styles.nav_btn_l : styles.nav_btn_s}/>*/}
      </View>

      <View style={[MainStyle.h_2, MainStyle.bc_blueText]}/>
    </>
  );
};

const styles = {
  logo_wrap_l: [MainStyle.row, MainStyle.m_b_17, MainStyle.m_t_48],
  logo_wrap_s: [MainStyle.row, MainStyle.m_b_34, MainStyle.m_t_12, MainStyle.a_i_c, MainStyle.j_c_c],
  logo_s: [MainStyle.h_37, MainStyle.w_225, MainStyle.rm_stretch, MainStyle.m_x_auto],
  logo_l: [MainStyle.h_80, MainStyle.w_496, MainStyle.rm_stretch, MainStyle.m_x_auto],
  subtitle_l: [
    MainStyle.f_w_400, MainStyle.f_s_32, MainStyle.l_h_70, MainStyle.t_a_c, MainStyle.t_t_u, MainStyle.c_white
  ],
  subtitle_s: [
    MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.l_h_28, MainStyle.t_a_c, MainStyle.t_t_u, MainStyle.c_white,
    MainStyle.m_b_2
  ],
  footer_l: [MainStyle.row, MainStyle.m_b_25, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.m_t__17],
  footer_s: [MainStyle.row, MainStyle.m_b_18, MainStyle.a_i_c, MainStyle.j_c_c],
  title_l: [
    MainStyle.f_w_700, MainStyle.f_s_48, MainStyle.l_h_80, MainStyle.t_a_c, MainStyle.t_t_u, MainStyle.c_lightblue,
    MainStyle.flex1
  ],
  title_s: [
    MainStyle.f_w_700, MainStyle.f_s_20, MainStyle.l_h_32, MainStyle.t_a_c, MainStyle.t_t_u, MainStyle.c_lightblue,
    MainStyle.flex1
  ],
  nav_btn_l: [MainStyle.w_48, MainStyle.h_48, MainStyle.m_x_56],
  nav_btn_s: [MainStyle.w_28, MainStyle.h_28, MainStyle.m_x_19]
};

export default React.memo(SupportGuideHeader);
