import React, {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
  Text, ScrollView, View, Platform, FlatList, NativeSyntheticEvent, NativeScrollEvent, ActivityIndicator
} from 'react-native';
import MainStyle from "../../../MainStyle";
import NewHeader from "../../components/NewHeader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import {LANDING_PAGE_URL, SESSION_EXPIRE_TIME} from "../../constants";
import {Tournament, TournamentPlayerBet, TournamentPlayerDetails, User} from "../../../generated/smg_service_pb";
import {StoreContext} from "../../components/StoreContextProvider";
import TournamentsFilter from "../../components/TournamentsFilter";
import TournamentItem from "../../components/TournamentItem";
import {AntDesign} from '@expo/vector-icons';
import {colors} from "../../assets/styles/colors";
import BottomNavigation from "../../components/BottomNavigation";
import ServiceClient from "../../utils/ServiceClient";
import {CancelModalProps} from "../../types";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import JoinTournamentButton from "../../components/JoinTournamentButton";
import ContinueAutoPlayModal from "../../modals/ContinueAutoPlay";

const ITEM_WEIGHT = 368;
const ITEM_MARGIN_HORIZONTAL = 5;
const TOTAL_ITEM_WEIGHT = ITEM_WEIGHT + ITEM_MARGIN_HORIZONTAL * 2;

const TournamentsListingScreen = () => {
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user, session, extendedUserData} = Context;
  const [filters, setFilters] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState('Start Date/Time - Soonest');
  const [tournaments, setTournaments] = useState<Tournament.AsObject[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const flatListRef = useRef<FlatList<Tournament.AsObject>>(null);
  const navigation: any = useNavigation();
  const isFocused = useIsFocused();
  const [isLoading, setIsLoading] = useState(false);
  const [continueAutoPlay, setContinueAutoPlay] = useState(false);

  const leaveTournament = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.leaveTournament(tournaments[activeIndex - 1].id).then(() => {
      listTournaments();

    }).catch(err => {
      console.log('leaveTournament err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const scrollToItem = (index: number) => {
    flatListRef.current?.scrollToIndex({animated: true, index: index});
  };

  const getItemLayout = (data: any, index: number) => (
    {length: TOTAL_ITEM_WEIGHT, offset: TOTAL_ITEM_WEIGHT * index, index}
  );

  const onScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width;
    setActiveIndex(Math.ceil((event.nativeEvent.contentOffset.x + slideSize / 2) / 378));
  }, []);

  const listTournaments = () => {
    ServiceClient.listTournaments(filters, sortBy).then(res => {
      Context.setLoadingOverlay(false);
      setIsLoading(false);
      setTournaments(res);
      if (extendedUserData.hasactivetournament && !user?.autoPlayEnabled) setContinueAutoPlay(true);

    }).catch(err => {
      console.log('listTournaments err', err);
      Context.setLoadingOverlay(false);
      setIsLoading(false);

      let conf: CancelModalProps = {
        visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: err.message,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          if (err.code !== 20020042) navigation.navigate({name: 'account', params: {step: 1}});
        }
      };

      Context.setCancelModalConfig(conf);
    });
  };

  const getUser = () => {
    Context.getUser().catch(err => {
      console.log('getUser err', err);
      setTimeout(() => {
        getUser();
      }, 5000);
    });
  };

  useEffect(() => {
    if (user?.kycStatus === 6) {
      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', leftBtnText: 'Cancel', rightBtnText: 'Contact Support',
        subtitle: 'Your Know Your Customer information and registration information do not match. Your account with Skill Money Games has been locked. Please contact support to resolve this issue.',
        onResume: () => {
          if (Platform.OS === 'web') {
            window.open('https://smgames.atlassian.net/servicedesk/customer/portal/3', '_self');
          }
        },
        onClose: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 0}});
        }
      });
    }
  }, [user?.kycStatus]);

  useEffect(() => {
    if (!user || !isFocused) return;

    listTournaments();

    const time = setTimeout(() => {
      getUser();
    }, 1000 * 10);

    return () => {
      clearTimeout(time);
    };
  }, [user, isFocused, filters, sortBy]);

  useEffect(() => {
    if (!isFocused) return;
    const time = setInterval(() => {
      (async () => {
        const lastActiveTime = await AsyncStorage.getItem('lastActiveTime');

        if (lastActiveTime && moment().diff(moment(lastActiveTime), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            setIsLoading(false);
          });
        }
      })();
    }, 1000 * 60);

    return () => {
      clearInterval(time);
    };
  }, [isFocused]);

  useLayoutEffect(() => {
    setIsLoading(true);

    if (!session || !user) {
      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);

            } catch (e: any) {
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
              });
            }
          }

          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            });

          } else console.log('user', curUser);
        }
      })();
    }
  }, []);

  return (
    <>
      <NewHeader pb={11}/>

      <ScrollView contentContainerStyle={MainStyle.flex1}>
        <Text style={styles.title}>Tournaments</Text>

        <TournamentsFilter
          filters={filters}
          filtersChange={val => setFilters(val)}
          sortBy={sortBy}
          sortChange={val => setSortBy(val)}
          mb={17}
        />

        {isLoading ? (
          <View style={[MainStyle.flex1, MainStyle.j_c_c, MainStyle.a_i_c]}>
            <ActivityIndicator color={colors.white} size="large"/>
          </View>
        ) : !!tournaments.length ? (
          <>
            {tournaments.length > 1 ? (
              <FlatList
                ref={flatListRef}
                data={tournaments}
                renderItem={({item}) => (
                  <TournamentItem w={ITEM_WEIGHT} mx={ITEM_MARGIN_HORIZONTAL} item={item} getUser={getUser}/>
                )}
                keyExtractor={(item) => item.toString()}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                snapToAlignment="center"
                nativeID="tournament_list"
                onScroll={onScroll}
                style={[MainStyle.m_x_auto, MainStyle.full_w, MainStyle.max_w_430, MainStyle.m_b_14]}
                getItemLayout={getItemLayout}
              />
            ) : (
              <TournamentItem w={ITEM_WEIGHT} mx="auto" item={tournaments[0]} mb={14} getUser={getUser}/>
            )}

            <View style={[MainStyle.row, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.m_b_33]}>
              {activeIndex !== 1 ? (
                <AntDesign name="left" size={20} color={colors.caret} onPress={() => scrollToItem(activeIndex - 2)}/>
              ) : <View style={MainStyle.w_20}/>}

              <Text style={[MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_18, MainStyle.m_l_36]}>
                {activeIndex}
              </Text>

              <Text style={[MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_14, MainStyle.m_r_32]}>
                &nbsp;/ {tournaments.length}
              </Text>

              {activeIndex !== tournaments.length ? (
                <AntDesign name="right" size={20} color={colors.caret} onPress={() => scrollToItem(activeIndex)}/>
              ) : <View style={MainStyle.w_20}/>}
            </View>

            <JoinTournamentButton
              entryfee={tournaments[activeIndex - 1].entryfee}
              id={tournaments[activeIndex - 1].id}
              onReload={listTournaments}
              registered={!!tournaments[activeIndex - 1].currentplayerdetails && tournaments[activeIndex - 1].currentplayerdetails?.state !== TournamentPlayerDetails.State.FINISHED}
              joinDisabled={tournaments[activeIndex - 1].state === Tournament.State.CANCELLED || tournaments[activeIndex - 1].state === Tournament.State.FINISHED || tournaments[activeIndex - 1].currentplayerdetails?.state === TournamentPlayerDetails.State.FINISHED}
              leaveDisabled={tournaments[activeIndex - 1]?.playerdetailsList.find(el => el.playerId === tournaments[activeIndex - 1].currentplayerdetails?.playerId)?.betsList.filter(bet => bet.state === TournamentPlayerBet.State.FINISHED).length === tournaments[activeIndex - 1].totalshots}
              totalShots={tournaments[activeIndex - 1].totalshots}
              leave={leaveTournament}
              autoPlayAmount={user?.autoPlayAmount}
            />

            <Text style={[MainStyle.c_Gray, MainStyle.t_a_c, MainStyle.Barlow400, MainStyle.f_s_17]}>
              Malfunction voids all pays and plays
            </Text>
          </>
        ) : (
          <View style={[MainStyle.flex1, MainStyle.j_c_c, MainStyle.a_i_c]}>
            <Text style={styles.title}>Nothing found</Text>
          </View>
        )}
      </ScrollView>

      <BottomNavigation page="tournaments"/>

      <ContinueAutoPlayModal
        isOpen={continueAutoPlay}
        onClose={() => setContinueAutoPlay(false)}
        onReload={getUser}
      />
    </>
  );
};

const styles = {
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_32, MainStyle.m_b_14, MainStyle.m_l_21],
  btn_wrap: [
    MainStyle.m_x_auto, MainStyle.w_350, MainStyle.h_63, MainStyle.a_i_c, MainStyle.b_r_70, MainStyle.bg_newGreen,
    MainStyle.j_c_c, MainStyle.m_b_10
  ]
};

export default TournamentsListingScreen;
