import React, {useRef} from "react";
import {View, TextInput} from "react-native";
import MainStyle from "../../MainStyle";
import {outline_0} from "../constants";

type Props = { onChange: (text: string) => void };

const CodeVerificationInput = (props: Props) => {
  const input0 = useRef<TextInput>(null);
  const input1 = useRef<TextInput>(null);
  const input2 = useRef<TextInput>(null);
  const input3 = useRef<TextInput>(null);
  const input4 = useRef<TextInput>(null);
  const input5 = useRef<TextInput>(null);
  const inputs = [input0, input1, input2, input3, input4, input5];
  const lastIndex = inputs.length - 1;

  const onChange = () => {
    let val = '';

    inputs.forEach(el => {
      const input: any = el.current;
      val += input.value;
    });

    props.onChange(val);
  };

  const onChangeText = (index: number) => (val: string) => {
    const text = val.slice(0, inputs.length);

    if (text.length > 1) {
      if (index === lastIndex) {
        const input: any = inputs[lastIndex].current;
        input.value = text[text.length - 1];

      } else {
        inputs.forEach((el, i) => {
          const input: any = el.current;
          input.focus();
          input.value = text[i] || '';
        });
      }

    } else if (text.length === 1) {
      if (index < lastIndex) {
        inputs[index + 1].current?.focus();
      }
    }

    onChange();
  };

  const handleBackspace = (i: number) => {
    const input: any = inputs[i].current;

    if (input.value) {
      input.value = '';
      onChange();
      return;
    }

    if (i) inputs[i - 1].current?.focus();
  };

  const handleKeydown = (i: number) => (e: any) => {
    switch (e.code) {
      case 'Backspace':
        handleBackspace(i);
        break;
      case 'ArrowLeft':
        if (i) inputs[i - 1].current?.focus();
        break;
      case 'ArrowRight':
        if (i < lastIndex) inputs[i + 1].current?.focus();
        break;
      default:
    }
  };

  const onFocus = (index: number) => () => {
    const input: any = inputs[index].current;
    input.value = '';
  };

  return (
    <View style={[MainStyle.row, MainStyle.w_390, MainStyle.j_c_s_between, MainStyle.m_x_auto, MainStyle.m_b_32]}>
      {inputs.map((input, i) => (
        <TextInput
          key={i} ref={input} style={styles.item} keyboardType="phone-pad" autoFocus={!i} selectTextOnFocus
          onKeyPress={handleKeydown(i)} onChangeText={onChangeText(i)} onFocus={onFocus(i)}
        />
      ))}
    </View>
  );
};

const styles = {
  item: [
    MainStyle.w_60, MainStyle.h_60, MainStyle.b_r_16, MainStyle.b_w_1, MainStyle.b_c_cardInfoBorder,
    MainStyle.bg_settings, MainStyle.c_lightblue, outline_0, MainStyle.t_a_c, MainStyle.f_s_18
  ]
};

export default React.memo(CodeVerificationInput);
