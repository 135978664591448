import React, {useState} from "react";
import {Platform, Text, View} from "react-native";
import Button from "../Button";
// import DateTimePickerModal from "react-native-modal-datetime-picker";
import {FormikProps} from "formik";
import {registerUserPayloadType} from "../../types";
import MainStyle from "../../../MainStyle";
// import './DatePickerDarkTheme.css';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {colors} from "../../assets/styles/colors";
import moment from "moment";

const darkTheme = createTheme({palette: {mode: 'dark'}});

type Props = { formik: FormikProps<registerUserPayloadType>, mb?: number | string, label?: string };

const DateTimePicker = (props: Props) => {
  const {formik, mb, label} = props;
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  return (
    <>
      {Platform.OS === 'web' ? (
        <>
          <Text style={[styles.label_s, MainStyle.t_t_u]}>{label}</Text>

          <ThemeProvider theme={darkTheme}>
            <DesktopDatePicker
              value={formik.values.dateOfBirth ? moment(formik.values.dateOfBirth) : null}
              onChange={(date) => formik.setFieldValue('dateOfBirth', date?.format('YYYY-MM-DD'))}
              slotProps={{layout: {sx: {backgroundColor: colors.settings_bg}}}}
              views={['year', 'month', 'day']}
              sx={{
                '.MuiInputBase-root': {borderRadius: '200px'},
                '.MuiInputBase-input': {textAlign: 'center', fontFamily: 'Barlow-Medium', fontSize: 24},
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors[formik.touched.dateOfBirth && formik.errors.dateOfBirth ? 'newDangerText' : 'cardInfoBorder']} !important`,
                  borderWidth: '1px !important'
                },
                '.MuiInputAdornment-root': {position: 'absolute', right: 30},
                height: 66, backgroundColor: colors.settings_bg, marginBottom: mb
              }}
            />
          </ThemeProvider>
        </>
      ) : (
        <View>
          <Button text={formik.values.dateOfBirth ? moment(formik.values.dateOfBirth).format('MM/DD/YYYY') : ''}
                  onPress={() => setDatePickerVisibility(true)}/>
          {/*<DateTimePickerModal*/}
          {/*  isVisible={isDatePickerVisible}*/}
          {/*  mode="date"*/}
          {/*  onConfirm={handleConfirm}*/}
          {/*  onCancel={() => setDatePickerVisibility(false)}*/}
          {/*/>*/}
        </View>
      )}
    </>
  );
};

const styles = {
  label_s: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.t_a_c, MainStyle.o_05]
}

export default React.memo(DateTimePicker);
