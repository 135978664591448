import React from "react";
import {StyleSheet, Text} from "react-native";
import {Div} from "react-native-magnus";
import {colors} from "../assets/styles/colors";
import MainStyle from "../../MainStyle";
import Switch from "./Switch";

type Props = { isSm: boolean, value?: boolean, onPress: () => void, label: string };

const NotificationSwitch = (props: Props) => {
  const {isSm, value, label} = props;
  const trackStyle = {
    activeBackgroundColor: colors.newGreen, inActiveBackgroundColor: colors.popupDropBg, borderRadius: 100,
    activeBorderColor: colors.blueText, inActiveBorderColor: colors.blue, borderWidth: 1, width: isSm ? 44 : 102,
    height: isSm ? 24 : 54
  };
  const thumbStyle = {
    borderWidth: 1, borderRadius: 28, inActiveBorderColor: colors.unselectDark, shadowColor: colors.mainBg,
    shadowOffset: {width: 0, height: 4}, shadowOpacity: 0.25, shadowRadius: 4, elevation: 2, height: isSm ? 20 : 48,
    width: isSm ? 20 : 48, activeBackgroundColor: colors.newGreenText, inActiveBackgroundColor: colors.unselect,
    activeBorderColor: colors.darkNewGreen
  };

  return (
    <Div row={isSm} justifyContent="space-between" alignItems="center" py={isSm ? 0 : 36} rounded={8} borderWidth={1}
         borderColor={colors.blueText} bg={colors.bottomTabBg} w={isSm ? 300 : 330} h={isSm ? 60 : 200}
         pr={isSm ? 10 : 0}>
      <Text style={[isSm ? rnStyles.bxTitleSm : rnStyles.bxTitle, MainStyle.t_a_c, MainStyle.flex1]}>{label}</Text>

      <Div row alignItems="center">
        <Text style={[isSm ? rnStyles.indicatorTxtSm : rnStyles.indicatorTxt, {opacity: value ? 0.25 : 1}]}>OFF</Text>

        <Switch value={value} onPress={props.onPress} trackStyle={trackStyle} thumbStyle={thumbStyle} isSm={isSm}/>

        <Text style={[isSm ? rnStyles.indicatorTxtSm : rnStyles.indicatorTxt, {opacity: !value ? 0.25 : 1}]}>ON</Text>
      </Div>
    </Div>
  );
};

const rnStyles = StyleSheet.create({
  bxTitle: {
    color: colors.whiteText,
    fontSize: 28,
    fontFamily: 'Barlow-Bold'
  },
  bxTitleSm: {
    color: colors.whiteText,
    fontSize: 14,
    fontFamily: 'Barlow-Bold'
  },
  indicatorTxt: {
    width: 80,
    color: colors.whiteText,
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Barlow-Bold'
  },
  indicatorTxtSm: {
    width: 40,
    color: colors.whiteText,
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Barlow-Bold'
  }
});

export default React.memo(NotificationSwitch);
