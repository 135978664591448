import React, {useContext, useEffect, useRef, useState} from 'react';
import {View, Text, Image, useWindowDimensions, Platform, Pressable, Switch} from 'react-native';
import play_icon from '../../../assets/img/icons/play_icon.png';
import MainStyle from '../../../../MainStyle';
import Button from '../../../components/Button';
import {breakpoint, SkillMoneyLanding, smgEnvironment, tabletBreakpoint} from '../../../constants';
import {HelpLinkModalTrigger} from '../../../modals/HelpLinksModal';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {browser} from "../../../utils";
import {StoreContext} from "../../../components/StoreContextProvider";
import add_to_home from '../../../assets/img/icons/add_to_home.png';
import about_company from '../../../assets/img/icons/about_company_icon.png';
import {Ionicons} from '@expo/vector-icons';
import Vimeo from '@vimeo/player';
import {Div} from 'react-native-magnus';

const smartphone_video = 'https://player.vimeo.com/video/846314975?h=5c6f773563&title=0&byline=0&portrait=0&controls=0';
const kiosk_video = 'https://player.vimeo.com/video/846311618?h=2e465dfec4&title=0&byline=0&portrait=0&controls=0';

interface SplashScreenProps {
  navigation: any;
}

export const SplashScreen = ({navigation}: SplashScreenProps) => {
  const {width} = useWindowDimensions();
  const isLg = width >= breakpoint;
  const isMd = width >= tabletBreakpoint && width < breakpoint;
  const [installEvent, setInstallEvent] = useState<any>();
  const insets = useSafeAreaInsets();
  const Context = useContext(StoreContext);
  const {cancelModalConfig, isTest} = Context;
  const [isPlayingFullScreen, setIsPlayingFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const player = useRef<Vimeo>();
  const openAboutCompany = () => {
    if (Platform.OS === 'web') window.open(SkillMoneyLanding, '_self');
    // if (Platform.OS === 'web') window.open(`https://about.skillmoney.com/?env=${smgEnvironment}`, '_blank');
  };

  const isInstalled = () => {
    if (Platform.OS !== 'web') return false;
    return window.matchMedia('(display-mode: standalone)').matches;
  };

  const AddToHomeScreen = () => {
    if (installEvent) {
      installEvent?.prompt();

    } else if (getMobileOperatingSystem() === 'iOS') {
      Context.setCancelModalConfig({
        visible: true, title: 'Add To Home Screen', titleColor: MainStyle.c_light_blue, leftBtnText: 'OK',
        subtitle: 'Tap on the “Share” button on your browser',
        secondary: 'Select “Add to Home Screen”',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });

    } else if (getMobileOperatingSystem() === 'Android') {
      Context.setCancelModalConfig({
        visible: true, title: 'Add To Home Screen', titleColor: MainStyle.c_light_blue, leftBtnText: 'OK',
        subtitle: 'Tap on the menu button',
        secondary: 'Select the option labeled "Add to home screen"',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    }
  };

  const getMobileOperatingSystem = () => {
    if (Platform.OS !== 'web') return '';
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) return "Windows Phone";

    if (/android/i.test(userAgent)) return "Android";

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) return "iOS";

    return "unknown";
  };

  const videoClick = () => {
    if (isLg) {
      navigation.reset({index: 0, routes: [{name: 'LoginWithPin'}]});

    } else if (Platform.OS === 'web') {
      if (!isPlayingFullScreen) {
        playVideo();
        setIsPlayingFullScreen(true);

      } else {
        navigation.reset({index: 0, routes: [{name: 'LoginWithPin'}]});
      }
    }
  };

  const playVideo = () => {
    if (Platform.OS === 'web') player.current?.play();
  };

  useEffect(() => {
    if (!player.current) return;

    setTimeout(() => {
      player.current?.setMuted(isMuted).then(() => {
        playVideo();

      }).catch(err => {
        console.log('setMuted err', err);
      });
    }, 150);
  }, [isMuted]);

  useEffect(() => {
    if (isLg) setIsPlayingFullScreen(false);
    const iframe = document.querySelector('iframe');
    let time: any;

    if (iframe) {
      setTimeout(() => {
        player.current = new Vimeo(iframe);
        if (isLg) player.current?.setMuted(true);

        player.current?.on('ended', () => {
          player.current?.setCurrentTime(14);

          time = setTimeout(() => {
            if (isLg) {
              player.current?.setCurrentTime(0);
              playVideo();
            }
          }, 1000 * 30);
        });

        if (isLg) playVideo();
      }, 1500);
    }

    return () => {
      if (iframe) player.current?.off('ended');
      if (time) clearTimeout(time);
    };
  }, [isLg]);

  useEffect(() => {
    console.log('getMobileOperatingSystem=', getMobileOperatingSystem());
    console.log('browser=', browser());

    const listener = (ev: Event) => {
      // Prevent the mini-infobar from appearing on mobile
      ev.preventDefault();
      // Stash the event so it can be triggered later.
      setInstallEvent(ev);
      console.log('saved the install event');
      // Update UI notify the user they can install the PWA
      // if you want here...
    };

    if (Platform.OS === 'web') {
      window.addEventListener('beforeinstallprompt', listener);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('beforeinstallprompt', listener);
      }
    };
  }, []);

  return (
    <>
      {!isPlayingFullScreen && <HelpLinkModalTrigger isSplashPage/>}

      <View style={[MainStyle.flex1, {paddingTop: insets.top}]}>
        {!isPlayingFullScreen && (
          <Div pt={isMd ? 37 : 24} pb={isMd ? 21 : 7}>
            <View style={isLg ? styles.header_l : isMd ? styles.header_m : styles.header_s}>
              <Div flexDir={(isMd || isLg) ? 'row' : 'column'} alignItems='center'>
                <Text
                  style={[...(isLg ? styles.title_l : isMd ? styles.title_m : styles.title_s), MainStyle.c_title_blue]}>
                  PLAY GOLF.
                </Text>
                <Text
                  style={[...(isLg ? styles.title_l : isMd ? styles.title_m : styles.title_s), MainStyle.c_new_green_text]}>
                  &nbsp;WIN MONEY.&nbsp;
                </Text>
              </Div>
              <Text style={[...(isLg ? styles.title_l : isMd ? styles.title_m : styles.title_s), MainStyle.c_white]}>
                IT’S THAT SIMPLE.
              </Text>
            </View>
          </Div>
        )}

        <View style={[MainStyle.flex1, isPlayingFullScreen ? {} : MainStyle.p_x_27, MainStyle.relative]}>
          {Platform.OS === 'web' && (
            <iframe
              src={isLg ? kiosk_video : smartphone_video}
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{border: 0}}
            />
          )}

          <Pressable
            onPress={videoClick}
            style={[MainStyle.absolute, MainStyle.t_0, MainStyle.r_0, MainStyle.b_0, MainStyle.l_0]}
          />

          {(!isLg && !isPlayingFullScreen) && (
            <Pressable onPress={videoClick} style={styles.play_btn}>
              <Image source={play_icon} style={[MainStyle.w_64, MainStyle.h_64]}/>
            </Pressable>
          )}
        </View>

        {!isPlayingFullScreen && (
          <View
            style={isLg ? [MainStyle.h_150, MainStyle.p_t_20] : isMd ? [MainStyle.p_t_22, MainStyle.p_b_29, MainStyle.p_x_30] : [MainStyle.p_y_19]}>
            <View style={styles.footer}>
              <View style={[MainStyle.w_53, MainStyle.m_l_10]}>
                <Pressable style={MainStyle.a_i_c} onPress={openAboutCompany}>
                  <Image
                    source={about_company}
                    style={isLg ? [MainStyle.w_20, MainStyle.h_20, MainStyle.m_b_6] : isMd ? [MainStyle.w_32, MainStyle.h_32, MainStyle.m_b_6] : [MainStyle.w_20, MainStyle.h_20, MainStyle.m_b_6]}
                  />
                  <Text style={isLg ? styles.btn_text : isMd ? styles.btn_text_m : styles.btn_text}>
                    About the Company
                  </Text>
                </Pressable>
              </View>

              <Div flexDir={isLg ? 'row' : 'column'}>
                <Button
                  text="SIGN UP/IN"
                  onPress={() => navigation.reset({index: 0, routes: [{name: 'LoginWithPin'}]})}
                  w={isLg ? 400 : isMd ? 360 : 180}
                  h={isLg ? 80 : isMd ? 36 : 24}
                  fontSize={isLg ? 48 : isMd ? 24 : 16}
                  fontFamily="Barlow-Bold"
                  style={styles.btn_shadow}
                />
                <Div w={30} h={isLg ? 5 : isMd ? 16 : 10}/>
                <Button
                  text="BOOK NOW"
                  onPress={() => {
                    if (Platform.OS === 'web') window.open(SkillMoneyLanding, '_self');
                  }}
                  w={isLg ? 400 : isMd ? 360 : 180}
                  h={isLg ? 80 : isMd ? 36 : 24}
                  fontSize={isLg ? 48 : isMd ? 24 : 16}
                  fontFamily="Barlow-Bold"
                  style={styles.btn_shadow}
                />
              </Div>

              {isLg ? (
                <Ionicons
                  name={isMuted ? 'volume-mute-outline' : 'volume-high-outline'}
                  size={46}
                  color="white"
                  style={MainStyle.m_r_10}
                  onPress={() => setIsMuted(!isMuted)}
                />
              ) : (
                <View style={[MainStyle.w_53, MainStyle.m_r_10]}>
                  {!isInstalled() && !isLg && (getMobileOperatingSystem() === 'Android' || getMobileOperatingSystem() === 'iOS') && (
                    <Pressable style={MainStyle.a_i_c} onPress={AddToHomeScreen}>
                      <Image source={add_to_home}
                             style={isLg ? [MainStyle.w_20, MainStyle.h_20, MainStyle.m_b_6] : isMd ? [MainStyle.w_32, MainStyle.h_32, MainStyle.m_b_6] : [MainStyle.w_20, MainStyle.h_20, MainStyle.m_b_6]}/>
                      <Text style={isLg ? styles.btn_text : isMd ? styles.btn_text_m : styles.btn_text}>add to Home
                        Screen</Text>
                    </Pressable>
                  )}
                </View>
              )}
            </View>
          </View>
        )}
      </View>

      {(smgEnvironment === 'external' || smgEnvironment === 'test') && (
        <Pressable style={[MainStyle.absolute, MainStyle.t_35, MainStyle.l_5, MainStyle.row, MainStyle.a_i_c]}
                   onPress={() => Context.setIsTest(!isTest)}>
          <Text style={[MainStyle.f_w_600, MainStyle.f_s_10, MainStyle.c_white, MainStyle.m_r_7]}>
            Test {isLg && 'Simulator'}
          </Text>

          <Switch
            trackColor={{false: '#767577', true: '#81b0ff'}}
            thumbColor={isTest ? '#f5dd4b' : '#f4f3f4'}
            ios_backgroundColor="#3e3e3e"
            onValueChange={() => Context.setIsTest(!isTest)}
            value={isTest}
          />
        </Pressable>
      )}

      {smgEnvironment !== 'prod' && (
        <Text style={[MainStyle.absolute, MainStyle.t_9, MainStyle.l_15, MainStyle.c_gray]}>
          {smgEnvironment}
        </Text>
      )}
    </>
  );
};

const styles = {
  header_l: [MainStyle.row, MainStyle.j_c_c, MainStyle.a_i_e],
  header_m: [MainStyle.a_i_c, MainStyle.m_y_6, MainStyle.column, MainStyle.j_c_c, MainStyle.a_i_c],
  header_s: [MainStyle.a_i_c, MainStyle.m_y_6],

  title_l: [MainStyle.f_s_52, MainStyle.Barlow700, MainStyle.m_b_20],
  title_m: [MainStyle.f_s_32, MainStyle.italic, MainStyle.f_w_800],
  title_s: [MainStyle.f_s_20, MainStyle.italic, MainStyle.f_w_800],

  footer: [
    MainStyle.full_w, MainStyle.max_w_1205, MainStyle.m_x_auto, MainStyle.relative, MainStyle.row,
    MainStyle.j_c_s_between
  ],

  btn_text: [
    MainStyle.Barlow700, MainStyle.f_s_10, MainStyle.l_h_12, MainStyle.t_t_u, MainStyle.c_white, MainStyle.t_a_c
  ],

  btn_text_m: [
    MainStyle.Barlow700, MainStyle.f_s_12, MainStyle.l_h_14, MainStyle.t_t_u, MainStyle.c_white, MainStyle.t_a_c
  ],

  play_btn: [MainStyle.t_50_percent, MainStyle.l_50_percent, MainStyle.absolute, MainStyle.m_t__32, MainStyle.m_l__32],

  btn_shadow: {
    "shadowOffset": {
      "width": 0,
      "height": 4
    },
    "shadowRadius": 4,
    "shadowColor": "rgba(0, 0, 0, 0.25)",
    "shadowOpacity": 1
  },
};
