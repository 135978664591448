import React, {useContext, useEffect, useState} from 'react';
import {Modal, StyleSheet, Text, TouchableOpacity, useWindowDimensions, ScrollView, View} from 'react-native';
import {Div, Image, Button} from 'react-native-magnus';
import {colors} from '../assets/styles/colors';
import {StoreContext} from '../components/StoreContextProvider';
import {focus_plus_icon} from '../constants/assest-manager';
import PayTable from "../components/PayTable";
import {tabletBreakpoint} from "../constants";
import DistanceChallengeItem from "../components/DistanceChallengeItem";
import MainStyle from "../../MainStyle";
import img0 from "../assets/img/image 23.png";
import img1 from "../assets/img/image 24.png";
import img2 from "../assets/img/image 25.png";
import location from "../assets/img/location_icon.png";

const ContestEntryModal = () => {
  const {contestEntryModalConfig} = useContext(StoreContext);
  const {visible, amount, contestAmount, largestDistanceChallenge, challengesList} = contestEntryModalConfig;
  const {golf2challengesList, payLinesList, golf2challenge, holeInOneAmount} = contestEntryModalConfig;
  const {width} = useWindowDimensions();
  const isMd = width >= tabletBreakpoint;
  const [challengeId, setChallengeId] = useState<number>();

  const renderGolfChallenge = (i: number, title: string, img: any) => {
    if (!golf2challengesList?.length) return;

    return (
      <Div alignItems="center" flexDir="row" mb={15}>
        {isMd && <Text style={Styles.label}>{title}</Text>}

        <Image h={80} w={80} source={img}/>

        <Div alignItems="center" ml={20}>
          {!isMd && <Text style={Styles.label_s}>{title}</Text>}

          <Div row mb={3}>
            <Text style={Styles.text_s}>{golf2challengesList[i].gamedetails?.hole?.distance} YDS</Text>

            <Text style={[...Styles.text_s, MainStyle.m_x_10]}>
              {golf2challengesList[i].gamedetails?.circle?.winCircleDistance} FT
            </Text>

            <Text style={Styles.amount_s}>
              ${(contestAmount ? +contestAmount : 0) * golf2challengesList[i].multiplier}
            </Text>
          </Div>

          <Div>
            <Button w={118} h={40} rounded={28} shadow="lg" fontSize={16} fontWeight="700"
                    bg={challengeId === golf2challengesList[i].challengeId ? colors.blueText : colors.unselect}
                    onPress={() => setChallengeId(golf2challengesList[i].challengeId)}>
              SELECT
            </Button>
          </Div>
        </Div>
      </Div>
    );
  };

  useEffect(() => {
    if (contestEntryModalConfig.challengeId) {
      setChallengeId(contestEntryModalConfig.challengeId)
    }
  }, [visible]);

  return (
    <Modal animationType="fade" transparent visible={visible} statusBarTranslucent={false} hardwareAccelerated={false}
           onRequestClose={contestEntryModalConfig?.onCancel}>
      <View style={[MainStyle.fix_gradient_and_modal_error_in_landscape, MainStyle.flex1, MainStyle.bg_darkOverlay]}>
        <ScrollView contentContainerStyle={[MainStyle.full_h, MainStyle.full_w]}>
          <Div minH="100%" justifyContent="center" alignItems="center" px={40} py={104}>
            <Div position="relative" maxW={isMd ? 500 : 280} w="100%">
              <Div w="100%" bg={colors.deepBlue} borderWidth={1} borderColor={colors.lightBlue} rounded={4} shadow="md"
                   overflow="hidden">
                <Div h={32} bg={colors.blueText} row justifyContent="center" alignItems="center" position="relative">
                  <Div h="100%" row justifyContent="center" alignItems="center" position="absolute" left={0} p={10}
                       top={0}>
                    <Image
                      h={24}
                      w={24}
                      resizeMode="contain"
                      source={challengesList?.length ? focus_plus_icon : location}
                    />
                  </Div>

                  <Text style={[styles.title, {fontSize: 14}]}>
                    {challengesList?.length ? 'CLOSEST TO THE PIN' : golf2challenge ? 'MULTI-PIN RANGE' : 'CHOOSE CHALLENGE'}
                  </Text>
                </Div>

                {challengesList?.length ? (
                  <Div pt={49} px={15} pb={47} flexDir="column" alignItems="center">
                    <Div w="100%" mb={34}>
                      <DistanceChallengeItem
                        label="DISTANCE:"
                        value={`${largestDistanceChallenge ? largestDistanceChallenge.value1 / 36 : 0} YARDS`}
                        dense={false}
                        w="100%"
                        mb={4}
                      />

                      <DistanceChallengeItem
                        label="WIN CIRCLE:"
                        value={`${largestDistanceChallenge ? largestDistanceChallenge.value2 / 12 : 0} FEET`}
                        dense={false}
                        w="100%"
                        mb={4}
                      />

                      <DistanceChallengeItem
                        label="CHALLENGE FEE:"
                        value={`$${contestAmount}`}
                        dense={false}
                        w="100%"
                      />
                    </Div>
                    <Div w="100%">
                      <PayTable
                        payLinesList={payLinesList}
                        challengesList={challengesList}
                        amount={amount}
                        holeInOneAmount={holeInOneAmount}
                      />
                    </Div>
                  </Div>
                ) : golf2challengesList?.length ? (
                  <Div pt={17} flexDir="column" justifyContent="space-around" px={20}>
                    {renderGolfChallenge(0, 'Easy', img0)}

                    {renderGolfChallenge(1, 'Medium', img1)}

                    {renderGolfChallenge(2, 'Hard', img2)}
                  </Div>
                ) : golf2challenge ? (
                  <Div pt={49} px={15} pb={47} flexDir="column" alignItems="center">
                    <Div w="100%" mb={34}>
                      <DistanceChallengeItem
                        label="DISTANCE:"
                        value={`${golf2challenge.gamedetails?.hole?.distance} YARDS`}
                        dense={false}
                        w="100%"
                        mb={4}
                      />

                      <DistanceChallengeItem
                        label="WIN CIRCLE:"
                        value={`${golf2challenge.gamedetails?.circle?.winCircleDistance} FT`}
                        dense={false}
                        w="100%"
                        mb={4}
                      />

                      <DistanceChallengeItem
                        label="CHALLENGE FEE:"
                        value={`$${contestAmount}`}
                        dense={false}
                        w="100%"
                      />
                    </Div>

                    <Div alignItems="center" w="100%">
                      <DistanceChallengeItem
                        label="TOTAL PAYOUT:"
                        value={`$${+(contestAmount || 0) * golf2challenge.multiplier}`}
                        dense
                        w={145}
                        textAlign="center"
                      />
                    </Div>
                  </Div>
                ) : null}
              </Div>

              <Div flexDir="column-reverse" bottom={-104} left={16} right={16} justifyContent="space-between"
                   position="absolute">
                <TouchableOpacity style={styles.outline_btn_s} onPress={contestEntryModalConfig.onCancel}>
                  <Text style={[styles.outlineBtnText, {fontSize: 16}]}>CANCEL CHALLENGE</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.fill_btn_s}
                                  onPress={() => contestEntryModalConfig.onOk ? contestEntryModalConfig.onOk(challengeId) : null}>
                  <Text style={[styles.fillBtnText, {fontSize: 16}]}>CONFIRM CHALLENGE ENTRY</Text>
                </TouchableOpacity>
              </Div>
            </Div>
          </Div>
        </ScrollView>
      </View>
    </Modal>
  );
};

const Styles = {
  label: [MainStyle.f_w_600, MainStyle.f_s_28, MainStyle.l_h_35, MainStyle.c_white, MainStyle.m_b_17],
  label_s: [MainStyle.f_w_600, MainStyle.f_s_14, MainStyle.l_h_20, MainStyle.c_white],
  text_s: [MainStyle.f_w_600, MainStyle.f_s_14, MainStyle.l_h_20, MainStyle.c_white],
  amount_s: [MainStyle.f_w_900, MainStyle.f_s_16, MainStyle.l_h_20, MainStyle.c_light_blue]
};

const styles = StyleSheet.create({
  title: {fontWeight: '700', color: '#F5F5F5'},
  fill_btn_s: {
    backgroundColor: '#209D01', borderWidth: 1, borderColor: 'rgba(0, 0, 0, 0.5)', borderStyle: 'solid', height: 40,
    borderRadius: 28, marginBottom: 9, justifyContent: 'center'
  },
  fillBtnText: {fontWeight: '700', textAlign: 'center', color: '#F5F5F5'},
  outline_btn_s: {
    height: 40, backgroundColor: '#07111C', borderWidth: 1, borderColor: '#FF6262', borderStyle: 'solid',
    borderRadius: 28, justifyContent: 'center'
  },
  outlineBtnText: {fontWeight: '700', textAlign: 'center', color: '#FF6262'}
});

export default ContestEntryModal;
