import React, {useContext, useLayoutEffect, useState} from 'react';
import {Platform, Text, View} from 'react-native';
import {StoreContext} from "../../../../../components/StoreContextProvider";
import * as Yup from "yup";
import {useFormik} from "formik";
import Input from "../../../../../components/Input";
import MainStyle from "../../../../../../MainStyle";
import {phoneUtil} from '../../../../../constants';
import {colors} from "../../../../../assets/styles/colors";
import Button from "../../../../../components/Button";
import {profanity} from '@2toad/profanity';
import PhoneInput from "../../../../../components/PhoneInput";
import Handedness from "../../../../../components/Handedness";
import {useNavigation} from "@react-navigation/native";
import ServiceClient from "../../../../../utils/ServiceClient";
import {updateUserPayloadType} from "../../../../../types";
import OtpModal from "../../../../../modals/OtpModal";
import {ChangeType} from "../../../../../../generated/smg_service_pb";

const Profile = () => {
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user} = Context;
  const formik = useFormik({
    initialValues: {email: '', phone: '', username: '', handedness: ''},
    validationSchema: Yup.object({
      email: Yup.string().email().required(), phone: Yup.string(), username: Yup.string().required(),
      handedness: Yup.string()
    }),
    onSubmit: (values) => {
      let isValid = false;

      try {
        isValid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(values.phone));

      } catch (error) {
        console.log('isValidNumber error', error);
      }

      if (!isValid) {
        formik.setFieldError('phone', 'Invalid format');
        return;

      } else if (profanity.exists(values.username)) {
        formik.setFieldError('username', 'The selected user name is not allowed. Please choose another username');
        return;
      }

      onSave();
    }
  });
  const [init, setInit] = useState(false);
  const navigation: any = useNavigation();
  const [phoneVerificationDialog, setPhoneVerificationDialog] = useState(false);

  const requestChange = (type: ChangeType) => () => {
    Context.setLoadingOverlay(true);

    ServiceClient.requestChange(type).then(() => {
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Success', titleColor: MainStyle.c_green, leftBtnText: 'OK',
        subtitle: `You have successfully sent a request to change your ${type === ChangeType.ADDRESS ? 'address' : 'photo'} to the support team.`,
        onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('requestChange err', err);

      Context.setCancelModalConfig({
        visible: true, subtitle: err.message || 'requestChange Error', title: 'Error', leftBtnText: 'OK',
        onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const updatePhoneNumber = (code?: string) => {
    setPhoneVerificationDialog(false);
    Context.setLoadingOverlay(true);

    ServiceClient.updatePhoneNumber(formik.values.phone, code).then(() => {
      Context.setLoadingOverlay(false);

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('updatePhoneNumber err', err);

      Context.setCancelModalConfig({
        visible: true, subtitle: err.message || 'UpdatePhoneNumber Error', title: 'Error', leftBtnText: 'OK',
        onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const requestSmsCode = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.requestSmsCode({phone: formik.values.phone}).then(() => {
      Context.setLoadingOverlay(false);
      setPhoneVerificationDialog(true);

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('requestSmsCode err', err);

      Context.setCancelModalConfig({
        visible: true, subtitle: err.message || 'RequestSmsCode Error', title: 'Error', leftBtnText: 'OK',
        onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const deactivateAccountHandler = () => {
    Context.setCancelModalConfig({
      visible: true, title: 'Do you want to De-Activate your account?', leftBtnText: 'NO', rightBtnText: 'YES',
      subtitle: 'This will disable your ability to log in.', rightBtnBorderColor: colors.popupDangerPrimary,
      onResume: () => onAccountDeactivate(),
      onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false}),
      leftBtnBackgroundColor: colors.unselect, rightBtnBackgroundColor: colors.btn_bg_color,
      rightBtnBorderWidth: 1, rightBtnColor: colors.popupDangerPrimary
    });
  };

  const onAccountDeactivate = () => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
    Context.setLoadingOverlay(true);

    ServiceClient.deactivateAccount().then(() => {
      Context.logout().then(() => {
        setTimeout(() => {
          if (Platform.OS === 'web') window.location.reload();
        }, 150);
        navigation.reset({index: 0, routes: [{name: 'Splash'}]});
        Context.setLoadingOverlay(false);
      });

    }).catch(err => {
      console.log('DeactivateAccount err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, subtitle: err.message || 'DeactivateAccount Error', title: 'Error', leftBtnText: 'OK',
        onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const getFieldError = (key: string): boolean | string | undefined => {
    return formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors];
  };

  const requestEmailChange = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.requestEmailChange(formik.values.email).then(() => {
      Context.setIsSuccessMessageShow('Email Confirmation Sent\n\nIf you do not see email, check your "junk" or "spam" folder');
      Context.setLoadingOverlay(false);

      setTimeout(() => {
        Context.setIsSuccessMessageShow('');
      }, 3000);

    }).catch(err => {
      console.log('RequestEmailChange error', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, subtitle: err.message || 'RequestEmailChange Error', title: 'Error', leftBtnText: 'OK',
        onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const updateUser = () => {
    if (!user) return;
    Context.setLoadingOverlay(true);

    const {username, handedness} = formik.values;
    const {allowTextNotifications, allowEmailNotifications, allowPartnerPromos, handicap} = user;

    const payload: updateUserPayloadType = {
      username, handedness, allowTextNotifications, allowEmailNotifications, allowPartnerPromos, handicap
    };

    ServiceClient.updateUser(payload).then(() => {
      Context.setLoadingOverlay(false);
      Context.getUser().then();

    }).catch(err => {
      console.log('UpdateUser error', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, subtitle: err.message || 'UpdateUser Error', title: 'Error', leftBtnText: 'OK',
        onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const onSave = () => {
    if (!user) return;

    const {emailAddress, phoneNumber} = user;
    const {email, username, phone, handedness} = formik.values;

    if (emailAddress !== email) requestEmailChange();
    if (phoneNumber !== phone) requestSmsCode();
    if (user.username !== username || user.handedness !== handedness) updateUser();
  };

  useLayoutEffect(() => {
    formik.resetForm({
      values: {
        email: user?.emailAddress || '', phone: user?.phoneNumber || '', username: user?.username || '',
        handedness: user?.handedness || ''
      }
    });

    // fixing issues with incorrect phone country
    setInit(true);
  }, []);

  return (
    <View style={[MainStyle.p_t_30, MainStyle.p_x_15, MainStyle.max_w_690, MainStyle.m_x_auto]}>
      <Button w={244} h={24} bg={colors.unselect} mb={19} fontSize={16} text="CHANGE ADDRESS"
              onPress={requestChange(ChangeType.ADDRESS)} mx="auto"/>

      <Button w={244} h={24} bg={colors.unselect} fontSize={16} text="CHANGE PHOTO"
              onPress={requestChange(ChangeType.PHOTO)} mx="auto" mb={20}/>

      <Input
        type="email-address" placeholder="Enter Text Here" label="EMAIL ADDRESS"
        value={formik.values.email} error={getFieldError('email')} mb={9}
        onChangeText={formik.handleChange('email')} onBlur={formik.handleBlur('email')}
      />

      {init && (
        <PhoneInput
          placeholder="Phone Number" label="PHONE NUMBER - MOBILE *" mb={14}
          value={formik.values.phone} onChangeText={formik.handleChange('phone')}
          onBlur={formik.handleBlur('phone')} error={getFieldError('phone')}
        />
      )}

      <Input
        placeholder="Enter Text Here" label="Player Name (for screen)"
        value={formik.values.username} error={getFieldError('username')} mb={12}
        onChangeText={formik.handleChange('username')} onBlur={formik.handleBlur('username')}
      />

      <Handedness
        error={formik.touched.handedness && formik.errors.handedness} value={formik.values.handedness}
        onChange={val => formik.setFieldValue('handedness', val)} mb={30}
      />

      <Button text="UPDATE PROFILE" onPress={formik.submitForm} mb={20}/>

      <Button bg="transparent" borderWidth={1} text="DEACTIVATE ACCOUNT" borderColor={colors.popupDangerPrimary} color={colors.popupDangerPrimary} onPress={deactivateAccountHandler} mb={25}/>

      <Text style={styles.subtitle_s}>
        Any changes to your profile information must be reviewed and approved. You will receive an email after
        this process is complete.
      </Text>

      <OtpModal
        visible={phoneVerificationDialog}
        setVisible={updatePhoneNumber}
        phone={formik.values.phone}
        email={formik.values.email}
        goBackBtn
      />
    </View>
  );
};

const styles = {
  subtitle_s: [
    MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.c_white, MainStyle.t_a_c, MainStyle.w_267, MainStyle.m_x_auto,
    MainStyle.m_b_20
  ]
};

export default React.memo(Profile);
