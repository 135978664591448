import React, {useContext, useEffect, useState} from 'react';
import {ActivityIndicator, StyleSheet, Text, useWindowDimensions, View, Image} from 'react-native';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import MainStyle from '../../../../../../MainStyle';
import {tabletBreakpoint} from "../../../../../constants";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button";
import OnlinePayment from "../../../../../components/OnlinePayment";
import PosTerminal from "../../../../../components/PosTerminal";
import visa from "../../../../../assets/img/icons/visa.png";
import SavedCards from "../../../../../components/SavedCards";
import ApplePayButton from "../../../../../components/ApplePayButton";
import KycTerm from "../../../../Auth/SignUp/steps/KycTerm";
import {StoreContext} from "../../../../../components/StoreContextProvider";
import {colors} from "../../../../../assets/styles/colors";

const initialValues = {amount: '50'};
const validationSchema = Yup.object({
  amount: Yup.string().required().matches(/^[0-9]*(\.[0-9]{0,2})?$/, 'Invalid Value')
});

const Wallet = () => {
  const Context = useContext(StoreContext);
  const {user, cancelModalConfig} = Context;
  const {width} = useWindowDimensions();
  const isSm = width < tabletBreakpoint;
  const formik = useFormik({
    initialValues, validationSchema,
    onSubmit: () => {
      console.log('onSubmit');
    }
  });
  const [type, setType] = useState('');
  const [showOnce, setShowOnce] = useState(false);

  const restartKyc = () => {
    if (!user) return;
    Context.setUser({...user, kycStatus: 0});
  };

  const getUser = () => {
    setTimeout(() => {
      Context.getUser().catch(err => {
        console.log('err', err);
      });
    }, 300);
  };

  const getFieldError = (key: string) => {
    return formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors];
  };

  useEffect(() => {
    if ((user?.kycStatus === 0 || user?.kycStatus === 2) && !showOnce) {
      setShowOnce(true);
      Context.setCancelModalConfig({
        visible: true, title: 'Complete KYC', titleColor: MainStyle.c_yellow, rightBtnText: 'COMPLETE KYC',
        subtitle: 'We will need to verify your identity to withdraw or deposit.',
        onClose: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false}),
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    }
  }, [user?.kycStatus, showOnce]);

  return (
    <View style={[MainStyle.w_367, MainStyle.m_x_auto, MainStyle.flex1]}>
      {user?.kycStatus === 0 || user?.kycStatus === 2 ? (
        <KycTerm redirectUser={getUser}/>
      ) : user?.kycStatus === 3 || user?.kycStatus === 4 ? (
        <View style={[MainStyle.flex1, MainStyle.j_c_c, MainStyle.a_i_c]}>
          <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_16, MainStyle.m_b_22]}>
            {user?.kycStatus === 3 ? 'KYC verification started' : 'Waiting for KYC confirmation'}
          </Text>

          <ActivityIndicator color="white" size={30} style={MainStyle.m_b_20}/>

          {user?.kycStatus === 3 && (
            <Button w={244} h={24} bg={colors.unselect} mb={19} fontSize={16} text="Restart KYC Verification"
                    onPress={restartKyc} mx="auto"/>
          )}
        </View>
      ) : type === 'online' ? (
        <OnlinePayment isSm={isSm} amount={formik.values.amount} goBack={() => setType('')}/>
      ) : type === 'pos' ? (
        <PosTerminal isSm={isSm} amount={formik.values.amount} goBack={() => setType('')}/>
      ) : (
        <View style={[MainStyle.column, MainStyle.j_c_s_between, MainStyle.a_i_c, MainStyle.p_t_102]}>
          <View style={[MainStyle.w_228, MainStyle.h_125, MainStyle.a_i_c, MainStyle.m_b_65, MainStyle.bg_amount, MainStyle.p_t_18, MainStyle.b_r_8]}>
            <Input
              label="AMOUNT IN $" type="numeric" placeholder="AMOUNT" dollarMask useGrouping w={164}
              value={formik.values.amount} onChangeText={formik.handleChange('amount')} textAlign="center"
              onBlur={formik.handleBlur('amount')} error={getFieldError('amount')}
            />
          </View>

          <View>
            <Button
              text="ENTER CARD INFO"
              onPress={() => {
                if (!formik.errors.amount && formik.values.amount) setType('online');
              }}
              w={isSm ? 240 : 348}
              h={isSm ? 40 : 56}
              fontSize={isSm ? 16 : 24}
              style={styles.btn_shadow}
              mb={15}
            />

            <ApplePayButton isSm={isSm} amount={formik.values.amount}/>

            <SavedCards amount={formik.values.amount}/>

            <View style={MainStyle.m_b_60}/>

            {/*{isSm ? (*/}
            {/*  <>*/}
            {/*    <Button*/}
            {/*      text="ENTER CARD INFO"*/}
            {/*      onPress={() => {*/}
            {/*        if (!formik.errors.amount && formik.values.amount) setType('online');*/}
            {/*      }}*/}
            {/*      w={isSm ? 240 : 500}*/}
            {/*      h={isSm ? 40 : 80}*/}
            {/*      textStyle={[{fontSize: isSm ? 16 : 32}]}*/}
            {/*    />*/}

            {/*    <SavedCards amount={formik.values.amount}/>*/}

            {/*    <Div mb={isSm ? 51 : 62}/>*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <Button*/}
            {/*    text="SWIPE CARD ON TERMINAL"*/}
            {/*    onPress={() => {*/}
            {/*      if (!formik.errors.amount && formik.values.amount) setType('pos');*/}
            {/*    }}*/}
            {/*    w={isSm ? 240 : 500}*/}
            {/*    h={isSm ? 40 : 80}*/}
            {/*    textStyle={[{fontSize: isSm ? 16 : 32}]}*/}
            {/*    mb={isSm ? 51 : 79}*/}
            {/*  />*/}
            {/*)}*/}

            <Image
              source={visa}
              style={[MainStyle.w_170, MainStyle.h_51, MainStyle.rm_contain, MainStyle.m_x_auto, MainStyle.m_b_110]}
            />
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  btn_shadow: {
    "shadowOffset": {"width": 0, "height": 4}, "shadowRadius": 4, "shadowColor": "rgba(0, 0, 0, 0.25)",
    "shadowOpacity": 1
  }
});

export default React.memo(Wallet);