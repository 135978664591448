import React, {useContext} from "react";
import {Image, Text, View, Pressable, useWindowDimensions} from "react-native";
import MainStyle from "../../../MainStyle";
import icon from "../../assets/img/icon.png";
import add from "../../assets/img/add.png";
import {StoreContext} from "../StoreContextProvider";
import {numberWithCommas} from "../../utils/number";
import {useNavigation} from "@react-navigation/native";
import styles from "./styles";
import {tabletBreakpoint} from "../../constants";
import {HelpLinkModalTrigger} from "../../modals/HelpLinksModal";

type Props = { mb?: string | number };

const ProfileHeader = (props: Props) => {
  const {mb} = props;
  const Context = useContext(StoreContext);
  const {user, balance} = Context;
  const navigation: any = useNavigation();
  const {width} = useWindowDimensions();
  const isSm = width < tabletBreakpoint;

  return (
    <View style={MainStyle.a_i_c}>
      <View style={[styles[isSm ? 'profile_header_s' : 'profile_header_m'], {marginBottom: mb}]}>

        <View style={[MainStyle.flex1, MainStyle.row, MainStyle.a_i_c]}>
          <Pressable onPress={() => navigation.navigate('challenges')}>
            <Image source={icon} style={styles[isSm ? 'profile_header_logo_s' : 'profile_header_logo_m']}/>
          </Pressable>

          <Pressable onPress={() => navigation.navigate({name: 'account', params: {step: 0}})}
                     style={[MainStyle.flex1, MainStyle.j_c_c]}>
            <Text numberOfLines={1}
                  style={[MainStyle.f_w_900, isSm ? MainStyle.f_s_16 : MainStyle.f_s_24, MainStyle.t_a_c, MainStyle.c_white]}>
              {user?.username}
            </Text>
          </Pressable>
        </View>

        <View style={[MainStyle.row, MainStyle.flex1, MainStyle.a_i_c, MainStyle.j_c_s_between]}>
          {isSm ? (
            <Pressable onPress={() => navigation.navigate({name: 'account', params: {step: 1}})}
                       style={[MainStyle.row, MainStyle.a_i_c, MainStyle.flex1, MainStyle.j_c_c]}>
              <Text style={[MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.c_balance, MainStyle.min_w_64, MainStyle.t_a_r]}>
                ${balance ? numberWithCommas(balance / 100, true) : 0}
              </Text>
              <Image source={add} style={[MainStyle.w_30, MainStyle.h_28, MainStyle.m_l_10]}/>
            </Pressable>
          ) : (
            <Pressable onPress={() => navigation.navigate({name: 'account', params: {step: 1}})}
                       style={[MainStyle.row, MainStyle.a_i_c, MainStyle.flex1, MainStyle.j_c_e]}>
              <Text style={[MainStyle.Barlow700, MainStyle.f_s_24, MainStyle.c_balance, MainStyle.min_w_64, MainStyle.t_a_r]}>
                ${balance ? numberWithCommas(balance / 100, true) : 0}
              </Text>
              <Image source={add} style={[MainStyle.w_38, MainStyle.h_36, MainStyle.m_l_10]}/>
            </Pressable>
          )}
        </View>

        <HelpLinkModalTrigger/>
      </View>
    </View>
  );
};

export default React.memo(ProfileHeader);
