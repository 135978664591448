import { Image, Modal, ScrollView, StyleSheet, Text, View } from 'react-native';

import Button from '../components/Button';
import MainStyle from '../../MainStyle';
import React from 'react';
import { colors } from '../assets/styles/colors';
import topgolfRenton from '../assets/img/topgolf-renton.png';
import topgolfRentonGfx from '../assets/img/topgolf-renton-gfx.png';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};
const TopGolfRentonModal = (props: Props) => {
	const { isOpen, onClose } = props;
	return (
		<Modal
			animationType='fade'
			transparent
			visible={isOpen}
			statusBarTranslucent={false}
			hardwareAccelerated={false}
			onRequestClose={onClose}>
			<View
				style={[ MainStyle.fix_gradient_and_modal_error_in_landscape, MainStyle.flex1, MainStyle.bg_filter_modal]}>
				<ScrollView contentContainerStyle={MainStyle.flex1}>
					<View style={[MainStyle.min_h_full, MainStyle.a_i_c, MainStyle.j_c_c]}>
						<View style={rnStyles.contentWrapper}>
							<Image source={topgolfRenton} style={rnStyles.logo} />
							<Text style={rnStyles.title}>
								Play Skill Money at Swing Suites on the First Floor next to the
								Lobby
							</Text>
							<Image source={topgolfRentonGfx} style={rnStyles.gfx} />
							<Button text={'LET’S GO!'} fontFamily={'Barlow-SemiBold'}  onPress={onClose}/>
						</View>
					</View>
				</ScrollView>
			</View>
		</Modal>
	);
};

const rnStyles = StyleSheet.create({
	contentWrapper: {
		backgroundColor: colors.settings_bg,
		maxWidth: 330,
		width: '100%',
		borderRadius: 20,
		borderColor: colors.settings_border,
		borderWidth: 1,
		paddingTop: 32,
		minHeight: 400,
		paddingBottom: 35,
		paddingHorizontal: 26,
	},
	logo: {
		width: 264,
		height: 19,
		alignSelf: 'center',
		marginBottom: 16,
	},
	title: {
		color: '#FFF',
		textAlign: 'center',
		fontFeatureSettings: "'case' on",
		fontSize: 18,
		fontStyle: 'normal',
		fontWeight: '500',
		letterSpacing: -0.374,
		...MainStyle.Barlow500,
	},
	gfx: {
		width: 278,
		height: 182,
		alignSelf: 'center',
		marginBottom: 23,
		marginTop: 21,
	},
});
export default React.memo(TopGolfRentonModal);
