import React, {useContext, useEffect, useState} from "react";
import {Text, Pressable, View} from "react-native";
import Input from "./Input";
import MainStyle from "../../MainStyle";
import Button from "./Button";
import * as Yup from "yup";
import {useFormik} from "formik";
import {formatWithMask, useMaskedInputProps} from "react-native-mask-input";
import {AxiosError} from "axios";
import {smgEProtectUrl, smgPayPageId, smgReportGroup} from "../constants";
import {StoreContext} from "./StoreContextProvider";
import {colors} from "../assets/styles/colors";
import Valid from "card-validator";
import Checkbox from "./Checkbox";
import {useNavigation} from "@react-navigation/native";
import {numberWithCommas} from "../utils/number";
import ServiceClient from "../utils/ServiceClient";
import {DepositPayloadType, EProtectResponse} from "../types";
import {getLocation} from "../utils";

const initialValues = {number: '', expiry: '', cvc: '', name: ''};
const validationSchema = Yup.object({
  number: Yup.string().required(), expiry: Yup.string().required('Required Field').min(5), cvc: Yup.string().required(),
  name: Yup.string()
});

const numberMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
const expiryMask = [/\d/, /\d/, '/', /\d/, /\d/];
const cvcMask = [/\d/, /\d/, /\d/];

type Props = { isSm: boolean, amount: string, goBack: () => void };

const OnlinePayment = (props: Props) => {
  const {isSm, amount} = props;
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user} = Context;
  const formik = useFormik({
    initialValues, validationSchema,
    onSubmit: () => {
      if (user && user.selfLimitationConfirmed) {
        Context.setCancelModalConfig({
          visible: true, title: 'Notice', titleColor: MainStyle.c_yellow, leftBtnText: 'OK',
          subtitle: 'You will not be able to play or deposit money while the Self-Limitation is in effect.',
          onExit: () => {
            Context.setCancelModalConfig({...cancelModalConfig, visible: false});
            navigation.navigate('SelfExclusion');
          }
        });

      } else getToken();
    }
  });
  const numberValidation = Valid.number(formik.values.number);
  let cardNumberMask = numberMask;
  let codeSizeMask = cvcMask;
  const [isChecked, setIsChecked] = useState(false);
  const navigation: any = useNavigation();

  if (numberValidation.card) {
    const {gaps, lengths, code} = numberValidation.card;
    cardNumberMask = [];
    codeSizeMask = [];

    for (let i = 0; i < lengths[lengths.length - 1]; i++) {
      if (gaps.includes(i)) cardNumberMask.push(' ');
      cardNumberMask.push(/\d/);
    }

    for (let i = 0; i < code.size; i++) {
      codeSizeMask.push(/\d/);
    }
  }

  const numberMaskedInputProps = useMaskedInputProps({
    value: formik.values.number, onChangeText: formik.handleChange('number'), mask: cardNumberMask
  });

  const expiryMaskedInputProps = useMaskedInputProps({
    value: formik.values.expiry, onChangeText: formik.handleChange('expiry'), mask: expiryMask
  });

  const codeMaskedInputProps = useMaskedInputProps({
    value: formik.values.cvc, onChangeText: formik.handleChange('cvc'), mask: codeSizeMask
  });

  const getToken = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.getPayGateToken().then(() => {
      callEProtect();

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('getToken err', err);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const timeoutOnEProtect = () => {
    console.log('timeoutOnEProtect');
    const subtitle = 'We are experiencing technical difficulties. Please try again later or call 555-555-1212 (timeout)';

    Context.setLoadingOverlay(false);
    Context.setCancelModalConfig({
      visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
      onResume: () => {
        Context.setCancelModalConfig({...cancelModalConfig, visible: false});
      }
    });
  };

  const onErrorAfterEProtect = (res: any) => {
    const {response} = res;
    console.log('onErrorAfterEProtect', res);
    let subtitle = '';

    if (response === '871') subtitle = 'Invalid card number. Check and retry. (Not Mod10)';
    else if (response === '872') subtitle = 'Invalid card number. Check and retry. (Too short)';
    else if (response === '873') subtitle = 'Invalid card number. Check and retry. (Too long)';
    else if (response === '874') subtitle = 'Invalid card number. Check and retry. (Not a number)';
    else if (response === '875') subtitle = 'We are experiencing technical difficulties. Please try again later or call 555-555-1212';
    else if (response === '876') subtitle = 'Invalid card number. Check and retry. (Failure from Server)';
    else if (response === '881') subtitle = 'Invalid card validation code. Check and retry. (Not a number)';
    else if (response === '882') subtitle = 'Invalid card validation code. Check and retry. (Too short)';
    else if (response === '883') subtitle = 'Invalid card validation code. Check and retry. (Too long)';
    else if (response === '889') subtitle = 'We are experiencing technical difficulties. Please try again later or call 555-555-1212';

    Context.setLoadingOverlay(false);
    Context.setCancelModalConfig({
      visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
      onResume: () => {
        Context.setCancelModalConfig({...cancelModalConfig, visible: false});
      }
    });

    return false;
  };

  const submitAfterEProtect = (response: EProtectResponse) => {
    deposit(response);
    // document.forms['fCheckout'].submit();
  };

  const callEProtect = () => {
    const accountNum = document.getElementById('ccNum') as HTMLInputElement;
    const cvv = document.getElementById('cvvNum') as HTMLInputElement;

    const {unmasked: number} = formatWithMask({text: formik.values.number, mask: cardNumberMask});
    const {unmasked: code} = formatWithMask({text: formik.values.cvc, mask: codeSizeMask});

    accountNum.value = number;
    cvv.value = code;

    const formFields = {accountNum, cvv};
    const win: any = window;

    const eProtectRequest = {
      paypageId: smgPayPageId, reportGroup: smgReportGroup,
      orderId: win.crypto.randomUUID().replaceAll('-', '').slice(0, 24),
      id: win.crypto.randomUUID().replaceAll('-', '').slice(0, 24), url: smgEProtectUrl
    };

    new win.eProtect().sendToEprotect(eProtectRequest, formFields, submitAfterEProtect, onErrorAfterEProtect, timeoutOnEProtect, 15000);
  };

  const deposit = async (response: EProtectResponse) => {
    const {unmasked: number} = formatWithMask({text: formik.values.number, mask: cardNumberMask});
    const {unmasked: code} = formatWithMask({text: formik.values.cvc, mask: codeSizeMask});
    const {unmasked: expiry} = formatWithMask({text: formik.values.expiry, mask: expiryMask});
    const {type, orderId, id, reportGroup, vantivTxnId, paypageRegistrationId} = response;

    const curLocation = await getLocation();

    if (!curLocation) {
      Context.setLoadingOverlay(false);
      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: 'Permission to access the location was denied.', rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });

      return;
    }

    const data: DepositPayloadType = {
      amount: +amount * 100, saveNewCard: isChecked, cardType: type, orderId, merchantTxId: id, reportGroup,
      vendorTxId: vantivTxnId, payPageRegistrationId: paypageRegistrationId, cardNumber: number, expirationDate: expiry,
      cvc: code, location: {latitude: `${curLocation.coords.latitude}`, longitude: `${curLocation.coords.longitude}`}
    };

    ServiceClient.deposit(data).then(() => {
      Context.setLoadingOverlay(false);
      Context.getUser().then();

      Context.setCancelModalConfig({
        visible: true, title: 'Success', titleColor: MainStyle.c_green, leftBtnText: 'OK',
        subtitle: 'You have successfully replenished your balance.',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate('challenges');
        }
      });

    }).catch((err: AxiosError<{ success: boolean, message: string }>) => {
      console.log('deposit error', err);
      Context.setLoadingOverlay(false);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const getFieldError = (key: string) => {
    return formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors];
  };

  useEffect(() => {
    if (!user) return;
    formik.setFieldValue('name', `${user.realName} ${user.lastname}`);
  }, [user]);

  return (
    <>
      <View style={MainStyle.h_72}/>

      <Input
        value={formik.values.name}
        onChangeText={formik.handleChange('name')}
        onBlur={formik.handleBlur('name')}
        placeholder="Cardholder’s Name"
        editable={false}
      />

      <View style={[MainStyle.column, MainStyle.j_c_s_between]}>
        <View style={[MainStyle.full_w, MainStyle.p_t_19]}>
          <Input
            label="CARD NUMBER"
            type="numeric"
            onBlur={formik.handleBlur('number')}
            error={formik.touched.number && !numberValidation.isValid && 'Invalid Card Number'}
            style={MainStyle.m_b_21}
            {...numberMaskedInputProps}
            placeholder="1234 5678 1234 5678"
          />

          <View style={[MainStyle.row, MainStyle.j_c_s_between, MainStyle.m_b_15]}>
            <Input
              type="numeric" w={175}
              onBlur={formik.handleBlur('expiry')}
              error={getFieldError('expiry')}
              {...expiryMaskedInputProps}
              label="EXPIRY" placeholder="MM/YY"
            />

            <Input
              type="numeric" w={175}
              onBlur={formik.handleBlur('cvc')}
              error={formik.touched.cvc && numberValidation.card && formik.values.cvc.length !== numberValidation.card.code.size && 'Invalid CVC'}
              {...codeMaskedInputProps}
              placeholder="CVC" label="CVC"
            />

            {/*<Input label="ZIP CODE" placeholder="Zip Code" w={120} type="numeric"/>*/}
          </View>

          {/* {isSm && (
            <Pressable style={[MainStyle.row, MainStyle.a_i_c]} onPress={() => setIsChecked(!isChecked)}>
              <Checkbox value={isChecked} onValueChange={setIsChecked}/>
              <Text style={styles.label_s}>Save Card</Text>
            </Pressable>
          )} */}
        </View>

        <View style={[MainStyle.w_164, MainStyle.a_i_c, MainStyle.p_t_60, MainStyle.m_x_auto]}>
          <Text style={styles[isSm ? 'amount_s' : 'amount_m']}>${amount ? numberWithCommas(amount) : 0}</Text>

          <Button
            text="DEPOSIT"
            onPress={() => formik.handleSubmit()}
            w={isSm ? 164 : 240}
            h={isSm ? 40 : 56}
            fontSize={isSm ? 16 : 24}
            mb={16}
            style={styles.btn_shadow}
          />

          <Button
            text="BACK"
            onPress={props.goBack}
            w={isSm ? 164 : 240}
            h={isSm ? 40 : 56}
            fontSize={isSm ? 16 : 24}
            bg={colors.unselect}
            style={styles.btn_shadow}
          />

          <Pressable
            style={[MainStyle.row, MainStyle.a_i_c, isSm ? MainStyle.m_t_34 : MainStyle.m_t_50, MainStyle.m_b_45]}
            onPress={() => setIsChecked(!isChecked)}>
            <Checkbox value={isChecked} onValueChange={() => setIsChecked(!isChecked)} size={18}/>
            <Text style={[isSm ? styles.label_s : styles.label_m]}>Save Card</Text>
          </Pressable>
        </View>
      </View>

      <table style={{position: 'absolute', zIndex: -1, opacity: 0}}>
        <tbody>
        <tr>
          <td>Credit Card</td>
          <td><input type="text" id="ccNum" name="ccNum" size={20}/></td>
        </tr>
        <tr>
          <td>CVV</td>
          <td><input type="text" id="cvvNum" name="cvvNum" size={5}/></td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

const styles = {
  amount_l: [
    MainStyle.max_w_323, MainStyle.m_b_6, MainStyle.f_w_600, MainStyle.c_lightblue, MainStyle.l_h_78, MainStyle.f_s_60
  ],
  amount_s: [MainStyle.m_b_38, MainStyle.f_w_600, MainStyle.f_s_28, MainStyle.c_lightblue, MainStyle.l_h_27],
  amount_m: [MainStyle.m_b_45, MainStyle.f_w_600, MainStyle.f_s_48, MainStyle.c_lightblue, MainStyle.l_h_27],
  label_s: [MainStyle.f_w_600, MainStyle.f_s_14, MainStyle.c_white, MainStyle.m_l_8],
  label_m: [MainStyle.f_w_600, MainStyle.f_s_16, MainStyle.c_white, MainStyle.m_l_8],
  label_l: [MainStyle.f_w_600, MainStyle.f_s_22, MainStyle.c_white, MainStyle.m_l_22],
  btn_shadow: {
    "shadowOffset": {
      "width": 0,
      "height": 4
    },
    "shadowRadius": 4,
    "shadowColor": "rgba(0, 0, 0, 0.25)",
    "shadowOpacity": 1
  },
};

export default React.memo(OnlinePayment);
