export const colors = {
  mainBg: '#000000',
  layoutBg: 'rgb(64, 64, 64)',
  modalBg: 'rgba(0, 0, 0, 0.95)',
  bottomTabBg: '#0E2338',
  whiteText: '#F5F5F5',
  lightText: '#FAFAFA',
  darkText: 'rgba(255, 255, 255, 0.3)',
  registered: 'rgba(255, 255, 255, 0.15)',
  settings_border: 'rgba(255, 255, 255, 0.1)',
  settings_bg: '#121D31',
  deposit_bg: '#0D282B',
  activeDarkText: '#0F8300',
  activeText: '#209D00',
  activeLightText: '#14FF00',
  warningText: '#D90000',
  blueText: '#2E75BA',
  greenText: '#91c859',
  newGreenText: '#1CE783',
  inActiveBtnBg: '#5b5b5b',
  inActiveBtnText: '#7b7b7b',
  inputBorder: '#588396',
  popupDangerPrimary: '#FF6262',
  popupDropBg: 'rgba(0, 0, 0, 0.5)',
  popupBorder: '#B1D5EA',
  popupPrimaryButtonBg: '#209D01',
  blue: 'rgba(46, 117, 186, 0.5)',
  primary: '#219C02',
  darkOverlay: 'rgba(0, 0, 0, 0.9)',
  deepBlue: '#19436C',
  lightBlue: '#85BED1',
  bg_contest: '#08121D',
  darkYellow: '#FABF41',
  lightYellow: '#FAFF00',
  unselect: '#585858',
  unselectDark: 'rgba(88, 88, 88, 0.5)',
  amount: '#143352',
  bird_wrap: 'rgba(9, 23, 36, 0.65)',
  gray: 'rgba(255, 255, 255, 0.5)',
  milk: '#D9D9D9',
  green_area: '#009F09',
  divider: 'rgba(255, 255, 255, 0.7)',
  newGreen: '#10884D',
  newDangerText: '#FF6565',
  iconColor: '#2791B5',
  darkNewGreen: 'rgba(28, 231, 131, 0.5)',
  balanceColor: '#03FF83',
  btn_bg_color: '#07111C',
  white: '#FFFFFF',
  darkBlue: '#1C4872',
  mainBackground: '#0B1321',
  filter_chip_bg: 'rgba(0, 123, 193, 1)',
  filter_chip_bg2: 'rgba(0, 123, 193, 0.1)',
  disable_chip_text: '#92C0DB',
  disable_chip: '#004065',
  filter_modal_bg: 'rgba(0, 0, 0, 0.7)',
  id_text: '#CBCBCB',
  status_border: '#005C09',
  availability: '#006F39',
  caret: '#3BFF2E',
  state_color: '#006F38',
  state_completed: '#7F7F7F',
  state_cancelled: '#FF0000',
  divider_bg: '#545A64',
  cardInfoBorder: 'rgba(255, 255, 255, 0.1)',
  pay_table_border: '#2A3446',
  stop_auto_play: '#2F3642',
  placeholder: '#808080'
};
