import {createContext, ReactNode, useEffect, useState} from "react";
import {
  EmailLoginRequest, GetBalanceRequest, GetTransactionHistoryRequest, GetUserRequest, ImageLoginRequest,
  RegisterUserRequest, Session, User, Error, SmsLoginRequest
} from "../../generated/smg_service_pb";
import {SMG_Client, Test_Client} from "../constants";
import {CancelModalProps, ExtendedUserDataType, IContestEntryModal, registerUserPayloadType} from "../types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Buffer} from "buffer";
import moment from 'moment';
import ServiceClient from "../utils/ServiceClient";
import MainStyle from "../../MainStyle";

const defaultCancelModalConfig = {visible: false, title: '', subtitle: ''};

type initTypes = {
  user: User.AsObject | undefined,
  getUser: (Session?: Session.AsObject) => Promise<User.AsObject>,
  session: Session.AsObject | undefined,
  setSession: (val: Session.AsObject) => void,
  logout: () => Promise<boolean>,
  registerUser: (val: registerUserPayloadType) => Promise<Session.AsObject>,
  emailLogin: (email: string, password: string) => Promise<true>,
  imageLogin: (val: string) => Promise<true>,
  cancelModalConfig: CancelModalProps,
  setCancelModalConfig: (val: CancelModalProps) => void,
  loadingOverlay: boolean,
  setLoadingOverlay: (val: boolean) => void,
  getBalance: () => Promise<number | Error.AsObject>,
  balance: number,
  isSuccessMessageShow: string,
  setIsSuccessMessageShow: (message: string) => void
  contestEntryModalConfig: IContestEntryModal,
  setContestEntryModalConfig: (val: IContestEntryModal) => void,
  isHelpModalVisible: boolean,
  setIsHelpModalVisible: (val: boolean) => void,
  getTransactionHistory: () => Promise<any>,
  isTest: boolean,
  setIsTest: (val: boolean) => void,
  onLine: boolean,
  setOnLine: (val: boolean) => void,
  totalBonuses: number,
  smsLogin: (email: string, code: string) => Promise<true>,
  extendedUserData: ExtendedUserDataType,
  setUser: (val: User.AsObject) => void
};

const defaultValue: initTypes = {
  user: undefined,
  getUser: (): any => {
  },
  session: undefined,
  setSession: () => {
  },
  logout: (): any => {
  },
  registerUser: (): any => {
  },
  emailLogin: (): any => {
  },
  imageLogin: (): any => {
  },
  cancelModalConfig: defaultCancelModalConfig,
  setCancelModalConfig: () => {
  },
  loadingOverlay: false,
  setLoadingOverlay: () => {
  },
  getBalance: (): any => {
  },
  balance: 0,
  isSuccessMessageShow: '',
  setIsSuccessMessageShow: () => {
  },
  contestEntryModalConfig: {},
  setContestEntryModalConfig: () => {
  },
  isHelpModalVisible: false,
  setIsHelpModalVisible: () => {
  },
  getTransactionHistory: (): any => {
  },
  isTest: false,
  setIsTest: () => {
  },
  onLine: true,
  setOnLine: () => {
  },
  totalBonuses: 0,
  smsLogin: (): any => {
  },
  extendedUserData: {
    maxshotamount: 0, hasactivetournament: false, freeshotcount: 0, freeshotnumber: 0, isnewplayer: false
  },
  setUser: () => {
  }
};

export const StoreContext = createContext(defaultValue);

type Props = { children: ReactNode };
export const StoreContextProvider = (props: Props) => {
  const {children} = props;
  const [user, setUser] = useState<User.AsObject>();
  const [session, setSession] = useState<Session.AsObject>();
  const [cancelModalConfig, setCancelModalConfig] = useState<CancelModalProps>(defaultCancelModalConfig);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isSuccessMessageShow, setIsSuccessMessageShow] = useState('');
  const [contestEntryModalConfig, setContestEntryModalConfig] = useState<IContestEntryModal>({visible: false});
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const client = isTest ? Test_Client : SMG_Client;
  const [onLine, setOnLine] = useState(true);
  const [totalBonuses, setTotalBonuses] = useState(0);
  const [extendedUserData, setExtendedUserData] = useState<ExtendedUserDataType>({
    maxshotamount: 0, hasactivetournament: false, freeshotcount: 0, freeshotnumber: 0, isnewplayer: false
  });

  const getTransactionHistory = async () => {
    const request = new GetTransactionHistoryRequest();
    request.setUserId(user?.userId || 0);

    return client.getTransactionHistory(request, {}).then(res => {
      const {success, error, historyList} = res.toObject();

      if (success) return historyList;
      else throw error;
    });
  };

  const getBalance = async (localSession?: Session.AsObject): Promise<number | Error.AsObject> => {
    const request = new GetBalanceRequest();
    request.setToken(localSession?.token || session?.token || '');

    return client.getBalance(request, {}).then(res => {
      const {success, balance, error, totalBonusesBalance} = res.toObject();

      if (success) {
        setTotalBonuses(totalBonusesBalance);
        setBalance(balance);
        return balance;

      } else throw error;

    }).catch(err => {
      console.log('getBalance', err);
      return err;
    });
  };

  const logout = async () => {
    await AsyncStorage.removeItem('session');

    setUser(undefined);
    setSession(undefined);
    setBalance(0);
    setExtendedUserData({
      maxshotamount: 0, hasactivetournament: false, freeshotcount: 0, freeshotnumber: 0, isnewplayer: false
    });
    return true;
  };

  const imageLogin = async (image: string) => {
    const request = new ImageLoginRequest();
    const byteArray = Buffer.from(image, 'base64');

    request.setImage(byteArray);

    return client.imageLogin(request, {}).then(res => {
      const {success, user, session, error} = res.toObject();

      if (user && user.dateOfBirth && moment().diff(moment(user.dateOfBirth), 'years') < 18) {
        throw {message: 'You have successfully completed registration. However, you must be 18 or older to play.'};

      } else if (success && session && user) {
        setSession(session);
        getBalance(session).then();
        AsyncStorage.setItem('session', JSON.stringify(session));
        setUser(user);
        return success;

      } else throw error;
    });
  };

  const smsLogin = async (email: string, code: string) => {
    const request = new SmsLoginRequest();
    request.setEmailAddress(email);
    request.setSmsCode(code);

    return client.smsLogin(request, {}).then(res => {
      const {success, error, session, user} = res.toObject();

      if (user && user.dateOfBirth && moment().diff(moment(user.dateOfBirth), 'years') < 18) {
        throw {message: 'You have successfully completed registration. However, you must be 18 or older to play.'};

      } else if (success && session) {
        setSession(session);
        AsyncStorage.setItem('session', JSON.stringify(session)).then();
        return success;

      } else throw error;
    });
  };

  const emailLogin = async (email: string, password: string) => {
    const request = new EmailLoginRequest();
    request.setEmailAddress(email);
    request.setPassword(password);

    return client.emailLogin(request, {}).then(res => {
      const {error, session, user, success} = res.toObject();

      if (user && user.dateOfBirth && moment().diff(moment(user.dateOfBirth), 'years') < 18) {
        throw {message: 'You have successfully completed registration. However, you must be 18 or older to play.'};

      } else if (success && session && user) {
        setSession(session);
        getBalance(session).then();
        AsyncStorage.setItem('session', JSON.stringify(session));
        setUser(user);
        return success;

      } else throw error;
    });
  };

  const registerUser = async (values: registerUserPayloadType) => {
    const {email, smsCode, phone, username, handedness, handicap, dateOfBirth, firstname, lastname} = values;
    const request = new RegisterUserRequest();

    request.setEmailAddress(email);
    request.setUsername(username);
    request.setSmscode(smsCode);
    request.setPhonenumber(phone);
    request.setHandedness(handedness);
    request.setHandicap(handicap);
    if (dateOfBirth) request.setDateOfBirth(dateOfBirth);
    request.setFirstname(firstname);
    request.setLastname(lastname);

    return client.registerUser(request, {}).then(res => {
      const {success, error, session} = res.toObject();

      if (success && session) {
        AsyncStorage.setItem('session', JSON.stringify(session));
        setSession(session);
        return session;

      } else throw error;
    });
  };

  const getUser = async (localSession?: Session.AsObject) => {
    const request = new GetUserRequest();
    request.setToken(localSession?.token || session?.token || '');

    return client.getUser(request, {}).then(res => {
      const {
        success, user, error, maxshotamount, hasactivetournament, freeshotcount, freeshotnumber, isnewplayer
      } = res.toObject();

      if (success && user) {
        getBalance(localSession).then();
        setExtendedUserData({maxshotamount, hasactivetournament, freeshotcount, freeshotnumber, isnewplayer});
        setTimeout(() => setUser(user), 150);

        if (extendedUserData.maxshotamount && (extendedUserData.maxshotamount < maxshotamount)) {
          setCancelModalConfig({
            visible: true, title: 'Success', titleColor: MainStyle.c_green, leftBtnText: 'OK',
            subtitle: `Your Max Challenge Fee has increased to $${maxshotamount / 100}!\n\nYou can adjust your Challenge Fee in the app.`,
            onExit: () => setCancelModalConfig({...cancelModalConfig, visible: false})
          });
        }

        return user;

      } else throw error;
    });
  };

  useEffect(() => {
    ServiceClient.client = isTest ? Test_Client : SMG_Client;
    if (session) ServiceClient.session = session;
  }, [isTest, session]);

  const providerValue = {
    user, getUser, setSession, registerUser, emailLogin, imageLogin, session, cancelModalConfig, setCancelModalConfig,
    logout, loadingOverlay, setLoadingOverlay, getBalance, balance, isSuccessMessageShow, setIsSuccessMessageShow,
    contestEntryModalConfig, setContestEntryModalConfig, isHelpModalVisible, setIsHelpModalVisible,
    getTransactionHistory, isTest, setIsTest, onLine, setOnLine, totalBonuses, smsLogin, extendedUserData, setUser
  };

  return (
    <StoreContext.Provider value={providerValue}>
      {children}
    </StoreContext.Provider>
  );
};
