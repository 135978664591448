import React, {useContext, useEffect, useState} from 'react';
import {Modal, Text, View, Image} from 'react-native';
import Button from '../components/Button';
import CodeVerificationInput from '../components/CodeVerificationInput';
import ServiceClient from "../utils/ServiceClient";
import {StoreContext} from "../components/StoreContextProvider";
import {useNavigation} from "@react-navigation/native";
import MainStyle from "../../MainStyle";
import bg from '../assets/img/Group 2494.svg';

type Props = {
  visible: boolean, setVisible: (code?: string) => void, email: string, phone?: string, goBackBtn?: boolean,
  onSuccess?: (email: string) => void
};

const OtpModal = (props: Props) => {
  const {visible, email, phone, goBackBtn} = props;
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const Context = useContext(StoreContext);
  const [isCodeWasResend, setIsCodeWasResend] = useState(false);
  const navigation: any = useNavigation();
  const [bgColor, setBgColor] = useState(MainStyle.bg_transparent);

  const smsLogin = () => {
    Context.setLoadingOverlay(true);

    Context.smsLogin(email, code).then(() => {
      Context.setLoadingOverlay(false);
      props.setVisible();
      if (props.onSuccess) props.onSuccess(email);
      else navigation.reset({index: 0, routes: [{name: 'challenges'}]});

    }).catch((err) => {
      Context.setLoadingOverlay(false);
      console.log('smsLogin err', err);

      if (err.code === 10010008 && err.message === 'User login rejected') {
        setErrorMessage('Your email is not associated with a Player Account. Please create an account or edit login details.');

      } else if (err.code === 10010009 && err.message === 'User login rejected') {
        setErrorMessage('The Pin Code entered does not match your User Account. Please re-enter or select the “Forgot Password” button to reset.');

      } else if (err?.message) {
        setErrorMessage(err.message);
      }
    });
  };

  const resendCode = () => {
    Context.setLoadingOverlay(true);
    const payload = phone ? {phone} : {email};

    ServiceClient.requestSmsCode(payload).then(() => {
      Context.setLoadingOverlay(false);
      setIsCodeWasResend(true);

    }).catch(err => {
      console.log('requestSmsCode err', err);
      Context.setLoadingOverlay(false);

      if (err && err.message) setErrorMessage(err.message || 'Code expired or incorrect');
    });
  };

  const verifySmsCode = (currPhone: string) => {
    Context.setLoadingOverlay(true);

    ServiceClient.verifySmsCode(code, email, currPhone).then(codeIsCorrect => {
      Context.setLoadingOverlay(false);

      if (codeIsCorrect) props.setVisible(code);
      else setErrorMessage('Code expired or incorrect');

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('verifySmsCode err', err);
      if (err && err.message) setErrorMessage(err.message || 'Code expired or incorrect');
    });
  };

  const submit = () => {
    if (phone) verifySmsCode(phone);
    else smsLogin();
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setBgColor(MainStyle.bc_popupDropBg);
      }, 400);

    } else {
      setBgColor(MainStyle.bg_transparent);
    }
  }, [visible]);

  useEffect(() => {
    if (isCodeWasResend) {
      setTimeout(() => {
        setIsCodeWasResend(false);
      }, 3000);
    }
  }, [isCodeWasResend]);

  useEffect(() => {
    if (errorMessage) setErrorMessage('');
  }, [code]);

  return (
    <Modal animationType="slide" transparent visible={visible} onRequestClose={() => props.setVisible()}>
      <View style={[MainStyle.flex1, MainStyle.z_i_2, bgColor]}>
        <View style={MainStyle.flex1}/>

        <View style={styles.container}>
          <Image source={bg as any} style={styles.image}/>

          <Text style={styles.text}>We've sent you a code to your phone for verification.</Text>

          {!!errorMessage ? (
            <Text style={[...styles.text, MainStyle.Barlow700, MainStyle.m_b_30, MainStyle.c_warn]}>
              {errorMessage}
            </Text>
          ) : isCodeWasResend ? (
            <Text style={[...styles.text, MainStyle.Barlow700, MainStyle.m_b_30, MainStyle.c_yellow]}>
              Code was resent
            </Text>
          ) : (
            <Text style={[...styles.text, MainStyle.Barlow700, MainStyle.m_b_30]}>
              Please enter the code below:
            </Text>
          )}

          <CodeVerificationInput onChange={val => setCode(val)}/>

          <View style={[MainStyle.p_x_18, MainStyle.m_b_20]}>
            <Button text="Submit" onPress={submit} w="100%"/>
          </View>

          <View style={[MainStyle.p_x_20, MainStyle.row, MainStyle.m_b_60]}>
            <Button text="Resend Code" bg="transparent" borderWidth={1} borderColor="white" onPress={resendCode}
                    style={MainStyle.flex1} color="white"/>

            {goBackBtn && (
              <>
                <View style={MainStyle.w_10}/>
                <Button text="Back" bg="transparent" borderWidth={1} borderColor="white" style={MainStyle.flex1}
                        onPress={() => props.setVisible()} color="white"/>
              </>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = {
  container: [
    MainStyle.bg_main, MainStyle.full_w, MainStyle.max_w_430, MainStyle.m_x_auto, MainStyle.relative,
    MainStyle.b_c_cardInfoBorder, MainStyle.b_w_x_1
  ],
  text: [
    MainStyle.c_white, MainStyle.Barlow400, MainStyle.t_a_c, MainStyle.f_s_18, MainStyle.w_267,
    MainStyle.m_x_auto, MainStyle.m_b_16
  ],
  image: [MainStyle.h_267, MainStyle.absolute, MainStyle.t__50, MainStyle.z_i__1, MainStyle.l__1, MainStyle.r__1]
};

export default React.memo(OtpModal);
