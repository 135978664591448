import {StyleSheet} from 'react-native';
import {colors} from './src/assets/styles/colors';

const MainStyle = StyleSheet.create({
  border: {borderStyle: 'solid', borderColor: 'red', borderWidth: 1},
  bc_white: {backgroundColor: colors.whiteText},
  bc_gray: {backgroundColor: colors.gray},
  bc_darkgray: {backgroundColor: colors.unselect},
  bc_darkblue: {backgroundColor: colors.bottomTabBg},
  bc_black: {backgroundColor: 'black'},
  bc_popupDropBg: {backgroundColor: colors.popupDropBg},
  bc_green: {backgroundColor: colors.greenText},
  bc_modalBg: {backgroundColor: colors.modalBg},
  bc_layoutBg: {backgroundColor: colors.layoutBg},
  bc_milk: {backgroundColor: colors.milk},
  bc_transparent: {backgroundColor: 'transparent'},
  bc_lightgreen: {backgroundColor: colors.newGreen},
  bc_bird_wrap: {backgroundColor: colors.bird_wrap},
  bc_blueText: {backgroundColor: colors.blueText},
  bc_divider: {backgroundColor: colors.divider},
  bc_green_area: {backgroundColor: colors.green_area},
  bg_contest: {backgroundColor: colors.bg_contest},
  bg_main: {backgroundColor: colors.mainBackground},
  bg_placeholder: {backgroundColor: colors.placeholder},
  bg_amount: {backgroundColor: colors.amount},
  bg_divider: {backgroundColor: colors.divider_bg},
  bg_availability: {backgroundColor: colors.availability},
  bg_settings: {backgroundColor: colors.settings_bg},
  bg_deposit: {backgroundColor: colors.deposit_bg},
  bg_filter_chip: {backgroundColor: colors.filter_chip_bg},
  bg_filter_chip2: {backgroundColor: colors.filter_chip_bg2},
  bg_disable_chip: {backgroundColor: colors.disable_chip},
  bg_filter_modal: {backgroundColor: colors.filter_modal_bg},
  bg_black: {backgroundColor: colors.mainBg},
  bg_newGreen: {backgroundColor: colors.newGreenText},
  bg_registered: {backgroundColor: colors.registered},
  bg_settings_border: {backgroundColor: colors.settings_border},
  bg_darkText: {backgroundColor: colors.darkText},
  bg_darkOverlay: {backgroundColor: colors.darkOverlay},
  bg_transparent: {backgroundColor: 'transparent'},
  max_w_80: {maxWidth: 80},
  max_w_108: {maxWidth: 108},
  max_w_244: {maxWidth: 244},
  max_w_260: {maxWidth: 260},
  max_w_274: {maxWidth: 274},
  max_w_312: {maxWidth: 312},
  max_w_323: {maxWidth: 323},
  max_w_328: {maxWidth: 328},
  max_w_330: {maxWidth: 330},
  max_w_332: {maxWidth: 332},
  max_w_340: {maxWidth: 340},
  max_w_389: {maxWidth: 389},
  max_w_390: {maxWidth: 390},
  max_w_430: {maxWidth: 430},
  max_w_570: {maxWidth: 570},
  max_w_582: {maxWidth: 582},
  max_w_600: {maxWidth: 600},
  max_w_640: {maxWidth: 640},
  max_w_690: {maxWidth: 690},
  max_w_1040: {maxWidth: 1040},
  max_w_1100: {maxWidth: 1100},
  max_w_1120: {maxWidth: 1120},
  max_w_1205: {maxWidth: 1205},
  full_w: {width: '100%'},
  full_h: {height: '100%'},
  p_b_6: {paddingBottom: 6},
  p_b_10: {paddingBottom: 10},
  p_b_11: {paddingBottom: 11},
  p_b_15: {paddingBottom: 15},
  p_b_18: {paddingBottom: 18},
  p_b_20: {paddingBottom: 20},
  p_b_27: {paddingBottom: 27},
  p_b_29: {paddingBottom: 29},
  p_b_30: {paddingBottom: 30},
  p_b_41: {paddingBottom: 41},
  p_b_67: {paddingBottom: 67},
  b_r_1: {borderRadius: 1},
  b_r_2: {borderRadius: 2},
  b_r_4: {borderRadius: 4},
  b_r_5: {borderRadius: 5},
  b_r_8: {borderRadius: 8},
  b_r_9: {borderRadius: 9},
  b_r_16: {borderRadius: 16},
  b_r_20: {borderRadius: 20},
  b_r_40: {borderRadius: 40},
  b_r_50: {borderRadius: 50},
  b_r_70: {borderRadius: 70},
  b_r_125: {borderRadius: 125},
  b_r_150: {borderRadius: 150},
  b_r_200: {borderRadius: 200},
  c_light_blue: {color: colors.popupBorder},
  c_lightText: {color: colors.lightText},
  c_white: {color: colors.whiteText},
  c_darkText: {color: colors.darkText},
  c_mainBackground: {color: colors.mainBackground},
  c_id_text: {color: colors.id_text},
  c_yellow: {color: colors.darkYellow},
  c_light_green: {color: colors.popupPrimaryButtonBg},
  c_balance: {color: colors.balanceColor},
  c_black: {color: colors.mainBg},
  c_gray: {color: '#989898'},
  c_warn: {color: colors.newDangerText},
  c_lightblue: {color: colors.lightBlue},
  c_title_blue: {color: colors.blueText},
  c_popupDangerPrimary: {color: colors.popupDangerPrimary},
  c_green: {color: '#43D120'},
  c_green_text: {color: colors.greenText},
  c_new_green_text: {color: colors.newGreenText},
  c_disable_chip_text: {color: colors.disable_chip_text},
  c_Gray: {color: colors.gray},
  c_state_completed: {color: colors.state_completed},
  m_b__1: {marginBottom: -1},
  m_b_0: {marginBottom: 0},
  m_b_1: {marginBottom: 1},
  m_b_2: {marginBottom: 2},
  m_b_3: {marginBottom: 3},
  m_b_4: {marginBottom: 4},
  m_b_5: {marginBottom: 5},
  m_b_6: {marginBottom: 6},
  m_b_7: {marginBottom: 7},
  m_b_8: {marginBottom: 8},
  m_b_9: {marginBottom: 9},
  m_b_10: {marginBottom: 10},
  m_b_11: {marginBottom: 11},
  m_b_12: {marginBottom: 12},
  m_b_13: {marginBottom: 13},
  m_b_14: {marginBottom: 14},
  m_b_15: {marginBottom: 15},
  m_b_16: {marginBottom: 16},
  m_b_17: {marginBottom: 17},
  m_b_18: {marginBottom: 18},
  m_b_19: {marginBottom: 19},
  m_b_20: {marginBottom: 20},
  m_b_21: {marginBottom: 21},
  m_b_22: {marginBottom: 22},
  m_b_24: {marginBottom: 24},
  m_b_25: {marginBottom: 25},
  m_b_26: {marginBottom: 26},
  m_b_27: {marginBottom: 27},
  m_b_28: {marginBottom: 28},
  m_b_29: {marginBottom: 29},
  m_b_30: {marginBottom: 30},
  m_b_31: {marginBottom: 31},
  m_b_32: {marginBottom: 32},
  m_b_33: {marginBottom: 33},
  m_b_34: {marginBottom: 34},
  m_b_36: {marginBottom: 36},
  m_b_37: {marginBottom: 37},
  m_b_38: {marginBottom: 38},
  m_b_40: {marginBottom: 40},
  m_b_42: {marginBottom: 42},
  m_b_45: {marginBottom: 45},
  m_b_46: {marginBottom: 46},
  m_b_48: {marginBottom: 48},
  m_b_50: {marginBottom: 50},
  m_b_52: {marginBottom: 52},
  m_b_57: {marginBottom: 57},
  m_b_60: {marginBottom: 60},
  m_b_62: {marginBottom: 62},
  m_b_64: {marginBottom: 64},
  m_b_65: {marginBottom: 65},
  m_b_70: {marginBottom: 70},
  m_b_72: {marginBottom: 72},
  m_b_74: {marginBottom: 74},
  m_b_80: {marginBottom: 80},
  m_b_86: {marginBottom: 86},
  m_b_101: {marginBottom: 101},
  m_b_110: {marginBottom: 110},
  m_b_112: {marginBottom: 112},
  m_b_125: {marginBottom: 125},
  m_b_129: {marginBottom: 129},
  m_b_158: {marginBottom: 158},
  flex: {display: 'flex'},
  row: {display: 'flex', flexDirection: 'row'},
  column: {display: 'flex', flexDirection: 'column'},
  a_i_c: {alignItems: 'center'},
  a_i_e: {alignItems: 'flex-end'},
  a_i_s: {alignItems: 'stretch'},
  a_i_start: {alignItems: 'flex-start'},
  o_0: {opacity: 0},
  o_01: {opacity: 0.1},
  o_03: {opacity: 0.3},
  o_05: {opacity: 0.5},
  o_06: {opacity: 0.6},
  w_1: {width: 1},
  w_4: {width: 4},
  w_5: {width: 5},
  w_6: {width: 6},
  w_7: {width: 7},
  w_8: {width: 8},
  w_9: {width: 9},
  w_10: {width: 10},
  w_12: {width: 12},
  w_13: {width: 13},
  w_14: {width: 14},
  w_15: {width: 15},
  w_16: {width: 16},
  w_19: {width: 19},
  w_20: {width: 20},
  w_21: {width: 21},
  w_24: {width: 24},
  w_28: {width: 28},
  w_30: {width: 30},
  w_32: {width: 32},
  w_36: {width: 36},
  w_37: {width: 37},
  w_38: {width: 38},
  w_40: {width: 40},
  w_41: {width: 41},
  w_43: {width: 43},
  w_44: {width: 44},
  w_45: {width: 45},
  w_46: {width: 46},
  w_48: {width: 48},
  w_49: {width: 49},
  w_50: {width: 50},
  w_52: {width: 52},
  w_53: {width: 53},
  w_55: {width: 55},
  w_56: {width: 56},
  w_60: {width: 60},
  w_64: {width: 64},
  w_65: {width: 65},
  w_67: {width: 67},
  w_68: {width: 68},
  w_70: {width: 70},
  w_72: {width: 72},
  w_76: {width: 76},
  w_80: {width: 80},
  w_82: {width: 82},
  w_83: {width: 83},
  w_85: {width: 85},
  w_86: {width: 86},
  w_88: {width: 88},
  w_92: {width: 92},
  w_95: {width: 95},
  w_96: {width: 96},
  w_100: {width: 100},
  w_102: {width: 102},
  w_108: {width: 108},
  w_116: {width: 116},
  w_118: {width: 118},
  w_120: {width: 120},
  w_129: {width: 129},
  w_130: {width: 130},
  w_136: {width: 136},
  w_144: {width: 144},
  w_152: {width: 152},
  w_155: {width: 155},
  w_158: {width: 158},
  w_160: {width: 160},
  w_164: {width: 164},
  w_165: {width: 165},
  w_170: {width: 170},
  w_180: {width: 180},
  w_190: {width: 190},
  w_199: {width: 199},
  w_194: {width: 194},
  w_200: {width: 200},
  w_213: {width: 213},
  w_220: {width: 220},
  w_224: {width: 224},
  w_225: {width: 225},
  w_228: {width: 228},
  w_240: {width: 240},
  w_244: {width: 244},
  w_248: {width: 248},
  w_250: {width: 250},
  w_255: {width: 255},
  w_261: {width: 261},
  w_267: {width: 267},
  w_280: {width: 280},
  w_286: {width: 286},
  w_300: {width: 300},
  w_330: {width: 330},
  w_332: {width: 332},
  w_333: {width: 333},
  w_337: {width: 337},
  w_340: {width: 340},
  w_348: {width: 348},
  w_350: {width: 350},
  w_355: {width: 355},
  w_359: {width: 359},
  w_367: {width: 367},
  w_368: {width: 368},
  w_370: {width: 370},
  w_387: {width: 387},
  w_389: {width: 389},
  w_390: {width: 390},
  w_416: {width: 416},
  w_439: {width: 439},
  w_480: {width: 480},
  w_496: {width: 496},
  w_560: {width: 560},
  w_640: {width: 640},
  w_973: {width: 973},
  w_50_percent: {width: '50%'},
  h_1: {height: 1},
  h_2: {height: 2},
  h_4: {height: 4},
  h_5: {height: 5},
  h_6: {height: 6},
  h_7: {height: 7},
  h_9: {height: 9},
  h_12: {height: 12},
  h_13: {height: 13},
  h_16: {height: 16},
  h_18: {height: 18},
  h_20: {height: 20},
  h_21: {height: 21},
  h_22: {height: 22},
  h_24: {height: 24},
  h_25: {height: 25},
  h_26: {height: 26},
  h_28: {height: 28},
  h_30: {height: 30},
  h_32: {height: 32},
  h_33: {height: 33},
  h_34: {height: 34},
  h_35: {height: 35},
  h_36: {height: 36},
  h_37: {height: 37},
  h_38: {height: 38},
  h_40: {height: 40},
  h_41: {height: 41},
  h_45: {height: 45},
  h_48: {height: 48},
  h_49: {height: 49},
  h_50: {height: 50},
  h_51: {height: 51},
  h_52: {height: 52},
  h_55: {height: 55},
  h_56: {height: 56},
  h_58: {height: 58},
  h_60: {height: 60},
  h_61: {height: 61},
  h_62: {height: 62},
  h_63: {height: 63},
  h_64: {height: 64},
  h_66: {height: 66},
  h_68: {height: 68},
  h_70: {height: 70},
  h_72: {height: 72},
  h_74: {height: 74},
  h_80: {height: 80},
  h_90: {height: 90},
  h_92: {height: 92},
  h_95: {height: 95},
  h_96: {height: 96},
  h_102: {height: 102},
  h_120: {height: 120},
  h_125: {height: 125},
  h_128: {height: 128},
  h_150: {height: 150},
  h_159: {height: 159},
  h_170: {height: 170},
  h_176: {height: 176},
  h_187: {height: 187},
  h_212: {height: 212},
  h_221: {height: 221},
  h_240: {height: 240},
  h_253: {height: 253},
  h_267: {height: 267},
  h_300: {height: 300},
  h_350: {height: 350},
  h_400: {height: 400},
  h_493: {height: 493},
  h_630: {height: 630},
  h_700: {height: 700},
  h_auto: {height: 'auto'},
  m_y_5: {marginVertical: 5},
  m_y_6: {marginVertical: 6},
  m_y_8: {marginVertical: 8},
  m_y_9: {marginVertical: 9},
  m_y_10: {marginVertical: 10},
  m_y_20: {marginVertical: 20},
  t_a_c: {textAlign: 'center'},
  t_a_r: {textAlign: 'right'},
  t_a_l: {textAlign: 'left'},
  f_s_8: {fontSize: 8},
  f_s_10: {fontSize: 10},
  f_s_11: {fontSize: 11},
  f_s_12: {fontSize: 12},
  f_s_13: {fontSize: 13},
  f_s_14: {fontSize: 14},
  f_s_15: {fontSize: 15},
  f_s_16: {fontSize: 16},
  f_s_17: {fontSize: 17},
  f_s_18: {fontSize: 18},
  f_s_19: {fontSize: 19},
  f_s_20: {fontSize: 20},
  f_s_21: {fontSize: 21},
  f_s_22: {fontSize: 22},
  f_s_23: {fontSize: 23},
  f_s_24: {fontSize: 24},
  f_s_25: {fontSize: 25},
  f_s_26: {fontSize: 26},
  f_s_28: {fontSize: 28},
  f_s_30: {fontSize: 30},
  f_s_32: {fontSize: 32},
  f_s_34: {fontSize: 34},
  f_s_36: {fontSize: 36},
  f_s_40: {fontSize: 40},
  f_s_48: {fontSize: 48},
  f_s_52: {fontSize: 52},
  f_s_56: {fontSize: 56},
  f_s_60: {fontSize: 60},
  m_t__200: {marginTop: -200},
  m_t__150: {marginTop: -150},
  m_t__105: {marginTop: -105},
  m_t__75: {marginTop: -75},
  m_t__64: {marginTop: -64},
  m_t__47: {marginTop: -47},
  m_t__32: {marginTop: -32},
  m_t__17: {marginTop: -17},
  m_t__1: {marginTop: -1},
  m_t_3: {marginTop: 3},
  m_t_6: {marginTop: 6},
  m_t_9: {marginTop: 9},
  m_t_10: {marginTop: 10},
  m_t_12: {marginTop: 12},
  m_t_13: {marginTop: 13},
  m_t_14: {marginTop: 14},
  m_t_17: {marginTop: 17},
  m_t_20: {marginTop: 20},
  m_t_28: {marginTop: 28},
  m_t_30: {marginTop: 30},
  m_t_34: {marginTop: 34},
  m_t_41: {marginTop: 41},
  m_t_48: {marginTop: 48},
  m_t_50: {marginTop: 50},
  m_t_64: {marginTop: 64},
  m_t_114: {marginTop: 114},
  j_c_c: {justifyContent: 'center'},
  j_c_e: {justifyContent: 'flex-end'},
  j_c_s: {justifyContent: 'flex-start'},
  j_c_s_between: {justifyContent: 'space-between'},
  j_c_s_around: {justifyContent: 'space-around'},
  flex1: {flex: 1},
  p_9: {padding: 9},
  p_10: {padding: 10},
  p_18: {padding: 18},
  p_20: {padding: 20},
  o_hidden: {overflow: 'hidden'},
  m_l__120: {marginLeft: -120},
  m_l__90: {marginLeft: -90},
  m_l__40: {marginLeft: -40},
  m_l__30: {marginLeft: -30},
  m_l__24: {marginLeft: -24},
  m_l__35: {marginLeft: -35},
  m_l__32: {marginLeft: -32},
  m_l__18: {marginLeft: -18},
  m_l_0: {marginLeft: 0},
  m_l_2: {marginLeft: 2},
  m_l_4: {marginLeft: 4},
  m_l_6: {marginLeft: 6},
  m_l_7: {marginLeft: 7},
  m_l_8: {marginLeft: 8},
  m_l_9: {marginLeft: 9},
  m_l_10: {marginLeft: 10},
  m_l_12: {marginLeft: 12},
  m_l_13: {marginLeft: 13},
  m_l_14: {marginLeft: 14},
  m_l_15: {marginLeft: 15},
  m_l_17: {marginLeft: 17},
  m_l_20: {marginLeft: 20},
  m_l_21: {marginLeft: 21},
  m_l_22: {marginLeft: 22},
  m_l_24: {marginLeft: 24},
  m_l_26: {marginLeft: 26},
  m_l_32: {marginLeft: 32},
  m_l_36: {marginLeft: 36},
  m_l_37: {marginLeft: 37},
  m_l_52: {marginLeft: 52},
  m_l_77: {marginLeft: 77},
  b_b_w_1: {borderBottomWidth: 1},
  b_b_w_3: {borderBottomWidth: 3},
  b_c_transparent: {borderColor: 'transparent'},
  b_c_black: {borderColor: 'black'},
  b_c_gray: {borderColor: colors.gray},
  b_c_blue: {borderColor: colors.blue},
  b_c_white: {borderColor: colors.whiteText},
  b_c_pay_table_border: {borderColor: colors.pay_table_border},
  b_c_cardInfoBorder: {borderColor: colors.cardInfoBorder},
  b_c_warn: {borderColor: colors.newDangerText},
  b_c_yellow: {borderColor: colors.darkYellow},
  b_c_blueText: {borderColor: colors.blueText},
  b_c_popupBorder: {borderColor: colors.popupBorder},
  b_c_settings_border: {borderColor: colors.settings_border},
  b_c_darkText: {borderColor: colors.darkText},
  b_c_newGreen: {borderColor: colors.newGreenText},
  b_c_status_border: {borderColor: colors.status_border},
  b_c_filter_chip_bg: {borderColor: colors.filter_chip_bg},
  p_x_4: {paddingHorizontal: 4},
  p_x_5: {paddingHorizontal: 5},
  p_x_7: {paddingHorizontal: 7},
  p_x_8: {paddingHorizontal: 8},
  p_x_9: {paddingHorizontal: 9},
  p_x_10: {paddingHorizontal: 10},
  p_x_11: {paddingHorizontal: 11},
  p_x_12: {paddingHorizontal: 12},
  p_x_14: {paddingHorizontal: 14},
  p_x_15: {paddingHorizontal: 15},
  p_x_16: {paddingHorizontal: 16},
  p_x_18: {paddingHorizontal: 18},
  p_x_20: {paddingHorizontal: 20},
  p_x_24: {paddingHorizontal: 24},
  p_x_26: {paddingHorizontal: 26},
  p_x_27: {paddingHorizontal: 27},
  p_x_30: {paddingHorizontal: 30},
  p_x_32: {paddingHorizontal: 32},
  p_x_36: {paddingHorizontal: 36},
  p_x_45: {paddingHorizontal: 45},
  p_x_80: {paddingHorizontal: 80},
  m_r_0: {marginRight: 0},
  m_r_2: {marginRight: 2},
  m_r_3: {marginRight: 3},
  m_r_4: {marginRight: 4},
  m_r_5: {marginRight: 5},
  m_r_6: {marginRight: 6},
  m_r_7: {marginRight: 7},
  m_r_8: {marginRight: 8},
  m_r_9: {marginRight: 9},
  m_r_10: {marginRight: 10},
  m_r_14: {marginRight: 14},
  m_r_21: {marginRight: 21},
  m_r_15: {marginRight: 15},
  m_r_17: {marginRight: 17},
  m_r_24: {marginRight: 24},
  m_r_32: {marginRight: 32},
  relative: {position: 'relative'},
  p_l_9: {paddingLeft: 9},
  p_l_10: {paddingLeft: 10},
  p_l_11: {paddingLeft: 11},
  p_l_14: {paddingLeft: 14},
  p_l_16: {paddingLeft: 16},
  p_l_20: {paddingLeft: 20},
  p_l_23: {paddingLeft: 23},
  p_l_32: {paddingLeft: 32},
  p_l_48: {paddingLeft: 48},
  p_r_0: {paddingRight: 0},
  p_r_4: {paddingRight: 4},
  p_r_6: {paddingRight: 6},
  p_r_10: {paddingRight: 10},
  p_r_18: {paddingRight: 18},
  p_r_34: {paddingRight: 34},
  p_r_80: {paddingRight: 80},
  f_w_400: {fontWeight: '400'},
  f_w_500: {fontWeight: '500'},
  f_w_600: {fontWeight: '600'},
  f_w_700: {fontWeight: '700'},
  f_w_800: {fontWeight: '800'},
  f_w_900: {fontWeight: '900'},
  m_x_auto: {marginHorizontal: 'auto'},
  m_x_2: {marginHorizontal: 2},
  m_x_5: {marginHorizontal: 5},
  m_x_8: {marginHorizontal: 8},
  m_x_10: {marginHorizontal: 10},
  m_x_11: {marginHorizontal: 11},
  m_x_12: {marginHorizontal: 12},
  m_x_15: {marginHorizontal: 15},
  m_x_16: {marginHorizontal: 16},
  m_x_19: {marginHorizontal: 19},
  m_x_20: {marginHorizontal: 20},
  m_x_23: {marginHorizontal: 23},
  m_x_30: {marginHorizontal: 30},
  m_x_32: {marginHorizontal: 32},
  m_x_35: {marginHorizontal: 35},
  m_x_40: {marginHorizontal: 40},
  m_x_42: {marginHorizontal: 42},
  m_x_56: {marginHorizontal: 56},
  min_h_32: {minHeight: 32},
  min_h_164: {minHeight: 164},
  min_h_260: {minHeight: 260},
  min_h_330: {minHeight: 330},
  min_h_400: {minHeight: 400},
  min_h_452: {minHeight: 452},
  min_h_477: {minHeight: 477},
  min_h_full: {minHeight: '100%'},
  p_y_0: {paddingVertical: 0},
  p_y_4: {paddingVertical: 4},
  p_y_10: {paddingVertical: 10},
  p_y_11: {paddingVertical: 11},
  p_y_23: {paddingVertical: 23},
  p_y_19: {paddingVertical: 19},
  p_y_26: {paddingVertical: 26},
  p_y_70: {paddingVertical: 70},
  p_y_114: {paddingVertical: 114},
  b_t_r_9: {borderTopLeftRadius: 9, borderTopRightRadius: 9},
  b_t_r_20: {borderTopLeftRadius: 20, borderTopRightRadius: 20},
  b_b_r_20: {borderBottomLeftRadius: 20, borderBottomRightRadius: 20},
  r__68: {right: -68},
  r__28: {right: -28},
  r__22: {right: -22},
  r__17: {right: -17},
  r__12: {right: -12},
  r__1: {right: -1},
  r_0: {right: 0},
  r_1: {right: 1},
  r_5: {right: 5},
  r_38: {right: 38},
  r_56: {right: 56},
  r_60: {right: 60},
  r_75: {right: 75},
  r_81: {right: 81},
  r_96: {right: 96},
  l_50_percent: {left: '50%'},
  l__1: {left: -1},
  l_0: {left: 0},
  l_5: {left: 5},
  l_7: {left: 7},
  l_9: {left: 9},
  l_10: {left: 10},
  l_14: {left: 14},
  l_15: {left: 15},
  l_18: {left: 18},
  l_20: {left: 20},
  l_26: {left: 26},
  l_35: {left: 35},
  l_72: {left: 72},
  l_168: {left: 168},
  l_171: {left: 171},
  absolute: {position: 'absolute'},
  b__28: {bottom: -28},
  b__20: {bottom: -20},
  b__14: {bottom: -14},
  b__11: {bottom: -11},
  b__9: {bottom: -9},
  b_0: {bottom: 0},
  b_1: {bottom: 1},
  b_15: {bottom: 15},
  b_40: {bottom: 40},
  b_47: {bottom: 47},
  l_h_3: {lineHeight: 3},
  l_h_3_5: {lineHeight: 3.5},
  l_h_4: {lineHeight: 4},
  l_h_9: {lineHeight: 9},
  l_h_10: {lineHeight: 10},
  l_h_12: {lineHeight: 12},
  l_h_13: {lineHeight: 13},
  l_h_14: {lineHeight: 14},
  l_h_15: {lineHeight: 15},
  l_h_16: {lineHeight: 16},
  l_h_17: {lineHeight: 17},
  l_h_18: {lineHeight: 18},
  l_h_19: {lineHeight: 19},
  l_h_20: {lineHeight: 20},
  l_h_22: {lineHeight: 22},
  l_h_23: {lineHeight: 23},
  l_h_24: {lineHeight: 24},
  l_h_26: {lineHeight: 26},
  l_h_27: {lineHeight: 27},
  l_h_28: {lineHeight: 28},
  l_h_29: {lineHeight: 29},
  l_h_30: {lineHeight: 30},
  l_h_32: {lineHeight: 32},
  l_h_33: {lineHeight: 33},
  l_h_35: {lineHeight: 35},
  l_h_36: {lineHeight: 36},
  l_h_34: {lineHeight: 34},
  l_h_38: {lineHeight: 38},
  l_h_40: {lineHeight: 40},
  l_h_42: {lineHeight: 42},
  l_h_45: {lineHeight: 45},
  l_h_47: {lineHeight: 47},
  l_h_48: {lineHeight: 48},
  l_h_50: {lineHeight: 50},
  l_h_55: {lineHeight: 55},
  l_h_63: {lineHeight: 63},
  l_h_64: {lineHeight: 64},
  l_h_70: {lineHeight: 70},
  l_h_72: {lineHeight: 72},
  l_h_78: {lineHeight: 78},
  l_h_80: {lineHeight: 80},
  b_w_1: {borderWidth: 1},
  b_w_2: {borderWidth: 2},
  b_w_3: {borderWidth: 3},
  b_w_5: {borderWidth: 5},
  p_t_9: {paddingTop: 9},
  p_t_5: {paddingTop: 5},
  p_t_10: {paddingTop: 10},
  p_t_13: {paddingTop: 13},
  p_t_15: {paddingTop: 15},
  p_t_17: {paddingTop: 17},
  p_t_18: {paddingTop: 18},
  p_t_19: {paddingTop: 19},
  p_t_20: {paddingTop: 20},
  p_t_22: {paddingTop: 22},
  p_t_23: {paddingTop: 23},
  p_t_25: {paddingTop: 25},
  p_t_30: {paddingTop: 30},
  p_t_37: {paddingTop: 37},
  p_t_46: {paddingTop: 46},
  p_t_60: {paddingTop: 60},
  p_t_102: {paddingTop: 102},
  rm_center: {resizeMode: 'center'},
  rm_contain: {resizeMode: 'contain'},
  rm_stretch: {resizeMode: 'stretch'},
  rm_cover: {resizeMode: 'cover'},
  italic: {fontStyle: 'italic'},
  t_50_percent: {top: '50%'},
  t__68: {top: -68},
  t__50: {top: -50},
  t__28: {top: -28},
  t_0: {top: 0},
  t_1: {top: 1},
  t_3: {top: 3},
  t_4: {top: 4},
  t_6: {top: 6},
  t_7: {top: 7},
  t_8: {top: 8},
  t_9: {top: 9},
  t_11: {top: 11},
  t_13: {top: 13},
  t_15: {top: 15},
  t_20: {top: 20},
  t_35: {top: 35},
  t_88: {top: 88},
  t_170: {top: 170},
  t_261: {top: 261},
  t_480: {top: 480},
  b_t_l_r_0: {borderTopLeftRadius: 0},
  b_t_l_r_14: {borderTopLeftRadius: 14},
  b_t_l_r_28: {borderTopLeftRadius: 28},
  b_t_r_r_0: {borderTopRightRadius: 0},
  b_t_r_r_14: {borderTopRightRadius: 14},
  b_b_l_r_0: {borderBottomLeftRadius: 0},
  b_b_l_r_14: {borderBottomLeftRadius: 14},
  b_b_l_r_28: {borderBottomLeftRadius: 28},
  b_b_r_r_0: {borderBottomRightRadius: 0},
  b_b_r_r_14: {borderBottomRightRadius: 14},
  b_b_r_r_40: {borderBottomRightRadius: 40},
  b_b_r_r_100: {borderBottomRightRadius: 100},
  b_t_w_3: {borderTopWidth: 3},
  b_l_w_0: {borderLeftWidth: 0},
  b_l_w_1: {borderLeftWidth: 1},
  z_i__1: {zIndex: -1},
  z_i_0: {zIndex: 0},
  z_i_1: {zIndex: 1},
  z_i_2: {zIndex: 2},
  t_t_u: {textTransform: 'uppercase'},
  t_t_c: {textTransform: 'capitalize'},
  f_w_nowrap: {flexWrap: 'nowrap'},
  f_w_wrap: {flexWrap: 'wrap'},
  fix_gradient_and_modal_error_in_landscape: {borderWidth: 0.1, borderColor: 'black'},
  b_r_w_0: {borderRightWidth: 0},
  b_r_w_1: {borderRightWidth: 1},
  visible: {overflow: 'visible'},
  Barlow400: {fontFamily: 'Barlow-Regular'},
  Barlow500: {fontFamily: 'Barlow-Medium'},
  Barlow600: {fontFamily: 'Barlow-SemiBold'},
  Barlow700: {fontFamily: 'Barlow-Bold'},
  max_h_385: {maxHeight: 385},
  max_h_770: {maxHeight: 770},
  max_h_full: {maxHeight: '100%'},
  t_d_l_u: {textDecorationLine: 'underline'},
  min_w_5: {minWidth: 5},
  min_w_36: {minWidth: 36},
  min_w_43: {minWidth: 43},
  min_w_64: {minWidth: 64},
  min_w_80: {minWidth: 80},
  min_w_96: {minWidth: 96},
  min_w_108: {minWidth: 108},
  b_w_x_1: {borderLeftWidth: 1, borderRightWidth: 1},
  textShadow: {textShadowOffset: { width: 0, height: 2 }, textShadowRadius: 2, textShadowColor: 'rgba(0, 0, 0, 0.50)'},
  m_5: {margin: 5},
  m_15: {margin: 15}
});

export default MainStyle;
