import React, {useContext, useEffect, useState} from "react";
import {ActivityIndicator, Text, useWindowDimensions, View} from "react-native";
import MainStyle from "../../MainStyle";
import Input from "./Input";
import Button from "./Button";
import * as Yup from "yup";
import {useFormik} from "formik";
import {StoreContext} from "./StoreContextProvider";
import {AxiosError} from "axios";
import {breakpoint, payoutFormat, tabletBreakpoint} from "../constants";
import {Div} from "react-native-magnus";
import {numberWithCommas} from "../utils/number";
import {colors} from "../assets/styles/colors";
import ServiceClient from "../utils/ServiceClient";
import KycTerm from "../screens/Auth/SignUp/steps/KycTerm";

type Props = { changeTab: (val: number) => void };

const Withdraw = (props: Props) => {
  const {width} = useWindowDimensions();
  const isSm = width < tabletBreakpoint;
  const isMd = width >= tabletBreakpoint && width < breakpoint;
  const formik = useFormik({
    validationSchema: Yup.object({amount: Yup.string().required().matches(/^[0-9]*(\.[0-9]{0,2})?$/, 'Invalid Value')}),
    initialValues: {amount: '50'},
    onSubmit: () => {
      getToken();
    }
  });
  const Context = useContext(StoreContext);
  const {cancelModalConfig, balance, user, totalBonuses} = Context;
  const [showOnce, setShowOnce] = useState(false);

  const restartKyc = () => {
    if (!user) return;
    Context.setUser({...user, kycStatus: 0});
  };

  const withdraw = () => {
    if (user && !user.emailConfirmed) {
      Context.setCancelModalConfig({
        visible: true, title: 'VERIFY YOUR EMAIL', subtitle: 'To withdraw funds, you must verify your email.',
        leftBtnText: 'RESEND VERIFICATION EMAIL', leftBtnBackgroundColor: colors.unselect, leftBtnFontSize: 20,
        rightBtnText: 'OK', rightBtnFontSize: 20,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});

          ServiceClient.resendEmailConfirmation().then(() => {
            console.log('resendEmailConfirmation');
            Context.setIsSuccessMessageShow('Email Confirmation Sent\n\nIf you do not see email, check your “junk” or “spam” folder');

            setTimeout(() => {
              Context.setIsSuccessMessageShow('');
            }, 3000);

          }).catch(err => {
            console.log('resendEmailConfirmation err', err);

            Context.setCancelModalConfig({
              visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: err.message,
              onExit: () => {
                Context.setCancelModalConfig({...cancelModalConfig, visible: false});
              }
            });
          });
        },
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          props.changeTab(0);
        }
      });

    } else {
      Context.setCancelModalConfig({
        visible: true, title: 'Confirm', rightBtnText: 'Confirm', titleColor: {color: colors.lightBlue},
        subtitle: `Please confirm you would like to withdraw $${payoutFormat(+formik.values.amount)}`,
        cancelGray: true,
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          formik.handleSubmit();
        },
        onClose: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    }
  };

  const getFieldError = (key: string) => {
    return formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors];
  };

  const payoff = () => {
    ServiceClient.payoff(+formik.values.amount * 100).then(() => {
      Context.setLoadingOverlay(false);
      Context.getUser().then();
      Context.setIsSuccessMessageShow('We sent withdrawal details to your email');

      setTimeout(() => {
        Context.setIsSuccessMessageShow('');
      }, 3000);

    }).catch((err: AxiosError<{ success: boolean, message: string }>) => {
      console.log('payoff error', err);
      Context.setLoadingOverlay(false);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const getToken = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.getPayGateToken().then(() => {
      payoff();

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('getPayGateToken err', err);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  useEffect(() => {
    if ((user?.kycStatus === 0 || user?.kycStatus === 2) && !showOnce) {
      setShowOnce(true);
      Context.setCancelModalConfig({
        visible: true, title: 'Complete KYC', titleColor: MainStyle.c_yellow, rightBtnText: 'COMPLETE KYC',
        subtitle: 'We will need to verify your identity to withdraw or deposit.',
        onClose: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false}),
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    }
  }, [user?.kycStatus, showOnce]);

  useEffect(() => {
    const val = balance - totalBonuses;
    if (val && val < 5000) formik.setFieldValue('amount', (val / 100).toString());
    else formik.setFieldValue('amount', '50');
  }, [balance, totalBonuses]);

  return (
    <>
      {user?.kycStatus === 0 || user?.kycStatus === 2 ? (
        <KycTerm redirectUser={Context.getUser}/>
      ) : user?.kycStatus === 3 || user?.kycStatus === 4 ? (
        <View style={[MainStyle.flex1, MainStyle.j_c_c, MainStyle.a_i_c]}>
          <Text style={[MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_16, MainStyle.m_b_22]}>
            {user?.kycStatus === 3 ? 'KYC verification started' : 'Waiting for KYC confirmation'}
          </Text>

          <ActivityIndicator color="white" size={30} style={MainStyle.m_b_20}/>

          {user?.kycStatus === 3 && (
            <Button w={244} h={24} bg={colors.unselect} mb={19} fontSize={16} text="Restart KYC Verification"
                    onPress={restartKyc} mx="auto"/>
          )}
        </View>
      ) : (
        <Div flexDir={(isSm || isMd) ? 'column' : 'row'} pt={(isSm || isMd) ? 67 : 113} maxW={1120} w="100%" mx="auto"
             justifyContent="space-between">
          <Div w={(isSm || isMd) ? '100%' : 569} pt={(isSm || isMd) ? 0 : 24} mb={(isSm || isMd) ? 66 : 0}>
            <Text style={styles[isSm ? 'balance_s' : isMd ? 'balance_m' : 'balance_l']}>BALANCE</Text>

            <Text style={styles[isSm ? 'balance_text_s' : isMd ? 'balance_text_m' : 'balance_text_l']}>
              ${balance ? numberWithCommas(balance / 100, true) : 0}
            </Text>

            <Text style={styles[isSm ? 'available_s' : isMd ? 'available_m' : 'available_l']}>
              AVAILABLE TO WITHDRAW
            </Text>

            <Text style={styles[isSm ? 'available_balance_s' : isMd ? 'available_balance_m' : 'available_balance_l']}>
              ${(balance - totalBonuses) ? numberWithCommas((balance - totalBonuses) / 100, true) : 0}
            </Text>
          </Div>

          <Div w={isSm ? 228 : isMd ? 348 : 484} h={isSm ? 169 : isMd ? 275 : 326} alignItems="center" mb={42}
               bg={colors.amount} rounded={(isSm || isMd) ? 8 : 16} pt={isSm ? 18 : isMd ? 33 : 24}
               mx={(isSm || isMd) ? 'auto' : 0}>
            <Input
              label="AMOUNT IN $"
              type="numeric"
              onChangeText={formik.handleChange('amount')}
              value={formik.values.amount}
              onBlur={formik.handleBlur('amount')}
              placeholder="AMOUNT"
              error={getFieldError('amount')}
              labelStyle={MainStyle.t_a_c}
              inputStyles={{
                height: isSm ? 60 : isMd ? 74 : 112, width: isSm ? 164 : isMd ? 238 : 326,
                fontSize: isSm ? 28 : isMd ? 48 : 60, textAlign: 'center'
              }}
              style={(isSm || isMd) ? MainStyle.m_b_16 : MainStyle.m_b_32}
              dollarMask
              decimalPlaces={2}
              useGrouping
            />

            <Button
              text="WITHDRAW"
              onPress={withdraw}
              w={isSm ? 164 : isMd ? 238 : 323}
              h={isSm ? 40 : isMd ? 56 : 80}
              mt={isMd ? 38 : undefined}
              textStyle={[{fontSize: isSm ? 16 : isMd ? 24 : 32}]}
              style={styles.btn_shadow}
            />
          </Div>
        </Div>
      )}
    </>
  );
};

const styles = {
  btn_shadow: {
    "shadowOffset": {
      "width": 0,
      "height": 4
    },
    "shadowRadius": 4,
    "shadowColor": "rgba(0, 0, 0, 0.25)",
    "shadowOpacity": 1
  },
  balance_l: [
    MainStyle.f_w_400, MainStyle.f_s_36, MainStyle.l_h_36, MainStyle.c_white, MainStyle.m_b_9, MainStyle.t_a_c
  ],
  balance_m: [
    MainStyle.f_w_400, MainStyle.f_s_24, MainStyle.l_h_36, MainStyle.c_white, MainStyle.m_b_9, MainStyle.t_a_c
  ],
  balance_s: [
    MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.l_h_23, MainStyle.c_white, MainStyle.m_b_13, MainStyle.t_a_c
  ],

  balance_text_l: [
    MainStyle.f_w_600, MainStyle.f_s_60, MainStyle.l_h_70, MainStyle.c_new_green_text, MainStyle.t_a_c, MainStyle.m_b_45
  ],
  balance_text_m: [
    MainStyle.f_w_600, MainStyle.f_s_48, MainStyle.l_h_70, MainStyle.c_new_green_text, MainStyle.t_a_c, MainStyle.m_b_45
  ],
  balance_text_s: [
    MainStyle.f_w_700, MainStyle.f_s_32, MainStyle.l_h_29, MainStyle.c_new_green_text, MainStyle.t_a_c, MainStyle.m_b_22
  ],
  available_l: [
    MainStyle.f_w_400, MainStyle.f_s_36, MainStyle.l_h_38, MainStyle.t_a_c, MainStyle.c_white, MainStyle.m_b_7
  ],
  available_m: [
    MainStyle.f_w_400, MainStyle.f_s_24, MainStyle.l_h_38, MainStyle.t_a_c, MainStyle.c_white, MainStyle.m_b_7
  ],
  available_s: [
    MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.l_h_18, MainStyle.t_a_c, MainStyle.c_white, MainStyle.m_b_15
  ],

  available_balance_l: [MainStyle.f_w_600, MainStyle.f_s_60, MainStyle.t_a_c, MainStyle.c_lightblue],
  available_balance_m: [MainStyle.f_w_600, MainStyle.f_s_48, MainStyle.t_a_c, MainStyle.c_lightblue],
  available_balance_s: [MainStyle.f_w_700, MainStyle.f_s_32, MainStyle.l_h_24, MainStyle.t_a_c, MainStyle.c_lightblue],
};

export default React.memo(Withdraw);
