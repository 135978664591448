import React, {useCallback, useContext, useLayoutEffect, useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Linking, Platform, Text, View, useWindowDimensions} from 'react-native';
import {Div, ScrollDiv} from 'react-native-magnus';
import MainStyle from '../../MainStyle';
import {colors} from '../assets/styles/colors';
import SupportGuideHeader from '../components/ SupportGuideHeader';
import Button from '../components/Button';
import {StoreContext} from '../components/StoreContextProvider';
import {LANDING_PAGE_URL, SESSION_EXPIRE_TIME, breakpoint} from '../constants';
import SupportGuideButtonMenu from "../components/SupportGuideButtonMenu";
import SelfLimitationModal from "../modals/SelfLimitationModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import {User} from "../../generated/smg_service_pb";

const SelfExclusionScreen = () => {
  const Context = useContext(StoreContext);
  const {user, session, cancelModalConfig} = Context;
  const {width} = useWindowDimensions();
  const isSm = width < breakpoint;
  const navigation: any = useNavigation();
  const [showModal, setShowModal] = useState(false);

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  const openLink = (url: string) => {
    if (Platform.OS === 'web') window.open(url, '_blank');
    else Linking.openURL(url).then();
  };

  useLayoutEffect(() => {
    if (!session || !user) {
      Context.setLoadingOverlay(true);

      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if(Platform.OS === 'web'){
              window.open(LANDING_PAGE_URL, '_self');
            }
            Context.setLoadingOverlay(false);
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);

            } catch (e: any) {
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
              });
            }
          }

          Context.setLoadingOverlay(false);
          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if(Platform.OS === 'web'){
                window.open(LANDING_PAGE_URL, '_self');
              }
            });
          }
        }
      })();
    }
  }, []);

  return (
    <>
      <SupportGuideHeader title='SELF LIMITATION' onExit={onExit}/>

      <ScrollDiv bg={colors.bg_contest} flex={1} pb={isSm ? 27 : 92}>
        <Div maxW={1040} w='100%' alignSelf='center' px={isSm ? 36 : 0}>
          <Div row justifyContent='center' alignItems='center' w={'100%'} mt={isSm ? 40 : 45} mb={isSm ? 37 : 42}>
            <Button
              w={isSm ? 240 : 500}
              h={isSm ? 40 : 80}
              bg={colors.unselect}
              borderWidth={1}
              borderColor={colors.popupDropBg}
              radius={40}
              fontSize={isSm ? 16 : 32}
              text="START SELF LIMITATION"
              onPress={() => setShowModal(true)}
            />
          </Div>

          <Div>
            <Text style={isSm ? styles.title_s : styles.title_l}>
              {`SELF-EXCLUSION INFORMATION\nSUPER MONEY GAMES, INC. (SMG)`}
            </Text>

            <Text style={isSm ? styles.title_s : styles.title_l}>GENERAL</Text>

            <Text style={isSm ? styles.text_s : styles.text_l}>
              We want all of our user to enjoy our games responsibly. We are committed to maintaining a safe and
              accountable experience for our users. All of our users should:
            </Text>

            <View style={MainStyle.row}>
              <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Consider different ways that they can limit their play;
              </Text>
            </View>

            <View style={MainStyle.row}>
              <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Not participate in challenges if it interferes with your daily life and/or responsibilities;
              </Text>
            </View>

            <View style={MainStyle.row}>
              <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Not enter challenges if you are recovering from a dependency and/or under the influence of alcohol, drugs,
                or other substances; and
              </Text>
            </View>

            <View style={[MainStyle.row, MainStyle.m_b_34]}>
              <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Not enter challenges if your primary goal is to recover losses.
              </Text>
            </View>

            <Text style={isSm ? styles.title_s : styles.title_l}>SELF-LIMITATION PROCESS</Text>

            <Text style={isSm ? styles.text_s : styles.text_l}>
              All users have the option of self-excluding by selecting the “Self-Limitation” option in the application
              menu. Once you have selected the Self-Limitation option from the application menu, you will have the
              option to select to the duration of the limitation. The minimum self-limitation time is six (6) months.
            </Text>

            <Text style={isSm ? styles.text_s : styles.text_l}>
              Once you have selected the Self-Limitation option from the menu and the duration of the limitation, and
              have confirmed once prompted to do so, the limitation becomes irrevocable until the expiration of the
              limitation term. During the limitation period (i) you will not be able to access the application; (ii) you
              will not receive any promotional materials from SMG; (iii) you will be prohibited from making deposits and
              entering challenges; (iv) any new accounts you create or attempt to create in circumvention of your
              self-limitation that are detected by us will be closed. If you have questions about how reward points,
              scheduled payments, and remaining balances are handled, please contact us at <Text
              style={MainStyle.t_d_l_u}
              onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
            </Text>

            <Text style={isSm ? styles.title_s : styles.title_l}>REINSTATEMENT</Text>

            <Text style={isSm ? styles.text_s : styles.text_l}>
              SMG will not notify you once your self-limitation period expires. If you would like to have your account
              reinstated after expiration of your self-limitation period, you may contact us at <Text
              style={MainStyle.t_d_l_u}
              onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
            </Text>

            <Text style={isSm ? styles.title_s : styles.title_l}>NOTICE</Text>

            <Text style={isSm ? styles.text_s : styles.text_l}>
              All users of SMG’s games and challenge and promotions entrants must agree and are subject to SuperMoney
              Games’ General Terms and Conditions, Promotions <Text style={styles.small_link}
                                                                    onPress={() => navigation.navigate('TermsAndConditions')}>Terms
              and Conditions</Text> (if applicable, and any applicable Key Terms for such promotions or specific games),
              and Super Money Games’ <Text style={styles.small_link}
                                           onPress={() => navigation.navigate('PrivacyPolicy')}>Privacy
              Policy</Text> (collectively, the “Rules”). It is your responsibility to read and understand the Rules
              before beginning play.
            </Text>
          </Div>
        </Div>
      </ScrollDiv>

      <SupportGuideButtonMenu isSm={isSm} onExit={onExit}/>

      <SelfLimitationModal visible={showModal} onClose={() => setShowModal(false)} isLg={!isSm}/>
    </>
  );
};

const styles = {
  title_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.m_b_30, MainStyle.t_t_u],
  title_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.m_b_15, MainStyle.t_t_u],
  text_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.m_b_30, MainStyle.l_h_36],
  text_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.m_b_15, MainStyle.l_h_16],
  dot_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.l_h_36, MainStyle.m_x_16],
  dot_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.l_h_16, MainStyle.m_x_8],
  small_link: [MainStyle.Barlow700, MainStyle.t_d_l_u]
};

export default SelfExclusionScreen;
