import React, {useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, useWindowDimensions} from 'react-native';
import {Div, Image} from 'react-native-magnus';
import MainStyle from '../../../../../MainStyle';
import starIconExpired from '../../../../assets/img/star-icon-expired.png';
import starIconLive from '../../../../assets/img/star-icon-live.png';
import starIconUnused from '../../../../assets/img/star-icon-unused.png';
import starIconUsed from '../../../../assets/img/star-icon-used.png';
import {tabletBreakpoint} from '../../../../constants';
import PromoHelpLinkModal from './PromoHelpLinkModal';
import {Promo} from '../../../../../generated/smg_service_pb';
import moment from 'moment';
import {convertCentsToDollars} from '../../../../utils';
import {useNavigation} from '@react-navigation/native';
import {colors} from "../../../../assets/styles/colors";

type PromoItemProps = { data: Promo.AsObject };

const PromoItem = (props: PromoItemProps) => {
  const {data} = props;
  const {width} = useWindowDimensions();
  const navigation: any = useNavigation();
  const isSm = tabletBreakpoint > width;
  const [isOpenHelpLinkModal, setIsOpenHelpLinkModal] = useState(false);

  const calculateBonusMultiplier = (amount: number, turnoverAmount: number): string => {
    const multiplier = Math.floor(turnoverAmount / amount);
    return `${multiplier}X`;
  };

  const getStatusColor = (status: string) => {
    switch (status.toUpperCase()) {
      case 'LIVE':
        return colors.newGreenText;
      case 'UNUSED':
        return colors.lightBlue;
      case 'USED':
        return colors.darkYellow;
      case 'EXPIRED':
        return colors.popupDangerPrimary;
      default:
        return colors.white;
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status.toUpperCase()) {
      case 'LIVE':
        return starIconLive;
      case 'UNUSED':
        return starIconUnused;
      case 'USED':
        return starIconUsed;
      case 'EXPIRED':
        return starIconExpired;
      default:
        return starIconUnused;
    }
  };

  const onSelectItem = () => {
    if (data.name.toLowerCase() === 'registration bonus') {
      return navigation.navigate('Promotions', {scrollTo: 'ELIGIBILITY_SECTION'});
    }

    navigation.navigate('Promotions', {scrollTo: 'PROMOTIONS_AND_PROMOTION_PERIOD'});
  };

  return (
    <>
      <TouchableOpacity onPress={onSelectItem}>
        <Div style={styles.wrapper} w={isSm ? 290 : 430}>
          <Div style={styles.header} h={isSm ? 30 : 44}>
            <Div position='absolute' left={isSm ? 13 : 19}>
              <Image source={getStatusIcon(data.status || '')} h={isSm ? 18 : 24} w={isSm ? 18 : 24}/>
            </Div>
            <Text style={[isSm ? styles.titleSm : styles.title, MainStyle.Barlow700]}>{data.name || ''}</Text>
          </Div>

          <Div px={19} py={16}>
            <Div row alignItems="center" mb={8}>
              <Div minW={isSm ? 70 : 90}>
                <Text style={[isSm ? styles.labelSm : styles.label, MainStyle.Barlow500]}>TYPE</Text>
              </Div>
              <Div minW={isSm ? 180 : 300} alignItems="center">
                <Text style={[isSm ? styles.valueSm : styles.value, MainStyle.Barlow700]}>{data.type || ''}</Text>
              </Div>
            </Div>

            <Div row alignItems="center" mb={8}>
              <Div minW={isSm ? 70 : 90}>
                <Text style={[isSm ? styles.labelSm : styles.label, MainStyle.Barlow500]}>AMOUNT</Text>
              </Div>
              <Div minW={isSm ? 180 : 300} alignItems="center">
                <Text style={[isSm ? styles.valueSm : styles.value, MainStyle.Barlow700]}>
                  ${convertCentsToDollars(data.amount) || ''}
                </Text>
              </Div>
            </Div>

            <Div row alignItems="center" mb={8}>
              <Div minW={isSm ? 70 : 90}>
                <Text style={[isSm ? styles.labelSm : styles.label, MainStyle.Barlow500]}>TURNOVER</Text>
              </Div>
              <Div minW={isSm ? 180 : 300} alignItems="center">
                <Text style={[isSm ? styles.valueSm : styles.value, MainStyle.Barlow700]}>
                  {calculateBonusMultiplier(data.amount, data.turnoverAmount)}
                </Text>
              </Div>
            </Div>

            <Div row alignItems="center" mb={8}>
              <Div minW={isSm ? 70 : 90}>
                <Text style={[isSm ? styles.labelSm : styles.label, MainStyle.Barlow500]}>STATUS</Text>
              </Div>
              <Div minW={isSm ? 180 : 300} alignItems="center">
                <Text
                  style={[isSm ? styles.valueSm : styles.value, MainStyle.Barlow700, {color: getStatusColor(data.status || '')}]}>
                  {data.status || ''}
                </Text>
              </Div>
            </Div>

            <Div row alignItems="center">
              <Div minW={isSm ? 70 : 90}>
                <Text style={[isSm ? styles.labelSm : styles.label, MainStyle.Barlow500]}>EXP</Text>
              </Div>
              <Div minW={isSm ? 180 : 300} alignItems="center">
                <Text style={[isSm ? styles.valueSm : styles.value, MainStyle.Barlow700]}>
                  {moment.unix(data.expirationDate).format('ll')}
                </Text>
              </Div>
            </Div>
          </Div>
        </Div>
      </TouchableOpacity>

      <PromoHelpLinkModal isOpen={isOpenHelpLinkModal} onClose={() => setIsOpenHelpLinkModal(false)}/>
    </>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    boxSizing: 'border-box',
    backgroundColor: '#0E2338',
    borderWidth: 0.5,
    borderColor: '#2E75BA',
    borderStyle: 'solid',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  header: {
    backgroundColor: 'rgba(46, 117, 186, 0.5)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontStyle: 'italic',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 33,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  titleSm: {
    fontStyle: 'italic',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 22,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  label: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: '#85BED1',
    lineHeight: 16,
  },
  value: {
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    lineHeight: 18,
  },
  labelSm: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: '#85BED1',
    lineHeight: 12,
  },
  valueSm: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    lineHeight: 14,
  },
});

export default PromoItem;
