import React from "react";
import Spinner from "react-native-loading-spinner-overlay";

type Props = {visible?: boolean};

const Loader = (props: Props) => {
  const {visible} = props;

  return (
    <Spinner visible={visible} overlayColor="rgba(0, 0, 0, 0.6)"/>
  );
};

export default React.memo(Loader);
