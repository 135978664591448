import React, {useContext, useState} from "react";
import {Modal, Platform, ScrollView, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import Button from "../components/Button";
import {colors} from "../assets/styles/colors";
import Select from "../components/Select";
import {StoreContext} from "../components/StoreContextProvider";
import ServiceClient from "../utils/ServiceClient";
import {useNavigation} from "@react-navigation/native";

const items = [
  {value: '0', label: '6 months'}, {value: '1', label: '1 year'}, {value: '2', label: '2 years'},
  {value: '3', label: 'Forever'}
];

type Props = { visible: boolean, onClose: () => void, isLg: boolean };

const SelfLimitationModal = (props: Props) => {
  const {visible, isLg} = props;
  const [value, setValue] = useState('0');
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const navigation: any = useNavigation();
  const [isLoading, setIsLoading] = useState(false);

  const confirmSelfLimitation = () => {
    setIsLoading(true);

    ServiceClient.enableSelfLimitation(+value).then(() => {
      setIsLoading(false);
      props.onClose();

      Context.setCancelModalConfig({
        visible: true, title: 'Success', titleColor: MainStyle.c_green, leftBtnText: 'OK',
        subtitle: 'You have successfully confirmed your Self-Limitation.',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          Context.setLoadingOverlay(true);

          Context.getUser().then(() => {
            Context.setLoadingOverlay(false);
            navigation.navigate('challenges');

          }).catch(err => {
            console.log('getUser err', err);
            Context.logout().then(() => {
              setTimeout(() => {
                if (Platform.OS === 'web') window.location.reload();
              }, 150);
              navigation.reset({index: 0, routes: [{name: 'Splash'}]});
              Context.setLoadingOverlay(false);
            });
          });
        }
      });

    }).catch(err => {
      console.log('enableSelfLimitation err', err);
      setIsLoading(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    })
  };

  return (
    <Modal animationType="fade" transparent visible={visible} statusBarTranslucent={false}
           hardwareAccelerated={false} onRequestClose={props.onClose}>
      <View style={styles.wrap}>
        <ScrollView contentContainerStyle={[MainStyle.full_h, MainStyle.full_w, MainStyle.a_i_c, MainStyle.j_c_c]}>
          <View style={isLg ? styles.container_l : styles.container_s}>
            <Text style={isLg ? styles.title_l : styles.title_s}>SELF LIMITATION</Text>

            <Select
              value={value}
              onChangeText={(val) => setValue(val)}
              label="TIMEFRAME"
              customItems={items}
              mb={isLg ? 61 : 38}
            />
          </View>

          <Button
            text="CONFIRM SELF LIMITATION"
            h={isLg ? 80 : 40}
            fontSize={isLg ? 32 : 16}
            bg={colors.unselect}
            w={isLg ? 480 : 240}
            mb={15}
            onPress={confirmSelfLimitation}
            isLoading={isLoading}
          />

          <Button
            text="CANCEL"
            h={isLg ? 80 : 40}
            fontSize={isLg ? 32 : 16}
            bg="transparent"
            w={isLg ? 480 : 240}
            borderColor={colors.popupDangerPrimary}
            borderWidth={1}
            color={colors.popupDangerPrimary}
            onPress={isLoading ? undefined : props.onClose}
          />
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = {
  wrap: [MainStyle.full_h, MainStyle.full_w, MainStyle.bc_modalBg, MainStyle.fix_gradient_and_modal_error_in_landscape],
  container_s: [
    MainStyle.w_300, MainStyle.bc_darkblue, MainStyle.b_w_1, MainStyle.b_c_popupBorder, MainStyle.b_r_4, MainStyle.p_t_25,
    MainStyle.p_x_30, MainStyle.m_b_101
  ],
  container_l: [
    MainStyle.w_640, MainStyle.bc_darkblue, MainStyle.b_w_1, MainStyle.b_c_popupBorder, MainStyle.b_r_4, MainStyle.p_t_46,
    MainStyle.p_x_80, MainStyle.m_b_158
  ],
  title_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_48, MainStyle.t_a_c, MainStyle.m_b_30],
  title_s: [MainStyle.c_lightblue, MainStyle.Barlow600, MainStyle.f_s_20, MainStyle.t_a_c, MainStyle.m_b_20]
};

export default React.memo(SelfLimitationModal);
