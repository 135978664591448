import React, {useContext, useLayoutEffect, useRef, useState} from 'react';
import {Image, Text, View} from 'react-native';
import {Camera} from 'expo-camera';
import MainStyle from '../../../../../../MainStyle';
import Button from '../../../../../components/Button';
import {StoreContext} from "../../../../../components/StoreContextProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {transformImg} from "../../../../../constants";
import {colors} from "../../../../../assets/styles/colors";
import CameraModal from "../../../../../modals/CameraModal";
import ServiceClient from "../../../../../utils/ServiceClient";
import * as ImageManipulator from 'expo-image-manipulator';

type Props = { goNextStep: () => void, goCsr: () => void, onCancel: () => void, title: string, type: 'photo' };

const IdVerification = (props: Props) => {
  const {title, type} = props;
  const videoRef = useRef<Camera | null>(null);
  const [imageUri, setImageUri] = useState('');
  const Context = useContext(StoreContext);
  const {cancelModalConfig, session} = Context;
  const [cameraModalVisible, setCameraModalVisible] = useState(false);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  const errorModalClose = () => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
  };

  const SubmitPhoto = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.submitPhoto(imageUri).then(isRecognized => {
      if (isRecognized) {
        setTimeout(() => {
          Context.setLoadingOverlay(false);
          props.goNextStep();
        }, 1000);

      } else {
        Context.setLoadingOverlay(false);
        Context.setCancelModalConfig({
          visible: true, title: 'SELFIE ISSUE', leftBtnText: 'RETAKE PHOTO',
          subtitle: 'Something’s up with your selfie… please retake it, looking directly into the camera. If this continues to happen, please contact support.',
          rightBtnText: 'CONTACT SUPPORT', onClose: errorModalClose, onExit: errorModalClose,
          onResume: () => {
            errorModalClose();
            props.goCsr();
          }
        });
      }

    }).catch(err => {
      Context.setLoadingOverlay(false);
      console.log('submitPhoto', err);

      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', subtitle: (err && err.message) ? err.message : 'Failed to submit photo',
        secondary: 'Please contact support', leftBtnText: 'RETAKE PHOTO', rightBtnText: 'CONTACT SUPPORT',
        onClose: errorModalClose, onExit: errorModalClose, onResume: () => {
          errorModalClose();
          props.goCsr();
        }
      });
    });
  };

  const takePicture = () => {
    if (!hasPermission) {
      (async () => {
        const {status} = await Camera.requestCameraPermissionsAsync();
        const val = status === 'granted';
        setHasPermission(val);
        if (val) setCameraModalVisible(true);
      })();

    } else if (hasPermission && !cameraModalVisible) {
      setCameraModalVisible(true);

    } else if (videoRef.current) {
      const options = {quality: 1, base64: false, exif: false, skipProcessing: true};

      videoRef.current.takePictureAsync(options).then(photo => {
        ImageManipulator.manipulateAsync(photo.uri, [{ flip: ImageManipulator.FlipType.Horizontal }], { compress: 1, base64: true, format: ImageManipulator.SaveFormat.JPEG }).then(res => {
          if (res.base64) setImageUri(transformImg(res.base64));
          setCameraModalVisible(false);
        });
      });

      // const imageSrc = videoRef.current.getScreenshot();
      // if (imageSrc) setImageUri(transformImg(imageSrc));
      // setCameraModalVisible(false);

      // const canvas = document.createElement('canvas');
      // const context = canvas.getContext('2d');
      // if (context) {
      //   canvas.width = videoRef.current.videoWidth;
      //   canvas.height = videoRef.current.videoHeight;
      //   context.translate(canvas.width, 0);
      //   context.scale(-1, 1);
      //
      //   context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      //   setImageUri(transformImg(canvas.toDataURL('image/png')));
      //   setCameraModalVisible(false);
      // }
    }
  };

  const next = () => {
    if (!imageUri) return;
    if (type === 'photo') SubmitPhoto();
  };

  useLayoutEffect(() => {
    if (!session) {
      (async () => {
        const val = await AsyncStorage.getItem('session');

        if (val) {
          const Session = JSON.parse(val);
          Context.setSession(Session);
        }
      })();
    }
  }, []);

  return (
    <View style={MainStyle.flex1}>
      <Text style={styles.title}>{title}</Text>

      <View style={[MainStyle.flex1, MainStyle.j_c_c]}>
        <View style={styles.size_s}>
          {!!imageUri && <Image style={MainStyle.flex1} source={{uri: `data:image/jpg;base64,${imageUri}`}}/>}
        </View>
      </View>

      <Button
        text={`${imageUri ? 'Retake' : 'Take'} Photo`} w="100%" h={63} onPress={takePicture} fontSize={20}
        bg={imageUri ? colors.unselect : colors.newGreenText} color={imageUri ? 'white' : colors.mainBackground}
        fontFamily="Barlow-SemiBold" mb={16}
      />

      <View style={[MainStyle.h_63, MainStyle.relative, MainStyle.z_i__1, MainStyle.m_b_86, MainStyle.full_w]}>
        <View style={[MainStyle.absolute, MainStyle.full_w, MainStyle.row, MainStyle.j_c_s_between]}>
          <Button
            text="Back" w={190} h={63} bg="transparent" borderWidth={1} borderColor="white" onPress={props.onCancel}
            fontFamily="Barlow-SemiBold" fontSize={20} color="white"
          />
          <Button
            text="Next" w={190} h={63} onPress={next} bg={imageUri ? colors.newGreenText : colors.unselect}
            color={imageUri ? colors.mainBackground : 'white'} fontFamily="Barlow-SemiBold" fontSize={20}
          />
        </View>
      </View>

      <CameraModal
        videoRef={videoRef}
        cameraModalVisible={cameraModalVisible}
        takePicture={takePicture}
        onRequestClose={() => setCameraModalVisible(false)}
      />
    </View>
  );
};

const styles = {
  title: [MainStyle.c_white, MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.m_b_8, MainStyle.t_a_c, MainStyle.m_x_2],
  size_s: [MainStyle.w_190, MainStyle.h_253, MainStyle.bc_gray, MainStyle.m_b_17, MainStyle.m_x_auto]
};

export default React.memo(IdVerification);
