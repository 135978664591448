import React, {useContext, useEffect, useState} from "react";
import {Text, ScrollView, useWindowDimensions, View, Image} from "react-native";
import MainStyle from "../../MainStyle";
import Button from "./Button";
import {Bet, Challenge, Payline} from "../../generated/smg_service_pb";
import {StoreContext} from "./StoreContextProvider";
import ContestFrame from "./ContestFrame";
import PayTable from "./PayTable";
import {tabletBreakpoint} from "../constants";
import NewGameChallenges from "./NewGameChallenges";
import Golf2AcceptedBetDetails from "./Golf2AcceptedBetDetails";
import {useNavigation} from "@react-navigation/native";
import {colors} from "../assets/styles/colors";
import NewHeader from "./NewHeader";
import ChallengeInfoCard from "./ChallengeInfoCard";
import WinCircleIcon from "../assets/img/icons/Frame.svg";
import DistanceIcon from "../assets/img/icons/pin-distance-line 1.svg";
import CoinIcon from "../assets/img/icons/coin.svg";
import TournamentRegistrationConfirmationModal from "../modals/TournamentRegistrationConfirmationModal";
import StartAutoPlayIcon from "../assets/img/icons/auto_play.svg";
import StopAutoPlayIcon from "../assets/img/icons/StopAutoPlayIcon.svg";
import ServiceClient from "../utils/ServiceClient";

type Props = {
  bet: Bet.AsObject, formik: any, title: string, type: 'open' | 'accepted', changeGameType?: () => void,
  moreGames?: boolean, showAutoPlayModal: () => void
};

const OpenBets = (props: Props) => {
  const {formik, title, type, bet} = props;
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user, extendedUserData} = Context;
  const {width} = useWindowDimensions();
  const isMd = width >= tabletBreakpoint;
  const [challengeId, setChallengeId] = useState(0);
  const navigation: any = useNavigation();
  const [confirmModalType, setConfirmModalType] = useState<'ChallengeFeeConfirmationModal' | 'ChallengeEntry' | 'ChallengeAmountModal' | ''>('');
  const AutoPlayIcon = (
    <Image
      source={(user?.autoPlayEnabled ? StopAutoPlayIcon : StartAutoPlayIcon) as any}
      style={[MainStyle.w_24, MainStyle.h_24]}
    />
  );

  let payLinesList: Payline.AsObject[] | undefined;
  let challengesList: Challenge.AsObject[] | undefined;
  let largestDistanceChallenge: Challenge.AsObject | undefined;

  if (bet) {
    challengesList = bet.challengesList;
    payLinesList = bet.paylinesList;
    largestDistanceChallenge = challengesList[0];

    challengesList.forEach(challenge => {
      if (largestDistanceChallenge && (largestDistanceChallenge.value2 < challenge.value2)) {
        largestDistanceChallenge = challenge;
      }
    });
  }

  const challengeFeeChange = (fee: string) => {
    setConfirmModalType('');
    formik.setFieldValue('contestAmount', fee);
    if (confirmModalType === 'ChallengeEntry') AcceptBet(fee);
  };

  const onArrowForwardPress = () => {
    const val = bet.golf2challengesList;
    const find = val.find(el => el.challengeId === challengeId);

    if (!find) return;
    const index = val.indexOf(find);

    if (index === 2) setChallengeId(val[0].challengeId);
    else setChallengeId(val[index + 1].challengeId);
  };

  const AcceptBet = (contestAmount: string, challengeId?: number) => {
    Context.setLoadingOverlay(true);
    const {betId} = bet;

    ServiceClient.acceptBet(betId, contestAmount === '-2' ? extendedUserData.maxshotamount : +contestAmount * 100, challengeId).then(() => {
      Context.getUser().then();

    }).catch(err => {
      console.log('acceptBet err', err);
      Context.setLoadingOverlay(false);

      if (err.message) {
        Context.setCancelModalConfig({
          visible: true, title: 'ERROR', subtitle: err.message, rightBtnText: 'OK',
          onResume: () => {
            Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          },
          onClose: () => {
            Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          }
        });
      }
    });
  };

  const CancelBet = (betId: number) => {
    Context.setLoadingOverlay(true);

    ServiceClient.refundBet(betId).then(resolved => {
      if (resolved) {
        setTimeout(() => {
          Context.getUser().then();
        }, 1500);
      }

    }).catch(err => {
      console.log('cancelBet err', err);
      Context.setLoadingOverlay(false);

      if (err.message) {
        Context.setCancelModalConfig({
          visible: true, title: 'Error', leftBtnText: 'OK',
          subtitle: err.code === 20020043 ? 'Challenge in progress. Unable to cancel challenge.' : err.message,
          onExit: () => {
            Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          }
        });
      }
    });
  };

  const onEnterContestModalOpen = () => {
    if (type === 'open') {
      if (bet.challengesList.length) {
        setConfirmModalType('ChallengeEntry');

      } else if (bet.golf2challengesList.length) {
        const find = bet.golf2challengesList.find(el => el.challengeId === challengeId);
        if (find && find.gamedetails && find.gamedetails.circle) {
          find.gamedetails.circle.winCircleDistance = find.gamedetails.circle.winCircleDistance / 12;
        }

        Context.setContestEntryModalConfig({
          visible: true, contestAmount: formik.values.contestAmount, challengeId,
          golf2challenge: find,
          onCancel: () => Context.setContestEntryModalConfig({visible: false}),
          onOk: (curChallengeId) => {
            Context.setContestEntryModalConfig({visible: false});
            AcceptBet(formik.values.contestAmount, curChallengeId);
          }
        });
      }

    } else if (type === 'accepted') {
      CancelBet(bet.betId);
    }
  };

  useEffect(() => {
    if (bet.betType === 'golf2') setChallengeId(bet.golf2challengesList[0].challengeId);
    else if (bet.challengesList.length) setChallengeId(bet.challengesList[0].challengeId);
  }, [bet.betType, bet.betState]);

  return (
    <ScrollView contentContainerStyle={MainStyle.flex1} scrollEventThrottle={16}>
      <NewHeader/>

      <View style={[MainStyle.w_333, MainStyle.m_x_auto]}>
        <Text style={styles.title}>{title}</Text>

        <ContestFrame
          betType={bet.betType}
          type={type}
          onArrowForwardPress={onArrowForwardPress}
          golf2challengesList={bet.golf2challengesList}
          challengeId={challengeId}
          largestDistanceChallenge={largestDistanceChallenge}
        />

        <View style={[MainStyle.row, MainStyle.j_c_s_between, MainStyle.m_b_10, MainStyle.a_i_start]}>
          <View>
            {type === 'accepted' && bet.betType === 'golf2' ? (
              <Golf2AcceptedBetDetails
                isLg={isMd}
                amount={bet.amount}
                challenge={bet.golf2challengesList.find(el => el.outcome === 'unresolved')}
              />
            ) : bet.betType === 'golf2' ? (
              <NewGameChallenges
                challenges={bet.golf2challengesList}
                challengeId={challengeId}
                onChange={setChallengeId}
                amount={bet.amount}
              />
            ) : (
              <>
                <ChallengeInfoCard
                  title="Distance"
                  value={`${largestDistanceChallenge ? largestDistanceChallenge.value1 / 36 : 0} Yards`}
                  icon={DistanceIcon as any}
                  mb={8}
                />

                <ChallengeInfoCard
                  title="Win Circle"
                  value={`${largestDistanceChallenge ? largestDistanceChallenge.value2 / 12 : 0} Feet`}
                  icon={WinCircleIcon as any}
                  mb={8}
                />

                {type === 'accepted' ? (
                  <ChallengeInfoCard
                    title="Fee per Shot"
                    icon={CoinIcon as any}
                    value={(formik.values.contestAmount === '-2' || (user?.autoPlayEnabled && user?.autoPlayAmount === -2)) ? `$${extendedUserData.maxshotamount / 100} (Max)` : `$${formik.values.contestAmount}`}
                  />
                ) : (
                  <ChallengeInfoCard
                    leftText="Challenge Amount"
                    changeFee={() => setConfirmModalType('ChallengeAmountModal')}
                    formik={formik}
                    maxShotAmount={extendedUserData.maxshotamount}
                    autoPlayAmount={user?.autoPlayAmount} autoPlayEnabled={user?.autoPlayEnabled}
                  />
                )}
              </>
            )}
          </View>

          <PayTable
            payLinesList={payLinesList}
            challengesList={challengesList}
            amount={type === 'open' ? (formik.values.contestAmount === '-2' ? extendedUserData.maxshotamount : +formik.values.contestAmount * 100) : bet.amount}
            holeInOneAmount={bet.holeInOneBonusAmount}
          />
        </View>

        <View style={[MainStyle.row, MainStyle.m_b_10]}>
          {user?.userId && (
            <View style={MainStyle.flex1}>
              <Button
                text={user.autoPlayEnabled ? `Stop\nAutoPlay` : `Start\nAutoPlay`} onPress={props.showAutoPlayModal}
                bg={user.autoPlayEnabled ? colors.stop_auto_play : colors.newGreenText} autoPlayIcon={AutoPlayIcon}
                color={user.autoPlayEnabled ? colors.newGreenText : 'black'} w="100%" fontSize={20}
                fontFamily="Barlow-SemiBold"
                disabled={extendedUserData.isnewplayer || extendedUserData.freeshotnumber !== extendedUserData.freeshotcount}
              />
            </View>
          )}

          <View style={MainStyle.w_9}/>

          <View style={MainStyle.flex1}>
            <Button
              text={type === 'open' ? `Enter\nChallenge` : `Cancel\nChallenge`} onPress={onEnterContestModalOpen}
              bg="transparent" borderWidth={1} borderColor={colors.white} color="white" w="100%" fontSize={20}
              disabled={extendedUserData.isnewplayer || extendedUserData.freeshotnumber !== extendedUserData.freeshotcount}
              fontFamily="Barlow-SemiBold"
            />
          </View>
        </View>

        {extendedUserData.isnewplayer || extendedUserData.freeshotnumber !== extendedUserData.freeshotcount ? (
          <Text style={styles.terms}>
            * See <Text style={MainStyle.c_new_green_text}
                        onPress={() => navigation.navigate('TermsAndConditions')}>Terms & Conditions</Text>
          </Text>
        ) : (
          <Text style={[MainStyle.c_Gray, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_16]}>
            Malfunction voids all pays and plays
          </Text>
        )}
      </View>

      {!!formik.values.contestAmount && (
        <TournamentRegistrationConfirmationModal
          isOpen={!!confirmModalType}
          onClose={() => setConfirmModalType('')}
          joinTournament={challengeFeeChange}
          type={confirmModalType}
          entryFee={0}
          entryChallengeFee={formik.values.contestAmount}
          min_h={confirmModalType === 'ChallengeEntry' ? 372 : undefined}
        />
      )}
    </ScrollView>
  );
};

const styles = {
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_30],
  terms: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_16]
};

export default React.memo(OpenBets);
