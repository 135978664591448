import React from "react";
import {Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import {Fontisto} from '@expo/vector-icons';
import {colors} from "../assets/styles/colors";

type Props = {
  children: React.ReactNode, disable?: boolean, mb?: string | number, mt?: string | number, onClose?: () => void,
  my?: string | number
};

const FilterChip = (props: Props) => {
  const {children, disable, mb, mt, my} = props;
  const dynamic_styles = {marginBottom: mb, marginTop: mt, marginVertical: my};

  return (
    <View style={[...styles.wrap, disable ? MainStyle.bg_disable_chip : MainStyle.bg_filter_chip, dynamic_styles]}>
      <Text style={disable ? styles.text_d : styles.text}>{children}</Text>
      {(props.onClose && !disable) && (
        <Fontisto name="close-a" size={10} color={colors.white} onPress={props.onClose} style={MainStyle.m_r_2}/>
      )}
    </View>
  );
};

const styles = {
  wrap: [
    MainStyle.h_20, MainStyle.b_r_16, MainStyle.p_l_9, MainStyle.a_i_c, MainStyle.p_r_4, MainStyle.row, MainStyle.m_r_4
  ],
  text: [MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.m_r_4, MainStyle.c_white, MainStyle.t_t_u],
  text_d: [
    MainStyle.Barlow500, MainStyle.f_s_14, MainStyle.m_r_4, MainStyle.c_disable_chip_text, MainStyle.t_t_u
  ]
};

export default React.memo(FilterChip);
