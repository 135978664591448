import React, {useContext} from "react";
import {Div, Image} from "react-native-magnus";
import {TouchableOpacity} from "react-native";
import button_menu from "../assets/img/icons/button_menu.png";
import button_exit from "../assets/img/icons/button_exit.png";
import {StoreContext} from "./StoreContextProvider";

type Props = { isSm: boolean, onExit: () => void };

const SupportGuideButtonMenu = (props: Props) => {
  const {isSm} = props;
  const Context = useContext(StoreContext);

  return (
    <>
      {!isSm ? (
        <Div position="absolute" right={isSm ? 19 : 56} bottom={47} top={isSm ? 10 : undefined}>
          <Div mb={isSm ? 15 : 35}>
            <TouchableOpacity onPress={() => Context.setIsHelpModalVisible(true)}>
              <Image source={button_menu} w={isSm ? 28 : 48} h={isSm ? 28 : 48} resizeMode="contain"/>
            </TouchableOpacity>
          </Div>

          <Div>
            <TouchableOpacity onPress={props.onExit}>
              <Image source={button_exit} w={isSm ? 28 : 48} h={isSm ? 28 : 48} resizeMode="contain"/>
            </TouchableOpacity>
          </Div>
        </Div>
      ) : null}
    </>
  );
};

export default React.memo(SupportGuideButtonMenu);
