import React, {useContext} from "react";
import {Modal, Pressable, ScrollView, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import {useNavigation} from "@react-navigation/native";
import ServiceClient from "../utils/ServiceClient";
import {StoreContext} from "../components/StoreContextProvider";

type Props = { isOpen: boolean, onClose: () => void, onReload: () => void };

const ContinueAutoPlayModal = (props: Props) => {
  const {isOpen} = props;
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;

  const enableAutoPlay = (fee: number) => {
    Context.setLoadingOverlay(true);

    ServiceClient.enableAutoPlay(true, fee).then(() => {
      Context.setLoadingOverlay(false);
      props.onReload();

    }).catch(err => {
      console.log('enableAutoPlay err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const setZeroAutoPlay = () => {
    props.onClose();
    enableAutoPlay(0);
  };

  const onDeposit = () => {
    props.onClose();
    navigation.navigate({name: 'account', params: {step: 1}});
  };

  const onContinue = () => {
    props.onClose();
    enableAutoPlay(-1);
  };

  return (
    <Modal animationType="fade" transparent visible={isOpen} statusBarTranslucent={false} hardwareAccelerated={false}
           onRequestClose={onContinue}>
      <View style={[MainStyle.fix_gradient_and_modal_error_in_landscape, MainStyle.flex1, MainStyle.bg_filter_modal]}>
        <ScrollView contentContainerStyle={MainStyle.flex1}>
          <View style={[MainStyle.min_h_full, MainStyle.a_i_c, MainStyle.j_c_c]}>
            <View style={styles.contentWrapper}>
              <Text style={styles.title}>Insufficient balance to continue Auto-play with existing amount</Text>

              <View style={MainStyle.flex1}/>

              <View style={MainStyle.m_x_40}>
                <Pressable style={[...styles.btn_wrap, MainStyle.m_b_20]} onPress={setZeroAutoPlay}>
                  <Text style={[...styles.btn, MainStyle.c_white, MainStyle.m_x_23]}>
                    Set Auto Play $0 and continue
                  </Text>
                </Pressable>

                <Pressable style={styles.deposit} onPress={onDeposit}>
                  <Text style={styles.btn}>Deposit</Text>
                </Pressable>

                <Pressable style={styles.btn_wrap} onPress={onContinue}>
                  <Text style={[...styles.btn, MainStyle.c_white]}>Continue</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = {
  contentWrapper: [
    MainStyle.bg_settings, MainStyle.w_389, MainStyle.b_r_20, MainStyle.b_c_settings_border, MainStyle.b_w_1,
    MainStyle.p_t_46, MainStyle.min_h_452, MainStyle.p_b_67
  ],
  title: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_23, MainStyle.m_l_37, MainStyle.w_255
  ],
  btn: [
    MainStyle.c_mainBackground, MainStyle.t_a_c, MainStyle.Barlow600, MainStyle.f_s_18
  ],
  btn_wrap: [MainStyle.b_r_70, MainStyle.bg_main, MainStyle.b_w_1, MainStyle.b_c_white, MainStyle.h_63, MainStyle.j_c_c],
  deposit: [MainStyle.b_r_70, MainStyle.bg_newGreen, MainStyle.m_b_20, MainStyle.h_63, MainStyle.j_c_c]
};

export default React.memo(ContinueAutoPlayModal);
