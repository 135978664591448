import React from "react";
import {View, Text} from "react-native";
import {TransactionHistory} from "../../generated/smg_service_pb";
import MainStyle from "../../MainStyle";
import moment from 'moment';
import {numberWithCommas} from "../utils/number";

type Props = {item: TransactionHistory.AsObject, isLg: boolean};

const TransactionHistoryItem = (props: Props) => {
  const {item, isLg} = props;
  // @ts-ignore
  const num = item.i;

  const showStatus = (val: string) => {
    if (val === 'RETURNED') return 'CANCELLED';
    else if (val === 'WON') return `WON $${item.winAmount ? numberWithCommas(item.winAmount / 100) : 0}`;
    else return val;
  };

  const color = (val: string) => {
    if (val === 'COMPLETED') return MainStyle.c_green_text;
    else if (val === 'PENDING') return MainStyle.c_lightblue;
    else if (val === 'DECLINED' || val === 'LOST' || val === 'RETURNED') return MainStyle.c_popupDangerPrimary;
    else if (val === 'WON') return MainStyle.c_yellow;
    else return {color: 'white'};
  };

  return (
    <View style={MainStyle.p_x_30}>
      <View style={[...styles.row, isLg ? styles.wrap_l : styles.wrap_s]}>
        <View style={isLg ? MainStyle.row : [MainStyle.column, MainStyle.flex1]}>
          <Text style={isLg ? styles.index_l : styles.index_s}>{num}</Text>
          <Text style={isLg ? styles.time_date_l : styles.index_s}>{moment.unix(item.time).format('ll')}</Text>
          <Text style={isLg ? styles.time_l : styles.index_s}>{moment.unix(item.time).format('LT')} PT</Text>
        </View>

        <Text style={isLg ? styles.type_l : styles.type_s}>{item.type}</Text>

        <Text style={isLg ? styles.amount_l : styles.amount_s}>${Math.abs(item.amount / 100)}</Text>

        <View style={isLg ? MainStyle.row : [MainStyle.column, MainStyle.flex1]}>
          <Text style={[...(isLg ? styles.status_l : styles.status_s), color(item.status)]}>
            {showStatus(item.status)}
          </Text>

          <Text style={isLg ? [...styles.status_l, MainStyle.w_116] : styles.status_s}>
            {moment.unix(item.lastUpdateTime).format('ll')}
          </Text>

          <Text style={isLg ? styles.time_l : styles.index_s}>
            {moment.unix(item.lastUpdateTime).format('LT')} PT
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = {
  row: [
    MainStyle.row, MainStyle.full_w, MainStyle.max_w_1040, MainStyle.bc_darkblue, MainStyle.b_w_1, MainStyle.m_x_auto,
    MainStyle.b_c_blueText
  ],
  wrap_l: [MainStyle.m_b_8, MainStyle.h_48, MainStyle.b_r_8],
  wrap_s: [MainStyle.m_b_4, MainStyle.h_55, MainStyle.b_r_4, MainStyle.a_i_c],
  index_l: [
    MainStyle.w_45, MainStyle.m_l_9, MainStyle.f_w_700, MainStyle.f_s_18, MainStyle.l_h_48, MainStyle.t_a_c,
    MainStyle.c_white
  ],
  index_s: [MainStyle.f_w_700, MainStyle.f_s_10, MainStyle.l_h_14, MainStyle.t_a_c, MainStyle.c_white],
  time_date_l: [
    MainStyle.w_116, MainStyle.f_w_400, MainStyle.f_s_18, MainStyle.l_h_48, MainStyle.t_a_c, MainStyle.c_white
  ],
  time_l: [
    MainStyle.w_116, MainStyle.f_w_400, MainStyle.f_s_18, MainStyle.l_h_48, MainStyle.t_a_c, MainStyle.c_white
  ],
  type_l: [MainStyle.w_224, MainStyle.f_w_700, MainStyle.f_s_18, MainStyle.l_h_48, MainStyle.t_a_c, MainStyle.c_white],
  type_s: [MainStyle.f_w_700, MainStyle.f_s_10, MainStyle.l_h_14, MainStyle.t_a_c, MainStyle.c_white, MainStyle.flex1],
  amount_l: [MainStyle.w_160, MainStyle.f_w_700, MainStyle.f_s_18, MainStyle.l_h_48, MainStyle.t_a_c, MainStyle.c_white],
  amount_s: [MainStyle.flex1, MainStyle.f_w_700, MainStyle.f_s_10, MainStyle.l_h_55, MainStyle.t_a_c, MainStyle.c_white],
  status_l: [MainStyle.w_130, MainStyle.f_w_700, MainStyle.f_s_18, MainStyle.l_h_48, MainStyle.t_a_c, MainStyle.c_white],
  status_s: [MainStyle.f_w_700, MainStyle.f_s_10, MainStyle.l_h_14, MainStyle.t_a_c, MainStyle.c_white],
};

export default React.memo(TransactionHistoryItem);
