import React, {useState} from "react";
import {Image, Pressable, View} from "react-native";
import MainStyle from "../../MainStyle";
import FilterChip from "./FilterChip";
import Group3220 from "../assets/img/icons/Group 3220.svg";
import Subtract from "../assets/img/icons/Subtract.svg";
import TournamentsFilterModal from "../screens/Tournaments/componenets/TournamentsFilterModal";

type Props = {
  filters: string[], filtersChange: (val: string[]) => void, sortBy: string, sortChange: (val: string) => void,
  mb?: string | number
};

const TournamentsFilter = (props: Props) => {
  const {filters, sortBy, mb} = props;
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onChangeFilter = (item: string) => {
    if (filters.includes(item)) props.filtersChange(filters.filter((filter) => filter !== item));
    else props.filtersChange([...filters, item]);
  };

  return (
    <>
      <View style={[MainStyle.m_x_32, MainStyle.row, {marginBottom: mb}]}>
        <View style={[MainStyle.row, MainStyle.flex1, MainStyle.f_w_wrap]}>
          <FilterChip my={2.5} disable>Sort by: {sortBy}</FilterChip>

          {filters.map(item => (
            <FilterChip key={item} my={2.5} onClose={() => onChangeFilter(item)}>{item}</FilterChip>
          ))}
        </View>

        <Pressable style={styles.icon_btn} onPress={() => setIsOpenFilterModal(true)}>
          <Image source={Group3220 as any} style={[MainStyle.w_12, MainStyle.h_21]}/>
          <Image source={Subtract as any} style={[MainStyle.w_13, MainStyle.h_21, MainStyle.m_l_2]}/>
        </Pressable>
      </View>

      <TournamentsFilterModal
        isOpen={isOpenFilterModal}
        onClose={() => setIsOpenFilterModal(false)}
        filters={filters}
        onChangeFilter={onChangeFilter}
        sortBy={sortBy}
        sortChange={props.sortChange}
      />
    </>
  );
};

const styles = {
  icon_btn: [
    MainStyle.b_w_1, MainStyle.b_c_settings_border, MainStyle.bg_settings, MainStyle.w_48, MainStyle.h_48,
    MainStyle.b_r_40, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.row
  ]
};

export default React.memo(TournamentsFilter);
