import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Platform, ScrollView, useWindowDimensions} from 'react-native';
import {LANDING_PAGE_URL, SESSION_EXPIRE_TIME, tabletBreakpoint} from '../../../constants';
import ProfileContent from './Tabs/Profile';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {StoreContext} from "../../../components/StoreContextProvider";
import MainStyle from "../../../../MainStyle";
import ProfileHeader from "../../../components/ProfileHeader";
import {User} from "../../../../generated/smg_service_pb";
import {useIsFocused, useNavigation, useRoute} from "@react-navigation/native";
import Wallet from "./Tabs/Wallet";
import Withdraw from "../../../components/Withdraw";
import {Div} from "react-native-magnus";
import AccountNavBar from "../../../components/AccountNavBar";
import moment from 'moment';
import {useSafeAreaInsets} from "react-native-safe-area-context";
import BottomNavigation from '../../../components/BottomNavigation';

const Account = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const Context = useContext(StoreContext);
  const {session, user, cancelModalConfig} = Context;
  const navigation: any = useNavigation();
  const route = useRoute();
  const {width} = useWindowDimensions();
  const isSm = width < tabletBreakpoint;
  const insets = useSafeAreaInsets();
  // const confirmEmailDialogWasShown = useRef(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!user || !isFocused) return;
    let time: any;

    time = setTimeout(() => {
      Context.getUser().then();
    }, 1000 * 15);

    return () => {
      clearTimeout(time);
    };
  }, [user, isFocused]);

  useEffect(() => {
    const time = setInterval(() => {
      (async () => {
        const lastActiveTime = await AsyncStorage.getItem('lastActiveTime');

        if (lastActiveTime && moment().diff(moment(lastActiveTime), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') {
              window.open(LANDING_PAGE_URL, '_self');
            }
            Context.setLoadingOverlay(false);
          });
        }
      })();
    }, 1000 * 60);

    return () => {
      clearInterval(time);
    };
  }, []);

  useLayoutEffect(() => {
    if (!session || !user) {
      Context.setLoadingOverlay(true);

      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') {
              window.open(LANDING_PAGE_URL, '_self');
            }
            Context.setLoadingOverlay(false);
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);
              console.log('user', curUser);

            } catch (e: any) {
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
              });
            }
          }

          Context.setLoadingOverlay(false);
          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            });
          }
        }
      })();

    } else {
      console.log('user', user);
    }
  }, []);

  useLayoutEffect(() => {
    const params: any = route.params;
    if (params) setCurrentTab(+params.step);
  }, [route.params]);

  return (
    <Div flex={1} pt={insets.top} position="relative">
      <ScrollView contentContainerStyle={MainStyle.min_h_full}>
        <ProfileHeader mb={25}/>

        <Div alignItems="center">
          <AccountNavBar
            isSm={isSm}
            tabIndex={currentTab}
            onChange={(step) => navigation.navigate({name: 'account', params: {step}})}
          />
        </Div>

        {currentTab === 0 && user && <ProfileContent/>}
        {currentTab === 1 && user && <Wallet/>}
        {currentTab === 2 && user && (
          <Withdraw changeTab={(step) => navigation.navigate({name: 'account', params: {step}})}/>
        )}
      </ScrollView>

      <Div alignItems="center" maxW={1125} mx="auto" w="100%">
        <BottomNavigation page="account"/>
      </Div>
    </Div>
  );
};

export default Account;
