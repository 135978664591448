import React from "react";
import {Text, View, Pressable, StyleProp, ViewStyle} from "react-native";
import MainStyle from "../../MainStyle";
import {AntDesign} from "@expo/vector-icons";
import {colors} from "../assets/styles/colors";

type Props = {
  children?: React.ReactNode, w?: string | number, onPress?: () => void, active?: boolean, dir?: string,
  style?: StyleProp<ViewStyle>
};

const Cell = (props: Props) => {
  const {children, w, active, dir, style} = props;

  return (
    <Pressable onPress={props.onPress} style={[MainStyle.row, MainStyle.j_c_c, MainStyle.a_i_e, style, {width: w}]}>
      <Text style={[...styles.row_header, active && MainStyle.c_new_green_text]}>{children}</Text>
      <View style={[MainStyle.m_l_2, MainStyle.m_b_1]}>
        <AntDesign
          name="caretup"
          size={4}
          color={active && dir === 'ascending' ? colors.newGreenText : 'white'}
          style={MainStyle.l_h_3_5}
        />
        <AntDesign
          name="caretdown"
          size={4}
          color={active && dir !== 'ascending' ? colors.newGreenText : 'white'}
          style={MainStyle.l_h_3_5}
        />
      </View>
    </Pressable>
  );
};

const styles = {
  row_header: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.l_h_12]
};

export default React.memo(Cell);
