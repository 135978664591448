import React, {useCallback, useContext} from "react";
import {useWindowDimensions, Text, View, Image, Linking} from "react-native";
import SupportGuideHeader from "../components/ SupportGuideHeader";
import {useNavigation} from "@react-navigation/native";
import {StoreContext} from "../components/StoreContextProvider";
import SupportGuideButtonMenu from "../components/SupportGuideButtonMenu";
import {breakpoint, SkillMoneyLanding} from "../constants";
import MainStyle from "../../MainStyle";
import {Div, ScrollDiv} from "react-native-magnus";
import step1Safari from "../assets/img/safari-guide-step-1.jpeg"
import step2Safari from "../assets/img/safari-guide-step-2.jpeg"
import step3Safari from "../assets/img/safari-guide-step-3.jpeg"
import step1 from "../assets/img/settings-step1.jpg"
import step2 from "../assets/img/settings-step2.jpg"
import step3 from "../assets/img/settings-step3.jpg"
import step4 from "../assets/img/settings-step4.jpg"
import {colors} from "../assets/styles/colors";

const LocationSettings = () => {
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {width} = useWindowDimensions();
  const isSm = width < breakpoint;

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  return (
    <>
      <SupportGuideHeader title="LOCATION SETTINGS" onExit={onExit}/>

      <ScrollDiv bg={colors.bg_contest} flex={1} pb={isSm ? 27 : 92}>
        <Div maxW={1140} w="100%" pt={isSm ? 17 : 27} px={isSm ? 40 : 0} mx="auto">
          <Text style={isSm ? styles.title_s : styles.title_l}>How to Enable Geo-Location</Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>If you are using Safari:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>1</Text>
            <View>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Tap the <Text style={MainStyle.Barlow700}>AA</Text> button to the left of the URL field.
              </Text>
              <Image source={step1Safari} style={{width: 240, height: 145}}/>
            </View>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>2</Text>
            <View>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Tap <Text style={MainStyle.Barlow700}>Website Settings</Text>.
              </Text>
              <Image source={step2Safari} style={{width: 240, height: 350}}/>
            </View>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>3</Text>
            <View>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Tap <Text style={MainStyle.Barlow700}>Location</Text> and select <Text
                style={MainStyle.Barlow700}>Ask</Text>.
              </Text>
              <Image source={step3Safari} style={{width: 240, height: 310}}/>
            </View>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            After enabling Location Access, go to <Text style={MainStyle.t_d_l_u}
                                                        onPress={() => Linking.openURL(SkillMoneyLanding)}>SkillMoney.com</Text>,
            reload your page, and select “Allow”. If you are still having trouble with enabling location, continue with
            these steps:
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>For iOS Devices:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>1</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Open the Settings app on your iPhone or iPad.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>2</Text>
            <View>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Scroll down and tap on Privacy & Security.
              </Text>
              <Image source={step1} style={{width: 188, height: 303}}/>
            </View>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>3</Text>
            <View>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>Tap Location Services.</Text>

              <Image source={step2} style={{width: 213, height: 182}}/>

              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Ensure that Location Services is toggled on.
              </Text>
            </View>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>4</Text>
            <View>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                {`Scroll down and select your browser app\n(e.g., Safari Websites, Chrome Websites).`}
              </Text>
              <Image source={step3} style={{width: 180, height: 212}}/>
            </View>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>5</Text>
            <View>
              <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                Choose the following option:
              </Text>
              <View style={MainStyle.row}>
                <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
                <View>
                  <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                    Ask Next Time or When I Share
                  </Text>
                  <Image source={step4} style={{width: 215, height: 203}}/>
                </View>
              </View>
            </View>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            After enabling Location Access, go to <Text style={MainStyle.t_d_l_u}
                                                        onPress={() => Linking.openURL(SkillMoneyLanding)}>SkillMoney.com</Text>,
            reload your page, and select “Allow”.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>For Android Devices:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>1</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Open the Settings app on your Android device.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>2</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Scroll down and tap on Location.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>3</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Toggle Use location to on.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>4</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Go back to the main Settings menu and tap on Apps (& notifications on some devices).
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>5</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Find and tap on your web browser app from the list.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>6</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Tap on Permissions.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>7</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Tap on Location.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>8</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Choose to allow location access to Only While Using the App.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>9</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              When a website requests your location in the browser, a permission prompt will appear, and you can choose
              to Allow or Block the request.
            </Text>
          </View>
        </Div>
      </ScrollDiv>

      <SupportGuideButtonMenu isSm={isSm} onExit={onExit}/>
    </>
  );
};

const styles = {
  title_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.m_b_30, MainStyle.t_t_u],
  title_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.m_b_15, MainStyle.t_t_u],

  text_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.m_b_30, MainStyle.l_h_36],
  text_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.m_b_15, MainStyle.l_h_16],

  dot_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.l_h_36, MainStyle.m_x_16],
  dot_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.l_h_16, MainStyle.m_x_8]
};

export default LocationSettings;
