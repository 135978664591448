import React, {useContext, useState} from 'react';
import {View, Text, Modal, LayoutChangeEvent, ScrollView, Platform} from 'react-native';
import MainStyle from '../../../../../../MainStyle';
import Button from '../../../../../components/Button';
import {StoreContext} from "../../../../../components/StoreContextProvider";
import {colors} from "../../../../../assets/styles/colors";
import {browser} from "../../../../../utils";

const Internet_connection_issues_message = 'Internet connection issues detected. Please reload the page.';
const Location_is_turned_offSm = `1. If you selected "Don't Allow", reload your website. You'll be prompted to Allow Location Access again.
Select "Allow".

2. If you didn't see a pop-up, follow the instructions on this link:
`;

const CancelModal = () => {
  const Context = useContext(StoreContext);
  const {cancelModalConfig, onLine} = Context;
  const {visible, title, subtitle, secondary, leftBtnText, rightBtnText, titleColor, cancelGray} = cancelModalConfig;
  const {leftBtnBackgroundColor, leftBtnFontSize, rightBtnFontSize, rightBtnBackgroundColor} = cancelModalConfig;
  const {rightBtnBorderColor, rightBtnBorderWidth, rightBtnColor} = cancelModalConfig;
  const [containerHeight, setContainerHeight] = useState(164);

  const renderSecondary = () => {
    if (subtitle === 'Permission to access the location was denied.') return 'Note: You can still access your account.';
    else return secondary;
  };

  const renderTitle = () => {
    if (subtitle === 'Permission to access the location was denied.') return 'TURN ON LOCATION ACCESS';
    else return title;
  };

  const renderSubtitle = () => {
    if (subtitle === 'Permission to access the location was denied.') return Location_is_turned_offSm;
    else return subtitle;
  };

  if (Platform.OS === 'web' && visible && !onLine && subtitle !== Internet_connection_issues_message) {
    Context.setCancelModalConfig({
      visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: Internet_connection_issues_message,
      onExit: () => {
        Context.setCancelModalConfig({
          ...cancelModalConfig, subtitle: Internet_connection_issues_message, visible: false
        });
      }
    });
  }

  const enablingLocationGuide = () => {
    if (Platform.OS === 'web' && subtitle === 'Permission to access the location was denied.') {
      return `https://www.google.com/search?q=how+do+I+turn+on+location+for+a+website+in+${browser()}`;
    }
  };

  const onLayout = (event: LayoutChangeEvent) => {
    const {height} = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  return (
    <Modal animationType="fade" transparent visible={!!visible} statusBarTranslucent={false}
           hardwareAccelerated={false} onRequestClose={cancelModalConfig.onResume}>
      <View style={styles.wrap}>
        <ScrollView contentContainerStyle={[MainStyle.full_h, MainStyle.full_w, MainStyle.a_i_c, MainStyle.j_c_c]}>
          <View style={styles.container_s} onLayout={onLayout}>
            <Text style={[...styles.title_s, titleColor]}>{renderTitle()}</Text>

            <Text style={styles.subtitle_s}>{renderSubtitle()}</Text>

            {enablingLocationGuide() && (
              <Text style={[...styles.subtitle_s, MainStyle.m_y_10, {color: '#8ab4f8'}]}
                    onPress={() => window.open(enablingLocationGuide(), '_blank')}>
                How To Enable Location
              </Text>
            )}

            {!!renderSecondary() && (
              <Text style={styles.subtitle_s}>{renderSecondary()}</Text>
            )}

            {(leftBtnText || rightBtnText) ? (
              <View style={[...styles.btnGroup, {top: containerHeight + 60}]}>
                <View>
                  {(!!cancelModalConfig.onExit && leftBtnText) && (
                    <Button h={63} w={244} text={leftBtnText} onPress={cancelModalConfig.onExit} mb={16}
                            bg={leftBtnBackgroundColor} fontSize={leftBtnFontSize}/>
                  )}

                  <View style={[MainStyle.m_b_27, MainStyle.h_40]}>
                    {(!!cancelModalConfig.onResume && rightBtnText) && (
                      <Button text={rightBtnText} onPress={cancelModalConfig.onResume} w={244} h={63}
                              fontSize={rightBtnFontSize} bg={rightBtnBackgroundColor} borderColor={rightBtnBorderColor}
                              borderWidth={rightBtnBorderWidth} color={rightBtnColor}/>
                    )}
                  </View>
                </View>

                <View style={MainStyle.h_22}>
                  {(!!cancelModalConfig.onResume && rightBtnText && !!cancelModalConfig.onClose) && (
                    <Button text="CANCEL" onPress={cancelModalConfig.onClose} w={244} h={63} bg="transparent"
                            borderColor={cancelGray ? 'gray' : colors.popupDangerPrimary} borderWidth={1}
                            textStyle={[cancelGray ? MainStyle.c_gray : MainStyle.c_warn]}/>
                  )}
                </View>
              </View>
            ) : (
              <View style={[...styles.btnGroup, {top: containerHeight + 110}]}>
                <View style={[MainStyle.a_i_c, MainStyle.row, MainStyle.w_244]}>
                  {!!cancelModalConfig.onExit && (
                    <Button text="Yes" onPress={cancelModalConfig.onExit} style={MainStyle.flex1}/>
                  )}

                  <View style={MainStyle.w_8}/>

                  {!!cancelModalConfig.onResume && (
                    <Button text="No" onPress={cancelModalConfig.onResume} style={MainStyle.flex1}/>
                  )}
                </View>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = {
  wrap: [MainStyle.full_h, MainStyle.full_w, MainStyle.bc_modalBg, MainStyle.fix_gradient_and_modal_error_in_landscape],
  container_s: [
    MainStyle.m_x_16, MainStyle.max_w_328, MainStyle.bc_darkblue, MainStyle.min_h_164, MainStyle.b_w_1,
    MainStyle.b_c_popupBorder, MainStyle.b_r_4, MainStyle.p_y_23, MainStyle.p_x_16, MainStyle.relative, MainStyle.full_w
  ],
  container_l: [
    MainStyle.m_x_16, MainStyle.max_w_690, MainStyle.bc_darkblue, MainStyle.min_h_260, MainStyle.b_w_1,
    MainStyle.b_c_popupBorder, MainStyle.b_r_4, MainStyle.p_t_37, MainStyle.p_b_20, MainStyle.p_x_27, MainStyle.relative,
    MainStyle.full_w
  ],
  title_s: [MainStyle.c_warn, MainStyle.f_w_700, MainStyle.f_s_20, MainStyle.l_h_27, MainStyle.t_a_c, MainStyle.m_b_22],
  title_l: [MainStyle.c_warn, MainStyle.f_w_700, MainStyle.f_s_48, MainStyle.t_a_c, MainStyle.m_b_37],
  btnGroup: [MainStyle.absolute, MainStyle.l_0, MainStyle.r_0, MainStyle.a_i_c],
  btn: [MainStyle.t_a_c, MainStyle.c_warn, MainStyle.f_w_700, MainStyle.f_s_16, MainStyle.l_h_22],
  subtitle_s: [MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.l_h_19, MainStyle.c_white, MainStyle.t_a_c],
  subtitle_l: [MainStyle.f_w_600, MainStyle.f_s_32, MainStyle.c_white, MainStyle.t_a_c]
};

export default CancelModal;
