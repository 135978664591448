// @ts-ignore
import {SMG_AUTH_WORLDPAY, SMG_ENVIRONMENT, SMG_SERVER_URL, SMG_TRANSACTOR_WORLDPAY, SMG_TRANSACTOR_WORLDPAY_WEBSOCKET, SMG_PAY_PAGE_ID, SMG_REPORT_GROUP, SMG_ePROTECT_URL} from 'react-native-dotenv';

import Config from 'react-native-config';
import {Platform} from 'react-native';
import {SmgServiceClient} from '../../generated/Smg_serviceServiceClientPb';
import {PhoneNumberUtil} from 'google-libphonenumber';

export const smgServerUrl = Config.SMG_SERVER_URL || SMG_SERVER_URL;
export const smgEnvironment = Config.SMG_ENVIRONMENT || SMG_ENVIRONMENT || 'local';
export const smgAuthWorldpay = Config.SMG_AUTH_WORLDPAY || SMG_AUTH_WORLDPAY;
export const smgTransactorWorldpay = Config.SMG_TRANSACTOR_WORLDPAY || SMG_TRANSACTOR_WORLDPAY;
export const smgTransactorWorldpayWebsocket = Config.SMG_TRANSACTOR_WORLDPAY_WEBSOCKET || SMG_TRANSACTOR_WORLDPAY_WEBSOCKET;
export const smgPayPageId = Config.SMG_PAY_PAGE_ID || SMG_PAY_PAGE_ID;
export const smgReportGroup = Config.SMG_REPORT_GROUP || SMG_REPORT_GROUP;
export const smgEProtectUrl = Config.SMG_ePROTECT_URL || SMG_ePROTECT_URL;

export const SMG_Client = new SmgServiceClient(smgServerUrl);
export const Test_Client = new SmgServiceClient('http://10.8.0.14:50051');

// const RomaAccounts = {
//   dev: {deactivated: 6, lastCreated: 17}, internal: {deactivated: 7, lastCreated: 14}, external: {lastCreated: 1}
// };

export const transformImg = (base64: string): string => {
  let val = base64;

  val = val.replace('data:image/png;base64,', '');
  val = val.replace('data:image/jpg;base64,', '');
  val = val.replace('data:image/jpeg;base64,', '');

  return val;
};

export const breakpoint = 1150;
export const tabletBreakpoint = 768;

export const preventSelect: any = Platform.select({web: {userSelect: 'none'}});

export const outline_0: any = Platform.select({web: {outline: 0}});

export const phoneUtil = PhoneNumberUtil.getInstance();

export const payoutFormat = (val: number) => {
  if (Number.isInteger(val)) return val;
  else return val.toFixed(2);
};

export const CustomScrollbarStyle = `
<style>
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0d0d0d;
  border-radius: 4px;
}
</style>
`;

export const FixForTournamentFlatListAlignment = `
<style>
#tournament_list > div > div {
scroll-snap-align: center !important;
}
</style>
`;

export const SkillMoneyLanding = 'https://skillmoney.com';
export const LANDING_PAGE_URL = smgEnvironment === 'prod' ? SkillMoneyLanding : '/splash';
export const SESSION_EXPIRE_TIME = 30; // minutes
