import React from "react";
import {Div} from "react-native-magnus";
import {Golf2Challenge} from "../../generated/smg_service_pb";
import {Text} from "react-native";
import MainStyle from "../../MainStyle";
import SelectChallengeGradient from "./SelectChallengeGradient";

const Golf2ChallengeLevel = Golf2Challenge.Golf2ChallengeLevel;

type Props = {
  challenges: Golf2Challenge.AsObject[], challengeId: number, onChange: (val: number) => void, amount: number
};

const NewGameChallenges = (props: Props) => {
  const {challenges, challengeId, amount} = props;
  const isLg = false;

  const setWhiteColor = (active: boolean) => {
    return !active ? MainStyle.c_white : null;
  };

  return (
    <Div flex={1} flexDir="row" justifyContent={isLg ? 'flex-end' : 'center'} my={isLg ? 86 : 9}>
      <Div>
        <Text style={[...styles[isLg ? 'label_l' : 'label_s'], MainStyle.t_a_l, MainStyle.p_l_10]}>Level</Text>

        {challenges.map(el => (
          <SelectChallengeGradient active={challengeId === el?.challengeId} position="left" key={el.challengeId}
                                   onPress={() => props.onChange(el?.challengeId)}>
            <Text style={[...styles[isLg ? 'lvl_l' : 'lvl_s'], setWhiteColor(challengeId === el?.challengeId)]}>
              {el.level === Golf2ChallengeLevel.EASY && 'Easy'}
              {el.level === Golf2ChallengeLevel.MEDIUM && 'Medium'}
              {el.level === Golf2ChallengeLevel.HARD && 'Hard'}
            </Text>
          </SelectChallengeGradient>
        ))}
      </Div>

      <Div>
        <Text style={styles[isLg ? 'label_l' : 'label_s']}>Distance</Text>
        {challenges.map(el => (
          <SelectChallengeGradient active={challengeId === el.challengeId} position="middle" key={el.challengeId}
                                   onPress={() => props.onChange(el.challengeId)}>
            <Text style={[...styles[isLg ? 'text_l' : 'text_s'], setWhiteColor(challengeId === el.challengeId)]}>
              {el?.gamedetails?.hole?.distance} YDS
            </Text>
          </SelectChallengeGradient>
        ))}
      </Div>

      <Div>
        <Text style={styles[isLg ? 'label_l' : 'label_s']}>Win Circle</Text>
        {challenges.map(el => (
          <SelectChallengeGradient active={challengeId === el.challengeId} position="middle" key={el.challengeId}
                                   onPress={() => props.onChange(el.challengeId)}>
            <Text style={[...styles[isLg ? 'text_l' : 'text_s'], setWhiteColor(challengeId === el.challengeId)]}>
              {(el.gamedetails && el.gamedetails.circle) ? el.gamedetails.circle.winCircleDistance / 12 : 0} FT
            </Text>
          </SelectChallengeGradient>
        ))}
      </Div>

      <Div>
        <Text style={styles[isLg ? 'label_l' : 'label_s']}>Payout</Text>
        {challenges.map(el => (
          <SelectChallengeGradient active={challengeId === el.challengeId} position="right" key={el.challengeId}
                                   onPress={() => props.onChange(el.challengeId)}>
            <Text style={[...styles[isLg ? 'amount_l' : 'amount_s'], challengeId !== el.challengeId ? MainStyle.c_light_blue : null]}>
              ${amount / 100 * el.multiplier}
            </Text>
          </SelectChallengeGradient>
        ))}
      </Div>
    </Div>
  );
};

const styles = {
  label_s: [
    MainStyle.f_w_600, MainStyle.f_s_12, MainStyle.l_h_24, MainStyle.c_light_blue, MainStyle.t_a_c
  ],
  label_l: [
    MainStyle.f_w_600, MainStyle.f_s_20, MainStyle.l_h_23, MainStyle.c_light_blue, MainStyle.m_b_19, MainStyle.t_a_c
  ],
  lvl_s: [MainStyle.f_w_600, MainStyle.f_s_16, MainStyle.l_h_30, MainStyle.m_l_10, MainStyle.m_r_10],
  lvl_l: [MainStyle.f_w_600, MainStyle.f_s_32, MainStyle.l_h_50, MainStyle.m_l_10, MainStyle.m_r_15],

  text_s: [MainStyle.f_w_600, MainStyle.f_s_16, MainStyle.l_h_30, MainStyle.m_x_10],
  text_l: [MainStyle.f_w_600, MainStyle.f_s_32, MainStyle.l_h_50, MainStyle.m_x_15],

  amount_s: [MainStyle.f_w_900, MainStyle.f_s_18, MainStyle.m_r_10, MainStyle.l_h_30, MainStyle.m_l_10],
  amount_l: [MainStyle.f_w_900, MainStyle.f_s_36, MainStyle.m_r_10, MainStyle.l_h_50, MainStyle.m_l_15]
};

export default React.memo(NewGameChallenges);
