import React, {useContext, useState} from "react";
import {Pressable, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import TournamentRegistrationConfirmationModal from "../modals/TournamentRegistrationConfirmationModal";
import ServiceClient from "../utils/ServiceClient";
import {StoreContext} from "./StoreContextProvider";

type Props = {
  entryfee?: number, id?: number, onReload: () => void, registered: boolean, joinDisabled: boolean, totalShots: number,
  leave: () => void, autoPlayAmount?: number, leaveDisabled: boolean
};

const JoinTournamentButton = (props: Props) => {
  const {entryfee, id, registered, joinDisabled, totalShots, autoPlayAmount, leaveDisabled} = props;
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const [tournamentRegistrationModalType, setTournamentRegistrationModalType] = useState<'RegistrationConfirmationModal' | 'LowBalanceModal' | 'CancelCashChallenge' | 'LeaveTournament' | ''>('');

  const LeaveTournament = () => {
    if (!leaveDisabled) setTournamentRegistrationModalType('LeaveTournament');
  };

  const enableAutoPlay = (fee: number) => {
    Context.setLoadingOverlay(true);
    setTournamentRegistrationModalType('');

    ServiceClient.enableAutoPlay(true, fee).then(() => {
      Context.setLoadingOverlay(false);
      props.onReload();

    }).catch(err => {
      console.log('enableAutoPlay err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const joinTournament = (val: string) => {
    if (!id) return;
    Context.setLoadingOverlay(true);
    setTournamentRegistrationModalType('');

    ServiceClient.joinTournament(id, +val * 100).then(() => {
      setTimeout(() => {
        Context.setLoadingOverlay(false);
        props.onReload();
      }, 500);

    }).catch(err => {
      console.log('joinTournament err', err);
      Context.setLoadingOverlay(false);

      if (err.message === 'insufficient balance') {
        setTournamentRegistrationModalType('LowBalanceModal');

      } else {
        Context.setCancelModalConfig({
          visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
          onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
        });
      }
    });
  };

  return (
    <>
      {registered ? (
        <View style={[MainStyle.row, MainStyle.w_350, MainStyle.m_x_auto, MainStyle.j_c_s_between]}>
          <Pressable onPress={LeaveTournament}
                     style={[...styles.btn_wrap, ...styles.leave_btn, leaveDisabled && MainStyle.o_06, autoPlayAmount ? MainStyle.w_170 : {}]}>
            <Text style={styles.text_btn}>Leave Tournament</Text>
          </Pressable>

          {autoPlayAmount && (
            <Pressable style={[...styles.btn_wrap, ...styles.leave_btn, MainStyle.w_170]}
                       onPress={() => setTournamentRegistrationModalType('CancelCashChallenge')}>
              <Text style={styles.text_btn}>Cancel Cash Challenge</Text>
            </Pressable>
          )}
        </View>
      ) : (
        <Pressable style={[...styles.btn_wrap, joinDisabled && MainStyle.o_06]} disabled={joinDisabled}
                   onPress={() => setTournamentRegistrationModalType('RegistrationConfirmationModal')}>
          <Text style={[MainStyle.c_mainBackground, MainStyle.Barlow600, MainStyle.f_s_22]}>
            Join This Tournament
          </Text>

          <Text style={[MainStyle.c_mainBackground, MainStyle.Barlow500, MainStyle.f_s_18]}>
            ${entryfee ? entryfee / 100 : 0} Entry Fee
          </Text>
        </Pressable>
      )}

      <TournamentRegistrationConfirmationModal
        isOpen={!!tournamentRegistrationModalType}
        onClose={() => setTournamentRegistrationModalType('')}
        joinTournament={val => {
          if (tournamentRegistrationModalType === 'CancelCashChallenge') {
            enableAutoPlay(0);

          } else if (tournamentRegistrationModalType === 'LeaveTournament') {
            setTournamentRegistrationModalType('');
            props.leave();

          } else {
            joinTournament(val);
          }
        }}
        type={tournamentRegistrationModalType}
        totalShots={totalShots}
        entryFee={entryfee || 0}
        helperText={tournamentRegistrationModalType !== 'CancelCashChallenge' && tournamentRegistrationModalType !== 'LeaveTournament' ? 'Note: Your Auto-Play will be stopped during a Tournament until you have completed your Tournament Shots or the Tournament Time has ended.' : ''}
        entryChallengeFee={`${(autoPlayAmount || 0) / 100}`}
      />
    </>
  );
};

const styles = {
  btn_wrap: [
    MainStyle.m_x_auto, MainStyle.w_350, MainStyle.h_63, MainStyle.a_i_c, MainStyle.b_r_70, MainStyle.bg_newGreen,
    MainStyle.j_c_c, MainStyle.m_b_10
  ],
  leave_btn: [MainStyle.bg_transparent, MainStyle.b_w_1, MainStyle.b_c_white],
  text_btn: [MainStyle.c_white, MainStyle.Barlow700, MainStyle.t_a_c, MainStyle.f_s_18]
};

export default React.memo(JoinTournamentButton);
