import React, {useState, ReactNode} from 'react';
import {
  GestureResponderEvent, StyleProp, Text, ViewStyle, TouchableOpacity, TextStyle, ColorValue, ActivityIndicator,
  LayoutChangeEvent
} from 'react-native';
import MainStyle from '../../MainStyle';
import {Fontisto} from '@expo/vector-icons';
import {Div} from "react-native-magnus";

type Props = {
  text: string, onPress?: ((event: GestureResponderEvent) => void), style?: StyleProp<ViewStyle>, w?: string | number,
  textStyle?: StyleProp<TextStyle>, h?: string | number, radius?: number, bg?: ColorValue, borderWidth?: number,
  fontFamily?: string, borderColor?: ColorValue, fontSize?: number, mb?: string | number, ml?: string | number,
  mt?: string | number, cardType?: string, color?: ColorValue, isLoading?: boolean, mx?: number | string,
  my?: number | string, shadow?: boolean, autoPlayIcon?: ReactNode, disabled?: boolean
};

const Button = (props: Props) => {
  const {text, style, textStyle, w, h, radius, bg, borderWidth, mb, borderColor, fontFamily, fontSize, ml, mt} = props;
  const {cardType, color, isLoading, mx, my, shadow, autoPlayIcon, disabled} = props;
  let btnStyle: StyleProp<ViewStyle> = [
    MainStyle.h_63, MainStyle.bg_newGreen, MainStyle.b_r_70, MainStyle.flex, MainStyle.j_c_c, MainStyle.a_i_c,
    MainStyle.row
  ];
  let TextS: StyleProp<TextStyle> = [MainStyle.c_mainBackground, MainStyle.Barlow500, MainStyle.f_s_22, MainStyle.t_a_c];
  const [currBtnHeight, setCurrBtnHeight] = useState(40);

  if (Array.isArray(style)) btnStyle = [...btnStyle, ...style];
  else if (style) btnStyle.push(style);

  if (Array.isArray(textStyle)) TextS = [...TextS, ...textStyle];
  else if (textStyle) TextS.push(textStyle);

  if (w) btnStyle.push({width: w});
  if (h) btnStyle.push({height: h});
  if (radius) btnStyle.push({borderRadius: radius});
  if (bg) btnStyle.push({backgroundColor: bg});
  if (borderWidth) btnStyle.push({borderWidth});
  if (borderColor) btnStyle.push({borderColor});
  if (fontFamily) TextS.push({fontFamily});
  if (fontSize) TextS.push({fontSize});
  if (mb) btnStyle.push({marginBottom: mb});
  if (ml) btnStyle.push({marginLeft: ml});
  if (mt) btnStyle.push({marginTop: mt});
  if (mx) btnStyle.push({marginHorizontal: mx});
  if (my) btnStyle.push({marginVertical: my});
  if (color) TextS.push({color});
  if (shadow) {
    btnStyle.push({
      shadowOffset: {width: 0, height: 4},
      shadowRadius: 4,
      shadowColor: "rgba(0, 0, 0, 0.25)",
      shadowOpacity: 1
    })
  }

  const onLayout = (event: LayoutChangeEvent) => {
    const {height} = event.nativeEvent.layout;
    setCurrBtnHeight(height);
  };

  return (
    <TouchableOpacity onPress={props.onPress} style={[...btnStyle, disabled && MainStyle.o_05]} onLayout={onLayout}
                      disabled={isLoading || disabled}>
      {cardType && (
        <Div mr={10}>
          <Fontisto name={cardType as 'visa' | 'mastercard' | 'american-express'} size={18} color="white"/>
        </Div>
      )}

      {autoPlayIcon}

      {isLoading ? (
        <ActivityIndicator color="white" size={currBtnHeight * 0.6}/>
      ) : (
        <Text style={TextS}>{text}</Text>
      )}
    </TouchableOpacity>
  );
};

export default React.memo(Button);
