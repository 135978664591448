import React from "react";
import {ActivityIndicator, Image, ScrollView, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import Logo_SMG from "../assets/img/logo_RGB_3rows_shadow.png";
import Logo from "../assets/img/Group 48096152.png";

type Props = { isLg: boolean, text: string };

const ProtectionAndDisclaimer = (props: Props) => {
  const {isLg, text} = props;

  return (
    <ScrollView contentContainerStyle={MainStyle.full_h} scrollEventThrottle={16}>
      <View style={[MainStyle.min_h_full, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.p_x_30, MainStyle.p_y_23]}>
        <View style={isLg ? styles.content_l : styles.content_s}>
          <Text style={isLg ? styles.title_l : styles.title_s}>{text}</Text>

          <ActivityIndicator color="white" size={isLg ? 60 : 30} style={isLg ? MainStyle.m_b_42 : MainStyle.m_b_26}/>

          <View style={isLg ? styles.media_l : styles.media_s}>
            <View style={[MainStyle.max_w_340, MainStyle.full_w, MainStyle.a_i_c, MainStyle.p_x_20, MainStyle.m_b_21]}>
              <View style={MainStyle.relative}>
                <Image source={Logo_SMG} style={isLg ? styles.logo_l : styles.logo_s}/>
                <Text style={[MainStyle.absolute, MainStyle.f_s_20, MainStyle.r__12, MainStyle.c_white]}>®</Text>
              </View>

              <Text style={[...styles[isLg ? 'text_l' : 'text_s'], MainStyle.Barlow700]}>DISCLAIMER:</Text>

              <Text style={isLg ? styles.text_l : styles.text_s}>
                Skill Money Games is not responsible for errors caused by technical issues with the Full Swing golf
                simulator system.
              </Text>

              <Text style={isLg ? styles.text_l : styles.text_s}>
                If you have any issues with gameplay, please contact support.
              </Text>
            </View>

            <View style={[MainStyle.max_w_260, MainStyle.full_w, MainStyle.a_i_c, MainStyle.p_x_26, MainStyle.m_b_21]}>
              <Image source={Logo} style={[...styles[isLg ? 'logo_l' : 'logo_s'], MainStyle.o_0]}/>

              <Text style={[...styles[isLg ? 'text_l' : 'text_s'], MainStyle.Barlow700]}>US Patent Numbers:</Text>

              <Text style={isLg ? styles.text_l : styles.text_s}>
                10,262,503; 10,720,026 11,250,673; 11,430,303 11,620,877
              </Text>

              <Text style={isLg ? styles.text_l : styles.text_s}>
                © Super Money Games, Inc. All Rights Reserved
              </Text>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = {
  content_l: [
    MainStyle.b_c_popupBorder, MainStyle.b_r_4, MainStyle.b_w_1, MainStyle.max_w_690, MainStyle.full_w, MainStyle.p_t_25,
    MainStyle.a_i_c, MainStyle.p_b_15
  ],
  content_s: [
    MainStyle.b_c_popupBorder, MainStyle.b_r_4, MainStyle.b_w_1, MainStyle.max_w_690, MainStyle.full_w, MainStyle.p_t_23,
    MainStyle.a_i_c, MainStyle.p_b_15
  ],

  title_l: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_32, MainStyle.l_h_40, MainStyle.m_b_22],
  title_s: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_16, MainStyle.l_h_20, MainStyle.m_b_22],

  logo_l: [MainStyle.w_194, MainStyle.h_90, MainStyle.rm_center, MainStyle.m_b_20],
  logo_s: [MainStyle.w_129, MainStyle.h_60, MainStyle.rm_center, MainStyle.m_b_17],

  media_l: [MainStyle.row, MainStyle.full_w, MainStyle.p_x_36, MainStyle.j_c_s_between],
  media_s: [MainStyle.flex, MainStyle.full_w, MainStyle.p_x_36, MainStyle.a_i_c],

  text_l: [
    MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_16, MainStyle.l_h_24, MainStyle.t_a_c, MainStyle.m_b_6
  ],
  text_s: [
    MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.l_h_18, MainStyle.t_a_c, MainStyle.m_b_4
  ]
};

export default React.memo(ProtectionAndDisclaimer);
