import React, {useContext, useEffect, useRef, useState} from "react";
import {LayoutChangeEvent, View} from "react-native";
import ServiceClient from "../utils/ServiceClient";
import MainStyle from "../../MainStyle";
import Button from "./Button";
import {StoreContext} from "./StoreContextProvider";
import {AxiosError} from "axios";
import {useNavigation} from "@react-navigation/native";
import {getCardTypeForApplePay, getLocation} from "../utils";

type Props = { isSm: boolean, amount: string };

const ApplePayButton = (props: Props) => {
  const {isSm, amount} = props;
  const wrap = useRef<any>();
  const [currBtnHeight, setCurrBtnHeight] = useState(0);
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const navigation: any = useNavigation();

  const getToken = () => {
    ServiceClient.getPayGateToken().catch(err => {
      console.log('getToken err', err);
    });
  };

  // const onApplePayButtonClicked = async () => {
  //   // Consider falling back to Apple Pay JS if Payment Request is not available.
  //   if (!PaymentRequest) return;
  //
  //   try {
  //     // Define PaymentMethodData
  //     const paymentMethodData = [{
  //       supportedMethods: 'https://apple.com/apple-pay',
  //       data: {
  //         version: 3,
  //         merchantIdentifier: 'merchant.com.supermoneygames.prelive',
  //         merchantCapabilities: ['supports3DS'],
  //         supportedNetworks: ['masterCard', 'visa'],
  //         countryCode: 'US'
  //       }
  //     }];
  //
  //     // Define PaymentDetails
  //     const paymentDetails = {total: {label: 'SkillMoneyGames', amount: {value: amount, currency: 'USD'}}};
  //
  //     // Define PaymentOptions
  //     const paymentOptions = {
  //       requestPayerName: false,
  //       requestBillingAddress: false,
  //       requestPayerEmail: false,
  //       requestPayerPhone: false,
  //       requestShipping: false,
  //       shippingType: 'shipping'
  //     };
  //
  //     // Create PaymentRequest
  //     // @ts-ignore
  //     const request: any = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);
  //
  //     request.onmerchantvalidation = (event: any) => {
  //       // Call your own server to request a new merchant session.
  //       try {
  //         event.complete(ServiceClient.validateMerchant(event.validationURL));
  //
  //       } catch (e) {
  //         console.log('onmerchantvalidation err', e);
  //       }
  //     };
  //
  //     const response = await request.show();
  //     await response.complete('success');
  //
  //   } catch (e) {
  //     console.log('Handle errors', e);
  //   }
  // };

  const pay = () => {
    // const wrap = document.getElementsByClassName('css-175oi2r')[0]
    // const button = document.createElement('button');
    // button.innerHTML = 'Apple pay';
    // wrap.appendChild(button);
    //
    // // wrap.innerHTML = `<button id="lol">Apple Pay</button>`;
    // button.addEventListener('click', () => {
    //   const request = {
    //     countryCode: 'US',
    //     currencyCode: 'USD',
    //     merchantCapabilities: ['supports3DS'],
    //     supportedNetworks: ['visa', 'masterCard'],
    //     total: {label: 'SkillMoneyGames', type: 'final', amount: '0.10'}
    //   };
    //
    //   const session = new ApplePaySession(3, request);
    //
    //   // Define payment processing functions
    //   session.onvalidatemerchant = async (event) => {
    //     // Call your own server to request a new merchant session.
    //     const merchantSession = await ServiceClient.validateMerchant(event.validationURL);
    //     console.log('merchantSession string', JSON.stringify(merchantSession, null, 2));
    //     console.log('merchantSession obj', merchantSession);
    //     session.completeMerchantValidation(merchantSession);
    //   };
    //
    //   session.onpaymentauthorized = function (event) {
    //     console.log('onpaymentauthorized', event);
    //     // Handle payment authorization
    //     const payment = event.payment;
    //     console.log('payment', payment);
    //
    //     // // Send payment details to your server for processing
    //     // // After processing, call session.completePayment() or session.completePaymentMethod()
    //     // session.completePayment(win.ApplePaySession.STATUS_SUCCESS);
    //
    //     // Define ApplePayPaymentAuthorizationResult
    //     const result = {status: ApplePaySession.STATUS_SUCCESS};
    //     session.completePayment(result);
    //   };
    //
    //   session.begin();
    // });

    (async () => {
      const curLocation = await getLocation();

      if (!curLocation) {
        Context.setLoadingOverlay(false);
        Context.setCancelModalConfig({
          visible: true, title: 'Error', subtitle: 'Permission to access the location was denied.', rightBtnText: 'OK',
          onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
        });

      } else {
        const win: any = window;

        const request = {
          countryCode: 'US',
          currencyCode: 'USD',
          merchantCapabilities: ['supports3DS'],
          supportedNetworks: ['visa', 'masterCard'],
          total: {label: 'SkillMoneyGames', type: 'final', amount}
        };

        // Create ApplePaySession
        const session = new win.ApplePaySession(3, request);

        // Define payment processing functions
        session.onvalidatemerchant = async (event: any) => {
          // Call your own server to request a new merchant session.
          const merchantSession = await ServiceClient.validateMerchant(event.validationURL);
          session.completeMerchantValidation(merchantSession);
        };

        session.onpaymentauthorized = function (event: any) {
          const payment = event.payment;
          const paymentToken = payment.token.paymentData;
          const paymentMethod = payment.token.paymentMethod;
          const cardType = getCardTypeForApplePay(paymentMethod.network);
          const depositPayload = {
            amount: +amount * 100, applePayPkPaymentToken: paymentToken, cardType,
            location: {latitude: `${curLocation.coords.latitude}`, longitude: `${curLocation.coords.longitude}`}
          };

          ServiceClient.deposit(depositPayload).then(() => {
            const result = {status: win.ApplePaySession.STATUS_SUCCESS};
            session.completePayment(result);

            Context.getUser().then(() => {
              Context.setCancelModalConfig({
                visible: true, title: 'Success', titleColor: MainStyle.c_green, leftBtnText: 'OK',
                subtitle: 'You have successfully replenished your balance.',
                onExit: () => {
                  Context.setCancelModalConfig({...cancelModalConfig, visible: false});
                  navigation.navigate('challenges');
                }
              });

            }).catch(err => {
              console.log('getUser err', err);
            });

          }).catch((err: AxiosError<{ success: boolean, message: string }>) => {
            console.log('deposit error', err);
            session.completePayment(win.ApplePaySession.STATUS_FAILURE);

            Context.setLoadingOverlay(false);
            const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

            Context.setCancelModalConfig({
              visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
              onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
            });
          });
        };

        session.begin();
      }
    })();
  };

  useEffect(() => {
    wrap.current.innerHTML = `<apple-pay-button buttonstyle="black" type="plain" locale="en-US"></apple-pay-button>`;
    getToken();
  }, []);

  const onLayout = (event: LayoutChangeEvent) => {
    const {height} = event.nativeEvent.layout;
    setCurrBtnHeight(height);
  };

  return (
    <>
      <View style={[MainStyle.absolute, {top: -1000, left: -1000}]} ref={ref => wrap.current = ref}
            onLayout={onLayout}/>
      {!!currBtnHeight && (
        <Button
          text="Apple Pay"
          onPress={pay}
          w={isSm ? 240 : 348}
          h={isSm ? 40 : 56}
          fontSize={isSm ? 16 : 24}
          bg="black"
          color="white"
        />
      )}
    </>
  );
};

export default ApplePayButton;
