import React from "react";
import {Image, ImageSourcePropType, Pressable, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import {colors} from "../assets/styles/colors";
import {MaterialCommunityIcons} from '@expo/vector-icons';

type Props = {
  title?: string, value?: string, icon?: ImageSourcePropType, mb?: number, leftText?: string, formik?: any,
  changeFee?: () => void, autoPlayAmount?: number, maxShotAmount?: number, autoPlayEnabled?: boolean
};

const ChallengeInfoCard = (props: Props) => {
  const {title, value, icon, leftText, mb, formik, autoPlayAmount, maxShotAmount, autoPlayEnabled} = props;

  return (
    <View style={[...styles.wrap, {marginBottom: mb}]}>
      {icon && (
        <View style={styles.icon_wrap}>
          <Image source={icon} style={[MainStyle.w_20, MainStyle.h_20, MainStyle.m_x_auto]}/>
        </View>
      )}

      {leftText && <Text style={styles.left_text}>{leftText}</Text>}

      <View style={[MainStyle.flex1, title ? MainStyle.a_i_c : {}]}>
        {title && <Text style={styles.title}>{title}</Text>}
        {value && <Text style={styles.value}>{value}</Text>}

        {formik && (
          <View style={MainStyle.a_i_c}>
            <Pressable style={styles.input_wrap} onPress={props.changeFee}>
              <Text style={styles.text_s}>
                {(formik.values.contestAmount === '-2' || (autoPlayEnabled && autoPlayAmount === -2)) ? `$${(maxShotAmount || 0) / 100} (Max)` : `$${formik.values.contestAmount}`}
              </Text>
              <MaterialCommunityIcons
                name="pencil-outline"
                size={22}
                color={colors.newGreenText}
                style={[MainStyle.absolute, MainStyle.r__22]}
              />
            </Pressable>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = {
  wrap: [
    MainStyle.w_160, MainStyle.h_60, MainStyle.b_r_20, MainStyle.b_w_1, MainStyle.b_c_cardInfoBorder,
    MainStyle.bg_settings, MainStyle.row, MainStyle.a_i_c, MainStyle.p_x_11
  ],
  left_text: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.w_52],
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.t_a_c, MainStyle.flex1],
  value: [MainStyle.c_new_green_text, MainStyle.Barlow500, MainStyle.f_s_18, MainStyle.t_a_c, MainStyle.flex1],
  icon_wrap: [
    MainStyle.w_36, MainStyle.h_36, MainStyle.b_r_40, MainStyle.b_w_1, MainStyle.b_c_cardInfoBorder, MainStyle.j_c_c
  ],
  input_wrap: [MainStyle.row, MainStyle.relative, MainStyle.b_b_w_1, MainStyle.b_c_newGreen, MainStyle.a_i_c],
  text_s: [
    MainStyle.Barlow500, MainStyle.f_s_20, MainStyle.c_new_green_text, MainStyle.t_a_c, MainStyle.flex1,
    MainStyle.min_w_36
  ]
};

export default ChallengeInfoCard;
