import {SMG_Client, smgAuthWorldpay, smgTransactorWorldpay} from "../constants";
import {
  AcceptBetRequest, AcceptTncRequest, BetResolutionRequest, ChangeType, CheckUserEmailExistsRequest,
  ConfirmEmailChangeRequest, ConfirmEmailRequest, ContactCsrRequest, DeactivateAccountRequest, DoResetPinRequest,
  EnableAutoPlayRequest, EnableSelfLimitationRequest, GetBetsRequest, GetPromosRequest, GetTournamentRequest,
  JoinTournamentRequest, LeaveTournamentRequest, ListTournamentRequest, RequestChangeRequest, RequestEmailChangeRequest,
  RequestResetPinRequest, RequestSmsCodeRequest, ResendEmailConfirmationRequest, Session,
  StartVeriffKycVerificationRequest, SubmitPhotoRequest, UpdatePhoneNumberRequest, UpdateUserRequest,
  VerifySmsCodeRequest
} from "../../generated/smg_service_pb";
import axios, {AxiosResponse} from "axios";
import {DepositPayloadType, PayoffResponse, SavedCardType, updateUserPayloadType, WorldpayReply} from "../types";
import {setVenueIdOrLocation} from "./index";
import {Buffer} from "buffer";

const ServiceClient = {
  client: SMG_Client,
  session: {} as Session.AsObject,
  payGateToken: '',
  async checkUserEmailExists(email: string) {
    const request = new CheckUserEmailExistsRequest();
    request.setEmailAddress(email);

    return this.client.checkUserEmailExists(request, {}).then(res => {
      const {success, error, exists} = res.toObject();
      if (success) return exists;
      else throw error;
    });
  },
  async requestSmsCode(val: { email?: string, phone?: string }) {
    const request = new RequestSmsCodeRequest();
    const {email, phone} = val;

    if (email) request.setEmailAddress(email);
    else if (phone) request.setPhoneNumber(phone);

    return this.client.requestSmsCode(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async verifySmsCode(code: string, email: string, phone: string) {
    const request = new VerifySmsCodeRequest();

    request.setSmsCode(code);
    request.setEmailAddress(email);
    request.setPhoneNumber(phone);

    return this.client.verifySmsCode(request, {}).then(res => {
      const {success, error, codeiscorrect} = res.toObject();

      if (success) return codeiscorrect;
      else throw error;
    });
  },
  async deposit(data: DepositPayloadType) {
    const config = {headers: {Authorization: `Bearer ${this.payGateToken}`}};

    return axios.post(`${smgTransactorWorldpay}/v1/transactions/deposit`, data, config).then((response: AxiosResponse<WorldpayReply<{ balance: number, orderId: string }>>) => {
      const {success, message, result} = response.data;

      if (success) return result;
      else throw {message};
    });
  },
  async validateMerchant(verificationURL: string) {
    const config = {headers: {Authorization: `Bearer ${this.payGateToken}`}};

    return axios.post(`${smgTransactorWorldpay}/v1/transactions/deposit/applepay/verification`, {verificationURL}, config).then((response: AxiosResponse<WorldpayReply<any>>) => {
      const {success, message, result} = response.data;

      if (success) return result;
      else throw {message};
    });
  },
  async getPayGateToken() {
    const data = {sessionToken: this.session?.token || ''};

    return axios.post(`${smgAuthWorldpay}/auth/v1/token`, data).then((response: AxiosResponse<WorldpayReply<{ accessToken: string }>>) => {
      const {success, result, message} = response.data;

      if (success && result) {
        this.payGateToken = result.accessToken;
        return result.accessToken;

      } else throw {message: message || 'Error'};
    });
  },
  async getSavedCards() {
    const config = {headers: {Authorization: `Bearer ${this.payGateToken}`}};
    return axios.get(`${smgTransactorWorldpay}/v1/cards/getSavedCards`, config).then((response: AxiosResponse<WorldpayReply<SavedCardType[]>>) => {
      const {success, result, message} = response.data;

      if (success && result) return result;
      else throw {message: message || 'Error'};
    });
  },
  async deleteCard(cardUid: string) {
    const config = {headers: {Authorization: `Bearer ${this.payGateToken}`}};
    const data = {cardUid};

    return axios.post(`${smgTransactorWorldpay}/v1/cards/delete`, data, config).then((res: AxiosResponse<WorldpayReply<any>>) => {
      const {success, result, message} = res.data;

      if (success) return result;
      else {
        console.log('deleteCard res.data', res.data);
        throw {message: message || 'Error'};
      }
    });
  },
  async payoff(amount: number) {
    const config = {headers: {Authorization: `Bearer ${this.payGateToken}`}};
    const data = {amount};

    return axios.post(`${smgTransactorWorldpay}/v1/transactions/payoff`, data, config).then((res: AxiosResponse<PayoffResponse>) => {
      const {success, message, result} = res.data;

      if (success) return result;
      else throw {message};
    });
  },
  async sale(data: { amount: number, location: { latitude: string, longitude: string } }) {
    const config = {headers: {Authorization: `Bearer ${this.payGateToken}`}};

    return axios.post(`${smgTransactorWorldpay}/v1/transactions/sale`, data, config).then((response: AxiosResponse<WorldpayReply<{ transactionId: string }>>) => {
      const {success, result, message} = response.data;

      if (success && result?.transactionId) return result.transactionId;
      else throw {message: message || 'Error'};
    });
  },
  async enableSelfLimitation(timeFrame: EnableSelfLimitationRequest.TimeFrame) {
    const request = new EnableSelfLimitationRequest();
    await setVenueIdOrLocation(request);

    request.setToken(this.session.token || '');
    request.setTimeFrame(timeFrame);

    return this.client.enableSelfLimitation(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      throw error;
    });
  },
  async deactivateAccount() {
    const request = new DeactivateAccountRequest();
    request.setToken(this.session.token || '');

    return this.client.deactivateAccount(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async acceptTnc() {
    const request = new AcceptTncRequest();
    request.setToken(this.session.token || '');

    return this.client.acceptTnc(request, {}).then(res => {
      const {error, success} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async getBets(state: 'open' | 'accepted', localSession?: Session.AsObject) {
    const request = new GetBetsRequest();
    request.setToken(localSession?.token || this.session.token || '');
    request.setBetState(state);

    return this.client.getBets(request, {}).then(res => {
      const {success, error, betsList} = res.toObject();

      if (success) return betsList;
      else throw error;
    });
  },
  async enableAutoPlay(enabled: boolean, betAmount: number) {
    const request = new EnableAutoPlayRequest();

    request.setToken(this.session.token || '');
    request.setEnabled(enabled);
    request.setBetamount(betAmount);

    return this.client.enableAutoPlay(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async updateUser(value: updateUserPayloadType) {
    const request = new UpdateUserRequest();
    const {allowPartnerPromos, allowEmailNotifications, allowTextNotifications, username, handicap} = value;
    const {handedness} = value;

    request.setToken(this.session.token || '');
    request.setUsername(username);
    request.setAllowTextNotifications(allowTextNotifications);
    request.setAllowEmailNotifications(allowEmailNotifications);
    request.setAllowPartnerPromos(allowPartnerPromos);
    request.setHandicap(handicap);
    request.setHandedness(handedness);
    await setVenueIdOrLocation(request);

    return this.client.updateUser(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async requestEmailChange(email: string) {
    const request = new RequestEmailChangeRequest();

    request.setNewEmail(email);
    request.setToken(this.session.token || '');

    return this.client.requestEmailChange(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async confirmEmail(token: string) {
    const request = new ConfirmEmailRequest();
    request.setConfirmationtoken(token);

    return this.client.confirmEmail(request, {}).then(res => {
      const {success, error, session} = res.toObject();

      if (success) return session;
      else throw error;
    });
  },
  async confirmEmailChange(token: string) {
    const request = new ConfirmEmailChangeRequest();
    request.setToken(token);

    return this.client.confirmEmailChange(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async updatePhoneNumber(phone: string, code?: string) {
    const request = new UpdatePhoneNumberRequest();

    request.setToken(this.session.token || '');
    if (code) request.setSmsCode(code);
    request.setPhoneNumber(phone);

    return this.client.updatePhoneNumber(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async requestChange(type: ChangeType) {
    const request = new RequestChangeRequest();

    request.setToken(this.session.token || '');
    request.setChangeType(type);

    return this.client.requestChange(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async getPromos(player_id: number) {
    const request = new GetPromosRequest();

    request.setToken(this.session.token || '');
    request.setPlayerId(player_id);

    return this.client.getPromos(request, {}).then(res => {
      const {success, error, promosList} = res.toObject();

      if (success) return promosList;
      else throw error;
    });
  },
  async listTournaments(filters: string[], sort: string) {
    const request = new ListTournamentRequest();
    request.setToken(this.session.token || '');
    const Sort = ListTournamentRequest.Sort;

    if (filters.includes('upcoming')) request.setUpcoming(true);
    if (filters.includes('My Entries')) request.setMine(true);
    if (filters.includes('live')) request.setLivenow(true);

    if (sort === 'Start Date/Time - Soonest') request.setSort(Sort.ENTRY_BEGIN_DATE_SOONEST);
    else if (sort === 'Start Date/Time - Latest') request.setSort(Sort.ENTRY_BEGIN_DATE_LATEST);
    else if (sort === 'End Date/Time - Soonest') request.setSort(Sort.ENTRY_END_DATE_SOONEST);
    else if (sort === 'End Date/Time - Latest') request.setSort(Sort.ENTRY_END_DATE_LATEST);

    return this.client.listTournaments(request, {}).then(res => {
      const {success, tournamentsList, error} = res.toObject();

      if (success) return tournamentsList;
      else throw error;
    });
  },
  async getTournament(id: number) {
    const request = new GetTournamentRequest();
    request.setToken(this.session.token || '');
    request.setTournamentid(id);

    return this.client.getTournament(request, {}).then(res => {
      const {success, error, tournament} = res.toObject();

      if (success) return tournament;
      else throw error;
    });
  },
  async joinTournament(id: number, betEntreeFee: number) {
    const request = new JoinTournamentRequest();

    request.setToken(this.session.token || '');
    request.setTournamentid(id);
    request.setBetentreefee(betEntreeFee);

    return this.client.joinTournament(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async leaveTournament(id: number) {
    const request = new LeaveTournamentRequest();
    request.setTournamentid(id);
    request.setToken(this.session.token || '');

    return this.client.leaveTournament(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async acceptBet(betId: number, amount: number, challengeId?: number) {
    const request = new AcceptBetRequest();

    request.setToken(this.session.token || '');
    if (challengeId) request.setChallengeId(challengeId);
    request.setBetId(betId);
    request.setBetamount(amount);

    return this.client.acceptBet(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async resendEmailConfirmation() {
    const request = new ResendEmailConfirmationRequest();
    request.setToken(this.session.token || '');

    return this.client.resendEmailConfirmation(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async doResetPin(token: string, newPin: string) {
    const request = new DoResetPinRequest();
    request.setToken(token);
    request.setNewPin(newPin);
    await setVenueIdOrLocation(request);

    return this.client.doResetPin(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async requestResetPin(email: string) {
    const request = new RequestResetPinRequest();
    request.setEmailAddress(email);
    await setVenueIdOrLocation(request);

    return this.client.requestResetPin(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async refundBet(betId: number) {
    const request = new BetResolutionRequest();
    request.setBetId(betId);
    request.setToken(this.session.token || '');

    return this.client.refundBet(request, {}).then(res => {
      const {resolved, error} = res.toObject();

      if (resolved) return resolved;
      else throw error;
    });
  },
  async contactCsr(message: string) {
    const request = new ContactCsrRequest();

    request.setRequest(message);
    request.setToken(this.session.token || '');
    await setVenueIdOrLocation(request);

    return this.client.contactCsr(request, {}).then(res => {
      const {success, error} = res.toObject();

      if (success) return success;
      else throw error;
    });
  },
  async submitPhoto(image: string) {
    const request = new SubmitPhotoRequest();
    const byteArray = Buffer.from(image, 'base64');

    request.setImage(byteArray);
    request.setToken(this.session.token || '');

    return this.client.submitPhoto(request, {}).then(res => {
      const {success, error, isRecognized} = res.toObject();

      if (success) return isRecognized;
      else throw error;
    });
  },
  async startVeriffKycVerification() {
    const request = new StartVeriffKycVerificationRequest();
    request.setToken(this.session.token || '');

    return this.client.startVeriffKycVerification(request, {}).then(res => {
      const {success, error, veriffurl} = res.toObject();

      if (success) return veriffurl;
      else throw error;
    });
  }
};

export default ServiceClient;
