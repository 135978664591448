import React, {useContext, useEffect, useState} from "react";
import {Modal, ScrollView, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import {colors} from "../assets/styles/colors";
import {StoreContext} from "../components/StoreContextProvider";
import Select from "../components/Select";
import icon from "../assets/img/icons/pencil-line 1.svg";
import {useNavigation} from "@react-navigation/native";
import Button from "../components/Button";

type Props = {
  isOpen: boolean, onClose: () => void, joinTournament: (val: string) => void, totalShots?: number, entryFee: number,
  type: 'RegistrationConfirmationModal' | 'LowBalanceModal' | 'ChallengeFeeConfirmationModal' | 'StartAutoPlay' | 'StopAutoPlay' | 'ChallengeEntry' | 'CancelCashChallenge' | 'ChallengeAmountModal' | 'LeaveTournament' | '',
  helperText?: string, entryChallengeFee?: string, autoPlayHandler?: () => void, min_h?: number
};

const TournamentRegistrationConfirmationModal = (props: Props) => {
  const {isOpen, type, totalShots, entryFee, helperText, entryChallengeFee, min_h} = props;
  const [value, setValue] = useState(entryChallengeFee || '0');
  const Context = useContext(StoreContext);
  const {balance, extendedUserData} = Context;
  const [items, setItems] = useState([{value: '-2', customLabel: '', label: 'Max'}]);
  const navigation: any = useNavigation();
  const showDropDown = type === 'RegistrationConfirmationModal' || type === 'ChallengeFeeConfirmationModal' || type === 'StartAutoPlay' || type === 'CancelCashChallenge' || type === 'ChallengeAmountModal';
  const [disableItems, setDisableItems] = useState([]);

  const handlePress = () => {
    if (type === 'LowBalanceModal') {
      props.onClose();
      navigation.navigate({name: 'account', params: {step: 1}});

    } else if (type === 'StopAutoPlay' && props.autoPlayHandler) {
      props.autoPlayHandler();

    } else {
      props.joinTournament(value);
    }
  };

  useEffect(() => {
    let val = totalShots ? [{value: '0', customLabel: '', label: '0'}] : [{value: '-2', customLabel: '', label: 'Max'}];
    const disableList: any = [];
    const availableBalance = balance - entryFee;
    const shotOptions = [3, 5, 10, 20];

    if (!totalShots && type === 'ChallengeFeeConfirmationModal' && (extendedUserData.isnewplayer || extendedUserData.freeshotnumber !== extendedUserData.freeshotcount)) {
      val = [{value: '3', customLabel: '', label: '$3'}];
      setValue('3');

    } else if (type === 'StartAutoPlay' || type === 'ChallengeAmountModal') {
      setValue('-2');

      shotOptions.reverse().forEach(shotOption => {
        if (shotOption * 100 <= extendedUserData.maxshotamount) {
          val.push({value: `${shotOption}`, customLabel: '', label: `$${shotOption}`});

        } else {
          disableList.push({value: `${shotOption}`, label: `$${shotOption}`});
        }
      });

    } else {
      shotOptions.forEach(shotOption => {
        const totalCost = (shotOption * 100) * (totalShots || 1);
        if (totalCost <= availableBalance && shotOption * 100 <= extendedUserData.maxshotamount) {
          val.push({
            value: `${shotOption}`,
            customLabel: `$${shotOption}`,
            label: `$${shotOption} (Total: $${totalCost / 100})`
          });
        }
      });

      if (type === 'CancelCashChallenge') setValue('0');
      else if (type === 'RegistrationConfirmationModal') setValue(val[val.length - 1].value);
    }

    setDisableItems(disableList);
    setItems(val);
  }, [balance, entryFee, totalShots, extendedUserData.maxshotamount, type]);

  return (
    <Modal animationType="fade" transparent visible={isOpen} statusBarTranslucent={false} hardwareAccelerated={false}
           onRequestClose={props.onClose}>
      <View style={[MainStyle.fix_gradient_and_modal_error_in_landscape, MainStyle.flex1, MainStyle.bg_filter_modal]}>
        <ScrollView contentContainerStyle={MainStyle.flex1}>
          <View style={[MainStyle.min_h_full, MainStyle.a_i_c, MainStyle.j_c_c]}>
            <View style={[...styles.contentWrapper, {minHeight: min_h}]}>
              <Text style={styles.title}>
                {type === 'LowBalanceModal' && 'Insufficient balance to enter Tournament with existing amount.'}
                {type === 'RegistrationConfirmationModal' && `Confirm Tournament\nEntry (${entryFee ? `$${entryFee / 100}` : 'Free'})`}
                {(type === 'ChallengeEntry' || type === 'ChallengeAmountModal') && 'Confirm Challenge Entry'}
                {type === 'ChallengeFeeConfirmationModal' && `Choose Your\nChallenge Fee`}
                {type === 'StartAutoPlay' && `Please Confirm\nStart Auto-Play`}
                {type === 'StopAutoPlay' && `Please Confirm\nCanceling Auto-Play`}
                {type === 'CancelCashChallenge' && `Please Confirm\nCancelling Cash\nChallenges`}
                {type === 'LeaveTournament' && 'Leave Tournament'}
              </Text>

              <View style={showDropDown ? [MainStyle.flex1, MainStyle.min_h_32] : MainStyle.h_51}/>

              <View style={MainStyle.m_x_35}>
                {showDropDown && (
                  <View style={[MainStyle.row, MainStyle.a_i_c, MainStyle.m_b_30]}>
                    <Text style={styles.label}>
                      {type === 'CancelCashChallenge' ? `Challenge Fee\nper Shot:` : `Select Your\nChallenge Fee\nper Shot:`}
                    </Text>

                    <View style={MainStyle.flex1}/>

                    <Select value={value} onChangeText={(val) => setValue(val)} customItems={items} pl={17} pr={21}
                            w={111} borderRadius={20} h={63} textAlign="left" icon={icon} fontSize={22}
                            borderColor={colors.settings_border} bg={colors.mainBackground} color={colors.newGreenText}
                            fontFamily="Barlow-Medium" iconSize={24} secondItems={disableItems}
                            disabledItemsBottom={!(type === 'StartAutoPlay' || type === 'ChallengeAmountModal')}
                            disabled={type === 'CancelCashChallenge'}
                            popupTitle={type !== 'StartAutoPlay' ? 'Win Money with Every Shot!' : ''}/>
                  </View>
                )}

                <Button
                  onPress={handlePress} mb={20} bg={type === 'LeaveTournament' ? colors.stop_auto_play : ''}
                  text={type === 'LowBalanceModal' ? 'Take me to deposit' : type === 'RegistrationConfirmationModal' ? 'Enter Tournament' : type === 'LeaveTournament' ? 'Leave Tournament' : 'Confirm'}
                  color={type === 'LeaveTournament' ? colors.newGreenText : ''}
                />

                <Button onPress={props.onClose} bg={colors.mainBackground} borderWidth={1} borderColor="white"
                        text={type === 'LowBalanceModal' ? 'Back to the Tournament Registration' : (type === 'StartAutoPlay' || type === 'StopAutoPlay') ? 'Back' : 'Cancel'}
                        color="white"/>
              </View>

              {!!helperText && (
                <View style={[MainStyle.max_w_389, MainStyle.full_w, MainStyle.m_t_34]}>
                  <Text style={[MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_14]}>
                    {helperText}
                  </Text>
                </View>
              )}

              {type === 'LeaveTournament' && (
                <View style={[MainStyle.max_w_389, MainStyle.full_w, MainStyle.m_t_34]}>
                  <Text style={[MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_14]}>
                    If you leave the tournament, you will not receive a refund for your entry fee.
                  </Text>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = {
  contentWrapper: [
    MainStyle.bg_settings, MainStyle.max_w_332, MainStyle.full_w, MainStyle.b_r_20, MainStyle.b_c_settings_border,
    MainStyle.b_w_1, MainStyle.p_t_30, MainStyle.min_h_400, MainStyle.p_b_41
  ],
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_26, MainStyle.t_a_c],
  label: [
    MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_16, MainStyle.w_136,
    MainStyle.t_t_u
  ],
  btn_wrap: [MainStyle.flex, MainStyle.j_c_c, MainStyle.b_r_70, MainStyle.h_63],
  btn: [MainStyle.c_mainBackground, MainStyle.t_a_c, MainStyle.Barlow600, MainStyle.f_s_20],
  subtitle_m: [
    MainStyle.f_w_400, MainStyle.f_s_18, MainStyle.l_h_19, MainStyle.c_white, MainStyle.t_a_c, MainStyle.m_b_20
  ],
  subtitle_s: [
    MainStyle.f_w_400, MainStyle.f_s_12, MainStyle.l_h_19, MainStyle.c_white, MainStyle.t_a_c, MainStyle.m_b_20
  ]
};

export default React.memo(TournamentRegistrationConfirmationModal);
