import React, {useEffect, useRef} from "react";
import {Modal, Pressable, ScrollView, Text, View, Image} from "react-native";
import MainStyle from "../../MainStyle";
import icon from '../assets/img/circle-close-icon.png';
import {CountryData, FlagEmoji} from "react-international-phone";
import {outline_0} from "../constants";

type ItemType = { value: string, label: string };

type Props = {
  visible: boolean, onResume: (val?: string) => void, isLg: boolean, value: string, items?: ItemType[],
  countries?: CountryData[], secondItems?: ItemType[], disabledItemsBottom?: boolean, popupTitle?: string
};

const SelectModal = (props: Props) => {
  const {visible, isLg, value, items, countries, secondItems, disabledItemsBottom, popupTitle} = props;
  const scrollView = useRef<ScrollView>(null);
  const itemSize = isLg ? 72 : 36;

  useEffect(() => {
    if (visible) {
      let index = 0;
      if (items) index = items.findIndex(el => el.value === value);
      else if (countries) index = countries.findIndex(el => el[1] === value);

      if (index > 6) {
        scrollView.current?.scrollTo({x: 0, y: itemSize * index - (isLg ? 385 : 190), animated: true});

      } else if (index === -1 && items) {
        const zeroIndex = items.findIndex(el => el.value === '0');
        scrollView.current?.scrollTo({x: 0, y: itemSize * zeroIndex - (isLg ? 385 : 190), animated: true});
      }
    }
  }, [visible, isLg]);

  return (
    <Modal animationType="fade" transparent visible={visible} statusBarTranslucent={false} hardwareAccelerated={false}
           onRequestClose={() => props.onResume()}>
      <View style={styles.wrap}>
        <View style={isLg ? styles.content_l : styles.content_s}>
          <Pressable style={isLg ? styles.icon_l : styles.icon_s} onPress={() => props.onResume()}>
            <Image source={icon} style={isLg ? styles.img_l : styles.img_s}/>
          </Pressable>

          <ScrollView ref={scrollView}>
            {!!secondItems?.length && (
              <>
                <Text style={[MainStyle.f_s_12, MainStyle.l_h_36, MainStyle.t_a_c, MainStyle.c_white]}>
                  {popupTitle || 'Play more to activate higher challenges!'}
                </Text>

                {!disabledItemsBottom && secondItems?.map(el => (
                  <Text key={el.value} style={[...styles[isLg ? 'item_l' : 'item_s'], MainStyle.o_03]}>
                    {el.label}
                  </Text>
                ))}
              </>
            )}

            {items?.map(el => (
              <Pressable key={el.value} onPress={() => props.onResume(el.value)}>
                <Text style={[...styles[isLg ? 'item_l' : 'item_s'], el.value === value ? styles.active : {}]}>
                  {el.label}
                </Text>
              </Pressable>
            ))}

            {(!!secondItems?.length && disabledItemsBottom) && (
              <>
                {secondItems?.map(el => (
                  <Text key={el.value} style={[...styles[isLg ? 'item_l' : 'item_s'], MainStyle.o_03]}>
                    {el.label}
                  </Text>
                ))}
              </>
            )}

            {countries?.map(el => {
              const [name, iso2, dialCode] = el;
              return (
                <Pressable style={[MainStyle.row, MainStyle.a_i_c, iso2 === value ? MainStyle.bc_popupDropBg : {}]}
                           key={iso2} onPress={() => props.onResume(iso2)}>
                  <FlagEmoji
                    iso2={iso2}
                    style={isLg ? {width: 40, height: 40, margin: 16} : {width: 20, height: 20, margin: 8}}
                  />

                  <Text
                    style={[...styles[isLg ? 'country_l' : 'country_s'], iso2 === value ? MainStyle.c_lightblue : {}]}>
                    {name} (+{dialCode})
                  </Text>
                </Pressable>
              )
            })}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const styles = {
  wrap: [
    MainStyle.full_h, MainStyle.full_w, MainStyle.bc_modalBg, MainStyle.fix_gradient_and_modal_error_in_landscape,
    MainStyle.flex, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.p_y_70
  ],

  content_l: [
    MainStyle.w_560, MainStyle.max_h_770, MainStyle.b_r_4, MainStyle.b_w_2, MainStyle.b_c_popupBorder,
    MainStyle.bc_darkblue, MainStyle.relative
  ],
  content_s: [
    MainStyle.w_240, MainStyle.max_h_385, MainStyle.b_r_4, MainStyle.b_w_1, MainStyle.b_c_popupBorder,
    MainStyle.bc_darkblue, MainStyle.relative
  ],

  item_l: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.l_h_72],
  item_s: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.l_h_36],

  country_l: [MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.l_h_72],
  country_s: [MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.l_h_36],

  icon_l: [MainStyle.absolute, MainStyle.t__68, MainStyle.r__68, outline_0],
  icon_s: [MainStyle.absolute, MainStyle.t__28, MainStyle.r__28, outline_0],

  img_l: [MainStyle.w_68, MainStyle.h_68],
  img_s: [MainStyle.w_28, MainStyle.h_28],

  active: [MainStyle.bc_popupDropBg, MainStyle.c_lightblue]
};

export default React.memo(SelectModal);
