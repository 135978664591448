import React from 'react';
import {Pressable} from "react-native";
import MainStyle from "../../MainStyle";
import {Ionicons} from '@expo/vector-icons';
import {colors} from "../assets/styles/colors";

type Props = { value: boolean, onValueChange?: () => void, size: number };

const Checkbox = (props: Props) => {
  const {value, size} = props;

  return (
    <Pressable style={MainStyle.relative} onPress={props.onValueChange}>
      <Ionicons name="square-outline" size={size} color="white"/>
      {value && (
        <Ionicons name="checkmark" style={[MainStyle.absolute, MainStyle.b_1]} size={size + 3}
                  color={colors.newGreenText}/>
      )}
    </Pressable>
  );
};

export default React.memo(Checkbox);
