import React from "react";
import {Modal, ScrollView, ActivityIndicator} from "react-native";
import {Div, Text} from "react-native-magnus";
import {colors} from "../assets/styles/colors";
import MainStyle from "../../MainStyle";

type Props = { visible: boolean};

const PosTerminalLoading = (props: Props) => {
  const {visible} = props;

  return (
    <Modal animationType="fade" transparent visible={visible} statusBarTranslucent={false} hardwareAccelerated={false}>
      <Div h="100%" w="100%" bg={colors.darkOverlay} style={MainStyle.fix_gradient_and_modal_error_in_landscape}>
        <ScrollView contentContainerStyle={[MainStyle.full_h, MainStyle.full_w]}>
          <Div minH="100%" justifyContent="center" alignItems="center" px={20} py={20}>
            <Div position="relative" maxW={500} w="100%">
              <Div w="100%" bg={colors.bottomTabBg} borderWidth={1} borderColor={colors.popupBorder} rounded={4}
                   overflow="hidden" pt={28} px={23} pb={41} alignItems="center">
                <Text fontWeight="500" fontSize={25} color={colors.whiteText} textAlign="center">
                  Follow the instructions
                </Text>

                <Text fontWeight="500" fontSize={25} mb={20} color={colors.whiteText} textAlign="center">
                  shown on the terminal
                </Text>

                <ActivityIndicator color="white" size="large"/>

                <Div h={20}/>

                <Text fontWeight="400" fontSize={25} lineHeight={42} color={colors.whiteText} textAlign="center">
                  Status: WAITING
                </Text>
              </Div>
            </Div>
          </Div>
        </ScrollView>
      </Div>
    </Modal>
  );
};

export default React.memo(PosTerminalLoading);
