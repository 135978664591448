import React, {useEffect, useState} from "react";
import {Image, Modal, Pressable, View, Text} from "react-native";
import icon from "../assets/img/circle-close-icon.png";
import MainStyle from "../../MainStyle";
import Lottie from "lottie-react";
import tutorial_1_1_2_3 from "../assets/lottie/tutorial_1-1-2-3.json";
import tutorial_2_1 from "../assets/lottie/tutorial_2-1.json";
import tutorial_2_2 from "../assets/lottie/tutorial_2-2.json";
import tutorial_3_1 from "../assets/lottie/tutorial_3-1.json";
import tutorial_3_2 from "../assets/lottie/tutorial_3-2.json";
import tutorial_3_3 from "../assets/lottie/tutorial_3-3.json";
import tutorial_4_1 from "../assets/lottie/tutorial_4-1.json";
import tutorial_4_2 from "../assets/lottie/tutorial_4-2.json";
import tutorial_4_3 from "../assets/lottie/tutorial_4-3.json";
import Logo_SMG from "../assets/img/logo_RGB_3rows_shadow.png";
import FadeView from "../components/FadeView";

const register = [
  {header: 'Register', data: tutorial_1_1_2_3, other: 'We’ll start you off with 10 Free Shots'}
];

const enterContest = [
  {header: 'Enter a Challenge', data: tutorial_2_1, other: 'Choose your Distance, Win Circle, and Pay Table'},
  {header: 'Enter a Challenge', data: tutorial_2_2, other: 'Select the “Enter Challenge” button and Confirm Challenge Entry'}
];

const takeShot = [
  {header: 'Take a Shot', data: tutorial_3_1, other: 'You’ll see your name on the screen with your challenge active'},
  {header: 'Take a Shot', data: tutorial_3_2, other: 'Choose your club and place a ball in the hitting area'},
  {header: 'Take a Shot', data: tutorial_3_3, other: 'Swing away!'}
];

const getPaid = [
  {header: 'Get Paid!', data: tutorial_4_1, other: "If your ball lands within the Win Circle, you'll win"},
  {header: 'Get Paid!', data: tutorial_4_2, other: "The closer you get, the more you'll win!"},
  {header: 'Get Paid!', data: tutorial_4_3, other: 'Use the Web App to deposit or withdraw money easily'}
];

type Props = { visible: boolean, onResume: () => void, isLg: boolean, logged: boolean };

const TutorialModal = (props: Props) => {
  const {visible, isLg, logged} = props;
  const [animationIndex, setAnimationIndex] = useState(0);
  const animations = [...(logged ? [] : register), ...enterContest, ...takeShot, ...getPaid];
  const [showPreview, setShowPreview] = useState(true);

  const onLoopComplete = () => {
    if (animationIndex + 1 === animations.length) props.onResume();
    else setAnimationIndex(prevState => prevState + 1);
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setShowPreview(false);
      }, 2000);

    } else {
      setShowPreview(true);
      setAnimationIndex(0);
    }
  }, [visible]);

  return (
    <Modal animationType="fade" transparent visible={visible} statusBarTranslucent={false} hardwareAccelerated={false}
           onRequestClose={() => props.onResume()}>
      <View style={styles.wrap}>
        <View style={isLg ? styles.content_l : styles.content_s}>
          <Pressable style={isLg ? styles.icon_l : styles.icon_s} onPress={() => props.onResume()}>
            <Image source={icon} style={isLg ? styles.img_l : styles.img_s}/>
          </Pressable>

          {showPreview ? (
            <FadeView duration={300} showDuration={1400} style={[isLg ? MainStyle.h_700 : MainStyle.h_300, MainStyle.a_i_c, MainStyle.j_c_c]}>
              <Text style={isLg ? styles.header_l : styles.header_s}>Here’s how to play</Text>
              <Image source={Logo_SMG} style={isLg ? styles.logo_l : styles.logo_s}/>
            </FadeView>
          ) : (
            <>
              <Text style={isLg ? styles.header_l : styles.header_s}>
                {animations[animationIndex].header}
              </Text>

              <Lottie
                animationData={animations[animationIndex].data}
                style={{width: '100%', height: '100%'}}
                onLoopComplete={onLoopComplete}
                loop
              />
            </>
          )}

          <Text style={isLg ? styles.other_l : styles.other_s}>
            {showPreview ? '' : animations[animationIndex].other}
          </Text>
        </View>
      </View>
    </Modal>
  );
};

const styles = {
  wrap: [
    MainStyle.full_h, MainStyle.full_w, MainStyle.bc_modalBg, MainStyle.fix_gradient_and_modal_error_in_landscape,
    MainStyle.flex, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.p_y_70
  ],

  content_l: [
    MainStyle.w_560, MainStyle.max_h_770, MainStyle.b_r_4, MainStyle.b_w_2, MainStyle.b_c_popupBorder,
    MainStyle.bc_darkblue, MainStyle.relative, MainStyle.p_y_26
  ],
  content_s: [
    MainStyle.w_240, MainStyle.max_h_385, MainStyle.b_r_4, MainStyle.b_w_1, MainStyle.b_c_popupBorder,
    MainStyle.bc_darkblue, MainStyle.relative, MainStyle.p_y_10
  ],

  icon_l: [MainStyle.absolute, MainStyle.t__68, MainStyle.r__68],
  icon_s: [MainStyle.absolute, MainStyle.t__28, MainStyle.r__28],

  img_l: [MainStyle.w_68, MainStyle.h_68],
  img_s: [MainStyle.w_28, MainStyle.h_28],

  header_l: [MainStyle.c_lightblue, MainStyle.t_a_c, MainStyle.Barlow700, MainStyle.f_s_56],
  header_s: [MainStyle.c_lightblue, MainStyle.t_a_c, MainStyle.Barlow600, MainStyle.f_s_20],

  other_l: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.m_x_8],
  other_s: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow400, MainStyle.f_s_16, MainStyle.m_x_8],

  logo_l: [MainStyle.w_300, MainStyle.h_240, MainStyle.rm_contain],
  logo_s: [MainStyle.full_w, MainStyle.h_176, MainStyle.rm_center]
};

export default React.memo(TutorialModal);
