import React from 'react';
import Input from '../../../../../../components/Input';
import PhoneInput from "../../../../../../components/PhoneInput";
import Handedness from "../../../../../../components/Handedness";
import {FormikProps} from "formik";
import {registerUserPayloadType} from "../../../../../../types";
import DateTimePicker from "../../../../../../components/DateTimePicker/DateTimePicker";

const getFieldError = (key: string, formik: any): boolean | string => {
  return formik.touched[key] && formik.errors[key];
};

type EmailProps = { formik: FormikProps<registerUserPayloadType> };
export const Email = (props: EmailProps) => {
  const {formik} = props;

  return (
    <Input
      placeholder="Enter Text Here" type="email-address" label="EMAIL ADDRESS *" value={formik.values.email}
      onChangeText={formik.handleChange('email')} onBlur={formik.handleBlur('email')}
      error={getFieldError('email', formik)} onFocusScroll
    />
  );
};

type PhoneProps = { formik: FormikProps<registerUserPayloadType> };
export const Phone = (props: PhoneProps) => {
  const {formik} = props;

  return (
    <PhoneInput
      w="100%"
      placeholder="Phone Number"
      label="PHONE NUMBER - MOBILE *"
      value={formik.values.phone}
      onChangeText={formik.handleChange('phone')}
      onBlur={formik.handleBlur('phone')}
      error={getFieldError('phone', formik)}
    />
  );
};

type NameProps = { formik: FormikProps<registerUserPayloadType> };
export const Name = (props: NameProps) => {
  const {formik} = props;

  return (
    <>
      <Input
        placeholder="Enter Text Here" label="PLAYER NAME (FOR SCREEN)"
        value={formik.values.username} onChangeText={formik.handleChange('username')}
        onBlur={formik.handleBlur('username')} error={getFieldError('username', formik)}
        onFocusScroll mb={12} textAlign="center"
      />

      <Input
        placeholder="Enter Text Here" label="FIRST NAME"
        value={formik.values.firstname} onChangeText={formik.handleChange('firstname')}
        onBlur={formik.handleBlur('firstname')} error={getFieldError('firstname', formik)}
        onFocusScroll mb={12} textAlign="center"
      />

      <Input
        placeholder="Enter Text Here" label="LAST NAME"
        value={formik.values.lastname} onChangeText={formik.handleChange('lastname')}
        onBlur={formik.handleBlur('lastname')} error={getFieldError('lastname', formik)}
        onFocusScroll mb={12} textAlign="center"
      />

      <DateTimePicker formik={formik} mb="12px" label="DATE OF BIRTH"/>

      <Handedness
        error={getFieldError('handedness', formik)} value={formik.values.handedness}
        onChange={val => formik.setFieldValue('handedness', val)} mb={12}
      />
    </>
  );
};
