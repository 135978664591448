import {StyleSheet} from 'react-native';
import {colors} from "../../assets/styles/colors";

const styles = StyleSheet.create({
  profile_header_logo_s: {width: 32, height: 28,marginRight:5},
  profile_header_logo_m: {width: 66, height: 58},
  profile_header_logo_l: {width: 93, height: 82},

  profile_header_img_s: {width: 48, height: 48, borderRadius: 28},
  profile_header_img_l: {width: 96, height: 96, borderRadius: 50},

  profile_header_balance_l: {fontFamily: 'Barlow-Bold', fontSize: 40},

  profile_header_username_s: {
    fontWeight: '900', fontSize: 14, lineHeight: 24, color: colors.whiteText, paddingHorizontal: 23, marginBottom: 7,
    textAlign: 'center', textTransform: 'uppercase'
  },
  profile_header_username_l: {
    fontFamily: 'Roboto_900Black', fontSize: 32, color: colors.whiteText, flex: 1, textTransform: 'uppercase',
    lineHeight: 82
  },

  profile_header_s: {flexDirection: 'row', marginTop: 18, paddingHorizontal: 23, width: '100%'},
  profile_header_m: {
    flexDirection: 'row', marginTop: 20, width: '100%', maxWidth: 768, height: 100, paddingLeft: 50, paddingRight: 120
  },
  profile_header_l: {
    flexDirection: 'row', marginTop: 32, paddingHorizontal: 23, maxWidth: 1166, width: '100%', alignItems: 'center',
    marginBottom: 64
  }
});

export default styles;
