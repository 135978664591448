import React, {useContext, useLayoutEffect} from 'react';
import {Platform, StatusBar, useWindowDimensions} from 'react-native';
import {NavigationContainer, Theme} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {SplashScreen} from '../screens/Common/Splash';
import LoginWithPin from "../screens/Auth/SignIn/LoginWithPin";
import CancelModal from "../screens/Auth/SignUp/steps/CancelModal";
import {StoreContext} from "./StoreContextProvider";
import Loader from "./Loader";
import BetSlip from "../screens/User/BetSlip";
import Account from "../screens/User/Account";
import * as NavigationBar from 'expo-navigation-bar';
import * as ScreenOrientation from 'expo-screen-orientation';
import SuccessModal from "../modals/SuccessModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContestEntryModal from '../modals/ContestEntryModal';
import moment from 'moment';
import HelpLinksModal from '../modals/HelpLinksModal';
import ResponsibleGaming from '../screens/Common/Responsible-Gaming';
import TransactionHistoryScreen from "../screens/TransactionHistoryScreen";
import EmailVerification from '../screens/Common/EmailVerification/Index';
import TermsAndConditionsScreen from "../screens/TermsAndConditionsScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import PromotionsScreen from "../screens/PromotionsScreen";
import SelfExclusionScreen from "../screens/SelfExclusionScreen";
import NotificationsScreen from "../screens/NotificationsScreen";
import LocationSettings from "../screens/LocationSettings";
import PromosScreen from '../screens/User/promos';
import TournamentsListingScreen from '../screens/Tournaments/TournamentsListingScreen';
import TournamentDetails from "../screens/TournamentDetails";
import SignUpScreen from "../screens/Auth/SignUp";

const Stack = createNativeStackNavigator();

const DarkTheme: Theme = {
  dark: true,
  colors: {
    primary: 'rgb(10, 132, 255)',
    background: 'transparent',
    card: 'rgb(18, 18, 18)',
    text: 'rgb(229, 229, 231)',
    border: 'rgb(39, 39, 41)',
    notification: 'rgb(255, 69, 58)'
  }
};

const config = {
  screens: {
    challenges: 'challenges', account: 'account', promos: 'promos', Splash: 'splash', Register: 'register',
    LoginWithPin: 'login-with-pin', ResponsibleGaming: 'responsiblegaming', TransactionHistory: 'transaction-history',
    EmailVerification: 'confirm-email', TermsAndConditions: 'termsandconditions', PrivacyPolicy: 'privacy',
    Promotions: 'promotions', SelfExclusion: 'self-exclusion', Notifications: 'notifications',
    LocationSettings: 'location-settings', tournaments: 'tournaments', TournamentDetails: 'tournament'
  }
};

const linking = {prefixes: [], config};

export const Main = () => {
  const Context = useContext(StoreContext);
  const {loadingOverlay} = Context;
  const {width} = useWindowDimensions();

  useLayoutEffect(() => {
    if (Platform.OS === 'web') {
      document.addEventListener('click', () => {
        AsyncStorage.setItem('lastActiveTime', moment().format()).then();
      }, true);

      window.addEventListener('online', () => Context.setOnLine(true));
      window.addEventListener('offline', () => Context.setOnLine(false));

    } else {
      NavigationBar.setButtonStyleAsync('light').then();
      NavigationBar.setBackgroundColorAsync('black').then();
      if (width < 768) ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT).then();
    }
  }, []);

  return (
    <>
      <StatusBar backgroundColor="black"/>

      <NavigationContainer theme={DarkTheme} linking={linking}>
        <Stack.Navigator screenOptions={{headerShown: false, title: 'Skill Money Games'}}>
          <Stack.Screen name="challenges" component={BetSlip}/>
          <Stack.Screen name="account" component={Account}/>
          <Stack.Screen name="promos" component={PromosScreen}/>
          <Stack.Screen name="Splash" component={SplashScreen}/>
          <Stack.Screen name="Register" component={SignUpScreen}/>
          <Stack.Screen name="LoginWithPin" component={LoginWithPin}/>
          <Stack.Screen name="ResponsibleGaming" component={ResponsibleGaming}/>
          <Stack.Screen name="TransactionHistory" component={TransactionHistoryScreen}/>
          <Stack.Screen name="EmailVerification" component={EmailVerification}/>
          <Stack.Screen name="TermsAndConditions" component={TermsAndConditionsScreen}/>
          <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen}/>
          <Stack.Screen name="Promotions" component={PromotionsScreen}/>
          <Stack.Screen name="SelfExclusion" component={SelfExclusionScreen}/>
          <Stack.Screen name="Notifications" component={NotificationsScreen}/>
          <Stack.Screen name="LocationSettings" component={LocationSettings}/>
          <Stack.Screen name="tournaments" component={TournamentsListingScreen}/>
          <Stack.Screen name="TournamentDetails" component={TournamentDetails}/>
        </Stack.Navigator>

        <HelpLinksModal/>
      </NavigationContainer>

      <SuccessModal/>
      <CancelModal/>
      <ContestEntryModal/>
      <Loader visible={loadingOverlay}/>
    </>
  );
};
