import React, {useContext} from "react";
import {Linking, Platform, Text, View, useWindowDimensions, ScrollView} from "react-native";
import {useNavigation} from "@react-navigation/native";
import MainStyle from "../../MainStyle";
import {StoreContext} from "./StoreContextProvider";
import {breakpoint, SkillMoneyLanding} from "../constants";

type Props = { bg?: string, height?: number, px?: number, size?: 'small' };

const TermsAndConditionsContent = ({bg, height, px, size}: Props) => {
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {user} = Context;
  const {width} = useWindowDimensions();
  const isSm = size === 'small' ? true : width < breakpoint;

  const renderResponsibleGamingLink = () => {
    if (user?.userId) return 'https://www.skillmoney.com/responsiblegaming';
    else {
      return (
        <Text style={styles.small_link} onPress={() => navigation.navigate('ResponsibleGaming')}>
          https://www.skillmoney.com/responsiblegaming
        </Text>
      );
    }
  };

  const openLink = (url: string) => {
    if (Platform.OS === 'web') window.open(url, '_blank');
    else Linking.openURL(url).then();
  };

  return (
    <ScrollView contentContainerStyle={[MainStyle.min_h_full, {backgroundColor: bg, height, paddingHorizontal: px}]}
                keyboardShouldPersistTaps="handled">
      <View style={[MainStyle.max_w_1120, MainStyle.m_x_auto]}>
        <Text style={isSm ? styles.title_s : styles.title_l}>
          TERMS AND CONDITIONS FOR THE USE OF THE SUPER MONEY GAMES WEBSITE, WWW.SKILLMONEY.COM AND RELATED GOODS AND
          SERVICES
        </Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          1. THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS
          CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY.
        </Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS
          OR CLASS ACTIONS.
        </Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          BY USING THE SUPER MONEY GAMES WEBSITE, ONLINE, IN PERSON KIOSKS OR OTHERWISE, YOU AFFIRM THAT YOU ARE OF
          LEGAL AGE TO ENTER INTO THIS AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS AND CONDITIONS. YOUR USE
          OF SUPER MONEY GAMES WEBSITE INDICATES YOUR AFFIRMATION THAT YOU HAVE REVIEWED THESE TERMS AND CONDITIONS.
        </Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          YOU MAY NOT USE SUPER MONEY GAMES WEBSITE, APP OR KIOSKS IF YOU (A) DO NOT AGREE TO THESE TERMS, (B) ARE NOT
          THE OLDER OF (i) AT LEAST 18 YEARS OF AGE OR (ii) LEGAL AGE TO FORM A BINDING CONTRACT WITH SUPER MONEY GAMES,
          OR (C) ARE PROHIBITED FROM ACCESSING OR USING THIS WEBSITE/SERVICE OR ANY OF THIS WEBSITE'S AND/OR SERVICE’S
          CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          These terms and conditions (these "<Text style={MainStyle.Barlow700}>Terms</Text>") apply to the purchase
          and sale of services through SUPER MONEY GAMES (<Text style={styles.small_link}
                                                                onPress={() => openLink(SkillMoneyLanding)}>WWW.SKILLMONEY.COM</Text>),
          APPs associated with services provides by SUPER MONEY GAMES and/or KIOSKS associated with SUPER MONEY GAMES
          and its dba, Skillmoney.com (the "<Text style={MainStyle.Barlow700}>Services</Text>"). These Terms are
          subject to change by SUPER MONEY GAMES (referred to as "<Text style={MainStyle.Barlow700}>us</Text>",
          "<Text style={MainStyle.Barlow700}>we</Text>", or "<Text style={MainStyle.Barlow700}>our</Text>" as
          the context may require) without prior written notice at any time, in our sole discretion. Any changes to the
          Terms will be in effect as of the "Last Updated Date" referenced on the Site. You should review these Terms
          prior to purchasing any product or services that are available through this Site. Your continued use of this
          Site after the "Last Updated Date" will constitute your acceptance of and agreement to such changes.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          These Terms are an integral part of the Website Terms of Use that apply generally to the use of our Site. You
          should also carefully review our Privacy Policy before using the services provided by SUPER MONEY GAMES,
          placing an order for products or services (see Section 8). The Terms herein are valid only for so long as you
          are accessing the Services. Once you sign up and create an account you will be directed to a particular Bank.
          Your account and rights will be governed by the Bank’s terms and conditions and you should consult the Bank
          for a description of your rights.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          If any deposit is charged back any winnings generated from SUPER MONEY GAMES challenges shall be invalidated,
          forfeited and deducted from your Account balance. In addition, the amount of the initial deposit will be
          invalidated, forfeited and deducted from the Account balance. SUPER MONEY GAMES reserves the right to close
          your account - without notice - shall a deposit be charged back.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          SUPER MONEY GAMES employees ("Employees") and Immediate Family Members (an "Immediate Family Member" means any
          domestic partner and relative of the employee who resident at an employee's residence, including but not
          limited to parents, grandparents, in-laws, children, siblings, and spouses) are not permitted to play in any
          public challenges for cash hosted on the Website; provided, however, Employees and Immediate Family Members
          are permitted to play in the following challenges on the Website: SUPER MONEY GAMES-sponsored Private
          Challenge, Private Challenges for cash, or Private Challenges for prizes other than cash. A "Private
          Challenge" is one that is not listed in the website public domain, and is hosted by another Employee,
          relative, or friend of the Employee or Immediate Family Member participating. Employees and relatives living
          in the same household as such employees located in Colorado, are prevented from competing in any fantasy
          challenges offered by any fantasy challenge operator in which the operator offers a cash prize.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Professional or amateur athletes, sports agents, coaches, team owners, team employees, referees or league
          officials, and their immediate family members, are not eligible to participate in, and are strictly prohibited
          from entering, any SUPER MONEY GAMES challenges in the sport in which they are associated.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          You may establish only one account per person to participate in the Services offered on the Website. In the
          event SUPER MONEY GAMES discovers that you have opened more than one account per person, in addition to any
          other rights that SUPER MONEY GAMES may have, SUPER MONEY GAMES reserves the right to suspend or terminate any
          or all of your accounts and terminate, withhold or revoke the awarding of any prizes. You are responsible for
          maintaining the confidentiality of your login names and passwords and you accept responsibility for all
          activities, charges, and damages that occur under your account. It shall be a violation of these Terms of Use
          to allow any other person to use your account to participate in any challenge. You are responsible for
          ensuring you use strong and secure passwords, and you may be required to change your passwords from time to
          time. If you have reason to believe that someone is using your account without your permission, you should
          contact us immediately. We will not be responsible for any loss or damage resulting from your failure to
          notify us of unauthorized use. If we request registration information from you, you must provide us with
          accurate and complete information and must update the information when it changes.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>2. Order Acceptance and Cancellation.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          You agree that your order is an offer to buy, under these Terms, all products and services listed in your
          order. All orders must be accepted by us or we will not be obligated to sell the products or services to you.
          We may choose not to accept orders at our sole discretion, even after we send you a confirmation email with
          your order number and details of the items you have ordered.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>3. CHALLENGE ENTRY.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Users will be able to visit the Website, app or Kiosk and view the games available for entry (the
          "Challenges"). The entry fee for each Challenge is displayed in the Lobby of the Website and on the respective
          Challenge page, and each individual Challenge that is not free to enter has an entry fee listed in US dollars.
          The minimum entry fee for Challenges that are not free to play is one cent ($0.01), and there is no maximum
          entry fee for Challenges. When you select to participate in a Challenge and complete the entry process, the
          listed amount of US dollars will be debited from your SUPER MONEY GAMES account. The maximum number entries an
          Authorized Account Holder may submit for a particular Challenge is listed in the "Challenge Details" tab of
          each Challenge.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Information about responsible gaming, including self-exclusion and self-limitation options, is available
          at: {renderResponsibleGamingLink()}. If you self-exclude, you will be removed from any upcoming challenges as
          set forth in the self-exclusion procedures available at: <Text style={styles.small_link}
                                                                         onPress={() => navigation.navigate('SelfExclusion')}>www.skillmoney.com/self-exclusion</Text>,
          and any entry fees for such upcoming challenges you are removed from will be refunded to you.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>4. REFUND POLICY.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          SUPER MONEY GAMES accepts all major debit cards, and, depending on the jurisdiction you are in, SUPER MONEY
          GAMES may also accept major credit cards (including Visa, MasterCard, Discover, and American Express), Pay
          Pal, and gift cards (including SUPER MONEY GAMES specific cards, Visa, MasterCard, and American Express).
          While physically located in the State of Georgia, customers may only link one credit or debit card to their
          account. Deposits made on our site will appear on your statement as SUPER MONEY GAMES. All payments are final.
          No refunds will be issued. In the event of a dispute regarding the identity of the person submitting an entry,
          the entry will be deemed submitted by the person in whose name the account was registered.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Customers depositing funds using a credit card in some states may see a charge on their credit card statement
          labeled "international transaction fee" or similar description. The company processing credit card payments
          for SUPER MONEY GAMES in certain states may operate outside the United States and may impose fees for
          international transactions.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          If you are charged this international transaction fee to deposit funds into your SUPER MONEY GAMES account,
          you may contact SUPER MONEY GAMES at <Text style={styles.small_link}
                                                     onPress={() => openLink('mailto:support@skillmoney.com')}>support@SUPERMONEYGAMES.com</Text> and
          request a full reimbursement of the international transaction fee. To process your reimbursement request,
          SUPER MONEY GAMES will need the following information: (i) original transaction date and amount deposited;
          (ii) the amount(s) of the fee(s) assessed on such deposited amount; (iii) date(s) such fee(s) was/were
          assessed; and (iv) a copy of your credit card statement showing such fee(s).
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          SUPER MONEY GAMES reserves the right to request additional information to process your request. SUPER MONEY
          GAMES will review your submission and if SUPER MONEY GAMES determines that you have been charged the
          international transaction fee as a result of depositing funds into your SUPER MONEY GAMES account, SUPER MONEY
          GAMES will credit such amount back to your Account.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Accounts may be deemed dormant or inactive, and any remaining funds held in a dormant or inactive account will
          be treated, in each case, in accordance with applicable state laws and regulations.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Players can obtain a copy of their Account statements by emailing customer service at <Text
          style={styles.small_link}
          onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>5. CONDITIONS OF PARTICIPATION.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          By entering a Challenge, entrants agree to be bound by these Rules and the decisions of SUPER MONEY GAMES,
          which shall be final and binding in all respects. The Company, at its sole discretion, may disqualify any
          entrant from a Challenge, refuse to award benefits or prizes and require the return of any prizes, if the
          entrant engages in conduct or otherwise utilizes any information the Company deems to be improper, unfair or
          otherwise adverse to the operation of the Challenge or is in any way detrimental to other entrants. These
          Terms prohibit entering a Challenge if the entrant is:
        </Text>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            Except as otherwise stated in the Eligibility section above, an Employee of the Company or an immediate
            family member of such employee;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            An employee or operator of any daily fantasy site including any that charges entrance fees or offers prizes,
            and any Immediate Family Member of any such person;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            Accessing or has had access to any pre-release, confidential information or other information that is not
            available to all other entrants of a Challenge and that provides the entrant an advantage in such a
            Challenge, including any information from any daily fantasy sport site or information from a sports
            governing body (e.g., pre-release injury information) ("Pre-Release Data");
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            An employee of a sponsor, consultant, or supplier of the Company or any other daily fantasy sports challenge
            provider that has access to Pre-Release Data or otherwise receives an advantage in the entrant's
            participation in a Challenge;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            An employee, operator or consultant to a sports governing body where such employee, operator or consultant
            is prohibited from participating in applicable Challenges by such governing body;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            Breaches any rules or policies of the entrant's employer, of any sports governing body, or any other
            professional body of which the entrant is a member, including any rule or policy regarding participation in
            Challenges or accepting prize money;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            Any person prohibited from participating pursuant to court order;
          </Text>
        </View>

        <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            Any entrant who has knowingly received Pre-Release Data or any other non-public information that provides an
            advantage in a Challenge from any person who is prohibited from entering a Challenge as provided in these
            Terms.
          </Text>
        </View>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          In addition, conduct that would be deemed improper also includes, but is not limited to:
        </Text>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Falsifying personal information required to enter a Challenge or claim a prize;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Engaging in any type of financial fraud including unauthorized use of credit instruments to enter a
            Challenge or claim a prize;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Colluding with any other individual(s) or engaging in any type of syndicate play;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Any violation of Challenge rules or the Terms of Use;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Using a single Account to participate in a Challenge on behalf of multiple entrants or otherwise
            collaborating with others to participate in any Challenge;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Using automated means (including but not limited to scripts and third-party tools) to interact with the
            Website in any way (this includes, but is not limited to: creating a challenge, entering a challenge,
            withdrawing from a challenge, creating a lineup, and editing a lineup);
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Using automated means (including but not limited to harvesting bots, robots, parser, spiders or screen
            scrapers) to obtain, collect or access any information on the Website or of any User for any purpose.
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Any type of bonus abuse, abuse of the refer-a-friend program, or abuse of any other offers or promotions;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Tampering with the administration of a Challenge or trying to in any way tamper with the computer programs
            or any security measure associated with a Challenge;
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Obtaining other entrants information and spamming other entrants; or
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Abusing the Website, APP, Kiosk or other interface in any way.
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            Users further acknowledge that the forfeiture and/or return of any prize shall in no way prevent SUPER MONEY
            GAMES from pursuing criminal or civil proceedings in connection with such conduct.
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            By entering into a Challenge or accepting any prize, entrants, including but not limited to the winner(s),
            agree to indemnify, release and to hold harmless SUPER MONEY GAMES, its parents, subsidiaries, affiliates
            and agents, as well as the officers, directors, employees, shareholders and representatives of any of the
            foregoing entities (collectively, the "Released Parties"), from any and all liability, claims or actions of
            any kind whatsoever, including but not limited to injuries, damages, or losses to persons and property which
            may be sustained in connection with participation in the Challenge, the receipt, ownership, use or misuse of
            any prize or while preparing for, participating in and/or travelling to or from any prize related activity,
            as well as any claims based on publicity rights, defamation, or invasion of privacy. SUPER MONEY GAMES may,
            in its sole and absolute discretion, require an Authorized Account Holder to execute a separate release of
            claims similar to the one listed above in this Paragraph as a condition of being awarded any prize or
            receiving any payout.
          </Text>
        </View>

        <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            SUPER MONEY GAMES is not responsible for: any incorrect, invalid or inaccurate entry information; human
            errors; postal delays/postage due mail; technical malfunctions; failures, including public utility or
            telephone outages; omissions, interruptions, deletions or defects of any telephone system or network,
            computer online systems, data, computer equipment, servers, providers, or software (including, but not
            limited to software and operating systems that do not permit an entrant to participate in a Challenge),
            including without limitation any injury or damage to any entrant's or any other person's computer or video
            equipment relating to or resulting from participation in a Challenge; inability to access the Website, or
            any web pages that are part of or related to the Website; theft, tampering, destruction, or unauthorized
            access to, or alteration of, entries and/or images of any kind; data that is processed late or incorrectly
            or is incomplete or lost due to telephone, postal issues, computer or electronic malfunction or traffic
            congestion on telephone lines or transmission systems, or the Internet, or any service provider's
            facilities, or any phone site or website or for any other reason whatsoever; typographical, printing or
            other errors, or any combination thereof.
          </Text>
        </View>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          <Text style={MainStyle.t_d_l_u}>CHALLENGE PRIZES AND PROMOTIONS.</Text> Challenges offered are challenges of
          skill. Winners are determined by the objective criteria described in the Challenge deadline, roster, Rules,
          scoring, and any other applicable documentation associated with the Challenge. From all entries received for
          each Challenge, winners are determined by the individuals who use their skill and knowledge of relevant sports
          information and fantasy sports rules to accumulate the most points according to the corresponding scoring
          rules. The Website and Challenges may not be used for any form of illicit gambling.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Guaranteed prizes are offered in connection with some of the Challenges offered. Each Challenge or promotion
          is governed by its own set of official rules. We encourage you to read such Challenge and promotions Rules
          before participating.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          <Text style={MainStyle.t_d_l_u}>TOURNAMENTS.</Text> Skill Money Games offers Tournaments wherein players have
          the opportunity to win all or part of a Prize Pool. The details for the Tournaments are in the Tournament
          Overview, and include Timing, Entry Fee (or sponsored) and Payouts. Tournaments Entries can be entered with
          Withdrawable Funds (not Free Play) or the Prize Pool can be funded by a Third Party.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          <Text style={MainStyle.t_d_l_u}>New Player Promotion.</Text> New players receive “10 Free Shots” which are the
          equivalent of 10 Challenges with a $3 Challenge Fee, without deposit required. The results of each challenge
          during the “10 Free Shots” will be accumulated into Free Play with a 1x Turnover requirement. In the case that
          a New Player does not earn at least $10, the New Player will receive $10 in Free Play after the completion of
          their 10th Challenge
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>6. Manufacturer's Warranty and Disclaimers.</Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          ALL PRODUCTS AND SERVICES OFFERED ON THIS SITE ARE PROVIDED “AS IS” WITHOUT ANY WARRANTY WHATSOEVER,
          INCLUDING, WITHOUT LIMITATION, ANY (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR
          PURPOSE; OR (C) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER
          EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.
        </Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER
          MAY NOT APPLY TO YOU.
        </Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          YOU AFFIRM THAT WE SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES, FOR ANY BREACH OF WARRANTY CLAIMS OR FOR ANY
          DAMAGES ARISING OUT OF THE MANUFACTURER'S FAILURE TO HONOR ITS WARRANTY OBLIGATIONS TO YOU.
        </Text>

        <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_t_u]}>
          By accessing and using the Company’s Services, regardless of through which Platform, you acknowledge and agree
          that the owner and/or operator of any venue or facility containing or operating physical facilities or
          equipment used by you in connection with your use of the Services and/or Platform (each a “Facility Owner
          Operator”) is not liable for any funds in your Account, any payment due to you by the Company and/or any other
          loss incurred by you in connection with your access to, and use of the Services through any Platform; and in
          furtherance of the foregoing, you agree and acknowledge that each such Facility Owner Operator and its
          respective affiliates are for all purposes hereby included in the definition of Released Parties (as defined
          in Section 4 below and as used in these Terms.)
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>7. Limitation of Liability.</Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE, ARISING OUT OF, OR
          RELATING TO, AND/OR IN CONNECTION WITH ANY BREACH OF THESE TERMS, REGARDLESS OF (A) WHETHER SUCH DAMAGES WERE
          FORESEEABLE, (B) WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND (C) THE LEGAL OR
          EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED.
        </Text>

        <Text style={isSm ? styles.text_s : styles.text_l}>
          OUR SOLE AND ENTIRE MAXIMUM LIABILITY , FOR ANY REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE
          WHATSOEVER, SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU FOR THE PRODUCTS AND SERVICES YOU HAVE ORDERED
          THROUGH OUR SITE.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          The limitation of liability set forth above shall : (i) only apply to the extent permitted by law and (ii)/
          not apply to (A) liability resulting from our willful misconduct and (B) death or bodily injury resulting from
          our acts or omissions.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>8. INTELLECTUAL PROPERTY RIGHTS.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          The content on the Website, APP, Kiosks other any other location, including without limitation, the text,
          software, scripts, graphics, photos, sounds, music, videos, interactive features and the like and the
          trademarks, service marks and logos contained therein (the "Intellectual Property"), are owned by or licensed
          to SUPER MONEY GAMES, subject to copyright and other intellectual property rights under United States and
          foreign laws and international conventions. Content on the Website is provided to you AS IS for your
          information and personal use only and may not be used, copied, reproduced, distributed, transmitted,
          broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the
          prior written consent of the respective owners. SUPER MONEY GAMES reserves all rights not expressly granted in
          and to the Website and the Intellectual Property. You agree to not engage in the use, copying, or distribution
          of any of the Intellectual Property other than expressly permitted herein. If you download or print a copy of
          the Intellectual Property for personal use, you must retain all copyright and other proprietary notices
          contained therein. You agree not to circumvent, disable or otherwise interfere with security related features
          of the Website or features that prevent or restrict use or copying of any Intellectual Property or enforce
          limitations on use of the Website or the Intellectual Property therein.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Some of the Services may allow you to submit or transmit audio, video, text, or other materials (collectively,
          "User Submissions") to or through the Services. When you provide User Submissions, you grant to SUPER MONEY
          GAMES, its parents, subsidiaries, affiliates, and partners a non-exclusive, worldwide, royalty-free, fully
          sublicenseable license to use, distribute, edit, display, archive, publish, sublicense, perform, reproduce,
          make available, transmit, broadcast, sell, translate, and create derivative works of those User Submissions,
          in any form, media, software, or technology of any kind now known or developed in the future, including,
          without limitation, for developing, manufacturing, and marketing products. You hereby waive any moral rights
          you may have in your User Submissions.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          In addition, you agree that any User Submissions you submit shall not contain any material that is, in the
          sole and absolute discretion of SUPER MONEY GAMES, inappropriate, obscene, vulgar, unlawful, or otherwise
          objectionable (hereinafter, "Prohibited Content"). Posting of any Prohibited Content, in addition to any and
          all other rights and remedies available to SUPER MONEY GAMES, may result in account suspension or termination.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          We respect your ownership of User Submissions. If you owned a User Submission before providing it to us, you
          will continue owning it after providing it to us, subject to any rights granted in the Terms of Use and any
          access granted to others. If you delete a User Submission from the Services, our general license to that User
          Submission will end after a reasonable period of time required for the deletion to take full effect. However,
          the User Submission may still exist in our backup copies, which are not publicly available. If your User
          Submission is shared with third parties, those third parties may have retained copies of your User
          Submissions. In addition, if we made use of your User Submission before you deleted it, we will continue to
          have the right to make, duplicate, redistribute, and sublicense those pre-existing uses, even after you delete
          the User Submission. Terminating your account on a Service will not automatically delete your User
          Submissions.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          We may refuse or remove a User Submission without notice to you. However, we have no obligation to monitor
          User Submissions, and you agree that neither we nor our parents, subsidiaries, affiliates, employees, or
          agents will be liable for User Submissions or any loss or damage resulting from User Submissions.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          Except as provided in the Privacy Policy, we do not guarantee that User Submissions will be private, even if
          the User Submission is in a password-protected area. Accordingly, you should not provide User Submissions that
          you want protected from others.
        </Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          You represent and warrant that you have all rights necessary to grant to SUPER MONEY GAMES the license above
          and that none of your User Submissions are defamatory, violate any rights of third parties (including
          intellectual property rights or rights of publicity or privacy), or violate applicable law.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>9. Goods Not for Resale or Export.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          You represent and warrant that you are buying products or services from the Site for your own personal or
          household use only, and not for resale or export. You further represent and warrant that all purchases are
          intended for final delivery to locations within the US.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>10. Privacy.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          We respect your privacy and are committed to protecting it.] Our <Text
          style={MainStyle.Barlow400}>Privacy Policy</Text>, <Text style={styles.small_link}
                                                                                  onPress={() => navigation.navigate('PrivacyPolicy')}>http://www.skillmoney.com/privacy</Text>,
          governs the processing of all personal data collected from you in connection with your purchase of products or
          services through the Site.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>11. Force Majeure.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          We will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms, for any
          failure or delay in our performance under these Terms when and to the extent such failure or delay is caused
          by or results from acts or circumstances beyond our reasonable control, including, without limitation, acts of
          God, flood, fire, earthquake, explosion, governmental actions, war, invasion or hostilities (whether war is
          declared or not), terrorist threats or acts, riot or other civil unrest, national emergency, revolution,
          insurrection, epidemic, lockouts, strikes or other labor disputes (whether or not relating to our workforce),
          or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable
          materials, materials or telecommunication breakdown or power outage.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>12. Governing Law and Jurisdiction.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          This Site is operated from the US. All matters arising out of or relating to these Terms are governed by and
          construed in accordance with the internal laws of the State of Nevada without giving effect to any choice or
          conflict of law provision or rule (whether of the State of Nevada or any other jurisdiction) that would cause
          the application of the laws of any jurisdiction other than those of the State of Nevada.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>13. Dispute Resolution and Binding Arbitration.</Text>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
          <View style={MainStyle.flex1}>
            <Text style={styles[isSm ? 'text_s' : 'text_l']}>
              YOU AND SUPER MONEY GAMES ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A
              JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS
              THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
            </Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT
              OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND
              EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR
              SERVICES THROUGH THE SITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
            </Text>
          </View>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
          <View style={MainStyle.flex1}>
            <Text style={styles[isSm ? 'secondary_s' : 'secondary_l']}>
              The arbitration will be administered by the American Arbitration Association ("<Text
              style={MainStyle.Barlow700}>AAA</Text>") in accordance with the Consumer Arbitration Rules (the
              "<Text style={MainStyle.Barlow700}>AAA Rules</Text>") hen in effect, except as modified by this
              Section 12. (The AAA Rules are available at www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.)
              The Federal Arbitration Act will govern the interpretation and enforcement of this section.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
              The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability and/or
              enforceability of this arbitration provision, including any unconscionability challenge or any other
              challenge that the arbitration provision or the agreement is void, voidable, or otherwise invalid. The
              arbitrator will be empowered to grant whatever relief would be available in court under law or in equity.
              Any award of the arbitrator(s) will be final and binding on each of the parties and may be entered as a
              judgment in any court of competent jurisdiction.
            </Text>
          </View>
        </View>

        <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            You agree to an arbitration on an individual basis. In any dispute, <Text
            style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>NEITHER YOU NOR SUPER MONEY GAMES WILL BE
            ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR OTHERWISE
            PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL
            CAPACITY.</Text> The arbitral tribunal may not consolidate more than one person's claims, and may not
            otherwise preside over any form of a representative or class proceeding. The arbitral tribunal has no power
            to consider the enforceability of 10 this class arbitration waiver and any challenge to the class
            arbitration waiver may only be raised in a court of competent jurisdiction.
          </Text>
        </View>

        <Text style={styles[isSm ? 'secondary_s' : 'secondary_l']}>
          If any provision of this arbitration agreement is found unenforceable, the unenforceable provision will be
          severed and the remaining arbitration terms will be enforced.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>14. Assignment.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          You will not assign any of your rights or delegate any of your obligations under these Terms without our prior
          written consent. Any purported assignment or delegation in violation of this Section 131 is null and void. No
          assignment or delegation relieves you of any of your obligations under these Terms.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>15. No Waivers.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          The failure by us to enforce any right or provision of these Terms will not constitute a waiver of future
          enforcement of that right or provision. The waiver of any right or provision will be effective only if in
          writing and signed by a duly authorized representative of SUPER MONEY GAMES.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>16. No Third-Party Beneficiaries.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          These Terms do not and are not intended to confer any rights or remedies upon any person other than you.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>17. Notices.</Text>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            <Text style={MainStyle.t_d_l_u}>To You</Text>. We may provide any notice to you under these Terms by: (i)
            sending a message to the email address you provide or (ii) by posting to the Site. Notices sent by email
            will be effective when we send the email and notices we provide by posting will be effective upon posting.
            It is your responsibility to keep your email address current.
          </Text>
        </View>

        <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            <Text style={MainStyle.t_d_l_u}>To Us</Text>. To give us notice under these Terms, you must contact us as
            follows: (i) by personal delivery, overnight courier, or registered or certified mail to [Super Money Games,
            2575 S Highland Dr, Las Vegas, 89109]. We may update the facsimile number or address for notices to us by
            posting a notice on the Site. Notices provided by personal delivery will be effective immediately. Notices
            provided by overnight courier will be effective one business day after they are sent. Notices provided by
            registered or certified mail will be effective three business days after they are sent.
          </Text>
        </View>

        <Text style={isSm ? styles.title_s : styles.title_l}>18. Severability.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          If any provision of these Terms is invalid, illegal, void or unenforceable, then that provision will be deemed
          severed from these Terms and will not affect the validity or enforceability of the remaining provisions of
          these Terms.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>19. Entire Agreement.</Text>

        <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
          These Terms, our Website Terms of Use and our Privacy Policy will be deemed the final and integrated agreement
          between you and us on the matters contained in these Terms.
        </Text>

        <Text style={isSm ? styles.title_s : styles.title_l}>20. SMS.</Text>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            When you opt in to the service, we will send you a one-time passcode on every attempt to log in.
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            You can cancel the SMS or MMS service at any time by texting “STOP” to 1-855-619-2302. When you send the SMS
            message “STOP” to us, we reply with an SMS message that confirms that you have been unsubscribed. After
            this, you won’t receive SMS any additional messages from us. If you want to join again, sign up as you did
            the first time and we will start sending SMS and MMS messages to you again.
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            You can get more information at any time by texting “HELP” to 1-855-619-2302. When you send the SMS message
            “HELP” to us, we respond with instructions on how to use our service and how to unsubscribe.
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0]}>
            We are able to deliver messages to the following mobile phone carriers: Major carriers: AT&T, Verizon
            Wireless, Sprint, T-Mobile, MetroPCS, US Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile. Minor
            carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One
            of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral
            Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden
            State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless
            (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech
            Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP
            Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5
            Star Wireless). Carriers are not liable for delayed or undelivered messages.
          </Text>
        </View>

        <View style={MainStyle.row}>
          <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
          <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'],]}>
            Message and data rates may apply for any messages that we send to you or you send to us. You will receive
            one message per login attempt. Contact your wireless provider for more information about your text plan or
            data plan. If you have questions about the services provided by this short code, email us at <Text
            style={styles.small_link}
            onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
          </Text>
        </View>

        <Text style={isSm ? styles.title_s : styles.title_l}>21. Promotions and Promotion Periods.</Text>

        <Text style={[isSm ? styles.secondary_s : styles.secondary_l, MainStyle.m_b_0]}>
          <Text style={MainStyle.t_d_l_u}>New Player Promotion.</Text> New players receive “10 Free Shots” which are the
          equivalent of 10 Challenges with a $3 Challenge Fee, without deposit required. The results of each challenge
          during the “10 Free Shots” will be accumulated into Free Play with a 1x Turnover requirement. In the case that
          a New Player does not earn at least $10, the New Player will receive $10 in Free Play after the completion of
          their 10th Challenge
        </Text>
      </View>
    </ScrollView>
  );
};

const styles = {
  text_l: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_24, MainStyle.m_b_30, MainStyle.l_h_36
  ],
  text_s: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_14, MainStyle.m_b_15, MainStyle.l_h_18
  ],

  link_l: [
    MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_24, MainStyle.t_d_l_u, MainStyle.l_h_36
  ],
  link_s: [
    MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_12, MainStyle.t_d_l_u, MainStyle.l_h_16
  ],

  small_link: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.t_d_l_u],

  secondary_l: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_18, MainStyle.m_b_30, MainStyle.l_h_36
  ],
  secondary_s: [
    MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_10, MainStyle.m_b_15, MainStyle.l_h_16
  ],

  title_l: [MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.m_b_30, MainStyle.t_t_u],
  title_s: [
    MainStyle.c_white, MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.l_h_18, MainStyle.m_b_21, MainStyle.t_t_u
  ],

  dot_l: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_24, MainStyle.l_h_36, MainStyle.m_x_16],
  dot_s: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.l_h_16, MainStyle.m_x_8]
};

export default React.memo(TermsAndConditionsContent);
