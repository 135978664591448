import React, {useContext} from 'react';
import {Modal, Text, View} from 'react-native';
import MainStyle from '../../MainStyle';
import {StoreContext} from "../components/StoreContextProvider";

const SuccessModal = () => {
  const {isSuccessMessageShow, setIsSuccessMessageShow} = useContext(StoreContext);

  return (
    <Modal animationType="fade" transparent={true} visible={!!isSuccessMessageShow} statusBarTranslucent={false}
           hardwareAccelerated={false} onRequestClose={() => setIsSuccessMessageShow('')}>
      <View style={[MainStyle.flex1, MainStyle.a_i_c, MainStyle.j_c_c, MainStyle.p_x_9]}>
        <View style={[MainStyle.bc_layoutBg, MainStyle.b_r_5, MainStyle.p_20]}>
          <Text style={[MainStyle.f_s_25, MainStyle.c_lightText, MainStyle.t_a_c]}>{isSuccessMessageShow}</Text>
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(SuccessModal);
