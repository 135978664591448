import React, {useCallback, useContext, useEffect, useRef} from "react";
import MainStyle from "../../MainStyle";
import {Div, ScrollDiv} from "react-native-magnus";
import {Linking, Platform, Text, useWindowDimensions, View} from "react-native";
import SupportGuideHeader from "../components/ SupportGuideHeader";
import {colors} from "../assets/styles/colors";
import {useNavigation, useRoute} from "@react-navigation/native";
import {StoreContext} from "../components/StoreContextProvider";
import {breakpoint} from "../constants";
import SupportGuideButtonMenu from "../components/SupportGuideButtonMenu";

const PromotionsScreen = () => {
  const navigation: any = useNavigation();
  const route: any = useRoute();
  const Context = useContext(StoreContext);
  const {width} = useWindowDimensions();
  const isSm = width < breakpoint;
  let eligibilitySectionRef: any = useRef(null);
  let promotionsAndPromotionPeriodRef: any = useRef(null);

  const openLink = (url: string) => {
    if (Platform.OS === 'web') window.open(url, '_blank');
    else Linking.openURL(url).then();
  };

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  useEffect(() => {
    if (route.params?.scrollTo === 'ELIGIBILITY_SECTION') {
      eligibilitySectionRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    if (route.params?.scrollTo === 'PROMOTIONS_AND_PROMOTION_PERIOD') {
      promotionsAndPromotionPeriodRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }, [route.params?.scrollTo]);

  return (
    <>
      <SupportGuideHeader title="PROMOTIONS TERMS AND CONDITIONS" onExit={onExit}/>

      <ScrollDiv bg={colors.bg_contest} flex={1} pb={isSm ? 27 : 92}>
        <Div maxW={1140} w="100%" alignSelf="center" pt={isSm ? 17 : 27} px={isSm ? 40 : 0}>
          <Text style={isSm ? styles.title_s : styles.title_l}>Introduction</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              New Super Money Games (“<Text style={MainStyle.Barlow700}>SMG</Text>” or “<Text
              style={MainStyle.Barlow700}>Us</Text>” or "<Text style={MainStyle.Barlow700}>Our</Text>” or
              “<Text style={MainStyle.Barlow700}>We</Text>”) users (“<Text
              style={MainStyle.Barlow700}>User</Text>” or “<Text style={MainStyle.Barlow700}>You</Text>” or
              “<Text style={MainStyle.Barlow700}>Your</Text>”) may qualify for certain promotions (“<Text
              style={MainStyle.Barlow700}>Promotions</Text>”) offered by SMG from time to time. Promotions offered
              through any of SMG’s Platforms are subject to Super Money Games’ General Terms and Conditions <Text
              style={styles.small_link}
              onPress={() => navigation.navigate('TermsAndConditions')}>www.skillmoney.com/termsandconditions</Text> (“General
              Terms”), these Promotions Terms and Conditions (“Promotion Terms”), together with any applicable Key Terms
              (which may also be referred to as “Key Terms”), set out on the individual web landing page for the
              relevant Promotion(s), and Super Money Games’ Privacy Policy (“Privacy Policy”) <Text
              style={styles.small_link}
              onPress={() => navigation.navigate('PrivacyPolicy')}>www.skillmoney.com/privacy</Text> (collectively, the
              “Rules”).
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The Rules form a legal agreement between You and Us and can only be amended with Our consent. By
              participating in any Promotion, You are indicating You accept and agree to be bound by the Rules.
              Capitalized terms used but not defined in these Promotion Terms have the meanings ascribed to them in the
              General Terms. In the event of any conflict or inconsistency between these Promotion Terms, any applicable
              Key Terms, and the General Terms, the Key Terms shall prevail, followed by these Promotion Terms, but only
              to the extent necessary to resolve such conflict or inconsistency. Your use of each Platform itself and of
              any services available through that Platform are subject to the Rules.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l} ref={promotionsAndPromotionPeriodRef}>
            Promotions and Promotion Periods
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              We may, from time-to-time, run Promotions and these may take the form of bonus offers (whether Bonus, Free
              Play, Risk Free, and/or Deposit Match), or other form of Promotion, or a combination of any of these. The
              particular form of Promotion will be described, and full details set out, in the communications (such as
              emails, computer pop-ups and letters) We issue to tell You about each Promotion (each a “<Text
              style={MainStyle.Barlow700}>Promotion Communication</Text>”) and/or on the individual web landing
              page for the relevant Promotion.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              For the purposes of all Promotions, the promoter is the entity You have contracted with under the General
              Terms.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The period of time during which each Promotion will run (“<Text style={MainStyle.Barlow700}>Promotion
              Period</Text>”) will be specified in the applicable Key Terms. Each Promotion will automatically close at
              the end of the relevant Promotion Period, at which point no further participation in that Promotion will
              be possible. Where no Promotion Period is specified the relevant Promotion will end when it is
              discontinued on the Platform.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Promotion Communications</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Unless otherwise indicated in the applicable Promotion Communication, participation in each Promotion is
              open to players from any the Platforms but is limited to one per person, family, household, address or
              organization to whom We send the Promotion Communication. We reserve the right to restrict participation
              in certain Promotions to players who fulfill specific selection criteria. Where a Promotion Communication
              permits multiple participants, We reserve the right, in Our sole discretion, to limit the number of
              participants.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Unless otherwise indicated in the Promotion Communication, it is intended for the addressed recipient or
              category of recipient only and cannot be transferred. If You are not the intended recipient or within the
              intended category of recipient, then the offer is null and void.
            </Text>
          </View>

          <Text style={[isSm ? styles.title_s : styles.title_l,]} ref={eligibilitySectionRef}>Eligibility</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To be eligible to participate in any Promotion, You must (1) be eighteen (18) years of age or older; (2)
              be legally entitled to use Our services in accordance with Our General Terms; (3) if the Promotion is
              specifically intended for players who are residents of and/or located in a particular State as stated in
              the applicable Key Terms, be a resident of and/or located in said State; (4) unless the applicable Key
              Terms indicate that a “no purchase necessary” route is available and subject to section 5(b) below, have
              successfully completed registration on the Platform for the relevant Service and opened an Account in
              accordance with Our General Terms; (5) satisfy any other eligibility criteria set out in the relevant
              sections of these Promotion Terms in respect of certain general categories of Promotions and in the
              applicable Key Terms; and (v) not be an Unauthorized Person (as defined in section 6, below) (a “<Text
              style={MainStyle.Barlow700}>Qualifying Player</Text>”).
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To be eligible to participate in any Promotion which is stated to be intended for new players or which is
              described as a “new player” or “sign up” offer (or similar), You must not previously have opened and made
              a deposit into an Account on any of Our Services. Such Promotions may not be used in conjunction with any
              other Promotion unless otherwise specified in a particular Promotion’s Key Terms.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The identity of each participant in a Promotion will be determined from all or any combination of the
              following: name, address, email address, credit/charge/debit card number, biometric data (including facial
              recognition), IP address and other forms of identification which may be required. We reserve the right to
              request further information from You if You wish to participate in certain Promotions.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Participation in Promotions</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Participation in any Promotion may be automatic or voluntary.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Where participation in a Promotion is not automatic, You should follow the instructions set out in the
              relevant Promotion Communication, on the individual web landing page for that Promotion and, where
              appropriate, the applicable Key Terms.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Unless otherwise indicated in the applicable Key Terms, entry into each Promotion may be used only once.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Excluded and Disqualified Players</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The following persons shall be excluded/disqualified from participating in any Promotions: (1) any person
              prohibited from gaming within the State where SMG is permitted to offer Services, (2) an employee of a
              supplier or vendor of SMG (unless otherwise expressly agreed to in writing by SMG after notice from such
              supplier or vendor); (3) any individual prohibited from participating or using the Services under any
              Rules (including, without limitation, the General Terms); and (4) any employee of any company or entity
              who has administrative or privileged access to SMG’s online gaming data or systems (collectively, “<Text
              style={MainStyle.Barlow700}>Unauthorized Persons</Text>”).
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If You have received communication from Us that you are not eligible for participating in any of Our
              Promotions, then You would be ineligible to participate in any such Promotions or bonus privileges.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Qualifying Deposits</Text>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Unless the applicable Key Terms indicate that a “no purchase necessary” route is available (or such other
              dispensation is available), participation in any Promotion is subject to You having made the required
              minimum deposit as may be required from time-to-time into Your Account, once opened, (“<Text
              style={MainStyle.Barlow700}>Qualifying Deposit</Text>”) unless the Key Terms specify that a different
              Qualifying Deposit amount is required.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Payment of Bonuses</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Subject to clause 7(a) above, unless otherwise indicated in the applicable Key Terms, all real money
              bonuses will be paid into the Accounts of Qualifying Players any time within seven (7) days of entry into
              the relevant Promotion.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Your use of any real money bonuses is subject to Our review for irregular playing patterns. In the event
              that We deem that an irregular playing pattern has taken place, We reserve the right to prevent you from
              cashing out Account funds and/or withhold any of Your winnings derived from Your use of the bonus.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              In the event that more than one real money bonus or any excess payment is accidentally paid to a
              Qualifying Player, We reserve the right, without prejudice to any other rights under the Rules, to seek
              the return of the amount of any additional real money bonus or payment from that player's Account.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Prizes</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Qualifying Players who win a prize as the result of any Promotion (each, respectively, a “<Text
              style={MainStyle.Barlow700}>Winner</Text>” and a “<Text style={MainStyle.Barlow700}>Prize</Text>”)
              must accept the relevant Prize “as is” and there is no right to a cash alternative unless We, in Our sole
              discretion, elect to offer such a cash alternative (which may be less than the full value of the Prize).
              We reserve the right, at any time, to substitute any Prize with one or more prizes (other than a cash
              alternative) of substantially equivalent value.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              All Prizes will be drawn by the time or within the timeframe specified in the applicable Key Terms and,
              unless otherwise indicated there, a cash Prize will be paid into the Account of the Winner. In the case of
              a non-cash Prize, We will make all reasonable attempts to contact the Winner using his/her Account contact
              details, but if We have not been able to make contact with the Winner within 30 days of the Prize being
              won, We reserve the right to draw another Winner in place of the original Winner or to forfeit the Prize
              altogether without liability to the Winner.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Any transactions (e.g., chargeback fees, non-sufficient funds) that cause Your account credit balance
              (unrestricted funds in Your Account) to be negative must be paid to Us before any individual cash Prize is
              issued to You. We reserve the right to withhold any cash Prize that You win to cover Your negative account
              credit balance. We also reserve the right to withhold any non-cash Prize that You win until Your account
              credit balance is zero or greater. Your account credit balance must be zero or greater within 30 calendar
              days of You being notified of Your non-cash Prize win or such Prize may be forfeited. We reserve the right
              not to award a Prize or to seek the return of any Prize awarded in the event that the Winner is not
              entitled to receive such Prize in accordance with applicable law (provided always that the Winner is
              solely responsible for ensuring that s/he is entitled to receive any Prize) or if We have cause to
              disqualify the Winner in accordance with section 6 of these Promotion Terms or any other provision of the
              Rules.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(d)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If You are competing in a challenge, league or tournament and are unable, for any reason whatsoever, or
              choose not to progress to the next round after qualifying to do so, You will forfeit Your place and will
              not be eligible to receive any Prize to be awarded for the next or any subsequent round or the final
              Prize.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(e)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Any entitlement to receive a Prize is non-transferable.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(f)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The Winner is solely responsible for the payment of any applicable tax in relation to the relevant Prize
              and for any costs associated with the Prize or its use, including without limitation the cost of complying
              with any requirements of applicable local law.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(g)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Where a Prize consists of an event, activity, holiday or the performance of a service, the Winner is
              solely responsible for complying with any booking or other conditions or instructions of the relevant
              third party provider or organizer and must be able to comply with any restrictions as to dates and times
              in relation to the Prize (which may not be re-scheduled) and any other eligibility restrictions imposed by
              the relevant third party provider or organizer. The contract in relation to any such event, activity,
              holiday or service will be directly between the Winner and the relevant third party provider or organizer
              and We will not be a party to that contract or have any liability in relation to its performance or
              non-performance, which shall be the sole responsibility of the relevant third party provider or organizer.
              We will not provide any form of insurance, including without limitation any public liability or
              cancellation insurance, in respect of any Prize consisting of an event, activity, holiday or service.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(h)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Where, in relation to any Prize, We make use of any third party name or trademark, these are proprietary
              to the relevant third party. No license, affiliation, sponsorship or endorsement is claimed or should be
              inferred from the use of these names or trademarks or the use of any photograph of a Prize. Any photograph
              used to promote a Prize is for illustrative purposes only and the actual Prize may differ from the item
              shown in the photograph, including without limitation as to make, model, specification, color, finish,
              packaging and other features.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(i)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Our offering of a particular Prize implies no affiliation on Our part with or sponsorship or endorsement
              of the relevant third party manufacturer, supplier, provider or organizer.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(j)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The Winner is solely responsible for all use made of the Prize. Except as expressly provided in these
              Promotion Terms, no representations, warranties, conditions or other terms are made, given or accepted by
              Us and no other terms shall apply, as between Us and any person, in relation to any Prize, including
              without limitation any terms as to suitability, reliability, satisfactory quality or fitness for purpose,
              or any other implied terms, all of which We exclude to the maximum extent permitted by law.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Prize Draws</Text>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              In respect of any prize draw, if and where applicable, Winners will be drawn at random from all entries
              correctly submitted and, provided all players are Qualifying Players, no favoritism will be shown in terms
              of gender, age, geographic location, length of time as a Qualifying Player, which of Our gaming channels
              or Platforms a Qualifying Player has used, or any other factor.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Publicity and Intellectual Property</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              By participating in any Promotion, We may ask you to co-operate in advertising, marketing and/or publicity
              material and activities, at Our expense. You may also be asked (1) to wear any branded clothing or attire
              We may provide; and (2) to sign, if requested, an irrevocable release form allowing Us, without any
              compensation being payable to you, to use Your name, photograph, likeness, any comments made by or
              attributed to You, and to incorporate any such information and any audio/visual recording or broadcast for
              such promotional purposes, in any media, throughout the world.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Where You participate in any event which We host or arrange in connection with a Promotion, You agree not
              to make use of any third party branding or advertising for any organization which We consider to be Our
              competitor, including without limitation any branding or advertising for any other gaming company, and You
              agree not to conduct yourself in a manner that might, in Our reasonable opinion, bring us, any of Our
              affiliates or any of Our or their respective brands into disrepute. In the event of any breach of this
              requirement, We reserve the right not to award a Prize or to seek the return of any Prize awarded.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Promotional Play Restrictions</Text>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <View style={MainStyle.flex1}>
              <Text style={isSm ? styles.text_s : styles.text_l}>
                We offer bonuses and promotions to enhance your enjoyment of our games. We therefore expect that you
                will participate in all bonuses and promotions fairly, so that our other customers too may enjoy our
                services. We have set out below certain types of play which we consider to be unfair and therefore to be
                in breach of these Promotion Terms:
              </Text>

              <View style={MainStyle.row}>
                <Text style={isSm ? styles.dot_s : styles.dot_l}>(1)</Text>
                <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                  using minimal risk gaming patterns (e.g. low risk gaming, zero risk gaming or equal gaming patterns);
                </Text>
              </View>

              <View style={MainStyle.row}>
                <Text style={isSm ? styles.dot_s : styles.dot_l}>(2)</Text>
                <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                  using multiple accounts to obtain more than the number of bonuses or free play permitted for each
                  customer;
                </Text>
              </View>

              <View style={MainStyle.row}>
                <Text style={isSm ? styles.dot_s : styles.dot_l}>(3)</Text>
                <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                  sharing personalized promotional offers or bonuses (i.e. promotions or bonuses which are available to
                  specific customers only) received by you e.g. via email, inbox message, links, URLs etc. with others
                  who were not the intended recipient. This includes accessing promotions or bonuses forwarded to you by
                  others when you were not the intended recipient;
                </Text>
              </View>

              <View style={MainStyle.row}>
                <Text style={isSm ? styles.dot_s : styles.dot_l}>(4)</Text>
                <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                  concealing your country of residence in order to access a promotion or bonus that is not available in
                  your country of residence;
                </Text>
              </View>

              <View style={MainStyle.row}>
                <Text style={isSm ? styles.dot_s : styles.dot_l}>(5)</Text>
                <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
                  colluding with other customers in order to take advantages of promotions or bonuses; or
                </Text>
              </View>
            </View>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Where you are in breach of any of the provisions of this Section 12, we may be entitled to cancel any
              bonuses or promotions applied to your account, reclaim any bonus element of such offers and either settle
              challenges at the correct odds, void any challenge funded by a bonus, or remove any subsequent winnings.
              We may also be entitled to levy an administration charge up to the value of the bonus, free play or
              additional payment to cover our reasonable administrative costs in respect of any such action that we
              take.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Currency</Text>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              All references to sums of money will be in U.S. currency.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>Our Liability</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Except where prohibited by law, We accept no liability in relation to Your participation (or inability to
              participate) in any Promotion, including without limitation any use made by You of (or inability to use)
              any Prize, to the maximum extent permitted by law.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If any Promotion cannot be executed as planned, including due to any technical problems or circumstances
              beyond Our control, We shall incur no liability and no bonus, payment or prize of any kind will be
              awarded.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>
            Alteration and Termination of Promotions; Modification of the Rules
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              We reserve the right to alter, discontinue or terminate any Promotion, or any aspect of it, at any time,
              with or without notice, for any reason whatsoever, including without limitation if there has been any
              printing, production, distribution or other error in any Promotion Communication or on the Platform, or
              where there has been any error in the preparation for or conduct of any Promotion affecting the result of
              the Promotion or the number of participants or the value of claims.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The Rules may be modified by Us at any time by posting the modified terms on the relevant page(s) of the
              Platform. We recommend You revisit these Promotion Terms regularly. By Your continued participation in the
              Promotion, You accept any such modified terms.
            </Text>
          </View>

          <Text style={isSm ? styles.title_s : styles.title_l}>General</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              You may not assign or transfer any or all of Your rights or obligations under the Rules.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              No third party shall have a right to enforce the Rules against Us.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Failure by Us to enforce a right under the Rules does not result in waiver of such right.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(d)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If any part of the Rules is found to be unenforceable as a matter of law, all other parts of the Rules
              shall be unaffected and shall remain in force.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(e)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Our determination and decision on all matters will be final and no correspondence will be entered into.
              Any dispute or situation not covered by the Rules will be resolved by Our management in a manner it deems
              to be the fairest to all concerned and, subject to the following section, that decision shall be final and
              binding on all players.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(f)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              You and We agree that the laws of the state where SMG is permitted and licensed to offer sports wagering
              apply to the Rules and that any dispute between You and Us arising out of or in connection with the Rules
              or any Promotion will only be dealt with by the courts of that state.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(g)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If these Promotion Terms or any applicable Key Terms are translated into a language other than English,
              then the English version shall prevail where there is any inconsistency.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(h)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If You have any questions, please contact <Text style={styles.small_link}
                                                              onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
            </Text>
          </View>
        </Div>
      </ScrollDiv>

      <SupportGuideButtonMenu isSm={isSm} onExit={onExit}/>
    </>
  );
};

const styles = {
  text_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.m_b_30, MainStyle.l_h_36],
  text_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.m_b_15, MainStyle.l_h_16],

  link_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_24, MainStyle.t_d_l_u, MainStyle.l_h_36],
  link_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_12, MainStyle.t_d_l_u, MainStyle.l_h_16],

  small_link: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.t_d_l_u],

  secondary_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_18, MainStyle.m_b_30, MainStyle.l_h_36],
  secondary_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_10, MainStyle.m_b_15, MainStyle.l_h_16],

  title_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.m_b_30, MainStyle.t_t_u],
  title_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.m_b_15, MainStyle.t_t_u],

  dot_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.l_h_36, MainStyle.m_x_16],
  dot_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.l_h_16, MainStyle.m_x_8]
};

export default PromotionsScreen;
