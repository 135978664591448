import React from "react";
import {Image, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import {Challenge, Payline} from "../../generated/smg_service_pb";
import {payoutFormat} from "../constants";
import {numberWithCommas} from "../utils/number";
import icon from "../assets/img/d3df7f9a40bd13add704acfca782219b.png";
import {colors} from "../assets/styles/colors";

type Props = {
  challengesList?: Challenge.AsObject[], payLinesList?: Payline.AsObject[], amount?: number, holeInOneAmount?: number
};

const PayTable = (props: Props) => {
  const {challengesList, payLinesList, amount, holeInOneAmount} = props;
  const list = challengesList?.reduce((acc: any[], challenge, i) => {
    if (payLinesList && payLinesList[i]?.multiplier) {
      acc.push({
        ft: Math.floor(challenge.value2 / 12),
        amount: payoutFormat(payLinesList[i]?.multiplier / 100 * (amount || 0) / 100)
      });
    }
    return acc;
  }, []) || [];

  return (
    <View style={styles.wrap}>
      <View style={[MainStyle.row, MainStyle.m_t_13]}>
        <Text style={styles.title}>Win Circle</Text>
        <Text style={styles.title}>Win</Text>
      </View>

      {!!holeInOneAmount && (
        <View style={[MainStyle.row, MainStyle.a_i_c]}>
          <View style={[MainStyle.flex1, MainStyle.a_i_c]}>
            <Image source={icon} style={[MainStyle.w_44, MainStyle.h_28, styles.shadow]}/>
          </View>
          <Text style={styles.value}>${numberWithCommas(holeInOneAmount / 100)}</Text>
        </View>
      )}

      {list.map(el => (
        <View key={el.ft} style={MainStyle.row}>
          <Text style={[...styles.value, MainStyle.c_white]}>{el.ft} ft</Text>
          <Text style={styles.value}>${el.amount}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = {
  wrap: [
    MainStyle.w_160, MainStyle.b_r_20, MainStyle.b_w_1, MainStyle.b_c_cardInfoBorder, MainStyle.bg_settings,
    MainStyle.column, MainStyle.p_b_10
  ],
  column: [MainStyle.flex1, MainStyle.p_t_13, MainStyle.a_i_c],
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.flex1, MainStyle.t_a_c],
  shadow: {
    shadowColor: 'rgba(0, 0, 0, 0.75)', shadowOffset: {width: 0, height: 2}, shadowOpacity: 1, shadowRadius: 2,
    backgroundColor: colors.settings_bg, elevation: 5
  },
  value: [MainStyle.Barlow500, MainStyle.f_s_18, MainStyle.c_new_green_text, MainStyle.flex1, MainStyle.t_a_c]
};

export default React.memo(PayTable);
