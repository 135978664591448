import React, {useContext, useEffect, useState} from "react";
import {Modal, ScrollView, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import Input from "../components/Input";
import Button from "../components/Button";
import {colors} from "../assets/styles/colors";
import {useFormik} from "formik";
import * as Yup from "yup";
import {formatWithMask, useMaskedInputProps} from "react-native-mask-input";
import ServiceClient from "../utils/ServiceClient";
import {AxiosError} from "axios";
import {StoreContext} from "../components/StoreContextProvider";
import {useNavigation} from "@react-navigation/native";
import {getLocation} from "../utils";

type Props = { cardUid: string, onClose: () => void, amount: string };

const expiryMask = [/\d/, /\d/, '/', /\d/, /\d/];

const SavedCardsModal = (props: Props) => {
  const {cardUid, amount} = props;
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {expiry: ''}, validationSchema: Yup.object({expiry: Yup.string().required().min(5)}),
    onSubmit: () => {
      deposit();
    }
  });
  const Context = useContext(StoreContext);
  const {cancelModalConfig} = Context;
  const navigation: any = useNavigation();

  const expiryMaskedInputProps = useMaskedInputProps({
    value: formik.values.expiry, onChangeText: formik.handleChange('expiry'), mask: expiryMask
  });

  const deposit = async () => {
    setIsLoading(true);
    const {unmasked: expiry} = formatWithMask({text: formik.values.expiry, mask: expiryMask});
    const curLocation = await getLocation();

    if (!curLocation) {
      setIsLoading(false);
      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: 'Permission to access the location was denied.', rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });

      return;
    }

    const data = {
      amount: +amount * 100, savedCardUid: cardUid, expirationDate: expiry,
      location: {latitude: `${curLocation.coords.latitude}`, longitude: `${curLocation.coords.longitude}`}
    };

    ServiceClient.deposit(data).then(() => {
      Context.getUser().then();
      setIsLoading(false);
      props.onClose();

      Context.setCancelModalConfig({
        visible: true, title: 'Success', titleColor: MainStyle.c_green, leftBtnText: 'OK',
        subtitle: 'You have successfully replenished your balance.',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate('challenges');
        }
      });

    }).catch((err: AxiosError<{ success: boolean, message: string }>) => {
      console.log('deposit error', err);
      setIsLoading(false);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;
      props.onClose();

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const getFieldError = (key: string) => {
    return formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors];
  };

  useEffect(() => {
    if (!cardUid) formik.resetForm();
  }, [cardUid]);

  return (
    <Modal animationType="fade" transparent visible={!!cardUid} statusBarTranslucent={false}
           hardwareAccelerated={false} onRequestClose={props.onClose}>
      <View style={styles.wrap}>
        <ScrollView contentContainerStyle={[MainStyle.full_h, MainStyle.full_w, MainStyle.a_i_c, MainStyle.j_c_c]}>
          <View style={styles.container_s}>
            <Text style={styles.title_s}>Saved Card</Text>

            <View style={[MainStyle.w_240, MainStyle.m_x_auto]}>
              <Input
                label="EXPIRY"
                type="numeric"
                onBlur={formik.handleBlur('expiry')}
                error={getFieldError('expiry')}
                {...expiryMaskedInputProps}
                placeholder="MM/YY"
                style={MainStyle.m_b_20}
              />
            </View>
          </View>

          <View style={MainStyle.flex}>
            <Button
              text="DEPOSIT"
              h={40}
              fontSize={16}
              w={240}
              mb={15}
              onPress={() => formik.handleSubmit()}
              isLoading={isLoading}
            />

            <Button
              text="CANCEL"
              h={40}
              fontSize={16}
              bg="transparent"
              w={240}
              borderColor={colors.popupDangerPrimary}
              borderWidth={1}
              color={colors.popupDangerPrimary}
              onPress={isLoading ? undefined : props.onClose}
            />
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = {
  wrap: [MainStyle.full_h, MainStyle.full_w, MainStyle.bc_modalBg, MainStyle.fix_gradient_and_modal_error_in_landscape],
  container_s: [
    MainStyle.w_330, MainStyle.bc_darkblue, MainStyle.b_w_1, MainStyle.b_c_popupBorder, MainStyle.b_r_4, MainStyle.p_t_20,
    MainStyle.p_x_20, MainStyle.m_b_80
  ],
  title_s: [MainStyle.c_lightblue, MainStyle.Barlow600, MainStyle.f_s_20, MainStyle.t_a_c, MainStyle.m_b_20],
  subtitle_s: [
    MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.l_h_19, MainStyle.c_white, MainStyle.t_a_c, MainStyle.m_b_20
  ]
};

export default React.memo(SavedCardsModal);
