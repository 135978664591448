import React, {ReactElement} from "react";
import MainStyle from "../../MainStyle";
import {LinearGradient} from "expo-linear-gradient";
import {colors} from "../assets/styles/colors";
import {GestureResponderEvent, Pressable, View} from 'react-native';

type Props = {
  children: ReactElement, position: 'left' | 'middle' | 'right', active: boolean,
  onPress?: ((event: GestureResponderEvent) => void)
};

const SelectChallengeGradient = (props: Props) => {
  const {children, position, active} = props;

  return (
    <Pressable onPress={props.onPress}>
      {active ? (
        <LinearGradient colors={[colors.darkYellow, colors.lightYellow]} style={styles[`${position}_gradient_style`]}>
          {children}
        </LinearGradient>
      ) : (
        <View style={[...styles[`${position}_gradient_style`], MainStyle.b_c_transparent]}>
          {children}
        </View>
      )}
    </Pressable>
  );
};

const styles = {
  middle_gradient_style: [MainStyle.b_t_w_3, MainStyle.b_b_w_3, MainStyle.b_c_yellow],
  left_gradient_style: [
    MainStyle.b_w_3, MainStyle.b_r_w_0, MainStyle.b_c_yellow, MainStyle.b_t_l_r_14, MainStyle.b_b_l_r_14
  ],
  right_gradient_style: [
    MainStyle.b_w_3, MainStyle.b_l_w_0, MainStyle.b_c_yellow, MainStyle.b_t_r_r_14, MainStyle.b_b_r_r_14
  ]
}

export default React.memo(SelectChallengeGradient);
