import React, {useCallback, useContext} from 'react';
import {Linking, Platform, Text, useWindowDimensions, View} from 'react-native';
import {Div, ScrollDiv} from 'react-native-magnus';
import {StoreContext} from '../../../components/StoreContextProvider';
import {breakpoint} from '../../../constants';
import {useNavigation} from '@react-navigation/native';
import SupportGuideHeader from "../../../components/ SupportGuideHeader";
import {colors} from "../../../assets/styles/colors";
import MainStyle from "../../../../MainStyle";
import SupportGuideButtonMenu from "../../../components/SupportGuideButtonMenu";

const ResponsibleGaming = () => {
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {width} = useWindowDimensions();
  const isSm = width < breakpoint;

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  const openLink = (url: string) => {
    if (Platform.OS === 'web') window.open(url, '_blank');
    else Linking.openURL(url).then();
  };

  return (
    <>
      <SupportGuideHeader title="Responsible Gaming" onExit={onExit}/>

      <ScrollDiv bg={colors.bg_contest} flex={1} pb={isSm ? 27 : 92}>
        <Div maxW={1140} w="100%" alignSelf="center" pt={isSm ? 17 : 27} px={isSm ? 40 : 0}>
          <Text style={isSm ? styles.title_s : styles.title_l}>General</Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            SkillMoneyGames strive to offer the most exciting complement to the sports viewing experience for you and
            your friends. Importantly, we want to provide you with that experience in a safe, reliable and responsible
            manner.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Please know that we are here to help you at any time.
          </Text>

          <Text style={isSm ? styles.title_s : styles.title_l}>Additional Information</Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Like many other addictions, compulsive play includes uncontrollable urges, which, if left unchecked, could
            result in financial hardship. Anyone could be susceptible to compulsive play regardless of age, gender,
            race, or financial status. Once detected, compulsive play can be successfully treated. The key is early
            detection in order to avoid adverse and dire consequences. If you feel you have a compulsive-play problem,
            we urge you to seek assistance.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>Compulsive-play warning signs include:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Playing more in order to recoup or chase previous losses.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Sacrificing time from family or work in order to participate in tournaments.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Repeated inability to stop or control participating.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Borrowing money to participate or to pay off previous losses.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Neglecting one's family or oneself due to excessive play.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Lying or not being truthful about time or money lost to playing.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Selling or pawning personal property to play.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Feelings of hopelessness, depression, or suicide.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            If you or someone you know is or may be experiencing a compulsive-play problem, it is important to remember
            that assistance, intervention, and treatment are available.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Resources that are committed to providing professional and compassionate service to all interested persons
            include:
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Call: 1.800-426-2537 or access {''}
            <Text style={isSm ? styles.link_s : styles.link_l} onPress={() => openLink('http://www.800gambler.org/')}>
              Gambling Problem Hotline in NJ | Gambling Addiction Treatment | 800-Gambler
            </Text>
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Call: 1.800-522-4700 National Council on Problem Gambling or access {''}
            <Text style={isSm ? styles.link_s : styles.link_l} onPress={() => openLink('https://www.ncpgambling.org')}>
              Home - National Council on Problem Gambling
            </Text>
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            If you are in Arizona, call the toll-free Arizona statewide helpline at 1-800-NEXTSTEP or text NEXT-STEP to
            53342. You may also visit {''}
            <Text style={isSm ? styles.link_s : styles.link_l} onPress={() => openLink('https://problemgambling.az.gov')}>
              Problem Gambling | Arizona Department of Gaming
            </Text>
          </Text>

          <Text style={isSm ? styles.secondary_s : styles.secondary_l}>
            If you are concerned that a family member is exhibiting warning signs of compulsive play behavior, please
            contact Customer Support for assistance. Eligible third parties may request that a consumer be excluded from
            SMG. Proof of eligibility status will be requested in documentary form evidencing sole or joint financial
            responsibility for the source of any funds deposited with SMG for gameplay including: (i) proof that the
            requestor is jointly obligated on the credit or debit card associated with the Consumer’s account; (ii)
            proof of legal dependency of the consumer on the requestor under state or federal law; (iii) proof of the
            existence of a court order that makes the requestor wholly or partially obligated for the debts of the
            person for whom exclusion is requested; or (iv) proof of the existence of a court order requiring the
            consumer to pay unmet child support obligations. SMG, in its sole discretion, may request additional
            documentary verification information to establish third-party eligibility.
          </Text>
        </Div>
      </ScrollDiv>

      <SupportGuideButtonMenu isSm={isSm} onExit={onExit}/>
    </>
  );
};

const styles = {
  text_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.m_b_30, MainStyle.l_h_36],
  text_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.m_b_15, MainStyle.l_h_16],

  link_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_24, MainStyle.t_d_l_u, MainStyle.l_h_36],
  link_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_12, MainStyle.t_d_l_u, MainStyle.l_h_16],

  secondary_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_18, MainStyle.m_b_30, MainStyle.l_h_36],
  secondary_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_10, MainStyle.m_b_15, MainStyle.l_h_16],

  title_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.m_b_30, MainStyle.t_t_u],
  title_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.m_b_15, MainStyle.t_t_u],

  dot_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.l_h_36, MainStyle.m_x_16],
  dot_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.l_h_16, MainStyle.m_x_8]
};

export default ResponsibleGaming;
