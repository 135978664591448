import React, {useContext, useEffect, useState} from "react";
import {Div} from "react-native-magnus";
import {colors} from "../assets/styles/colors";
import {Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import Button from "./Button";
import Valid from "card-validator";
import ServiceClient from "../utils/ServiceClient";
import {AxiosError} from "axios";
import {SavedCardType} from "../types";
import {StoreContext} from "./StoreContextProvider";
import {useNavigation} from "@react-navigation/native";
import SavedCardsModal from "../modals/SavedCardsModal";

type Props = { amount: string };

const SavedCards = (props: Props) => {
  const {amount} = props;
  const [savedCards, setSavedCards] = useState<SavedCardType[]>([]);
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user} = Context;
  const navigation: any = useNavigation();
  const [cardUid, setCardUid] = useState('');

  const deleteCard = (Uid: string) => {
    Context.setCancelModalConfig({...cancelModalConfig, visible: false});
    Context.setLoadingOverlay(true);

    ServiceClient.deleteCard(Uid).then(() => {
      getSavedCards();

    }).catch(err => {
      console.log('deleteCard err', err);
      Context.setLoadingOverlay(false);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const deleteSavedCard = (Uid: string) => () => {
    Context.setCancelModalConfig({
      visible: true, title: 'Notice', titleColor: MainStyle.c_yellow, leftBtnText: 'NO', rightBtnText: 'YES',
      subtitle: 'Do you want to delete a saved card?', rightBtnBorderColor: colors.popupDangerPrimary,
      onResume: () => deleteCard(Uid),
      onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false}),
      leftBtnBackgroundColor: colors.unselect, rightBtnBackgroundColor: colors.btn_bg_color,
      rightBtnBorderWidth: 1, rightBtnColor: colors.popupDangerPrimary
    });
  };

  const savedCardHandler = (Uid: string) => () => {
    if (user && user.selfLimitationConfirmed) {
      Context.setCancelModalConfig({
        visible: true, title: 'Notice', titleColor: MainStyle.c_yellow, leftBtnText: 'OK',
        subtitle: 'You will not be able to play or deposit money while the Self-Limitation is in effect.',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate('SelfExclusion');
        }
      });

    } else {
      setCardUid(Uid);
    }
  };

  const getToken = () => {
    ServiceClient.getPayGateToken().then(() => {
      getSavedCards();

    }).catch(err => {
      console.log('getPayGateToken err', err);
      Context.setLoadingOverlay(false);
      const subtitle = (err.response && err.response.data) ? err.response.data.message : err.message;

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle, rightBtnText: 'OK',
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const getSavedCards = () => {
    ServiceClient.getSavedCards().then(res => {
      setSavedCards(res);
      Context.setLoadingOverlay(false);

    }).catch((err: AxiosError<{ success: boolean, message: string }>) => {
      console.log('getSavedCards error', err);
    });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      {!!savedCards.length && (
        <Div w={240} alignItems="center" mb={51} bg={colors.amount} rounded={8} pt={18} mt={15} px={10}>
          <Text style={[MainStyle.f_s_20, MainStyle.c_white, MainStyle.m_b_15]}>Saved Cards</Text>

          {savedCards.map(el => (
            <View style={[MainStyle.row, MainStyle.full_w, MainStyle.m_b_15]} key={el.cardUid}>
              <Button
                text={el.cardMaskedNumber} h={40} fontSize={14} bg="transparent" borderWidth={1} borderColor="black"
                cardType={Valid.number(el.cardMaskedNumber.slice(0, 6)).card?.type} color="white"
                onPress={savedCardHandler(el.cardUid)}
                style={[MainStyle.flex1, MainStyle.b_t_r_r_0, MainStyle.b_b_r_r_0]}
              />

              <Button
                text="✕" h={40} w={45} fontSize={14} bg="transparent" borderWidth={1} borderColor="black"
                onPress={deleteSavedCard(el.cardUid)} style={[MainStyle.b_t_l_r_0, MainStyle.b_b_l_r_0]} color="white"
              />
            </View>
          ))}
        </Div>
      )}

      <SavedCardsModal cardUid={cardUid} amount={amount} onClose={() => setCardUid('')}/>
    </>
  );
};

export default React.memo(SavedCards);
