import React, {useCallback, useContext, useLayoutEffect} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Platform, StyleSheet, Text, useWindowDimensions} from 'react-native';
import {Div, ScrollDiv} from 'react-native-magnus';
import {colors} from '../assets/styles/colors';
import SupportGuideHeader from '../components/ SupportGuideHeader';
import {StoreContext} from '../components/StoreContextProvider';
import {LANDING_PAGE_URL, SESSION_EXPIRE_TIME, breakpoint} from '../constants';
import NotificationSwitch from "../components/NotificationSwitch";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import {User} from "../../generated/smg_service_pb";
import SupportGuideButtonMenu from "../components/SupportGuideButtonMenu";
import ServiceClient from "../utils/ServiceClient";

const NotificationsScreen = () => {
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {user, session, cancelModalConfig} = Context;
  const {width} = useWindowDimensions();
  const isSm = width < breakpoint;

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  const getUser = () => {
    Context.getUser().then(() => {
      Context.setLoadingOverlay(false);

    }).catch(err => {
      console.log('getUser err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: err.message,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const onChange = (field: 'text' | 'email' | 'partner') => () => {
    if (!user) return;
    Context.setLoadingOverlay(true);

    const {emailAddress, username, phoneNumber, allowTextNotifications, allowEmailNotifications} = user;
    const {allowPartnerPromos, handicap, handedness} = user;

    const payload = {
      email: emailAddress, username, phone: phoneNumber, allowTextNotifications, allowEmailNotifications,
      allowPartnerPromos, handicap, handedness
    };

    if (field === 'text') payload.allowTextNotifications = !payload.allowTextNotifications;
    else if (field === 'email') payload.allowEmailNotifications = !payload.allowEmailNotifications;
    else if (field === 'partner') payload.allowPartnerPromos = !payload.allowPartnerPromos;

    ServiceClient.updateUser(payload).then(() => {
      getUser();

    }).catch(err => {
      console.log('updateUser err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: err.message,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  useLayoutEffect(() => {
    if (!session || !user) {
      Context.setLoadingOverlay(true);
      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if(Platform.OS === 'web'){
              window.open(LANDING_PAGE_URL, '_self');
            }
            Context.setLoadingOverlay(false);
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);

            } catch (e: any) {
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
              });
            }
          }

          Context.setLoadingOverlay(false);
          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if(Platform.OS === 'web'){
                window.open(LANDING_PAGE_URL, '_self');
              }
            });
          }
        }
      })();
    }
  }, []);

  return (
    <>
      <SupportGuideHeader title="Notifications" onExit={onExit}/>

      <ScrollDiv bg={colors.bg_contest} flex={1}>
        <Div pt={isSm ? 17 : 27} alignItems="center">
          <Text style={isSm ? rnStyles.contentTitleSm : rnStyles.contentTitle}>NOTIFICATION SETTINGS</Text>

          <Div w={isSm ? 'auto' : 1040} row={!isSm} mt={isSm ? 54 : 85} justifyContent="space-between" h={248}>
            <NotificationSwitch
              isSm={isSm}
              label="TEXT"
              value={user?.allowTextNotifications}
              onPress={onChange('text')}
            />

            <NotificationSwitch
              isSm={isSm}
              label="EMAIL"
              value={user?.allowEmailNotifications}
              onPress={onChange('email')}
            />

            <NotificationSwitch
              isSm={isSm}
              label="PARTNER PROMOS"
              value={user?.allowPartnerPromos}
              onPress={onChange('partner')}
            />
          </Div>
        </Div>
      </ScrollDiv>

      <SupportGuideButtonMenu isSm={isSm} onExit={onExit}/>
    </>
  );
};

const rnStyles = StyleSheet.create({
  contentTitle: {color: colors.lightBlue, fontSize: 32, fontFamily: 'Barlow-Bold'},
  contentTitleSm: {color: colors.lightBlue, fontSize: 16, fontFamily: 'Barlow-Bold'}
});

export default NotificationsScreen;
