import React, {useContext, useLayoutEffect} from 'react';
import {View, Text, Pressable, Platform, useWindowDimensions} from 'react-native';
import MainStyle from '../../../../../../MainStyle';
import Button from '../../../../../components/Button';
import {StoreContext} from "../../../../../components/StoreContextProvider";

// @ts-ignore
import VeriffSdk from '@veriff/react-native-sdk';
import {breakpoint} from "../../../../../constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useNavigation} from "@react-navigation/native";
import ServiceClient from "../../../../../utils/ServiceClient";

let createFrame: any, messages: any;

if (Platform.OS === 'web') {
  const {createVeriffFrame, MESSAGES} = require('@veriff/incontext-sdk');
  createFrame = createVeriffFrame;
  messages = MESSAGES;
}

type Props = { goBack?: () => void, redirectUser: () => void }

const KycTerm = (props: Props) => {
  const Context = useContext(StoreContext);
  const {cancelModalConfig, session} = Context;
  const {width} = useWindowDimensions();
  const navigation: any = useNavigation();

  const launchVeriff = (veriffurl: string) => {
    (async () => {
      if (Platform.OS === 'web') {
        createFrame({
          url: veriffurl,
          onEvent: function (msg: any) {
            switch (msg) {
              case messages.CANCELED:
                console.log('user canceled the flow before completing');
                break;

              case messages.FINISHED:
                console.log('MESSAGES.FINISHED');
                props.redirectUser();
                break;

              case messages.STARTED:
                console.log('MESSAGES.STARTED');
                break;
            }
          },
          onReload: () => {
            // Logic to re-open Veriff SDK after page reload
            // e.g. sessionStorage.setItem('@veriff-session-url', url);
            window.location.reload();
          }
        });

      } else {
        const result = await VeriffSdk.launchVeriff({sessionUrl: veriffurl});

        switch (result.status) {
          case VeriffSdk.statusDone:
            props.redirectUser();
            break;

          case VeriffSdk.statusCanceled:
            console.log('user canceled the flow before completing');
            break;

          case VeriffSdk.statusError:
            Context.setCancelModalConfig({
              title: 'ERROR', subtitle: `Veriff failed with error=${result.error}`, visible: true, leftBtnText: 'OK',
              onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false}),
              onClose: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
            });
            break;
        }
      }
    })();
  };

  const StartVeriffKycVerification = () => {
    Context.setLoadingOverlay(true);

    ServiceClient.startVeriffKycVerification().then(res => {
      Context.setLoadingOverlay(false);
      if (res) launchVeriff(res);

    }).catch(err => {
      console.log('startVeriffKycVerification err', err);
      Context.setLoadingOverlay(false);

      if (err.message) {
        Context.setCancelModalConfig({
          title: 'ERROR', subtitle: err.message, leftBtnText: 'OK', visible: true,
          onExit: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false}),
          onClose: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
        });
      }
    });
  };

  useLayoutEffect(() => {
    (async () => {
      if (!session) {
        const val = await AsyncStorage.getItem('session');

        if (val) {
          const session = JSON.parse(val);
          Context.setSession(session);

        } else {
          navigation.reset({index: 0, routes: [{name: 'Splash'}]});
        }
      }
    })();
  }, []);

  return (
    <View style={[MainStyle.flex1, MainStyle.j_c_c]}>
      <Text style={width >= breakpoint ? styles.title_l : styles.title_s}>Know Your Customer (KYC)</Text>

      <View style={[MainStyle.a_i_c, MainStyle.p_x_24]}>
        <Text style={width >= breakpoint ? styles.subtitle_l : styles.subtitle_s}>
          By selecting the “Proceed” button below, you agree to completing the KYC procedures.
        </Text>
      </View>

      <View style={[MainStyle.flex, MainStyle.a_i_c, MainStyle.m_b_64]}>
        <Button
          text="Proceed with KYC"
          onPress={StartVeriffKycVerification}
          style={[width >= breakpoint ? styles.btn : MainStyle.w_220, MainStyle.m_b_16]}
          textStyle={[width >= breakpoint ? MainStyle.f_s_32 : null]}
        />

        {!!props.goBack && (
          <Pressable onPress={props.goBack} style={[MainStyle.w_144, MainStyle.h_22]}>
            <Text style={[MainStyle.t_a_c, MainStyle.c_warn, MainStyle.f_w_700, MainStyle.f_s_16, MainStyle.l_h_22]}>
              CANCEL
            </Text>
          </Pressable>
        )}
      </View>
    </View>
  );
};

const styles = {
  title_s: [MainStyle.c_lightblue, MainStyle.f_w_600, MainStyle.f_s_20, MainStyle.t_a_c, MainStyle.m_b_62],
  title_l: [MainStyle.c_lightblue, MainStyle.f_w_700, MainStyle.f_s_56, MainStyle.t_a_c, MainStyle.m_b_125],

  subtitle_s: [
    MainStyle.max_w_312, MainStyle.t_a_c, MainStyle.c_white, MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.h_90,
    MainStyle.m_b_28
  ],
  subtitle_l: [
    MainStyle.max_w_570, MainStyle.t_a_c, MainStyle.c_white, MainStyle.f_w_600, MainStyle.f_s_32, MainStyle.h_159,
    MainStyle.m_b_60
  ],

  secondary_s: [
    MainStyle.t_a_c, MainStyle.c_white, MainStyle.f_w_400, MainStyle.f_s_16, MainStyle.h_72, MainStyle.m_b_101
  ],
  secondary_l: [
    MainStyle.t_a_c, MainStyle.c_white, MainStyle.f_w_600, MainStyle.f_s_32, MainStyle.h_72, MainStyle.m_b_112
  ],

  label_s: [MainStyle.f_w_600, MainStyle.f_s_10, MainStyle.c_white, MainStyle.m_l_8],
  label_l: [MainStyle.f_w_600, MainStyle.f_s_20, MainStyle.c_white, MainStyle.m_l_22],

  btn: [MainStyle.w_480, MainStyle.h_80]
};

export default React.memo(KycTerm);
