import React, {useCallback, useContext, useEffect, useLayoutEffect, useState} from "react";
import {FlatList, Platform, useWindowDimensions} from "react-native";
import SupportGuideHeader from "../components/ SupportGuideHeader";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import {StoreContext} from "../components/StoreContextProvider";
import {TransactionHistory, User} from "../../generated/smg_service_pb";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import TransactionHistoryItem from "../components/TransactionHistoryItem";
import MainStyle from "../../MainStyle";
import TransactionHistoryHeader from "../components/TransactionHistoryHeader";
import {LANDING_PAGE_URL, SESSION_EXPIRE_TIME, breakpoint} from "../constants";
import SupportGuideButtonMenu from "../components/SupportGuideButtonMenu";

const TransactionHistoryScreen = () => {
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user, session} = Context;
  const [historyList, setHistoryList] = useState<TransactionHistory.AsObject[]>([]);
  const {width} = useWindowDimensions();
  const isLg = width >= breakpoint;
  const isFocused = useIsFocused();

  const getTransactionHistory = () => {
    Context.setLoadingOverlay(true);

    Context.getTransactionHistory().then(res => {
      Context.setLoadingOverlay(false);

      res = res.reverse().map((el: any, i: any) => {
        el.i = i + 1;
        return el;
      });

      res.reverse();
      setHistoryList(res);

    }).catch(err => {
      console.log('getTransactionHistory err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: err.message,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
        }
      });
    });
  };

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    const time = setInterval(() => {
      (async () => {
        const lastActiveTime = await AsyncStorage.getItem('lastActiveTime');

        if (lastActiveTime && moment().diff(moment(lastActiveTime), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if(Platform.OS === 'web'){
              window.open(LANDING_PAGE_URL, '_self');
            }
            Context.setLoadingOverlay(false);
          });
        }
      })();
    }, 1000 * 60);

    return () => {
      clearInterval(time);
    };
  }, [isFocused]);

  useEffect(() => {
    if (user) getTransactionHistory();
  }, [user]);

  useLayoutEffect(() => {
    Context.setLoadingOverlay(true);

    if (!session || !user) {
      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if(Platform.OS === 'web'){
              window.open(LANDING_PAGE_URL, '_self');
            }
            Context.setLoadingOverlay(false);
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);

            } catch (e: any) {
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
              });
            }
          }

          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if(Platform.OS === 'web'){
                window.open(LANDING_PAGE_URL, '_self');
              }
              Context.setLoadingOverlay(false);
            });
          }
        }
      })();
    }
  }, []);

  return (
    <>
      <SupportGuideHeader title="Transaction History" onExit={onExit}/>

      <FlatList
        style={MainStyle.bg_contest}
        data={historyList}
        renderItem={({item}) => <TransactionHistoryItem item={item} isLg={isLg}/>}
        keyExtractor={item => item.amount + item.lastUpdateTime + item.time + item.winAmount + item.status}
        ListHeaderComponent={<TransactionHistoryHeader/>}
      />

      <SupportGuideButtonMenu isSm={!isLg} onExit={onExit}/>
    </>
  );
};

export default React.memo(TransactionHistoryScreen);
