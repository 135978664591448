import React from "react";
import {Text, View, Pressable} from "react-native";
import MainStyle from "../../MainStyle";
import TournamentHeader from "./TournamentHeader";
import PrizePool from "./PrizePool";
import {Tournament, TournamentPlayerDetails} from "../../generated/smg_service_pb";
import {useNavigation} from "@react-navigation/native";

type Props = {
  item: Tournament.AsObject, w?: string | number, mx?: string | number, mb?: string | number, getUser: () => void
};

const TournamentItem = (props: Props) => {
  const {item, w, mx, mb} = props;
  const navigation: any = useNavigation();
  const registered = !!item.currentplayerdetails && item.currentplayerdetails.state !== TournamentPlayerDetails.State.FINISHED;

  return (
    <View style={[...styles[registered ? 'wrap_r' : 'wrap'], {marginHorizontal: mx, width: w, marginBottom: mb}]}>
      <View style={[MainStyle.h_18, MainStyle.m_b_18, registered && MainStyle.bg_newGreen]}>
        {registered && (
          <Text style={[MainStyle.t_a_c, MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.l_h_18]}>
            REGISTERED
          </Text>
        )}
      </View>

      <TournamentHeader mb={16} item={item} onReload={props.getUser}/>

      <PrizePool
        mx={18} maxH={206} state={item?.state}
        prizePoolList={item.prizepoolList} playerDetailsList={item.playerdetailsList}
      />

      <Pressable style={MainStyle.m_x_auto} onPress={() => navigation.navigate('TournamentDetails', {id: item.id})}>
        <Text style={styles.details_btn_text}>View Details</Text>
      </Pressable>
    </View>
  );
};

const styles = {
  wrap: [MainStyle.b_r_20, MainStyle.b_c_darkText, MainStyle.bg_black, MainStyle.b_w_1],
  wrap_r: [
    MainStyle.b_c_newGreen, MainStyle.bg_black, MainStyle.b_w_1, MainStyle.b_b_r_20, MainStyle.b_t_r_9,
    MainStyle.o_hidden
  ],
  details_btn_text: [
    MainStyle.l_h_33, MainStyle.c_new_green_text, MainStyle.Barlow700, MainStyle.f_s_14, MainStyle.t_t_u
  ]
};

export default React.memo(TournamentItem);
