import React from "react";
import {Text, useWindowDimensions, View} from "react-native";
import {preventSelect, tabletBreakpoint} from "../constants";
import MainStyle from "../../MainStyle";
import {Div} from "react-native-magnus";

type Props = {
  label?: string, value?: string, dense?: boolean, minW?: string | number, w?: string | number, mb?: string | number,
  textAlign?: 'left' | 'center' | 'right'
};

const DistanceChallengeItem = (props: Props) => {
  const {label, value, dense, minW, w, mb, textAlign = 'left'} = props;
  const {width} = useWindowDimensions();
  const isMd = width >= tabletBreakpoint;

  return (
    <Div row minW={minW} alignItems={dense ? undefined : 'center'} w={w} mb={mb}>
      <Div pt={dense ? 8 : 0}><View style={styles[isMd ? 'dot_m' : 'dot_s']}/></Div>

      <Div flexDir={dense ? 'column' : 'row'} ml={isMd ? 12 : 9} flex={1} alignItems={dense ? undefined : 'center'}
           minW="100%">
        <Text style={[...styles[isMd ? 'contest_label_m' : 'contest_label_s'], preventSelect, {textAlign}]}>
          {label}
        </Text>

        <Text style={[...(isMd ? [...styles.contest_value_m, dense ? {textAlign} : MainStyle.t_a_c] : [...styles.contest_value_s, dense ? {textAlign} : MainStyle.t_a_c]), preventSelect]}>
          {value}
        </Text>
      </Div>
    </Div>
  );
};

const styles = {
  dot_s: [MainStyle.w_6, MainStyle.h_6, MainStyle.b_r_2, MainStyle.bc_green],
  dot_m: [MainStyle.w_8, MainStyle.h_9, MainStyle.b_r_2, MainStyle.bc_green, MainStyle.m_t_6],
  dot_l: [MainStyle.w_12, MainStyle.h_12, MainStyle.b_r_4, MainStyle.bc_green],
  contest_label_s: [MainStyle.f_w_700, MainStyle.f_s_16, MainStyle.c_white, MainStyle.l_h_20, MainStyle.flex1],
  contest_label_m: [MainStyle.f_w_700, MainStyle.f_s_22, MainStyle.c_white, MainStyle.l_h_40, MainStyle.flex1],
  contest_label_l: [
    MainStyle.f_w_700, MainStyle.f_s_32, MainStyle.l_h_32, MainStyle.c_white, MainStyle.t_t_u, MainStyle.flex1
  ],
  contest_value_s: [MainStyle.f_w_700, MainStyle.f_s_18, MainStyle.c_white, MainStyle.l_h_22, MainStyle.flex1],
  contest_value_m: [MainStyle.f_w_700, MainStyle.f_s_28, MainStyle.c_white, MainStyle.l_h_22, MainStyle.flex1],
  contest_value_l: [
    MainStyle.f_w_700, MainStyle.f_s_40, MainStyle.l_h_47, MainStyle.c_white, MainStyle.flex1, MainStyle.t_a_c
  ]
};

export default React.memo(DistanceChallengeItem);
