import React, {useCallback, useContext} from "react";
import {Div, ScrollDiv} from "react-native-magnus";
import {Linking, Platform, Text, useWindowDimensions, View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import {StoreContext} from "../components/StoreContextProvider";
import {breakpoint, smgEnvironment} from "../constants";
import SupportGuideHeader from "../components/ SupportGuideHeader";
import MainStyle from "../../MainStyle";
import {colors} from "../assets/styles/colors";
import SupportGuideButtonMenu from "../components/SupportGuideButtonMenu";

const PrivacyPolicyScreen = () => {
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {width} = useWindowDimensions();
  const isSm = width < breakpoint;

  const scrollIntoView = (id: string) => () => {
    if (Platform.OS === 'web') {
      document.getElementById(id)?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }
  };

  const openLink = (url: string) => {
    if (Platform.OS === 'web') window.open(url, '_blank');
    else Linking.openURL(url).then();
  };

  const onExit = useCallback(() => {
    navigation.navigate('challenges');
    Context.setIsHelpModalVisible(false);
  }, []);

  return (
    <>
      <SupportGuideHeader title="Privacy Policy" onExit={onExit}/>

      <ScrollDiv bg={colors.bg_contest} flex={1} pb={isSm ? 27 : 92}>
        <Div maxW={1140} w="100%" alignSelf="center" pt={isSm ? 17 : 27} px={isSm ? 40 : 0}>
          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Introduction
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Super Money Games dba SkillMoneyGames, ("<Text style={MainStyle.Barlow700}>Company</Text>" or "<Text
            style={MainStyle.Barlow700}>We</Text>" or “<Text style={MainStyle.Barlow700}>Us</Text>”) respects
            your privacy and is committed to protecting it through our compliance with this policy.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            This policy describes the types of information we may collect from you or that you may provide when you
            visit the website <Text style={styles.small_link}
                                    onPress={() => openLink(`https://about.skillmoney.com/?env=${smgEnvironment}`)}>www.SKILLMONEY.com</Text>,
            (our "<Text style={MainStyle.Barlow700}>Website</Text>"), our mobile applications (“<Text
            style={MainStyle.Barlow700}>Applications</Text>”), physical locations, including but not limited to
            physical kiosks (“<Text style={MainStyle.Barlow700}>Kiosks</Text>”) including social networking
            features (collectively “<Text style={MainStyle.Barlow700}>Services and/or Challenges</Text>”) and our
            practices for collecting, using, maintaining, protecting, and disclosing that information.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>This policy applies to information we collect:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>On our Website.</Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              In email, text, and other electronic messages between you and Us, whether direct email or text
              communications or through our Website, Applications or Kiosks.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              In person at our Kiosks, in order to perform Know Your Customer (“KYC”) obligations and verification that
              it is you.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Through mobile and desktop applications you download from our Website, which provide dedicated
              non-browser-based interaction between you and us.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>It does not apply to information collected by:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Us offline (except as noted above) or through any other means, including on any other website operated by
              Company or any third party; or
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Any third party, including through any application or content (including advertising) that may link to or
              be accessible from or on our Website, App or Kiosk.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Please read this policy carefully to understand our policies and practices regarding your information and
            how we will treat it. <Text style={MainStyle.Barlow700}>If you do not agree with our policies and
            practices, your choice is not to use our Services. By accessing or using our Services, you agree to this
            privacy policy. This policy may change from time to time (see <Text style={MainStyle.t_d_l_u}
                                                                                onPress={scrollIntoView('ChangesToOurPrivacyPolicy')}>Changes
              to Our Privacy Policy</Text>). Your continued use of our Services after we make changes is deemed to be
            acceptance of those changes, so please check the policy periodically for updates.</Text>
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Person Under the Age of 18
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Our Services are not intended for persons under 18 years of age. No one under age 18 may provide any
            information to us in connection with our Services. We do not knowingly collect personal information from
            persons under 18. If you are under 18, do not use or provide any information to us in connection with
            Services offered. If we learn we have collected or received personal information from a person under 18 we
            will delete that information. If we determine that a customer is under the age of 18, we will immediately
            terminate the associated account and delete any information provided. If you believe we might have any
            information from or about a person under 18, please contact us at:
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
            Super Money Games, 2575 S Highland Dr, Las Vegas, 89109
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            <Text style={styles.small_link} onPress={() => openLink('mailto:support@skillmoney.com')}>
              support@skillmoney.com
            </Text>
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            California residents under 16 years of age may have additional rights regarding the collection and sale of
            their personal information. Please see Your California Privacy Rights for more information. SkillMoneyGames
            does not knowingly collect any information from person under the age of 18.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Information We Collect About You and How We Collect It
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We collect several types of information from and about users of our Services, including information:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              By which you may be personally identified, such as first and last name, postal address, e-mail address,
              telephone number, IP address, browser type, account information, such as usernames and passwords, usage
              data, device identifying information, or any other identifier by which you may be contacted online or
              offline ("<Text style={MainStyle.Barlow700}>personal information</Text>");
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Face scans for know your customer (“KYC”) purposes and then to match when you log in and to confirm you
              are playing (“<Text style={MainStyle.Barlow700}>biometric information</Text>”);
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              You may decide to share your first and last name, date of birth, location, email address, phone number,
              username and password, and the state in which you reside when you create an account to log in to our
              network ("Account"). We need this information to set up your Account, verify your identity, and provide
              you with our Services or Challenges;
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              When you use our Website, Application(s), Kiosks or Services or Challenges, or deposit or withdraw funds
              from your Account, we may collect all information necessary to complete the transaction and provide you
              with the Services, including your name, address, email address, phone number, credit or debit card
              information and other billing information, social security number, tax documents, and records of the
              products and Services or Challengeses you purchased, obtained, or considered;
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If you provide us feedback or contact us via email, we will collect your name and email address, as well
              as any other content provided in the email, in order to send you a reply, provide customer and technical
              support, improve and optimize our Services, satisfy our legal obligations, enforce our contracts, and
              address any existing or anticipated disputes;
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              We will collect certain biometric information which you may upload, including, but not limited to a
              profile photo, with data derived from the photo you upload and username you choose for your public profile
              page including in connection with our Services. When you utilize our Services or Challenges, other users may
              be able to see the information you selected for your Profile.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If you tell us where you are (e.g., by allowing your mobile device to send us your location or by
              otherwise permitting us to geolocate you), we may store and use that information to verify your
              eligibility to use our Services, to comply with relevant laws, and for fraud prevention purposes.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              That is about you but individually does not identify you; and/or
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              About your internet connection, the equipment you use to access our Services, and usage details.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Biometric information (see Biometric Privacy Policy for additional information), if you have affirmatively
              consented to our collection of such information.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>We collect this information:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Directly from you when you provide it to us.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Automatically as you navigate through the site, app, kiosk or other platform associated with our Services.
              Information collected automatically may include usage details, IP addresses, and information collected
              through cookies and web beacons.
            </Text>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Information You Provide to Us.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The information we collect on or through our Website, App or Kiosks may include:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Information that you provide by filling in forms. This includes information provided at the time of
              registering to use our Services or Challenges, subscribing to our service, or requesting further services.
              We may also ask you for information when you report a problem with our Website, App, Kiosk or other
              platform.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Information that you provide within SkillMoneyGames or to establish an account.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Records and copies of your correspondence (including email addresses), if you contact us or provide us
              with feedback.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Details of transactions you carry out through our Services and of the fulfillment of your participation in
              any Services.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            You also may provide information to be published or displayed (hereinafter, "<Text
            style={MainStyle.Barlow700}>posted</Text>") on public areas of the Website, App, Kiosk, or other
            platform, or transmitted to other users of the Website or third parties (collectively, "<Text
            style={MainStyle.Barlow700}>User Contributions</Text>"). Your User Contributions are posted on and
            transmitted to others at your own risk. Please be aware that no security measures are perfect or
            impenetrable. Additionally, we cannot control the actions of other users of the Website, App, Kiosk or other
            platform with whom you may choose to share your User Contributions. Therefore, we cannot and do not
            guarantee that your User Contributions will not be viewed by unauthorized persons.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Information We Collect Through Automatic Data Collection Technologies.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            As you navigate through and interact with our Website, App, Kiosk or other platform, we may use automatic
            data collection technologies to collect certain information about your equipment, browsing actions, and
            patterns, including:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Details of your visits to our Website, App, Kiosk or other platform, including traffic data, location
              data, logs, and other communication data and the resources that you access and use on the Website.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Information about your computer, device and internet connection, including the hardware models, operating
              systems and versions, software, file names and versions, preferred languages, unique device identifiers,
              advertising identifiers, serial numbers, device motion information, and mobile network information. If you
              have downloaded our mobile Application(s), we may also collect certain information from your mobile
              device, including information about how you use the Application(s). This information is generally used to
              help us deliver relevant information to you. In the event our Application crashes on your mobile device,
              we will also receive information about your mobile device model software version and device carrier, which
              allows us to identify and fix bugs, provide other technical support, and improve and optimize the
              performance of our Application(s).
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              In order to improve, personalize, and optimize our Services and meet our legal obligations, we may
              automatically track certain information about your visits to and usage of our Website, Applications, and
              Services, including your browser type, operating system, Internet Protocol (IP) address (a number that is
              automatically assigned to your computer when you use the Internet, which may vary from session to
              session), device identifier, domain name, clickstream data, referring/exist pages, geolocation, and a
              date/time stamp for your visit. We also use this information to analyze trends, administer the Website,
              gather demographic information about our user base, deliver advertising, perform research, provide
              technical and customer support, inform our product development efforts, protect our legal rights, address
              disputes, and prevent our Services from being used to commit harmful or unlawful activities, including
              fraud. Some of this information may also be collected so that when you use the Website, Applications, or
              Services again, we will be able to recognize you and optimize your experience accordingly.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The information we collect automatically may include personal information, or we may maintain it or
            associate it with personal information we collect in other ways or receive from third parties. It helps us
            to improve our Services and to deliver a better and more personalized service, including by enabling us to:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Estimate our audience size and usage patterns.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Store information about your preferences, allowing us to customize our Services according to your
              individual interests.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>Speed up your searches.</Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Recognize you when you return to one of our platforms.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The technologies we use for this automatic data collection may include:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              <Text style={MainStyle.Barlow700}>Cookies (or browser cookies)</Text>. A cookie is a small file
              placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the
              appropriate setting on your browser. However, if you select this setting, you may be unable to access
              certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse
              cookies, our system will issue cookies when you direct your browser to our Website.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              <Text style={MainStyle.Barlow700}>Flash Cookies</Text>. Certain features of our Website may use local
              stored objects (or Flash cookies) to collect and store information about your preferences and navigation
              to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for
              browser cookies. For information about managing your privacy and security settings for Flash cookies,
              see <Text style={MainStyle.t_d_l_u} onPress={scrollIntoView('disclose')}>Choices About How We Use and
              Disclose Your Information</Text>.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              <Text style={MainStyle.Barlow700}>Web Beacons</Text>. Pages of our Website may contain small
              electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs)
              that permit the Company, for example, to count users who have visited those pages and for other related
              website statistics (for example, recording the popularity of certain website content and verifying system
              and server integrity).
            </Text>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u]}>
            What to do if you don’t want cookies to be set or want them to be removed?
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            If you do not want to receive cookies, you can change your browser settings on your computer or other device
            you’re using to access our services. If you use our Website without changing your browser settings, or if
            you click the “Accept” button on our website page regarding cookies, we’ll assume that you’re happy to
            receive all cookies on the Website and that you have provided consent by such actions or conduct. Most
            browsers also provide functionality that lets you review and erase cookies, including our Website cookies.
            Please note that the Website may not work properly without cookies.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Third-Party Use of Cookies
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Some content or applications, including advertisements, on the Website are served by third parties,
            including advertisers, ad networks and servers, content providers, and application providers. These third
            parties may use cookies to collect information about you when you use our website. The information they
            collect may be associated with your personal information or they may collect information, including personal
            information, about your online activities over time and across different websites and other online services.
            They may use this information to provide you with interest-based (behavioral) advertising or other targeted
            content.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We do not control these third parties' tracking technologies or how they may be used. If you have any
            questions about an advertisement or other targeted content, you should contact the responsible provider
            directly. For information about how you can opt out of receiving targeted advertising from many providers,
            see <Text style={MainStyle.t_d_l_u} onPress={scrollIntoView('disclose')}>Choices About How We Use and
            Disclose Your Information</Text>.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            How We Use Your Information
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We use information that we collect about you or that you provide to us, including any personal information:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To present our Website and our Services or Challenges to you.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To provide you with SkillMoneyGames.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To improve our Website and/or SkillMoneyGames.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To provide you with other Super Money Games products and/or services.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To provide you with information, products or services that you request from us.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To notify you about changes to our website and/or SkillMoneyGames.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To allow you to participate in interactive features on our website and/or SkillMoneyGames.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>To provide customer support.</Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>For Internal recordkeeping.</Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>For Market research.</Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To investigate, deter, and protect against fraudulent, unauthorized or illegal activity.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To inform you about promotions, events, and news related to our Website and SkillMoneyGames.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To contact you with respect to our website and SkillMoneyGames.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              In any other way we may describe when you provide the information.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To fulfill any other purpose for which you provide it.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              For any other purpose with your consent.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              We may also use your information to contact you about goods and services that may be of interest to you.
              For more information, see <Text style={MainStyle.t_d_l_u} onPress={scrollIntoView('disclose')}>Choices
              About How We Use and Disclose Your Information</Text>.
            </Text>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Disclosure of Your Information
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We may disclose aggregated information about our users, and information that does not identify any
            individual, without restriction.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We may disclose personal information that we collect or you provide as described in this privacy policy:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To our subsidiaries and affiliates.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To our employees on a need-to-know basis.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To contractors, service providers, and other third parties we use to support our business and who are
              bound by contractual obligations to keep personal information confidential and use it only for the
              purposes for which we disclose it to them.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of SkillMoneyGames’ assets, whether as a going
              concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held
              by Super Money Games about our Service users is among the assets transferred.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To fulfill the purpose for which you provide it.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If your earnings from Challenges exceed a certain threshold, in accordance with our interpretation of
              applicable law, we will disclose the amount of your earnings and related information to the United States
              Internal Revenue Service and other appropriate taxing authorities.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              From time to time, we may also need to share your information with professional advisers, including
              lawyers, auditors, bankers, insurers and consultants, in order to obtain advice or other professional
              services from them. Such advisers are bound by legal and/or contractual duties of confidentiality and
              other prohibitions against disclosure of personal information.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Co-Branded Partners are companies with whom we may jointly offer a service or feature. You can tell when
              you are accessing a service or feature offered by a CoBranded Partner because the Co-Branded Partner's
              name will be featured prominently. You may be asked to provide information about yourself to register for
              a service offered by a CoBranded Partner. In doing so, you may be providing your information to both us
              and the CoBranded Partner, or we may share your information with the Co-Branded Partner. Please note that
              the Co-Branded Partner's privacy policy may also apply to its use of your information. We are not
              responsible for the practices used by Co-Branded Partners. In addition, by using our Website,
              Application(s), and Services, you acknowledge and agree that we are not responsible for and do not have
              control over any Co-Branded Partners.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              For any other purpose disclosed by us when you provide the information.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>With your consent.</Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>We may also disclose your personal information:</Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              To comply with any court order, law, or legal process, including to respond to any government or
              regulatory request.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              and other agreements, including for billing and collection purposes.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Super
              Money Games, our customers, or others.
            </Text>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}
                nativeID="disclose">
            Choices About How We Use and Disclose Your Information
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We strive to provide you with choices regarding the personal information you provide to us. We have created
            mechanisms to provide you with the following control over your information:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              <Text style={MainStyle.Barlow700}>Tracking Technologies and Advertising</Text>. You can set your
              browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable
              or refuse cookies, please note that some parts of this site may then be inaccessible or not function
              properly.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              <Text style={MainStyle.Barlow700}>Disclosure of Your Information for Third-Party Advertising</Text>.
              If you do not want us to share your personal information with unaffiliated or non-agent third parties for
              promotional purposes, you can opt out by checking the relevant box located on the form on which we collect
              your data (the order/registration form) or Contact by email or phone. You can also always opt out by
              logging into the Website and adjusting your user preferences in your account profile, by checking or
              unchecking the relevant boxes or by sending us an email stating your request to <Text
              style={styles.small_link}
              onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              <Text style={MainStyle.Barlow700}>Promotional Offers from the Company</Text>. If you do not wish to
              have your contact information used by the Company to promote our own or third parties' products or
              services, you can opt out by sending us an email stating your request to <Text style={styles.small_link}
                                                                                             onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
              If we have sent you a promotional email, you may send us a return email asking to be omitted from future
              email distributions.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We do not control third parties' collection or use of your information to serve interest- based advertising.
            However, these third parties may provide you with ways to choose not to have your information collected or
            used in this way. You can opt out of receiving targeted ads from members of the Network Advertising
            Initiative ("<Text style={MainStyle.Barlow700}>NAI</Text>") on the NAI's <Text
            style={styles.small_link}
            onPress={() => openLink('http://www.networkadvertising.org/managing/opt_out.asp')}>website</Text>.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Third Party Links On Our Site
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u]}>
            SkillMoneyGames and/or our Website may contain links to other third party Websites for your convenience. We
            are not responsible for the privacy policies and practices of other third parties and any information you
            submit to a third party is subject to their privacy policy. Once you leave our SkillMoneyGames or our
            Website, or are otherwise redirected to a third-party Website, application, or other online service, we
            encourage you to read the privacy policy applicable to that third party.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Opt-Out Of Communications With Super Money Games
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u]}>
            You may opt-out of receiving promotional e-mail communication from us by sending us an e-mail at <Text
            style={styles.small_link}
            onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>, or by clicking the
            “unsubscribe” button at the bottom of our emails that we send to you. Please note that you will not be able
            to opt-out of transactional e-mail communication, such as communication related to your account activities.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Data Security
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We have implemented measures designed to secure your personal information from accidental loss and from
            unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our
            secure servers behind firewalls.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The safety and security of your information also depends on you. Where we have given you (or where you have
            chosen) a password for access to certain parts of our Service, you are responsible for keeping this password
            confidential. We ask you not to share your password with anyone.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Unfortunately, the transmission of information via the internet or other electronic method is not completely
            secure. Although we do our best to protect your personal information, we cannot guarantee the security of
            your personal information transmitted to us. Any transmission of personal information is at your own risk.
            We are not responsible for circumvention of any privacy settings or security measures contained on the
            Website.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}
                nativeID="ChangesToOurPrivacyPolicy">
            Changes to Our Privacy Policy
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            It is our policy to post any changes we make to our privacy policy on this page with a notice that the
            privacy policy has been updated on the Website home page. If we make material changes to how we treat our
            users' personal information, we will notify you. The date the privacy policy was last revised is identified
            at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email
            address for you, and for periodically visiting our Website and this privacy policy to check for any changes.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Nevada provides its residents with a limited right to opt-out of certain personal information sales.
            Residents who wish to exercise this sale opt-out rights may submit a request to this designated
            address: <Text style={styles.small_link}
                           onPress={() => navigation.navigate('Notifications')}>www.skillmoney.com/notifications</Text>.
            However, please know we do not currently sell data triggering that statute's opt-out requirements.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.t_d_l_u, MainStyle.Barlow700]}>
            Contact Information
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            To ask questions or comment about this privacy policy and our privacy practices, contact us at: <Text
            style={styles.small_link}
            onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>
          </Text>

          <Text style={isSm ? styles.title_s : styles.title_l}>BIOMETRIC PRIVACY POLICY</Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The Company has instituted the following biometric information privacy policy:
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Biometric Data Defined
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            As used in this policy, biometric data includes “biometric identifiers” and “biometric information” as
            defined in the Illinois Biometric Information Privacy Act, 740 ILCS § 14/1, et seq. “Biometric identifier”
            means a retina or iris scan, fingerprint, voiceprint, or scan of hand or face geometry. Likewise, this
            policy applies to the Texas Capture and Use of Biometric Identifier Act (“CUBI”) and the biometric
            protection laws of the State of Washington. Biometric identifiers do not include writing samples, written
            signatures, photographs, human biological samples used for valid scientific testing or screening,
            demographic data, tattoo descriptions, or physical descriptions such as height, weight, hair color, or eye
            color. Biometric identifiers do not include information captured from a patient in a health care setting or
            information collected, used, or stored for health care treatment, payment, or operations under the federal
            Health Insurance Portability and Accountability Act of 1996. “Biometric information” means any information,
            regardless of how it is captured, converted, stored, or shared, based on an individual’s biometric
            identifier used to identify an individual. Biometric information does not include information derived from
            items or procedures excluded under the definition of biometric identifiers.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Purpose for Collection of Biometric Data
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The Company and/or its vendors, may collect biometric data for purposes of the Services to be provided,
            including, but not limited to, identification of users and confirming identity of any party using the
            Services provided.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Disclosure and Authorization
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            To the extent that the Company or its vendors, capture, or otherwise obtain biometric data relating to a
            user, the Company must first:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Inform the user in writing that the Company and its vendors, are collecting, capturing, or otherwise
              obtaining the user’s biometric data, and that the Company is providing such biometric data to its vendors;
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Inform the users in writing of the specific purpose and length of time for which the user’s biometric data
              is being collected, stored, and used; and
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Receive a written release acknowledged and executed by the user (or his or her legally authorized
              representative) authorizing the Company and its vendors to collect, store, and use the user’s biometric
              data for the specific purposes disclosed by the Company, and for the Company to provide such biometric
              data to its vendors.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The Company and its vendors will not sell, lease, trade, or otherwise profit from users’ biometric data.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Disclosure
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The Company will not disclose or disseminate any biometric data to anyone other than its vendors providing
            products and services using biometric data without/unless:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(a)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              First obtaining written user consent to such disclosure or dissemination;
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(b)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The disclosed data completes a financial transaction requested or authorized by the user;
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(c)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Disclosure is required by state or federal law or municipal ordinance; or
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>(d)</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Disclosure is required pursuant to a valid warrant or subpoena issued by a court of competent
              jurisdiction.
            </Text>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Retention Schedule
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The Company shall retain user’s biometric data only until, and shall request that its vendors permanently
            destroy such data when, the first of the following occurs:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              The initial purpose for collecting or obtaining such biometric data has been satisfied, such as the
              termination of the business relationship with User; or
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Within 3 years of the User's last interaction with the Company, unless a shorter period is required by
              law.
            </Text>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Data Storage
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            The Company shall use a reasonable standard of care to store, transmit and protect from disclosure any paper
            or electronic biometric data collected. Such storage, transmission, and protection from disclosure shall be
            performed in a manner that is the same as or more protective than the manner in which the Company stores,
            transmits and protects from disclosure other confidential and sensitive information, including personal
            information that can be used to uniquely identify an individual or an individual’s account or property, such
            as genetic markers, genetic testing information, account numbers, PINs, driver’s license numbers and social
            security numbers.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Your California Privacy Rights
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We do not sell, trade, or otherwise transfer your Personal Information to third parties, except as expressly
            stated in this Policy. California Civil Code Section § 1798.83 permits California residents to request
            certain information regarding our use and disclosure of Personal Information to third parties. To make such
            a request, please send an e-mail to <Text style={styles.small_link}
                                                      onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.{`\n`}Further,
            the California Consumer Privacy Act of 2018, as amended (Cal. Civ. Code §§ 1798.100 to 1798.199) (“CCPA”),
            does not apply to us. If you have any questions or concerns about the CCPA or its non-applicability to us,
            please e-mail us at <Text style={styles.small_link}
                                      onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
            California “Shine the Light” Law
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Separate from CCPA, California residents may, pursuant to California Civil Code Section 1798.83 (the
            California “Shine the Light” law), request certain information regarding our disclosure (if any) of personal
            data to third parties for their direct marketing purposes. To make such a request, please contact us,
            identify yourself as a California resident and provide sufficient information so we can take appropriate
            action, such as your name, email address or any additional information required.
          </Text>

          <Text style={[...styles[isSm ? 'title_s' : 'title_l'], MainStyle.m_b_0]}>
            CALIFORNIA-SPECIFIC PRIVACY RIGHTS (for California residents only)
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>California Consumer Privacy Act (CCPA)</Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            This California Privacy Notice supplements the Privacy Policy (“Policy”) and contains the disclosures
            required under the California Consumer Privacy Act (CCPA). For individuals who are California residents, the
            CCPA requires certain disclosures about the categories of personal information we collect and how we use it,
            the categories of sources from whom we collect personal information, and the third parties with whom we
            share it. You have also the right to exercise certain rights regarding your personal information.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Please note that for California residents, the term personal information means information that identifies,
            relates to, describes, is reasonably capable of being associated with or could reasonably be linked,
            directly or indirectly, with a particular consumer or household as defined in the CCPA. All other
            capitalized terms have the same meanings as given them in the Policy.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Data Subject Requests
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            If you are a California resident who has provided personal information to us, or a California resident that
            reasonably believes that we have collected or stored their personal information, you have the right to
            request Company to:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Disclose the categories of personal information we collected about you in the preceding twelve months;
              categories of sources from which the personal information was collected; the business or commercial
              purpose for which we collected or sold the personal information; the categories of third parties with
              which we have shared your personal information; categories of personal information that we sold in the
              preceding twelve months, and for each category identified, categories of third parties to which we sold
              that particular category of personal information; and the categories of personal information that we
              disclosed for a business purpose in the preceding twelve months, and for each category identified, the
              categories of third parties to whom we disclosed that particular category of personal information
              (“Request to Know”);
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Disclose the specific pieces of personal information that we have collected on you (“Request to Know”);
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Delete personal information we have collected on you (“Request to Delete”); and
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Opt out of the sale of the personal information we have collected on you (“Right to Opt Out”).
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            You can only make two Requests to Know in a twelve-month period, and the information provided need only
            cover the twelve-month period prior to receipt of your request.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Methods for Submitting Request
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            You may submit Requests to Know and Requests to Deletein one of the following methods: (a) writing to us
            at <Text style={MainStyle.Barlow700}>2575 S Highland Dr, Las Vegas, NV 89101</Text> or (b) by sending
            an email to <Text style={styles.small_link}
                              onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Verification of Requests
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We will need to verify your identity to respond to Requests to Delete and Requests to Know. If you have an
            account with us that is password-protected, we may verify your identity through our existing authentication
            practices for your account.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            If you do not have an account with us, and your request concerns “categories” of personal information
            collected, we can request from you two data points of personal information to verify your identity. If you
            do not have an account with us, and your request concerns specific pieces of personal information, we can
            request from you at least three data points of personal information as well as a signed declaration made
            under penalty of perjury to verify your identity.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            If a request concerns household information, and the household does not have a password- protected account
            with us, we will not comply with a Request to Know specific pieces of personal information about the
            household or a Request to Delete household personal information unless all of the following conditions are
            satisfied: (1) all consumers of the household jointly request access to specific pieces of information for
            the household or the deletion of household personal information; (2) we have individually verified all the
            members of the household under the CCPA; and (3) we have verified that each member making the request is
            currently a member of the household.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Time for Response
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Please note that once you have submitted a Request to Know or Request to Delete, we will send you a receipt,
            acknowledging your request, within ten business days. If, for some reason, you do not receive such a receipt
            within ten days of your submitted request, please send us an email to <Text style={styles.small_link}
                                                                                        onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text> as
            an error may have occurred.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We will respond to Requests to Know and Requests to Delete within forty-five calendar days after they are
            received. If we cannot verify you within the forty-five-day time period, we may deny the request. If
            necessary, we may take up to an additional forty-five calendar days to respond to the consumer’s request,
            for a maximum total of ninety calendar days from the day the request is received, provided that we provide
            you with notice and an explanation of the reason that we will take more than forty-five days to respond to
            the request.
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            In the case of a Request to Delete, we may use a two-step process for online requests to delete where you
            must first, submit the Request to Delete and then second, separately confirm that you want your personal
            information deleted.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Notice of Opt Out
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            You have the right to opt out of the sale of your personal information by us. To exercise this right, you
            can use this form or send an email to <Text style={styles.small_link}
                                                        onPress={() => openLink('mailto:dataprotection@skillmoney.com')}>dataprotection@skillmoney.com</Text>.
            We will comply with a Request to Opt Out as soon as feasibly possible, but no later than fifteen business
            days from the date we received the request.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Authorized Agent
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            As a California resident, you have the right to designate an agent to exercise these rights on your behalf.
            We may require proof that you have designated the authorized agent to act on your behalf and to verify your
            identity directly with us. Please contact us at <Text style={styles.small_link}
                                                                  onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text> for
            more information if you wish to submit a request through an authorized agent.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Right to Non-Discrimination for the Exercise of CCPA Rights
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA,
            we will not:
          </Text>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>Deny you goods or services.</Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Charge you different prices or rates for goods or services, including through granting discounts or other
              benefits, or imposing penalties.
            </Text>
          </View>

          <View style={MainStyle.row}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Provide you a different level or quality of goods or services.
            </Text>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={isSm ? styles.dot_s : styles.dot_l}>&#8226;</Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0]}>
              Suggest that you may receive a different price or rate for goods or services or a different level or
              quality of goods or services.
            </Text>
          </View>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            However, we may offer you certain financial incentives permitted by the CCPA that <Text
            style={MainStyle.Barlow700}>can result</Text> in different prices, rates, or quality levels. Any
            CCPA-permitted financial incentive we offer will reasonably relate to your personal information's value and
            contain written terms that describe the program's material aspects. Participation in a financial incentive
            program requires your prior opt-in consent, which you may revoke at any time.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Contact Us
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            If you have questions about our privacy policies or practices or this notice, please contact us at <Text
            style={styles.small_link}
            onPress={() => openLink('mailto:support@skillmoney.com')}>support@skillmoney.com</Text>.
          </Text>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700]}>
            Notice at Collection
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            Pursuant to California Civil Section 1798.100(b), this serves as notice of the categories of personal
            information that we collect through the Site and Services, the business or commercial purposes for which
            such personal information was collected, the categories of sources of such personal information, and the
            categories of third parties to whom such information was disclosed in the past twelve months.
          </Text>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0, MainStyle.t_a_c, {width: '20%'}]}>
              Category
            </Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0, MainStyle.t_a_c, {width: '60%'}]}>
              Examples
            </Text>
            <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.m_b_0, MainStyle.t_a_c, {width: '20%'}]}>
              Collected
            </Text>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              A. Identifiers.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol
              address, email address, account name, Social Security number, driver's license number, passport number, or
              other similar identifiers.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code §
              1798.80(e)).
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              A name, signature, Social Security number, physical characteristics or description, address, telephone
              number, passport number, driver's license or state identification card number, insurance policy number,
              education, employment, employment history, bank account number, credit card number, debit card number, or
              any other financial information, medical information, or health insurance information.{`\n`}Some personal
              information included in this category may overlap with other categories.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              C. Protected classification characteristics under California or federal law.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital
              status, medical condition, physical or mental disability, sex (including gender, gender identity, gender
              expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or
              military status, genetic information (including familial genetic information).
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              D. Commercial information.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Records of personal property, products or services purchased, obtained, or considered, or other purchasing
              or consuming histories or tendencies.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              E. Biometric information.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a
              template or other identifier or identifying information, such as, fingerprints, faceprints, and
              voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or
              exercise data.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              F. Internet or other similar network activity.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Browsing history, search history, information on a consumer's interaction with a website, application, or
              advertisement.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              G. Geolocation data.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Physical location or movements.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              H. Sensory data.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Audio, electronic, visual, thermal, olfactory, or similar information.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              I. Professional or employment- related information.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Current or past job history or performance evaluations.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, MainStyle.b_b_w_1, MainStyle.b_c_white]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section
              1232g, 34 C.F.R. Part 99)).
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Education records directly related to a student maintained by an educational institution or party acting
              on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes,
              student financial information, or student disciplinary records.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <View style={[MainStyle.row, isSm ? MainStyle.m_b_15 : MainStyle.m_b_30]}>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}>
              K. Inferences drawn from other personal information.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '60%'}]}>
              Profile reflecting a person's preferences, characteristics, psychological trends, predispositions,
              behavior, attitudes, intelligence, abilities, and aptitudes.
            </Text>
            <Text style={[...styles[isSm ? 'secondary_s' : 'secondary_l'], MainStyle.m_b_0, {width: '20%'}]}/>
          </View>

          <Text style={[...styles[isSm ? 'text_s' : 'text_l'], MainStyle.Barlow700, MainStyle.t_d_l_u]}>
            Changes to Our Privacy Policy
          </Text>

          <Text style={isSm ? styles.text_s : styles.text_l}>
            We reserve the right to amend this privacy policy at our discretion and at any time. When we make changes to
            this privacy policy, we will post the updated notice on the Website and update the notice's effective
            date. <Text style={MainStyle.Barlow700}>Your continued use of our Services following the posting of
            changes constitutes your acceptance of such changes</Text>.
          </Text>
        </Div>
      </ScrollDiv>

      <SupportGuideButtonMenu isSm={isSm} onExit={onExit}/>
    </>
  );
};

const styles = {
  text_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.m_b_30, MainStyle.l_h_36],
  text_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.m_b_15, MainStyle.l_h_16],

  link_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_24, MainStyle.t_d_l_u, MainStyle.l_h_36],
  link_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_12, MainStyle.t_d_l_u, MainStyle.l_h_16],

  small_link: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.t_d_l_u],

  secondary_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_18, MainStyle.m_b_30, MainStyle.l_h_36],
  secondary_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_10, MainStyle.m_b_15, MainStyle.l_h_16],

  title_l: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_32, MainStyle.m_b_30, MainStyle.t_t_u],
  title_s: [MainStyle.c_lightblue, MainStyle.Barlow700, MainStyle.f_s_16, MainStyle.m_b_15, MainStyle.t_t_u],

  dot_l: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_24, MainStyle.l_h_36, MainStyle.m_x_16],
  dot_s: [MainStyle.c_white, MainStyle.Barlow400, MainStyle.f_s_12, MainStyle.l_h_16, MainStyle.m_x_8]
};

export default PrivacyPolicyScreen;
