import React from 'react';
import {Pressable, Image} from 'react-native';
import checked_icon from '../assets/img/icons/checked.svg';
import unchecked from '../assets/img/icons/unchecked.svg';
import MainStyle from "../../MainStyle";

type IFProps = { checked: boolean, onChange?: () => void };

const CheckMarkCheckbox: React.FC<IFProps> = (props: IFProps) => {
  const {checked} = props;

  return (
    <Pressable onPress={props.onChange} style={[MainStyle.w_19, MainStyle.h_18, MainStyle.row, MainStyle.a_i_e]}>
      <Image
        source={(checked ? checked_icon : unchecked) as any}
        style={checked ? [MainStyle.w_21, MainStyle.h_21] : [MainStyle.w_19, MainStyle.h_18]}
      />
    </Pressable>
  );
};

export default React.memo(CheckMarkCheckbox);
