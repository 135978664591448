/**
 * @fileoverview gRPC-Web generated client stub for qb.smg
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: smg_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as smg_service_pb from './smg_service_pb';


export class SmgServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorRegisterUser = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RegisterUser',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.RegisterUserRequest,
    smg_service_pb.RegisterUserReply,
    (request: smg_service_pb.RegisterUserRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.RegisterUserReply.deserializeBinary
  );

  registerUser(
    request: smg_service_pb.RegisterUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.RegisterUserReply>;

  registerUser(
    request: smg_service_pb.RegisterUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.RegisterUserReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.RegisterUserReply>;

  registerUser(
    request: smg_service_pb.RegisterUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.RegisterUserReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RegisterUser',
        request,
        metadata || {},
        this.methodDescriptorRegisterUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RegisterUser',
    request,
    metadata || {},
    this.methodDescriptorRegisterUser);
  }

  methodDescriptorRequestResetPin = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RequestResetPin',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.RequestResetPinRequest,
    smg_service_pb.RequestResetPinReply,
    (request: smg_service_pb.RequestResetPinRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.RequestResetPinReply.deserializeBinary
  );

  requestResetPin(
    request: smg_service_pb.RequestResetPinRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.RequestResetPinReply>;

  requestResetPin(
    request: smg_service_pb.RequestResetPinRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestResetPinReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.RequestResetPinReply>;

  requestResetPin(
    request: smg_service_pb.RequestResetPinRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestResetPinReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RequestResetPin',
        request,
        metadata || {},
        this.methodDescriptorRequestResetPin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RequestResetPin',
    request,
    metadata || {},
    this.methodDescriptorRequestResetPin);
  }

  methodDescriptorResendEmailConfirmation = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ResendEmailConfirmation',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ResendEmailConfirmationRequest,
    smg_service_pb.ResendEmailConfirmationReply,
    (request: smg_service_pb.ResendEmailConfirmationRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ResendEmailConfirmationReply.deserializeBinary
  );

  resendEmailConfirmation(
    request: smg_service_pb.ResendEmailConfirmationRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ResendEmailConfirmationReply>;

  resendEmailConfirmation(
    request: smg_service_pb.ResendEmailConfirmationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResendEmailConfirmationReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ResendEmailConfirmationReply>;

  resendEmailConfirmation(
    request: smg_service_pb.ResendEmailConfirmationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResendEmailConfirmationReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ResendEmailConfirmation',
        request,
        metadata || {},
        this.methodDescriptorResendEmailConfirmation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ResendEmailConfirmation',
    request,
    metadata || {},
    this.methodDescriptorResendEmailConfirmation);
  }

  methodDescriptorConfirmEmail = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ConfirmEmail',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ConfirmEmailRequest,
    smg_service_pb.ConfirmEmailReply,
    (request: smg_service_pb.ConfirmEmailRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ConfirmEmailReply.deserializeBinary
  );

  confirmEmail(
    request: smg_service_pb.ConfirmEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ConfirmEmailReply>;

  confirmEmail(
    request: smg_service_pb.ConfirmEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ConfirmEmailReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ConfirmEmailReply>;

  confirmEmail(
    request: smg_service_pb.ConfirmEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ConfirmEmailReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ConfirmEmail',
        request,
        metadata || {},
        this.methodDescriptorConfirmEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ConfirmEmail',
    request,
    metadata || {},
    this.methodDescriptorConfirmEmail);
  }

  methodDescriptorDoResetPin = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/DoResetPin',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.DoResetPinRequest,
    smg_service_pb.DoResetPinReply,
    (request: smg_service_pb.DoResetPinRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.DoResetPinReply.deserializeBinary
  );

  doResetPin(
    request: smg_service_pb.DoResetPinRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.DoResetPinReply>;

  doResetPin(
    request: smg_service_pb.DoResetPinRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.DoResetPinReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.DoResetPinReply>;

  doResetPin(
    request: smg_service_pb.DoResetPinRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.DoResetPinReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/DoResetPin',
        request,
        metadata || {},
        this.methodDescriptorDoResetPin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/DoResetPin',
    request,
    metadata || {},
    this.methodDescriptorDoResetPin);
  }

  methodDescriptorLocateVenue = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/LocateVenue',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.LocateVenueRequest,
    smg_service_pb.LocateVenueReply,
    (request: smg_service_pb.LocateVenueRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.LocateVenueReply.deserializeBinary
  );

  locateVenue(
    request: smg_service_pb.LocateVenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.LocateVenueReply>;

  locateVenue(
    request: smg_service_pb.LocateVenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.LocateVenueReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.LocateVenueReply>;

  locateVenue(
    request: smg_service_pb.LocateVenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.LocateVenueReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/LocateVenue',
        request,
        metadata || {},
        this.methodDescriptorLocateVenue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/LocateVenue',
    request,
    metadata || {},
    this.methodDescriptorLocateVenue);
  }

  methodDescriptorCheckUserEmailExists = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/CheckUserEmailExists',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.CheckUserEmailExistsRequest,
    smg_service_pb.CheckUserEmailExistsReply,
    (request: smg_service_pb.CheckUserEmailExistsRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.CheckUserEmailExistsReply.deserializeBinary
  );

  checkUserEmailExists(
    request: smg_service_pb.CheckUserEmailExistsRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.CheckUserEmailExistsReply>;

  checkUserEmailExists(
    request: smg_service_pb.CheckUserEmailExistsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.CheckUserEmailExistsReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.CheckUserEmailExistsReply>;

  checkUserEmailExists(
    request: smg_service_pb.CheckUserEmailExistsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.CheckUserEmailExistsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/CheckUserEmailExists',
        request,
        metadata || {},
        this.methodDescriptorCheckUserEmailExists,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/CheckUserEmailExists',
    request,
    metadata || {},
    this.methodDescriptorCheckUserEmailExists);
  }

  methodDescriptorRequestChangePhoto = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RequestChangePhoto',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.RequestChangePhotoRequest,
    smg_service_pb.RequestChangePhotoReply,
    (request: smg_service_pb.RequestChangePhotoRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.RequestChangePhotoReply.deserializeBinary
  );

  requestChangePhoto(
    request: smg_service_pb.RequestChangePhotoRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.RequestChangePhotoReply>;

  requestChangePhoto(
    request: smg_service_pb.RequestChangePhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestChangePhotoReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.RequestChangePhotoReply>;

  requestChangePhoto(
    request: smg_service_pb.RequestChangePhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestChangePhotoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RequestChangePhoto',
        request,
        metadata || {},
        this.methodDescriptorRequestChangePhoto,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RequestChangePhoto',
    request,
    metadata || {},
    this.methodDescriptorRequestChangePhoto);
  }

  methodDescriptorGetChangePhotoRequests = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetChangePhotoRequests',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetChangePhotoRequestsRequest,
    smg_service_pb.GetChangePhotoRequestsReply,
    (request: smg_service_pb.GetChangePhotoRequestsRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetChangePhotoRequestsReply.deserializeBinary
  );

  getChangePhotoRequests(
    request: smg_service_pb.GetChangePhotoRequestsRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetChangePhotoRequestsReply>;

  getChangePhotoRequests(
    request: smg_service_pb.GetChangePhotoRequestsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetChangePhotoRequestsReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetChangePhotoRequestsReply>;

  getChangePhotoRequests(
    request: smg_service_pb.GetChangePhotoRequestsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetChangePhotoRequestsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetChangePhotoRequests',
        request,
        metadata || {},
        this.methodDescriptorGetChangePhotoRequests,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetChangePhotoRequests',
    request,
    metadata || {},
    this.methodDescriptorGetChangePhotoRequests);
  }

  methodDescriptorApproveChangePhotoRequest = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ApproveChangePhotoRequest',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ApproveChangePhotoRequestRequest,
    smg_service_pb.ApproveChangePhotoRequestReply,
    (request: smg_service_pb.ApproveChangePhotoRequestRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ApproveChangePhotoRequestReply.deserializeBinary
  );

  approveChangePhotoRequest(
    request: smg_service_pb.ApproveChangePhotoRequestRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ApproveChangePhotoRequestReply>;

  approveChangePhotoRequest(
    request: smg_service_pb.ApproveChangePhotoRequestRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ApproveChangePhotoRequestReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ApproveChangePhotoRequestReply>;

  approveChangePhotoRequest(
    request: smg_service_pb.ApproveChangePhotoRequestRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ApproveChangePhotoRequestReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ApproveChangePhotoRequest',
        request,
        metadata || {},
        this.methodDescriptorApproveChangePhotoRequest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ApproveChangePhotoRequest',
    request,
    metadata || {},
    this.methodDescriptorApproveChangePhotoRequest);
  }

  methodDescriptorGetTnc = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetTnc',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetTncRequest,
    smg_service_pb.GetTncReply,
    (request: smg_service_pb.GetTncRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetTncReply.deserializeBinary
  );

  getTnc(
    request: smg_service_pb.GetTncRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetTncReply>;

  getTnc(
    request: smg_service_pb.GetTncRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetTncReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetTncReply>;

  getTnc(
    request: smg_service_pb.GetTncRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetTncReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetTnc',
        request,
        metadata || {},
        this.methodDescriptorGetTnc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetTnc',
    request,
    metadata || {},
    this.methodDescriptorGetTnc);
  }

  methodDescriptorAcceptTnc = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/AcceptTnc',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.AcceptTncRequest,
    smg_service_pb.AcceptTncReply,
    (request: smg_service_pb.AcceptTncRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.AcceptTncReply.deserializeBinary
  );

  acceptTnc(
    request: smg_service_pb.AcceptTncRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.AcceptTncReply>;

  acceptTnc(
    request: smg_service_pb.AcceptTncRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.AcceptTncReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.AcceptTncReply>;

  acceptTnc(
    request: smg_service_pb.AcceptTncRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.AcceptTncReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/AcceptTnc',
        request,
        metadata || {},
        this.methodDescriptorAcceptTnc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/AcceptTnc',
    request,
    metadata || {},
    this.methodDescriptorAcceptTnc);
  }

  methodDescriptorStartVeriffKycVerification = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/StartVeriffKycVerification',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.StartVeriffKycVerificationRequest,
    smg_service_pb.StartVeriffKycVerificationResponse,
    (request: smg_service_pb.StartVeriffKycVerificationRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.StartVeriffKycVerificationResponse.deserializeBinary
  );

  startVeriffKycVerification(
    request: smg_service_pb.StartVeriffKycVerificationRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.StartVeriffKycVerificationResponse>;

  startVeriffKycVerification(
    request: smg_service_pb.StartVeriffKycVerificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.StartVeriffKycVerificationResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.StartVeriffKycVerificationResponse>;

  startVeriffKycVerification(
    request: smg_service_pb.StartVeriffKycVerificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.StartVeriffKycVerificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/StartVeriffKycVerification',
        request,
        metadata || {},
        this.methodDescriptorStartVeriffKycVerification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/StartVeriffKycVerification',
    request,
    metadata || {},
    this.methodDescriptorStartVeriffKycVerification);
  }

  methodDescriptorSubmitPhoto = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/SubmitPhoto',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.SubmitPhotoRequest,
    smg_service_pb.SubmitPhotoReply,
    (request: smg_service_pb.SubmitPhotoRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.SubmitPhotoReply.deserializeBinary
  );

  submitPhoto(
    request: smg_service_pb.SubmitPhotoRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.SubmitPhotoReply>;

  submitPhoto(
    request: smg_service_pb.SubmitPhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.SubmitPhotoReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.SubmitPhotoReply>;

  submitPhoto(
    request: smg_service_pb.SubmitPhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.SubmitPhotoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/SubmitPhoto',
        request,
        metadata || {},
        this.methodDescriptorSubmitPhoto,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/SubmitPhoto',
    request,
    metadata || {},
    this.methodDescriptorSubmitPhoto);
  }

  methodDescriptorContactCsr = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ContactCsr',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ContactCsrRequest,
    smg_service_pb.ContactCsrReply,
    (request: smg_service_pb.ContactCsrRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ContactCsrReply.deserializeBinary
  );

  contactCsr(
    request: smg_service_pb.ContactCsrRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ContactCsrReply>;

  contactCsr(
    request: smg_service_pb.ContactCsrRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ContactCsrReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ContactCsrReply>;

  contactCsr(
    request: smg_service_pb.ContactCsrRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ContactCsrReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ContactCsr',
        request,
        metadata || {},
        this.methodDescriptorContactCsr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ContactCsr',
    request,
    metadata || {},
    this.methodDescriptorContactCsr);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/UpdateUser',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.UpdateUserRequest,
    smg_service_pb.UpdateUserReply,
    (request: smg_service_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.UpdateUserReply.deserializeBinary
  );

  updateUser(
    request: smg_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.UpdateUserReply>;

  updateUser(
    request: smg_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.UpdateUserReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.UpdateUserReply>;

  updateUser(
    request: smg_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.UpdateUserReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorEmailLogin = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/EmailLogin',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.EmailLoginRequest,
    smg_service_pb.EmailLoginReply,
    (request: smg_service_pb.EmailLoginRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.EmailLoginReply.deserializeBinary
  );

  emailLogin(
    request: smg_service_pb.EmailLoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.EmailLoginReply>;

  emailLogin(
    request: smg_service_pb.EmailLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.EmailLoginReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.EmailLoginReply>;

  emailLogin(
    request: smg_service_pb.EmailLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.EmailLoginReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/EmailLogin',
        request,
        metadata || {},
        this.methodDescriptorEmailLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/EmailLogin',
    request,
    metadata || {},
    this.methodDescriptorEmailLogin);
  }

  methodDescriptorImageLogin = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ImageLogin',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ImageLoginRequest,
    smg_service_pb.ImageLoginReply,
    (request: smg_service_pb.ImageLoginRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ImageLoginReply.deserializeBinary
  );

  imageLogin(
    request: smg_service_pb.ImageLoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ImageLoginReply>;

  imageLogin(
    request: smg_service_pb.ImageLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ImageLoginReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ImageLoginReply>;

  imageLogin(
    request: smg_service_pb.ImageLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ImageLoginReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ImageLogin',
        request,
        metadata || {},
        this.methodDescriptorImageLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ImageLogin',
    request,
    metadata || {},
    this.methodDescriptorImageLogin);
  }

  methodDescriptorRequestSmsCode = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RequestSmsCode',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.RequestSmsCodeRequest,
    smg_service_pb.RequestSmsCodeReply,
    (request: smg_service_pb.RequestSmsCodeRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.RequestSmsCodeReply.deserializeBinary
  );

  requestSmsCode(
    request: smg_service_pb.RequestSmsCodeRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.RequestSmsCodeReply>;

  requestSmsCode(
    request: smg_service_pb.RequestSmsCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestSmsCodeReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.RequestSmsCodeReply>;

  requestSmsCode(
    request: smg_service_pb.RequestSmsCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestSmsCodeReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RequestSmsCode',
        request,
        metadata || {},
        this.methodDescriptorRequestSmsCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RequestSmsCode',
    request,
    metadata || {},
    this.methodDescriptorRequestSmsCode);
  }

  methodDescriptorRequestEmailCode = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RequestEmailCode',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.RequestEmailCodeRequest,
    smg_service_pb.RequestEmailCodeReply,
    (request: smg_service_pb.RequestEmailCodeRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.RequestEmailCodeReply.deserializeBinary
  );

  requestEmailCode(
    request: smg_service_pb.RequestEmailCodeRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.RequestEmailCodeReply>;

  requestEmailCode(
    request: smg_service_pb.RequestEmailCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestEmailCodeReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.RequestEmailCodeReply>;

  requestEmailCode(
    request: smg_service_pb.RequestEmailCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestEmailCodeReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RequestEmailCode',
        request,
        metadata || {},
        this.methodDescriptorRequestEmailCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RequestEmailCode',
    request,
    metadata || {},
    this.methodDescriptorRequestEmailCode);
  }

  methodDescriptorEnableSelfLimitation = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/EnableSelfLimitation',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.EnableSelfLimitationRequest,
    smg_service_pb.EnableSelfLimitationReply,
    (request: smg_service_pb.EnableSelfLimitationRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.EnableSelfLimitationReply.deserializeBinary
  );

  enableSelfLimitation(
    request: smg_service_pb.EnableSelfLimitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.EnableSelfLimitationReply>;

  enableSelfLimitation(
    request: smg_service_pb.EnableSelfLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.EnableSelfLimitationReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.EnableSelfLimitationReply>;

  enableSelfLimitation(
    request: smg_service_pb.EnableSelfLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.EnableSelfLimitationReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/EnableSelfLimitation',
        request,
        metadata || {},
        this.methodDescriptorEnableSelfLimitation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/EnableSelfLimitation',
    request,
    metadata || {},
    this.methodDescriptorEnableSelfLimitation);
  }

  methodDescriptorSmsLogin = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/SmsLogin',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.SmsLoginRequest,
    smg_service_pb.SmsLoginReply,
    (request: smg_service_pb.SmsLoginRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.SmsLoginReply.deserializeBinary
  );

  smsLogin(
    request: smg_service_pb.SmsLoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.SmsLoginReply>;

  smsLogin(
    request: smg_service_pb.SmsLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.SmsLoginReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.SmsLoginReply>;

  smsLogin(
    request: smg_service_pb.SmsLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.SmsLoginReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/SmsLogin',
        request,
        metadata || {},
        this.methodDescriptorSmsLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/SmsLogin',
    request,
    metadata || {},
    this.methodDescriptorSmsLogin);
  }

  methodDescriptorVerifySmsCode = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/VerifySmsCode',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.VerifySmsCodeRequest,
    smg_service_pb.VerifySmsCodeReply,
    (request: smg_service_pb.VerifySmsCodeRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.VerifySmsCodeReply.deserializeBinary
  );

  verifySmsCode(
    request: smg_service_pb.VerifySmsCodeRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.VerifySmsCodeReply>;

  verifySmsCode(
    request: smg_service_pb.VerifySmsCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.VerifySmsCodeReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.VerifySmsCodeReply>;

  verifySmsCode(
    request: smg_service_pb.VerifySmsCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.VerifySmsCodeReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/VerifySmsCode',
        request,
        metadata || {},
        this.methodDescriptorVerifySmsCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/VerifySmsCode',
    request,
    metadata || {},
    this.methodDescriptorVerifySmsCode);
  }

  methodDescriptorLoginWithIdToken = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/LoginWithIdToken',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.LoginWithIdTokenRequest,
    smg_service_pb.LoginWithIdTokenReply,
    (request: smg_service_pb.LoginWithIdTokenRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.LoginWithIdTokenReply.deserializeBinary
  );

  loginWithIdToken(
    request: smg_service_pb.LoginWithIdTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.LoginWithIdTokenReply>;

  loginWithIdToken(
    request: smg_service_pb.LoginWithIdTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.LoginWithIdTokenReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.LoginWithIdTokenReply>;

  loginWithIdToken(
    request: smg_service_pb.LoginWithIdTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.LoginWithIdTokenReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/LoginWithIdToken',
        request,
        metadata || {},
        this.methodDescriptorLoginWithIdToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/LoginWithIdToken',
    request,
    metadata || {},
    this.methodDescriptorLoginWithIdToken);
  }

  methodDescriptorGetUser = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetUser',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetUserRequest,
    smg_service_pb.GetUserReply,
    (request: smg_service_pb.GetUserRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetUserReply.deserializeBinary
  );

  getUser(
    request: smg_service_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetUserReply>;

  getUser(
    request: smg_service_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetUserReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetUserReply>;

  getUser(
    request: smg_service_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetUserReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetUser',
        request,
        metadata || {},
        this.methodDescriptorGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetUser',
    request,
    metadata || {},
    this.methodDescriptorGetUser);
  }

  methodDescriptorGetBalance = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetBalance',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetBalanceRequest,
    smg_service_pb.GetBalanceReply,
    (request: smg_service_pb.GetBalanceRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetBalanceReply.deserializeBinary
  );

  getBalance(
    request: smg_service_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetBalanceReply>;

  getBalance(
    request: smg_service_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBalanceReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetBalanceReply>;

  getBalance(
    request: smg_service_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBalanceReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetBalance',
        request,
        metadata || {},
        this.methodDescriptorGetBalance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetBalance',
    request,
    metadata || {},
    this.methodDescriptorGetBalance);
  }

  methodDescriptorRefundBet = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RefundBet',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.BetResolutionRequest,
    smg_service_pb.BetResolutionReply,
    (request: smg_service_pb.BetResolutionRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.BetResolutionReply.deserializeBinary
  );

  refundBet(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.BetResolutionReply>;

  refundBet(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.BetResolutionReply>;

  refundBet(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RefundBet',
        request,
        metadata || {},
        this.methodDescriptorRefundBet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RefundBet',
    request,
    metadata || {},
    this.methodDescriptorRefundBet);
  }

  methodDescriptorCancelBet = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/CancelBet',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.BetResolutionRequest,
    smg_service_pb.BetResolutionReply,
    (request: smg_service_pb.BetResolutionRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.BetResolutionReply.deserializeBinary
  );

  cancelBet(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.BetResolutionReply>;

  cancelBet(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.BetResolutionReply>;

  cancelBet(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/CancelBet',
        request,
        metadata || {},
        this.methodDescriptorCancelBet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/CancelBet',
    request,
    metadata || {},
    this.methodDescriptorCancelBet);
  }

  methodDescriptorRefundBetByAdmin = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RefundBetByAdmin',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.BetResolutionRequest,
    smg_service_pb.BetResolutionReply,
    (request: smg_service_pb.BetResolutionRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.BetResolutionReply.deserializeBinary
  );

  refundBetByAdmin(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.BetResolutionReply>;

  refundBetByAdmin(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.BetResolutionReply>;

  refundBetByAdmin(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RefundBetByAdmin',
        request,
        metadata || {},
        this.methodDescriptorRefundBetByAdmin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RefundBetByAdmin',
    request,
    metadata || {},
    this.methodDescriptorRefundBetByAdmin);
  }

  methodDescriptorCancelBetByAdmin = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/CancelBetByAdmin',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.BetResolutionRequest,
    smg_service_pb.BetResolutionReply,
    (request: smg_service_pb.BetResolutionRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.BetResolutionReply.deserializeBinary
  );

  cancelBetByAdmin(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.BetResolutionReply>;

  cancelBetByAdmin(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.BetResolutionReply>;

  cancelBetByAdmin(
    request: smg_service_pb.BetResolutionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.BetResolutionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/CancelBetByAdmin',
        request,
        metadata || {},
        this.methodDescriptorCancelBetByAdmin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/CancelBetByAdmin',
    request,
    metadata || {},
    this.methodDescriptorCancelBetByAdmin);
  }

  methodDescriptorGetPayouts = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetPayouts',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetPayoutRequest,
    smg_service_pb.GetPayoutReply,
    (request: smg_service_pb.GetPayoutRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetPayoutReply.deserializeBinary
  );

  getPayouts(
    request: smg_service_pb.GetPayoutRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetPayoutReply>;

  getPayouts(
    request: smg_service_pb.GetPayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetPayoutReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetPayoutReply>;

  getPayouts(
    request: smg_service_pb.GetPayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetPayoutReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetPayouts',
        request,
        metadata || {},
        this.methodDescriptorGetPayouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetPayouts',
    request,
    metadata || {},
    this.methodDescriptorGetPayouts);
  }

  methodDescriptorGetBets = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetBets',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetBetsRequest,
    smg_service_pb.GetBetsReply,
    (request: smg_service_pb.GetBetsRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetBetsReply.deserializeBinary
  );

  getBets(
    request: smg_service_pb.GetBetsRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetBetsReply>;

  getBets(
    request: smg_service_pb.GetBetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBetsReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetBetsReply>;

  getBets(
    request: smg_service_pb.GetBetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBetsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetBets',
        request,
        metadata || {},
        this.methodDescriptorGetBets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetBets',
    request,
    metadata || {},
    this.methodDescriptorGetBets);
  }

  methodDescriptorGetUpcomingBetsForSimulator = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetUpcomingBetsForSimulator',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetUpcomingBetsForSimulatorRequest,
    smg_service_pb.GetUpcomingBetsForSimulatorReply,
    (request: smg_service_pb.GetUpcomingBetsForSimulatorRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetUpcomingBetsForSimulatorReply.deserializeBinary
  );

  getUpcomingBetsForSimulator(
    request: smg_service_pb.GetUpcomingBetsForSimulatorRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetUpcomingBetsForSimulatorReply>;

  getUpcomingBetsForSimulator(
    request: smg_service_pb.GetUpcomingBetsForSimulatorRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetUpcomingBetsForSimulatorReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetUpcomingBetsForSimulatorReply>;

  getUpcomingBetsForSimulator(
    request: smg_service_pb.GetUpcomingBetsForSimulatorRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetUpcomingBetsForSimulatorReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetUpcomingBetsForSimulator',
        request,
        metadata || {},
        this.methodDescriptorGetUpcomingBetsForSimulator,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetUpcomingBetsForSimulator',
    request,
    metadata || {},
    this.methodDescriptorGetUpcomingBetsForSimulator);
  }

  methodDescriptorStreamBets = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/StreamBets',
    grpcWeb.MethodType.SERVER_STREAMING,
    smg_service_pb.StreamBetsRequest,
    smg_service_pb.StreamBetsReply,
    (request: smg_service_pb.StreamBetsRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.StreamBetsReply.deserializeBinary
  );

  streamBets(
    request: smg_service_pb.StreamBetsRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<smg_service_pb.StreamBetsReply> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/qb.smg.SmgService/StreamBets',
      request,
      metadata || {},
      this.methodDescriptorStreamBets);
  }

  methodDescriptorGetBet = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetBet',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetBetRequest,
    smg_service_pb.GetBetReply,
    (request: smg_service_pb.GetBetRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetBetReply.deserializeBinary
  );

  getBet(
    request: smg_service_pb.GetBetRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetBetReply>;

  getBet(
    request: smg_service_pb.GetBetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBetReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetBetReply>;

  getBet(
    request: smg_service_pb.GetBetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBetReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetBet',
        request,
        metadata || {},
        this.methodDescriptorGetBet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetBet',
    request,
    metadata || {},
    this.methodDescriptorGetBet);
  }

  methodDescriptorGetBetNoToken = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetBetNoToken',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetBetNoTokenRequest,
    smg_service_pb.GetBetNoTokenReply,
    (request: smg_service_pb.GetBetNoTokenRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetBetNoTokenReply.deserializeBinary
  );

  getBetNoToken(
    request: smg_service_pb.GetBetNoTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetBetNoTokenReply>;

  getBetNoToken(
    request: smg_service_pb.GetBetNoTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBetNoTokenReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetBetNoTokenReply>;

  getBetNoToken(
    request: smg_service_pb.GetBetNoTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetBetNoTokenReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetBetNoToken',
        request,
        metadata || {},
        this.methodDescriptorGetBetNoToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetBetNoToken',
    request,
    metadata || {},
    this.methodDescriptorGetBetNoToken);
  }

  methodDescriptorAcceptBet = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/AcceptBet',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.AcceptBetRequest,
    smg_service_pb.AcceptBetReply,
    (request: smg_service_pb.AcceptBetRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.AcceptBetReply.deserializeBinary
  );

  acceptBet(
    request: smg_service_pb.AcceptBetRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.AcceptBetReply>;

  acceptBet(
    request: smg_service_pb.AcceptBetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.AcceptBetReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.AcceptBetReply>;

  acceptBet(
    request: smg_service_pb.AcceptBetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.AcceptBetReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/AcceptBet',
        request,
        metadata || {},
        this.methodDescriptorAcceptBet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/AcceptBet',
    request,
    metadata || {},
    this.methodDescriptorAcceptBet);
  }

  methodDescriptorAdjustBet = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/AdjustBet',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.AdjustBetRequest,
    smg_service_pb.AdjustBetReply,
    (request: smg_service_pb.AdjustBetRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.AdjustBetReply.deserializeBinary
  );

  adjustBet(
    request: smg_service_pb.AdjustBetRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.AdjustBetReply>;

  adjustBet(
    request: smg_service_pb.AdjustBetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.AdjustBetReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.AdjustBetReply>;

  adjustBet(
    request: smg_service_pb.AdjustBetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.AdjustBetReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/AdjustBet',
        request,
        metadata || {},
        this.methodDescriptorAdjustBet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/AdjustBet',
    request,
    metadata || {},
    this.methodDescriptorAdjustBet);
  }

  methodDescriptorStartHit = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/StartHit',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.StartHitRequest,
    smg_service_pb.StartHitReply,
    (request: smg_service_pb.StartHitRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.StartHitReply.deserializeBinary
  );

  startHit(
    request: smg_service_pb.StartHitRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.StartHitReply>;

  startHit(
    request: smg_service_pb.StartHitRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.StartHitReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.StartHitReply>;

  startHit(
    request: smg_service_pb.StartHitRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.StartHitReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/StartHit',
        request,
        metadata || {},
        this.methodDescriptorStartHit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/StartHit',
    request,
    metadata || {},
    this.methodDescriptorStartHit);
  }

  methodDescriptorHitSingle = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/HitSingle',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.HitRequest,
    smg_service_pb.HitReply,
    (request: smg_service_pb.HitRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.HitReply.deserializeBinary
  );

  hitSingle(
    request: smg_service_pb.HitRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.HitReply>;

  hitSingle(
    request: smg_service_pb.HitRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.HitReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.HitReply>;

  hitSingle(
    request: smg_service_pb.HitRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.HitReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/HitSingle',
        request,
        metadata || {},
        this.methodDescriptorHitSingle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/HitSingle',
    request,
    metadata || {},
    this.methodDescriptorHitSingle);
  }

  methodDescriptorSettingsUpdateStream = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/SettingsUpdateStream',
    grpcWeb.MethodType.SERVER_STREAMING,
    smg_service_pb.SettingsUpdateStreamRequest,
    smg_service_pb.SettingsUpdate,
    (request: smg_service_pb.SettingsUpdateStreamRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.SettingsUpdate.deserializeBinary
  );

  settingsUpdateStream(
    request: smg_service_pb.SettingsUpdateStreamRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<smg_service_pb.SettingsUpdate> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/qb.smg.SmgService/SettingsUpdateStream',
      request,
      metadata || {},
      this.methodDescriptorSettingsUpdateStream);
  }

  methodDescriptorConnectStationSimple = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ConnectStationSimple',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ConnectStationSimpleRequest,
    smg_service_pb.ConnectStationSimpleReply,
    (request: smg_service_pb.ConnectStationSimpleRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ConnectStationSimpleReply.deserializeBinary
  );

  connectStationSimple(
    request: smg_service_pb.ConnectStationSimpleRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ConnectStationSimpleReply>;

  connectStationSimple(
    request: smg_service_pb.ConnectStationSimpleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ConnectStationSimpleReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ConnectStationSimpleReply>;

  connectStationSimple(
    request: smg_service_pb.ConnectStationSimpleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ConnectStationSimpleReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ConnectStationSimple',
        request,
        metadata || {},
        this.methodDescriptorConnectStationSimple,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ConnectStationSimple',
    request,
    metadata || {},
    this.methodDescriptorConnectStationSimple);
  }

  methodDescriptorHeartbeat = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/Heartbeat',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.HeartbeatRequest,
    smg_service_pb.HeartbeatResponse,
    (request: smg_service_pb.HeartbeatRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.HeartbeatResponse.deserializeBinary
  );

  heartbeat(
    request: smg_service_pb.HeartbeatRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.HeartbeatResponse>;

  heartbeat(
    request: smg_service_pb.HeartbeatRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.HeartbeatResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.HeartbeatResponse>;

  heartbeat(
    request: smg_service_pb.HeartbeatRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.HeartbeatResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/Heartbeat',
        request,
        metadata || {},
        this.methodDescriptorHeartbeat,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/Heartbeat',
    request,
    metadata || {},
    this.methodDescriptorHeartbeat);
  }

  methodDescriptorGetGameState = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetGameState',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetGameStateRequest,
    smg_service_pb.GetGameStateReply,
    (request: smg_service_pb.GetGameStateRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetGameStateReply.deserializeBinary
  );

  getGameState(
    request: smg_service_pb.GetGameStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetGameStateReply>;

  getGameState(
    request: smg_service_pb.GetGameStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetGameStateReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetGameStateReply>;

  getGameState(
    request: smg_service_pb.GetGameStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetGameStateReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetGameState',
        request,
        metadata || {},
        this.methodDescriptorGetGameState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetGameState',
    request,
    metadata || {},
    this.methodDescriptorGetGameState);
  }

  methodDescriptorStreamStationState = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/StreamStationState',
    grpcWeb.MethodType.SERVER_STREAMING,
    smg_service_pb.StreamStationStateRequest,
    smg_service_pb.StationState,
    (request: smg_service_pb.StreamStationStateRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.StationState.deserializeBinary
  );

  streamStationState(
    request: smg_service_pb.StreamStationStateRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<smg_service_pb.StationState> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/qb.smg.SmgService/StreamStationState',
      request,
      metadata || {},
      this.methodDescriptorStreamStationState);
  }

  methodDescriptorDeleteUserImage = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/DeleteUserImage',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.DeleteUserImageRequest,
    smg_service_pb.DeleteUserImageResponse,
    (request: smg_service_pb.DeleteUserImageRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.DeleteUserImageResponse.deserializeBinary
  );

  deleteUserImage(
    request: smg_service_pb.DeleteUserImageRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.DeleteUserImageResponse>;

  deleteUserImage(
    request: smg_service_pb.DeleteUserImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.DeleteUserImageResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.DeleteUserImageResponse>;

  deleteUserImage(
    request: smg_service_pb.DeleteUserImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.DeleteUserImageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/DeleteUserImage',
        request,
        metadata || {},
        this.methodDescriptorDeleteUserImage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/DeleteUserImage',
    request,
    metadata || {},
    this.methodDescriptorDeleteUserImage);
  }

  methodDescriptorDeleteUserImagesBeforeDate = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/DeleteUserImagesBeforeDate',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.DeleteUserImagesBeforeDateRequest,
    smg_service_pb.DeleteUserImagesBeforeDateResponse,
    (request: smg_service_pb.DeleteUserImagesBeforeDateRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.DeleteUserImagesBeforeDateResponse.deserializeBinary
  );

  deleteUserImagesBeforeDate(
    request: smg_service_pb.DeleteUserImagesBeforeDateRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.DeleteUserImagesBeforeDateResponse>;

  deleteUserImagesBeforeDate(
    request: smg_service_pb.DeleteUserImagesBeforeDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.DeleteUserImagesBeforeDateResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.DeleteUserImagesBeforeDateResponse>;

  deleteUserImagesBeforeDate(
    request: smg_service_pb.DeleteUserImagesBeforeDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.DeleteUserImagesBeforeDateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/DeleteUserImagesBeforeDate',
        request,
        metadata || {},
        this.methodDescriptorDeleteUserImagesBeforeDate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/DeleteUserImagesBeforeDate',
    request,
    metadata || {},
    this.methodDescriptorDeleteUserImagesBeforeDate);
  }

  methodDescriptorDisableUsers = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/DisableUsers',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.DisableUsersRequest,
    smg_service_pb.DisableUsersResponse,
    (request: smg_service_pb.DisableUsersRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.DisableUsersResponse.deserializeBinary
  );

  disableUsers(
    request: smg_service_pb.DisableUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.DisableUsersResponse>;

  disableUsers(
    request: smg_service_pb.DisableUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.DisableUsersResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.DisableUsersResponse>;

  disableUsers(
    request: smg_service_pb.DisableUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.DisableUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/DisableUsers',
        request,
        metadata || {},
        this.methodDescriptorDisableUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/DisableUsers',
    request,
    metadata || {},
    this.methodDescriptorDisableUsers);
  }

  methodDescriptorIgnoreFrs = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/IgnoreFrs',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.IgnoreFrsRequest,
    smg_service_pb.IgnoreFrsResponse,
    (request: smg_service_pb.IgnoreFrsRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.IgnoreFrsResponse.deserializeBinary
  );

  ignoreFrs(
    request: smg_service_pb.IgnoreFrsRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.IgnoreFrsResponse>;

  ignoreFrs(
    request: smg_service_pb.IgnoreFrsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.IgnoreFrsResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.IgnoreFrsResponse>;

  ignoreFrs(
    request: smg_service_pb.IgnoreFrsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.IgnoreFrsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/IgnoreFrs',
        request,
        metadata || {},
        this.methodDescriptorIgnoreFrs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/IgnoreFrs',
    request,
    metadata || {},
    this.methodDescriptorIgnoreFrs);
  }

  methodDescriptorResetIgnoreFrs = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ResetIgnoreFrs',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ResetIgnoreFrsRequest,
    smg_service_pb.ResetIgnoreFrsResponse,
    (request: smg_service_pb.ResetIgnoreFrsRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ResetIgnoreFrsResponse.deserializeBinary
  );

  resetIgnoreFrs(
    request: smg_service_pb.ResetIgnoreFrsRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ResetIgnoreFrsResponse>;

  resetIgnoreFrs(
    request: smg_service_pb.ResetIgnoreFrsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResetIgnoreFrsResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.ResetIgnoreFrsResponse>;

  resetIgnoreFrs(
    request: smg_service_pb.ResetIgnoreFrsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResetIgnoreFrsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ResetIgnoreFrs',
        request,
        metadata || {},
        this.methodDescriptorResetIgnoreFrs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ResetIgnoreFrs',
    request,
    metadata || {},
    this.methodDescriptorResetIgnoreFrs);
  }

  methodDescriptorResetShotCount = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ResetShotCount',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ResetShotCountRequest,
    smg_service_pb.ResetShotCountResponse,
    (request: smg_service_pb.ResetShotCountRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ResetShotCountResponse.deserializeBinary
  );

  resetShotCount(
    request: smg_service_pb.ResetShotCountRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ResetShotCountResponse>;

  resetShotCount(
    request: smg_service_pb.ResetShotCountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResetShotCountResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.ResetShotCountResponse>;

  resetShotCount(
    request: smg_service_pb.ResetShotCountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResetShotCountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ResetShotCount',
        request,
        metadata || {},
        this.methodDescriptorResetShotCount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ResetShotCount',
    request,
    metadata || {},
    this.methodDescriptorResetShotCount);
  }

  methodDescriptorGenerateS3ArchiveVideoUploadUrl = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GenerateS3ArchiveVideoUploadUrl',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GenerateS3ArchiveVideoUploadUrlRequest,
    smg_service_pb.GenerateS3ArchiveVideoUploadUrlResponse,
    (request: smg_service_pb.GenerateS3ArchiveVideoUploadUrlRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GenerateS3ArchiveVideoUploadUrlResponse.deserializeBinary
  );

  generateS3ArchiveVideoUploadUrl(
    request: smg_service_pb.GenerateS3ArchiveVideoUploadUrlRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GenerateS3ArchiveVideoUploadUrlResponse>;

  generateS3ArchiveVideoUploadUrl(
    request: smg_service_pb.GenerateS3ArchiveVideoUploadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GenerateS3ArchiveVideoUploadUrlResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.GenerateS3ArchiveVideoUploadUrlResponse>;

  generateS3ArchiveVideoUploadUrl(
    request: smg_service_pb.GenerateS3ArchiveVideoUploadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GenerateS3ArchiveVideoUploadUrlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GenerateS3ArchiveVideoUploadUrl',
        request,
        metadata || {},
        this.methodDescriptorGenerateS3ArchiveVideoUploadUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GenerateS3ArchiveVideoUploadUrl',
    request,
    metadata || {},
    this.methodDescriptorGenerateS3ArchiveVideoUploadUrl);
  }

  methodDescriptorGetTransactionHistory = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetTransactionHistory',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetTransactionHistoryRequest,
    smg_service_pb.GetTransactionHistoryReply,
    (request: smg_service_pb.GetTransactionHistoryRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetTransactionHistoryReply.deserializeBinary
  );

  getTransactionHistory(
    request: smg_service_pb.GetTransactionHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetTransactionHistoryReply>;

  getTransactionHistory(
    request: smg_service_pb.GetTransactionHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetTransactionHistoryReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetTransactionHistoryReply>;

  getTransactionHistory(
    request: smg_service_pb.GetTransactionHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetTransactionHistoryReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetTransactionHistory',
        request,
        metadata || {},
        this.methodDescriptorGetTransactionHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetTransactionHistory',
    request,
    metadata || {},
    this.methodDescriptorGetTransactionHistory);
  }

  methodDescriptorEnableAutoPlay = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/EnableAutoPlay',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.EnableAutoPlayRequest,
    smg_service_pb.EnableAutoPlayResponse,
    (request: smg_service_pb.EnableAutoPlayRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.EnableAutoPlayResponse.deserializeBinary
  );

  enableAutoPlay(
    request: smg_service_pb.EnableAutoPlayRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.EnableAutoPlayResponse>;

  enableAutoPlay(
    request: smg_service_pb.EnableAutoPlayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.EnableAutoPlayResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.EnableAutoPlayResponse>;

  enableAutoPlay(
    request: smg_service_pb.EnableAutoPlayRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.EnableAutoPlayResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/EnableAutoPlay',
        request,
        metadata || {},
        this.methodDescriptorEnableAutoPlay,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/EnableAutoPlay',
    request,
    metadata || {},
    this.methodDescriptorEnableAutoPlay);
  }

  methodDescriptorSetWelcomeBonus = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/SetWelcomeBonus',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.SetWelcomeBonusRequest,
    smg_service_pb.SetWelcomeBonusResponse,
    (request: smg_service_pb.SetWelcomeBonusRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.SetWelcomeBonusResponse.deserializeBinary
  );

  setWelcomeBonus(
    request: smg_service_pb.SetWelcomeBonusRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.SetWelcomeBonusResponse>;

  setWelcomeBonus(
    request: smg_service_pb.SetWelcomeBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.SetWelcomeBonusResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.SetWelcomeBonusResponse>;

  setWelcomeBonus(
    request: smg_service_pb.SetWelcomeBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.SetWelcomeBonusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/SetWelcomeBonus',
        request,
        metadata || {},
        this.methodDescriptorSetWelcomeBonus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/SetWelcomeBonus',
    request,
    metadata || {},
    this.methodDescriptorSetWelcomeBonus);
  }

  methodDescriptorEnableShawWallet = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/EnableShawWallet',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.EnableShawWalletRequest,
    smg_service_pb.EnableShawWalletResponse,
    (request: smg_service_pb.EnableShawWalletRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.EnableShawWalletResponse.deserializeBinary
  );

  enableShawWallet(
    request: smg_service_pb.EnableShawWalletRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.EnableShawWalletResponse>;

  enableShawWallet(
    request: smg_service_pb.EnableShawWalletRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.EnableShawWalletResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.EnableShawWalletResponse>;

  enableShawWallet(
    request: smg_service_pb.EnableShawWalletRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.EnableShawWalletResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/EnableShawWallet',
        request,
        metadata || {},
        this.methodDescriptorEnableShawWallet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/EnableShawWallet',
    request,
    metadata || {},
    this.methodDescriptorEnableShawWallet);
  }

  methodDescriptorDeactivateAccount = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/DeactivateAccount',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.DeactivateAccountRequest,
    smg_service_pb.DeactivateAccountResponse,
    (request: smg_service_pb.DeactivateAccountRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.DeactivateAccountResponse.deserializeBinary
  );

  deactivateAccount(
    request: smg_service_pb.DeactivateAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.DeactivateAccountResponse>;

  deactivateAccount(
    request: smg_service_pb.DeactivateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.DeactivateAccountResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.DeactivateAccountResponse>;

  deactivateAccount(
    request: smg_service_pb.DeactivateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.DeactivateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/DeactivateAccount',
        request,
        metadata || {},
        this.methodDescriptorDeactivateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/DeactivateAccount',
    request,
    metadata || {},
    this.methodDescriptorDeactivateAccount);
  }

  methodDescriptorRequestEmailChange = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RequestEmailChange',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.RequestEmailChangeRequest,
    smg_service_pb.RequestEmailChangeResponse,
    (request: smg_service_pb.RequestEmailChangeRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.RequestEmailChangeResponse.deserializeBinary
  );

  requestEmailChange(
    request: smg_service_pb.RequestEmailChangeRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.RequestEmailChangeResponse>;

  requestEmailChange(
    request: smg_service_pb.RequestEmailChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestEmailChangeResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.RequestEmailChangeResponse>;

  requestEmailChange(
    request: smg_service_pb.RequestEmailChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestEmailChangeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RequestEmailChange',
        request,
        metadata || {},
        this.methodDescriptorRequestEmailChange,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RequestEmailChange',
    request,
    metadata || {},
    this.methodDescriptorRequestEmailChange);
  }

  methodDescriptorConfirmEmailChange = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ConfirmEmailChange',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ConfirmEmailChangeRequest,
    smg_service_pb.ConfirmEmailChangeResponse,
    (request: smg_service_pb.ConfirmEmailChangeRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ConfirmEmailChangeResponse.deserializeBinary
  );

  confirmEmailChange(
    request: smg_service_pb.ConfirmEmailChangeRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ConfirmEmailChangeResponse>;

  confirmEmailChange(
    request: smg_service_pb.ConfirmEmailChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ConfirmEmailChangeResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.ConfirmEmailChangeResponse>;

  confirmEmailChange(
    request: smg_service_pb.ConfirmEmailChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ConfirmEmailChangeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ConfirmEmailChange',
        request,
        metadata || {},
        this.methodDescriptorConfirmEmailChange,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ConfirmEmailChange',
    request,
    metadata || {},
    this.methodDescriptorConfirmEmailChange);
  }

  methodDescriptorUpdatePhoneNumber = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/UpdatePhoneNumber',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.UpdatePhoneNumberRequest,
    smg_service_pb.UpdatePhoneNumberResponse,
    (request: smg_service_pb.UpdatePhoneNumberRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.UpdatePhoneNumberResponse.deserializeBinary
  );

  updatePhoneNumber(
    request: smg_service_pb.UpdatePhoneNumberRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.UpdatePhoneNumberResponse>;

  updatePhoneNumber(
    request: smg_service_pb.UpdatePhoneNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.UpdatePhoneNumberResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.UpdatePhoneNumberResponse>;

  updatePhoneNumber(
    request: smg_service_pb.UpdatePhoneNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.UpdatePhoneNumberResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/UpdatePhoneNumber',
        request,
        metadata || {},
        this.methodDescriptorUpdatePhoneNumber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/UpdatePhoneNumber',
    request,
    metadata || {},
    this.methodDescriptorUpdatePhoneNumber);
  }

  methodDescriptorRequestChange = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/RequestChange',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.RequestChangeRequest,
    smg_service_pb.RequestChangeResponse,
    (request: smg_service_pb.RequestChangeRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.RequestChangeResponse.deserializeBinary
  );

  requestChange(
    request: smg_service_pb.RequestChangeRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.RequestChangeResponse>;

  requestChange(
    request: smg_service_pb.RequestChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestChangeResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.RequestChangeResponse>;

  requestChange(
    request: smg_service_pb.RequestChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.RequestChangeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/RequestChange',
        request,
        metadata || {},
        this.methodDescriptorRequestChange,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/RequestChange',
    request,
    metadata || {},
    this.methodDescriptorRequestChange);
  }

  methodDescriptorGenerateS3SimulatorVideoUploadUrl = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GenerateS3SimulatorVideoUploadUrl',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GenerateS3SimulatorVideoUploadUrlRequest,
    smg_service_pb.GenerateS3SimulatorVideoUploadUrlResponse,
    (request: smg_service_pb.GenerateS3SimulatorVideoUploadUrlRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GenerateS3SimulatorVideoUploadUrlResponse.deserializeBinary
  );

  generateS3SimulatorVideoUploadUrl(
    request: smg_service_pb.GenerateS3SimulatorVideoUploadUrlRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GenerateS3SimulatorVideoUploadUrlResponse>;

  generateS3SimulatorVideoUploadUrl(
    request: smg_service_pb.GenerateS3SimulatorVideoUploadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GenerateS3SimulatorVideoUploadUrlResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.GenerateS3SimulatorVideoUploadUrlResponse>;

  generateS3SimulatorVideoUploadUrl(
    request: smg_service_pb.GenerateS3SimulatorVideoUploadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GenerateS3SimulatorVideoUploadUrlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GenerateS3SimulatorVideoUploadUrl',
        request,
        metadata || {},
        this.methodDescriptorGenerateS3SimulatorVideoUploadUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GenerateS3SimulatorVideoUploadUrl',
    request,
    metadata || {},
    this.methodDescriptorGenerateS3SimulatorVideoUploadUrl);
  }

  methodDescriptorResetKyc = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ResetKyc',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ResetKycRequest,
    smg_service_pb.ResetKycReply,
    (request: smg_service_pb.ResetKycRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ResetKycReply.deserializeBinary
  );

  resetKyc(
    request: smg_service_pb.ResetKycRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ResetKycReply>;

  resetKyc(
    request: smg_service_pb.ResetKycRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResetKycReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ResetKycReply>;

  resetKyc(
    request: smg_service_pb.ResetKycRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ResetKycReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ResetKyc',
        request,
        metadata || {},
        this.methodDescriptorResetKyc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ResetKyc',
    request,
    metadata || {},
    this.methodDescriptorResetKyc);
  }

  methodDescriptorGetPromos = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetPromos',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetPromosRequest,
    smg_service_pb.GetPromosResponse,
    (request: smg_service_pb.GetPromosRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetPromosResponse.deserializeBinary
  );

  getPromos(
    request: smg_service_pb.GetPromosRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetPromosResponse>;

  getPromos(
    request: smg_service_pb.GetPromosRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetPromosResponse) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetPromosResponse>;

  getPromos(
    request: smg_service_pb.GetPromosRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetPromosResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetPromos',
        request,
        metadata || {},
        this.methodDescriptorGetPromos,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetPromos',
    request,
    metadata || {},
    this.methodDescriptorGetPromos);
  }

  methodDescriptorListTournaments = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/ListTournaments',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.ListTournamentRequest,
    smg_service_pb.ListTournamentReply,
    (request: smg_service_pb.ListTournamentRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.ListTournamentReply.deserializeBinary
  );

  listTournaments(
    request: smg_service_pb.ListTournamentRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.ListTournamentReply>;

  listTournaments(
    request: smg_service_pb.ListTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.ListTournamentReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.ListTournamentReply>;

  listTournaments(
    request: smg_service_pb.ListTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.ListTournamentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/ListTournaments',
        request,
        metadata || {},
        this.methodDescriptorListTournaments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/ListTournaments',
    request,
    metadata || {},
    this.methodDescriptorListTournaments);
  }

  methodDescriptorJoinTournament = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/JoinTournament',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.JoinTournamentRequest,
    smg_service_pb.JoinTournamentReply,
    (request: smg_service_pb.JoinTournamentRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.JoinTournamentReply.deserializeBinary
  );

  joinTournament(
    request: smg_service_pb.JoinTournamentRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.JoinTournamentReply>;

  joinTournament(
    request: smg_service_pb.JoinTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.JoinTournamentReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.JoinTournamentReply>;

  joinTournament(
    request: smg_service_pb.JoinTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.JoinTournamentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/JoinTournament',
        request,
        metadata || {},
        this.methodDescriptorJoinTournament,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/JoinTournament',
    request,
    metadata || {},
    this.methodDescriptorJoinTournament);
  }

  methodDescriptorLeaveTournament = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/LeaveTournament',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.LeaveTournamentRequest,
    smg_service_pb.LeaveTournamentReply,
    (request: smg_service_pb.LeaveTournamentRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.LeaveTournamentReply.deserializeBinary
  );

  leaveTournament(
    request: smg_service_pb.LeaveTournamentRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.LeaveTournamentReply>;

  leaveTournament(
    request: smg_service_pb.LeaveTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.LeaveTournamentReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.LeaveTournamentReply>;

  leaveTournament(
    request: smg_service_pb.LeaveTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.LeaveTournamentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/LeaveTournament',
        request,
        metadata || {},
        this.methodDescriptorLeaveTournament,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/LeaveTournament',
    request,
    metadata || {},
    this.methodDescriptorLeaveTournament);
  }

  methodDescriptorGetTournament = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/GetTournament',
    grpcWeb.MethodType.UNARY,
    smg_service_pb.GetTournamentRequest,
    smg_service_pb.GetTournamentReply,
    (request: smg_service_pb.GetTournamentRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.GetTournamentReply.deserializeBinary
  );

  getTournament(
    request: smg_service_pb.GetTournamentRequest,
    metadata: grpcWeb.Metadata | null): Promise<smg_service_pb.GetTournamentReply>;

  getTournament(
    request: smg_service_pb.GetTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetTournamentReply) => void): grpcWeb.ClientReadableStream<smg_service_pb.GetTournamentReply>;

  getTournament(
    request: smg_service_pb.GetTournamentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: smg_service_pb.GetTournamentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/qb.smg.SmgService/GetTournament',
        request,
        metadata || {},
        this.methodDescriptorGetTournament,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/qb.smg.SmgService/GetTournament',
    request,
    metadata || {},
    this.methodDescriptorGetTournament);
  }

  methodDescriptorStreamTournamentLeaderBoard = new grpcWeb.MethodDescriptor(
    '/qb.smg.SmgService/StreamTournamentLeaderBoard',
    grpcWeb.MethodType.SERVER_STREAMING,
    smg_service_pb.StreamTournamentLeaderBoardRequest,
    smg_service_pb.TournamentLeaderBoard,
    (request: smg_service_pb.StreamTournamentLeaderBoardRequest) => {
      return request.serializeBinary();
    },
    smg_service_pb.TournamentLeaderBoard.deserializeBinary
  );

  streamTournamentLeaderBoard(
    request: smg_service_pb.StreamTournamentLeaderBoardRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<smg_service_pb.TournamentLeaderBoard> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/qb.smg.SmgService/StreamTournamentLeaderBoard',
      request,
      metadata || {},
      this.methodDescriptorStreamTournamentLeaderBoard);
  }

}

