import React from "react";
import DistanceChallengeItem from "./DistanceChallengeItem";
import {Div} from "react-native-magnus";
import {Golf2Challenge} from "../../generated/smg_service_pb";

type Props = { isLg: boolean, challenge?: Golf2Challenge.AsObject, amount: number };

const Golf2AcceptedBetDetails = (props: Props) => {
  const {isLg, challenge, amount} = props;
  const {EASY, MEDIUM} = Golf2Challenge.Golf2ChallengeLevel;

  return (
    <Div flex={1} mt={isLg ? 70 : 23} pl={isLg ? 90 : 55} mb={isLg ? 99 : 27} pr={!isLg ? 20 : 0}
         w={isLg ? 606 : 'auto'} maxW={isLg ? 'auto' : 400}>
      <DistanceChallengeItem
        label="LEVEL:"
        value={challenge?.level === EASY ? 'EASY' : challenge?.level === MEDIUM ? 'MEDIUM' : 'HARD'}
        minW={isLg ? 0 : 125}
        w={isLg ? '100%' : 'auto'}
        mb={!isLg ? 8 : 0}
      />

      <DistanceChallengeItem
        label="DISTANCE:"
        value={`${challenge?.gamedetails?.hole?.distance} YARDS`}
        minW={isLg ? 0 : 125}
        w={isLg ? '100%' : 'auto'}
        mb={!isLg ? 8 : 0}
      />

      <DistanceChallengeItem
        label="WIN CIRCLE:"
        value={`${(challenge && challenge.gamedetails && challenge.gamedetails.circle) ? challenge.gamedetails.circle.winCircleDistance / 12 : 0} FT`}
        minW={isLg ? 0 : 125}
        w={isLg ? '100%' : 'auto'}
        mb={!isLg ? 8 : 0}
      />

      <DistanceChallengeItem
        label="CHALLENGE FEE:"
        value={`$${amount / 100}`}
        minW={isLg ? 0 : 125}
        w={isLg ? '100%' : 'auto'}
        mb={!isLg ? 8 : 0}
      />

      <DistanceChallengeItem
        label="TOTAL PAYOUT:"
        value={`$${amount / 100 * (challenge?.multiplier || 0)}`}
        minW={isLg ? 0 : 125}
        w={isLg ? '100%' : 'auto'}
      />
    </Div>
  );
};

export default React.memo(Golf2AcceptedBetDetails);
