import React from "react";
import {Pressable, View, Image, Text} from "react-native";
import MainStyle from "../../MainStyle";
import ticket_icon from '../assets/img/icons/ticket_icon.svg';
import cross_icon from '../assets/img/icons/crosshair-line 1.svg';
import trophy_icon from '../assets/img/icons/trophy-line 2.svg';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {useNavigation} from "@react-navigation/native";

type Props = { page: 'challenges' | 'account' | 'promos' | 'tournaments' };

const BottomNavigation = (props: Props) => {
  const {page} = props;
  const navigation: any = useNavigation();

  return (
    <View style={[MainStyle.row, MainStyle.j_c_s_between, MainStyle.w_228, MainStyle.m_x_auto]}>
      <Pressable onPress={() => navigation.navigate('challenges')} style={styles.item}>
        <View style={[...styles.btn, page === 'challenges' && styles.active]}>
          <Image source={cross_icon as any} style={[MainStyle.w_24, MainStyle.h_24]}/>
        </View>
        <Text style={styles.text}>GAMES</Text>
      </Pressable>

      <Pressable onPress={() => navigation.navigate('tournaments')} style={styles.item}>
        <View style={[...styles.btn, page === 'tournaments' && styles.active]}>
          <Image source={trophy_icon as any} style={[MainStyle.w_24, MainStyle.h_24]}/>
        </View>
        <Text style={styles.text}>Tourney</Text>
      </Pressable>

      <Pressable onPress={() => navigation.navigate('account')} style={styles.item}>
        <View style={[...styles.btn, page === 'account' && styles.active]}>
          <MaterialCommunityIcons name="account-circle-outline" size={24} color="white"/>
        </View>
        <Text style={styles.text}>PROFILE</Text>
      </Pressable>

      <Pressable onPress={() => navigation.navigate('promos')} style={styles.item}>
        <View style={[...styles.btn, page === 'promos' && styles.active]}>
          <Image source={ticket_icon as any} style={[MainStyle.w_24, MainStyle.h_24]}/>
        </View>
        <Text style={styles.text}>PROMOS</Text>
      </Pressable>
    </View>
  );
};

const styles = {
  btn: [
    MainStyle.w_48, MainStyle.h_48, MainStyle.b_r_40, MainStyle.bg_settings, MainStyle.a_i_c, MainStyle.j_c_c,
    MainStyle.b_c_settings_border, MainStyle.b_w_1
  ],
  active: [MainStyle.bg_filter_chip, MainStyle.b_c_filter_chip_bg],
  text: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_12, MainStyle.t_t_u],
  item: [MainStyle.a_i_c, MainStyle.j_c_c]
};

export default React.memo(BottomNavigation);
