import React, {useContext, useLayoutEffect} from 'react';
import {Image, ScrollView, Text, View, StyleSheet, Platform} from 'react-native';
import MainStyle from '../../../../MainStyle';
import kiosk_logo_RGB_1row from '../../../assets/img/kiosk_logo_RGB_1row.png';
import Button from '../../../components/Button';
import {HelpLinkModalTrigger} from '../../../modals/HelpLinksModal';
import {StoreContext} from "../../../components/StoreContextProvider";
import {colors} from "../../../assets/styles/colors";
import ServiceClient from "../../../utils/ServiceClient";
import AsyncStorage from "@react-native-async-storage/async-storage";

type LoginWithPinProps = { navigation: any, route: any };

const EmailVerification = ({navigation, route}: LoginWithPinProps) => {
  const Context = useContext(StoreContext);
  const {loadingOverlay, cancelModalConfig} = Context;

  const confirmEmailChange = (token: string) => {
    ServiceClient.confirmEmailChange(token).then(() => {
      Context.setLoadingOverlay(false);

    }).catch(err => {
      console.log('confirmEmailChange err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', subtitle: err.message, leftBtnText: 'CONTACT SUPPORT',
        leftBtnBackgroundColor: colors.unselect, rightBtnText: 'OK',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          if (Platform.OS === 'web') window.open('https://smgames.atlassian.net/servicedesk/customer/portal/3', '_blank');
        },
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.reset({index: 0, routes: [{name: 'challenges'}]});
        }
      });
    });
  };

  const navigateToChallengePage = () => {
    Context.setLoadingOverlay(true);

    Context.getUser().then(() => {
      Context.setLoadingOverlay(false);
      navigation.reset({index: 0, routes: [{name: 'challenges'}]});

    }).catch(() => {
      Context.setLoadingOverlay(false);
      navigation.reset({index: 0, routes: [{name: 'challenges'}]});
    });
  };

  const confirmEmail = (token: string) => {
    ServiceClient.confirmEmail(token).then(res => {
      Context.setLoadingOverlay(false);

      if (res) {
        Context.setSession(res);
        AsyncStorage.setItem('session', JSON.stringify(res)).then();
      }

    }).catch(err => {
      console.log('confirmEmail', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', subtitle: err.message, leftBtnText: 'CONTACT SUPPORT',
        leftBtnBackgroundColor: colors.unselect, rightBtnText: 'OK',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          if (Platform.OS === 'web') window.open('https://smgames.atlassian.net/servicedesk/customer/portal/3', '_blank');
        },
        onResume: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.reset({index: 0, routes: [{name: 'challenges'}]});
        }
      });
    });
  };

  useLayoutEffect(() => {
    Context.setLoadingOverlay(true);
    if (!route.params) return;
    const {token, type} = route.params;
    Context.setLoadingOverlay(true);

    if (type === 'emailChange') confirmEmailChange(token);
    else confirmEmail(token);
  }, []);

  return (
    <>
      <HelpLinkModalTrigger/>

      <ScrollView contentContainerStyle={[MainStyle.full_h, MainStyle.full_w]}>
        <View style={[MainStyle.a_i_c, MainStyle.p_x_16, MainStyle.flex1]}>
          <Image
            source={kiosk_logo_RGB_1row}
            style={[MainStyle.rm_stretch, MainStyle.h_41, MainStyle.w_255, MainStyle.m_t_10]}
          />

          <View>
            <View style={MainStyle.m_t_114}>
              <Text style={styles.titleSmall}>VERIFICATION</Text>
            </View>

            <View style={[MainStyle.m_t_114, {opacity: loadingOverlay ? 0 : 1}]}>
              <Text style={styles.msgTextSmall}>EMAIL VERIFIED</Text>
            </View>

            <View style={[MainStyle.m_t_20, MainStyle.m_b_158, {opacity: loadingOverlay ? 0 : 1}]}>
              <Text style={styles.descSmall}>Your email has been verified!</Text>
            </View>

            <Button text="OK" h={40} w={244} mb={100} onPress={navigateToChallengePage}/>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  titleSmall: {fontWeight: '600', fontSize: 20, lineHeight: 27, textAlign: 'center', color: '#85BED1'},
  msgTextSmall: {fontWeight: '700', fontSize: 20, lineHeight: 27, textAlign: 'center', color: '#43D120'},
  descSmall: {fontWeight: '400', fontSize: 16, lineHeight: 19, textAlign: 'center', color: '#F5F5F5'}
});

export default EmailVerification;
