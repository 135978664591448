import React, {useContext} from "react";
import {Text, View, Image} from "react-native";
import MainStyle from "../../MainStyle";
import location from "../assets/img/icons/map-pin-range-line 1.svg";
import {Challenge, Golf2Challenge} from "../../generated/smg_service_pb";
import {setContestFrameImage, setPointConfig} from "../utils";
import liveChallengeIndicationGraphics from "../assets/img/live-challange-indication-grapics.png";
import {Entypo} from '@expo/vector-icons';
import {StoreContext} from "./StoreContextProvider";

type Props = {
  betType: string, type: 'open' | 'accepted', onArrowForwardPress: () => void, challengeId: number,
  golf2challengesList: Golf2Challenge.AsObject[], largestDistanceChallenge?: Challenge.AsObject
};

const ContestFrame = (props: Props) => {
  const {betType, type, golf2challengesList, challengeId, largestDistanceChallenge} = props;
  const source = setContestFrameImage(betType, type, golf2challengesList.find(el => el.outcome === 'unresolved'));
  const Context = useContext(StoreContext);
  const {extendedUserData} = Context;

  const renderPoint = () => {
    if (golf2challengesList.length) {
      const find = golf2challengesList.find(el => type === 'open' ? el.challengeId === challengeId : el.outcome === 'unresolved');

      if (find) {
        const config = setPointConfig(find, false, type);
        const {lvl, text, top, left, icon, iconTop, iconLeft, Styles} = config;
        const iconStyle = [
          MainStyle.absolute, {height: Styles.iconH, width: Styles.iconW, top: iconTop, left: iconLeft}
        ];

        return (
          <>
            <Image source={icon} style={iconStyle}/>

            <View style={[MainStyle.absolute, MainStyle.w_100, {top, left}]}>
              <Text style={Styles.distance}>{find.gamedetails?.hole?.distance} yds</Text>
              <Text style={Styles.text}>{lvl}</Text>
              <Text style={Styles.text}>{text}</Text>
            </View>
          </>
        );

      } else return null;

    } else if (betType === 'paytable') {
      const distance = largestDistanceChallenge ? largestDistanceChallenge.value1 / 36 : 0;

      return (
        <View style={[MainStyle.absolute, MainStyle.w_85, MainStyle.t_11, MainStyle.l_168]}>
          <Text style={[...styles.distance, styles.shadow]}>{distance} yds</Text>
          <Entypo name="triangle-down" size={14} color="white" style={[MainStyle.m_x_auto, styles.shadow]}/>
        </View>
      );

    } else return null;
  };

  return (
    <View style={styles.bg}>
      <Image source={type === 'open' ? source : liveChallengeIndicationGraphics} style={[MainStyle.h_240, MainStyle.rm_cover]}/>

      <View style={[MainStyle.absolute, MainStyle.t_8, MainStyle.l_7, MainStyle.row, MainStyle.w_160, MainStyle.a_i_c]}>
        <View style={[MainStyle.w_48, MainStyle.h_48, MainStyle.b_r_50, MainStyle.bg_settings, MainStyle.j_c_c]}>
          <Image source={location as any} style={[MainStyle.w_24, MainStyle.h_24, MainStyle.m_x_auto]}/>
        </View>
        <Text style={[...styles.title, styles.shadow]}>
          {betType === 'golf2' ? 'Multi-Pin Range' : 'Closest to the Pin'}
        </Text>
      </View>

      {type === 'open' && renderPoint()}

      {(extendedUserData.isnewplayer || extendedUserData.freeshotnumber !== extendedUserData.freeshotcount) && (
        <View style={[MainStyle.absolute, MainStyle.b_0, MainStyle.full_w]}>
          <Text style={styles.freeShots}>
            {type === 'open' ? `${extendedUserData.freeshotcount} Free Shots` : `Free Shot ${extendedUserData.freeshotnumber} of ${extendedUserData.freeshotcount}`}
          </Text>
          <Text style={styles.banner}>See How Much Money You Can Win*!</Text>
        </View>
      )}

      {/*<Div position="relative" flex={1}>*/}
      {/*  <Div position="absolute" style={MainStyle.t_50_percent} right={0} mr={-24}>*/}
      {/*    <Button w={48} h={48} bg={colors.unselect} borderWidth={1} shadow="lg" rounded={56}*/}
      {/*            borderColor="rgba(0, 0, 0, 0.5)" onPress={props.onArrowForwardPress}>*/}
      {/*      <Ionicons name="ios-chevron-forward" size={40} color="white"/>*/}
      {/*    </Button>*/}
      {/*  </Div>*/}
      {/*</Div>*/}
    </View>
  );
};

const styles = {
  bg: [
    MainStyle.h_187, MainStyle.b_r_20, MainStyle.relative, MainStyle.o_hidden, MainStyle.m_b_10,
    MainStyle.bg_placeholder
  ],
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_22, MainStyle.m_l_10],
  distance: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_22],
  shadow: {textShadowColor: 'rgba(0, 0, 0, 0.50)', textShadowOffset: {width: 0, height: 2}, textShadowRadius: 2},
  banner: [
    MainStyle.l_h_24, MainStyle.bg_newGreen, MainStyle.c_black, MainStyle.t_a_c, MainStyle.Barlow700,
    MainStyle.f_s_16, MainStyle.t_t_u
  ],
  freeShots: [
    MainStyle.c_white, MainStyle.t_a_c, MainStyle.textShadow, MainStyle.Barlow500, MainStyle.f_s_36
  ]
};

export default React.memo(ContestFrame);
