import React, {useState} from 'react';
import {Text, Pressable, Image, StyleProp, ViewStyle, TextStyle} from 'react-native';
import MainStyle from "../../MainStyle";
import arrowDown from '../assets/img/arrow-down.png';
import SelectModal from "../modals/SelectModal";
import {outline_0} from "../constants";

type ItemType = { value: string, label: string, customLabel?: string };

let items: ItemType[] = [];

for (let i = 10; i > 0; i--) {
  items.push({value: `+${i}`, label: `+${i}`});
}

items.push({value: 'N/A', label: 'N/A'});
items.push({value: '0', label: '0'});

for (let i = 1; i <= 30; i++) {
  items.push({value: `${i}`, label: `${i}`});
}

type Props = {
  placeholder?: string, label?: string, value: string, onChangeText: (val: string) => void, mt?: string | number,
  customItems?: ItemType[], mb?: string | number, secondItems?: ItemType[], w?: string | number, borderRadius?: number,
  h?: string | number, pl?: string | number, pr?: string | number, bg?: string, borderColor?: string, color?: string,
  textAlign?: "center" | "auto" | "left" | "right" | "justify", fontFamily?: string, icon?: any,
  iconSize?: string | number, disabledItemsBottom?: boolean, popupTitle?: string, disabled?: boolean, fontSize?: number
};

const Select = (props: Props) => {
  const {placeholder, label, value, mt, customItems, mb, secondItems, w, borderRadius, h, pl, pr, textAlign} = props;
  const {bg, borderColor, color, fontFamily, icon, iconSize, disabledItemsBottom, popupTitle, disabled} = props;
  const {fontSize} = props;
  // const isMd = width >= tabletBreakpoint;
  const [visible, setVisible] = useState(false);
  if (customItems) items = customItems;
  const dynamicStyles: StyleProp<ViewStyle> = {
    marginBottom: mb, width: w, borderRadius, height: h, paddingLeft: disabled ? undefined : pl,
    paddingRight: disabled ? undefined : pr, backgroundColor: bg, borderColor
  };
  const selectedItem = items.find(el => el.value === value);
  const textStyle: StyleProp<TextStyle> = {
    textAlign: disabled ? 'center' : textAlign, color: disabled ? 'white' : color, fontFamily, fontSize
  };

  const onResume = (val?: string) => {
    setVisible(false);
    if (val) props.onChangeText(val);
  };

  return (
    <>
      {label && <Text style={[...styles.label_s, {marginTop: mt}]}>{label}</Text>}

      <Pressable style={[...styles.select_s, dynamicStyles, outline_0]} onPress={() => setVisible(true)} disabled={disabled}>
        <Text style={[styles.select_value, MainStyle.f_s_22, textStyle]}>
          {selectedItem?.customLabel || selectedItem?.label || placeholder}
        </Text>
        {!disabled && (
          <Image source={icon || arrowDown} style={[...styles.down_s, {width: iconSize, height: iconSize}]}/>
        )}
      </Pressable>

      <SelectModal visible={visible} onResume={onResume} isLg={false} value={value} items={items}
                   secondItems={secondItems} disabledItemsBottom={disabledItemsBottom} popupTitle={popupTitle}/>
    </>
  );
};

const styles = {
  label_s: [MainStyle.c_white, MainStyle.t_a_c, MainStyle.f_s_12, MainStyle.Barlow400, MainStyle.o_05],
  select_s: [
    MainStyle.b_r_4, MainStyle.b_c_blue, MainStyle.bc_popupDropBg, MainStyle.b_w_1, MainStyle.p_x_9, MainStyle.h_60,
    MainStyle.row, MainStyle.a_i_c
  ],
  select_value: [MainStyle.c_lightblue, MainStyle.Barlow500, MainStyle.t_a_c, MainStyle.flex1],
  down_s: [MainStyle.w_10, MainStyle.h_12, MainStyle.rm_center, MainStyle.absolute, {right: 16}],
};

export default React.memo(Select);
