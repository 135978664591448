import React, {useState} from "react";
import {ScrollView, StyleProp, Text, View, ViewStyle} from "react-native";
import {TournamentPlayerBet, TournamentPlayerDetails} from "../../generated/smg_service_pb";
import MainStyle from "../../MainStyle";
import Cell from "./RegisteredPlayersStatusHeaderCell";
import {numberWithCommas} from "../utils/number";

type Props = {
  mx?: string | number, maxH?: string | number, item: TournamentPlayerDetails.AsObject[], w?: string | number,
  b_b_r?: number, playerId?: number
};

const RegisteredPlayersStatus = (props: Props) => {
  const {mx, maxH, item, w, b_b_r, playerId} = props;
  const dynamicStyles: StyleProp<ViewStyle> = {
    marginHorizontal: mx, maxHeight: maxH, width: w, borderBottomLeftRadius: b_b_r, borderBottomRightRadius: b_b_r
  };
  const [sortConfig, setSortConfig] = useState({key: 'rank', direction: 'ascending'});

  const requestSort = (key: string) => () => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') direction = 'descending';
    setSortConfig({key, direction});
  };

  const getStatusText = (state: TournamentPlayerDetails.State) => {
    const DetailsState = TournamentPlayerDetails.State;

    if (state === DetailsState.PENDING) return 'PENDING';
    else if (state === DetailsState.PLAYING) return 'Playing';
    else if (state === DetailsState.FINISHED) return 'Complete';
    else return '-';
  };

  const compareNumbers = (a: TournamentPlayerDetails.AsObject, b: TournamentPlayerDetails.AsObject) => {
    if (sortConfig.key === 'rank' || sortConfig.key === 'points') {
      if (a.score > b.score) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a.score < b.score) return sortConfig.direction === 'ascending' ? 1 : -1;

    } else if (sortConfig.key === 'name') {
      if (a.playerName > b.playerName) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a.playerName < b.playerName) return sortConfig.direction === 'ascending' ? 1 : -1;

    } else if (sortConfig.key === 'status') {
      if (a.state > b.state) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a.state < b.state) return sortConfig.direction === 'ascending' ? 1 : -1;

    } else if (sortConfig.key === 'shots') {
      const BetState = TournamentPlayerBet.State;

      const a_shots = a.betsList.filter(bet => bet.state === BetState.FINISHED).length;
      const b_shots = b.betsList.filter(bet => bet.state === BetState.FINISHED).length;

      if (a_shots > b_shots) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a_shots < b_shots) return sortConfig.direction === 'ascending' ? 1 : -1;

    } else if (sortConfig.key === 'payout') {
      if (a.prize > b.prize) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a.prize < b.prize) return sortConfig.direction === 'ascending' ? 1 : -1;
    }

    return 0;
  };

  return (
    <View style={[...styles.wrap, dynamicStyles]}>
      <View style={[MainStyle.row, MainStyle.m_b_9]}>
        <Cell w={32} onPress={requestSort('rank')} active={sortConfig.key === 'rank'} dir={sortConfig.direction}>
          Rank
        </Cell>

        <Cell style={MainStyle.flex1} onPress={requestSort('name')} active={sortConfig.key === 'name'}
              dir={sortConfig.direction}>
          Name
        </Cell>

        <Cell w={80} onPress={requestSort('status')} active={sortConfig.key === 'status'} dir={sortConfig.direction}>
          Status
        </Cell>

        <Cell w={46} onPress={requestSort('points')} active={sortConfig.key === 'points'} dir={sortConfig.direction}>
          Points
        </Cell>

        <Cell w={46} onPress={requestSort('shots')} active={sortConfig.key === 'shots'} dir={sortConfig.direction}>
          Shots
        </Cell>

        <Cell w={56} onPress={requestSort('payout')} active={sortConfig.key === 'payout'} dir={sortConfig.direction}>
          Payout
        </Cell>
      </View>

      <ScrollView>
        {item.sort(compareNumbers).map((el, i) => {
          const isYou = el.playerId === playerId;

          return (
            <View style={[MainStyle.row, MainStyle.b_r_2, isYou && MainStyle.bg_disable_chip]} key={el.playerId}>
              <Text style={[...styles.cell, isYou && MainStyle.c_new_green_text, MainStyle.w_32]}>{i + 1}</Text>

              <Text style={[...styles.cell, isYou && MainStyle.c_new_green_text, MainStyle.flex1]} numberOfLines={1}>
                {el.playerName || '-'}
              </Text>

              <Text style={[...styles.cell, isYou && MainStyle.c_new_green_text, MainStyle.f_s_15, MainStyle.w_80]}>
                {getStatusText(el.state)}
              </Text>

              <Text style={[...styles.cell, isYou && MainStyle.c_new_green_text, MainStyle.w_46]}>
                {el.score}
              </Text>

              <Text style={[...styles.cell, isYou && MainStyle.c_new_green_text, MainStyle.w_46]}>
                {el.betsList.filter(bet => bet.state === TournamentPlayerBet.State.FINISHED).length}
              </Text>

              <Text style={[...styles.cell, isYou && MainStyle.c_new_green_text, MainStyle.w_56]}>
                ${el.prize ? numberWithCommas(el.prize / 100, true) : 0}
              </Text>
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles = {
  wrap: [
    MainStyle.b_r_20, MainStyle.b_w_1, MainStyle.b_c_settings_border, MainStyle.bg_settings, MainStyle.p_t_17,
    MainStyle.p_b_20, MainStyle.p_x_12
  ],
  cell: [
    MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_16, MainStyle.l_h_22,
    MainStyle.t_t_u
  ]
};

export default React.memo(RegisteredPlayersStatus);
