import {Golf2Challenge, Location} from "../../generated/smg_service_pb";
import dollar1 from "../assets/img/dollar1.png";
import dollar2 from "../assets/img/dollar2.png";
import dollar3 from "../assets/img/dollar3.png";
import Frame2 from "../assets/img/Frame2.png";
import Frame3 from "../assets/img/Frame3.png";
import Frame4 from "../assets/img/Frame4.png";
import frame1 from "../assets/img/Frame1.png";
import image8 from "../assets/img/image8.png";
import image9 from "../assets/img/acfdd669d263e06734c69ca200dd47a7.png";
import MainStyle from "../../MainStyle";
import {preventSelect} from "../constants";
import {Platform} from "react-native";
import moment from 'moment';

const distance = (lat1: number, lon1: number, lat2: number, lon2: number, unit: 'M' | 'K' | 'N') => {
  if ((lat1 == lat2) && (lon1 == lon2)) return 0;
  else {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) dist = 1;

    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;

    if (unit === 'K') dist = dist * 1.609344;
    if (unit === 'N') dist = dist * 0.8684;

    return dist;
  }
};

export const browser = () => {
  if (Platform.OS !== 'web') return '';
  const test = (regexp: RegExp) => regexp.test(window.navigator.userAgent);

  switch (true) {
    case test(/edg/i):
      return 'microsoft edge';
    case test(/trident/i):
      return 'internet explorer';
    case test(/firefox|fxios/i):
      return 'firefox';
    case test(/opr\//i):
      return 'opera';
    case test(/ucbrowser/i):
      return 'uc browser';
    case test(/samsungbrowser/i):
      return 'samsung browser';
    case test(/chrome|chromium|crios/i):
      return 'chrome';
    case test(/safari/i):
      return 'safari';
    default:
      return 'browser';
  }
};

// string to readable capitalization
export const toCapitalize = (str: string) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const setPointConfig = (challenge: Golf2Challenge.AsObject, isLg: boolean, type: 'open' | 'accepted') => {
  const config = {
    lvl: '', text: '', top: 0, left: 0, icon: dollar1 as any, iconTop: 0, iconLeft: 0,
    Styles: {distance: [MainStyle.f_w_700], text: [MainStyle.f_w_700], iconH: 0, iconW: 0}
  };

  if (type === 'open') {
    config.Styles.distance = styles[isLg ? 'open_distance_l' : 'open_distance_s'];
    config.Styles.text = styles[isLg ? 'open_text_l' : 'open_text_s'];
    config.Styles.iconH = isLg ? 75 : 38;
    config.Styles.iconW = isLg ? 40 : 20;

    if (challenge.level === Golf2Challenge.Golf2ChallengeLevel.EASY) {
      config.lvl = 'Easy';
      config.text = 'Bowl';
      config.icon = dollar1;
      config.top = isLg ? 242 : 125;
      config.left = isLg ? 350 : 165;
      config.iconTop = isLg ? 207 : 107;
      config.iconLeft = isLg ? 310 : 156;

    } else if (challenge.level === Golf2Challenge.Golf2ChallengeLevel.MEDIUM) {
      config.lvl = 'Medium';
      config.text = 'Flat';
      config.icon = dollar2;
      config.top = isLg ? 142 : 74;
      config.left = isLg ? 73 : 22;
      config.iconTop = isLg ? 93 : 50;
      config.iconLeft = isLg ? 34 : 12;

    } else {
      config.lvl = 'Hard';
      config.text = 'Convex';
      config.icon = dollar3;
      config.top = isLg ? 61 : 33;
      config.left = isLg ? 303 : 140;
      config.iconTop = isLg ? 26 : 12;
      config.iconLeft = isLg ? 452 : 229;
    }

  } else {
    config.Styles.distance = styles[isLg ? 'distance_l' : 'distance_s'];
    config.Styles.text = styles[isLg ? 'text_l' : 'text_s'];
    config.Styles.iconH = isLg ? 115 : 75;
    config.Styles.iconW = isLg ? 65 : 40;

    if (challenge.level === Golf2Challenge.Golf2ChallengeLevel.EASY) {
      config.lvl = 'Easy';
      config.text = 'Bowl';
      config.icon = dollar1;
      config.top = isLg ? 142 : 75;
      config.left = isLg ? 272 : 124;
      config.iconTop = isLg ? 86 : 37;
      config.iconLeft = isLg ? 110 : 66;

    } else if (challenge.level === Golf2Challenge.Golf2ChallengeLevel.MEDIUM) {
      config.lvl = 'Medium';
      config.text = 'Flat';
      config.icon = dollar2;
      config.top = isLg ? 142 : 74;
      config.left = isLg ? 212 : 93;
      config.iconTop = isLg ? 73 : 21;
      config.iconLeft = isLg ? 84 : 38;

    } else {
      config.lvl = 'Hard';
      config.text = 'Convex';
      config.icon = dollar3;
      config.top = isLg ? 137 : 71;
      config.left = isLg ? 166 : 70;
      config.iconTop = isLg ? 86 : 33;
      config.iconLeft = isLg ? 382 : 189;
    }
  }

  return config;
};

export const setContestFrameImage = (betType: string, type: 'open' | 'accepted', golf2challenge?: Golf2Challenge.AsObject) => {
  if (type === 'accepted' && betType === 'golf2' && golf2challenge) {
    if (golf2challenge.level === Golf2Challenge.Golf2ChallengeLevel.EASY) return Frame2;
    else if (golf2challenge.level === Golf2Challenge.Golf2ChallengeLevel.MEDIUM) return Frame3;
    else return Frame4;

  } else if (betType === 'golf2') return frame1;
  else if (betType === 'paytable') return image9;
  else return image8;
};

let lastLocationUpdate: GeolocationPosition;

const getCurrentNetworkPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    const options = {enableHighAccuracy: true, timeout: 1000 * 10, maximumAge: 0};

    const showPosition = (position: GeolocationPosition) => {
      resolve(position);
    };

    const showError = (error: GeolocationPositionError) => {
      if (error.code === error.PERMISSION_DENIED) reject(null);
      else resolve(lastLocationUpdate);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError, options);

    } else {
      console.log("Geolocation is not supported by this browser.");
      reject(null);
    }

    // Geolocation.getCurrentPosition(
    //   (position) => {
    //     resolve(position);
    //   },
    //   () => {
    //     // See error code charts below.
    //     reject(null);
    //   },
    //   {enableHighAccuracy: true, timeout: 15000, maximumAge: 10000, showLocationDialog: true, forceRequestLocation: true}
    // );
  });
};

export const getLocation = async (): Promise<GeolocationPosition | null> => {
  try {
    if (!lastLocationUpdate || moment().diff(moment(lastLocationUpdate.timestamp), 'minutes')) {
      const newLocation = await getCurrentNetworkPosition();

      if (!lastLocationUpdate) {
        lastLocationUpdate = newLocation;

      } else {
        const currDistance = distance(lastLocationUpdate.coords.latitude, lastLocationUpdate.coords.longitude, newLocation.coords.latitude, newLocation.coords.longitude, 'K');
        console.log('current distance', currDistance);

        if (currDistance < 3.5) lastLocationUpdate = newLocation;
      }
    }

    return lastLocationUpdate;

  } catch (e) {
    console.log('getLocation e', e);
    return null;
  }
};

export const setVenueIdOrLocation = async (request: any) => {
  const curLocation = await getLocation();

  if (curLocation && curLocation.coords && curLocation.coords.latitude && curLocation.coords.longitude) {
    const location = new Location();

    location.setLatitude(curLocation.coords.latitude);
    location.setLongitude(curLocation.coords.longitude);

    request.setLocation(location);

  } else {
    throw {message: 'Permission to access the location was denied.'};
  }
};

const styles = {
  open_distance_l: [MainStyle.f_w_700, MainStyle.f_s_30, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_33, preventSelect],
  open_distance_s: [MainStyle.f_w_700, MainStyle.f_s_14, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_16, preventSelect],
  open_text_l: [MainStyle.f_w_700, MainStyle.f_s_22, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_22, preventSelect],
  open_text_s: [MainStyle.f_w_700, MainStyle.f_s_12, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_12, preventSelect],
  distance_l: [MainStyle.f_w_700, MainStyle.f_s_40, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_40, preventSelect],
  distance_s: [MainStyle.f_w_700, MainStyle.f_s_20, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_20, preventSelect],
  text_l: [MainStyle.f_w_700, MainStyle.f_s_32, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_30, preventSelect],
  text_s: [MainStyle.f_w_700, MainStyle.f_s_16, MainStyle.c_white, MainStyle.t_a_c, MainStyle.l_h_16, preventSelect],
};

export function convertCentsToDollars(cents: number): string {
  const dollars = cents / 100;
  return dollars.toFixed(2);
}
export const getCardTypeForApplePay = (type: 'Visa' | 'MasterCard') => {
  switch (type) {
    case 'Visa':
      return 'VI';
    case 'MasterCard':
      return 'MC';
    default:
      return null;
  }
};
