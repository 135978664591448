import React, {useContext} from "react";
import {Image, Pressable, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import WhiteIconLogo from "../assets/img/white-icon-logo.png";
import {useNavigation} from "@react-navigation/native";
import {Ionicons} from '@expo/vector-icons';
import {MaterialIcons} from '@expo/vector-icons';
import {colors} from "../assets/styles/colors";
import {numberWithCommas} from "../utils/number";
import {StoreContext} from "./StoreContextProvider";

type Props = { pt?: string | number, pb?: string | number };

const NewHeader = (props: Props) => {
  const {pt, pb} = props;
  const navigation: any = useNavigation();
  const Context = useContext(StoreContext);
  const {balance} = Context;

  return (
    <View style={[MainStyle.row, {paddingTop: pt, paddingBottom: pb}, MainStyle.a_i_c]}>
      <Pressable style={MainStyle.m_l_21} onPress={() => navigation.navigate('challenges')}>
        <Image source={WhiteIconLogo} style={[MainStyle.w_36, MainStyle.h_32]}/>
      </Pressable>

      <View style={MainStyle.flex1}/>

      <Pressable style={styles.settings_icon} onPress={() => Context.setIsHelpModalVisible(true)}>
        <Ionicons name="settings-outline" size={20} color={colors.white}/>
      </Pressable>

      <Pressable style={styles.deposit} onPress={() => navigation.navigate({name: 'account', params: {step: 1}})}>
        <MaterialIcons name="add-circle-outline" size={24} color={colors.newGreenText}/>
        <Text style={styles.balance}>
          ${balance ? numberWithCommas(balance / 100, true) : 0}
        </Text>
      </Pressable>
    </View>
  );
};

const styles = {
  settings_icon: [
    MainStyle.b_w_1, MainStyle.b_c_settings_border, MainStyle.bg_settings, MainStyle.w_48, MainStyle.h_48,
    MainStyle.b_r_40, MainStyle.a_i_c, MainStyle.j_c_c
  ],
  deposit: [
    MainStyle.bg_deposit, MainStyle.h_48, MainStyle.p_l_16, MainStyle.p_r_34, MainStyle.row, MainStyle.a_i_c,
    MainStyle.b_t_l_r_28, MainStyle.b_b_l_r_28, MainStyle.m_l_12
  ],
  balance: [MainStyle.c_new_green_text, MainStyle.Barlow500, MainStyle.f_s_20, MainStyle.m_l_13]
};

export default React.memo(NewHeader);
