import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {View, useWindowDimensions, Platform, Linking} from 'react-native';
import {Bet, Golf2Challenge, Session, User} from '../../../../generated/smg_service_pb';
import MainStyle from '../../../../MainStyle';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {StoreContext} from "../../../components/StoreContextProvider";
import OpenBets from "../../../components/OpenBets";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
  LANDING_PAGE_URL, SESSION_EXPIRE_TIME, SkillMoneyLanding, smgEnvironment, tabletBreakpoint
} from "../../../constants";
import moment from 'moment';
import {useIsFocused, useRoute} from '@react-navigation/native';
import {useSafeAreaInsets} from "react-native-safe-area-context";
import ProtectionAndDisclaimer from "../../../components/ProtectionAndDisclaimer";
import {colors} from "../../../assets/styles/colors";
import ServiceClient from "../../../utils/ServiceClient";
import {CancelModalProps} from "../../../types";
import ContinueAutoPlayModal from "../../../modals/ContinueAutoPlay";
import NewHeader from "../../../components/NewHeader";
import BottomNavigation from "../../../components/BottomNavigation";
import TournamentRegistrationConfirmationModal from "../../../modals/TournamentRegistrationConfirmationModal";
import AlertModal from "../../../modals/AlertModal";
import PlayNowModal from '../../../modals/PlayNowModal';

const Golf2ChallengeLevel = Golf2Challenge.Golf2ChallengeLevel;

type Props = { navigation: any };

const BetSlip = ({navigation}: Props) => {
  const Context = useContext(StoreContext);
  const {cancelModalConfig, user, session, extendedUserData} = Context;
  const formik = useFormik({
    initialValues: {contestAmount: ''},
    validationSchema: Yup.object({contestAmount: Yup.string().required().matches(/^[0-9]*(\.[0-9]{0,2})?$/, 'invalid')}),
    onSubmit: () => console.log('onSubmit')
  });
  const {width} = useWindowDimensions();
  const [BetTypes, setBetTypes] = useState<string[]>([]);
  const [currBetType, setCurrBetType] = useState('paytable');
  const [betsList, setBetsList] = useState<Bet.AsObject[]>([]);
  const [activeBet, setActiveBet] = useState<Bet.AsObject>();
  const isMd = width >= tabletBreakpoint;
  const isFocused = useIsFocused();
  const insets = useSafeAreaInsets();
  const init = useRef(false);
  const route = useRoute();
  const [continueAutoPlay, setContinueAutoPlay] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState<'StartAutoPlay' | 'StopAutoPlay' | ''>('');
  const [alertModalConfig, setAlertModalConfig] = useState({
    isOpen: false, title: '', message: '', firstButtonText: '', hideSecondButton: false
  });
  const [isOpenPlayNowModal, setIsOpenPlayNowModal] = useState(false);

  const enableAutoPlay = (enabled: boolean, fee: number) => {
    Context.setLoadingOverlay(true);

    ServiceClient.enableAutoPlay(enabled, fee).then(() => {
      getUser();

    }).catch(err => {
      console.log('enableAutoPlay err', err);
      Context.setLoadingOverlay(false);

      Context.setCancelModalConfig({
        visible: true, title: 'Error', subtitle: err.message, rightBtnText: 'OK',
        onResume: () => Context.setCancelModalConfig({...cancelModalConfig, visible: false})
      });
    });
  };

  const autoPlayHandler = () => {
    setConfirmModalType('');
    if (user) enableAutoPlay(false, user.autoPlayAmount);
  };

  const challengeFeeChange = (fee: string) => {
    setConfirmModalType('');
    formik.setFieldValue('contestAmount', fee);
    enableAutoPlay(true, fee === '-2' ? -2 : +fee * 100);
  };

  const changeGameType = () => {
    setCurrBetType(currBetType === 'golf2' ? 'paytable' : 'golf2');
  };

  const checkUserAccess = () => {
    if (!user?.tncAccepted) {
      Context.setCancelModalConfig({
        visible: true, title: 'Notice', titleColor: MainStyle.c_yellow, leftBtnText: 'OK',
        subtitle: 'Please accept Terms & Conditions and complete Know Your Customer to create your account and play.',
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.reset({index: 0, routes: [{name: 'Register', params: {step: 2}}]});
        }
      });
      return false;

    } else if (user && user.kycStatus === 5) {
      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', leftBtnText: 'OK',
        subtitle: 'Your KYC details appear to be identical to an existing registration record in our database. We have sent a message to the email originally provided with your KYC credentials suggesting to reset the PIN if you forgot it. If this does not help, You can request Support  to assist with your registration.',
        onExit: () => {
          Context.logout().then(() => {
            setTimeout(() => {
              if (Platform.OS === 'web') window.location.reload();
            }, 150);
            Context.setCancelModalConfig({...cancelModalConfig, visible: false});
            navigation.reset({index: 0, routes: [{name: 'Splash'}]});
          });
        }
      });
      return false;

    } else if (user && user.kycStatus === 6) {
      Context.setCancelModalConfig({
        visible: true, title: 'ERROR', leftBtnText: 'Cancel', rightBtnText: 'Contact Support',
        subtitle: 'Your Know Your Customer information and registration information do not match. Your account with Skill Money Games has been locked. Please contact support to resolve this issue.',
        onResume: () => {
          if (Platform.OS === 'web') {
            window.open('https://smgames.atlassian.net/servicedesk/customer/portal/3', '_self');
          }
        },
        onClose: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 0}});
        }
      });
      return false;

    } else if (!user?.photoAccepted) {
      navigation.reset({index: 0, routes: [{name: 'Register', params: {step: 3}}]});
      return false;

    } else if (user && user.dateOfBirth && moment().diff(moment(user.dateOfBirth), 'years') < 18) {
      Context.setCancelModalConfig({
        visible: true, title: 'Warning', titleColor: MainStyle.c_yellow, leftBtnText: 'OK',
        subtitle: 'You have successfully completed registration. However, you must be 18 or older to play.',
        onExit: () => {
          Context.logout().then(() => {
            setTimeout(() => {
              if (Platform.OS === 'web') window.location.reload();
            }, 150);
            Context.setCancelModalConfig({...cancelModalConfig, visible: false});
            navigation.reset({index: 0, routes: [{name: 'Splash'}]});
          });
        }
      });
      return false;

    } else if (user && user.selfLimitationConfirmed) {
      Context.setCancelModalConfig({
        visible: true, title: 'Notice', titleColor: MainStyle.c_yellow, leftBtnText: 'OK',
        subtitle: `You will not be able to play or deposit money while the Self-Limitation is in effect. End of Self-Limitation on ${moment.unix(user.selfLimitationExpiration).format('ll')}`,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 1}});
        }
      });
      return false;
    }

    return true;
  };

  const setBets = (res: Bet.AsObject[]) => {
    const val: string[] = [];

    res.forEach(el => {
      if (!val.includes(el.betType)) val.push(el.betType);

      // sort golf2challengesList
      if (el.golf2challengesList.length) {
        const EASY = el.golf2challengesList.find(challenge => challenge.level === Golf2ChallengeLevel.EASY);
        const MEDIUM = el.golf2challengesList.find(challenge => challenge.level === Golf2ChallengeLevel.MEDIUM);
        const HARD = el.golf2challengesList.find(challenge => challenge.level === Golf2ChallengeLevel.HARD);
        if (EASY && MEDIUM && HARD) el.golf2challengesList = [EASY, MEDIUM, HARD];
      }
    });

    if (val.length && (!currBetType || !val.includes(currBetType))) setCurrBetType(val[0]);

    setBetTypes(val);
    setBetsList(res);

    setTimeout(() => {
      Context.setLoadingOverlay(false);
    }, 150);
  };

  const GetBets = (state: 'open' | 'accepted', session?: Session.AsObject) => {
    ServiceClient.getBets(state, session).then(res => {
      // show only old game on external
      if (smgEnvironment !== 'dev') res = res.filter(el => el.betType === 'paytable');

      if (state === 'open') {
        if (smgEnvironment === 'dev' ? res.length === 2 : res.length === 1) {
          setBets(res);
          if (extendedUserData.hasactivetournament && !user?.autoPlayEnabled) setContinueAutoPlay(true);

        } else {
          setTimeout(() => {
            GetBets('accepted', session);
          }, 300);
        }

      } else if (state === 'accepted') {
        if (res.length) {
          setBets(res);
          if (extendedUserData.hasactivetournament && !user?.autoPlayEnabled) setContinueAutoPlay(true);

        } else {
          setTimeout(() => {
            GetBets('open', session);
          }, 150);
        }
      }

    }).catch(err => {
      console.log('getBets err', err);
      const params: any = route.params;
      Context.setLoadingOverlay(false);

      let conf: CancelModalProps = {
        visible: true, title: 'ERROR', leftBtnText: 'OK', subtitle: err.message,
        onExit: () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 1}});
        }
      };

      if (err.code === 20020054) {
        conf.leftBtnText = 'Locations';
        conf.titleColor = MainStyle.c_lightblue;
        conf.rightBtnText = 'OK';
        conf.rightBtnBackgroundColor = colors.gray;

        conf.onResume = () => {
          Context.setCancelModalConfig({...cancelModalConfig, visible: false});
          navigation.navigate({name: 'account', params: {step: 0}});
        };

        conf.onExit = () => {
          const url = `${SkillMoneyLanding}/locations/`;
          if (Platform.OS === 'web') window.open(url, '_self');
          else Linking.openURL(url).then();
        };

        if (params && params.fromRegistrationPage) {
          conf.title = 'Congrats! You are all set.';
          conf.subtitle = 'Go to your nearest Skill Money venue to play.';

        } else {
          conf.title = 'Offsite Mode';
          conf.subtitle = 'While away from SMG locations, you can see your profile and account information.';
          conf.secondary = 'To enter challenges, go to your nearest location.';
        }
      }

      Context.setCancelModalConfig(conf);
    });
  };

  const getUser = () => {
    Context.getUser().catch(err => {
      console.log('getUser err', err);
      setTimeout(() => {
        getUser();
      }, 5000);
    });
  };

  useEffect(() => {
    if (activeBet) {
      formik.setFieldValue('contestAmount', `${extendedUserData.isnewplayer || extendedUserData.freeshotnumber !== extendedUserData.freeshotcount ? 3 : activeBet.amount === -2 ? -2 : activeBet.amount / 100}`);

      if (extendedUserData.isnewplayer) {
        (async () => {
          let newPlayers = await AsyncStorage.getItem('newPlayers');

          if (!newPlayers || !newPlayers?.includes(`${user?.userId}`)) {
            setAlertModalConfig({
              isOpen: true, title: 'Build Your Bank!', message: 'You have 10 Free Shots to win as much as possible.',
              firstButtonText: 'Start Now', hideSecondButton: true
            });

            await AsyncStorage.setItem('newPlayers', newPlayers ? newPlayers + `${user?.userId}, ` : `${user?.userId}, `);
          }
        })();

      } else if ((extendedUserData.freeshotnumber === extendedUserData.freeshotcount) && !user?.autoPlayEnabled && !init.current) {
        setConfirmModalType('StartAutoPlay');
        init.current = true;
      }
    }
  }, [activeBet?.betId]);

  useEffect(() => {
    if (betsList.length && currBetType) setActiveBet(betsList.find(el => el.betType === currBetType));
  }, [betsList, currBetType]);

  useEffect(() => {
    if (!user || !isFocused) return;
    let time: any;

    if (user?.kycStatus === 3 || user?.kycStatus === 4) {
      Context.setLoadingOverlay(false);

      time = setTimeout(() => {
        getUser();
      }, 1000 * 10);

    } else if (checkUserAccess()) {
      GetBets('accepted');

      time = setTimeout(() => {
        getUser();
      }, 1000 * 10);

    } else {
      Context.setLoadingOverlay(false);
    }

    return () => {
      clearTimeout(time);
    };
  }, [user, isFocused]);

  useEffect(() => {
    if (!isFocused) return;
    const time = setInterval(() => {
      (async () => {
        const lastActiveTime = await AsyncStorage.getItem('lastActiveTime');

        if (lastActiveTime && moment().diff(moment(lastActiveTime), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            Context.setLoadingOverlay(false);
          });
        }
      })();
    }, 1000 * 60);

    return () => {
      clearInterval(time);
    };
  }, [isFocused]);

  useLayoutEffect(() => {
    if (activeBet) Context.setLoadingOverlay(true);

    if (!session || !user) {
      (async () => {
        const time = await AsyncStorage.getItem('lastActiveTime');

        if (time && moment().diff(moment(time), 'minutes') > SESSION_EXPIRE_TIME) {
          Context.logout().then(() => {
            if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
            Context.setLoadingOverlay(false);
          });

        } else {
          const val = await AsyncStorage.getItem('session');
          let curUser: User.AsObject | undefined;

          if (val) {
            const Session = JSON.parse(val);
            Context.setSession(Session);

            try {
              curUser = await Context.getUser(Session);

            } catch (e: any) {
              console.log('get curUser err', e);
              Context.setCancelModalConfig({
                visible: true, title: 'Error', subtitle: e?.message || 'Error', rightBtnText: 'OK',
                onResume: () => {
                  Context.setCancelModalConfig({...cancelModalConfig, visible: false});
                  Context.logout().then(() => {
                    if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
                    Context.setLoadingOverlay(false);
                  });
                }
              });
            }
          }

          if (!val || !curUser || !curUser.userId) {
            Context.logout().then(() => {
              if (Platform.OS === 'web') window.open(LANDING_PAGE_URL, '_self');
              Context.setLoadingOverlay(false);
            });

          } else console.log('user', curUser);
        }
      })();
    }
  }, []);

  return (
    <>
      <View style={[MainStyle.flex1, {paddingTop: insets.top}]}>
        <View style={MainStyle.flex1}>
          {user?.kycStatus === 3 || user?.kycStatus === 4 ? (
            <>
              <NewHeader/>
              <ProtectionAndDisclaimer
                isLg={isMd}
                text={user?.kycStatus === 3 ? 'KYC verification started' : 'Waiting for KYC confirmation'}
              />
            </>

          ) : (!!activeBet && activeBet.betState === 'open') ? (
            <OpenBets
              bet={activeBet}
              formik={formik}
              title="New Challenge"
              type="open"
              changeGameType={changeGameType}
              moreGames={BetTypes.length > 1}
              showAutoPlayModal={() => setConfirmModalType(user?.autoPlayEnabled ? 'StopAutoPlay' : 'StartAutoPlay')}
            />
          ) : (!!activeBet && activeBet.betState === 'accepted') ? (
            <OpenBets
              bet={activeBet}
              title="Live Challenge"
              type="accepted"
              showAutoPlayModal={() => setConfirmModalType(user?.autoPlayEnabled ? 'StopAutoPlay' : 'StartAutoPlay')}
              formik={formik}
            />
          ) : (
            <>
              <NewHeader/>
              <ProtectionAndDisclaimer isLg={isMd} text="Loading"/>
            </>
          )}
        </View>

        {/*<Button text="MORE GAMES" style={[styles.more_btn_m, styles.stickyBtn_md]} bg={colors.unselect}*/}
        {/*        textStyle={[MainStyle.t_a_c, MainStyle.f_s_18]} onPress={changeGameType} w={150}/>*/}

        <BottomNavigation page="challenges"/>
      </View>

      {!!formik.values.contestAmount && (
        <TournamentRegistrationConfirmationModal
          isOpen={!!confirmModalType}
          onClose={() => setConfirmModalType('')}
          joinTournament={challengeFeeChange}
          type={confirmModalType}
          entryFee={0}
          entryChallengeFee={`${+formik.values.contestAmount * 100}`}
          autoPlayHandler={autoPlayHandler}
          min_h={confirmModalType === 'StopAutoPlay' ? 372 : undefined}
        />
      )}

      <ContinueAutoPlayModal
        isOpen={continueAutoPlay}
        onClose={() => setContinueAutoPlay(false)}
        onReload={getUser}
      />

      <AlertModal
        isOpen={alertModalConfig.isOpen}
        onClose={() => setAlertModalConfig(prevState => ({...prevState, isOpen: false}))}
        message={alertModalConfig.message}
        title={alertModalConfig.title}
        onContinue={() => {
          setIsOpenPlayNowModal(true)
          setAlertModalConfig(prevState => ({...prevState, isOpen: false}))
        }}
        firstButtonText={alertModalConfig.firstButtonText}
        hideSecondButton={alertModalConfig.hideSecondButton}
      />

      <PlayNowModal
        isOpen={isOpenPlayNowModal}
        onClose={() => setIsOpenPlayNowModal(false)}
      />
    </>
  );
};

export default BetSlip;
