// source: smg_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.qb.smg.AcceptBetReply', null, global);
goog.exportSymbol('proto.qb.smg.AcceptBetRequest', null, global);
goog.exportSymbol('proto.qb.smg.AcceptTncReply', null, global);
goog.exportSymbol('proto.qb.smg.AcceptTncRequest', null, global);
goog.exportSymbol('proto.qb.smg.AddFaceReply', null, global);
goog.exportSymbol('proto.qb.smg.AddFaceRequest', null, global);
goog.exportSymbol('proto.qb.smg.AdjustBetReply', null, global);
goog.exportSymbol('proto.qb.smg.AdjustBetRequest', null, global);
goog.exportSymbol('proto.qb.smg.ApproveChangePhotoRequestReply', null, global);
goog.exportSymbol('proto.qb.smg.ApproveChangePhotoRequestRequest', null, global);
goog.exportSymbol('proto.qb.smg.Bet', null, global);
goog.exportSymbol('proto.qb.smg.BetResolutionReply', null, global);
goog.exportSymbol('proto.qb.smg.BetResolutionRequest', null, global);
goog.exportSymbol('proto.qb.smg.Challenge', null, global);
goog.exportSymbol('proto.qb.smg.ChangeType', null, global);
goog.exportSymbol('proto.qb.smg.CheckUserEmailExistsReply', null, global);
goog.exportSymbol('proto.qb.smg.CheckUserEmailExistsRequest', null, global);
goog.exportSymbol('proto.qb.smg.CommandExecutorFRSPayload', null, global);
goog.exportSymbol('proto.qb.smg.CommandExecutorFRSPayload.PayloadCase', null, global);
goog.exportSymbol('proto.qb.smg.CommandExecutorSMGPayload', null, global);
goog.exportSymbol('proto.qb.smg.CommandExecutorSMGPayload.PayloadCase', null, global);
goog.exportSymbol('proto.qb.smg.CompareImagesRequest', null, global);
goog.exportSymbol('proto.qb.smg.CompareImagesResponse', null, global);
goog.exportSymbol('proto.qb.smg.ConfirmEmailChangeRequest', null, global);
goog.exportSymbol('proto.qb.smg.ConfirmEmailChangeResponse', null, global);
goog.exportSymbol('proto.qb.smg.ConfirmEmailReply', null, global);
goog.exportSymbol('proto.qb.smg.ConfirmEmailRequest', null, global);
goog.exportSymbol('proto.qb.smg.ConnectStationSimpleReply', null, global);
goog.exportSymbol('proto.qb.smg.ConnectStationSimpleRequest', null, global);
goog.exportSymbol('proto.qb.smg.ContactCsrReply', null, global);
goog.exportSymbol('proto.qb.smg.ContactCsrRequest', null, global);
goog.exportSymbol('proto.qb.smg.DeactivateAccountRequest', null, global);
goog.exportSymbol('proto.qb.smg.DeactivateAccountResponse', null, global);
goog.exportSymbol('proto.qb.smg.DeleteLastActiveBeforeDateRequest', null, global);
goog.exportSymbol('proto.qb.smg.DeleteLastActiveBeforeDateResponse', null, global);
goog.exportSymbol('proto.qb.smg.DeleteUserImageRequest', null, global);
goog.exportSymbol('proto.qb.smg.DeleteUserImageResponse', null, global);
goog.exportSymbol('proto.qb.smg.DeleteUserImagesBeforeDateRequest', null, global);
goog.exportSymbol('proto.qb.smg.DeleteUserImagesBeforeDateResponse', null, global);
goog.exportSymbol('proto.qb.smg.DisableUsersRequest', null, global);
goog.exportSymbol('proto.qb.smg.DisableUsersResponse', null, global);
goog.exportSymbol('proto.qb.smg.DisplayMessage', null, global);
goog.exportSymbol('proto.qb.smg.DoResetPinReply', null, global);
goog.exportSymbol('proto.qb.smg.DoResetPinRequest', null, global);
goog.exportSymbol('proto.qb.smg.DriverLicenseBackExtractedData', null, global);
goog.exportSymbol('proto.qb.smg.DriverLicenseFrontExtractedData', null, global);
goog.exportSymbol('proto.qb.smg.EmailLoginReply', null, global);
goog.exportSymbol('proto.qb.smg.EmailLoginRequest', null, global);
goog.exportSymbol('proto.qb.smg.EnableAutoPlayRequest', null, global);
goog.exportSymbol('proto.qb.smg.EnableAutoPlayResponse', null, global);
goog.exportSymbol('proto.qb.smg.EnableSelfLimitationReply', null, global);
goog.exportSymbol('proto.qb.smg.EnableSelfLimitationRequest', null, global);
goog.exportSymbol('proto.qb.smg.EnableSelfLimitationRequest.TimeFrame', null, global);
goog.exportSymbol('proto.qb.smg.EnableShawWalletRequest', null, global);
goog.exportSymbol('proto.qb.smg.EnableShawWalletResponse', null, global);
goog.exportSymbol('proto.qb.smg.Error', null, global);
goog.exportSymbol('proto.qb.smg.FrsHeartbeatRequest', null, global);
goog.exportSymbol('proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest', null, global);
goog.exportSymbol('proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse', null, global);
goog.exportSymbol('proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest', null, global);
goog.exportSymbol('proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse', null, global);
goog.exportSymbol('proto.qb.smg.GetBalanceReply', null, global);
goog.exportSymbol('proto.qb.smg.GetBalanceRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetBetNoTokenReply', null, global);
goog.exportSymbol('proto.qb.smg.GetBetNoTokenRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetBetReply', null, global);
goog.exportSymbol('proto.qb.smg.GetBetRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetBetsReply', null, global);
goog.exportSymbol('proto.qb.smg.GetBetsRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetChangePhotoRequestsReply', null, global);
goog.exportSymbol('proto.qb.smg.GetChangePhotoRequestsRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetGameStateReply', null, global);
goog.exportSymbol('proto.qb.smg.GetGameStateRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetPayoutReply', null, global);
goog.exportSymbol('proto.qb.smg.GetPayoutRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetPromosRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetPromosResponse', null, global);
goog.exportSymbol('proto.qb.smg.GetTncReply', null, global);
goog.exportSymbol('proto.qb.smg.GetTncRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetTournamentReply', null, global);
goog.exportSymbol('proto.qb.smg.GetTournamentRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetTransactionHistoryReply', null, global);
goog.exportSymbol('proto.qb.smg.GetTransactionHistoryRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetUpcomingBetsForSimulatorReply', null, global);
goog.exportSymbol('proto.qb.smg.GetUpcomingBetsForSimulatorRequest', null, global);
goog.exportSymbol('proto.qb.smg.GetUserReply', null, global);
goog.exportSymbol('proto.qb.smg.GetUserRequest', null, global);
goog.exportSymbol('proto.qb.smg.Golf2Challenge', null, global);
goog.exportSymbol('proto.qb.smg.Golf2Challenge.Golf2ChallengeLevel', null, global);
goog.exportSymbol('proto.qb.smg.Golf2GameCircle', null, global);
goog.exportSymbol('proto.qb.smg.Golf2GameDetails', null, global);
goog.exportSymbol('proto.qb.smg.Golf2GameGreen', null, global);
goog.exportSymbol('proto.qb.smg.Golf2GameHole', null, global);
goog.exportSymbol('proto.qb.smg.HeartbeatRequest', null, global);
goog.exportSymbol('proto.qb.smg.HeartbeatResponse', null, global);
goog.exportSymbol('proto.qb.smg.Hit', null, global);
goog.exportSymbol('proto.qb.smg.HitReply', null, global);
goog.exportSymbol('proto.qb.smg.HitRequest', null, global);
goog.exportSymbol('proto.qb.smg.HitStreamReply', null, global);
goog.exportSymbol('proto.qb.smg.HoleInOneConfig', null, global);
goog.exportSymbol('proto.qb.smg.IgnoreFrsRequest', null, global);
goog.exportSymbol('proto.qb.smg.IgnoreFrsResponse', null, global);
goog.exportSymbol('proto.qb.smg.ImageLoginReply', null, global);
goog.exportSymbol('proto.qb.smg.ImageLoginRequest', null, global);
goog.exportSymbol('proto.qb.smg.InitialCommandConnectorRequest', null, global);
goog.exportSymbol('proto.qb.smg.InitialCommandConnectorResponse', null, global);
goog.exportSymbol('proto.qb.smg.JoinTournamentReply', null, global);
goog.exportSymbol('proto.qb.smg.JoinTournamentRequest', null, global);
goog.exportSymbol('proto.qb.smg.LeaveTournamentReply', null, global);
goog.exportSymbol('proto.qb.smg.LeaveTournamentRequest', null, global);
goog.exportSymbol('proto.qb.smg.ListFacesRequest', null, global);
goog.exportSymbol('proto.qb.smg.ListFacesResponse', null, global);
goog.exportSymbol('proto.qb.smg.ListTournamentReply', null, global);
goog.exportSymbol('proto.qb.smg.ListTournamentRequest', null, global);
goog.exportSymbol('proto.qb.smg.ListTournamentRequest.Sort', null, global);
goog.exportSymbol('proto.qb.smg.LocateVenueReply', null, global);
goog.exportSymbol('proto.qb.smg.LocateVenueRequest', null, global);
goog.exportSymbol('proto.qb.smg.Location', null, global);
goog.exportSymbol('proto.qb.smg.LoginWithIdTokenReply', null, global);
goog.exportSymbol('proto.qb.smg.LoginWithIdTokenRequest', null, global);
goog.exportSymbol('proto.qb.smg.OcrResult', null, global);
goog.exportSymbol('proto.qb.smg.Payline', null, global);
goog.exportSymbol('proto.qb.smg.Payout', null, global);
goog.exportSymbol('proto.qb.smg.Promo', null, global);
goog.exportSymbol('proto.qb.smg.PushStationWebcamUpdatesResponse', null, global);
goog.exportSymbol('proto.qb.smg.RecognizeFaceRequest', null, global);
goog.exportSymbol('proto.qb.smg.RecognizeFaceResponse', null, global);
goog.exportSymbol('proto.qb.smg.RegisterUserReply', null, global);
goog.exportSymbol('proto.qb.smg.RegisterUserRequest', null, global);
goog.exportSymbol('proto.qb.smg.ReplacePhotoRequest', null, global);
goog.exportSymbol('proto.qb.smg.ReplacePhotoStatus', null, global);
goog.exportSymbol('proto.qb.smg.RequestChangePhotoReply', null, global);
goog.exportSymbol('proto.qb.smg.RequestChangePhotoRequest', null, global);
goog.exportSymbol('proto.qb.smg.RequestChangeRequest', null, global);
goog.exportSymbol('proto.qb.smg.RequestChangeResponse', null, global);
goog.exportSymbol('proto.qb.smg.RequestEmailChangeRequest', null, global);
goog.exportSymbol('proto.qb.smg.RequestEmailChangeResponse', null, global);
goog.exportSymbol('proto.qb.smg.RequestEmailCodeReply', null, global);
goog.exportSymbol('proto.qb.smg.RequestEmailCodeRequest', null, global);
goog.exportSymbol('proto.qb.smg.RequestResetPinReply', null, global);
goog.exportSymbol('proto.qb.smg.RequestResetPinRequest', null, global);
goog.exportSymbol('proto.qb.smg.RequestSmsCodeReply', null, global);
goog.exportSymbol('proto.qb.smg.RequestSmsCodeRequest', null, global);
goog.exportSymbol('proto.qb.smg.ResendEmailConfirmationReply', null, global);
goog.exportSymbol('proto.qb.smg.ResendEmailConfirmationRequest', null, global);
goog.exportSymbol('proto.qb.smg.ResetIgnoreFrsRequest', null, global);
goog.exportSymbol('proto.qb.smg.ResetIgnoreFrsResponse', null, global);
goog.exportSymbol('proto.qb.smg.ResetKycReply', null, global);
goog.exportSymbol('proto.qb.smg.ResetKycRequest', null, global);
goog.exportSymbol('proto.qb.smg.ResetShotCountRequest', null, global);
goog.exportSymbol('proto.qb.smg.ResetShotCountResponse', null, global);
goog.exportSymbol('proto.qb.smg.Session', null, global);
goog.exportSymbol('proto.qb.smg.SetFaceRequest', null, global);
goog.exportSymbol('proto.qb.smg.SetFaceResponse', null, global);
goog.exportSymbol('proto.qb.smg.SetWelcomeBonusRequest', null, global);
goog.exportSymbol('proto.qb.smg.SetWelcomeBonusResponse', null, global);
goog.exportSymbol('proto.qb.smg.SettingsUpdate', null, global);
goog.exportSymbol('proto.qb.smg.SettingsUpdateStreamRequest', null, global);
goog.exportSymbol('proto.qb.smg.Skills', null, global);
goog.exportSymbol('proto.qb.smg.SmsLoginReply', null, global);
goog.exportSymbol('proto.qb.smg.SmsLoginRequest', null, global);
goog.exportSymbol('proto.qb.smg.StartHitReply', null, global);
goog.exportSymbol('proto.qb.smg.StartHitRequest', null, global);
goog.exportSymbol('proto.qb.smg.StartVeriffKycVerificationRequest', null, global);
goog.exportSymbol('proto.qb.smg.StartVeriffKycVerificationResponse', null, global);
goog.exportSymbol('proto.qb.smg.StationMessage', null, global);
goog.exportSymbol('proto.qb.smg.StationPlayerState', null, global);
goog.exportSymbol('proto.qb.smg.StationState', null, global);
goog.exportSymbol('proto.qb.smg.StationWebcamUpdate', null, global);
goog.exportSymbol('proto.qb.smg.StationWebcamUpdate.WebcamState', null, global);
goog.exportSymbol('proto.qb.smg.StationWebcamUpdate.WebcamState.Side', null, global);
goog.exportSymbol('proto.qb.smg.StreamBetsReply', null, global);
goog.exportSymbol('proto.qb.smg.StreamBetsRequest', null, global);
goog.exportSymbol('proto.qb.smg.StreamStationStateRequest', null, global);
goog.exportSymbol('proto.qb.smg.StreamTournamentLeaderBoardRequest', null, global);
goog.exportSymbol('proto.qb.smg.SubmitPhotoReply', null, global);
goog.exportSymbol('proto.qb.smg.SubmitPhotoRequest', null, global);
goog.exportSymbol('proto.qb.smg.Tournament', null, global);
goog.exportSymbol('proto.qb.smg.Tournament.State', null, global);
goog.exportSymbol('proto.qb.smg.TournamentLeaderBoard', null, global);
goog.exportSymbol('proto.qb.smg.TournamentLeaderBoardEntry', null, global);
goog.exportSymbol('proto.qb.smg.TournamentPlayerBet', null, global);
goog.exportSymbol('proto.qb.smg.TournamentPlayerBet.State', null, global);
goog.exportSymbol('proto.qb.smg.TournamentPlayerDetails', null, global);
goog.exportSymbol('proto.qb.smg.TournamentPlayerDetails.State', null, global);
goog.exportSymbol('proto.qb.smg.TransactionHistory', null, global);
goog.exportSymbol('proto.qb.smg.UpdatePhoneNumberRequest', null, global);
goog.exportSymbol('proto.qb.smg.UpdatePhoneNumberResponse', null, global);
goog.exportSymbol('proto.qb.smg.UpdateUserReply', null, global);
goog.exportSymbol('proto.qb.smg.UpdateUserRequest', null, global);
goog.exportSymbol('proto.qb.smg.UploadImageReply', null, global);
goog.exportSymbol('proto.qb.smg.UploadImageRequest', null, global);
goog.exportSymbol('proto.qb.smg.User', null, global);
goog.exportSymbol('proto.qb.smg.VenueConfig', null, global);
goog.exportSymbol('proto.qb.smg.VerifySmsCodeReply', null, global);
goog.exportSymbol('proto.qb.smg.VerifySmsCodeRequest', null, global);
goog.exportSymbol('proto.qb.smg.WalletBonus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.TournamentPlayerBet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.TournamentPlayerBet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.TournamentPlayerBet.displayName = 'proto.qb.smg.TournamentPlayerBet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.TournamentPlayerDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.TournamentPlayerDetails.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.TournamentPlayerDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.TournamentPlayerDetails.displayName = 'proto.qb.smg.TournamentPlayerDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Tournament = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.Tournament.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.Tournament, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Tournament.displayName = 'proto.qb.smg.Tournament';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ListTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ListTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ListTournamentRequest.displayName = 'proto.qb.smg.ListTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ListTournamentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.ListTournamentReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.ListTournamentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ListTournamentReply.displayName = 'proto.qb.smg.ListTournamentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetTournamentRequest.displayName = 'proto.qb.smg.GetTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetTournamentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetTournamentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetTournamentReply.displayName = 'proto.qb.smg.GetTournamentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.JoinTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.JoinTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.JoinTournamentRequest.displayName = 'proto.qb.smg.JoinTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.JoinTournamentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.JoinTournamentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.JoinTournamentReply.displayName = 'proto.qb.smg.JoinTournamentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.LeaveTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.LeaveTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.LeaveTournamentRequest.displayName = 'proto.qb.smg.LeaveTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.LeaveTournamentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.LeaveTournamentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.LeaveTournamentReply.displayName = 'proto.qb.smg.LeaveTournamentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StreamTournamentLeaderBoardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StreamTournamentLeaderBoardRequest.displayName = 'proto.qb.smg.StreamTournamentLeaderBoardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.TournamentLeaderBoardEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.TournamentLeaderBoardEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.TournamentLeaderBoardEntry.displayName = 'proto.qb.smg.TournamentLeaderBoardEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.TournamentLeaderBoard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.TournamentLeaderBoard.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.TournamentLeaderBoard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.TournamentLeaderBoard.displayName = 'proto.qb.smg.TournamentLeaderBoard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.displayName = 'proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.displayName = 'proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestChangeRequest.displayName = 'proto.qb.smg.RequestChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestChangeResponse.displayName = 'proto.qb.smg.RequestChangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.UpdatePhoneNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.UpdatePhoneNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.UpdatePhoneNumberRequest.displayName = 'proto.qb.smg.UpdatePhoneNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.UpdatePhoneNumberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.UpdatePhoneNumberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.UpdatePhoneNumberResponse.displayName = 'proto.qb.smg.UpdatePhoneNumberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestEmailChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestEmailChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestEmailChangeRequest.displayName = 'proto.qb.smg.RequestEmailChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestEmailChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestEmailChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestEmailChangeResponse.displayName = 'proto.qb.smg.RequestEmailChangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ConfirmEmailChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ConfirmEmailChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ConfirmEmailChangeRequest.displayName = 'proto.qb.smg.ConfirmEmailChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ConfirmEmailChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ConfirmEmailChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ConfirmEmailChangeResponse.displayName = 'proto.qb.smg.ConfirmEmailChangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeactivateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeactivateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeactivateAccountRequest.displayName = 'proto.qb.smg.DeactivateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeactivateAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeactivateAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeactivateAccountResponse.displayName = 'proto.qb.smg.DeactivateAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DisableUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.DisableUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.DisableUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DisableUsersRequest.displayName = 'proto.qb.smg.DisableUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DisableUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DisableUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DisableUsersResponse.displayName = 'proto.qb.smg.DisableUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SetFaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SetFaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SetFaceRequest.displayName = 'proto.qb.smg.SetFaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SetFaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SetFaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SetFaceResponse.displayName = 'proto.qb.smg.SetFaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RecognizeFaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RecognizeFaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RecognizeFaceRequest.displayName = 'proto.qb.smg.RecognizeFaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RecognizeFaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RecognizeFaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RecognizeFaceResponse.displayName = 'proto.qb.smg.RecognizeFaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.CompareImagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.CompareImagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.CompareImagesRequest.displayName = 'proto.qb.smg.CompareImagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.CompareImagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.CompareImagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.CompareImagesResponse.displayName = 'proto.qb.smg.CompareImagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ListFacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ListFacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ListFacesRequest.displayName = 'proto.qb.smg.ListFacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ListFacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.ListFacesResponse.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.ListFacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ListFacesResponse.displayName = 'proto.qb.smg.ListFacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.InitialCommandConnectorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.InitialCommandConnectorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.InitialCommandConnectorRequest.displayName = 'proto.qb.smg.InitialCommandConnectorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.InitialCommandConnectorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.InitialCommandConnectorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.InitialCommandConnectorResponse.displayName = 'proto.qb.smg.InitialCommandConnectorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.CommandExecutorFRSPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_);
};
goog.inherits(proto.qb.smg.CommandExecutorFRSPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.CommandExecutorFRSPayload.displayName = 'proto.qb.smg.CommandExecutorFRSPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.CommandExecutorSMGPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_);
};
goog.inherits(proto.qb.smg.CommandExecutorSMGPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.CommandExecutorSMGPayload.displayName = 'proto.qb.smg.CommandExecutorSMGPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.displayName = 'proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.displayName = 'proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StationWebcamUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.StationWebcamUpdate.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.StationWebcamUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StationWebcamUpdate.displayName = 'proto.qb.smg.StationWebcamUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StationWebcamUpdate.WebcamState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StationWebcamUpdate.WebcamState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StationWebcamUpdate.WebcamState.displayName = 'proto.qb.smg.StationWebcamUpdate.WebcamState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.PushStationWebcamUpdatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.PushStationWebcamUpdatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.PushStationWebcamUpdatesResponse.displayName = 'proto.qb.smg.PushStationWebcamUpdatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.UploadImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.UploadImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.UploadImageRequest.displayName = 'proto.qb.smg.UploadImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.UploadImageReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.UploadImageReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.UploadImageReply.displayName = 'proto.qb.smg.UploadImageReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestResetPinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestResetPinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestResetPinRequest.displayName = 'proto.qb.smg.RequestResetPinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestResetPinReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestResetPinReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestResetPinReply.displayName = 'proto.qb.smg.RequestResetPinReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResendEmailConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResendEmailConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResendEmailConfirmationRequest.displayName = 'proto.qb.smg.ResendEmailConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResendEmailConfirmationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResendEmailConfirmationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResendEmailConfirmationReply.displayName = 'proto.qb.smg.ResendEmailConfirmationReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ConfirmEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ConfirmEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ConfirmEmailRequest.displayName = 'proto.qb.smg.ConfirmEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ConfirmEmailReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ConfirmEmailReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ConfirmEmailReply.displayName = 'proto.qb.smg.ConfirmEmailReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DoResetPinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DoResetPinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DoResetPinRequest.displayName = 'proto.qb.smg.DoResetPinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DoResetPinReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DoResetPinReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DoResetPinReply.displayName = 'proto.qb.smg.DoResetPinReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RegisterUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RegisterUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RegisterUserRequest.displayName = 'proto.qb.smg.RegisterUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RegisterUserReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RegisterUserReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RegisterUserReply.displayName = 'proto.qb.smg.RegisterUserReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.LocateVenueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.LocateVenueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.LocateVenueRequest.displayName = 'proto.qb.smg.LocateVenueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.LocateVenueReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.LocateVenueReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.LocateVenueReply.displayName = 'proto.qb.smg.LocateVenueReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.CheckUserEmailExistsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.CheckUserEmailExistsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.CheckUserEmailExistsRequest.displayName = 'proto.qb.smg.CheckUserEmailExistsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.CheckUserEmailExistsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.CheckUserEmailExistsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.CheckUserEmailExistsReply.displayName = 'proto.qb.smg.CheckUserEmailExistsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ReplacePhotoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ReplacePhotoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ReplacePhotoRequest.displayName = 'proto.qb.smg.ReplacePhotoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestChangePhotoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestChangePhotoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestChangePhotoRequest.displayName = 'proto.qb.smg.RequestChangePhotoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestChangePhotoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestChangePhotoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestChangePhotoReply.displayName = 'proto.qb.smg.RequestChangePhotoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetChangePhotoRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetChangePhotoRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetChangePhotoRequestsRequest.displayName = 'proto.qb.smg.GetChangePhotoRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetChangePhotoRequestsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetChangePhotoRequestsReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetChangePhotoRequestsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetChangePhotoRequestsReply.displayName = 'proto.qb.smg.GetChangePhotoRequestsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ApproveChangePhotoRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ApproveChangePhotoRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ApproveChangePhotoRequestRequest.displayName = 'proto.qb.smg.ApproveChangePhotoRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ApproveChangePhotoRequestReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ApproveChangePhotoRequestReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ApproveChangePhotoRequestReply.displayName = 'proto.qb.smg.ApproveChangePhotoRequestReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetTncRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetTncRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetTncRequest.displayName = 'proto.qb.smg.GetTncRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetTncReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetTncReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetTncReply.displayName = 'proto.qb.smg.GetTncReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AcceptTncRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AcceptTncRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AcceptTncRequest.displayName = 'proto.qb.smg.AcceptTncRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AcceptTncReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AcceptTncReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AcceptTncReply.displayName = 'proto.qb.smg.AcceptTncReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.OcrResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.OcrResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.OcrResult.displayName = 'proto.qb.smg.OcrResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DriverLicenseFrontExtractedData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DriverLicenseFrontExtractedData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DriverLicenseFrontExtractedData.displayName = 'proto.qb.smg.DriverLicenseFrontExtractedData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DriverLicenseBackExtractedData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DriverLicenseBackExtractedData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DriverLicenseBackExtractedData.displayName = 'proto.qb.smg.DriverLicenseBackExtractedData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StartVeriffKycVerificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StartVeriffKycVerificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StartVeriffKycVerificationRequest.displayName = 'proto.qb.smg.StartVeriffKycVerificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StartVeriffKycVerificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StartVeriffKycVerificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StartVeriffKycVerificationResponse.displayName = 'proto.qb.smg.StartVeriffKycVerificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SubmitPhotoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SubmitPhotoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SubmitPhotoRequest.displayName = 'proto.qb.smg.SubmitPhotoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SubmitPhotoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SubmitPhotoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SubmitPhotoReply.displayName = 'proto.qb.smg.SubmitPhotoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ContactCsrRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ContactCsrRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ContactCsrRequest.displayName = 'proto.qb.smg.ContactCsrRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ContactCsrReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ContactCsrReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ContactCsrReply.displayName = 'proto.qb.smg.ContactCsrReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.UpdateUserRequest.displayName = 'proto.qb.smg.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.UpdateUserReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.UpdateUserReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.UpdateUserReply.displayName = 'proto.qb.smg.UpdateUserReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EmailLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EmailLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EmailLoginRequest.displayName = 'proto.qb.smg.EmailLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EmailLoginReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EmailLoginReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EmailLoginReply.displayName = 'proto.qb.smg.EmailLoginReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ImageLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ImageLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ImageLoginRequest.displayName = 'proto.qb.smg.ImageLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ImageLoginReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ImageLoginReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ImageLoginReply.displayName = 'proto.qb.smg.ImageLoginReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestSmsCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestSmsCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestSmsCodeRequest.displayName = 'proto.qb.smg.RequestSmsCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestSmsCodeReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestSmsCodeReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestSmsCodeReply.displayName = 'proto.qb.smg.RequestSmsCodeReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestEmailCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestEmailCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestEmailCodeRequest.displayName = 'proto.qb.smg.RequestEmailCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.RequestEmailCodeReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.RequestEmailCodeReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.RequestEmailCodeReply.displayName = 'proto.qb.smg.RequestEmailCodeReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EnableSelfLimitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EnableSelfLimitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EnableSelfLimitationRequest.displayName = 'proto.qb.smg.EnableSelfLimitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EnableSelfLimitationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EnableSelfLimitationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EnableSelfLimitationReply.displayName = 'proto.qb.smg.EnableSelfLimitationReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SmsLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SmsLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SmsLoginRequest.displayName = 'proto.qb.smg.SmsLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SmsLoginReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SmsLoginReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SmsLoginReply.displayName = 'proto.qb.smg.SmsLoginReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.VerifySmsCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.VerifySmsCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.VerifySmsCodeRequest.displayName = 'proto.qb.smg.VerifySmsCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.VerifySmsCodeReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.VerifySmsCodeReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.VerifySmsCodeReply.displayName = 'proto.qb.smg.VerifySmsCodeReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.LoginWithIdTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.LoginWithIdTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.LoginWithIdTokenRequest.displayName = 'proto.qb.smg.LoginWithIdTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.LoginWithIdTokenReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.LoginWithIdTokenReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.LoginWithIdTokenReply.displayName = 'proto.qb.smg.LoginWithIdTokenReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetUserRequest.displayName = 'proto.qb.smg.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetUserReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetUserReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetUserReply.displayName = 'proto.qb.smg.GetUserReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBalanceRequest.displayName = 'proto.qb.smg.GetBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBalanceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetBalanceReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetBalanceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBalanceReply.displayName = 'proto.qb.smg.GetBalanceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.WalletBonus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.WalletBonus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.WalletBonus.displayName = 'proto.qb.smg.WalletBonus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.BetResolutionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.BetResolutionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.BetResolutionRequest.displayName = 'proto.qb.smg.BetResolutionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.BetResolutionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.BetResolutionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.BetResolutionReply.displayName = 'proto.qb.smg.BetResolutionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetBetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBetsRequest.displayName = 'proto.qb.smg.GetBetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBetsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetBetsReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetBetsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBetsReply.displayName = 'proto.qb.smg.GetBetsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetUpcomingBetsForSimulatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetUpcomingBetsForSimulatorRequest.displayName = 'proto.qb.smg.GetUpcomingBetsForSimulatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetUpcomingBetsForSimulatorReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetUpcomingBetsForSimulatorReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetUpcomingBetsForSimulatorReply.displayName = 'proto.qb.smg.GetUpcomingBetsForSimulatorReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetPayoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetPayoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetPayoutRequest.displayName = 'proto.qb.smg.GetPayoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetPayoutReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetPayoutReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetPayoutReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetPayoutReply.displayName = 'proto.qb.smg.GetPayoutReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StreamBetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StreamBetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StreamBetsRequest.displayName = 'proto.qb.smg.StreamBetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StreamBetsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.StreamBetsReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.StreamBetsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StreamBetsReply.displayName = 'proto.qb.smg.StreamBetsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetBetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBetRequest.displayName = 'proto.qb.smg.GetBetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetBetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBetReply.displayName = 'proto.qb.smg.GetBetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBetNoTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetBetNoTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBetNoTokenRequest.displayName = 'proto.qb.smg.GetBetNoTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetBetNoTokenReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetBetNoTokenReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetBetNoTokenReply.displayName = 'proto.qb.smg.GetBetNoTokenReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AcceptBetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AcceptBetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AcceptBetRequest.displayName = 'proto.qb.smg.AcceptBetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AcceptBetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AcceptBetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AcceptBetReply.displayName = 'proto.qb.smg.AcceptBetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AdjustBetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AdjustBetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AdjustBetRequest.displayName = 'proto.qb.smg.AdjustBetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AdjustBetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AdjustBetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AdjustBetReply.displayName = 'proto.qb.smg.AdjustBetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Hit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Hit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Hit.displayName = 'proto.qb.smg.Hit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StartHitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StartHitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StartHitRequest.displayName = 'proto.qb.smg.StartHitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StartHitReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StartHitReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StartHitReply.displayName = 'proto.qb.smg.StartHitReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.HitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.HitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.HitRequest.displayName = 'proto.qb.smg.HitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.HitReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.HitReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.HitReply.displayName = 'proto.qb.smg.HitReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.HitStreamReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.HitStreamReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.HitStreamReply.displayName = 'proto.qb.smg.HitStreamReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AddFaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AddFaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AddFaceRequest.displayName = 'proto.qb.smg.AddFaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.AddFaceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.AddFaceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.AddFaceReply.displayName = 'proto.qb.smg.AddFaceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetGameStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetGameStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetGameStateRequest.displayName = 'proto.qb.smg.GetGameStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.HoleInOneConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.HoleInOneConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.HoleInOneConfig.displayName = 'proto.qb.smg.HoleInOneConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetGameStateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetGameStateReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetGameStateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetGameStateReply.displayName = 'proto.qb.smg.GetGameStateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StreamStationStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StreamStationStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StreamStationStateRequest.displayName = 'proto.qb.smg.StreamStationStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.FrsHeartbeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.FrsHeartbeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.FrsHeartbeatRequest.displayName = 'proto.qb.smg.FrsHeartbeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StationState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StationState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StationState.displayName = 'proto.qb.smg.StationState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.HeartbeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.HeartbeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.HeartbeatRequest.displayName = 'proto.qb.smg.HeartbeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.HeartbeatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.HeartbeatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.HeartbeatResponse.displayName = 'proto.qb.smg.HeartbeatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ConnectStationSimpleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ConnectStationSimpleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ConnectStationSimpleRequest.displayName = 'proto.qb.smg.ConnectStationSimpleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ConnectStationSimpleReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ConnectStationSimpleReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ConnectStationSimpleReply.displayName = 'proto.qb.smg.ConnectStationSimpleReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.StationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.StationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.StationMessage.displayName = 'proto.qb.smg.StationMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DisplayMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DisplayMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DisplayMessage.displayName = 'proto.qb.smg.DisplayMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SettingsUpdateStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SettingsUpdateStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SettingsUpdateStreamRequest.displayName = 'proto.qb.smg.SettingsUpdateStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SettingsUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SettingsUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SettingsUpdate.displayName = 'proto.qb.smg.SettingsUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeleteUserImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeleteUserImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeleteUserImageRequest.displayName = 'proto.qb.smg.DeleteUserImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeleteUserImageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeleteUserImageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeleteUserImageResponse.displayName = 'proto.qb.smg.DeleteUserImageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeleteUserImagesBeforeDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeleteUserImagesBeforeDateRequest.displayName = 'proto.qb.smg.DeleteUserImagesBeforeDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeleteUserImagesBeforeDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeleteUserImagesBeforeDateResponse.displayName = 'proto.qb.smg.DeleteUserImagesBeforeDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeleteLastActiveBeforeDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeleteLastActiveBeforeDateRequest.displayName = 'proto.qb.smg.DeleteLastActiveBeforeDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.DeleteLastActiveBeforeDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.DeleteLastActiveBeforeDateResponse.displayName = 'proto.qb.smg.DeleteLastActiveBeforeDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.IgnoreFrsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.IgnoreFrsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.IgnoreFrsRequest.displayName = 'proto.qb.smg.IgnoreFrsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.IgnoreFrsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.IgnoreFrsResponse.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.IgnoreFrsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.IgnoreFrsResponse.displayName = 'proto.qb.smg.IgnoreFrsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResetIgnoreFrsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResetIgnoreFrsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResetIgnoreFrsRequest.displayName = 'proto.qb.smg.ResetIgnoreFrsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResetIgnoreFrsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResetIgnoreFrsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResetIgnoreFrsResponse.displayName = 'proto.qb.smg.ResetIgnoreFrsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResetShotCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResetShotCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResetShotCountRequest.displayName = 'proto.qb.smg.ResetShotCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResetShotCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResetShotCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResetShotCountResponse.displayName = 'proto.qb.smg.ResetShotCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetTransactionHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetTransactionHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetTransactionHistoryRequest.displayName = 'proto.qb.smg.GetTransactionHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetTransactionHistoryReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetTransactionHistoryReply.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetTransactionHistoryReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetTransactionHistoryReply.displayName = 'proto.qb.smg.GetTransactionHistoryReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EnableAutoPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EnableAutoPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EnableAutoPlayRequest.displayName = 'proto.qb.smg.EnableAutoPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EnableAutoPlayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EnableAutoPlayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EnableAutoPlayResponse.displayName = 'proto.qb.smg.EnableAutoPlayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Challenge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Challenge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Challenge.displayName = 'proto.qb.smg.Challenge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Golf2Challenge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Golf2Challenge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Golf2Challenge.displayName = 'proto.qb.smg.Golf2Challenge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Golf2GameGreen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Golf2GameGreen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Golf2GameGreen.displayName = 'proto.qb.smg.Golf2GameGreen';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Golf2GameHole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Golf2GameHole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Golf2GameHole.displayName = 'proto.qb.smg.Golf2GameHole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Golf2GameCircle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Golf2GameCircle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Golf2GameCircle.displayName = 'proto.qb.smg.Golf2GameCircle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Golf2GameDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Golf2GameDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Golf2GameDetails.displayName = 'proto.qb.smg.Golf2GameDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Bet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.Bet.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.Bet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Bet.displayName = 'proto.qb.smg.Bet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Payout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Payout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Payout.displayName = 'proto.qb.smg.Payout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Payline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Payline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Payline.displayName = 'proto.qb.smg.Payline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Session = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Session, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Session.displayName = 'proto.qb.smg.Session';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.User.displayName = 'proto.qb.smg.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Location.displayName = 'proto.qb.smg.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.VenueConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.VenueConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.VenueConfig.displayName = 'proto.qb.smg.VenueConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Error.displayName = 'proto.qb.smg.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Skills = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Skills, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Skills.displayName = 'proto.qb.smg.Skills';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.TransactionHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.TransactionHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.TransactionHistory.displayName = 'proto.qb.smg.TransactionHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SetWelcomeBonusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SetWelcomeBonusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SetWelcomeBonusRequest.displayName = 'proto.qb.smg.SetWelcomeBonusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.SetWelcomeBonusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.SetWelcomeBonusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.SetWelcomeBonusResponse.displayName = 'proto.qb.smg.SetWelcomeBonusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EnableShawWalletRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EnableShawWalletRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EnableShawWalletRequest.displayName = 'proto.qb.smg.EnableShawWalletRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.EnableShawWalletResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.EnableShawWalletResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.EnableShawWalletResponse.displayName = 'proto.qb.smg.EnableShawWalletResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResetKycRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResetKycRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResetKycRequest.displayName = 'proto.qb.smg.ResetKycRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.ResetKycReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.ResetKycReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.ResetKycReply.displayName = 'proto.qb.smg.ResetKycReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetPromosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.GetPromosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetPromosRequest.displayName = 'proto.qb.smg.GetPromosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.GetPromosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qb.smg.GetPromosResponse.repeatedFields_, null);
};
goog.inherits(proto.qb.smg.GetPromosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.GetPromosResponse.displayName = 'proto.qb.smg.GetPromosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qb.smg.Promo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qb.smg.Promo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qb.smg.Promo.displayName = 'proto.qb.smg.Promo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.TournamentPlayerBet.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.TournamentPlayerBet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.TournamentPlayerBet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentPlayerBet.toObject = function(includeInstance, msg) {
  var f, obj = {
    betId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.TournamentPlayerBet}
 */
proto.qb.smg.TournamentPlayerBet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.TournamentPlayerBet;
  return proto.qb.smg.TournamentPlayerBet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.TournamentPlayerBet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.TournamentPlayerBet}
 */
proto.qb.smg.TournamentPlayerBet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBetId(value);
      break;
    case 2:
      var value = /** @type {!proto.qb.smg.TournamentPlayerBet.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.TournamentPlayerBet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.TournamentPlayerBet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.TournamentPlayerBet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentPlayerBet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qb.smg.TournamentPlayerBet.State = {
  PENDING: 0,
  PLAYING: 1,
  FINISHED: 2
};

/**
 * optional int32 bet_id = 1;
 * @return {number}
 */
proto.qb.smg.TournamentPlayerBet.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TournamentPlayerBet} returns this
 */
proto.qb.smg.TournamentPlayerBet.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional State state = 2;
 * @return {!proto.qb.smg.TournamentPlayerBet.State}
 */
proto.qb.smg.TournamentPlayerBet.prototype.getState = function() {
  return /** @type {!proto.qb.smg.TournamentPlayerBet.State} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qb.smg.TournamentPlayerBet.State} value
 * @return {!proto.qb.smg.TournamentPlayerBet} returns this
 */
proto.qb.smg.TournamentPlayerBet.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.TournamentPlayerDetails.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.TournamentPlayerDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.TournamentPlayerDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentPlayerDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    betsList: jspb.Message.toObjectList(msg.getBetsList(),
    proto.qb.smg.TournamentPlayerBet.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    won: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    score: jspb.Message.getFieldWithDefault(msg, 6, 0),
    prize: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.TournamentPlayerDetails}
 */
proto.qb.smg.TournamentPlayerDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.TournamentPlayerDetails;
  return proto.qb.smg.TournamentPlayerDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.TournamentPlayerDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.TournamentPlayerDetails}
 */
proto.qb.smg.TournamentPlayerDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 3:
      var value = new proto.qb.smg.TournamentPlayerBet;
      reader.readMessage(value,proto.qb.smg.TournamentPlayerBet.deserializeBinaryFromReader);
      msg.addBets(value);
      break;
    case 4:
      var value = /** @type {!proto.qb.smg.TournamentPlayerDetails.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWon(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.TournamentPlayerDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.TournamentPlayerDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentPlayerDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.TournamentPlayerBet.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getWon();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPrize();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qb.smg.TournamentPlayerDetails.State = {
  PENDING: 0,
  PLAYING: 1,
  FINISHED: 2
};

/**
 * optional int32 player_id = 1;
 * @return {number}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
 */
proto.qb.smg.TournamentPlayerDetails.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string player_name = 2;
 * @return {string}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
 */
proto.qb.smg.TournamentPlayerDetails.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TournamentPlayerBet bets = 3;
 * @return {!Array<!proto.qb.smg.TournamentPlayerBet>}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.getBetsList = function() {
  return /** @type{!Array<!proto.qb.smg.TournamentPlayerBet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.TournamentPlayerBet, 3));
};


/**
 * @param {!Array<!proto.qb.smg.TournamentPlayerBet>} value
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
*/
proto.qb.smg.TournamentPlayerDetails.prototype.setBetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.TournamentPlayerBet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.TournamentPlayerBet}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.addBets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.TournamentPlayerBet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
 */
proto.qb.smg.TournamentPlayerDetails.prototype.clearBetsList = function() {
  return this.setBetsList([]);
};


/**
 * optional State state = 4;
 * @return {!proto.qb.smg.TournamentPlayerDetails.State}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.getState = function() {
  return /** @type {!proto.qb.smg.TournamentPlayerDetails.State} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.qb.smg.TournamentPlayerDetails.State} value
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
 */
proto.qb.smg.TournamentPlayerDetails.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool won = 5;
 * @return {boolean}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.getWon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
 */
proto.qb.smg.TournamentPlayerDetails.prototype.setWon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 score = 6;
 * @return {number}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
 */
proto.qb.smg.TournamentPlayerDetails.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 prize = 7;
 * @return {number}
 */
proto.qb.smg.TournamentPlayerDetails.prototype.getPrize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TournamentPlayerDetails} returns this
 */
proto.qb.smg.TournamentPlayerDetails.prototype.setPrize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.Tournament.repeatedFields_ = [12,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Tournament.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Tournament.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Tournament} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Tournament.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isprivate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    criteriastates: jspb.Message.getFieldWithDefault(msg, 4, ""),
    criteriacities: jspb.Message.getFieldWithDefault(msg, 5, ""),
    criteriavenues: jspb.Message.getFieldWithDefault(msg, 6, ""),
    entrybegindate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    entryenddate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    minimumguaranteedpricepool: jspb.Message.getFieldWithDefault(msg, 9, 0),
    entryfee: jspb.Message.getFieldWithDefault(msg, 10, 0),
    rake: jspb.Message.getFieldWithDefault(msg, 11, 0),
    prizepoolList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    currentplayerdetails: (f = msg.getCurrentplayerdetails()) && proto.qb.smg.TournamentPlayerDetails.toObject(includeInstance, f),
    playerdetailsList: jspb.Message.toObjectList(msg.getPlayerdetailsList(),
    proto.qb.smg.TournamentPlayerDetails.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 15, 0),
    maxparticipants: jspb.Message.getFieldWithDefault(msg, 16, 0),
    totalshots: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Tournament}
 */
proto.qb.smg.Tournament.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Tournament;
  return proto.qb.smg.Tournament.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Tournament} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Tournament}
 */
proto.qb.smg.Tournament.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsprivate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriastates(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriacities(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriavenues(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEntrybegindate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEntryenddate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinimumguaranteedpricepool(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntryfee(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRake(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrizepool(values[i]);
      }
      break;
    case 13:
      var value = new proto.qb.smg.TournamentPlayerDetails;
      reader.readMessage(value,proto.qb.smg.TournamentPlayerDetails.deserializeBinaryFromReader);
      msg.setCurrentplayerdetails(value);
      break;
    case 14:
      var value = new proto.qb.smg.TournamentPlayerDetails;
      reader.readMessage(value,proto.qb.smg.TournamentPlayerDetails.deserializeBinaryFromReader);
      msg.addPlayerdetails(value);
      break;
    case 15:
      var value = /** @type {!proto.qb.smg.Tournament.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxparticipants(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Tournament.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Tournament.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Tournament} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Tournament.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsprivate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCriteriastates();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCriteriacities();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCriteriavenues();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEntrybegindate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEntryenddate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getMinimumguaranteedpricepool();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getEntryfee();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getRake();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getPrizepoolList();
  if (f.length > 0) {
    writer.writePackedInt32(
      12,
      f
    );
  }
  f = message.getCurrentplayerdetails();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.qb.smg.TournamentPlayerDetails.serializeBinaryToWriter
    );
  }
  f = message.getPlayerdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.qb.smg.TournamentPlayerDetails.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getMaxparticipants();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getTotalshots();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qb.smg.Tournament.State = {
  PENDING: 0,
  PUBLISHED: 1,
  PLAYING: 2,
  FINISHED: 3,
  CANCELLED: 4
};

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.qb.smg.Tournament.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isPrivate = 3;
 * @return {boolean}
 */
proto.qb.smg.Tournament.prototype.getIsprivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setIsprivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string criteriaStates = 4;
 * @return {string}
 */
proto.qb.smg.Tournament.prototype.getCriteriastates = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setCriteriastates = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string criteriaCities = 5;
 * @return {string}
 */
proto.qb.smg.Tournament.prototype.getCriteriacities = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setCriteriacities = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string criteriaVenues = 6;
 * @return {string}
 */
proto.qb.smg.Tournament.prototype.getCriteriavenues = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setCriteriavenues = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 entryBeginDate = 7;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getEntrybegindate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setEntrybegindate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 entryEndDate = 8;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getEntryenddate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setEntryenddate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 minimumGuaranteedPricePool = 9;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getMinimumguaranteedpricepool = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setMinimumguaranteedpricepool = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 entryFee = 10;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getEntryfee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setEntryfee = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 rake = 11;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getRake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setRake = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated int32 prizePool = 12;
 * @return {!Array<number>}
 */
proto.qb.smg.Tournament.prototype.getPrizepoolList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setPrizepoolList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.addPrizepool = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.clearPrizepoolList = function() {
  return this.setPrizepoolList([]);
};


/**
 * optional TournamentPlayerDetails currentPlayerDetails = 13;
 * @return {?proto.qb.smg.TournamentPlayerDetails}
 */
proto.qb.smg.Tournament.prototype.getCurrentplayerdetails = function() {
  return /** @type{?proto.qb.smg.TournamentPlayerDetails} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.TournamentPlayerDetails, 13));
};


/**
 * @param {?proto.qb.smg.TournamentPlayerDetails|undefined} value
 * @return {!proto.qb.smg.Tournament} returns this
*/
proto.qb.smg.Tournament.prototype.setCurrentplayerdetails = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.clearCurrentplayerdetails = function() {
  return this.setCurrentplayerdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.Tournament.prototype.hasCurrentplayerdetails = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated TournamentPlayerDetails playerDetails = 14;
 * @return {!Array<!proto.qb.smg.TournamentPlayerDetails>}
 */
proto.qb.smg.Tournament.prototype.getPlayerdetailsList = function() {
  return /** @type{!Array<!proto.qb.smg.TournamentPlayerDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.TournamentPlayerDetails, 14));
};


/**
 * @param {!Array<!proto.qb.smg.TournamentPlayerDetails>} value
 * @return {!proto.qb.smg.Tournament} returns this
*/
proto.qb.smg.Tournament.prototype.setPlayerdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.qb.smg.TournamentPlayerDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.TournamentPlayerDetails}
 */
proto.qb.smg.Tournament.prototype.addPlayerdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.qb.smg.TournamentPlayerDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.clearPlayerdetailsList = function() {
  return this.setPlayerdetailsList([]);
};


/**
 * optional State state = 15;
 * @return {!proto.qb.smg.Tournament.State}
 */
proto.qb.smg.Tournament.prototype.getState = function() {
  return /** @type {!proto.qb.smg.Tournament.State} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.qb.smg.Tournament.State} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int32 maxParticipants = 16;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getMaxparticipants = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setMaxparticipants = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 totalShots = 17;
 * @return {number}
 */
proto.qb.smg.Tournament.prototype.getTotalshots = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Tournament} returns this
 */
proto.qb.smg.Tournament.prototype.setTotalshots = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ListTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ListTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ListTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    upcoming: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    mine: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    livenow: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ListTournamentRequest}
 */
proto.qb.smg.ListTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ListTournamentRequest;
  return proto.qb.smg.ListTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ListTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ListTournamentRequest}
 */
proto.qb.smg.ListTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpcoming(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMine(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLivenow(value);
      break;
    case 5:
      var value = /** @type {!proto.qb.smg.ListTournamentRequest.Sort} */ (reader.readEnum());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ListTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ListTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ListTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpcoming();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMine();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLivenow();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qb.smg.ListTournamentRequest.Sort = {
  ENTRY_BEGIN_DATE_SOONEST: 0,
  ENTRY_BEGIN_DATE_LATEST: 1,
  ENTRY_END_DATE_SOONEST: 2,
  ENTRY_END_DATE_LATEST: 3
};

/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.ListTournamentRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ListTournamentRequest} returns this
 */
proto.qb.smg.ListTournamentRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool upcoming = 2;
 * @return {boolean}
 */
proto.qb.smg.ListTournamentRequest.prototype.getUpcoming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ListTournamentRequest} returns this
 */
proto.qb.smg.ListTournamentRequest.prototype.setUpcoming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool mine = 3;
 * @return {boolean}
 */
proto.qb.smg.ListTournamentRequest.prototype.getMine = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ListTournamentRequest} returns this
 */
proto.qb.smg.ListTournamentRequest.prototype.setMine = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool liveNow = 4;
 * @return {boolean}
 */
proto.qb.smg.ListTournamentRequest.prototype.getLivenow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ListTournamentRequest} returns this
 */
proto.qb.smg.ListTournamentRequest.prototype.setLivenow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional Sort sort = 5;
 * @return {!proto.qb.smg.ListTournamentRequest.Sort}
 */
proto.qb.smg.ListTournamentRequest.prototype.getSort = function() {
  return /** @type {!proto.qb.smg.ListTournamentRequest.Sort} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qb.smg.ListTournamentRequest.Sort} value
 * @return {!proto.qb.smg.ListTournamentRequest} returns this
 */
proto.qb.smg.ListTournamentRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.ListTournamentReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ListTournamentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ListTournamentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ListTournamentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListTournamentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    tournamentsList: jspb.Message.toObjectList(msg.getTournamentsList(),
    proto.qb.smg.Tournament.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ListTournamentReply}
 */
proto.qb.smg.ListTournamentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ListTournamentReply;
  return proto.qb.smg.ListTournamentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ListTournamentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ListTournamentReply}
 */
proto.qb.smg.ListTournamentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Tournament;
      reader.readMessage(value,proto.qb.smg.Tournament.deserializeBinaryFromReader);
      msg.addTournaments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ListTournamentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ListTournamentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ListTournamentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListTournamentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getTournamentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.Tournament.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.ListTournamentReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ListTournamentReply} returns this
 */
proto.qb.smg.ListTournamentReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ListTournamentReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ListTournamentReply} returns this
*/
proto.qb.smg.ListTournamentReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ListTournamentReply} returns this
 */
proto.qb.smg.ListTournamentReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ListTournamentReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Tournament tournaments = 3;
 * @return {!Array<!proto.qb.smg.Tournament>}
 */
proto.qb.smg.ListTournamentReply.prototype.getTournamentsList = function() {
  return /** @type{!Array<!proto.qb.smg.Tournament>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Tournament, 3));
};


/**
 * @param {!Array<!proto.qb.smg.Tournament>} value
 * @return {!proto.qb.smg.ListTournamentReply} returns this
*/
proto.qb.smg.ListTournamentReply.prototype.setTournamentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.Tournament=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Tournament}
 */
proto.qb.smg.ListTournamentReply.prototype.addTournaments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.Tournament, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.ListTournamentReply} returns this
 */
proto.qb.smg.ListTournamentReply.prototype.clearTournamentsList = function() {
  return this.setTournamentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tournamentid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetTournamentRequest}
 */
proto.qb.smg.GetTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetTournamentRequest;
  return proto.qb.smg.GetTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetTournamentRequest}
 */
proto.qb.smg.GetTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTournamentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTournamentid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetTournamentRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetTournamentRequest} returns this
 */
proto.qb.smg.GetTournamentRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tournamentId = 2;
 * @return {number}
 */
proto.qb.smg.GetTournamentRequest.prototype.getTournamentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetTournamentRequest} returns this
 */
proto.qb.smg.GetTournamentRequest.prototype.setTournamentid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetTournamentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetTournamentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetTournamentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTournamentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    tournament: (f = msg.getTournament()) && proto.qb.smg.Tournament.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetTournamentReply}
 */
proto.qb.smg.GetTournamentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetTournamentReply;
  return proto.qb.smg.GetTournamentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetTournamentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetTournamentReply}
 */
proto.qb.smg.GetTournamentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Tournament;
      reader.readMessage(value,proto.qb.smg.Tournament.deserializeBinaryFromReader);
      msg.setTournament(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetTournamentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetTournamentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetTournamentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTournamentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getTournament();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Tournament.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetTournamentReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetTournamentReply} returns this
 */
proto.qb.smg.GetTournamentReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetTournamentReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetTournamentReply} returns this
*/
proto.qb.smg.GetTournamentReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetTournamentReply} returns this
 */
proto.qb.smg.GetTournamentReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetTournamentReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Tournament tournament = 3;
 * @return {?proto.qb.smg.Tournament}
 */
proto.qb.smg.GetTournamentReply.prototype.getTournament = function() {
  return /** @type{?proto.qb.smg.Tournament} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Tournament, 3));
};


/**
 * @param {?proto.qb.smg.Tournament|undefined} value
 * @return {!proto.qb.smg.GetTournamentReply} returns this
*/
proto.qb.smg.GetTournamentReply.prototype.setTournament = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetTournamentReply} returns this
 */
proto.qb.smg.GetTournamentReply.prototype.clearTournament = function() {
  return this.setTournament(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetTournamentReply.prototype.hasTournament = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.JoinTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.JoinTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.JoinTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.JoinTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tournamentid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    betentreefee: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueid: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.JoinTournamentRequest}
 */
proto.qb.smg.JoinTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.JoinTournamentRequest;
  return proto.qb.smg.JoinTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.JoinTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.JoinTournamentRequest}
 */
proto.qb.smg.JoinTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTournamentid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBetentreefee(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.JoinTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.JoinTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.JoinTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.JoinTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTournamentid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBetentreefee();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueid();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.JoinTournamentRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.JoinTournamentRequest} returns this
 */
proto.qb.smg.JoinTournamentRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tournamentId = 2;
 * @return {number}
 */
proto.qb.smg.JoinTournamentRequest.prototype.getTournamentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.JoinTournamentRequest} returns this
 */
proto.qb.smg.JoinTournamentRequest.prototype.setTournamentid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 betEntreeFee = 3;
 * @return {number}
 */
proto.qb.smg.JoinTournamentRequest.prototype.getBetentreefee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.JoinTournamentRequest} returns this
 */
proto.qb.smg.JoinTournamentRequest.prototype.setBetentreefee = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.JoinTournamentRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.JoinTournamentRequest} returns this
*/
proto.qb.smg.JoinTournamentRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.JoinTournamentRequest} returns this
 */
proto.qb.smg.JoinTournamentRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.JoinTournamentRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venueId = 102;
 * @return {number}
 */
proto.qb.smg.JoinTournamentRequest.prototype.getVenueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.JoinTournamentRequest} returns this
 */
proto.qb.smg.JoinTournamentRequest.prototype.setVenueid = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.JoinTournamentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.JoinTournamentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.JoinTournamentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.JoinTournamentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.JoinTournamentReply}
 */
proto.qb.smg.JoinTournamentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.JoinTournamentReply;
  return proto.qb.smg.JoinTournamentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.JoinTournamentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.JoinTournamentReply}
 */
proto.qb.smg.JoinTournamentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.JoinTournamentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.JoinTournamentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.JoinTournamentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.JoinTournamentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.JoinTournamentReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.JoinTournamentReply} returns this
 */
proto.qb.smg.JoinTournamentReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.JoinTournamentReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.JoinTournamentReply} returns this
*/
proto.qb.smg.JoinTournamentReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.JoinTournamentReply} returns this
 */
proto.qb.smg.JoinTournamentReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.JoinTournamentReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.LeaveTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.LeaveTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.LeaveTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LeaveTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tournamentid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.LeaveTournamentRequest}
 */
proto.qb.smg.LeaveTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.LeaveTournamentRequest;
  return proto.qb.smg.LeaveTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.LeaveTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.LeaveTournamentRequest}
 */
proto.qb.smg.LeaveTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTournamentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.LeaveTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.LeaveTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.LeaveTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LeaveTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTournamentid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.LeaveTournamentRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.LeaveTournamentRequest} returns this
 */
proto.qb.smg.LeaveTournamentRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tournamentId = 2;
 * @return {number}
 */
proto.qb.smg.LeaveTournamentRequest.prototype.getTournamentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.LeaveTournamentRequest} returns this
 */
proto.qb.smg.LeaveTournamentRequest.prototype.setTournamentid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.LeaveTournamentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.LeaveTournamentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.LeaveTournamentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LeaveTournamentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.LeaveTournamentReply}
 */
proto.qb.smg.LeaveTournamentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.LeaveTournamentReply;
  return proto.qb.smg.LeaveTournamentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.LeaveTournamentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.LeaveTournamentReply}
 */
proto.qb.smg.LeaveTournamentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.LeaveTournamentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.LeaveTournamentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.LeaveTournamentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LeaveTournamentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.LeaveTournamentReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.LeaveTournamentReply} returns this
 */
proto.qb.smg.LeaveTournamentReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.LeaveTournamentReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.LeaveTournamentReply} returns this
*/
proto.qb.smg.LeaveTournamentReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LeaveTournamentReply} returns this
 */
proto.qb.smg.LeaveTournamentReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LeaveTournamentReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StreamTournamentLeaderBoardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StreamTournamentLeaderBoardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stationid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    topn: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StreamTournamentLeaderBoardRequest}
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StreamTournamentLeaderBoardRequest;
  return proto.qb.smg.StreamTournamentLeaderBoardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StreamTournamentLeaderBoardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StreamTournamentLeaderBoardRequest}
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStationid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTopn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StreamTournamentLeaderBoardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StreamTournamentLeaderBoardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStationid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTopn();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StreamTournamentLeaderBoardRequest} returns this
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 stationId = 2;
 * @return {number}
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.getStationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StreamTournamentLeaderBoardRequest} returns this
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 topN = 3;
 * @return {number}
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.getTopn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StreamTournamentLeaderBoardRequest} returns this
 */
proto.qb.smg.StreamTournamentLeaderBoardRequest.prototype.setTopn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.TournamentLeaderBoardEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.TournamentLeaderBoardEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentLeaderBoardEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    iscurrentuser: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    rank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    score: jspb.Message.getFieldWithDefault(msg, 3, 0),
    shotscompleted: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry}
 */
proto.qb.smg.TournamentLeaderBoardEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.TournamentLeaderBoardEntry;
  return proto.qb.smg.TournamentLeaderBoardEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.TournamentLeaderBoardEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry}
 */
proto.qb.smg.TournamentLeaderBoardEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscurrentuser(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayername(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShotscompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.TournamentLeaderBoardEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.TournamentLeaderBoardEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentLeaderBoardEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIscurrentuser();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getShotscompleted();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool isCurrentUser = 5;
 * @return {boolean}
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.getIscurrentuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry} returns this
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.setIscurrentuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 rank = 1;
 * @return {number}
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry} returns this
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string playerName = 2;
 * @return {string}
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.getPlayername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry} returns this
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.setPlayername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 score = 3;
 * @return {number}
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry} returns this
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 shotsCompleted = 4;
 * @return {number}
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.getShotscompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry} returns this
 */
proto.qb.smg.TournamentLeaderBoardEntry.prototype.setShotscompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.TournamentLeaderBoard.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.TournamentLeaderBoard.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.TournamentLeaderBoard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.TournamentLeaderBoard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentLeaderBoard.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasactivetournament: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hastournamentcompletedforuser: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.qb.smg.TournamentLeaderBoardEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.TournamentLeaderBoard}
 */
proto.qb.smg.TournamentLeaderBoard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.TournamentLeaderBoard;
  return proto.qb.smg.TournamentLeaderBoard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.TournamentLeaderBoard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.TournamentLeaderBoard}
 */
proto.qb.smg.TournamentLeaderBoard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasactivetournament(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHastournamentcompletedforuser(value);
      break;
    case 5:
      var value = new proto.qb.smg.TournamentLeaderBoardEntry;
      reader.readMessage(value,proto.qb.smg.TournamentLeaderBoardEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.TournamentLeaderBoard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.TournamentLeaderBoard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.TournamentLeaderBoard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TournamentLeaderBoard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasactivetournament();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHastournamentcompletedforuser();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.qb.smg.TournamentLeaderBoardEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool hasActiveTournament = 3;
 * @return {boolean}
 */
proto.qb.smg.TournamentLeaderBoard.prototype.getHasactivetournament = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.TournamentLeaderBoard} returns this
 */
proto.qb.smg.TournamentLeaderBoard.prototype.setHasactivetournament = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool hasTournamentCompletedForUser = 4;
 * @return {boolean}
 */
proto.qb.smg.TournamentLeaderBoard.prototype.getHastournamentcompletedforuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.TournamentLeaderBoard} returns this
 */
proto.qb.smg.TournamentLeaderBoard.prototype.setHastournamentcompletedforuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated TournamentLeaderBoardEntry entries = 5;
 * @return {!Array<!proto.qb.smg.TournamentLeaderBoardEntry>}
 */
proto.qb.smg.TournamentLeaderBoard.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.qb.smg.TournamentLeaderBoardEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.TournamentLeaderBoardEntry, 5));
};


/**
 * @param {!Array<!proto.qb.smg.TournamentLeaderBoardEntry>} value
 * @return {!proto.qb.smg.TournamentLeaderBoard} returns this
*/
proto.qb.smg.TournamentLeaderBoard.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.qb.smg.TournamentLeaderBoardEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.TournamentLeaderBoardEntry}
 */
proto.qb.smg.TournamentLeaderBoard.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.qb.smg.TournamentLeaderBoardEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.TournamentLeaderBoard} returns this
 */
proto.qb.smg.TournamentLeaderBoard.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    betid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    token: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest;
  return proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBetid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 stationId = 1;
 * @return {number}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.getStationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 betId = 2;
 * @return {number}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.getBetid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.setBetid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    uploadurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse;
  return proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getUploadurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse} returns this
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse} returns this
*/
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse} returns this
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string uploadUrl = 3;
 * @return {string}
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.getUploadurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse} returns this
 */
proto.qb.smg.GenerateS3SimulatorVideoUploadUrlResponse.prototype.setUploadurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changeType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestChangeRequest}
 */
proto.qb.smg.RequestChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestChangeRequest;
  return proto.qb.smg.RequestChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestChangeRequest}
 */
proto.qb.smg.RequestChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!proto.qb.smg.ChangeType} */ (reader.readEnum());
      msg.setChangeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.RequestChangeRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestChangeRequest} returns this
 */
proto.qb.smg.RequestChangeRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ChangeType change_type = 2;
 * @return {!proto.qb.smg.ChangeType}
 */
proto.qb.smg.RequestChangeRequest.prototype.getChangeType = function() {
  return /** @type {!proto.qb.smg.ChangeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qb.smg.ChangeType} value
 * @return {!proto.qb.smg.RequestChangeRequest} returns this
 */
proto.qb.smg.RequestChangeRequest.prototype.setChangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestChangeResponse}
 */
proto.qb.smg.RequestChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestChangeResponse;
  return proto.qb.smg.RequestChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestChangeResponse}
 */
proto.qb.smg.RequestChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.RequestChangeResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.RequestChangeResponse} returns this
*/
proto.qb.smg.RequestChangeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestChangeResponse} returns this
 */
proto.qb.smg.RequestChangeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestChangeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.RequestChangeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RequestChangeResponse} returns this
 */
proto.qb.smg.RequestChangeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.UpdatePhoneNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.UpdatePhoneNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdatePhoneNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    smsCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.UpdatePhoneNumberRequest}
 */
proto.qb.smg.UpdatePhoneNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.UpdatePhoneNumberRequest;
  return proto.qb.smg.UpdatePhoneNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.UpdatePhoneNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.UpdatePhoneNumberRequest}
 */
proto.qb.smg.UpdatePhoneNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmsCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.UpdatePhoneNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.UpdatePhoneNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdatePhoneNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSmsCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.UpdatePhoneNumberRequest} returns this
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.UpdatePhoneNumberRequest} returns this
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sms_code = 3;
 * @return {string}
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.getSmsCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.UpdatePhoneNumberRequest} returns this
 */
proto.qb.smg.UpdatePhoneNumberRequest.prototype.setSmsCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.UpdatePhoneNumberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.UpdatePhoneNumberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.UpdatePhoneNumberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdatePhoneNumberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.UpdatePhoneNumberResponse}
 */
proto.qb.smg.UpdatePhoneNumberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.UpdatePhoneNumberResponse;
  return proto.qb.smg.UpdatePhoneNumberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.UpdatePhoneNumberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.UpdatePhoneNumberResponse}
 */
proto.qb.smg.UpdatePhoneNumberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.UpdatePhoneNumberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.UpdatePhoneNumberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.UpdatePhoneNumberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdatePhoneNumberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.UpdatePhoneNumberResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.UpdatePhoneNumberResponse} returns this
*/
proto.qb.smg.UpdatePhoneNumberResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.UpdatePhoneNumberResponse} returns this
 */
proto.qb.smg.UpdatePhoneNumberResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.UpdatePhoneNumberResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.UpdatePhoneNumberResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.UpdatePhoneNumberResponse} returns this
 */
proto.qb.smg.UpdatePhoneNumberResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestEmailChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestEmailChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestEmailChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestEmailChangeRequest}
 */
proto.qb.smg.RequestEmailChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestEmailChangeRequest;
  return proto.qb.smg.RequestEmailChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestEmailChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestEmailChangeRequest}
 */
proto.qb.smg.RequestEmailChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestEmailChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestEmailChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestEmailChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.RequestEmailChangeRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestEmailChangeRequest} returns this
 */
proto.qb.smg.RequestEmailChangeRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_email = 2;
 * @return {string}
 */
proto.qb.smg.RequestEmailChangeRequest.prototype.getNewEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestEmailChangeRequest} returns this
 */
proto.qb.smg.RequestEmailChangeRequest.prototype.setNewEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestEmailChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestEmailChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestEmailChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestEmailChangeResponse}
 */
proto.qb.smg.RequestEmailChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestEmailChangeResponse;
  return proto.qb.smg.RequestEmailChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestEmailChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestEmailChangeResponse}
 */
proto.qb.smg.RequestEmailChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestEmailChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestEmailChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestEmailChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.RequestEmailChangeResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.RequestEmailChangeResponse} returns this
*/
proto.qb.smg.RequestEmailChangeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestEmailChangeResponse} returns this
 */
proto.qb.smg.RequestEmailChangeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestEmailChangeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.RequestEmailChangeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RequestEmailChangeResponse} returns this
 */
proto.qb.smg.RequestEmailChangeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ConfirmEmailChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ConfirmEmailChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ConfirmEmailChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ConfirmEmailChangeRequest}
 */
proto.qb.smg.ConfirmEmailChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ConfirmEmailChangeRequest;
  return proto.qb.smg.ConfirmEmailChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ConfirmEmailChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ConfirmEmailChangeRequest}
 */
proto.qb.smg.ConfirmEmailChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ConfirmEmailChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ConfirmEmailChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ConfirmEmailChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.ConfirmEmailChangeRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ConfirmEmailChangeRequest} returns this
 */
proto.qb.smg.ConfirmEmailChangeRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ConfirmEmailChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ConfirmEmailChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ConfirmEmailChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ConfirmEmailChangeResponse}
 */
proto.qb.smg.ConfirmEmailChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ConfirmEmailChangeResponse;
  return proto.qb.smg.ConfirmEmailChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ConfirmEmailChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ConfirmEmailChangeResponse}
 */
proto.qb.smg.ConfirmEmailChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ConfirmEmailChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ConfirmEmailChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ConfirmEmailChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ConfirmEmailChangeResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ConfirmEmailChangeResponse} returns this
*/
proto.qb.smg.ConfirmEmailChangeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ConfirmEmailChangeResponse} returns this
 */
proto.qb.smg.ConfirmEmailChangeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ConfirmEmailChangeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.ConfirmEmailChangeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ConfirmEmailChangeResponse} returns this
 */
proto.qb.smg.ConfirmEmailChangeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeactivateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeactivateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeactivateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeactivateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeactivateAccountRequest}
 */
proto.qb.smg.DeactivateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeactivateAccountRequest;
  return proto.qb.smg.DeactivateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeactivateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeactivateAccountRequest}
 */
proto.qb.smg.DeactivateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeactivateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeactivateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeactivateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeactivateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.DeactivateAccountRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DeactivateAccountRequest} returns this
 */
proto.qb.smg.DeactivateAccountRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeactivateAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeactivateAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeactivateAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeactivateAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeactivateAccountResponse}
 */
proto.qb.smg.DeactivateAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeactivateAccountResponse;
  return proto.qb.smg.DeactivateAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeactivateAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeactivateAccountResponse}
 */
proto.qb.smg.DeactivateAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeactivateAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeactivateAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeactivateAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeactivateAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.DeactivateAccountResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.DeactivateAccountResponse} returns this
*/
proto.qb.smg.DeactivateAccountResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DeactivateAccountResponse} returns this
 */
proto.qb.smg.DeactivateAccountResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DeactivateAccountResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.DeactivateAccountResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.DeactivateAccountResponse} returns this
 */
proto.qb.smg.DeactivateAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.DisableUsersRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DisableUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DisableUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DisableUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DisableUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    emailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    kycnamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    wipeKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DisableUsersRequest}
 */
proto.qb.smg.DisableUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DisableUsersRequest;
  return proto.qb.smg.DisableUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DisableUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DisableUsersRequest}
 */
proto.qb.smg.DisableUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addKycnames(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWipeKey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DisableUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DisableUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DisableUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DisableUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getKycnamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getWipeKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.qb.smg.DisableUsersRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * repeated string emails = 2;
 * @return {!Array<string>}
 */
proto.qb.smg.DisableUsersRequest.prototype.getEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.setEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.addEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * repeated string kycNames = 3;
 * @return {!Array<string>}
 */
proto.qb.smg.DisableUsersRequest.prototype.getKycnamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.setKycnamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.addKycnames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.clearKycnamesList = function() {
  return this.setKycnamesList([]);
};


/**
 * optional string wipe_key = 4;
 * @return {string}
 */
proto.qb.smg.DisableUsersRequest.prototype.getWipeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.setWipeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string reason = 5;
 * @return {string}
 */
proto.qb.smg.DisableUsersRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DisableUsersRequest} returns this
 */
proto.qb.smg.DisableUsersRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DisableUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DisableUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DisableUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DisableUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DisableUsersResponse}
 */
proto.qb.smg.DisableUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DisableUsersResponse;
  return proto.qb.smg.DisableUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DisableUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DisableUsersResponse}
 */
proto.qb.smg.DisableUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DisableUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DisableUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DisableUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DisableUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.DisableUsersResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.DisableUsersResponse} returns this
*/
proto.qb.smg.DisableUsersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DisableUsersResponse} returns this
 */
proto.qb.smg.DisableUsersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DisableUsersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.DisableUsersResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.DisableUsersResponse} returns this
 */
proto.qb.smg.DisableUsersResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SetFaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SetFaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SetFaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetFaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SetFaceRequest}
 */
proto.qb.smg.SetFaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SetFaceRequest;
  return proto.qb.smg.SetFaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SetFaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SetFaceRequest}
 */
proto.qb.smg.SetFaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SetFaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SetFaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SetFaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetFaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string image_url = 1;
 * @return {string}
 */
proto.qb.smg.SetFaceRequest.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SetFaceRequest} returns this
 */
proto.qb.smg.SetFaceRequest.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.qb.smg.SetFaceRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SetFaceRequest} returns this
 */
proto.qb.smg.SetFaceRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SetFaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SetFaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SetFaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetFaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    err: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SetFaceResponse}
 */
proto.qb.smg.SetFaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SetFaceResponse;
  return proto.qb.smg.SetFaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SetFaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SetFaceResponse}
 */
proto.qb.smg.SetFaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SetFaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SetFaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SetFaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetFaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.qb.smg.SetFaceResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.SetFaceResponse} returns this
 */
proto.qb.smg.SetFaceResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string err = 2;
 * @return {string}
 */
proto.qb.smg.SetFaceResponse.prototype.getErr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SetFaceResponse} returns this
 */
proto.qb.smg.SetFaceResponse.prototype.setErr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RecognizeFaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RecognizeFaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RecognizeFaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RecognizeFaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RecognizeFaceRequest}
 */
proto.qb.smg.RecognizeFaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RecognizeFaceRequest;
  return proto.qb.smg.RecognizeFaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RecognizeFaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RecognizeFaceRequest}
 */
proto.qb.smg.RecognizeFaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RecognizeFaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RecognizeFaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RecognizeFaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RecognizeFaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string image_url = 1;
 * @return {string}
 */
proto.qb.smg.RecognizeFaceRequest.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RecognizeFaceRequest} returns this
 */
proto.qb.smg.RecognizeFaceRequest.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RecognizeFaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RecognizeFaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RecognizeFaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RecognizeFaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    err: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RecognizeFaceResponse}
 */
proto.qb.smg.RecognizeFaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RecognizeFaceResponse;
  return proto.qb.smg.RecognizeFaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RecognizeFaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RecognizeFaceResponse}
 */
proto.qb.smg.RecognizeFaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RecognizeFaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RecognizeFaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RecognizeFaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RecognizeFaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.qb.smg.RecognizeFaceResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RecognizeFaceResponse} returns this
 */
proto.qb.smg.RecognizeFaceResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string err = 2;
 * @return {string}
 */
proto.qb.smg.RecognizeFaceResponse.prototype.getErr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RecognizeFaceResponse} returns this
 */
proto.qb.smg.RecognizeFaceResponse.prototype.setErr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.qb.smg.RecognizeFaceResponse.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RecognizeFaceResponse} returns this
 */
proto.qb.smg.RecognizeFaceResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.CompareImagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.CompareImagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.CompareImagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CompareImagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstImageUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secondImageUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.CompareImagesRequest}
 */
proto.qb.smg.CompareImagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.CompareImagesRequest;
  return proto.qb.smg.CompareImagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.CompareImagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.CompareImagesRequest}
 */
proto.qb.smg.CompareImagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstImageUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.CompareImagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.CompareImagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.CompareImagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CompareImagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstImageUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecondImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string first_image_url = 1;
 * @return {string}
 */
proto.qb.smg.CompareImagesRequest.prototype.getFirstImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.CompareImagesRequest} returns this
 */
proto.qb.smg.CompareImagesRequest.prototype.setFirstImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string second_image_url = 2;
 * @return {string}
 */
proto.qb.smg.CompareImagesRequest.prototype.getSecondImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.CompareImagesRequest} returns this
 */
proto.qb.smg.CompareImagesRequest.prototype.setSecondImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.CompareImagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.CompareImagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.CompareImagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CompareImagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isMatch: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    matchProbability: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    similiarity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    err: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.CompareImagesResponse}
 */
proto.qb.smg.CompareImagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.CompareImagesResponse;
  return proto.qb.smg.CompareImagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.CompareImagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.CompareImagesResponse}
 */
proto.qb.smg.CompareImagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMatch(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMatchProbability(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSimiliarity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.CompareImagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.CompareImagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.CompareImagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CompareImagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsMatch();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMatchProbability();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getSimiliarity();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getErr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool is_match = 1;
 * @return {boolean}
 */
proto.qb.smg.CompareImagesResponse.prototype.getIsMatch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.CompareImagesResponse} returns this
 */
proto.qb.smg.CompareImagesResponse.prototype.setIsMatch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional float match_probability = 2;
 * @return {number}
 */
proto.qb.smg.CompareImagesResponse.prototype.getMatchProbability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.CompareImagesResponse} returns this
 */
proto.qb.smg.CompareImagesResponse.prototype.setMatchProbability = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float similiarity = 3;
 * @return {number}
 */
proto.qb.smg.CompareImagesResponse.prototype.getSimiliarity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.CompareImagesResponse} returns this
 */
proto.qb.smg.CompareImagesResponse.prototype.setSimiliarity = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string err = 4;
 * @return {string}
 */
proto.qb.smg.CompareImagesResponse.prototype.getErr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.CompareImagesResponse} returns this
 */
proto.qb.smg.CompareImagesResponse.prototype.setErr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ListFacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ListFacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ListFacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListFacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ListFacesRequest}
 */
proto.qb.smg.ListFacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ListFacesRequest;
  return proto.qb.smg.ListFacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ListFacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ListFacesRequest}
 */
proto.qb.smg.ListFacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ListFacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ListFacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ListFacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListFacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.ListFacesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ListFacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ListFacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ListFacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListFacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ListFacesResponse}
 */
proto.qb.smg.ListFacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ListFacesResponse;
  return proto.qb.smg.ListFacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ListFacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ListFacesResponse}
 */
proto.qb.smg.ListFacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ListFacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ListFacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ListFacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ListFacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.qb.smg.ListFacesResponse.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qb.smg.ListFacesResponse} returns this
 */
proto.qb.smg.ListFacesResponse.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.ListFacesResponse} returns this
 */
proto.qb.smg.ListFacesResponse.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.ListFacesResponse} returns this
 */
proto.qb.smg.ListFacesResponse.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.InitialCommandConnectorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.InitialCommandConnectorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.InitialCommandConnectorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.InitialCommandConnectorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    venueid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stationid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.InitialCommandConnectorRequest}
 */
proto.qb.smg.InitialCommandConnectorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.InitialCommandConnectorRequest;
  return proto.qb.smg.InitialCommandConnectorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.InitialCommandConnectorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.InitialCommandConnectorRequest}
 */
proto.qb.smg.InitialCommandConnectorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenueid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.InitialCommandConnectorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.InitialCommandConnectorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.InitialCommandConnectorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.InitialCommandConnectorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVenueid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStationid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 venueId = 1;
 * @return {number}
 */
proto.qb.smg.InitialCommandConnectorRequest.prototype.getVenueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.InitialCommandConnectorRequest} returns this
 */
proto.qb.smg.InitialCommandConnectorRequest.prototype.setVenueid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 stationId = 2;
 * @return {number}
 */
proto.qb.smg.InitialCommandConnectorRequest.prototype.getStationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.InitialCommandConnectorRequest} returns this
 */
proto.qb.smg.InitialCommandConnectorRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.InitialCommandConnectorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.InitialCommandConnectorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.InitialCommandConnectorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.InitialCommandConnectorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.InitialCommandConnectorResponse}
 */
proto.qb.smg.InitialCommandConnectorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.InitialCommandConnectorResponse;
  return proto.qb.smg.InitialCommandConnectorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.InitialCommandConnectorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.InitialCommandConnectorResponse}
 */
proto.qb.smg.InitialCommandConnectorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.InitialCommandConnectorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.InitialCommandConnectorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.InitialCommandConnectorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.InitialCommandConnectorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_ = [[1,2,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.qb.smg.CommandExecutorFRSPayload.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  INITIALCOMMANDCONNECTORREQUEST: 1,
  SETFACERESPONSE: 2,
  COMPAREIMAGESRESPONSE: 3,
  LISTFACESRESPONSE: 4,
  DELETEUSERIMAGERESPONSE: 5,
  HEARTBEATRESPONSE: 6,
  DELETELASTACTIVEBEFOREDATERESPONSE: 7,
  RECOGNIZEFACERESPONSE: 8
};

/**
 * @return {proto.qb.smg.CommandExecutorFRSPayload.PayloadCase}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getPayloadCase = function() {
  return /** @type {proto.qb.smg.CommandExecutorFRSPayload.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.CommandExecutorFRSPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.CommandExecutorFRSPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CommandExecutorFRSPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialcommandconnectorrequest: (f = msg.getInitialcommandconnectorrequest()) && proto.qb.smg.InitialCommandConnectorRequest.toObject(includeInstance, f),
    setfaceresponse: (f = msg.getSetfaceresponse()) && proto.qb.smg.SetFaceResponse.toObject(includeInstance, f),
    compareimagesresponse: (f = msg.getCompareimagesresponse()) && proto.qb.smg.CompareImagesResponse.toObject(includeInstance, f),
    listfacesresponse: (f = msg.getListfacesresponse()) && proto.qb.smg.ListFacesResponse.toObject(includeInstance, f),
    deleteuserimageresponse: (f = msg.getDeleteuserimageresponse()) && proto.qb.smg.DeleteUserImageResponse.toObject(includeInstance, f),
    heartbeatresponse: (f = msg.getHeartbeatresponse()) && proto.qb.smg.HeartbeatResponse.toObject(includeInstance, f),
    deletelastactivebeforedateresponse: (f = msg.getDeletelastactivebeforedateresponse()) && proto.qb.smg.DeleteLastActiveBeforeDateResponse.toObject(includeInstance, f),
    recognizefaceresponse: (f = msg.getRecognizefaceresponse()) && proto.qb.smg.RecognizeFaceResponse.toObject(includeInstance, f),
    token: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload}
 */
proto.qb.smg.CommandExecutorFRSPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.CommandExecutorFRSPayload;
  return proto.qb.smg.CommandExecutorFRSPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.CommandExecutorFRSPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload}
 */
proto.qb.smg.CommandExecutorFRSPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.InitialCommandConnectorRequest;
      reader.readMessage(value,proto.qb.smg.InitialCommandConnectorRequest.deserializeBinaryFromReader);
      msg.setInitialcommandconnectorrequest(value);
      break;
    case 2:
      var value = new proto.qb.smg.SetFaceResponse;
      reader.readMessage(value,proto.qb.smg.SetFaceResponse.deserializeBinaryFromReader);
      msg.setSetfaceresponse(value);
      break;
    case 3:
      var value = new proto.qb.smg.CompareImagesResponse;
      reader.readMessage(value,proto.qb.smg.CompareImagesResponse.deserializeBinaryFromReader);
      msg.setCompareimagesresponse(value);
      break;
    case 4:
      var value = new proto.qb.smg.ListFacesResponse;
      reader.readMessage(value,proto.qb.smg.ListFacesResponse.deserializeBinaryFromReader);
      msg.setListfacesresponse(value);
      break;
    case 5:
      var value = new proto.qb.smg.DeleteUserImageResponse;
      reader.readMessage(value,proto.qb.smg.DeleteUserImageResponse.deserializeBinaryFromReader);
      msg.setDeleteuserimageresponse(value);
      break;
    case 6:
      var value = new proto.qb.smg.HeartbeatResponse;
      reader.readMessage(value,proto.qb.smg.HeartbeatResponse.deserializeBinaryFromReader);
      msg.setHeartbeatresponse(value);
      break;
    case 7:
      var value = new proto.qb.smg.DeleteLastActiveBeforeDateResponse;
      reader.readMessage(value,proto.qb.smg.DeleteLastActiveBeforeDateResponse.deserializeBinaryFromReader);
      msg.setDeletelastactivebeforedateresponse(value);
      break;
    case 8:
      var value = new proto.qb.smg.RecognizeFaceResponse;
      reader.readMessage(value,proto.qb.smg.RecognizeFaceResponse.deserializeBinaryFromReader);
      msg.setRecognizefaceresponse(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.CommandExecutorFRSPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.CommandExecutorFRSPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CommandExecutorFRSPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialcommandconnectorrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.InitialCommandConnectorRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetfaceresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.SetFaceResponse.serializeBinaryToWriter
    );
  }
  f = message.getCompareimagesresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.CompareImagesResponse.serializeBinaryToWriter
    );
  }
  f = message.getListfacesresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qb.smg.ListFacesResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeleteuserimageresponse();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.qb.smg.DeleteUserImageResponse.serializeBinaryToWriter
    );
  }
  f = message.getHeartbeatresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qb.smg.HeartbeatResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeletelastactivebeforedateresponse();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qb.smg.DeleteLastActiveBeforeDateResponse.serializeBinaryToWriter
    );
  }
  f = message.getRecognizefaceresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.qb.smg.RecognizeFaceResponse.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional InitialCommandConnectorRequest initialCommandConnectorRequest = 1;
 * @return {?proto.qb.smg.InitialCommandConnectorRequest}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getInitialcommandconnectorrequest = function() {
  return /** @type{?proto.qb.smg.InitialCommandConnectorRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.InitialCommandConnectorRequest, 1));
};


/**
 * @param {?proto.qb.smg.InitialCommandConnectorRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setInitialcommandconnectorrequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearInitialcommandconnectorrequest = function() {
  return this.setInitialcommandconnectorrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasInitialcommandconnectorrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetFaceResponse setFaceResponse = 2;
 * @return {?proto.qb.smg.SetFaceResponse}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getSetfaceresponse = function() {
  return /** @type{?proto.qb.smg.SetFaceResponse} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.SetFaceResponse, 2));
};


/**
 * @param {?proto.qb.smg.SetFaceResponse|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setSetfaceresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearSetfaceresponse = function() {
  return this.setSetfaceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasSetfaceresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CompareImagesResponse compareImagesResponse = 3;
 * @return {?proto.qb.smg.CompareImagesResponse}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getCompareimagesresponse = function() {
  return /** @type{?proto.qb.smg.CompareImagesResponse} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.CompareImagesResponse, 3));
};


/**
 * @param {?proto.qb.smg.CompareImagesResponse|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setCompareimagesresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearCompareimagesresponse = function() {
  return this.setCompareimagesresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasCompareimagesresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ListFacesResponse listFacesResponse = 4;
 * @return {?proto.qb.smg.ListFacesResponse}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getListfacesresponse = function() {
  return /** @type{?proto.qb.smg.ListFacesResponse} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.ListFacesResponse, 4));
};


/**
 * @param {?proto.qb.smg.ListFacesResponse|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setListfacesresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearListfacesresponse = function() {
  return this.setListfacesresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasListfacesresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DeleteUserImageResponse deleteUserImageResponse = 5;
 * @return {?proto.qb.smg.DeleteUserImageResponse}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getDeleteuserimageresponse = function() {
  return /** @type{?proto.qb.smg.DeleteUserImageResponse} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.DeleteUserImageResponse, 5));
};


/**
 * @param {?proto.qb.smg.DeleteUserImageResponse|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setDeleteuserimageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearDeleteuserimageresponse = function() {
  return this.setDeleteuserimageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasDeleteuserimageresponse = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional HeartbeatResponse heartbeatResponse = 6;
 * @return {?proto.qb.smg.HeartbeatResponse}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getHeartbeatresponse = function() {
  return /** @type{?proto.qb.smg.HeartbeatResponse} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.HeartbeatResponse, 6));
};


/**
 * @param {?proto.qb.smg.HeartbeatResponse|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setHeartbeatresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearHeartbeatresponse = function() {
  return this.setHeartbeatresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasHeartbeatresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DeleteLastActiveBeforeDateResponse deleteLastActiveBeforeDateResponse = 7;
 * @return {?proto.qb.smg.DeleteLastActiveBeforeDateResponse}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getDeletelastactivebeforedateresponse = function() {
  return /** @type{?proto.qb.smg.DeleteLastActiveBeforeDateResponse} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.DeleteLastActiveBeforeDateResponse, 7));
};


/**
 * @param {?proto.qb.smg.DeleteLastActiveBeforeDateResponse|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setDeletelastactivebeforedateresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearDeletelastactivebeforedateresponse = function() {
  return this.setDeletelastactivebeforedateresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasDeletelastactivebeforedateresponse = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RecognizeFaceResponse recognizeFaceResponse = 8;
 * @return {?proto.qb.smg.RecognizeFaceResponse}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getRecognizefaceresponse = function() {
  return /** @type{?proto.qb.smg.RecognizeFaceResponse} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.RecognizeFaceResponse, 8));
};


/**
 * @param {?proto.qb.smg.RecognizeFaceResponse|undefined} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
*/
proto.qb.smg.CommandExecutorFRSPayload.prototype.setRecognizefaceresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.qb.smg.CommandExecutorFRSPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.clearRecognizefaceresponse = function() {
  return this.setRecognizefaceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.hasRecognizefaceresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string token = 9;
 * @return {string}
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.CommandExecutorFRSPayload} returns this
 */
proto.qb.smg.CommandExecutorFRSPayload.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_ = [[2,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.qb.smg.CommandExecutorSMGPayload.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  SETFACEREQUEST: 2,
  COMPAREIMAGESREQUEST: 3,
  LISTFACESREQUEST: 4,
  DELETEUSERIMAGEREQUEST: 5,
  HEARTBEATREQUEST: 6,
  DELETELASTACTIVEBEFOREDATEREQUEST: 7,
  RECOGNIZEFACEREQUEST: 8
};

/**
 * @return {proto.qb.smg.CommandExecutorSMGPayload.PayloadCase}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getPayloadCase = function() {
  return /** @type {proto.qb.smg.CommandExecutorSMGPayload.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.CommandExecutorSMGPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.CommandExecutorSMGPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CommandExecutorSMGPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    setfacerequest: (f = msg.getSetfacerequest()) && proto.qb.smg.SetFaceRequest.toObject(includeInstance, f),
    compareimagesrequest: (f = msg.getCompareimagesrequest()) && proto.qb.smg.CompareImagesRequest.toObject(includeInstance, f),
    listfacesrequest: (f = msg.getListfacesrequest()) && proto.qb.smg.ListFacesRequest.toObject(includeInstance, f),
    deleteuserimagerequest: (f = msg.getDeleteuserimagerequest()) && proto.qb.smg.DeleteUserImageRequest.toObject(includeInstance, f),
    heartbeatrequest: (f = msg.getHeartbeatrequest()) && proto.qb.smg.FrsHeartbeatRequest.toObject(includeInstance, f),
    deletelastactivebeforedaterequest: (f = msg.getDeletelastactivebeforedaterequest()) && proto.qb.smg.DeleteLastActiveBeforeDateRequest.toObject(includeInstance, f),
    recognizefacerequest: (f = msg.getRecognizefacerequest()) && proto.qb.smg.RecognizeFaceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload}
 */
proto.qb.smg.CommandExecutorSMGPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.CommandExecutorSMGPayload;
  return proto.qb.smg.CommandExecutorSMGPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.CommandExecutorSMGPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload}
 */
proto.qb.smg.CommandExecutorSMGPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.qb.smg.SetFaceRequest;
      reader.readMessage(value,proto.qb.smg.SetFaceRequest.deserializeBinaryFromReader);
      msg.setSetfacerequest(value);
      break;
    case 3:
      var value = new proto.qb.smg.CompareImagesRequest;
      reader.readMessage(value,proto.qb.smg.CompareImagesRequest.deserializeBinaryFromReader);
      msg.setCompareimagesrequest(value);
      break;
    case 4:
      var value = new proto.qb.smg.ListFacesRequest;
      reader.readMessage(value,proto.qb.smg.ListFacesRequest.deserializeBinaryFromReader);
      msg.setListfacesrequest(value);
      break;
    case 5:
      var value = new proto.qb.smg.DeleteUserImageRequest;
      reader.readMessage(value,proto.qb.smg.DeleteUserImageRequest.deserializeBinaryFromReader);
      msg.setDeleteuserimagerequest(value);
      break;
    case 6:
      var value = new proto.qb.smg.FrsHeartbeatRequest;
      reader.readMessage(value,proto.qb.smg.FrsHeartbeatRequest.deserializeBinaryFromReader);
      msg.setHeartbeatrequest(value);
      break;
    case 7:
      var value = new proto.qb.smg.DeleteLastActiveBeforeDateRequest;
      reader.readMessage(value,proto.qb.smg.DeleteLastActiveBeforeDateRequest.deserializeBinaryFromReader);
      msg.setDeletelastactivebeforedaterequest(value);
      break;
    case 8:
      var value = new proto.qb.smg.RecognizeFaceRequest;
      reader.readMessage(value,proto.qb.smg.RecognizeFaceRequest.deserializeBinaryFromReader);
      msg.setRecognizefacerequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.CommandExecutorSMGPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.CommandExecutorSMGPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CommandExecutorSMGPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetfacerequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.SetFaceRequest.serializeBinaryToWriter
    );
  }
  f = message.getCompareimagesrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.CompareImagesRequest.serializeBinaryToWriter
    );
  }
  f = message.getListfacesrequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qb.smg.ListFacesRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeleteuserimagerequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.qb.smg.DeleteUserImageRequest.serializeBinaryToWriter
    );
  }
  f = message.getHeartbeatrequest();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qb.smg.FrsHeartbeatRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeletelastactivebeforedaterequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qb.smg.DeleteLastActiveBeforeDateRequest.serializeBinaryToWriter
    );
  }
  f = message.getRecognizefacerequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.qb.smg.RecognizeFaceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional SetFaceRequest setFaceRequest = 2;
 * @return {?proto.qb.smg.SetFaceRequest}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getSetfacerequest = function() {
  return /** @type{?proto.qb.smg.SetFaceRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.SetFaceRequest, 2));
};


/**
 * @param {?proto.qb.smg.SetFaceRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
*/
proto.qb.smg.CommandExecutorSMGPayload.prototype.setSetfacerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.clearSetfacerequest = function() {
  return this.setSetfacerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.hasSetfacerequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CompareImagesRequest compareImagesRequest = 3;
 * @return {?proto.qb.smg.CompareImagesRequest}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getCompareimagesrequest = function() {
  return /** @type{?proto.qb.smg.CompareImagesRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.CompareImagesRequest, 3));
};


/**
 * @param {?proto.qb.smg.CompareImagesRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
*/
proto.qb.smg.CommandExecutorSMGPayload.prototype.setCompareimagesrequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.clearCompareimagesrequest = function() {
  return this.setCompareimagesrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.hasCompareimagesrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ListFacesRequest listFacesRequest = 4;
 * @return {?proto.qb.smg.ListFacesRequest}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getListfacesrequest = function() {
  return /** @type{?proto.qb.smg.ListFacesRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.ListFacesRequest, 4));
};


/**
 * @param {?proto.qb.smg.ListFacesRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
*/
proto.qb.smg.CommandExecutorSMGPayload.prototype.setListfacesrequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.clearListfacesrequest = function() {
  return this.setListfacesrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.hasListfacesrequest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DeleteUserImageRequest deleteUserImageRequest = 5;
 * @return {?proto.qb.smg.DeleteUserImageRequest}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getDeleteuserimagerequest = function() {
  return /** @type{?proto.qb.smg.DeleteUserImageRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.DeleteUserImageRequest, 5));
};


/**
 * @param {?proto.qb.smg.DeleteUserImageRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
*/
proto.qb.smg.CommandExecutorSMGPayload.prototype.setDeleteuserimagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.clearDeleteuserimagerequest = function() {
  return this.setDeleteuserimagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.hasDeleteuserimagerequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FrsHeartbeatRequest heartbeatRequest = 6;
 * @return {?proto.qb.smg.FrsHeartbeatRequest}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getHeartbeatrequest = function() {
  return /** @type{?proto.qb.smg.FrsHeartbeatRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.FrsHeartbeatRequest, 6));
};


/**
 * @param {?proto.qb.smg.FrsHeartbeatRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
*/
proto.qb.smg.CommandExecutorSMGPayload.prototype.setHeartbeatrequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.clearHeartbeatrequest = function() {
  return this.setHeartbeatrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.hasHeartbeatrequest = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DeleteLastActiveBeforeDateRequest deleteLastActiveBeforeDateRequest = 7;
 * @return {?proto.qb.smg.DeleteLastActiveBeforeDateRequest}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getDeletelastactivebeforedaterequest = function() {
  return /** @type{?proto.qb.smg.DeleteLastActiveBeforeDateRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.DeleteLastActiveBeforeDateRequest, 7));
};


/**
 * @param {?proto.qb.smg.DeleteLastActiveBeforeDateRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
*/
proto.qb.smg.CommandExecutorSMGPayload.prototype.setDeletelastactivebeforedaterequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.clearDeletelastactivebeforedaterequest = function() {
  return this.setDeletelastactivebeforedaterequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.hasDeletelastactivebeforedaterequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RecognizeFaceRequest recognizeFaceRequest = 8;
 * @return {?proto.qb.smg.RecognizeFaceRequest}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.getRecognizefacerequest = function() {
  return /** @type{?proto.qb.smg.RecognizeFaceRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.RecognizeFaceRequest, 8));
};


/**
 * @param {?proto.qb.smg.RecognizeFaceRequest|undefined} value
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
*/
proto.qb.smg.CommandExecutorSMGPayload.prototype.setRecognizefacerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.qb.smg.CommandExecutorSMGPayload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CommandExecutorSMGPayload} returns this
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.clearRecognizefacerequest = function() {
  return this.setRecognizefacerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CommandExecutorSMGPayload.prototype.hasRecognizefacerequest = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    venueid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stationid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cameraid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    capturetime: (f = msg.getCapturetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    token: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest;
  return proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenueid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCapturetime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVenueid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStationid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCameraid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCapturetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 venueId = 1;
 * @return {number}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.getVenueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.setVenueid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 stationId = 2;
 * @return {number}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.getStationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cameraId = 3;
 * @return {number}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.getCameraid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.setCameraid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp captureTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.getCapturetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} returns this
*/
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.setCapturetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.clearCapturetime = function() {
  return this.setCapturetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.hasCapturetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string token = 5;
 * @return {string}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    uploadurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse;
  return proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getUploadurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse} returns this
*/
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string uploadUrl = 3;
 * @return {string}
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.getUploadurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse} returns this
 */
proto.qb.smg.GenerateS3ArchiveVideoUploadUrlResponse.prototype.setUploadurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.StationWebcamUpdate.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StationWebcamUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StationWebcamUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StationWebcamUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationWebcamUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sendtime: (f = msg.getSendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    webcamstateList: jspb.Message.toObjectList(msg.getWebcamstateList(),
    proto.qb.smg.StationWebcamUpdate.WebcamState.toObject, includeInstance),
    token: jspb.Message.getFieldWithDefault(msg, 4, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0),
    additionalParametersMap: (f = msg.getAdditionalParametersMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StationWebcamUpdate}
 */
proto.qb.smg.StationWebcamUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StationWebcamUpdate;
  return proto.qb.smg.StationWebcamUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StationWebcamUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StationWebcamUpdate}
 */
proto.qb.smg.StationWebcamUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSendtime(value);
      break;
    case 3:
      var value = new proto.qb.smg.StationWebcamUpdate.WebcamState;
      reader.readMessage(value,proto.qb.smg.StationWebcamUpdate.WebcamState.deserializeBinaryFromReader);
      msg.addWebcamstate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    case 103:
      var value = msg.getAdditionalParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StationWebcamUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StationWebcamUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StationWebcamUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationWebcamUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSendtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWebcamstateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.StationWebcamUpdate.WebcamState.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
  f = message.getAdditionalParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(103, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StationWebcamUpdate.WebcamState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StationWebcamUpdate.WebcamState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.toObject = function(includeInstance, msg) {
  var f, obj = {
    webcamid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    capturetime: (f = msg.getCapturetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    faceDetected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    userid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    probability: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    multipleHumanDetected: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    numberOfHumanDetected: jspb.Message.getFieldWithDefault(msg, 7, 0),
    side: jspb.Message.getFieldWithDefault(msg, 8, 0),
    numberOfHumanInDetectableArea: jspb.Message.getFieldWithDefault(msg, 9, 0),
    useridInDetectableArea: jspb.Message.getFieldWithDefault(msg, 10, 0),
    faceDetectedInDetectableArea: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StationWebcamUpdate.WebcamState;
  return proto.qb.smg.StationWebcamUpdate.WebcamState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StationWebcamUpdate.WebcamState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebcamid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCapturetime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaceDetected(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProbability(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultipleHumanDetected(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfHumanDetected(value);
      break;
    case 8:
      var value = /** @type {!proto.qb.smg.StationWebcamUpdate.WebcamState.Side} */ (reader.readEnum());
      msg.setSide(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfHumanInDetectableArea(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUseridInDetectableArea(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaceDetectedInDetectableArea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StationWebcamUpdate.WebcamState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StationWebcamUpdate.WebcamState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebcamid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCapturetime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFaceDetected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getProbability();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMultipleHumanDetected();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNumberOfHumanDetected();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getNumberOfHumanInDetectableArea();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUseridInDetectableArea();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getFaceDetectedInDetectableArea();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.Side = {
  LEFT: 0,
  RIGHT: 1
};

/**
 * optional string webcamId = 1;
 * @return {string}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getWebcamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setWebcamid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp captureTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getCapturetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
*/
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setCapturetime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.clearCapturetime = function() {
  return this.setCapturetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.hasCapturetime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool face_detected = 3;
 * @return {boolean}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getFaceDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setFaceDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 userId = 4;
 * @return {number}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float probability = 5;
 * @return {number}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getProbability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setProbability = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool multiple_human_detected = 6;
 * @return {boolean}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getMultipleHumanDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setMultipleHumanDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 number_of_human_detected = 7;
 * @return {number}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getNumberOfHumanDetected = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setNumberOfHumanDetected = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Side side = 8;
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState.Side}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getSide = function() {
  return /** @type {!proto.qb.smg.StationWebcamUpdate.WebcamState.Side} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.qb.smg.StationWebcamUpdate.WebcamState.Side} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 number_of_human_in_detectable_area = 9;
 * @return {number}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getNumberOfHumanInDetectableArea = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setNumberOfHumanInDetectableArea = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 userId_in_detectable_area = 10;
 * @return {number}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getUseridInDetectableArea = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setUseridInDetectableArea = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool face_detected_in_detectable_area = 11;
 * @return {boolean}
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.getFaceDetectedInDetectableArea = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState} returns this
 */
proto.qb.smg.StationWebcamUpdate.WebcamState.prototype.setFaceDetectedInDetectableArea = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 stationId = 1;
 * @return {number}
 */
proto.qb.smg.StationWebcamUpdate.prototype.getStationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
 */
proto.qb.smg.StationWebcamUpdate.prototype.setStationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp sendTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qb.smg.StationWebcamUpdate.prototype.getSendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
*/
proto.qb.smg.StationWebcamUpdate.prototype.setSendtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
 */
proto.qb.smg.StationWebcamUpdate.prototype.clearSendtime = function() {
  return this.setSendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StationWebcamUpdate.prototype.hasSendtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated WebcamState webcamState = 3;
 * @return {!Array<!proto.qb.smg.StationWebcamUpdate.WebcamState>}
 */
proto.qb.smg.StationWebcamUpdate.prototype.getWebcamstateList = function() {
  return /** @type{!Array<!proto.qb.smg.StationWebcamUpdate.WebcamState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.StationWebcamUpdate.WebcamState, 3));
};


/**
 * @param {!Array<!proto.qb.smg.StationWebcamUpdate.WebcamState>} value
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
*/
proto.qb.smg.StationWebcamUpdate.prototype.setWebcamstateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.StationWebcamUpdate.WebcamState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.StationWebcamUpdate.WebcamState}
 */
proto.qb.smg.StationWebcamUpdate.prototype.addWebcamstate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.StationWebcamUpdate.WebcamState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
 */
proto.qb.smg.StationWebcamUpdate.prototype.clearWebcamstateList = function() {
  return this.setWebcamstateList([]);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.qb.smg.StationWebcamUpdate.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
 */
proto.qb.smg.StationWebcamUpdate.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.StationWebcamUpdate.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
*/
proto.qb.smg.StationWebcamUpdate.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
 */
proto.qb.smg.StationWebcamUpdate.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StationWebcamUpdate.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.StationWebcamUpdate.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
 */
proto.qb.smg.StationWebcamUpdate.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};


/**
 * map<string, string> additional_parameters = 103;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.qb.smg.StationWebcamUpdate.prototype.getAdditionalParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 103, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qb.smg.StationWebcamUpdate} returns this
 */
proto.qb.smg.StationWebcamUpdate.prototype.clearAdditionalParametersMap = function() {
  this.getAdditionalParametersMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.PushStationWebcamUpdatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.PushStationWebcamUpdatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.PushStationWebcamUpdatesResponse}
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.PushStationWebcamUpdatesResponse;
  return proto.qb.smg.PushStationWebcamUpdatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.PushStationWebcamUpdatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.PushStationWebcamUpdatesResponse}
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.PushStationWebcamUpdatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.PushStationWebcamUpdatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.PushStationWebcamUpdatesResponse} returns this
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.PushStationWebcamUpdatesResponse} returns this
*/
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.PushStationWebcamUpdatesResponse} returns this
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.PushStationWebcamUpdatesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.UploadImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.UploadImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.UploadImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UploadImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.UploadImageRequest}
 */
proto.qb.smg.UploadImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.UploadImageRequest;
  return proto.qb.smg.UploadImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.UploadImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.UploadImageRequest}
 */
proto.qb.smg.UploadImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.UploadImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.UploadImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.UploadImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UploadImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes image = 1;
 * @return {!(string|Uint8Array)}
 */
proto.qb.smg.UploadImageRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes image = 1;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.qb.smg.UploadImageRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.qb.smg.UploadImageRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.qb.smg.UploadImageRequest} returns this
 */
proto.qb.smg.UploadImageRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.UploadImageReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.UploadImageReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.UploadImageReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UploadImageReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    fileId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.UploadImageReply}
 */
proto.qb.smg.UploadImageReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.UploadImageReply;
  return proto.qb.smg.UploadImageReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.UploadImageReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.UploadImageReply}
 */
proto.qb.smg.UploadImageReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.UploadImageReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.UploadImageReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.UploadImageReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UploadImageReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.UploadImageReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.UploadImageReply} returns this
 */
proto.qb.smg.UploadImageReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.UploadImageReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.UploadImageReply} returns this
*/
proto.qb.smg.UploadImageReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.UploadImageReply} returns this
 */
proto.qb.smg.UploadImageReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.UploadImageReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 file_id = 3;
 * @return {number}
 */
proto.qb.smg.UploadImageReply.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.UploadImageReply} returns this
 */
proto.qb.smg.UploadImageReply.prototype.setFileId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestResetPinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestResetPinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestResetPinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestResetPinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestResetPinRequest}
 */
proto.qb.smg.RequestResetPinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestResetPinRequest;
  return proto.qb.smg.RequestResetPinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestResetPinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestResetPinRequest}
 */
proto.qb.smg.RequestResetPinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestResetPinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestResetPinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestResetPinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestResetPinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.RequestResetPinRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestResetPinRequest} returns this
 */
proto.qb.smg.RequestResetPinRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.RequestResetPinRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.RequestResetPinRequest} returns this
*/
proto.qb.smg.RequestResetPinRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestResetPinRequest} returns this
 */
proto.qb.smg.RequestResetPinRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestResetPinRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.RequestResetPinRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.RequestResetPinRequest} returns this
 */
proto.qb.smg.RequestResetPinRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestResetPinReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestResetPinReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestResetPinReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestResetPinReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestResetPinReply}
 */
proto.qb.smg.RequestResetPinReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestResetPinReply;
  return proto.qb.smg.RequestResetPinReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestResetPinReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestResetPinReply}
 */
proto.qb.smg.RequestResetPinReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestResetPinReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestResetPinReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestResetPinReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestResetPinReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.RequestResetPinReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RequestResetPinReply} returns this
 */
proto.qb.smg.RequestResetPinReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.RequestResetPinReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.RequestResetPinReply} returns this
*/
proto.qb.smg.RequestResetPinReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestResetPinReply} returns this
 */
proto.qb.smg.RequestResetPinReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestResetPinReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResendEmailConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResendEmailConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResendEmailConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResendEmailConfirmationRequest}
 */
proto.qb.smg.ResendEmailConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResendEmailConfirmationRequest;
  return proto.qb.smg.ResendEmailConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResendEmailConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResendEmailConfirmationRequest}
 */
proto.qb.smg.ResendEmailConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResendEmailConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResendEmailConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResendEmailConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ResendEmailConfirmationRequest} returns this
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.ResendEmailConfirmationRequest} returns this
*/
proto.qb.smg.ResendEmailConfirmationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ResendEmailConfirmationRequest} returns this
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ResendEmailConfirmationRequest} returns this
 */
proto.qb.smg.ResendEmailConfirmationRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResendEmailConfirmationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResendEmailConfirmationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResendEmailConfirmationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResendEmailConfirmationReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResendEmailConfirmationReply}
 */
proto.qb.smg.ResendEmailConfirmationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResendEmailConfirmationReply;
  return proto.qb.smg.ResendEmailConfirmationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResendEmailConfirmationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResendEmailConfirmationReply}
 */
proto.qb.smg.ResendEmailConfirmationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResendEmailConfirmationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResendEmailConfirmationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResendEmailConfirmationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResendEmailConfirmationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.ResendEmailConfirmationReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ResendEmailConfirmationReply} returns this
 */
proto.qb.smg.ResendEmailConfirmationReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ResendEmailConfirmationReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ResendEmailConfirmationReply} returns this
*/
proto.qb.smg.ResendEmailConfirmationReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ResendEmailConfirmationReply} returns this
 */
proto.qb.smg.ResendEmailConfirmationReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ResendEmailConfirmationReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ConfirmEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ConfirmEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ConfirmEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    confirmationtoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ConfirmEmailRequest}
 */
proto.qb.smg.ConfirmEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ConfirmEmailRequest;
  return proto.qb.smg.ConfirmEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ConfirmEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ConfirmEmailRequest}
 */
proto.qb.smg.ConfirmEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ConfirmEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ConfirmEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ConfirmEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfirmationtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string confirmationToken = 1;
 * @return {string}
 */
proto.qb.smg.ConfirmEmailRequest.prototype.getConfirmationtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ConfirmEmailRequest} returns this
 */
proto.qb.smg.ConfirmEmailRequest.prototype.setConfirmationtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ConfirmEmailReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ConfirmEmailReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ConfirmEmailReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.qb.smg.Session.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.qb.smg.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ConfirmEmailReply}
 */
proto.qb.smg.ConfirmEmailReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ConfirmEmailReply;
  return proto.qb.smg.ConfirmEmailReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ConfirmEmailReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ConfirmEmailReply}
 */
proto.qb.smg.ConfirmEmailReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Session;
      reader.readMessage(value,proto.qb.smg.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 4:
      var value = new proto.qb.smg.User;
      reader.readMessage(value,proto.qb.smg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ConfirmEmailReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ConfirmEmailReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ConfirmEmailReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConfirmEmailReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Session.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qb.smg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.ConfirmEmailReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ConfirmEmailReply} returns this
 */
proto.qb.smg.ConfirmEmailReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ConfirmEmailReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ConfirmEmailReply} returns this
*/
proto.qb.smg.ConfirmEmailReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ConfirmEmailReply} returns this
 */
proto.qb.smg.ConfirmEmailReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ConfirmEmailReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Session session = 3;
 * @return {?proto.qb.smg.Session}
 */
proto.qb.smg.ConfirmEmailReply.prototype.getSession = function() {
  return /** @type{?proto.qb.smg.Session} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Session, 3));
};


/**
 * @param {?proto.qb.smg.Session|undefined} value
 * @return {!proto.qb.smg.ConfirmEmailReply} returns this
*/
proto.qb.smg.ConfirmEmailReply.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ConfirmEmailReply} returns this
 */
proto.qb.smg.ConfirmEmailReply.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ConfirmEmailReply.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User user = 4;
 * @return {?proto.qb.smg.User}
 */
proto.qb.smg.ConfirmEmailReply.prototype.getUser = function() {
  return /** @type{?proto.qb.smg.User} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.User, 4));
};


/**
 * @param {?proto.qb.smg.User|undefined} value
 * @return {!proto.qb.smg.ConfirmEmailReply} returns this
*/
proto.qb.smg.ConfirmEmailReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ConfirmEmailReply} returns this
 */
proto.qb.smg.ConfirmEmailReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ConfirmEmailReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DoResetPinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DoResetPinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DoResetPinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DoResetPinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DoResetPinRequest}
 */
proto.qb.smg.DoResetPinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DoResetPinRequest;
  return proto.qb.smg.DoResetPinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DoResetPinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DoResetPinRequest}
 */
proto.qb.smg.DoResetPinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPin(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DoResetPinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DoResetPinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DoResetPinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DoResetPinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.DoResetPinRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DoResetPinRequest} returns this
 */
proto.qb.smg.DoResetPinRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_pin = 2;
 * @return {string}
 */
proto.qb.smg.DoResetPinRequest.prototype.getNewPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DoResetPinRequest} returns this
 */
proto.qb.smg.DoResetPinRequest.prototype.setNewPin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.DoResetPinRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.DoResetPinRequest} returns this
*/
proto.qb.smg.DoResetPinRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DoResetPinRequest} returns this
 */
proto.qb.smg.DoResetPinRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DoResetPinRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.DoResetPinRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.DoResetPinRequest} returns this
 */
proto.qb.smg.DoResetPinRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DoResetPinReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DoResetPinReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DoResetPinReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DoResetPinReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DoResetPinReply}
 */
proto.qb.smg.DoResetPinReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DoResetPinReply;
  return proto.qb.smg.DoResetPinReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DoResetPinReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DoResetPinReply}
 */
proto.qb.smg.DoResetPinReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DoResetPinReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DoResetPinReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DoResetPinReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DoResetPinReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.DoResetPinReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.DoResetPinReply} returns this
 */
proto.qb.smg.DoResetPinReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.DoResetPinReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.DoResetPinReply} returns this
*/
proto.qb.smg.DoResetPinReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DoResetPinReply} returns this
 */
proto.qb.smg.DoResetPinReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DoResetPinReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RegisterUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RegisterUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RegisterUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RegisterUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    smscode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    handedness: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address: jspb.Message.getFieldWithDefault(msg, 10, ""),
    country: jspb.Message.getFieldWithDefault(msg, 11, ""),
    state: jspb.Message.getFieldWithDefault(msg, 12, ""),
    city: jspb.Message.getFieldWithDefault(msg, 13, ""),
    zipcode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    handicap: jspb.Message.getFieldWithDefault(msg, 15, ""),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 16, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RegisterUserRequest}
 */
proto.qb.smg.RegisterUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RegisterUserRequest;
  return proto.qb.smg.RegisterUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RegisterUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RegisterUserRequest}
 */
proto.qb.smg.RegisterUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmscode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandedness(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipcode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandicap(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateOfBirth(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RegisterUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RegisterUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RegisterUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RegisterUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSmscode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHandedness();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getZipcode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHandicap();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string smsCode = 2;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getSmscode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setSmscode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string handedness = 5;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getHandedness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setHandedness = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phoneNumber = 6;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string firstname = 7;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string middlename = 8;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastname = 9;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address = 10;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string country = 11;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string state = 12;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string city = 13;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string zipcode = 14;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getZipcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setZipcode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string handicap = 15;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getHandicap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setHandicap = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string date_of_birth = 16;
 * @return {string}
 */
proto.qb.smg.RegisterUserRequest.prototype.getDateOfBirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.RegisterUserRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
*/
proto.qb.smg.RegisterUserRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RegisterUserRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.RegisterUserRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.RegisterUserRequest} returns this
 */
proto.qb.smg.RegisterUserRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RegisterUserReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RegisterUserReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RegisterUserReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RegisterUserReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.qb.smg.Session.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RegisterUserReply}
 */
proto.qb.smg.RegisterUserReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RegisterUserReply;
  return proto.qb.smg.RegisterUserReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RegisterUserReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RegisterUserReply}
 */
proto.qb.smg.RegisterUserReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Session;
      reader.readMessage(value,proto.qb.smg.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RegisterUserReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RegisterUserReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RegisterUserReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RegisterUserReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Session.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.RegisterUserReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RegisterUserReply} returns this
 */
proto.qb.smg.RegisterUserReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.RegisterUserReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.RegisterUserReply} returns this
*/
proto.qb.smg.RegisterUserReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RegisterUserReply} returns this
 */
proto.qb.smg.RegisterUserReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RegisterUserReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Session session = 3;
 * @return {?proto.qb.smg.Session}
 */
proto.qb.smg.RegisterUserReply.prototype.getSession = function() {
  return /** @type{?proto.qb.smg.Session} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Session, 3));
};


/**
 * @param {?proto.qb.smg.Session|undefined} value
 * @return {!proto.qb.smg.RegisterUserReply} returns this
*/
proto.qb.smg.RegisterUserReply.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RegisterUserReply} returns this
 */
proto.qb.smg.RegisterUserReply.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RegisterUserReply.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.LocateVenueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.LocateVenueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.LocateVenueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LocateVenueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    token: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.LocateVenueRequest}
 */
proto.qb.smg.LocateVenueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.LocateVenueRequest;
  return proto.qb.smg.LocateVenueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.LocateVenueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.LocateVenueRequest}
 */
proto.qb.smg.LocateVenueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.LocateVenueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.LocateVenueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.LocateVenueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LocateVenueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Location location = 1;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.LocateVenueRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 1));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.LocateVenueRequest} returns this
*/
proto.qb.smg.LocateVenueRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LocateVenueRequest} returns this
 */
proto.qb.smg.LocateVenueRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LocateVenueRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 venue_id = 2;
 * @return {number}
 */
proto.qb.smg.LocateVenueRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.LocateVenueRequest} returns this
 */
proto.qb.smg.LocateVenueRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.qb.smg.LocateVenueRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.LocateVenueRequest} returns this
 */
proto.qb.smg.LocateVenueRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.LocateVenueReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.LocateVenueReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.LocateVenueReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LocateVenueReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    venueconfig: (f = msg.getVenueconfig()) && proto.qb.smg.VenueConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.LocateVenueReply}
 */
proto.qb.smg.LocateVenueReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.LocateVenueReply;
  return proto.qb.smg.LocateVenueReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.LocateVenueReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.LocateVenueReply}
 */
proto.qb.smg.LocateVenueReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.VenueConfig;
      reader.readMessage(value,proto.qb.smg.VenueConfig.deserializeBinaryFromReader);
      msg.setVenueconfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.LocateVenueReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.LocateVenueReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.LocateVenueReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LocateVenueReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getVenueconfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.VenueConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.LocateVenueReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.LocateVenueReply} returns this
 */
proto.qb.smg.LocateVenueReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.LocateVenueReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.LocateVenueReply} returns this
*/
proto.qb.smg.LocateVenueReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LocateVenueReply} returns this
 */
proto.qb.smg.LocateVenueReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LocateVenueReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VenueConfig venueConfig = 3;
 * @return {?proto.qb.smg.VenueConfig}
 */
proto.qb.smg.LocateVenueReply.prototype.getVenueconfig = function() {
  return /** @type{?proto.qb.smg.VenueConfig} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.VenueConfig, 3));
};


/**
 * @param {?proto.qb.smg.VenueConfig|undefined} value
 * @return {!proto.qb.smg.LocateVenueReply} returns this
*/
proto.qb.smg.LocateVenueReply.prototype.setVenueconfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LocateVenueReply} returns this
 */
proto.qb.smg.LocateVenueReply.prototype.clearVenueconfig = function() {
  return this.setVenueconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LocateVenueReply.prototype.hasVenueconfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.CheckUserEmailExistsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.CheckUserEmailExistsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CheckUserEmailExistsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.CheckUserEmailExistsRequest}
 */
proto.qb.smg.CheckUserEmailExistsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.CheckUserEmailExistsRequest;
  return proto.qb.smg.CheckUserEmailExistsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.CheckUserEmailExistsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.CheckUserEmailExistsRequest}
 */
proto.qb.smg.CheckUserEmailExistsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.CheckUserEmailExistsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.CheckUserEmailExistsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CheckUserEmailExistsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.CheckUserEmailExistsRequest} returns this
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.CheckUserEmailExistsRequest} returns this
*/
proto.qb.smg.CheckUserEmailExistsRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CheckUserEmailExistsRequest} returns this
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.CheckUserEmailExistsRequest} returns this
 */
proto.qb.smg.CheckUserEmailExistsRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.CheckUserEmailExistsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.CheckUserEmailExistsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CheckUserEmailExistsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    exists: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.CheckUserEmailExistsReply}
 */
proto.qb.smg.CheckUserEmailExistsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.CheckUserEmailExistsReply;
  return proto.qb.smg.CheckUserEmailExistsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.CheckUserEmailExistsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.CheckUserEmailExistsReply}
 */
proto.qb.smg.CheckUserEmailExistsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.CheckUserEmailExistsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.CheckUserEmailExistsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.CheckUserEmailExistsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getExists();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.CheckUserEmailExistsReply} returns this
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.CheckUserEmailExistsReply} returns this
*/
proto.qb.smg.CheckUserEmailExistsReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.CheckUserEmailExistsReply} returns this
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool exists = 3;
 * @return {boolean}
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.getExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.CheckUserEmailExistsReply} returns this
 */
proto.qb.smg.CheckUserEmailExistsReply.prototype.setExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ReplacePhotoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ReplacePhotoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ReplacePhotoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateOfRequest: jspb.Message.getFieldWithDefault(msg, 4, 0),
    photo: msg.getPhoto_asB64(),
    reasonForRequest: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    csrComment: jspb.Message.getFieldWithDefault(msg, 8, ""),
    csrId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dateOfTheDecision: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ReplacePhotoRequest}
 */
proto.qb.smg.ReplacePhotoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ReplacePhotoRequest;
  return proto.qb.smg.ReplacePhotoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ReplacePhotoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ReplacePhotoRequest}
 */
proto.qb.smg.ReplacePhotoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlayerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateOfRequest(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPhoto(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonForRequest(value);
      break;
    case 7:
      var value = /** @type {!proto.qb.smg.ReplacePhotoStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCsrComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCsrId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateOfTheDecision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ReplacePhotoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ReplacePhotoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ReplacePhotoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateOfRequest();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPhoto_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getReasonForRequest();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCsrComment();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCsrId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDateOfTheDecision();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 request_id = 1;
 * @return {number}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 player_id = 2;
 * @return {number}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_of_request = 4;
 * @return {number}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getDateOfRequest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setDateOfRequest = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes photo = 5;
 * @return {!(string|Uint8Array)}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getPhoto = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes photo = 5;
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {string}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getPhoto_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPhoto()));
};


/**
 * optional bytes photo = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {!Uint8Array}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getPhoto_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPhoto()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string reason_for_request = 6;
 * @return {string}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getReasonForRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setReasonForRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ReplacePhotoStatus status = 7;
 * @return {!proto.qb.smg.ReplacePhotoStatus}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getStatus = function() {
  return /** @type {!proto.qb.smg.ReplacePhotoStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.qb.smg.ReplacePhotoStatus} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string csr_comment = 8;
 * @return {string}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getCsrComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setCsrComment = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 csr_id = 9;
 * @return {number}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getCsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setCsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 date_of_the_decision = 10;
 * @return {number}
 */
proto.qb.smg.ReplacePhotoRequest.prototype.getDateOfTheDecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ReplacePhotoRequest} returns this
 */
proto.qb.smg.ReplacePhotoRequest.prototype.setDateOfTheDecision = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestChangePhotoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestChangePhotoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangePhotoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    photo: msg.getPhoto_asB64(),
    reasonForRequest: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestChangePhotoRequest}
 */
proto.qb.smg.RequestChangePhotoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestChangePhotoRequest;
  return proto.qb.smg.RequestChangePhotoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestChangePhotoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestChangePhotoRequest}
 */
proto.qb.smg.RequestChangePhotoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPhoto(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonForRequest(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestChangePhotoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestChangePhotoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangePhotoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoto_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getReasonForRequest();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestChangePhotoRequest} returns this
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes photo = 2;
 * @return {!(string|Uint8Array)}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.getPhoto = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes photo = 2;
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {string}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.getPhoto_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPhoto()));
};


/**
 * optional bytes photo = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.getPhoto_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPhoto()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.qb.smg.RequestChangePhotoRequest} returns this
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string reason_for_request = 3;
 * @return {string}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.getReasonForRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestChangePhotoRequest} returns this
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.setReasonForRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.RequestChangePhotoRequest} returns this
*/
proto.qb.smg.RequestChangePhotoRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestChangePhotoRequest} returns this
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.RequestChangePhotoRequest} returns this
 */
proto.qb.smg.RequestChangePhotoRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestChangePhotoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestChangePhotoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestChangePhotoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangePhotoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    replacephotorequest: (f = msg.getReplacephotorequest()) && proto.qb.smg.ReplacePhotoRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestChangePhotoReply}
 */
proto.qb.smg.RequestChangePhotoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestChangePhotoReply;
  return proto.qb.smg.RequestChangePhotoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestChangePhotoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestChangePhotoReply}
 */
proto.qb.smg.RequestChangePhotoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.ReplacePhotoRequest;
      reader.readMessage(value,proto.qb.smg.ReplacePhotoRequest.deserializeBinaryFromReader);
      msg.setReplacephotorequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestChangePhotoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestChangePhotoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestChangePhotoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestChangePhotoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getReplacephotorequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.ReplacePhotoRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.RequestChangePhotoReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RequestChangePhotoReply} returns this
 */
proto.qb.smg.RequestChangePhotoReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.RequestChangePhotoReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.RequestChangePhotoReply} returns this
*/
proto.qb.smg.RequestChangePhotoReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestChangePhotoReply} returns this
 */
proto.qb.smg.RequestChangePhotoReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestChangePhotoReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReplacePhotoRequest replacePhotoRequest = 3;
 * @return {?proto.qb.smg.ReplacePhotoRequest}
 */
proto.qb.smg.RequestChangePhotoReply.prototype.getReplacephotorequest = function() {
  return /** @type{?proto.qb.smg.ReplacePhotoRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.ReplacePhotoRequest, 3));
};


/**
 * @param {?proto.qb.smg.ReplacePhotoRequest|undefined} value
 * @return {!proto.qb.smg.RequestChangePhotoReply} returns this
*/
proto.qb.smg.RequestChangePhotoReply.prototype.setReplacephotorequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestChangePhotoReply} returns this
 */
proto.qb.smg.RequestChangePhotoReply.prototype.clearReplacephotorequest = function() {
  return this.setReplacephotorequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestChangePhotoReply.prototype.hasReplacephotorequest = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetChangePhotoRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetChangePhotoRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetChangePhotoRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateStart: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    token: jspb.Message.getFieldWithDefault(msg, 6, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetChangePhotoRequestsRequest;
  return proto.qb.smg.GetChangePhotoRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetChangePhotoRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateEnd(value);
      break;
    case 5:
      var value = /** @type {!proto.qb.smg.ReplacePhotoStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetChangePhotoRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetChangePhotoRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetChangePhotoRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateStart();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int64 request_id = 1;
 * @return {number}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 player_id = 2;
 * @return {number}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_start = 3;
 * @return {number}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getDateStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setDateStart = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 date_end = 4;
 * @return {number}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getDateEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ReplacePhotoStatus status = 5;
 * @return {!proto.qb.smg.ReplacePhotoStatus}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getStatus = function() {
  return /** @type {!proto.qb.smg.ReplacePhotoStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qb.smg.ReplacePhotoStatus} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string token = 6;
 * @return {string}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
*/
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsRequest} returns this
 */
proto.qb.smg.GetChangePhotoRequestsRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetChangePhotoRequestsReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetChangePhotoRequestsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetChangePhotoRequestsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetChangePhotoRequestsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    replacePhotoRequestsList: jspb.Message.toObjectList(msg.getReplacePhotoRequestsList(),
    proto.qb.smg.ReplacePhotoRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetChangePhotoRequestsReply}
 */
proto.qb.smg.GetChangePhotoRequestsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetChangePhotoRequestsReply;
  return proto.qb.smg.GetChangePhotoRequestsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetChangePhotoRequestsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetChangePhotoRequestsReply}
 */
proto.qb.smg.GetChangePhotoRequestsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.ReplacePhotoRequest;
      reader.readMessage(value,proto.qb.smg.ReplacePhotoRequest.deserializeBinaryFromReader);
      msg.addReplacePhotoRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetChangePhotoRequestsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetChangePhotoRequestsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetChangePhotoRequestsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getReplacePhotoRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.ReplacePhotoRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsReply} returns this
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsReply} returns this
*/
proto.qb.smg.GetChangePhotoRequestsReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetChangePhotoRequestsReply} returns this
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ReplacePhotoRequest replace_photo_requests = 3;
 * @return {!Array<!proto.qb.smg.ReplacePhotoRequest>}
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.getReplacePhotoRequestsList = function() {
  return /** @type{!Array<!proto.qb.smg.ReplacePhotoRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.ReplacePhotoRequest, 3));
};


/**
 * @param {!Array<!proto.qb.smg.ReplacePhotoRequest>} value
 * @return {!proto.qb.smg.GetChangePhotoRequestsReply} returns this
*/
proto.qb.smg.GetChangePhotoRequestsReply.prototype.setReplacePhotoRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.ReplacePhotoRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.ReplacePhotoRequest}
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.addReplacePhotoRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.ReplacePhotoRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetChangePhotoRequestsReply} returns this
 */
proto.qb.smg.GetChangePhotoRequestsReply.prototype.clearReplacePhotoRequestsList = function() {
  return this.setReplacePhotoRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ApproveChangePhotoRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ApproveChangePhotoRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    csrId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    commentFromCsr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    approved: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    token: jspb.Message.getFieldWithDefault(msg, 5, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ApproveChangePhotoRequestRequest;
  return proto.qb.smg.ApproveChangePhotoRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ApproveChangePhotoRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCsrId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentFromCsr(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ApproveChangePhotoRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ApproveChangePhotoRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCsrId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCommentFromCsr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int64 request_id = 1;
 * @return {number}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 csr_id = 2;
 * @return {number}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.getCsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.setCsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string comment_from_csr = 3;
 * @return {string}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.getCommentFromCsr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.setCommentFromCsr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool approved = 4;
 * @return {boolean}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.setApproved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string token = 5;
 * @return {string}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
*/
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestRequest} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ApproveChangePhotoRequestReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ApproveChangePhotoRequestReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ApproveChangePhotoRequestReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    replacephotorequest: (f = msg.getReplacephotorequest()) && proto.qb.smg.ReplacePhotoRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ApproveChangePhotoRequestReply}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ApproveChangePhotoRequestReply;
  return proto.qb.smg.ApproveChangePhotoRequestReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ApproveChangePhotoRequestReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ApproveChangePhotoRequestReply}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.ReplacePhotoRequest;
      reader.readMessage(value,proto.qb.smg.ReplacePhotoRequest.deserializeBinaryFromReader);
      msg.setReplacephotorequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ApproveChangePhotoRequestReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ApproveChangePhotoRequestReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ApproveChangePhotoRequestReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getReplacephotorequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.ReplacePhotoRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestReply} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestReply} returns this
*/
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ApproveChangePhotoRequestReply} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReplacePhotoRequest replacePhotoRequest = 3;
 * @return {?proto.qb.smg.ReplacePhotoRequest}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.getReplacephotorequest = function() {
  return /** @type{?proto.qb.smg.ReplacePhotoRequest} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.ReplacePhotoRequest, 3));
};


/**
 * @param {?proto.qb.smg.ReplacePhotoRequest|undefined} value
 * @return {!proto.qb.smg.ApproveChangePhotoRequestReply} returns this
*/
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.setReplacephotorequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ApproveChangePhotoRequestReply} returns this
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.clearReplacephotorequest = function() {
  return this.setReplacephotorequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ApproveChangePhotoRequestReply.prototype.hasReplacephotorequest = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetTncRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetTncRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetTncRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTncRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetTncRequest}
 */
proto.qb.smg.GetTncRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetTncRequest;
  return proto.qb.smg.GetTncRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetTncRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetTncRequest}
 */
proto.qb.smg.GetTncRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetTncRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetTncRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetTncRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTncRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetTncRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetTncRequest} returns this
 */
proto.qb.smg.GetTncRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.qb.smg.GetTncRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetTncRequest} returns this
 */
proto.qb.smg.GetTncRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetTncRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetTncRequest} returns this
*/
proto.qb.smg.GetTncRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetTncRequest} returns this
 */
proto.qb.smg.GetTncRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetTncRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetTncRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetTncRequest} returns this
 */
proto.qb.smg.GetTncRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetTncReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetTncReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetTncReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTncReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetTncReply}
 */
proto.qb.smg.GetTncReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetTncReply;
  return proto.qb.smg.GetTncReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetTncReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetTncReply}
 */
proto.qb.smg.GetTncReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetTncReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetTncReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetTncReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTncReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetTncReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetTncReply} returns this
 */
proto.qb.smg.GetTncReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetTncReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetTncReply} returns this
*/
proto.qb.smg.GetTncReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetTncReply} returns this
 */
proto.qb.smg.GetTncReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetTncReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.qb.smg.GetTncReply.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetTncReply} returns this
 */
proto.qb.smg.GetTncReply.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.qb.smg.GetTncReply.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetTncReply} returns this
 */
proto.qb.smg.GetTncReply.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AcceptTncRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AcceptTncRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AcceptTncRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptTncRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AcceptTncRequest}
 */
proto.qb.smg.AcceptTncRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AcceptTncRequest;
  return proto.qb.smg.AcceptTncRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AcceptTncRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AcceptTncRequest}
 */
proto.qb.smg.AcceptTncRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AcceptTncRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AcceptTncRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AcceptTncRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptTncRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.AcceptTncRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.AcceptTncRequest} returns this
 */
proto.qb.smg.AcceptTncRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.AcceptTncRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.AcceptTncRequest} returns this
*/
proto.qb.smg.AcceptTncRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.AcceptTncRequest} returns this
 */
proto.qb.smg.AcceptTncRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.AcceptTncRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.AcceptTncRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AcceptTncRequest} returns this
 */
proto.qb.smg.AcceptTncRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AcceptTncReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AcceptTncReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AcceptTncReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptTncReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AcceptTncReply}
 */
proto.qb.smg.AcceptTncReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AcceptTncReply;
  return proto.qb.smg.AcceptTncReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AcceptTncReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AcceptTncReply}
 */
proto.qb.smg.AcceptTncReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AcceptTncReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AcceptTncReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AcceptTncReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptTncReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.AcceptTncReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.AcceptTncReply} returns this
 */
proto.qb.smg.AcceptTncReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.AcceptTncReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.AcceptTncReply} returns this
*/
proto.qb.smg.AcceptTncReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.AcceptTncReply} returns this
 */
proto.qb.smg.AcceptTncReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.AcceptTncReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.OcrResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.OcrResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.OcrResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.OcrResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    street: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.OcrResult}
 */
proto.qb.smg.OcrResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.OcrResult;
  return proto.qb.smg.OcrResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.OcrResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.OcrResult}
 */
proto.qb.smg.OcrResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.OcrResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.OcrResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.OcrResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.OcrResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.qb.smg.OcrResult.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.OcrResult} returns this
 */
proto.qb.smg.OcrResult.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.qb.smg.OcrResult.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.OcrResult} returns this
 */
proto.qb.smg.OcrResult.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.qb.smg.OcrResult.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.OcrResult} returns this
 */
proto.qb.smg.OcrResult.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.qb.smg.OcrResult.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.OcrResult} returns this
 */
proto.qb.smg.OcrResult.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.qb.smg.OcrResult.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.OcrResult} returns this
 */
proto.qb.smg.OcrResult.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.qb.smg.OcrResult.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.OcrResult} returns this
 */
proto.qb.smg.OcrResult.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DriverLicenseFrontExtractedData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DriverLicenseFrontExtractedData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DriverLicenseFrontExtractedData.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    street: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DriverLicenseFrontExtractedData;
  return proto.qb.smg.DriverLicenseFrontExtractedData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DriverLicenseFrontExtractedData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DriverLicenseFrontExtractedData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DriverLicenseFrontExtractedData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DriverLicenseFrontExtractedData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData} returns this
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData} returns this
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData} returns this
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData} returns this
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData} returns this
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseFrontExtractedData} returns this
 */
proto.qb.smg.DriverLicenseFrontExtractedData.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DriverLicenseBackExtractedData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DriverLicenseBackExtractedData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DriverLicenseBackExtractedData.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData}
 */
proto.qb.smg.DriverLicenseBackExtractedData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DriverLicenseBackExtractedData;
  return proto.qb.smg.DriverLicenseBackExtractedData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DriverLicenseBackExtractedData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData}
 */
proto.qb.smg.DriverLicenseBackExtractedData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DriverLicenseBackExtractedData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DriverLicenseBackExtractedData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DriverLicenseBackExtractedData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData} returns this
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData} returns this
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData} returns this
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData} returns this
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData} returns this
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DriverLicenseBackExtractedData} returns this
 */
proto.qb.smg.DriverLicenseBackExtractedData.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StartVeriffKycVerificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StartVeriffKycVerificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartVeriffKycVerificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StartVeriffKycVerificationRequest}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StartVeriffKycVerificationRequest;
  return proto.qb.smg.StartVeriffKycVerificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StartVeriffKycVerificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StartVeriffKycVerificationRequest}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StartVeriffKycVerificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StartVeriffKycVerificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartVeriffKycVerificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StartVeriffKycVerificationRequest} returns this
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.StartVeriffKycVerificationRequest} returns this
*/
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StartVeriffKycVerificationRequest} returns this
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StartVeriffKycVerificationRequest} returns this
 */
proto.qb.smg.StartVeriffKycVerificationRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StartVeriffKycVerificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StartVeriffKycVerificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartVeriffKycVerificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    veriffurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StartVeriffKycVerificationResponse}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StartVeriffKycVerificationResponse;
  return proto.qb.smg.StartVeriffKycVerificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StartVeriffKycVerificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StartVeriffKycVerificationResponse}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVeriffurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StartVeriffKycVerificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StartVeriffKycVerificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartVeriffKycVerificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getVeriffurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StartVeriffKycVerificationResponse} returns this
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.StartVeriffKycVerificationResponse} returns this
*/
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StartVeriffKycVerificationResponse} returns this
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string veriffUrl = 3;
 * @return {string}
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.getVeriffurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StartVeriffKycVerificationResponse} returns this
 */
proto.qb.smg.StartVeriffKycVerificationResponse.prototype.setVeriffurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SubmitPhotoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SubmitPhotoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SubmitPhotoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    image: msg.getImage_asB64(),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SubmitPhotoRequest}
 */
proto.qb.smg.SubmitPhotoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SubmitPhotoRequest;
  return proto.qb.smg.SubmitPhotoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SubmitPhotoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SubmitPhotoRequest}
 */
proto.qb.smg.SubmitPhotoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SubmitPhotoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SubmitPhotoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SubmitPhotoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SubmitPhotoRequest} returns this
 */
proto.qb.smg.SubmitPhotoRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.qb.smg.SubmitPhotoRequest} returns this
 */
proto.qb.smg.SubmitPhotoRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.SubmitPhotoRequest} returns this
*/
proto.qb.smg.SubmitPhotoRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SubmitPhotoRequest} returns this
 */
proto.qb.smg.SubmitPhotoRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.SubmitPhotoRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SubmitPhotoRequest} returns this
 */
proto.qb.smg.SubmitPhotoRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SubmitPhotoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SubmitPhotoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SubmitPhotoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SubmitPhotoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    isRecognized: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    submissionAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SubmitPhotoReply}
 */
proto.qb.smg.SubmitPhotoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SubmitPhotoReply;
  return proto.qb.smg.SubmitPhotoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SubmitPhotoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SubmitPhotoReply}
 */
proto.qb.smg.SubmitPhotoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecognized(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubmissionAllowed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SubmitPhotoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SubmitPhotoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SubmitPhotoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SubmitPhotoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getIsRecognized();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSubmissionAllowed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.SubmitPhotoReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.SubmitPhotoReply} returns this
 */
proto.qb.smg.SubmitPhotoReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.SubmitPhotoReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.SubmitPhotoReply} returns this
*/
proto.qb.smg.SubmitPhotoReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SubmitPhotoReply} returns this
 */
proto.qb.smg.SubmitPhotoReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SubmitPhotoReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_recognized = 3;
 * @return {boolean}
 */
proto.qb.smg.SubmitPhotoReply.prototype.getIsRecognized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.SubmitPhotoReply} returns this
 */
proto.qb.smg.SubmitPhotoReply.prototype.setIsRecognized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool submission_allowed = 4;
 * @return {boolean}
 */
proto.qb.smg.SubmitPhotoReply.prototype.getSubmissionAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.SubmitPhotoReply} returns this
 */
proto.qb.smg.SubmitPhotoReply.prototype.setSubmissionAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ContactCsrRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ContactCsrRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ContactCsrRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ContactCsrRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    request: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ContactCsrRequest}
 */
proto.qb.smg.ContactCsrRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ContactCsrRequest;
  return proto.qb.smg.ContactCsrRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ContactCsrRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ContactCsrRequest}
 */
proto.qb.smg.ContactCsrRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ContactCsrRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ContactCsrRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ContactCsrRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ContactCsrRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.ContactCsrRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ContactCsrRequest} returns this
 */
proto.qb.smg.ContactCsrRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string request = 2;
 * @return {string}
 */
proto.qb.smg.ContactCsrRequest.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ContactCsrRequest} returns this
 */
proto.qb.smg.ContactCsrRequest.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.ContactCsrRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.ContactCsrRequest} returns this
*/
proto.qb.smg.ContactCsrRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ContactCsrRequest} returns this
 */
proto.qb.smg.ContactCsrRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ContactCsrRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.ContactCsrRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ContactCsrRequest} returns this
 */
proto.qb.smg.ContactCsrRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ContactCsrReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ContactCsrReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ContactCsrReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ContactCsrReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ContactCsrReply}
 */
proto.qb.smg.ContactCsrReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ContactCsrReply;
  return proto.qb.smg.ContactCsrReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ContactCsrReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ContactCsrReply}
 */
proto.qb.smg.ContactCsrReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ContactCsrReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ContactCsrReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ContactCsrReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ContactCsrReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.ContactCsrReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ContactCsrReply} returns this
 */
proto.qb.smg.ContactCsrReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ContactCsrReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ContactCsrReply} returns this
*/
proto.qb.smg.ContactCsrReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ContactCsrReply} returns this
 */
proto.qb.smg.ContactCsrReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ContactCsrReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    handedness: jspb.Message.getFieldWithDefault(msg, 6, ""),
    handicap: jspb.Message.getFieldWithDefault(msg, 16, ""),
    allowTextNotifications: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    allowEmailNotifications: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    allowPartnerPromos: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.UpdateUserRequest}
 */
proto.qb.smg.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.UpdateUserRequest;
  return proto.qb.smg.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.UpdateUserRequest}
 */
proto.qb.smg.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandedness(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandicap(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowTextNotifications(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowEmailNotifications(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowPartnerPromos(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHandedness();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHandicap();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAllowTextNotifications();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getAllowEmailNotifications();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getAllowPartnerPromos();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.UpdateUserRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.qb.smg.UpdateUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string handedness = 6;
 * @return {string}
 */
proto.qb.smg.UpdateUserRequest.prototype.getHandedness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setHandedness = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string handicap = 16;
 * @return {string}
 */
proto.qb.smg.UpdateUserRequest.prototype.getHandicap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setHandicap = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool allow_text_notifications = 17;
 * @return {boolean}
 */
proto.qb.smg.UpdateUserRequest.prototype.getAllowTextNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setAllowTextNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool allow_email_notifications = 18;
 * @return {boolean}
 */
proto.qb.smg.UpdateUserRequest.prototype.getAllowEmailNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setAllowEmailNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool allow_partner_promos = 19;
 * @return {boolean}
 */
proto.qb.smg.UpdateUserRequest.prototype.getAllowPartnerPromos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setAllowPartnerPromos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.UpdateUserRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
*/
proto.qb.smg.UpdateUserRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.UpdateUserRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.UpdateUserRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.UpdateUserRequest} returns this
 */
proto.qb.smg.UpdateUserRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.UpdateUserReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.UpdateUserReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.UpdateUserReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdateUserReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.UpdateUserReply}
 */
proto.qb.smg.UpdateUserReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.UpdateUserReply;
  return proto.qb.smg.UpdateUserReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.UpdateUserReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.UpdateUserReply}
 */
proto.qb.smg.UpdateUserReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.UpdateUserReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.UpdateUserReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.UpdateUserReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.UpdateUserReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.UpdateUserReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.UpdateUserReply} returns this
 */
proto.qb.smg.UpdateUserReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.UpdateUserReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.UpdateUserReply} returns this
*/
proto.qb.smg.UpdateUserReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.UpdateUserReply} returns this
 */
proto.qb.smg.UpdateUserReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.UpdateUserReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EmailLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EmailLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EmailLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EmailLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EmailLoginRequest}
 */
proto.qb.smg.EmailLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EmailLoginRequest;
  return proto.qb.smg.EmailLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EmailLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EmailLoginRequest}
 */
proto.qb.smg.EmailLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EmailLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EmailLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EmailLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EmailLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.EmailLoginRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.EmailLoginRequest} returns this
 */
proto.qb.smg.EmailLoginRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.qb.smg.EmailLoginRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.EmailLoginRequest} returns this
 */
proto.qb.smg.EmailLoginRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.EmailLoginRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.EmailLoginRequest} returns this
*/
proto.qb.smg.EmailLoginRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EmailLoginRequest} returns this
 */
proto.qb.smg.EmailLoginRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EmailLoginRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.EmailLoginRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.EmailLoginRequest} returns this
 */
proto.qb.smg.EmailLoginRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EmailLoginReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EmailLoginReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EmailLoginReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EmailLoginReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.qb.smg.Session.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.qb.smg.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EmailLoginReply}
 */
proto.qb.smg.EmailLoginReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EmailLoginReply;
  return proto.qb.smg.EmailLoginReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EmailLoginReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EmailLoginReply}
 */
proto.qb.smg.EmailLoginReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Session;
      reader.readMessage(value,proto.qb.smg.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 4:
      var value = new proto.qb.smg.User;
      reader.readMessage(value,proto.qb.smg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EmailLoginReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EmailLoginReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EmailLoginReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EmailLoginReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Session.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qb.smg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.EmailLoginReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.EmailLoginReply} returns this
 */
proto.qb.smg.EmailLoginReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.EmailLoginReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.EmailLoginReply} returns this
*/
proto.qb.smg.EmailLoginReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EmailLoginReply} returns this
 */
proto.qb.smg.EmailLoginReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EmailLoginReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Session session = 3;
 * @return {?proto.qb.smg.Session}
 */
proto.qb.smg.EmailLoginReply.prototype.getSession = function() {
  return /** @type{?proto.qb.smg.Session} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Session, 3));
};


/**
 * @param {?proto.qb.smg.Session|undefined} value
 * @return {!proto.qb.smg.EmailLoginReply} returns this
*/
proto.qb.smg.EmailLoginReply.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EmailLoginReply} returns this
 */
proto.qb.smg.EmailLoginReply.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EmailLoginReply.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User user = 4;
 * @return {?proto.qb.smg.User}
 */
proto.qb.smg.EmailLoginReply.prototype.getUser = function() {
  return /** @type{?proto.qb.smg.User} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.User, 4));
};


/**
 * @param {?proto.qb.smg.User|undefined} value
 * @return {!proto.qb.smg.EmailLoginReply} returns this
*/
proto.qb.smg.EmailLoginReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EmailLoginReply} returns this
 */
proto.qb.smg.EmailLoginReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EmailLoginReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ImageLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ImageLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ImageLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ImageLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: msg.getImage_asB64(),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ImageLoginRequest}
 */
proto.qb.smg.ImageLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ImageLoginRequest;
  return proto.qb.smg.ImageLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ImageLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ImageLoginRequest}
 */
proto.qb.smg.ImageLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ImageLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ImageLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ImageLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ImageLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.qb.smg.ImageLoginRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.qb.smg.ImageLoginRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.qb.smg.ImageLoginRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.qb.smg.ImageLoginRequest} returns this
 */
proto.qb.smg.ImageLoginRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.ImageLoginRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.ImageLoginRequest} returns this
*/
proto.qb.smg.ImageLoginRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ImageLoginRequest} returns this
 */
proto.qb.smg.ImageLoginRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ImageLoginRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.ImageLoginRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ImageLoginRequest} returns this
 */
proto.qb.smg.ImageLoginRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ImageLoginReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ImageLoginReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ImageLoginReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ImageLoginReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.qb.smg.Session.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.qb.smg.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ImageLoginReply}
 */
proto.qb.smg.ImageLoginReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ImageLoginReply;
  return proto.qb.smg.ImageLoginReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ImageLoginReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ImageLoginReply}
 */
proto.qb.smg.ImageLoginReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Session;
      reader.readMessage(value,proto.qb.smg.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 4:
      var value = new proto.qb.smg.User;
      reader.readMessage(value,proto.qb.smg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ImageLoginReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ImageLoginReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ImageLoginReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ImageLoginReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Session.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qb.smg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.ImageLoginReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ImageLoginReply} returns this
 */
proto.qb.smg.ImageLoginReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ImageLoginReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ImageLoginReply} returns this
*/
proto.qb.smg.ImageLoginReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ImageLoginReply} returns this
 */
proto.qb.smg.ImageLoginReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ImageLoginReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Session session = 3;
 * @return {?proto.qb.smg.Session}
 */
proto.qb.smg.ImageLoginReply.prototype.getSession = function() {
  return /** @type{?proto.qb.smg.Session} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Session, 3));
};


/**
 * @param {?proto.qb.smg.Session|undefined} value
 * @return {!proto.qb.smg.ImageLoginReply} returns this
*/
proto.qb.smg.ImageLoginReply.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ImageLoginReply} returns this
 */
proto.qb.smg.ImageLoginReply.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ImageLoginReply.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User user = 4;
 * @return {?proto.qb.smg.User}
 */
proto.qb.smg.ImageLoginReply.prototype.getUser = function() {
  return /** @type{?proto.qb.smg.User} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.User, 4));
};


/**
 * @param {?proto.qb.smg.User|undefined} value
 * @return {!proto.qb.smg.ImageLoginReply} returns this
*/
proto.qb.smg.ImageLoginReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ImageLoginReply} returns this
 */
proto.qb.smg.ImageLoginReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ImageLoginReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestSmsCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestSmsCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestSmsCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestSmsCodeRequest}
 */
proto.qb.smg.RequestSmsCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestSmsCodeRequest;
  return proto.qb.smg.RequestSmsCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestSmsCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestSmsCodeRequest}
 */
proto.qb.smg.RequestSmsCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestSmsCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestSmsCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestSmsCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestSmsCodeRequest} returns this
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestSmsCodeRequest} returns this
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.RequestSmsCodeRequest} returns this
*/
proto.qb.smg.RequestSmsCodeRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestSmsCodeRequest} returns this
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.RequestSmsCodeRequest} returns this
 */
proto.qb.smg.RequestSmsCodeRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestSmsCodeReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestSmsCodeReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestSmsCodeReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestSmsCodeReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestSmsCodeReply}
 */
proto.qb.smg.RequestSmsCodeReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestSmsCodeReply;
  return proto.qb.smg.RequestSmsCodeReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestSmsCodeReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestSmsCodeReply}
 */
proto.qb.smg.RequestSmsCodeReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestSmsCodeReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestSmsCodeReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestSmsCodeReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestSmsCodeReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.RequestSmsCodeReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RequestSmsCodeReply} returns this
 */
proto.qb.smg.RequestSmsCodeReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.RequestSmsCodeReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.RequestSmsCodeReply} returns this
*/
proto.qb.smg.RequestSmsCodeReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestSmsCodeReply} returns this
 */
proto.qb.smg.RequestSmsCodeReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestSmsCodeReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestEmailCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestEmailCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestEmailCodeRequest}
 */
proto.qb.smg.RequestEmailCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestEmailCodeRequest;
  return proto.qb.smg.RequestEmailCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestEmailCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestEmailCodeRequest}
 */
proto.qb.smg.RequestEmailCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestEmailCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestEmailCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.RequestEmailCodeRequest} returns this
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.RequestEmailCodeRequest} returns this
*/
proto.qb.smg.RequestEmailCodeRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestEmailCodeRequest} returns this
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.RequestEmailCodeRequest} returns this
 */
proto.qb.smg.RequestEmailCodeRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.RequestEmailCodeReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.RequestEmailCodeReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.RequestEmailCodeReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailCodeReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.RequestEmailCodeReply}
 */
proto.qb.smg.RequestEmailCodeReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.RequestEmailCodeReply;
  return proto.qb.smg.RequestEmailCodeReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.RequestEmailCodeReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.RequestEmailCodeReply}
 */
proto.qb.smg.RequestEmailCodeReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.RequestEmailCodeReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.RequestEmailCodeReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.RequestEmailCodeReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.RequestEmailCodeReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.RequestEmailCodeReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.RequestEmailCodeReply} returns this
 */
proto.qb.smg.RequestEmailCodeReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.RequestEmailCodeReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.RequestEmailCodeReply} returns this
*/
proto.qb.smg.RequestEmailCodeReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.RequestEmailCodeReply} returns this
 */
proto.qb.smg.RequestEmailCodeReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.RequestEmailCodeReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EnableSelfLimitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EnableSelfLimitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableSelfLimitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 2, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EnableSelfLimitationRequest}
 */
proto.qb.smg.EnableSelfLimitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EnableSelfLimitationRequest;
  return proto.qb.smg.EnableSelfLimitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EnableSelfLimitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EnableSelfLimitationRequest}
 */
proto.qb.smg.EnableSelfLimitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!proto.qb.smg.EnableSelfLimitationRequest.TimeFrame} */ (reader.readEnum());
      msg.setTimeFrame(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EnableSelfLimitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EnableSelfLimitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableSelfLimitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qb.smg.EnableSelfLimitationRequest.TimeFrame = {
  SIX_MONTH: 0,
  ONE_YEAR: 1,
  TWO_YEAR: 2,
  FOREVER: 3
};

/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.EnableSelfLimitationRequest} returns this
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TimeFrame time_frame = 2;
 * @return {!proto.qb.smg.EnableSelfLimitationRequest.TimeFrame}
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.getTimeFrame = function() {
  return /** @type {!proto.qb.smg.EnableSelfLimitationRequest.TimeFrame} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qb.smg.EnableSelfLimitationRequest.TimeFrame} value
 * @return {!proto.qb.smg.EnableSelfLimitationRequest} returns this
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.EnableSelfLimitationRequest} returns this
*/
proto.qb.smg.EnableSelfLimitationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EnableSelfLimitationRequest} returns this
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.EnableSelfLimitationRequest} returns this
 */
proto.qb.smg.EnableSelfLimitationRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EnableSelfLimitationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EnableSelfLimitationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EnableSelfLimitationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableSelfLimitationReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EnableSelfLimitationReply}
 */
proto.qb.smg.EnableSelfLimitationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EnableSelfLimitationReply;
  return proto.qb.smg.EnableSelfLimitationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EnableSelfLimitationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EnableSelfLimitationReply}
 */
proto.qb.smg.EnableSelfLimitationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EnableSelfLimitationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EnableSelfLimitationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EnableSelfLimitationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableSelfLimitationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.EnableSelfLimitationReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.EnableSelfLimitationReply} returns this
 */
proto.qb.smg.EnableSelfLimitationReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.EnableSelfLimitationReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.EnableSelfLimitationReply} returns this
*/
proto.qb.smg.EnableSelfLimitationReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EnableSelfLimitationReply} returns this
 */
proto.qb.smg.EnableSelfLimitationReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EnableSelfLimitationReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SmsLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SmsLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SmsLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SmsLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    smsCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SmsLoginRequest}
 */
proto.qb.smg.SmsLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SmsLoginRequest;
  return proto.qb.smg.SmsLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SmsLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SmsLoginRequest}
 */
proto.qb.smg.SmsLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmsCode(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SmsLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SmsLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SmsLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SmsLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSmsCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.SmsLoginRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SmsLoginRequest} returns this
 */
proto.qb.smg.SmsLoginRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sms_code = 2;
 * @return {string}
 */
proto.qb.smg.SmsLoginRequest.prototype.getSmsCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SmsLoginRequest} returns this
 */
proto.qb.smg.SmsLoginRequest.prototype.setSmsCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.SmsLoginRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.SmsLoginRequest} returns this
*/
proto.qb.smg.SmsLoginRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SmsLoginRequest} returns this
 */
proto.qb.smg.SmsLoginRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SmsLoginRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.SmsLoginRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SmsLoginRequest} returns this
 */
proto.qb.smg.SmsLoginRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SmsLoginReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SmsLoginReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SmsLoginReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SmsLoginReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.qb.smg.Session.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.qb.smg.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SmsLoginReply}
 */
proto.qb.smg.SmsLoginReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SmsLoginReply;
  return proto.qb.smg.SmsLoginReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SmsLoginReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SmsLoginReply}
 */
proto.qb.smg.SmsLoginReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Session;
      reader.readMessage(value,proto.qb.smg.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 4:
      var value = new proto.qb.smg.User;
      reader.readMessage(value,proto.qb.smg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SmsLoginReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SmsLoginReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SmsLoginReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SmsLoginReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Session.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qb.smg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.SmsLoginReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.SmsLoginReply} returns this
 */
proto.qb.smg.SmsLoginReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.SmsLoginReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.SmsLoginReply} returns this
*/
proto.qb.smg.SmsLoginReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SmsLoginReply} returns this
 */
proto.qb.smg.SmsLoginReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SmsLoginReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Session session = 3;
 * @return {?proto.qb.smg.Session}
 */
proto.qb.smg.SmsLoginReply.prototype.getSession = function() {
  return /** @type{?proto.qb.smg.Session} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Session, 3));
};


/**
 * @param {?proto.qb.smg.Session|undefined} value
 * @return {!proto.qb.smg.SmsLoginReply} returns this
*/
proto.qb.smg.SmsLoginReply.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SmsLoginReply} returns this
 */
proto.qb.smg.SmsLoginReply.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SmsLoginReply.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User user = 4;
 * @return {?proto.qb.smg.User}
 */
proto.qb.smg.SmsLoginReply.prototype.getUser = function() {
  return /** @type{?proto.qb.smg.User} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.User, 4));
};


/**
 * @param {?proto.qb.smg.User|undefined} value
 * @return {!proto.qb.smg.SmsLoginReply} returns this
*/
proto.qb.smg.SmsLoginReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SmsLoginReply} returns this
 */
proto.qb.smg.SmsLoginReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SmsLoginReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.VerifySmsCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.VerifySmsCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.VerifySmsCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    smsCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.VerifySmsCodeRequest}
 */
proto.qb.smg.VerifySmsCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.VerifySmsCodeRequest;
  return proto.qb.smg.VerifySmsCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.VerifySmsCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.VerifySmsCodeRequest}
 */
proto.qb.smg.VerifySmsCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmsCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.VerifySmsCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.VerifySmsCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.VerifySmsCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSmsCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.VerifySmsCodeRequest} returns this
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.VerifySmsCodeRequest} returns this
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sms_code = 3;
 * @return {string}
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.getSmsCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.VerifySmsCodeRequest} returns this
 */
proto.qb.smg.VerifySmsCodeRequest.prototype.setSmsCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.VerifySmsCodeReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.VerifySmsCodeReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.VerifySmsCodeReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.VerifySmsCodeReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    codeiscorrect: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.VerifySmsCodeReply}
 */
proto.qb.smg.VerifySmsCodeReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.VerifySmsCodeReply;
  return proto.qb.smg.VerifySmsCodeReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.VerifySmsCodeReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.VerifySmsCodeReply}
 */
proto.qb.smg.VerifySmsCodeReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCodeiscorrect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.VerifySmsCodeReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.VerifySmsCodeReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.VerifySmsCodeReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.VerifySmsCodeReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getCodeiscorrect();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.VerifySmsCodeReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.VerifySmsCodeReply} returns this
 */
proto.qb.smg.VerifySmsCodeReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.VerifySmsCodeReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.VerifySmsCodeReply} returns this
*/
proto.qb.smg.VerifySmsCodeReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.VerifySmsCodeReply} returns this
 */
proto.qb.smg.VerifySmsCodeReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.VerifySmsCodeReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool codeIsCorrect = 3;
 * @return {boolean}
 */
proto.qb.smg.VerifySmsCodeReply.prototype.getCodeiscorrect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.VerifySmsCodeReply} returns this
 */
proto.qb.smg.VerifySmsCodeReply.prototype.setCodeiscorrect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.LoginWithIdTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.LoginWithIdTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LoginWithIdTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.LoginWithIdTokenRequest}
 */
proto.qb.smg.LoginWithIdTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.LoginWithIdTokenRequest;
  return proto.qb.smg.LoginWithIdTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.LoginWithIdTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.LoginWithIdTokenRequest}
 */
proto.qb.smg.LoginWithIdTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.LoginWithIdTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.LoginWithIdTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LoginWithIdTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string id_token = 2;
 * @return {string}
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.getIdToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.LoginWithIdTokenRequest} returns this
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.setIdToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.LoginWithIdTokenRequest} returns this
*/
proto.qb.smg.LoginWithIdTokenRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LoginWithIdTokenRequest} returns this
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.LoginWithIdTokenRequest} returns this
 */
proto.qb.smg.LoginWithIdTokenRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.LoginWithIdTokenReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.LoginWithIdTokenReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LoginWithIdTokenReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.qb.smg.Session.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.qb.smg.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.LoginWithIdTokenReply}
 */
proto.qb.smg.LoginWithIdTokenReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.LoginWithIdTokenReply;
  return proto.qb.smg.LoginWithIdTokenReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.LoginWithIdTokenReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.LoginWithIdTokenReply}
 */
proto.qb.smg.LoginWithIdTokenReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Session;
      reader.readMessage(value,proto.qb.smg.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 4:
      var value = new proto.qb.smg.User;
      reader.readMessage(value,proto.qb.smg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.LoginWithIdTokenReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.LoginWithIdTokenReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.LoginWithIdTokenReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Session.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qb.smg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.LoginWithIdTokenReply} returns this
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.LoginWithIdTokenReply} returns this
*/
proto.qb.smg.LoginWithIdTokenReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LoginWithIdTokenReply} returns this
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Session session = 3;
 * @return {?proto.qb.smg.Session}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.getSession = function() {
  return /** @type{?proto.qb.smg.Session} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Session, 3));
};


/**
 * @param {?proto.qb.smg.Session|undefined} value
 * @return {!proto.qb.smg.LoginWithIdTokenReply} returns this
*/
proto.qb.smg.LoginWithIdTokenReply.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LoginWithIdTokenReply} returns this
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User user = 4;
 * @return {?proto.qb.smg.User}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.getUser = function() {
  return /** @type{?proto.qb.smg.User} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.User, 4));
};


/**
 * @param {?proto.qb.smg.User|undefined} value
 * @return {!proto.qb.smg.LoginWithIdTokenReply} returns this
*/
proto.qb.smg.LoginWithIdTokenReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.LoginWithIdTokenReply} returns this
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.LoginWithIdTokenReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetUserRequest}
 */
proto.qb.smg.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetUserRequest;
  return proto.qb.smg.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetUserRequest}
 */
proto.qb.smg.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetUserRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetUserRequest} returns this
 */
proto.qb.smg.GetUserRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetUserRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetUserRequest} returns this
*/
proto.qb.smg.GetUserRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetUserRequest} returns this
 */
proto.qb.smg.GetUserRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetUserRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetUserRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetUserRequest} returns this
 */
proto.qb.smg.GetUserRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetUserReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetUserReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetUserReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUserReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.qb.smg.User.toObject(includeInstance, f),
    maxshotamount: jspb.Message.getFieldWithDefault(msg, 25, 0),
    hasactivetournament: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    isnewplayer: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    freeshotcount: jspb.Message.getFieldWithDefault(msg, 28, 0),
    freeshotnumber: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetUserReply}
 */
proto.qb.smg.GetUserReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetUserReply;
  return proto.qb.smg.GetUserReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetUserReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetUserReply}
 */
proto.qb.smg.GetUserReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.User;
      reader.readMessage(value,proto.qb.smg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxshotamount(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasactivetournament(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnewplayer(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreeshotcount(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreeshotnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetUserReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetUserReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetUserReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUserReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.User.serializeBinaryToWriter
    );
  }
  f = message.getMaxshotamount();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getHasactivetournament();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getIsnewplayer();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getFreeshotcount();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getFreeshotnumber();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetUserReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetUserReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetUserReply} returns this
*/
proto.qb.smg.GetUserReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetUserReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional User user = 3;
 * @return {?proto.qb.smg.User}
 */
proto.qb.smg.GetUserReply.prototype.getUser = function() {
  return /** @type{?proto.qb.smg.User} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.User, 3));
};


/**
 * @param {?proto.qb.smg.User|undefined} value
 * @return {!proto.qb.smg.GetUserReply} returns this
*/
proto.qb.smg.GetUserReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetUserReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 maxShotAmount = 25;
 * @return {number}
 */
proto.qb.smg.GetUserReply.prototype.getMaxshotamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.setMaxshotamount = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool hasActiveTournament = 26;
 * @return {boolean}
 */
proto.qb.smg.GetUserReply.prototype.getHasactivetournament = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.setHasactivetournament = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool isNewPlayer = 27;
 * @return {boolean}
 */
proto.qb.smg.GetUserReply.prototype.getIsnewplayer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.setIsnewplayer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional int32 freeShotCount = 28;
 * @return {number}
 */
proto.qb.smg.GetUserReply.prototype.getFreeshotcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.setFreeshotcount = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 freeShotNumber = 29;
 * @return {number}
 */
proto.qb.smg.GetUserReply.prototype.getFreeshotnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetUserReply} returns this
 */
proto.qb.smg.GetUserReply.prototype.setFreeshotnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBalanceRequest}
 */
proto.qb.smg.GetBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBalanceRequest;
  return proto.qb.smg.GetBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBalanceRequest}
 */
proto.qb.smg.GetBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetBalanceRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetBalanceRequest} returns this
 */
proto.qb.smg.GetBalanceRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetBalanceRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetBalanceRequest} returns this
*/
proto.qb.smg.GetBalanceRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBalanceRequest} returns this
 */
proto.qb.smg.GetBalanceRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBalanceRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetBalanceRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBalanceRequest} returns this
 */
proto.qb.smg.GetBalanceRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetBalanceReply.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBalanceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBalanceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBalanceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBalanceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    balance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalBonusesBalance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    walletBonusesList: jspb.Message.toObjectList(msg.getWalletBonusesList(),
    proto.qb.smg.WalletBonus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBalanceReply}
 */
proto.qb.smg.GetBalanceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBalanceReply;
  return proto.qb.smg.GetBalanceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBalanceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBalanceReply}
 */
proto.qb.smg.GetBalanceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBalance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBonusesBalance(value);
      break;
    case 5:
      var value = new proto.qb.smg.WalletBonus;
      reader.readMessage(value,proto.qb.smg.WalletBonus.deserializeBinaryFromReader);
      msg.addWalletBonuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBalanceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBalanceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBalanceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBalanceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalBonusesBalance();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getWalletBonusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.qb.smg.WalletBonus.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetBalanceReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBalanceReply} returns this
 */
proto.qb.smg.GetBalanceReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetBalanceReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetBalanceReply} returns this
*/
proto.qb.smg.GetBalanceReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBalanceReply} returns this
 */
proto.qb.smg.GetBalanceReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBalanceReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 balance = 3;
 * @return {number}
 */
proto.qb.smg.GetBalanceReply.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBalanceReply} returns this
 */
proto.qb.smg.GetBalanceReply.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_bonuses_balance = 4;
 * @return {number}
 */
proto.qb.smg.GetBalanceReply.prototype.getTotalBonusesBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBalanceReply} returns this
 */
proto.qb.smg.GetBalanceReply.prototype.setTotalBonusesBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated WalletBonus wallet_bonuses = 5;
 * @return {!Array<!proto.qb.smg.WalletBonus>}
 */
proto.qb.smg.GetBalanceReply.prototype.getWalletBonusesList = function() {
  return /** @type{!Array<!proto.qb.smg.WalletBonus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.WalletBonus, 5));
};


/**
 * @param {!Array<!proto.qb.smg.WalletBonus>} value
 * @return {!proto.qb.smg.GetBalanceReply} returns this
*/
proto.qb.smg.GetBalanceReply.prototype.setWalletBonusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.qb.smg.WalletBonus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.WalletBonus}
 */
proto.qb.smg.GetBalanceReply.prototype.addWalletBonuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.qb.smg.WalletBonus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetBalanceReply} returns this
 */
proto.qb.smg.GetBalanceReply.prototype.clearWalletBonusesList = function() {
  return this.setWalletBonusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.WalletBonus.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.WalletBonus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.WalletBonus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.WalletBonus.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bonusAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    turnoverAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    turnoverCurrent: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.WalletBonus}
 */
proto.qb.smg.WalletBonus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.WalletBonus;
  return proto.qb.smg.WalletBonus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.WalletBonus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.WalletBonus}
 */
proto.qb.smg.WalletBonus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBonusId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBonusAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTurnoverAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTurnoverCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.WalletBonus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.WalletBonus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.WalletBonus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.WalletBonus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBonusAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTurnoverAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTurnoverCurrent();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional uint64 bonus_id = 1;
 * @return {number}
 */
proto.qb.smg.WalletBonus.prototype.getBonusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.WalletBonus} returns this
 */
proto.qb.smg.WalletBonus.prototype.setBonusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 bonus_amount = 2;
 * @return {number}
 */
proto.qb.smg.WalletBonus.prototype.getBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.WalletBonus} returns this
 */
proto.qb.smg.WalletBonus.prototype.setBonusAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 turnover_amount = 3;
 * @return {number}
 */
proto.qb.smg.WalletBonus.prototype.getTurnoverAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.WalletBonus} returns this
 */
proto.qb.smg.WalletBonus.prototype.setTurnoverAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 turnover_current = 4;
 * @return {number}
 */
proto.qb.smg.WalletBonus.prototype.getTurnoverCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.WalletBonus} returns this
 */
proto.qb.smg.WalletBonus.prototype.setTurnoverCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.BetResolutionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.BetResolutionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.BetResolutionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.BetResolutionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    betId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.BetResolutionRequest}
 */
proto.qb.smg.BetResolutionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.BetResolutionRequest;
  return proto.qb.smg.BetResolutionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.BetResolutionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.BetResolutionRequest}
 */
proto.qb.smg.BetResolutionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.BetResolutionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.BetResolutionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.BetResolutionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.BetResolutionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.BetResolutionRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.BetResolutionRequest} returns this
 */
proto.qb.smg.BetResolutionRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bet_id = 2;
 * @return {number}
 */
proto.qb.smg.BetResolutionRequest.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.BetResolutionRequest} returns this
 */
proto.qb.smg.BetResolutionRequest.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.BetResolutionRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.BetResolutionRequest} returns this
*/
proto.qb.smg.BetResolutionRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.BetResolutionRequest} returns this
 */
proto.qb.smg.BetResolutionRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.BetResolutionRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.BetResolutionRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.BetResolutionRequest} returns this
 */
proto.qb.smg.BetResolutionRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.BetResolutionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.BetResolutionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.BetResolutionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.BetResolutionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resolved: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.BetResolutionReply}
 */
proto.qb.smg.BetResolutionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.BetResolutionReply;
  return proto.qb.smg.BetResolutionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.BetResolutionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.BetResolutionReply}
 */
proto.qb.smg.BetResolutionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResolved(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.BetResolutionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.BetResolutionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.BetResolutionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.BetResolutionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResolved();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool resolved = 1;
 * @return {boolean}
 */
proto.qb.smg.BetResolutionReply.prototype.getResolved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.BetResolutionReply} returns this
 */
proto.qb.smg.BetResolutionReply.prototype.setResolved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.BetResolutionReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.BetResolutionReply} returns this
*/
proto.qb.smg.BetResolutionReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.BetResolutionReply} returns this
 */
proto.qb.smg.BetResolutionReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.BetResolutionReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    betState: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBetsRequest}
 */
proto.qb.smg.GetBetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBetsRequest;
  return proto.qb.smg.GetBetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBetsRequest}
 */
proto.qb.smg.GetBetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetState(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBetState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetBetsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetBetsRequest} returns this
 */
proto.qb.smg.GetBetsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bet_state = 2;
 * @return {string}
 */
proto.qb.smg.GetBetsRequest.prototype.getBetState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetBetsRequest} returns this
 */
proto.qb.smg.GetBetsRequest.prototype.setBetState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetBetsRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetBetsRequest} returns this
*/
proto.qb.smg.GetBetsRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetsRequest} returns this
 */
proto.qb.smg.GetBetsRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetsRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetBetsRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBetsRequest} returns this
 */
proto.qb.smg.GetBetsRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetBetsReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBetsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBetsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBetsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    betsList: jspb.Message.toObjectList(msg.getBetsList(),
    proto.qb.smg.Bet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBetsReply}
 */
proto.qb.smg.GetBetsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBetsReply;
  return proto.qb.smg.GetBetsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBetsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBetsReply}
 */
proto.qb.smg.GetBetsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.addBets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBetsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBetsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBetsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getBetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetBetsReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBetsReply} returns this
 */
proto.qb.smg.GetBetsReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetBetsReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetBetsReply} returns this
*/
proto.qb.smg.GetBetsReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetsReply} returns this
 */
proto.qb.smg.GetBetsReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetsReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Bet bets = 3;
 * @return {!Array<!proto.qb.smg.Bet>}
 */
proto.qb.smg.GetBetsReply.prototype.getBetsList = function() {
  return /** @type{!Array<!proto.qb.smg.Bet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Bet, 3));
};


/**
 * @param {!Array<!proto.qb.smg.Bet>} value
 * @return {!proto.qb.smg.GetBetsReply} returns this
*/
proto.qb.smg.GetBetsReply.prototype.setBetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.Bet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Bet}
 */
proto.qb.smg.GetBetsReply.prototype.addBets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.Bet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetBetsReply} returns this
 */
proto.qb.smg.GetBetsReply.prototype.clearBetsList = function() {
  return this.setBetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetUpcomingBetsForSimulatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetUpcomingBetsForSimulatorRequest;
  return proto.qb.smg.GetUpcomingBetsForSimulatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetUpcomingBetsForSimulatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} returns this
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 3;
 * @return {number}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} returns this
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} returns this
*/
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} returns this
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorRequest} returns this
 */
proto.qb.smg.GetUpcomingBetsForSimulatorRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetUpcomingBetsForSimulatorReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    betsList: jspb.Message.toObjectList(msg.getBetsList(),
    proto.qb.smg.Bet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorReply}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetUpcomingBetsForSimulatorReply;
  return proto.qb.smg.GetUpcomingBetsForSimulatorReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorReply}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.addBets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetUpcomingBetsForSimulatorReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getBetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} returns this
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} returns this
*/
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} returns this
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Bet bets = 3;
 * @return {!Array<!proto.qb.smg.Bet>}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.getBetsList = function() {
  return /** @type{!Array<!proto.qb.smg.Bet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Bet, 3));
};


/**
 * @param {!Array<!proto.qb.smg.Bet>} value
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} returns this
*/
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.setBetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.Bet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Bet}
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.addBets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.Bet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetUpcomingBetsForSimulatorReply} returns this
 */
proto.qb.smg.GetUpcomingBetsForSimulatorReply.prototype.clearBetsList = function() {
  return this.setBetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetPayoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetPayoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetPayoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPayoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    betId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetPayoutRequest}
 */
proto.qb.smg.GetPayoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetPayoutRequest;
  return proto.qb.smg.GetPayoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetPayoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetPayoutRequest}
 */
proto.qb.smg.GetPayoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetPayoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetPayoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetPayoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPayoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetPayoutRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetPayoutRequest} returns this
 */
proto.qb.smg.GetPayoutRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bet_id = 2;
 * @return {number}
 */
proto.qb.smg.GetPayoutRequest.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetPayoutRequest} returns this
 */
proto.qb.smg.GetPayoutRequest.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetPayoutRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetPayoutRequest} returns this
*/
proto.qb.smg.GetPayoutRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetPayoutRequest} returns this
 */
proto.qb.smg.GetPayoutRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetPayoutRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetPayoutRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetPayoutRequest} returns this
 */
proto.qb.smg.GetPayoutRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetPayoutReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetPayoutReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetPayoutReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetPayoutReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPayoutReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    payoutsList: jspb.Message.toObjectList(msg.getPayoutsList(),
    proto.qb.smg.Payout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetPayoutReply}
 */
proto.qb.smg.GetPayoutReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetPayoutReply;
  return proto.qb.smg.GetPayoutReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetPayoutReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetPayoutReply}
 */
proto.qb.smg.GetPayoutReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Payout;
      reader.readMessage(value,proto.qb.smg.Payout.deserializeBinaryFromReader);
      msg.addPayouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetPayoutReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetPayoutReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetPayoutReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPayoutReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getPayoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.Payout.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetPayoutReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetPayoutReply} returns this
 */
proto.qb.smg.GetPayoutReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetPayoutReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetPayoutReply} returns this
*/
proto.qb.smg.GetPayoutReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetPayoutReply} returns this
 */
proto.qb.smg.GetPayoutReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetPayoutReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Payout payouts = 3;
 * @return {!Array<!proto.qb.smg.Payout>}
 */
proto.qb.smg.GetPayoutReply.prototype.getPayoutsList = function() {
  return /** @type{!Array<!proto.qb.smg.Payout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Payout, 3));
};


/**
 * @param {!Array<!proto.qb.smg.Payout>} value
 * @return {!proto.qb.smg.GetPayoutReply} returns this
*/
proto.qb.smg.GetPayoutReply.prototype.setPayoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.Payout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Payout}
 */
proto.qb.smg.GetPayoutReply.prototype.addPayouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.Payout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetPayoutReply} returns this
 */
proto.qb.smg.GetPayoutReply.prototype.clearPayoutsList = function() {
  return this.setPayoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StreamBetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StreamBetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StreamBetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamBetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    closeAfterSend: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    lastSeenHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StreamBetsRequest}
 */
proto.qb.smg.StreamBetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StreamBetsRequest;
  return proto.qb.smg.StreamBetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StreamBetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StreamBetsRequest}
 */
proto.qb.smg.StreamBetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCloseAfterSend(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSeenHash(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StreamBetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StreamBetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StreamBetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamBetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCloseAfterSend();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLastSeenHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.StreamBetsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StreamBetsRequest} returns this
 */
proto.qb.smg.StreamBetsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool close_after_send = 2;
 * @return {boolean}
 */
proto.qb.smg.StreamBetsRequest.prototype.getCloseAfterSend = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StreamBetsRequest} returns this
 */
proto.qb.smg.StreamBetsRequest.prototype.setCloseAfterSend = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string last_seen_hash = 3;
 * @return {string}
 */
proto.qb.smg.StreamBetsRequest.prototype.getLastSeenHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StreamBetsRequest} returns this
 */
proto.qb.smg.StreamBetsRequest.prototype.setLastSeenHash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.StreamBetsRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.StreamBetsRequest} returns this
*/
proto.qb.smg.StreamBetsRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StreamBetsRequest} returns this
 */
proto.qb.smg.StreamBetsRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StreamBetsRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.StreamBetsRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StreamBetsRequest} returns this
 */
proto.qb.smg.StreamBetsRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.StreamBetsReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StreamBetsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StreamBetsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StreamBetsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamBetsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    betsList: jspb.Message.toObjectList(msg.getBetsList(),
    proto.qb.smg.Bet.toObject, includeInstance),
    hash: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StreamBetsReply}
 */
proto.qb.smg.StreamBetsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StreamBetsReply;
  return proto.qb.smg.StreamBetsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StreamBetsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StreamBetsReply}
 */
proto.qb.smg.StreamBetsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.addBets(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StreamBetsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StreamBetsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StreamBetsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamBetsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getBetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.StreamBetsReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StreamBetsReply} returns this
 */
proto.qb.smg.StreamBetsReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.StreamBetsReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.StreamBetsReply} returns this
*/
proto.qb.smg.StreamBetsReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StreamBetsReply} returns this
 */
proto.qb.smg.StreamBetsReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StreamBetsReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Bet bets = 3;
 * @return {!Array<!proto.qb.smg.Bet>}
 */
proto.qb.smg.StreamBetsReply.prototype.getBetsList = function() {
  return /** @type{!Array<!proto.qb.smg.Bet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Bet, 3));
};


/**
 * @param {!Array<!proto.qb.smg.Bet>} value
 * @return {!proto.qb.smg.StreamBetsReply} returns this
*/
proto.qb.smg.StreamBetsReply.prototype.setBetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.Bet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Bet}
 */
proto.qb.smg.StreamBetsReply.prototype.addBets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.Bet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.StreamBetsReply} returns this
 */
proto.qb.smg.StreamBetsReply.prototype.clearBetsList = function() {
  return this.setBetsList([]);
};


/**
 * optional string hash = 4;
 * @return {string}
 */
proto.qb.smg.StreamBetsReply.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StreamBetsReply} returns this
 */
proto.qb.smg.StreamBetsReply.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    betId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBetRequest}
 */
proto.qb.smg.GetBetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBetRequest;
  return proto.qb.smg.GetBetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBetRequest}
 */
proto.qb.smg.GetBetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.GetBetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetBetRequest} returns this
 */
proto.qb.smg.GetBetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bet_id = 2;
 * @return {number}
 */
proto.qb.smg.GetBetRequest.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBetRequest} returns this
 */
proto.qb.smg.GetBetRequest.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetBetRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetBetRequest} returns this
*/
proto.qb.smg.GetBetRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetRequest} returns this
 */
proto.qb.smg.GetBetRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetBetRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBetRequest} returns this
 */
proto.qb.smg.GetBetRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    bet: (f = msg.getBet()) && proto.qb.smg.Bet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBetReply}
 */
proto.qb.smg.GetBetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBetReply;
  return proto.qb.smg.GetBetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBetReply}
 */
proto.qb.smg.GetBetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.setBet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getBet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetBetReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBetReply} returns this
 */
proto.qb.smg.GetBetReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetBetReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetBetReply} returns this
*/
proto.qb.smg.GetBetReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetReply} returns this
 */
proto.qb.smg.GetBetReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Bet bet = 3;
 * @return {?proto.qb.smg.Bet}
 */
proto.qb.smg.GetBetReply.prototype.getBet = function() {
  return /** @type{?proto.qb.smg.Bet} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Bet, 3));
};


/**
 * @param {?proto.qb.smg.Bet|undefined} value
 * @return {!proto.qb.smg.GetBetReply} returns this
*/
proto.qb.smg.GetBetReply.prototype.setBet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetReply} returns this
 */
proto.qb.smg.GetBetReply.prototype.clearBet = function() {
  return this.setBet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetReply.prototype.hasBet = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBetNoTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBetNoTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetNoTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    betId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBetNoTokenRequest}
 */
proto.qb.smg.GetBetNoTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBetNoTokenRequest;
  return proto.qb.smg.GetBetNoTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBetNoTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBetNoTokenRequest}
 */
proto.qb.smg.GetBetNoTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBetNoTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBetNoTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetNoTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int64 bet_id = 1;
 * @return {number}
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBetNoTokenRequest} returns this
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetBetNoTokenRequest} returns this
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetBetNoTokenRequest} returns this
*/
proto.qb.smg.GetBetNoTokenRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetNoTokenRequest} returns this
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBetNoTokenRequest} returns this
 */
proto.qb.smg.GetBetNoTokenRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetBetNoTokenReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetBetNoTokenReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetNoTokenReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    bet: (f = msg.getBet()) && proto.qb.smg.Bet.toObject(includeInstance, f),
    istournamententrycomplete: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    playertournamentscore: jspb.Message.getFieldWithDefault(msg, 5, 0),
    istournamentbet: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    totalwonfreeplayamount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    onlyplayedtournamentbets: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isadditionalfreeplayamountadded: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetBetNoTokenReply}
 */
proto.qb.smg.GetBetNoTokenReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetBetNoTokenReply;
  return proto.qb.smg.GetBetNoTokenReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetBetNoTokenReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetBetNoTokenReply}
 */
proto.qb.smg.GetBetNoTokenReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.setBet(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIstournamententrycomplete(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayertournamentscore(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIstournamentbet(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalwonfreeplayamount(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyplayedtournamentbets(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsadditionalfreeplayamountadded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetBetNoTokenReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetBetNoTokenReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetBetNoTokenReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getBet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
  f = message.getIstournamententrycomplete();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPlayertournamentscore();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIstournamentbet();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTotalwonfreeplayamount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOnlyplayedtournamentbets();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsadditionalfreeplayamountadded();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
*/
proto.qb.smg.GetBetNoTokenReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Bet bet = 3;
 * @return {?proto.qb.smg.Bet}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getBet = function() {
  return /** @type{?proto.qb.smg.Bet} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Bet, 3));
};


/**
 * @param {?proto.qb.smg.Bet|undefined} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
*/
proto.qb.smg.GetBetNoTokenReply.prototype.setBet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.clearBet = function() {
  return this.setBet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.hasBet = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool isTournamentEntryComplete = 4;
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getIstournamententrycomplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.setIstournamententrycomplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 playerTournamentScore = 5;
 * @return {number}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getPlayertournamentscore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.setPlayertournamentscore = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool isTournamentBet = 6;
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getIstournamentbet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.setIstournamentbet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 totalWonFreePlayAmount = 7;
 * @return {number}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getTotalwonfreeplayamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.setTotalwonfreeplayamount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool onlyPlayedTournamentBets = 8;
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getOnlyplayedtournamentbets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.setOnlyplayedtournamentbets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool isAdditionalFreePlayAmountAdded = 9;
 * @return {boolean}
 */
proto.qb.smg.GetBetNoTokenReply.prototype.getIsadditionalfreeplayamountadded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetBetNoTokenReply} returns this
 */
proto.qb.smg.GetBetNoTokenReply.prototype.setIsadditionalfreeplayamountadded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AcceptBetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AcceptBetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AcceptBetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptBetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    betId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    betamount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    challengeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AcceptBetRequest}
 */
proto.qb.smg.AcceptBetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AcceptBetRequest;
  return proto.qb.smg.AcceptBetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AcceptBetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AcceptBetRequest}
 */
proto.qb.smg.AcceptBetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetamount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChallengeId(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AcceptBetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AcceptBetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AcceptBetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptBetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBetamount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChallengeId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.AcceptBetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.AcceptBetRequest} returns this
 */
proto.qb.smg.AcceptBetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bet_id = 2;
 * @return {number}
 */
proto.qb.smg.AcceptBetRequest.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AcceptBetRequest} returns this
 */
proto.qb.smg.AcceptBetRequest.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 betAmount = 3;
 * @return {number}
 */
proto.qb.smg.AcceptBetRequest.prototype.getBetamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AcceptBetRequest} returns this
 */
proto.qb.smg.AcceptBetRequest.prototype.setBetamount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 challenge_id = 4;
 * @return {number}
 */
proto.qb.smg.AcceptBetRequest.prototype.getChallengeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AcceptBetRequest} returns this
 */
proto.qb.smg.AcceptBetRequest.prototype.setChallengeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.AcceptBetRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.AcceptBetRequest} returns this
*/
proto.qb.smg.AcceptBetRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.AcceptBetRequest} returns this
 */
proto.qb.smg.AcceptBetRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.AcceptBetRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.AcceptBetRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AcceptBetRequest} returns this
 */
proto.qb.smg.AcceptBetRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AcceptBetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AcceptBetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AcceptBetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptBetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AcceptBetReply}
 */
proto.qb.smg.AcceptBetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AcceptBetReply;
  return proto.qb.smg.AcceptBetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AcceptBetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AcceptBetReply}
 */
proto.qb.smg.AcceptBetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AcceptBetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AcceptBetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AcceptBetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AcceptBetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.AcceptBetReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.AcceptBetReply} returns this
 */
proto.qb.smg.AcceptBetReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.AcceptBetReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.AcceptBetReply} returns this
*/
proto.qb.smg.AcceptBetReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.AcceptBetReply} returns this
 */
proto.qb.smg.AcceptBetReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.AcceptBetReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AdjustBetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AdjustBetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AdjustBetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AdjustBetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    betId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    betamount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AdjustBetRequest}
 */
proto.qb.smg.AdjustBetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AdjustBetRequest;
  return proto.qb.smg.AdjustBetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AdjustBetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AdjustBetRequest}
 */
proto.qb.smg.AdjustBetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetamount(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AdjustBetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AdjustBetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AdjustBetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AdjustBetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBetamount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.AdjustBetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.AdjustBetRequest} returns this
 */
proto.qb.smg.AdjustBetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bet_id = 2;
 * @return {number}
 */
proto.qb.smg.AdjustBetRequest.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AdjustBetRequest} returns this
 */
proto.qb.smg.AdjustBetRequest.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 betAmount = 3;
 * @return {number}
 */
proto.qb.smg.AdjustBetRequest.prototype.getBetamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AdjustBetRequest} returns this
 */
proto.qb.smg.AdjustBetRequest.prototype.setBetamount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.AdjustBetRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.AdjustBetRequest} returns this
*/
proto.qb.smg.AdjustBetRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.AdjustBetRequest} returns this
 */
proto.qb.smg.AdjustBetRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.AdjustBetRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.AdjustBetRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AdjustBetRequest} returns this
 */
proto.qb.smg.AdjustBetRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AdjustBetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AdjustBetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AdjustBetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AdjustBetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    betId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AdjustBetReply}
 */
proto.qb.smg.AdjustBetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AdjustBetReply;
  return proto.qb.smg.AdjustBetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AdjustBetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AdjustBetReply}
 */
proto.qb.smg.AdjustBetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 3:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AdjustBetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AdjustBetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AdjustBetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AdjustBetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.AdjustBetReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.AdjustBetReply} returns this
 */
proto.qb.smg.AdjustBetReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 bet_id = 2;
 * @return {number}
 */
proto.qb.smg.AdjustBetReply.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AdjustBetReply} returns this
 */
proto.qb.smg.AdjustBetReply.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Error error = 3;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.AdjustBetReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 3));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.AdjustBetReply} returns this
*/
proto.qb.smg.AdjustBetReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.AdjustBetReply} returns this
 */
proto.qb.smg.AdjustBetReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.AdjustBetReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Hit.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Hit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Hit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Hit.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 101, ""),
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    carryDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    headSpeedMph: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    ballSpeedMph: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    pathAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    faceOpenClosedAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    smashFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    launchAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    backSpin: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    sideSpin: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    distanceToTarget: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    apex: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    unixTimeStamp: jspb.Message.getFieldWithDefault(msg, 14, 0),
    stationId: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Hit}
 */
proto.qb.smg.Hit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Hit;
  return proto.qb.smg.Hit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Hit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Hit}
 */
proto.qb.smg.Hit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCarryDistance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalDistance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeadSpeedMph(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBallSpeedMph(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPathAngle(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFaceOpenClosedAngle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSmashFactor(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLaunchAngle(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackSpin(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSideSpin(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistanceToTarget(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setApex(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnixTimeStamp(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Hit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Hit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Hit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Hit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCarryDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHeadSpeedMph();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBallSpeedMph();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPathAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getFaceOpenClosedAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSmashFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLaunchAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getBackSpin();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getSideSpin();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getDistanceToTarget();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getApex();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getUnixTimeStamp();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getStationId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};


/**
 * optional string token = 101;
 * @return {string}
 */
proto.qb.smg.Hit.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qb.smg.Hit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double carry_distance = 2;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getCarryDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setCarryDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_distance = 3;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getTotalDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setTotalDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double head_speed_mph = 4;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getHeadSpeedMph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setHeadSpeedMph = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double ball_speed_mph = 5;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getBallSpeedMph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setBallSpeedMph = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double path_angle = 6;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getPathAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setPathAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double face_open_closed_angle = 7;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getFaceOpenClosedAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setFaceOpenClosedAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double smash_factor = 8;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getSmashFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setSmashFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double launch_angle = 9;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getLaunchAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setLaunchAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double back_spin = 10;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getBackSpin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setBackSpin = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double side_spin = 11;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getSideSpin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setSideSpin = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double distance_to_target = 12;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getDistanceToTarget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setDistanceToTarget = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double apex = 13;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getApex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setApex = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int64 unix_time_stamp = 14;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getUnixTimeStamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setUnixTimeStamp = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 station_id = 15;
 * @return {number}
 */
proto.qb.smg.Hit.prototype.getStationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Hit} returns this
 */
proto.qb.smg.Hit.prototype.setStationId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StartHitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StartHitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StartHitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartHitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    acceptedBetId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StartHitRequest}
 */
proto.qb.smg.StartHitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StartHitRequest;
  return proto.qb.smg.StartHitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StartHitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StartHitRequest}
 */
proto.qb.smg.StartHitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedBetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StartHitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StartHitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StartHitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartHitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAcceptedBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int64 station_id = 1;
 * @return {number}
 */
proto.qb.smg.StartHitRequest.prototype.getStationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StartHitRequest} returns this
 */
proto.qb.smg.StartHitRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 accepted_bet_id = 2;
 * @return {number}
 */
proto.qb.smg.StartHitRequest.prototype.getAcceptedBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StartHitRequest} returns this
 */
proto.qb.smg.StartHitRequest.prototype.setAcceptedBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.qb.smg.StartHitRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StartHitRequest} returns this
 */
proto.qb.smg.StartHitRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.StartHitRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.StartHitRequest} returns this
*/
proto.qb.smg.StartHitRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StartHitRequest} returns this
 */
proto.qb.smg.StartHitRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StartHitRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.StartHitRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StartHitRequest} returns this
 */
proto.qb.smg.StartHitRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StartHitReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StartHitReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StartHitReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartHitReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hitId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StartHitReply}
 */
proto.qb.smg.StartHitReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StartHitReply;
  return proto.qb.smg.StartHitReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StartHitReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StartHitReply}
 */
proto.qb.smg.StartHitReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StartHitReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StartHitReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StartHitReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StartHitReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHitId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.StartHitReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.StartHitReply} returns this
*/
proto.qb.smg.StartHitReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StartHitReply} returns this
 */
proto.qb.smg.StartHitReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StartHitReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.StartHitReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StartHitReply} returns this
 */
proto.qb.smg.StartHitReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 hit_id = 3;
 * @return {number}
 */
proto.qb.smg.StartHitReply.prototype.getHitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StartHitReply} returns this
 */
proto.qb.smg.StartHitReply.prototype.setHitId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.HitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.HitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.HitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hitId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hit: (f = msg.getHit()) && proto.qb.smg.Hit.toObject(includeInstance, f),
    holeInOne: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    token: jspb.Message.getFieldWithDefault(msg, 4, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.HitRequest}
 */
proto.qb.smg.HitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.HitRequest;
  return proto.qb.smg.HitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.HitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.HitRequest}
 */
proto.qb.smg.HitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitId(value);
      break;
    case 3:
      var value = new proto.qb.smg.Hit;
      reader.readMessage(value,proto.qb.smg.Hit.deserializeBinaryFromReader);
      msg.setHit(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoleInOne(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.HitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.HitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.HitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHitId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Hit.serializeBinaryToWriter
    );
  }
  f = message.getHoleInOne();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int64 hit_id = 2;
 * @return {number}
 */
proto.qb.smg.HitRequest.prototype.getHitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.HitRequest} returns this
 */
proto.qb.smg.HitRequest.prototype.setHitId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Hit hit = 3;
 * @return {?proto.qb.smg.Hit}
 */
proto.qb.smg.HitRequest.prototype.getHit = function() {
  return /** @type{?proto.qb.smg.Hit} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Hit, 3));
};


/**
 * @param {?proto.qb.smg.Hit|undefined} value
 * @return {!proto.qb.smg.HitRequest} returns this
*/
proto.qb.smg.HitRequest.prototype.setHit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.HitRequest} returns this
 */
proto.qb.smg.HitRequest.prototype.clearHit = function() {
  return this.setHit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.HitRequest.prototype.hasHit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool hole_in_one = 16;
 * @return {boolean}
 */
proto.qb.smg.HitRequest.prototype.getHoleInOne = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.HitRequest} returns this
 */
proto.qb.smg.HitRequest.prototype.setHoleInOne = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.qb.smg.HitRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.HitRequest} returns this
 */
proto.qb.smg.HitRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.HitRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.HitRequest} returns this
*/
proto.qb.smg.HitRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.HitRequest} returns this
 */
proto.qb.smg.HitRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.HitRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.HitRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.HitRequest} returns this
 */
proto.qb.smg.HitRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.HitReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.HitReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.HitReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HitReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.HitReply}
 */
proto.qb.smg.HitReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.HitReply;
  return proto.qb.smg.HitReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.HitReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.HitReply}
 */
proto.qb.smg.HitReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.HitReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.HitReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.HitReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HitReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.HitReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.HitReply} returns this
*/
proto.qb.smg.HitReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.HitReply} returns this
 */
proto.qb.smg.HitReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.HitReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.HitReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.HitReply} returns this
 */
proto.qb.smg.HitReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.HitStreamReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.HitStreamReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.HitStreamReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HitStreamReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    something: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.HitStreamReply}
 */
proto.qb.smg.HitStreamReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.HitStreamReply;
  return proto.qb.smg.HitStreamReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.HitStreamReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.HitStreamReply}
 */
proto.qb.smg.HitStreamReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSomething(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.HitStreamReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.HitStreamReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.HitStreamReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HitStreamReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSomething();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 something = 1;
 * @return {number}
 */
proto.qb.smg.HitStreamReply.prototype.getSomething = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.HitStreamReply} returns this
 */
proto.qb.smg.HitStreamReply.prototype.setSomething = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AddFaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AddFaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AddFaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AddFaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    faceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isRecognized: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isDetected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AddFaceRequest}
 */
proto.qb.smg.AddFaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AddFaceRequest;
  return proto.qb.smg.AddFaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AddFaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AddFaceRequest}
 */
proto.qb.smg.AddFaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFaceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecognized(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDetected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AddFaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AddFaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AddFaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AddFaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIsRecognized();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsDetected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 station_id = 1;
 * @return {number}
 */
proto.qb.smg.AddFaceRequest.prototype.getStationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AddFaceRequest} returns this
 */
proto.qb.smg.AddFaceRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 face_id = 2;
 * @return {number}
 */
proto.qb.smg.AddFaceRequest.prototype.getFaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.AddFaceRequest} returns this
 */
proto.qb.smg.AddFaceRequest.prototype.setFaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_recognized = 3;
 * @return {boolean}
 */
proto.qb.smg.AddFaceRequest.prototype.getIsRecognized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.AddFaceRequest} returns this
 */
proto.qb.smg.AddFaceRequest.prototype.setIsRecognized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_detected = 4;
 * @return {boolean}
 */
proto.qb.smg.AddFaceRequest.prototype.getIsDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.AddFaceRequest} returns this
 */
proto.qb.smg.AddFaceRequest.prototype.setIsDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.AddFaceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.AddFaceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.AddFaceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AddFaceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.AddFaceReply}
 */
proto.qb.smg.AddFaceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.AddFaceReply;
  return proto.qb.smg.AddFaceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.AddFaceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.AddFaceReply}
 */
proto.qb.smg.AddFaceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.AddFaceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.AddFaceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.AddFaceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.AddFaceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.AddFaceReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.AddFaceReply} returns this
*/
proto.qb.smg.AddFaceReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.AddFaceReply} returns this
 */
proto.qb.smg.AddFaceReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.AddFaceReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.AddFaceReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.AddFaceReply} returns this
 */
proto.qb.smg.AddFaceReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetGameStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetGameStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetGameStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetGameStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetGameStateRequest}
 */
proto.qb.smg.GetGameStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetGameStateRequest;
  return proto.qb.smg.GetGameStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetGameStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetGameStateRequest}
 */
proto.qb.smg.GetGameStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetGameStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetGameStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetGameStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetGameStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int64 station_id = 1;
 * @return {number}
 */
proto.qb.smg.GetGameStateRequest.prototype.getStationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateRequest} returns this
 */
proto.qb.smg.GetGameStateRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.qb.smg.GetGameStateRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetGameStateRequest} returns this
 */
proto.qb.smg.GetGameStateRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.GetGameStateRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.GetGameStateRequest} returns this
*/
proto.qb.smg.GetGameStateRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetGameStateRequest} returns this
 */
proto.qb.smg.GetGameStateRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetGameStateRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.GetGameStateRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateRequest} returns this
 */
proto.qb.smg.GetGameStateRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.HoleInOneConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.HoleInOneConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.HoleInOneConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HoleInOneConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusamount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.HoleInOneConfig}
 */
proto.qb.smg.HoleInOneConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.HoleInOneConfig;
  return proto.qb.smg.HoleInOneConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.HoleInOneConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.HoleInOneConfig}
 */
proto.qb.smg.HoleInOneConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBonusamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.HoleInOneConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.HoleInOneConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.HoleInOneConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HoleInOneConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusamount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 bonusAmount = 1;
 * @return {number}
 */
proto.qb.smg.HoleInOneConfig.prototype.getBonusamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.HoleInOneConfig} returns this
 */
proto.qb.smg.HoleInOneConfig.prototype.setBonusamount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetGameStateReply.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetGameStateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetGameStateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetGameStateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetGameStateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    userDetected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    faceDetected: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    multiFaceDetected: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    userInWrongCameraSide: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userBalance: jspb.Message.getFieldWithDefault(msg, 11, 0),
    username: jspb.Message.getFieldWithDefault(msg, 5, ""),
    activeBet: (f = msg.getActiveBet()) && proto.qb.smg.Bet.toObject(includeInstance, f),
    closedBet: (f = msg.getClosedBet()) && proto.qb.smg.Bet.toObject(includeInstance, f),
    availableBetsList: jspb.Message.toObjectList(msg.getAvailableBetsList(),
    proto.qb.smg.Bet.toObject, includeInstance),
    userSkills: (f = msg.getUserSkills()) && proto.qb.smg.Skills.toObject(includeInstance, f),
    playerhandedness: jspb.Message.getFieldWithDefault(msg, 14, ""),
    showMultiPlayerError: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    showPlayerNotInGamePlayAreaError: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    shotSequence: jspb.Message.getFieldWithDefault(msg, 17, 0),
    autoAcceptBets: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    hasactivetournament: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    maxshotamount: jspb.Message.getFieldWithDefault(msg, 25, 0),
    showWallet: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    holeInOneBonusAmount: jspb.Message.getFieldWithDefault(msg, 27, 0),
    istournamentbetactivated: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    playedtournamentshotcount: jspb.Message.getFieldWithDefault(msg, 29, 0),
    totaltournamentshotcount: jspb.Message.getFieldWithDefault(msg, 30, 0),
    isnewplayer: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    freeshotcount: jspb.Message.getFieldWithDefault(msg, 32, 0),
    freeshotnumber: jspb.Message.getFieldWithDefault(msg, 33, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetGameStateReply}
 */
proto.qb.smg.GetGameStateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetGameStateReply;
  return proto.qb.smg.GetGameStateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetGameStateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetGameStateReply}
 */
proto.qb.smg.GetGameStateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserDetected(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaceDetected(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiFaceDetected(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserInWrongCameraSide(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 6:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.setActiveBet(value);
      break;
    case 7:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.setClosedBet(value);
      break;
    case 8:
      var value = new proto.qb.smg.Bet;
      reader.readMessage(value,proto.qb.smg.Bet.deserializeBinaryFromReader);
      msg.addAvailableBets(value);
      break;
    case 9:
      var value = new proto.qb.smg.Skills;
      reader.readMessage(value,proto.qb.smg.Skills.deserializeBinaryFromReader);
      msg.setUserSkills(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerhandedness(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowMultiPlayerError(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowPlayerNotInGamePlayAreaError(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShotSequence(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoAcceptBets(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasactivetournament(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxshotamount(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowWallet(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHoleInOneBonusAmount(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIstournamentbetactivated(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayedtournamentshotcount(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotaltournamentshotcount(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnewplayer(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreeshotcount(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreeshotnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetGameStateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetGameStateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetGameStateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetGameStateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUserDetected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFaceDetected();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getMultiFaceDetected();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getUserInWrongCameraSide();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUserBalance();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActiveBet();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
  f = message.getClosedBet();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
  f = message.getAvailableBetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.qb.smg.Bet.serializeBinaryToWriter
    );
  }
  f = message.getUserSkills();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.qb.smg.Skills.serializeBinaryToWriter
    );
  }
  f = message.getPlayerhandedness();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getShowMultiPlayerError();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getShowPlayerNotInGamePlayAreaError();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getShotSequence();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getAutoAcceptBets();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getHasactivetournament();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getMaxshotamount();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getShowWallet();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getHoleInOneBonusAmount();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getIstournamentbetactivated();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getPlayedtournamentshotcount();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
  f = message.getTotaltournamentshotcount();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getIsnewplayer();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getFreeshotcount();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getFreeshotnumber();
  if (f !== 0) {
    writer.writeInt32(
      33,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetGameStateReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
*/
proto.qb.smg.GetGameStateReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool user_detected = 3;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getUserDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setUserDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool face_detected = 10;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getFaceDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setFaceDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool multi_face_detected = 12;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getMultiFaceDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setMultiFaceDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool user_in_wrong_camera_side = 13;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getUserInWrongCameraSide = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setUserInWrongCameraSide = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 user_id = 4;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 user_balance = 11;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getUserBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setUserBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string username = 5;
 * @return {string}
 */
proto.qb.smg.GetGameStateReply.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Bet active_bet = 6;
 * @return {?proto.qb.smg.Bet}
 */
proto.qb.smg.GetGameStateReply.prototype.getActiveBet = function() {
  return /** @type{?proto.qb.smg.Bet} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Bet, 6));
};


/**
 * @param {?proto.qb.smg.Bet|undefined} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
*/
proto.qb.smg.GetGameStateReply.prototype.setActiveBet = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.clearActiveBet = function() {
  return this.setActiveBet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.hasActiveBet = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Bet closed_bet = 7;
 * @return {?proto.qb.smg.Bet}
 */
proto.qb.smg.GetGameStateReply.prototype.getClosedBet = function() {
  return /** @type{?proto.qb.smg.Bet} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Bet, 7));
};


/**
 * @param {?proto.qb.smg.Bet|undefined} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
*/
proto.qb.smg.GetGameStateReply.prototype.setClosedBet = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.clearClosedBet = function() {
  return this.setClosedBet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.hasClosedBet = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Bet available_bets = 8;
 * @return {!Array<!proto.qb.smg.Bet>}
 */
proto.qb.smg.GetGameStateReply.prototype.getAvailableBetsList = function() {
  return /** @type{!Array<!proto.qb.smg.Bet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Bet, 8));
};


/**
 * @param {!Array<!proto.qb.smg.Bet>} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
*/
proto.qb.smg.GetGameStateReply.prototype.setAvailableBetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.qb.smg.Bet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Bet}
 */
proto.qb.smg.GetGameStateReply.prototype.addAvailableBets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.qb.smg.Bet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.clearAvailableBetsList = function() {
  return this.setAvailableBetsList([]);
};


/**
 * optional Skills user_skills = 9;
 * @return {?proto.qb.smg.Skills}
 */
proto.qb.smg.GetGameStateReply.prototype.getUserSkills = function() {
  return /** @type{?proto.qb.smg.Skills} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Skills, 9));
};


/**
 * @param {?proto.qb.smg.Skills|undefined} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
*/
proto.qb.smg.GetGameStateReply.prototype.setUserSkills = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.clearUserSkills = function() {
  return this.setUserSkills(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.hasUserSkills = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string PlayerHandedness = 14;
 * @return {string}
 */
proto.qb.smg.GetGameStateReply.prototype.getPlayerhandedness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setPlayerhandedness = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool show_multi_player_error = 15;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getShowMultiPlayerError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setShowMultiPlayerError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool show_player_not_in_game_play_area_error = 16;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getShowPlayerNotInGamePlayAreaError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setShowPlayerNotInGamePlayAreaError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int32 shot_sequence = 17;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getShotSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setShotSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool auto_accept_bets = 18;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getAutoAcceptBets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setAutoAcceptBets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool hasActiveTournament = 19;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getHasactivetournament = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setHasactivetournament = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional int32 maxShotAmount = 25;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getMaxshotamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setMaxshotamount = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool show_wallet = 26;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getShowWallet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setShowWallet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional int32 hole_in_one_bonus_amount = 27;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getHoleInOneBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setHoleInOneBonusAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional bool isTournamentBetActivated = 28;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getIstournamentbetactivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setIstournamentbetactivated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional int32 playedTournamentShotCount = 29;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getPlayedtournamentshotcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setPlayedtournamentshotcount = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional int32 totalTournamentShotCount = 30;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getTotaltournamentshotcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setTotaltournamentshotcount = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional bool isNewPlayer = 31;
 * @return {boolean}
 */
proto.qb.smg.GetGameStateReply.prototype.getIsnewplayer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setIsnewplayer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional int32 freeShotCount = 32;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getFreeshotcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setFreeshotcount = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int32 freeShotNumber = 33;
 * @return {number}
 */
proto.qb.smg.GetGameStateReply.prototype.getFreeshotnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetGameStateReply} returns this
 */
proto.qb.smg.GetGameStateReply.prototype.setFreeshotnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StreamStationStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StreamStationStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StreamStationStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamStationStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StreamStationStateRequest}
 */
proto.qb.smg.StreamStationStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StreamStationStateRequest;
  return proto.qb.smg.StreamStationStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StreamStationStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StreamStationStateRequest}
 */
proto.qb.smg.StreamStationStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StreamStationStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StreamStationStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StreamStationStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StreamStationStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int64 station_id = 1;
 * @return {number}
 */
proto.qb.smg.StreamStationStateRequest.prototype.getStationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StreamStationStateRequest} returns this
 */
proto.qb.smg.StreamStationStateRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.qb.smg.StreamStationStateRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.StreamStationStateRequest} returns this
 */
proto.qb.smg.StreamStationStateRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.StreamStationStateRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.StreamStationStateRequest} returns this
*/
proto.qb.smg.StreamStationStateRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StreamStationStateRequest} returns this
 */
proto.qb.smg.StreamStationStateRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StreamStationStateRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.StreamStationStateRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StreamStationStateRequest} returns this
 */
proto.qb.smg.StreamStationStateRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.FrsHeartbeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.FrsHeartbeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.FrsHeartbeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.FrsHeartbeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.FrsHeartbeatRequest}
 */
proto.qb.smg.FrsHeartbeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.FrsHeartbeatRequest;
  return proto.qb.smg.FrsHeartbeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.FrsHeartbeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.FrsHeartbeatRequest}
 */
proto.qb.smg.FrsHeartbeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.FrsHeartbeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.FrsHeartbeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.FrsHeartbeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.FrsHeartbeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StationState.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StationState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StationState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationState.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeuserid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activebetid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userDetected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    faceDetected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    multiFaceDetected: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    currentstate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    userInWrongCameraSide: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    showMultiplayerError: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    showPlayerNotInGamePlayAreaError: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    holeInOneDetected: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StationState}
 */
proto.qb.smg.StationState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StationState;
  return proto.qb.smg.StationState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StationState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StationState}
 */
proto.qb.smg.StationState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActiveuserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivebetid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserDetected(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaceDetected(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiFaceDetected(value);
      break;
    case 5:
      var value = /** @type {!proto.qb.smg.StationPlayerState} */ (reader.readEnum());
      msg.setCurrentstate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserInWrongCameraSide(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowMultiplayerError(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowPlayerNotInGamePlayAreaError(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoleInOneDetected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StationState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StationState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StationState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveuserid();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getActivebetid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getUserDetected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFaceDetected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMultiFaceDetected();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCurrentstate();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getUserInWrongCameraSide();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getShowMultiplayerError();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getShowPlayerNotInGamePlayAreaError();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHoleInOneDetected();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional uint64 activeUserId = 1;
 * @return {number}
 */
proto.qb.smg.StationState.prototype.getActiveuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setActiveuserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 activeBetId = 2;
 * @return {number}
 */
proto.qb.smg.StationState.prototype.getActivebetid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setActivebetid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool user_detected = 3;
 * @return {boolean}
 */
proto.qb.smg.StationState.prototype.getUserDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setUserDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool face_detected = 4;
 * @return {boolean}
 */
proto.qb.smg.StationState.prototype.getFaceDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setFaceDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool multi_face_detected = 6;
 * @return {boolean}
 */
proto.qb.smg.StationState.prototype.getMultiFaceDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setMultiFaceDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional StationPlayerState currentState = 5;
 * @return {!proto.qb.smg.StationPlayerState}
 */
proto.qb.smg.StationState.prototype.getCurrentstate = function() {
  return /** @type {!proto.qb.smg.StationPlayerState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qb.smg.StationPlayerState} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setCurrentstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool user_in_wrong_camera_side = 7;
 * @return {boolean}
 */
proto.qb.smg.StationState.prototype.getUserInWrongCameraSide = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setUserInWrongCameraSide = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool show_multiplayer_error = 8;
 * @return {boolean}
 */
proto.qb.smg.StationState.prototype.getShowMultiplayerError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setShowMultiplayerError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool show_player_not_in_game_play_area_error = 9;
 * @return {boolean}
 */
proto.qb.smg.StationState.prototype.getShowPlayerNotInGamePlayAreaError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setShowPlayerNotInGamePlayAreaError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool hole_in_one_detected = 10;
 * @return {boolean}
 */
proto.qb.smg.StationState.prototype.getHoleInOneDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.StationState} returns this
 */
proto.qb.smg.StationState.prototype.setHoleInOneDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.HeartbeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.HeartbeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.HeartbeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HeartbeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.HeartbeatRequest}
 */
proto.qb.smg.HeartbeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.HeartbeatRequest;
  return proto.qb.smg.HeartbeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.HeartbeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.HeartbeatRequest}
 */
proto.qb.smg.HeartbeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.HeartbeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.HeartbeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.HeartbeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HeartbeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.HeartbeatRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.HeartbeatRequest} returns this
 */
proto.qb.smg.HeartbeatRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.HeartbeatRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.HeartbeatRequest} returns this
*/
proto.qb.smg.HeartbeatRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.HeartbeatRequest} returns this
 */
proto.qb.smg.HeartbeatRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.HeartbeatRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.HeartbeatRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.HeartbeatRequest} returns this
 */
proto.qb.smg.HeartbeatRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.HeartbeatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.HeartbeatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.HeartbeatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HeartbeatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.HeartbeatResponse}
 */
proto.qb.smg.HeartbeatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.HeartbeatResponse;
  return proto.qb.smg.HeartbeatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.HeartbeatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.HeartbeatResponse}
 */
proto.qb.smg.HeartbeatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.HeartbeatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.HeartbeatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.HeartbeatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.HeartbeatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ConnectStationSimpleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ConnectStationSimpleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConnectStationSimpleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ConnectStationSimpleRequest}
 */
proto.qb.smg.ConnectStationSimpleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ConnectStationSimpleRequest;
  return proto.qb.smg.ConnectStationSimpleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ConnectStationSimpleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ConnectStationSimpleRequest}
 */
proto.qb.smg.ConnectStationSimpleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ConnectStationSimpleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ConnectStationSimpleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConnectStationSimpleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional int32 station_id = 1;
 * @return {number}
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.getStationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ConnectStationSimpleRequest} returns this
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ConnectStationSimpleRequest} returns this
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.ConnectStationSimpleRequest} returns this
*/
proto.qb.smg.ConnectStationSimpleRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ConnectStationSimpleRequest} returns this
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ConnectStationSimpleRequest} returns this
 */
proto.qb.smg.ConnectStationSimpleRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ConnectStationSimpleReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ConnectStationSimpleReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ConnectStationSimpleReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConnectStationSimpleReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ConnectStationSimpleReply}
 */
proto.qb.smg.ConnectStationSimpleReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ConnectStationSimpleReply;
  return proto.qb.smg.ConnectStationSimpleReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ConnectStationSimpleReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ConnectStationSimpleReply}
 */
proto.qb.smg.ConnectStationSimpleReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ConnectStationSimpleReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ConnectStationSimpleReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ConnectStationSimpleReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ConnectStationSimpleReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool connected = 1;
 * @return {boolean}
 */
proto.qb.smg.ConnectStationSimpleReply.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ConnectStationSimpleReply} returns this
 */
proto.qb.smg.ConnectStationSimpleReply.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.StationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.StationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.StationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settingsUpdate: (f = msg.getSettingsUpdate()) && proto.qb.smg.SettingsUpdate.toObject(includeInstance, f),
    displayMessage: (f = msg.getDisplayMessage()) && proto.qb.smg.DisplayMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.StationMessage}
 */
proto.qb.smg.StationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.StationMessage;
  return proto.qb.smg.StationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.StationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.StationMessage}
 */
proto.qb.smg.StationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 2:
      var value = new proto.qb.smg.SettingsUpdate;
      reader.readMessage(value,proto.qb.smg.SettingsUpdate.deserializeBinaryFromReader);
      msg.setSettingsUpdate(value);
      break;
    case 3:
      var value = new proto.qb.smg.DisplayMessage;
      reader.readMessage(value,proto.qb.smg.DisplayMessage.deserializeBinaryFromReader);
      msg.setDisplayMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.StationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.StationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.StationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.StationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSettingsUpdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.SettingsUpdate.serializeBinaryToWriter
    );
  }
  f = message.getDisplayMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.DisplayMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 message_id = 1;
 * @return {number}
 */
proto.qb.smg.StationMessage.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.StationMessage} returns this
 */
proto.qb.smg.StationMessage.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SettingsUpdate settings_update = 2;
 * @return {?proto.qb.smg.SettingsUpdate}
 */
proto.qb.smg.StationMessage.prototype.getSettingsUpdate = function() {
  return /** @type{?proto.qb.smg.SettingsUpdate} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.SettingsUpdate, 2));
};


/**
 * @param {?proto.qb.smg.SettingsUpdate|undefined} value
 * @return {!proto.qb.smg.StationMessage} returns this
*/
proto.qb.smg.StationMessage.prototype.setSettingsUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StationMessage} returns this
 */
proto.qb.smg.StationMessage.prototype.clearSettingsUpdate = function() {
  return this.setSettingsUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StationMessage.prototype.hasSettingsUpdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DisplayMessage display_message = 3;
 * @return {?proto.qb.smg.DisplayMessage}
 */
proto.qb.smg.StationMessage.prototype.getDisplayMessage = function() {
  return /** @type{?proto.qb.smg.DisplayMessage} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.DisplayMessage, 3));
};


/**
 * @param {?proto.qb.smg.DisplayMessage|undefined} value
 * @return {!proto.qb.smg.StationMessage} returns this
*/
proto.qb.smg.StationMessage.prototype.setDisplayMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.StationMessage} returns this
 */
proto.qb.smg.StationMessage.prototype.clearDisplayMessage = function() {
  return this.setDisplayMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.StationMessage.prototype.hasDisplayMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DisplayMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DisplayMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DisplayMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DisplayMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    line1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    line3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DisplayMessage}
 */
proto.qb.smg.DisplayMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DisplayMessage;
  return proto.qb.smg.DisplayMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DisplayMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DisplayMessage}
 */
proto.qb.smg.DisplayMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine3(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDisplayTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DisplayMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DisplayMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DisplayMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DisplayMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLine2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLine3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string line1 = 1;
 * @return {string}
 */
proto.qb.smg.DisplayMessage.prototype.getLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DisplayMessage} returns this
 */
proto.qb.smg.DisplayMessage.prototype.setLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line2 = 2;
 * @return {string}
 */
proto.qb.smg.DisplayMessage.prototype.getLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DisplayMessage} returns this
 */
proto.qb.smg.DisplayMessage.prototype.setLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string line3 = 3;
 * @return {string}
 */
proto.qb.smg.DisplayMessage.prototype.getLine3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DisplayMessage} returns this
 */
proto.qb.smg.DisplayMessage.prototype.setLine3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 display_time = 4;
 * @return {number}
 */
proto.qb.smg.DisplayMessage.prototype.getDisplayTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.DisplayMessage} returns this
 */
proto.qb.smg.DisplayMessage.prototype.setDisplayTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SettingsUpdateStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SettingsUpdateStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SettingsUpdateStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SettingsUpdateStreamRequest}
 */
proto.qb.smg.SettingsUpdateStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SettingsUpdateStreamRequest;
  return proto.qb.smg.SettingsUpdateStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SettingsUpdateStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SettingsUpdateStreamRequest}
 */
proto.qb.smg.SettingsUpdateStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SettingsUpdateStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SettingsUpdateStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SettingsUpdateStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SettingsUpdateStreamRequest} returns this
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.SettingsUpdateStreamRequest} returns this
*/
proto.qb.smg.SettingsUpdateStreamRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SettingsUpdateStreamRequest} returns this
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SettingsUpdateStreamRequest} returns this
 */
proto.qb.smg.SettingsUpdateStreamRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SettingsUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SettingsUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SettingsUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SettingsUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastchangetimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    handedness: jspb.Message.getFieldWithDefault(msg, 2, ""),
    distance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targettype: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SettingsUpdate}
 */
proto.qb.smg.SettingsUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SettingsUpdate;
  return proto.qb.smg.SettingsUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SettingsUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SettingsUpdate}
 */
proto.qb.smg.SettingsUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastchangetimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandedness(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistance(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargettype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SettingsUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SettingsUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SettingsUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SettingsUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastchangetimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHandedness();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTargettype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 LastChangeTimestamp = 1;
 * @return {number}
 */
proto.qb.smg.SettingsUpdate.prototype.getLastchangetimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SettingsUpdate} returns this
 */
proto.qb.smg.SettingsUpdate.prototype.setLastchangetimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Handedness = 2;
 * @return {string}
 */
proto.qb.smg.SettingsUpdate.prototype.getHandedness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SettingsUpdate} returns this
 */
proto.qb.smg.SettingsUpdate.prototype.setHandedness = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 Distance = 3;
 * @return {number}
 */
proto.qb.smg.SettingsUpdate.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SettingsUpdate} returns this
 */
proto.qb.smg.SettingsUpdate.prototype.setDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string TargetType = 4;
 * @return {string}
 */
proto.qb.smg.SettingsUpdate.prototype.getTargettype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.SettingsUpdate} returns this
 */
proto.qb.smg.SettingsUpdate.prototype.setTargettype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeleteUserImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeleteUserImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeleteUserImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    venueId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    token: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeleteUserImageRequest}
 */
proto.qb.smg.DeleteUserImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeleteUserImageRequest;
  return proto.qb.smg.DeleteUserImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeleteUserImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeleteUserImageRequest}
 */
proto.qb.smg.DeleteUserImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeleteUserImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeleteUserImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeleteUserImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.qb.smg.DeleteUserImageRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.DeleteUserImageRequest} returns this
 */
proto.qb.smg.DeleteUserImageRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 venue_id = 2;
 * @return {number}
 */
proto.qb.smg.DeleteUserImageRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.DeleteUserImageRequest} returns this
 */
proto.qb.smg.DeleteUserImageRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.qb.smg.DeleteUserImageRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DeleteUserImageRequest} returns this
 */
proto.qb.smg.DeleteUserImageRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeleteUserImageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeleteUserImageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeleteUserImageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeleteUserImageResponse}
 */
proto.qb.smg.DeleteUserImageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeleteUserImageResponse;
  return proto.qb.smg.DeleteUserImageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeleteUserImageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeleteUserImageResponse}
 */
proto.qb.smg.DeleteUserImageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeleteUserImageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeleteUserImageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeleteUserImageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.DeleteUserImageResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.DeleteUserImageResponse} returns this
*/
proto.qb.smg.DeleteUserImageResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DeleteUserImageResponse} returns this
 */
proto.qb.smg.DeleteUserImageResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DeleteUserImageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.DeleteUserImageResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.DeleteUserImageResponse} returns this
 */
proto.qb.smg.DeleteUserImageResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeleteUserImagesBeforeDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeleteUserImagesBeforeDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    token: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateRequest}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeleteUserImagesBeforeDateRequest;
  return proto.qb.smg.DeleteUserImagesBeforeDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeleteUserImagesBeforeDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateRequest}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeleteUserImagesBeforeDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeleteUserImagesBeforeDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp Date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateRequest} returns this
*/
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateRequest} returns this
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 venue_id = 2;
 * @return {number}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateRequest} returns this
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateRequest} returns this
 */
proto.qb.smg.DeleteUserImagesBeforeDateRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeleteUserImagesBeforeDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeleteUserImagesBeforeDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateResponse}
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeleteUserImagesBeforeDateResponse;
  return proto.qb.smg.DeleteUserImagesBeforeDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeleteUserImagesBeforeDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateResponse}
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeleteUserImagesBeforeDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeleteUserImagesBeforeDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateResponse} returns this
*/
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateResponse} returns this
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.DeleteUserImagesBeforeDateResponse} returns this
 */
proto.qb.smg.DeleteUserImagesBeforeDateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeleteLastActiveBeforeDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeleteLastActiveBeforeDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateRequest}
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeleteLastActiveBeforeDateRequest;
  return proto.qb.smg.DeleteLastActiveBeforeDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeleteLastActiveBeforeDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateRequest}
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeleteLastActiveBeforeDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeleteLastActiveBeforeDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateRequest} returns this
*/
proto.qb.smg.DeleteLastActiveBeforeDateRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateRequest} returns this
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DeleteLastActiveBeforeDateRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.DeleteLastActiveBeforeDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.DeleteLastActiveBeforeDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateResponse}
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.DeleteLastActiveBeforeDateResponse;
  return proto.qb.smg.DeleteLastActiveBeforeDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.DeleteLastActiveBeforeDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateResponse}
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.DeleteLastActiveBeforeDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.DeleteLastActiveBeforeDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateResponse} returns this
*/
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateResponse} returns this
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.DeleteLastActiveBeforeDateResponse} returns this
 */
proto.qb.smg.DeleteLastActiveBeforeDateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.IgnoreFrsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.IgnoreFrsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.IgnoreFrsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.IgnoreFrsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    forcedUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stationId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.IgnoreFrsRequest}
 */
proto.qb.smg.IgnoreFrsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.IgnoreFrsRequest;
  return proto.qb.smg.IgnoreFrsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.IgnoreFrsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.IgnoreFrsRequest}
 */
proto.qb.smg.IgnoreFrsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setForcedUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.IgnoreFrsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.IgnoreFrsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.IgnoreFrsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.IgnoreFrsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getForcedUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.qb.smg.IgnoreFrsRequest.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.IgnoreFrsRequest} returns this
 */
proto.qb.smg.IgnoreFrsRequest.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 forced_user_id = 2;
 * @return {number}
 */
proto.qb.smg.IgnoreFrsRequest.prototype.getForcedUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.IgnoreFrsRequest} returns this
 */
proto.qb.smg.IgnoreFrsRequest.prototype.setForcedUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.qb.smg.IgnoreFrsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.IgnoreFrsRequest} returns this
 */
proto.qb.smg.IgnoreFrsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 station_id = 4;
 * @return {number}
 */
proto.qb.smg.IgnoreFrsRequest.prototype.getStationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.IgnoreFrsRequest} returns this
 */
proto.qb.smg.IgnoreFrsRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.IgnoreFrsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.IgnoreFrsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.IgnoreFrsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.IgnoreFrsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.IgnoreFrsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    availableusersList: jspb.Message.toObjectList(msg.getAvailableusersList(),
    proto.qb.smg.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.IgnoreFrsResponse}
 */
proto.qb.smg.IgnoreFrsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.IgnoreFrsResponse;
  return proto.qb.smg.IgnoreFrsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.IgnoreFrsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.IgnoreFrsResponse}
 */
proto.qb.smg.IgnoreFrsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = new proto.qb.smg.User;
      reader.readMessage(value,proto.qb.smg.User.deserializeBinaryFromReader);
      msg.addAvailableusers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.IgnoreFrsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.IgnoreFrsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.IgnoreFrsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.IgnoreFrsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAvailableusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.IgnoreFrsResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.IgnoreFrsResponse} returns this
*/
proto.qb.smg.IgnoreFrsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.IgnoreFrsResponse} returns this
 */
proto.qb.smg.IgnoreFrsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.IgnoreFrsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.IgnoreFrsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.IgnoreFrsResponse} returns this
 */
proto.qb.smg.IgnoreFrsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated User availableUsers = 3;
 * @return {!Array<!proto.qb.smg.User>}
 */
proto.qb.smg.IgnoreFrsResponse.prototype.getAvailableusersList = function() {
  return /** @type{!Array<!proto.qb.smg.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.User, 3));
};


/**
 * @param {!Array<!proto.qb.smg.User>} value
 * @return {!proto.qb.smg.IgnoreFrsResponse} returns this
*/
proto.qb.smg.IgnoreFrsResponse.prototype.setAvailableusersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.User}
 */
proto.qb.smg.IgnoreFrsResponse.prototype.addAvailableusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.IgnoreFrsResponse} returns this
 */
proto.qb.smg.IgnoreFrsResponse.prototype.clearAvailableusersList = function() {
  return this.setAvailableusersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResetIgnoreFrsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResetIgnoreFrsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResetIgnoreFrsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetIgnoreFrsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResetIgnoreFrsRequest}
 */
proto.qb.smg.ResetIgnoreFrsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResetIgnoreFrsRequest;
  return proto.qb.smg.ResetIgnoreFrsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResetIgnoreFrsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResetIgnoreFrsRequest}
 */
proto.qb.smg.ResetIgnoreFrsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResetIgnoreFrsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResetIgnoreFrsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResetIgnoreFrsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetIgnoreFrsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.ResetIgnoreFrsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ResetIgnoreFrsRequest} returns this
 */
proto.qb.smg.ResetIgnoreFrsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResetIgnoreFrsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResetIgnoreFrsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResetIgnoreFrsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetIgnoreFrsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResetIgnoreFrsResponse}
 */
proto.qb.smg.ResetIgnoreFrsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResetIgnoreFrsResponse;
  return proto.qb.smg.ResetIgnoreFrsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResetIgnoreFrsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResetIgnoreFrsResponse}
 */
proto.qb.smg.ResetIgnoreFrsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResetIgnoreFrsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResetIgnoreFrsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResetIgnoreFrsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetIgnoreFrsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ResetIgnoreFrsResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ResetIgnoreFrsResponse} returns this
*/
proto.qb.smg.ResetIgnoreFrsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ResetIgnoreFrsResponse} returns this
 */
proto.qb.smg.ResetIgnoreFrsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ResetIgnoreFrsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.ResetIgnoreFrsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ResetIgnoreFrsResponse} returns this
 */
proto.qb.smg.ResetIgnoreFrsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResetShotCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResetShotCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResetShotCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetShotCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResetShotCountRequest}
 */
proto.qb.smg.ResetShotCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResetShotCountRequest;
  return proto.qb.smg.ResetShotCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResetShotCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResetShotCountRequest}
 */
proto.qb.smg.ResetShotCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResetShotCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResetShotCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResetShotCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetShotCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.ResetShotCountRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ResetShotCountRequest} returns this
 */
proto.qb.smg.ResetShotCountRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.qb.smg.ResetShotCountRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ResetShotCountRequest} returns this
 */
proto.qb.smg.ResetShotCountRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResetShotCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResetShotCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResetShotCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetShotCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResetShotCountResponse}
 */
proto.qb.smg.ResetShotCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResetShotCountResponse;
  return proto.qb.smg.ResetShotCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResetShotCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResetShotCountResponse}
 */
proto.qb.smg.ResetShotCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResetShotCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResetShotCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResetShotCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetShotCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ResetShotCountResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ResetShotCountResponse} returns this
*/
proto.qb.smg.ResetShotCountResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ResetShotCountResponse} returns this
 */
proto.qb.smg.ResetShotCountResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ResetShotCountResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.ResetShotCountResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ResetShotCountResponse} returns this
 */
proto.qb.smg.ResetShotCountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetTransactionHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetTransactionHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetTransactionHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTransactionHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetTransactionHistoryRequest}
 */
proto.qb.smg.GetTransactionHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetTransactionHistoryRequest;
  return proto.qb.smg.GetTransactionHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetTransactionHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetTransactionHistoryRequest}
 */
proto.qb.smg.GetTransactionHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetTransactionHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetTransactionHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetTransactionHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTransactionHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 user_id = 1;
 * @return {number}
 */
proto.qb.smg.GetTransactionHistoryRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetTransactionHistoryRequest} returns this
 */
proto.qb.smg.GetTransactionHistoryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetTransactionHistoryReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetTransactionHistoryReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetTransactionHistoryReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTransactionHistoryReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    proto.qb.smg.TransactionHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetTransactionHistoryReply}
 */
proto.qb.smg.GetTransactionHistoryReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetTransactionHistoryReply;
  return proto.qb.smg.GetTransactionHistoryReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetTransactionHistoryReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetTransactionHistoryReply}
 */
proto.qb.smg.GetTransactionHistoryReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = new proto.qb.smg.TransactionHistory;
      reader.readMessage(value,proto.qb.smg.TransactionHistory.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetTransactionHistoryReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetTransactionHistoryReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetTransactionHistoryReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.TransactionHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetTransactionHistoryReply} returns this
*/
proto.qb.smg.GetTransactionHistoryReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetTransactionHistoryReply} returns this
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetTransactionHistoryReply} returns this
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated TransactionHistory history = 3;
 * @return {!Array<!proto.qb.smg.TransactionHistory>}
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.qb.smg.TransactionHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.TransactionHistory, 3));
};


/**
 * @param {!Array<!proto.qb.smg.TransactionHistory>} value
 * @return {!proto.qb.smg.GetTransactionHistoryReply} returns this
*/
proto.qb.smg.GetTransactionHistoryReply.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.TransactionHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.TransactionHistory}
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.TransactionHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetTransactionHistoryReply} returns this
 */
proto.qb.smg.GetTransactionHistoryReply.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EnableAutoPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EnableAutoPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableAutoPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    betamount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: (f = msg.getLocation()) && proto.qb.smg.Location.toObject(includeInstance, f),
    venueId: jspb.Message.getFieldWithDefault(msg, 102, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EnableAutoPlayRequest}
 */
proto.qb.smg.EnableAutoPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EnableAutoPlayRequest;
  return proto.qb.smg.EnableAutoPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EnableAutoPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EnableAutoPlayRequest}
 */
proto.qb.smg.EnableAutoPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBetamount(value);
      break;
    case 101:
      var value = new proto.qb.smg.Location;
      reader.readMessage(value,proto.qb.smg.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EnableAutoPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EnableAutoPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableAutoPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getBetamount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.qb.smg.Location.serializeBinaryToWriter
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.EnableAutoPlayRequest} returns this
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.EnableAutoPlayRequest} returns this
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 betAmount = 3;
 * @return {number}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.getBetamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.EnableAutoPlayRequest} returns this
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.setBetamount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Location location = 101;
 * @return {?proto.qb.smg.Location}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.getLocation = function() {
  return /** @type{?proto.qb.smg.Location} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Location, 101));
};


/**
 * @param {?proto.qb.smg.Location|undefined} value
 * @return {!proto.qb.smg.EnableAutoPlayRequest} returns this
*/
proto.qb.smg.EnableAutoPlayRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EnableAutoPlayRequest} returns this
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 venue_id = 102;
 * @return {number}
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.EnableAutoPlayRequest} returns this
 */
proto.qb.smg.EnableAutoPlayRequest.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EnableAutoPlayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EnableAutoPlayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EnableAutoPlayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableAutoPlayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EnableAutoPlayResponse}
 */
proto.qb.smg.EnableAutoPlayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EnableAutoPlayResponse;
  return proto.qb.smg.EnableAutoPlayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EnableAutoPlayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EnableAutoPlayResponse}
 */
proto.qb.smg.EnableAutoPlayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EnableAutoPlayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EnableAutoPlayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EnableAutoPlayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableAutoPlayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.EnableAutoPlayResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 1));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.EnableAutoPlayResponse} returns this
*/
proto.qb.smg.EnableAutoPlayResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EnableAutoPlayResponse} returns this
 */
proto.qb.smg.EnableAutoPlayResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EnableAutoPlayResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.qb.smg.EnableAutoPlayResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.EnableAutoPlayResponse} returns this
 */
proto.qb.smg.EnableAutoPlayResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Challenge.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Challenge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Challenge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Challenge.toObject = function(includeInstance, msg) {
  var f, obj = {
    challengeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    challengeType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    challengeSubType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    challengeState: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outcome: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hitId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    value1: jspb.Message.getFieldWithDefault(msg, 9, 0),
    value2: jspb.Message.getFieldWithDefault(msg, 10, 0),
    value3: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalAttempts: jspb.Message.getFieldWithDefault(msg, 12, 0),
    attemptsRemaining: jspb.Message.getFieldWithDefault(msg, 13, 0),
    createdOn: jspb.Message.getFieldWithDefault(msg, 14, 0),
    resolvedOn: jspb.Message.getFieldWithDefault(msg, 15, 0),
    odds: jspb.Message.getFieldWithDefault(msg, 16, ""),
    distance: jspb.Message.getFieldWithDefault(msg, 17, ""),
    validtill: jspb.Message.getFieldWithDefault(msg, 18, ""),
    description: jspb.Message.getFieldWithDefault(msg, 19, ""),
    acceptedOn: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Challenge}
 */
proto.qb.smg.Challenge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Challenge;
  return proto.qb.smg.Challenge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Challenge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Challenge}
 */
proto.qb.smg.Challenge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChallengeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSequence(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChallengeType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChallengeSubType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChallengeState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcome(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue1(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue2(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue3(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAttempts(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttemptsRemaining(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedOn(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolvedOn(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdds(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistance(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidtill(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Challenge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Challenge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Challenge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Challenge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChallengeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getChallengeType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChallengeSubType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChallengeState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutcome();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getHitId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getValue1();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getValue2();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getValue3();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTotalAttempts();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getAttemptsRemaining();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getCreatedOn();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getResolvedOn();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getOdds();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDistance();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getValidtill();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAcceptedOn();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
};


/**
 * optional int64 challenge_id = 1;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getChallengeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setChallengeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sequence = 2;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string challenge_type = 3;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getChallengeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setChallengeType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string challenge_sub_type = 4;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getChallengeSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setChallengeSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string challenge_state = 5;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getChallengeState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setChallengeState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string outcome = 6;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getOutcome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setOutcome = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 user_id = 7;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 hit_id = 8;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getHitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setHitId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 value1 = 9;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getValue1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setValue1 = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 value2 = 10;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getValue2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setValue2 = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 value3 = 11;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getValue3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setValue3 = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_attempts = 12;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getTotalAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setTotalAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 attempts_remaining = 13;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getAttemptsRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setAttemptsRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 created_on = 14;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getCreatedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setCreatedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 resolved_on = 15;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getResolvedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setResolvedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string odds = 16;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getOdds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setOdds = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string distance = 17;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getDistance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setDistance = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string validTill = 18;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getValidtill = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setValidtill = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string description = 19;
 * @return {string}
 */
proto.qb.smg.Challenge.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int64 accepted_on = 20;
 * @return {number}
 */
proto.qb.smg.Challenge.prototype.getAcceptedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Challenge} returns this
 */
proto.qb.smg.Challenge.prototype.setAcceptedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Golf2Challenge.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Golf2Challenge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Golf2Challenge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2Challenge.toObject = function(includeInstance, msg) {
  var f, obj = {
    challengeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    gamedetails: (f = msg.getGamedetails()) && proto.qb.smg.Golf2GameDetails.toObject(includeInstance, f),
    multiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    outcome: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdOn: jspb.Message.getFieldWithDefault(msg, 7, 0),
    resolvedOn: jspb.Message.getFieldWithDefault(msg, 8, 0),
    acceptedOn: jspb.Message.getFieldWithDefault(msg, 9, 0),
    level: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Golf2Challenge}
 */
proto.qb.smg.Golf2Challenge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Golf2Challenge;
  return proto.qb.smg.Golf2Challenge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Golf2Challenge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Golf2Challenge}
 */
proto.qb.smg.Golf2Challenge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChallengeId(value);
      break;
    case 2:
      var value = new proto.qb.smg.Golf2GameDetails;
      reader.readMessage(value,proto.qb.smg.Golf2GameDetails.deserializeBinaryFromReader);
      msg.setGamedetails(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMultiplier(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcome(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedOn(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolvedOn(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedOn(value);
      break;
    case 10:
      var value = /** @type {!proto.qb.smg.Golf2Challenge.Golf2ChallengeLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Golf2Challenge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Golf2Challenge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Golf2Challenge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2Challenge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChallengeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGamedetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Golf2GameDetails.serializeBinaryToWriter
    );
  }
  f = message.getMultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOutcome();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreatedOn();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getResolvedOn();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAcceptedOn();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qb.smg.Golf2Challenge.Golf2ChallengeLevel = {
  EASY: 0,
  MEDIUM: 1,
  HARD: 2
};

/**
 * optional int64 challenge_id = 1;
 * @return {number}
 */
proto.qb.smg.Golf2Challenge.prototype.getChallengeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setChallengeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Golf2GameDetails gameDetails = 2;
 * @return {?proto.qb.smg.Golf2GameDetails}
 */
proto.qb.smg.Golf2Challenge.prototype.getGamedetails = function() {
  return /** @type{?proto.qb.smg.Golf2GameDetails} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Golf2GameDetails, 2));
};


/**
 * @param {?proto.qb.smg.Golf2GameDetails|undefined} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
*/
proto.qb.smg.Golf2Challenge.prototype.setGamedetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.clearGamedetails = function() {
  return this.setGamedetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.Golf2Challenge.prototype.hasGamedetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float multiplier = 3;
 * @return {number}
 */
proto.qb.smg.Golf2Challenge.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string outcome = 4;
 * @return {string}
 */
proto.qb.smg.Golf2Challenge.prototype.getOutcome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setOutcome = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 user_id = 5;
 * @return {number}
 */
proto.qb.smg.Golf2Challenge.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created_on = 7;
 * @return {number}
 */
proto.qb.smg.Golf2Challenge.prototype.getCreatedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setCreatedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 resolved_on = 8;
 * @return {number}
 */
proto.qb.smg.Golf2Challenge.prototype.getResolvedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setResolvedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 accepted_on = 9;
 * @return {number}
 */
proto.qb.smg.Golf2Challenge.prototype.getAcceptedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setAcceptedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional Golf2ChallengeLevel level = 10;
 * @return {!proto.qb.smg.Golf2Challenge.Golf2ChallengeLevel}
 */
proto.qb.smg.Golf2Challenge.prototype.getLevel = function() {
  return /** @type {!proto.qb.smg.Golf2Challenge.Golf2ChallengeLevel} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.qb.smg.Golf2Challenge.Golf2ChallengeLevel} value
 * @return {!proto.qb.smg.Golf2Challenge} returns this
 */
proto.qb.smg.Golf2Challenge.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Golf2GameGreen.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Golf2GameGreen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Golf2GameGreen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameGreen.toObject = function(includeInstance, msg) {
  var f, obj = {
    greenId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    range: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rangeExplanation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shape: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Golf2GameGreen}
 */
proto.qb.smg.Golf2GameGreen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Golf2GameGreen;
  return proto.qb.smg.Golf2GameGreen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Golf2GameGreen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Golf2GameGreen}
 */
proto.qb.smg.Golf2GameGreen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGreenId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRange(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRangeExplanation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShape(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Golf2GameGreen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Golf2GameGreen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Golf2GameGreen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameGreen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGreenId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRange();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRangeExplanation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShape();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 green_id = 1;
 * @return {number}
 */
proto.qb.smg.Golf2GameGreen.prototype.getGreenId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameGreen} returns this
 */
proto.qb.smg.Golf2GameGreen.prototype.setGreenId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 range = 2;
 * @return {number}
 */
proto.qb.smg.Golf2GameGreen.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameGreen} returns this
 */
proto.qb.smg.Golf2GameGreen.prototype.setRange = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string range_explanation = 3;
 * @return {string}
 */
proto.qb.smg.Golf2GameGreen.prototype.getRangeExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Golf2GameGreen} returns this
 */
proto.qb.smg.Golf2GameGreen.prototype.setRangeExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string shape = 4;
 * @return {string}
 */
proto.qb.smg.Golf2GameGreen.prototype.getShape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Golf2GameGreen} returns this
 */
proto.qb.smg.Golf2GameGreen.prototype.setShape = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Golf2GameHole.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Golf2GameHole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Golf2GameHole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameHole.toObject = function(includeInstance, msg) {
  var f, obj = {
    holeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    difficulty: jspb.Message.getFieldWithDefault(msg, 2, 0),
    difficultyExplanation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    distance: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Golf2GameHole}
 */
proto.qb.smg.Golf2GameHole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Golf2GameHole;
  return proto.qb.smg.Golf2GameHole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Golf2GameHole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Golf2GameHole}
 */
proto.qb.smg.Golf2GameHole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHoleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDifficulty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDifficultyExplanation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Golf2GameHole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Golf2GameHole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Golf2GameHole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameHole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHoleId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDifficulty();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDifficultyExplanation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 hole_id = 1;
 * @return {number}
 */
proto.qb.smg.Golf2GameHole.prototype.getHoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameHole} returns this
 */
proto.qb.smg.Golf2GameHole.prototype.setHoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 difficulty = 2;
 * @return {number}
 */
proto.qb.smg.Golf2GameHole.prototype.getDifficulty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameHole} returns this
 */
proto.qb.smg.Golf2GameHole.prototype.setDifficulty = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string difficulty_explanation = 3;
 * @return {string}
 */
proto.qb.smg.Golf2GameHole.prototype.getDifficultyExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Golf2GameHole} returns this
 */
proto.qb.smg.Golf2GameHole.prototype.setDifficultyExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 distance = 4;
 * @return {number}
 */
proto.qb.smg.Golf2GameHole.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameHole} returns this
 */
proto.qb.smg.Golf2GameHole.prototype.setDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Golf2GameCircle.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Golf2GameCircle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Golf2GameCircle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameCircle.toObject = function(includeInstance, msg) {
  var f, obj = {
    circleId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    winCircleDistance: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Golf2GameCircle}
 */
proto.qb.smg.Golf2GameCircle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Golf2GameCircle;
  return proto.qb.smg.Golf2GameCircle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Golf2GameCircle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Golf2GameCircle}
 */
proto.qb.smg.Golf2GameCircle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCircleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinCircleDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Golf2GameCircle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Golf2GameCircle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Golf2GameCircle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameCircle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCircleId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWinCircleDistance();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 circle_id = 1;
 * @return {number}
 */
proto.qb.smg.Golf2GameCircle.prototype.getCircleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameCircle} returns this
 */
proto.qb.smg.Golf2GameCircle.prototype.setCircleId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 win_circle_distance = 2;
 * @return {number}
 */
proto.qb.smg.Golf2GameCircle.prototype.getWinCircleDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameCircle} returns this
 */
proto.qb.smg.Golf2GameCircle.prototype.setWinCircleDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Golf2GameDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Golf2GameDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Golf2GameDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    green: (f = msg.getGreen()) && proto.qb.smg.Golf2GameGreen.toObject(includeInstance, f),
    hole: (f = msg.getHole()) && proto.qb.smg.Golf2GameHole.toObject(includeInstance, f),
    circle: (f = msg.getCircle()) && proto.qb.smg.Golf2GameCircle.toObject(includeInstance, f),
    targetLevel: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Golf2GameDetails}
 */
proto.qb.smg.Golf2GameDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Golf2GameDetails;
  return proto.qb.smg.Golf2GameDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Golf2GameDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Golf2GameDetails}
 */
proto.qb.smg.Golf2GameDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qb.smg.Golf2GameGreen;
      reader.readMessage(value,proto.qb.smg.Golf2GameGreen.deserializeBinaryFromReader);
      msg.setGreen(value);
      break;
    case 2:
      var value = new proto.qb.smg.Golf2GameHole;
      reader.readMessage(value,proto.qb.smg.Golf2GameHole.deserializeBinaryFromReader);
      msg.setHole(value);
      break;
    case 3:
      var value = new proto.qb.smg.Golf2GameCircle;
      reader.readMessage(value,proto.qb.smg.Golf2GameCircle.deserializeBinaryFromReader);
      msg.setCircle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Golf2GameDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Golf2GameDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Golf2GameDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Golf2GameDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGreen();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qb.smg.Golf2GameGreen.serializeBinaryToWriter
    );
  }
  f = message.getHole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Golf2GameHole.serializeBinaryToWriter
    );
  }
  f = message.getCircle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qb.smg.Golf2GameCircle.serializeBinaryToWriter
    );
  }
  f = message.getTargetLevel();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Golf2GameGreen green = 1;
 * @return {?proto.qb.smg.Golf2GameGreen}
 */
proto.qb.smg.Golf2GameDetails.prototype.getGreen = function() {
  return /** @type{?proto.qb.smg.Golf2GameGreen} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Golf2GameGreen, 1));
};


/**
 * @param {?proto.qb.smg.Golf2GameGreen|undefined} value
 * @return {!proto.qb.smg.Golf2GameDetails} returns this
*/
proto.qb.smg.Golf2GameDetails.prototype.setGreen = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.Golf2GameDetails} returns this
 */
proto.qb.smg.Golf2GameDetails.prototype.clearGreen = function() {
  return this.setGreen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.Golf2GameDetails.prototype.hasGreen = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Golf2GameHole hole = 2;
 * @return {?proto.qb.smg.Golf2GameHole}
 */
proto.qb.smg.Golf2GameDetails.prototype.getHole = function() {
  return /** @type{?proto.qb.smg.Golf2GameHole} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Golf2GameHole, 2));
};


/**
 * @param {?proto.qb.smg.Golf2GameHole|undefined} value
 * @return {!proto.qb.smg.Golf2GameDetails} returns this
*/
proto.qb.smg.Golf2GameDetails.prototype.setHole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.Golf2GameDetails} returns this
 */
proto.qb.smg.Golf2GameDetails.prototype.clearHole = function() {
  return this.setHole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.Golf2GameDetails.prototype.hasHole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Golf2GameCircle circle = 3;
 * @return {?proto.qb.smg.Golf2GameCircle}
 */
proto.qb.smg.Golf2GameDetails.prototype.getCircle = function() {
  return /** @type{?proto.qb.smg.Golf2GameCircle} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Golf2GameCircle, 3));
};


/**
 * @param {?proto.qb.smg.Golf2GameCircle|undefined} value
 * @return {!proto.qb.smg.Golf2GameDetails} returns this
*/
proto.qb.smg.Golf2GameDetails.prototype.setCircle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.Golf2GameDetails} returns this
 */
proto.qb.smg.Golf2GameDetails.prototype.clearCircle = function() {
  return this.setCircle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.Golf2GameDetails.prototype.hasCircle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 target_level = 4;
 * @return {number}
 */
proto.qb.smg.Golf2GameDetails.prototype.getTargetLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Golf2GameDetails} returns this
 */
proto.qb.smg.Golf2GameDetails.prototype.setTargetLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.Bet.repeatedFields_ = [11,13,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Bet.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Bet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Bet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Bet.toObject = function(includeInstance, msg) {
  var f, obj = {
    betId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    betType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    betState: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    multiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    outcome: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdOn: jspb.Message.getFieldWithDefault(msg, 8, 0),
    resolvedOn: jspb.Message.getFieldWithDefault(msg, 9, 0),
    venueId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    challengesList: jspb.Message.toObjectList(msg.getChallengesList(),
    proto.qb.smg.Challenge.toObject, includeInstance),
    paylinesList: jspb.Message.toObjectList(msg.getPaylinesList(),
    proto.qb.smg.Payline.toObject, includeInstance),
    golf2challengesList: jspb.Message.toObjectList(msg.getGolf2challengesList(),
    proto.qb.smg.Golf2Challenge.toObject, includeInstance),
    acceptedOn: jspb.Message.getFieldWithDefault(msg, 14, 0),
    holeInOne: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    holeInOneBonusAmount: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Bet}
 */
proto.qb.smg.Bet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Bet;
  return proto.qb.smg.Bet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Bet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Bet}
 */
proto.qb.smg.Bet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetState(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMultiplier(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcome(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedOn(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolvedOn(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVenueId(value);
      break;
    case 11:
      var value = new proto.qb.smg.Challenge;
      reader.readMessage(value,proto.qb.smg.Challenge.deserializeBinaryFromReader);
      msg.addChallenges(value);
      break;
    case 13:
      var value = new proto.qb.smg.Payline;
      reader.readMessage(value,proto.qb.smg.Payline.deserializeBinaryFromReader);
      msg.addPaylines(value);
      break;
    case 12:
      var value = new proto.qb.smg.Golf2Challenge;
      reader.readMessage(value,proto.qb.smg.Golf2Challenge.deserializeBinaryFromReader);
      msg.addGolf2challenges(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedOn(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoleInOne(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHoleInOneBonusAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Bet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Bet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Bet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Bet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBetType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBetState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOutcome();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedOn();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getResolvedOn();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getVenueId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getChallengesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.qb.smg.Challenge.serializeBinaryToWriter
    );
  }
  f = message.getPaylinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.qb.smg.Payline.serializeBinaryToWriter
    );
  }
  f = message.getGolf2challengesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.qb.smg.Golf2Challenge.serializeBinaryToWriter
    );
  }
  f = message.getAcceptedOn();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getHoleInOne();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHoleInOneBonusAmount();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional int64 bet_id = 1;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string bet_type = 3;
 * @return {string}
 */
proto.qb.smg.Bet.prototype.getBetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setBetType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bet_state = 4;
 * @return {string}
 */
proto.qb.smg.Bet.prototype.getBetState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setBetState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 amount = 5;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float multiplier = 6;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string outcome = 7;
 * @return {string}
 */
proto.qb.smg.Bet.prototype.getOutcome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setOutcome = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 created_on = 8;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getCreatedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setCreatedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 resolved_on = 9;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getResolvedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setResolvedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 venue_id = 10;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getVenueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated Challenge challenges = 11;
 * @return {!Array<!proto.qb.smg.Challenge>}
 */
proto.qb.smg.Bet.prototype.getChallengesList = function() {
  return /** @type{!Array<!proto.qb.smg.Challenge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Challenge, 11));
};


/**
 * @param {!Array<!proto.qb.smg.Challenge>} value
 * @return {!proto.qb.smg.Bet} returns this
*/
proto.qb.smg.Bet.prototype.setChallengesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.qb.smg.Challenge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Challenge}
 */
proto.qb.smg.Bet.prototype.addChallenges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.qb.smg.Challenge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.clearChallengesList = function() {
  return this.setChallengesList([]);
};


/**
 * repeated Payline paylines = 13;
 * @return {!Array<!proto.qb.smg.Payline>}
 */
proto.qb.smg.Bet.prototype.getPaylinesList = function() {
  return /** @type{!Array<!proto.qb.smg.Payline>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Payline, 13));
};


/**
 * @param {!Array<!proto.qb.smg.Payline>} value
 * @return {!proto.qb.smg.Bet} returns this
*/
proto.qb.smg.Bet.prototype.setPaylinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.qb.smg.Payline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Payline}
 */
proto.qb.smg.Bet.prototype.addPaylines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.qb.smg.Payline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.clearPaylinesList = function() {
  return this.setPaylinesList([]);
};


/**
 * repeated Golf2Challenge golf2Challenges = 12;
 * @return {!Array<!proto.qb.smg.Golf2Challenge>}
 */
proto.qb.smg.Bet.prototype.getGolf2challengesList = function() {
  return /** @type{!Array<!proto.qb.smg.Golf2Challenge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Golf2Challenge, 12));
};


/**
 * @param {!Array<!proto.qb.smg.Golf2Challenge>} value
 * @return {!proto.qb.smg.Bet} returns this
*/
proto.qb.smg.Bet.prototype.setGolf2challengesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.qb.smg.Golf2Challenge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Golf2Challenge}
 */
proto.qb.smg.Bet.prototype.addGolf2challenges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.qb.smg.Golf2Challenge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.clearGolf2challengesList = function() {
  return this.setGolf2challengesList([]);
};


/**
 * optional int64 accepted_on = 14;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getAcceptedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setAcceptedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool hole_in_one = 15;
 * @return {boolean}
 */
proto.qb.smg.Bet.prototype.getHoleInOne = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setHoleInOne = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int32 hole_in_one_bonus_amount = 16;
 * @return {number}
 */
proto.qb.smg.Bet.prototype.getHoleInOneBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Bet} returns this
 */
proto.qb.smg.Bet.prototype.setHoleInOneBonusAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Payout.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Payout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Payout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Payout.toObject = function(includeInstance, msg) {
  var f, obj = {
    payoutId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcome: jspb.Message.getFieldWithDefault(msg, 2, ""),
    odds: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    betid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Payout}
 */
proto.qb.smg.Payout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Payout;
  return proto.qb.smg.Payout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Payout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Payout}
 */
proto.qb.smg.Payout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayoutId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcome(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Payout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Payout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Payout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Payout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayoutId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOutcome();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOdds();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBetid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 payout_id = 1;
 * @return {number}
 */
proto.qb.smg.Payout.prototype.getPayoutId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payout} returns this
 */
proto.qb.smg.Payout.prototype.setPayoutId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string outcome = 2;
 * @return {string}
 */
proto.qb.smg.Payout.prototype.getOutcome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Payout} returns this
 */
proto.qb.smg.Payout.prototype.setOutcome = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string odds = 3;
 * @return {string}
 */
proto.qb.smg.Payout.prototype.getOdds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Payout} returns this
 */
proto.qb.smg.Payout.prototype.setOdds = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
proto.qb.smg.Payout.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payout} returns this
 */
proto.qb.smg.Payout.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 betId = 5;
 * @return {number}
 */
proto.qb.smg.Payout.prototype.getBetid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payout} returns this
 */
proto.qb.smg.Payout.prototype.setBetid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Payline.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Payline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Payline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Payline.toObject = function(includeInstance, msg) {
  var f, obj = {
    paylineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    betId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    challengeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    multiplier: jspb.Message.getFieldWithDefault(msg, 4, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Payline}
 */
proto.qb.smg.Payline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Payline;
  return proto.qb.smg.Payline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Payline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Payline}
 */
proto.qb.smg.Payline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaylineId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChallengeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMultiplier(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Payline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Payline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Payline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Payline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaylineId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getChallengeId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 payline_id = 1;
 * @return {number}
 */
proto.qb.smg.Payline.prototype.getPaylineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payline} returns this
 */
proto.qb.smg.Payline.prototype.setPaylineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 bet_id = 2;
 * @return {number}
 */
proto.qb.smg.Payline.prototype.getBetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payline} returns this
 */
proto.qb.smg.Payline.prototype.setBetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 challenge_id = 3;
 * @return {number}
 */
proto.qb.smg.Payline.prototype.getChallengeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payline} returns this
 */
proto.qb.smg.Payline.prototype.setChallengeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 multiplier = 4;
 * @return {number}
 */
proto.qb.smg.Payline.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payline} returns this
 */
proto.qb.smg.Payline.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 priority = 5;
 * @return {number}
 */
proto.qb.smg.Payline.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Payline} returns this
 */
proto.qb.smg.Payline.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Session.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Session.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Session} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Session.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionStart: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sessionEnd: jspb.Message.getFieldWithDefault(msg, 4, 0),
    analyticsId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Session}
 */
proto.qb.smg.Session.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Session;
  return proto.qb.smg.Session.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Session} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Session}
 */
proto.qb.smg.Session.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionEnd(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnalyticsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Session.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Session.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Session} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Session.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSessionStart();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSessionEnd();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAnalyticsId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.qb.smg.Session.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Session} returns this
 */
proto.qb.smg.Session.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.qb.smg.Session.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Session} returns this
 */
proto.qb.smg.Session.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 session_start = 3;
 * @return {number}
 */
proto.qb.smg.Session.prototype.getSessionStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Session} returns this
 */
proto.qb.smg.Session.prototype.setSessionStart = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 session_end = 4;
 * @return {number}
 */
proto.qb.smg.Session.prototype.getSessionEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Session} returns this
 */
proto.qb.smg.Session.prototype.setSessionEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string analytics_id = 5;
 * @return {string}
 */
proto.qb.smg.Session.prototype.getAnalyticsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Session} returns this
 */
proto.qb.smg.Session.prototype.setAnalyticsId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.User.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    handedness: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    middleName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address: jspb.Message.getFieldWithDefault(msg, 10, ""),
    country: jspb.Message.getFieldWithDefault(msg, 11, ""),
    state: jspb.Message.getFieldWithDefault(msg, 12, ""),
    city: jspb.Message.getFieldWithDefault(msg, 13, ""),
    zipcode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    tncAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    tncAcceptDate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    tncAcceptedVersion: jspb.Message.getFieldWithDefault(msg, 17, ""),
    photoAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    kycStatus: jspb.Message.getFieldWithDefault(msg, 20, 0),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 21, ""),
    realName: jspb.Message.getFieldWithDefault(msg, 22, ""),
    emailConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    handicap: jspb.Message.getFieldWithDefault(msg, 24, ""),
    allowTextNotifications: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    allowEmailNotifications: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    allowPartnerPromos: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    selfLimitationConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    selfLimitationExpiration: jspb.Message.getFieldWithDefault(msg, 29, 0),
    autoPlayEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    autoPlayAmount: jspb.Message.getFieldWithDefault(msg, 31, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.User}
 */
proto.qb.smg.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.User;
  return proto.qb.smg.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.User}
 */
proto.qb.smg.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandedness(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipcode(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTncAccepted(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTncAcceptDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTncAcceptedVersion(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhotoAccepted(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKycStatus(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateOfBirth(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealName(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailConfirmed(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandicap(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowTextNotifications(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowEmailNotifications(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowPartnerPromos(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfLimitationConfirmed(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSelfLimitationExpiration(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoPlayEnabled(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAutoPlayAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHandedness();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMiddleName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getZipcode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTncAccepted();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getTncAcceptDate();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getTncAcceptedVersion();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPhotoAccepted();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getKycStatus();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRealName();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getEmailConfirmed();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getHandicap();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAllowTextNotifications();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getAllowEmailNotifications();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getAllowPartnerPromos();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getSelfLimitationConfirmed();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getSelfLimitationExpiration();
  if (f !== 0) {
    writer.writeInt64(
      29,
      f
    );
  }
  f = message.getAutoPlayEnabled();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getAutoPlayAmount();
  if (f !== 0) {
    writer.writeInt32(
      31,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.qb.smg.User.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.qb.smg.User.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email_address = 3;
 * @return {string}
 */
proto.qb.smg.User.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 balance = 4;
 * @return {number}
 */
proto.qb.smg.User.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string handedness = 5;
 * @return {string}
 */
proto.qb.smg.User.prototype.getHandedness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setHandedness = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone_number = 6;
 * @return {string}
 */
proto.qb.smg.User.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string firstname = 7;
 * @return {string}
 */
proto.qb.smg.User.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string middle_name = 8;
 * @return {string}
 */
proto.qb.smg.User.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastname = 9;
 * @return {string}
 */
proto.qb.smg.User.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address = 10;
 * @return {string}
 */
proto.qb.smg.User.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string country = 11;
 * @return {string}
 */
proto.qb.smg.User.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string state = 12;
 * @return {string}
 */
proto.qb.smg.User.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string city = 13;
 * @return {string}
 */
proto.qb.smg.User.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string zipcode = 14;
 * @return {string}
 */
proto.qb.smg.User.prototype.getZipcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setZipcode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool tnc_accepted = 15;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getTncAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setTncAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int64 tnc_accept_date = 16;
 * @return {number}
 */
proto.qb.smg.User.prototype.getTncAcceptDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setTncAcceptDate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string tnc_accepted_version = 17;
 * @return {string}
 */
proto.qb.smg.User.prototype.getTncAcceptedVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setTncAcceptedVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool photo_accepted = 19;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getPhotoAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setPhotoAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional int32 kyc_status = 20;
 * @return {number}
 */
proto.qb.smg.User.prototype.getKycStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setKycStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string date_of_birth = 21;
 * @return {string}
 */
proto.qb.smg.User.prototype.getDateOfBirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string real_name = 22;
 * @return {string}
 */
proto.qb.smg.User.prototype.getRealName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setRealName = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool email_confirmed = 23;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getEmailConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setEmailConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional string handicap = 24;
 * @return {string}
 */
proto.qb.smg.User.prototype.getHandicap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setHandicap = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool allow_text_notifications = 25;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getAllowTextNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setAllowTextNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool allow_email_notifications = 26;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getAllowEmailNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setAllowEmailNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool allow_partner_promos = 27;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getAllowPartnerPromos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setAllowPartnerPromos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool self_limitation_confirmed = 28;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getSelfLimitationConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setSelfLimitationConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional int64 self_limitation_expiration = 29;
 * @return {number}
 */
proto.qb.smg.User.prototype.getSelfLimitationExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setSelfLimitationExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional bool auto_play_enabled = 30;
 * @return {boolean}
 */
proto.qb.smg.User.prototype.getAutoPlayEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setAutoPlayEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional int32 auto_play_amount = 31;
 * @return {number}
 */
proto.qb.smg.User.prototype.getAutoPlayAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.User} returns this
 */
proto.qb.smg.User.prototype.setAutoPlayAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Location}
 */
proto.qb.smg.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Location;
  return proto.qb.smg.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Location}
 */
proto.qb.smg.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.qb.smg.Location.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Location} returns this
 */
proto.qb.smg.Location.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.qb.smg.Location.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Location} returns this
 */
proto.qb.smg.Location.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.VenueConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.VenueConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.VenueConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.VenueConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    facialRecognitionServerAddress: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.VenueConfig}
 */
proto.qb.smg.VenueConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.VenueConfig;
  return proto.qb.smg.VenueConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.VenueConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.VenueConfig}
 */
proto.qb.smg.VenueConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacialRecognitionServerAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.VenueConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.VenueConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.VenueConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.VenueConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacialRecognitionServerAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string facial_recognition_server_address = 1;
 * @return {string}
 */
proto.qb.smg.VenueConfig.prototype.getFacialRecognitionServerAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.VenueConfig} returns this
 */
proto.qb.smg.VenueConfig.prototype.setFacialRecognitionServerAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Error}
 */
proto.qb.smg.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Error;
  return proto.qb.smg.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Error}
 */
proto.qb.smg.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 code = 1;
 * @return {number}
 */
proto.qb.smg.Error.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Error} returns this
 */
proto.qb.smg.Error.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.qb.smg.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Error} returns this
 */
proto.qb.smg.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Skills.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Skills.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Skills} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Skills.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillLevel1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    skillLevel2: jspb.Message.getFieldWithDefault(msg, 2, 0),
    skillLevel3: jspb.Message.getFieldWithDefault(msg, 3, 0),
    skillLevel4: jspb.Message.getFieldWithDefault(msg, 4, 0),
    golf2GameSkill: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Skills}
 */
proto.qb.smg.Skills.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Skills;
  return proto.qb.smg.Skills.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Skills} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Skills}
 */
proto.qb.smg.Skills.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSkillLevel1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSkillLevel2(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSkillLevel3(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSkillLevel4(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGolf2GameSkill(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Skills.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Skills.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Skills} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Skills.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillLevel1();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSkillLevel2();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSkillLevel3();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSkillLevel4();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGolf2GameSkill();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 skill_level_1 = 1;
 * @return {number}
 */
proto.qb.smg.Skills.prototype.getSkillLevel1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Skills} returns this
 */
proto.qb.smg.Skills.prototype.setSkillLevel1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 skill_level_2 = 2;
 * @return {number}
 */
proto.qb.smg.Skills.prototype.getSkillLevel2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Skills} returns this
 */
proto.qb.smg.Skills.prototype.setSkillLevel2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 skill_level_3 = 3;
 * @return {number}
 */
proto.qb.smg.Skills.prototype.getSkillLevel3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Skills} returns this
 */
proto.qb.smg.Skills.prototype.setSkillLevel3 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 skill_level_4 = 4;
 * @return {number}
 */
proto.qb.smg.Skills.prototype.getSkillLevel4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Skills} returns this
 */
proto.qb.smg.Skills.prototype.setSkillLevel4 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 golf2_game_skill = 5;
 * @return {number}
 */
proto.qb.smg.Skills.prototype.getGolf2GameSkill = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Skills} returns this
 */
proto.qb.smg.Skills.prototype.setGolf2GameSkill = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.TransactionHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.TransactionHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.TransactionHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TransactionHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    time: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    winAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastUpdateTime: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.TransactionHistory}
 */
proto.qb.smg.TransactionHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.TransactionHistory;
  return proto.qb.smg.TransactionHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.TransactionHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.TransactionHistory}
 */
proto.qb.smg.TransactionHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWinAmount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.TransactionHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.TransactionHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.TransactionHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.TransactionHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerid();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWinAmount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLastUpdateTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional uint64 playerId = 1;
 * @return {number}
 */
proto.qb.smg.TransactionHistory.prototype.getPlayerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TransactionHistory} returns this
 */
proto.qb.smg.TransactionHistory.prototype.setPlayerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.qb.smg.TransactionHistory.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.TransactionHistory} returns this
 */
proto.qb.smg.TransactionHistory.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 time = 3;
 * @return {number}
 */
proto.qb.smg.TransactionHistory.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TransactionHistory} returns this
 */
proto.qb.smg.TransactionHistory.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
proto.qb.smg.TransactionHistory.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TransactionHistory} returns this
 */
proto.qb.smg.TransactionHistory.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.qb.smg.TransactionHistory.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.TransactionHistory} returns this
 */
proto.qb.smg.TransactionHistory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 win_amount = 6;
 * @return {number}
 */
proto.qb.smg.TransactionHistory.prototype.getWinAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TransactionHistory} returns this
 */
proto.qb.smg.TransactionHistory.prototype.setWinAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 last_update_time = 7;
 * @return {number}
 */
proto.qb.smg.TransactionHistory.prototype.getLastUpdateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.TransactionHistory} returns this
 */
proto.qb.smg.TransactionHistory.prototype.setLastUpdateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SetWelcomeBonusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SetWelcomeBonusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetWelcomeBonusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    daysBeforeExpire: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    turnoverAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SetWelcomeBonusRequest}
 */
proto.qb.smg.SetWelcomeBonusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SetWelcomeBonusRequest;
  return proto.qb.smg.SetWelcomeBonusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SetWelcomeBonusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SetWelcomeBonusRequest}
 */
proto.qb.smg.SetWelcomeBonusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBonusId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysBeforeExpire(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTurnoverAmount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SetWelcomeBonusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SetWelcomeBonusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetWelcomeBonusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDaysBeforeExpire();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTurnoverAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 bonus_id = 1;
 * @return {number}
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.getBonusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SetWelcomeBonusRequest} returns this
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.setBonusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 days_before_expire = 2;
 * @return {number}
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.getDaysBeforeExpire = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SetWelcomeBonusRequest} returns this
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.setDaysBeforeExpire = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SetWelcomeBonusRequest} returns this
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 turnover_amount = 4;
 * @return {number}
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.getTurnoverAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.SetWelcomeBonusRequest} returns this
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.setTurnoverAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * @return {boolean}
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.SetWelcomeBonusRequest} returns this
 */
proto.qb.smg.SetWelcomeBonusRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.SetWelcomeBonusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.SetWelcomeBonusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.SetWelcomeBonusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetWelcomeBonusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.SetWelcomeBonusResponse}
 */
proto.qb.smg.SetWelcomeBonusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.SetWelcomeBonusResponse;
  return proto.qb.smg.SetWelcomeBonusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.SetWelcomeBonusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.SetWelcomeBonusResponse}
 */
proto.qb.smg.SetWelcomeBonusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.SetWelcomeBonusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.SetWelcomeBonusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.SetWelcomeBonusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.SetWelcomeBonusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.SetWelcomeBonusResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.SetWelcomeBonusResponse} returns this
 */
proto.qb.smg.SetWelcomeBonusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.SetWelcomeBonusResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.SetWelcomeBonusResponse} returns this
*/
proto.qb.smg.SetWelcomeBonusResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.SetWelcomeBonusResponse} returns this
 */
proto.qb.smg.SetWelcomeBonusResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.SetWelcomeBonusResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EnableShawWalletRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EnableShawWalletRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EnableShawWalletRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableShawWalletRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    token: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EnableShawWalletRequest}
 */
proto.qb.smg.EnableShawWalletRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EnableShawWalletRequest;
  return proto.qb.smg.EnableShawWalletRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EnableShawWalletRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EnableShawWalletRequest}
 */
proto.qb.smg.EnableShawWalletRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EnableShawWalletRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EnableShawWalletRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EnableShawWalletRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableShawWalletRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.qb.smg.EnableShawWalletRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.EnableShawWalletRequest} returns this
 */
proto.qb.smg.EnableShawWalletRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.qb.smg.EnableShawWalletRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.EnableShawWalletRequest} returns this
 */
proto.qb.smg.EnableShawWalletRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.qb.smg.EnableShawWalletRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.EnableShawWalletRequest} returns this
 */
proto.qb.smg.EnableShawWalletRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.EnableShawWalletResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.EnableShawWalletResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.EnableShawWalletResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableShawWalletResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.EnableShawWalletResponse}
 */
proto.qb.smg.EnableShawWalletResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.EnableShawWalletResponse;
  return proto.qb.smg.EnableShawWalletResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.EnableShawWalletResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.EnableShawWalletResponse}
 */
proto.qb.smg.EnableShawWalletResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.EnableShawWalletResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.EnableShawWalletResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.EnableShawWalletResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.EnableShawWalletResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.EnableShawWalletResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.EnableShawWalletResponse} returns this
 */
proto.qb.smg.EnableShawWalletResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.EnableShawWalletResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.EnableShawWalletResponse} returns this
*/
proto.qb.smg.EnableShawWalletResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.EnableShawWalletResponse} returns this
 */
proto.qb.smg.EnableShawWalletResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.EnableShawWalletResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResetKycRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResetKycRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResetKycRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetKycRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResetKycRequest}
 */
proto.qb.smg.ResetKycRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResetKycRequest;
  return proto.qb.smg.ResetKycRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResetKycRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResetKycRequest}
 */
proto.qb.smg.ResetKycRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResetKycRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResetKycRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResetKycRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetKycRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 player_id = 1;
 * @return {number}
 */
proto.qb.smg.ResetKycRequest.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.ResetKycRequest} returns this
 */
proto.qb.smg.ResetKycRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.qb.smg.ResetKycRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.ResetKycRequest} returns this
 */
proto.qb.smg.ResetKycRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.ResetKycReply.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.ResetKycReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.ResetKycReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetKycReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.ResetKycReply}
 */
proto.qb.smg.ResetKycReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.ResetKycReply;
  return proto.qb.smg.ResetKycReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.ResetKycReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.ResetKycReply}
 */
proto.qb.smg.ResetKycReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.ResetKycReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.ResetKycReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.ResetKycReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.ResetKycReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.ResetKycReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.ResetKycReply} returns this
 */
proto.qb.smg.ResetKycReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.ResetKycReply.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.ResetKycReply} returns this
*/
proto.qb.smg.ResetKycReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.ResetKycReply} returns this
 */
proto.qb.smg.ResetKycReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.ResetKycReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetPromosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetPromosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetPromosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPromosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetPromosRequest}
 */
proto.qb.smg.GetPromosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetPromosRequest;
  return proto.qb.smg.GetPromosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetPromosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetPromosRequest}
 */
proto.qb.smg.GetPromosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetPromosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetPromosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetPromosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPromosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 player_id = 1;
 * @return {number}
 */
proto.qb.smg.GetPromosRequest.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.GetPromosRequest} returns this
 */
proto.qb.smg.GetPromosRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.qb.smg.GetPromosRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.GetPromosRequest} returns this
 */
proto.qb.smg.GetPromosRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qb.smg.GetPromosResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.GetPromosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.GetPromosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.GetPromosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPromosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto.qb.smg.Error.toObject(includeInstance, f),
    promosList: jspb.Message.toObjectList(msg.getPromosList(),
    proto.qb.smg.Promo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.GetPromosResponse}
 */
proto.qb.smg.GetPromosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.GetPromosResponse;
  return proto.qb.smg.GetPromosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.GetPromosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.GetPromosResponse}
 */
proto.qb.smg.GetPromosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.qb.smg.Error;
      reader.readMessage(value,proto.qb.smg.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.qb.smg.Promo;
      reader.readMessage(value,proto.qb.smg.Promo.deserializeBinaryFromReader);
      msg.addPromos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.GetPromosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.GetPromosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.GetPromosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.GetPromosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qb.smg.Error.serializeBinaryToWriter
    );
  }
  f = message.getPromosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qb.smg.Promo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.qb.smg.GetPromosResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qb.smg.GetPromosResponse} returns this
 */
proto.qb.smg.GetPromosResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.qb.smg.Error}
 */
proto.qb.smg.GetPromosResponse.prototype.getError = function() {
  return /** @type{?proto.qb.smg.Error} */ (
    jspb.Message.getWrapperField(this, proto.qb.smg.Error, 2));
};


/**
 * @param {?proto.qb.smg.Error|undefined} value
 * @return {!proto.qb.smg.GetPromosResponse} returns this
*/
proto.qb.smg.GetPromosResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qb.smg.GetPromosResponse} returns this
 */
proto.qb.smg.GetPromosResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qb.smg.GetPromosResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Promo promos = 3;
 * @return {!Array<!proto.qb.smg.Promo>}
 */
proto.qb.smg.GetPromosResponse.prototype.getPromosList = function() {
  return /** @type{!Array<!proto.qb.smg.Promo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qb.smg.Promo, 3));
};


/**
 * @param {!Array<!proto.qb.smg.Promo>} value
 * @return {!proto.qb.smg.GetPromosResponse} returns this
*/
proto.qb.smg.GetPromosResponse.prototype.setPromosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qb.smg.Promo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qb.smg.Promo}
 */
proto.qb.smg.GetPromosResponse.prototype.addPromos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qb.smg.Promo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qb.smg.GetPromosResponse} returns this
 */
proto.qb.smg.GetPromosResponse.prototype.clearPromosList = function() {
  return this.setPromosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qb.smg.Promo.prototype.toObject = function(opt_includeInstance) {
  return proto.qb.smg.Promo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qb.smg.Promo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Promo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    turnoverAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qb.smg.Promo}
 */
proto.qb.smg.Promo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qb.smg.Promo;
  return proto.qb.smg.Promo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qb.smg.Promo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qb.smg.Promo}
 */
proto.qb.smg.Promo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTurnoverAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qb.smg.Promo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qb.smg.Promo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qb.smg.Promo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qb.smg.Promo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTurnoverAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.qb.smg.Promo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Promo} returns this
 */
proto.qb.smg.Promo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.qb.smg.Promo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Promo} returns this
 */
proto.qb.smg.Promo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.qb.smg.Promo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Promo} returns this
 */
proto.qb.smg.Promo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
proto.qb.smg.Promo.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Promo} returns this
 */
proto.qb.smg.Promo.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 turnover_amount = 5;
 * @return {number}
 */
proto.qb.smg.Promo.prototype.getTurnoverAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Promo} returns this
 */
proto.qb.smg.Promo.prototype.setTurnoverAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.qb.smg.Promo.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qb.smg.Promo} returns this
 */
proto.qb.smg.Promo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 expiration_date = 7;
 * @return {number}
 */
proto.qb.smg.Promo.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qb.smg.Promo} returns this
 */
proto.qb.smg.Promo.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.qb.smg.ChangeType = {
  ADDRESS: 0,
  PHOTO: 1
};

/**
 * @enum {number}
 */
proto.qb.smg.ReplacePhotoStatus = {
  OPEN: 0,
  APPROVED: 1,
  DECLINED: 2
};

/**
 * @enum {number}
 */
proto.qb.smg.StationPlayerState = {
  NO_PLAYER: 0,
  GUEST: 1,
  PLAYER: 2,
  PLAYER_DELAY: 3,
  MULTI_PLAYER: 4,
  PLAYER_NOT_IN_GAMEPLAY_AREA: 5
};

goog.object.extend(exports, proto.qb.smg);
