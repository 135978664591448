import React from "react";
import {Modal, ScrollView, Text, View} from "react-native";
import MainStyle from "../../MainStyle";
import Lottie from "lottie-react";
import build_your_bank from '../assets/lottie/build-your-bank_10FS_2024-Jul-10.json';
import Button from "../components/Button";

type Props = {
  isOpen: boolean, onClose: () => void, onContinue: () => void, title: string, message: string, firstButtonText: string,
  hideSecondButton: boolean
};

const AlertModal = (props: Props) => {
  const {isOpen, message, title, firstButtonText, hideSecondButton} = props;

  return (
    <Modal animationType="fade" transparent visible={isOpen} statusBarTranslucent={false} hardwareAccelerated={false}
           onRequestClose={props.onClose}>
      <View style={[MainStyle.fix_gradient_and_modal_error_in_landscape, MainStyle.flex1, MainStyle.bg_filter_modal]}>
        <ScrollView contentContainerStyle={MainStyle.flex1}>
          <View style={[MainStyle.min_h_full, MainStyle.a_i_c, MainStyle.j_c_c]}>
            <View style={styles.contentWrapper}>
              <Text style={styles.title}>{title}</Text>

              <Lottie animationData={build_your_bank} style={{width: 220, height: 173}} loop={false}/>

              <Text style={styles.label}>{message}</Text>

              <Button text={firstButtonText || 'Confirm'} onPress={props.onContinue} w={264} mb={15}/>

              {!hideSecondButton && (
                <Button text="Cancel" onPress={props.onContinue} w={264} bg="transparent" borderWidth={1} mb={15}
                        borderColor="white" color="white"/>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = {
  contentWrapper: [
    MainStyle.bg_settings, MainStyle.max_w_332, MainStyle.full_w, MainStyle.b_r_20, MainStyle.b_c_settings_border,
    MainStyle.b_w_1, MainStyle.p_t_30, MainStyle.p_b_41, MainStyle.a_i_c
  ],
  title: [MainStyle.c_white, MainStyle.Barlow500, MainStyle.f_s_26, MainStyle.t_a_c, MainStyle.l_h_26],
  label: [
    MainStyle.c_white, MainStyle.t_a_c, MainStyle.Barlow500, MainStyle.f_s_20, MainStyle.l_h_20,
    MainStyle.m_b_19
  ]
};

export default React.memo(AlertModal);
